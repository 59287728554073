import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.stage_Header = this.svg
      .append('svg')

      this.stage_Header
      .append('rect')
      .attr('x', -1).attr('y', -1)
      .attr('width', 1601)
      .attr('height', 175)
      .attr('fill', '#fff')
      .style('opacity', 1)

      this.loadSvg(
        this.stage_Header, {
        x   : 20,
        y   : 20,
        url : '/China1/icons/3d_Logo.svg'
      })

      this.stage_Header
      .append('text')
      .attr('transform', `translate(20,95)`)
      .attr('fill', '#000').attr('font-size', 15)
      .text('Commissioning StartUp Sequence - P3')


      this.stage_Header
      .append('line')
      .attr('x1', 0).attr('y1', 175)
      .attr('x2', 1601).attr('y2', 175)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .2)
      
      let Legends = this.stage_Header
      .append('g')
      .attr('id', 'Legends')
      .attr('transform', `translate(160,145)`)

      this.Legends.forEach((d,i)=> {
        Legends
        .append('rect')
        .attr('x', i*75).attr('y', 2)
        .attr('width', 7).attr('height', 10).attr('fill', d.color)

        this.draw_gmx_TextBox_Wrap_v1(
          Legends, 
          {
            text: d.title,
            width: 60,
            x: i*75 + 5,
            y: -5,
            id: {parents: '#Legends', group: `group_${i}`, rect: `rect_${i}`,},
            box: { visible: 'Y', color: '#fff', opacity: 1},
            padding: {left: 5, right: 5, top: 5, bottom: 5},
            font:{ color: '#000', size: 10,},
            border:{ color: '#fff', size: 0.1, rx: 0, ry: 0},
          },
          null
        )
      })

      
      // ---------------------------------------
      // Search Controller ---> Global Function
      // ---------------------------------------
      this.stage_Header
      let data__ = {
        x         : 20,
        y         : 110,
        width     : 200,
        height    : 57,
        label     : 'Search',
        labelWidth: 50,
        targets: [
          { text: 'System No /' },
          { text: 'Description'        },
        ],
        callFunc: this.call_Search, // --->  in Event.js
      }
      this.drawSearchToolFree(this.stage_Header, data__) //---> Global function

      // ---------------------------------------
      // pdf download button
      // ---------------------------------------
      let pdfdown = this.stage_Header
      .append('g')
      .attr('id', 'btn_wrap')
      .attr('transform', `translate(235,110)`).style('font-family', 'roboto')
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(100).style('opacity', 0.5)
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100).style('opacity', 1)
      })
      .on('click', () => {
        let request_ = this.getRequestColumnProps('single_file_download', null, this.ColumnProps)
        this.$emit('request-action', request_)
      })

      pdfdown
      .append('rect').attr('rx', 3).attr('x', 0).attr('y', 0)
      .attr('width', 90).attr('height', 22)
      .attr('fill','#44a9df')
      
      pdfdown
      .append('text')
      .attr('transform', `translate(${(45)}, ${12})`)
      .style('font-size', 12).style('fill','#ffffff').attr('text-anchor','middle').attr('alignment-baseline', 'middle')
      .text('EXPORT PDF')

      // ---------------------------------------
      // main svg
      // ---------------------------------------
      this.innerSvg = this.svg
      this.loadSvg(
        this.innerSvg, {
        x: 0,
        y: 170,
        url: '/China1/StartUp_Sequence/svg/CSU_P3_SVG.svg'
      }).then((svg) => {
        let el = this.innerSvg.select('#StartUp-Wrapper')

        let zoom_ = d3.zoom()
        .translateExtent([[0, 0], [4500, 1500]])
        .scaleExtent([0.6,  1.75])
        .extent([[0, 0], [1500, 1000]])
        .on('zoom', this.zoom)

        this.innerSvg = svg
        this.innerSvg.call(zoom_)

        svg.selectAll('text').classed('st4', false)
        this.stage_Header.moveToFront()

        // Call functions
        this.draw_Diagram()
      })
      
    },

   


  }
}
           