import * as d3 from 'd3'

export default {
  methods: {
    Activate_Hull() {

      this.CurrentArea = 'Hull'

      this.svgModeling = this.svg.append('svg')
      this.svgModeling
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 0)

      // Overall_Hull_Group
      let Overall_Hull_Group = this.svgModeling
      .append('g')
      .attr('id', 'Overall_Hull_Group')
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .call(this.Goto_Hull_SideBlocks) // --------------------------------------------------------------> Goto_Hull_SideBlocks
      
      // Draw the Module No. & Single Block No.
      this.draw_ModuleCode(this.svgModeling)
      this.draw_SingleBlockCode(this.svgModeling)

      // Draw the Block image & Codes
      this.MODULE.Hull.forEach(mod => {
        if (mod.type == 'Module') {

          // ### Module
          let ModuleGroup = Overall_Hull_Group
          .append('g')
          .attr('id', `${mod.module}`)
          .attr('transform', `translate(${mod.x1}, ${mod.y1})`)
          .attr('opacity', 1)
          .call(s => this.Goto_ModuleSummary(s, mod)) // --------------------------------------------------------------> Goto_ModuleSummary 
          
          // ### Module Blocks
          this.BLOCKS.Hull.filter(f => f.module == mod.module).forEach((d,i) => {
            ModuleGroup
            .append('image')
            .attr('id', `${d.blk}`)
            .attr('opacity', 1)
            .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/Images/Hull/${d.blk}.png`)
            .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.725})`)
            .call(s => {
              if (d.blk != 'PIPES') return this.Goto_Unit_Block(s, d) // --------------------------------------------------------------> Event 
            })
          })
          this.draw_BlockCode(ModuleGroup, mod.module)

        } else {
          // ### Single Blocks
          Overall_Hull_Group
          .append('image')
          .attr('id', `${mod.blk}`)
          .attr('opacity', 1)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/Images/Hull/${mod.blk}.png`)
          .attr('transform', `translate(${mod.x1}, ${mod.y1}) scale(${0.725})`)
          .style('cursor', 'pointer')
        }
      })

      this.svgModeling
      .transition()
      .delay(150)
      .duration(1000)
      .attr('width', Math.round(this.Canvas.CanvasWidth * 0.7))
      .attr('height', Math.round(this.Canvas.CanvasHeight * 0.7))
      .attr('x', 50).attr('y', 0)
      .attr('opacity', 1)

      // setTimeout(() => {
      //   this.mouseClickFlag = false
      // }, 1150);




      // HullMask
      this.svgModeling
      .append('path')
      .attr('id', 'HullMask')
      .attr('transform', `translate(340, -20) scale(1.25)`)
      .attr('d','M258.825,151.928l-57.292,44.858l-3.268,108.659l-59.481,49.485l-79.515-40.872L1.21,362.889v166.487l334.512,160.45l257.613-207.743V314.062l-76.9-34.989l-55.752,45.371l2.307,40.372l-127.269-65.364l-1.538-110.735L258.825,151.928z')
      .attr('fill', '#fff').style('opacity', 0)
      .style('visibility', 'hidden')
      .style('cursor', 'pointer')
      .call(s => this.Goto_Hull_ModuleDivision(s)) // --------------------------------------------------------------> Goto_Hull_ModuleDivision


      // Module_MenuSet
      let Module_Menu = this.svg
      .append('g')
      .attr('id', 'Module_MenuSet')
      .attr('transform', `translate(380, 480)`).style('opacity', 0).style('visibility', 'hidden')

      Module_Menu
      .append('text')
      .attr('id', `Mod_Name`)
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto').style('font-size', 16).style('font-weight', 700).style('fill', '#000').attr('text-anchor', 'start')
      .text('')

      Module_Menu
      .append('text')
      .attr('id', `Mod_Descr`)
      .attr('transform', `translate(0, 15)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#bcbcbc').attr('text-anchor', 'start')
      .text('')

      // button Menu_BlockDivision
      let Menu_BlockDivision = Module_Menu
      .append('g')
      .attr('id', 'Menu_BlockDivision')
      .attr('transform', `translate(20, 22)`)
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .call(s => this.Goto_BlockDivision(s)) // --------------------------------------------------------------> Goto Goto_BlockDivision
      

      Menu_BlockDivision
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', 85).style('height', 16).style('fill', '#44A9DF')

      Menu_BlockDivision
      .append('text')
      .attr('transform', `translate(42, 12)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').attr('text-anchor', 'middle')
      .text('Block Division')
    
    },
  }
}
           