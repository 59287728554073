import * as d3 from 'd3'

export default {
  data: () => ({
    Radians: 2 * Math.PI,
  }),

  computed: {
  },

  methods: {
    draw_RadarChart() {
      let allAxis = this.DataItems.map(d => d.title);
      let total = allAxis.length;
      let radarSize = this.Radar.chart.size
      let radius = radarSize / 2
      let angleSlice  = this.Radians / total
      
      //Circular segments
      let RadarChart = this.svg
      .append('g')
      .attr('transform', `translate(${this.Radar.chart.x - (radarSize/2)}, ${this.Radar.chart.y - (radarSize/2)})`)

      for(let j=0; j<this.Radar.level.no; j++) {
        
        let levelFactor = radius * ((j + 1) / this.Radar.level.no)

        if (this.ChartType == 'Polygon') {
          // polygon
          RadarChart
          .selectAll('.levels')
          .data(allAxis)
          .enter()
          .append('line')
          .attr('x1', (d, i) => levelFactor * (1 - Math.sin(i * this.Radians / total)))
          .attr('x2', (d, i) => levelFactor * (1 - Math.sin((i+1) *  this.Radians / total)))
          .attr('y1', (d, i) => levelFactor * (1 - Math.cos(i * this.Radians / total)))
          .attr('y2', (d, i) => levelFactor * (1 - Math.cos((i+1) * this.Radians / total)))
          .attr('stroke-width', () => {
            if (j == (this.Radar.level.no - 1)) return this.Radar.level.lastStWidth; else return this.Radar.level.stWidth
          })
          .attr('stroke', () => {
            if (j == (this.Radar.level.no - 1)) return this.Radar.level.lastStColor; else return this.Radar.level.stColor
          })
          .attr('opacity', () => {
            if (j == (this.Radar.level.no - 1)) return this.Radar.level.lastOpacity; else return this.Radar.level.opacity
          })
          .attr('transform', `translate(${(radarSize/2-levelFactor)}, ${(radarSize/2-levelFactor)})`)
        } else if (this.ChartType == 'Circular') {
          // polygon
          RadarChart
          .append('circle')
          .attr('cx', radarSize/2)
          .attr('cy', radarSize/2)
          .attr('r', levelFactor)
          .attr('fill', 'Transparent')
          .attr('stroke-width', () => {
            if (j == (this.Radar.level.no - 1)) return this.Radar.level.lastStWidth; else return this.Radar.level.stWidth
          })
          .attr('stroke', () => {
            if (j == (this.Radar.level.no - 1)) return this.Radar.level.lastStColor; else return this.Radar.level.stColor
          })
          .attr('opacity', () => {
            if (j == (this.Radar.level.no - 1)) return this.Radar.level.lastOpacity; else return this.Radar.level.opacity
          })
        }

      }

      
      //Level Text indicating at what % each level is
      for(let j=0; j<this.Radar.level.no; j++){
        let levelFactor = radius * ((j + 1) / this.Radar.level.no)

        RadarChart
        .append('text')
        .attr('transform', `translate(${radarSize / 2 - levelFactor + this.Radar.level.space}, ${radarSize/2-levelFactor})`)
        .attr('x', levelFactor * (1 - Math.sin(0)))
        .attr('y', levelFactor * (1 - Math.cos(0)))
        .attr('fill', this.Radar.level.tColor)
        .style('font-family', this.Radar.level.font)
        .attr('font-size', this.Radar.level.tSize)
        .text(`${(j+1)*100/this.Radar.level.no}%`)
      }


          // group of the axes in the Radar Chart 
          let Axes = RadarChart
          .selectAll('.axis')
          .data(allAxis)
          .enter()
          .append('g')
          .attr('class', 'axis')

          // draw lines for each axis
          Axes
          .append('line')
          .attr('x1', radarSize / 2)
          .attr('y1', radarSize / 2)
          .attr('x2', (d, i) => radarSize / 2 * (1 - Math.sin(i * this.Radians / total)))
          .attr('y2', (d, i) => radarSize / 2 * (1 - Math.cos(i * this.Radians / total)))
          .style('stroke', this.Radar.axes.stColor)
          .attr('stroke-width', this.Radar.axes.stWidth)
          .attr('opacity', this.Radar.axes.opacity)

          // put the title of the axis
          Axes
          .append('text')
          .attr('transform', 'translate(0, -10)')
          .attr('text-anchor', 'middle')
          .attr('dy', '1.5em')
          .attr('x', (d, i) => radarSize / 2 * (1 - this.Radar.series.factor * Math.sin(i * this.Radians / total)) - 60 * Math.sin(i * this.Radians / total))
          .attr('y', (d, i) => radarSize / 2 * (1 - Math.cos(i * this.Radians / total)) - 20 * Math.cos(i * this.Radians / total))
          .style('font-family', this.Radar.series.font)
          .attr('font-size', this.Radar.series.tSize)
          .attr('fill', this.Radar.series.tColor)
          .text(d => d)

          

      
      for(let n=1; n <= this.Radar.chart.data; n++) {
        let dataValues = JSON.parse(JSON.stringify(this.DataItems))

        //Scale for the radius
        let rScale = d3.scaleLinear()
        .domain([0, this.Radar.level.maxValue])
        .range([0, radius])

        //The radial line function
        let defaultRadial = d3.lineRadial()
        .angle((_, i) => (i * angleSlice) * -1)
        .radius(() => rScale(0))
        
        //The radial line function
        let lineRadial = d3.lineRadial()
        .angle((_, i) => (i * angleSlice) * -1)
        .radius(d => rScale(d[`value${n}`]))

        if (this.ChartType == 'Polygon') { 
          defaultRadial.curve(d3.curveCardinalClosed.tension(1))
          lineRadial.curve(d3.curveCardinalClosed.tension(1))
        } else if (this.ChartType == 'Circular') { 
          defaultRadial.curve(d3.curveCardinalClosed.tension(0))
          lineRadial.curve(d3.curveCardinalClosed.tension(0))
        }
        
        RadarChart
        .append('g')
        .attr('id', `${this.localId}__RadarArea_${n}`)
        .style('fill-opacity', this.Radar.area.opacity[n-1])
        .attr('transform', `translate(${radarSize/2}, ${radarSize/2})`)
        .on('mouseover', () => {
          RadarChart.style('cursor', 'pointer')
          d3.select(`#${this.localId}__RadarArea_${n}`).transition().duration(300).style('fill-opacity', 0.85)
          d3.select(`#${this.localId}__RadarValue_${n}`).transition().duration(300).attr('opacity', 1)
        })
        .on('mouseout', () => {
          RadarChart.style('cursor', 'default')
          d3.select(`#${this.localId}__RadarArea_${n}`).transition().duration(300).style('fill-opacity', this.Radar.area.opacity[n-1])
          d3.select(`#${this.localId}__RadarValue_${n}`).transition().duration(300).attr('opacity', () => { if(this.Radar.value.display == 'Y') return 1; else return 0 })
        })

        .append('path')
        .attr('d', defaultRadial(dataValues))
        .attr('stroke-width', this.Radar.area.stWidth[n-1])
        .attr('stroke', this.Radar.area.stColor[n-1])
        .attr('fill', this.Radar.area.fColor[n-1])
        .attr('fill', () => {
          if (this.Radar.area.fColor[n-1].substr(0,1) == '#' || this.Radar.area.fColor[n-1] == 'transparent') {
            return this.Radar.area.fColor[n-1]
          } else {
            return `url(#${this.localId}__LinearA9${this.Radar.area.fColor[n-1]}${n}0)`
          }
        })
        .transition().duration(700)
        .attr('d', lineRadial(dataValues))
        
        


        // Draw Node on area
        dataValues = []

        RadarChart
        .selectAll('.nodes')
        .data(this.DataItems, (d, i) => {
          dataValues.push([
            radarSize / 2 * (1 - (parseFloat(Math.max(d[`value${n}`], 0)) / this.Radar.level.maxValue) * Math.sin(i * this.Radians / total)), 
            radarSize / 2 * (1 - (parseFloat(Math.max(d[`value${n}`], 0)) / this.Radar.level.maxValue) * Math.cos(i * this.Radians / total))
          ])
        })

        RadarChart
        .selectAll('.circle')
        .data(dataValues)
        .enter()
        .append('circle')
        .attr('cx', radarSize/2)
        .attr('cy', radarSize/2)
        .attr('r', this.Radar.node.size[n-1])
        .attr('fill', this.Radar.node.fColor[n-1])
        .attr('stroke-width', this.Radar.node.stWidth[n-1])
        .attr('stroke', this.Radar.node.stColor[n-1])
        .attr('opacity', this.Radar.node.opacity[n-1])
        .transition().duration(700)
        .attr('cx', d => d[0])
        .attr('cy', d => d[1])

        // console.log(dataValues)
        let RadarValue = RadarChart
        .append('g')
        .attr('opacity', () => { if(this.Radar.value.display == 'Y') return 1; else return 0 })
        .attr('id', `${this.localId}__RadarValue_${n}`)

        RadarValue
        .selectAll('.text')
        .data(dataValues)
        .enter()
        .append('text')        
        .attr('x', radarSize/2)
        .attr('y', radarSize/2)
        .style('font-family', this.Radar.value.font)
        .attr('font-size', this.Radar.value.tSize)
        .attr('fill', this.Radar.value.tColor)
        .attr('text-anchor', 'middle')
        .text((d,i) => `${this.DataItems[i][`value${n}`].toFixed(1)}%`)
        .transition().duration(700)
        .attr('x', d => d[0])
        .attr('y', d => d[1] - 5)
      }
    },
  }
}
