import __C from '@/primitives/_constant_'
import LocalStorage from '@/services/local.storage/appservice.persistence'
import { ApplicationService, PageComponentService } from '@/services'
import { dispatch } from 'd3'
import saveAs from 'file-saver'

const _db = new LocalStorage()
const _appService = new ApplicationService()
const _pageCompService = new PageComponentService()
const _version = (v) => {
  if(!v) return '000000'
  return v.replace('v', '').split('.').map(v_ => v_.padStart(2, '0')).join('')
}

export default {
  namespaced: true,
  state: {
    // Instant values

    datagridID: Number,
    dataOrigianlID: Number,
    docID: String,
    iFilter: '',

    inputFilter: '',
    resultSet: {
      info: {},
      filterChains: [],
      filterOptions: [],
      chartValues: [],
      dataProps: [],
      dataList: [],
      pagination: { page: 1, rowsPerPage: 50 /* -1 for All */ },
      summaryData: [],
      tableAttrs: {},
      tableType: '',
    },

    /*** stored props in the local storage ***/
    info: {},
    configProps: [],
    chartFilter: {
      chartNo: '',
      qFilters: ''
    },
    // 'filteredValues' JS Data Structure
    // {
    //   COLUMN_NAME: "<String|Number|Array>",
    //   ...
    // }
    // Example:
    // {
    //   BLOCK:[],
    //   DEL_LEAD:["Rinat Bakirov","In-Tae Song","Zaigul Dossaliyeva"],
    //   MODULE:["42M073","44K156","43M104"],
    //   PE_BLOCK:["6HA","8QC","4HA","1UC"],
    //   RFS:"2020",
    //   SEALIFT:"2019",
    //   YARD:"DSM"
    // }
    filteredValues: {},
    /* ------------------------------------ */
  },
  mutations: {
    appendDataList(state, dataList) {
      if(!dataList || dataList.length === 0) return
      state.resultSet.dataList = [
        ...state.resultSet.dataList,
        ...dataList
      ]
    },
    setDatagridId (state, id) { state.datagridID = id },
    setDataOriginalId (state, id) { state.dataOrigianlID = id },
    setDataIFLTERS (state, iFilter) {state.iFilter = iFilter },
    setChartFilter (state, filter) { 
      if(state.chartFilter.chartNo == filter.chartNo) state.chartFilter = {
        chartNo: '',
        qFilters: ''
      }; else state.chartFilter = filter
    },
    setConfigProps (state, props) { state.configProps = props },
    setDocID (state, id) { state.docID = id },
    setFilteredValues (state, values) { state.filteredValues = values },
    setInfo (state, info) { state.info = info },
    setInputFilter (state, value) { state.inputFilter = value },
    setPageNumber (state, pageNum) { state.resultSet.pagination.page = pageNum },
    setPageRows (state, rows) { state.resultSet.pagination.rowsPerPage = rows },
    setPagination (state, values) { state.resultSet.pagination = values },
    setResultSet (state, result) { state.resultSet = result },
    setChartValues (state, values) { state.resultSet.chartValues = values },
    setDataList (state, dataList) { state.resultSet.dataList = dataList },
    setSummaryData (state, summaryData) { state.resultSet.summaryData = summaryData },
    setDataProps (state, dataProps) { state.resultSet.dataProps = dataProps },
    setTableAttrs (state, tableAttrs) { state.resultSet.tableAttrs = tableAttrs },
    setTableType (state, type) { state.resultSet.tableType = type },
  },
  getters: {
    dataOrigianlID: (state) => {
      if(state.dataOrigianlID == 0) return null
      return state.dataOrigianlID
    },
    filterChains: (state) => {
      if(!state.resultSet.filterChains || state.resultSet.filterChains.length == 0) return null
      return state.resultSet.filterChains
    },
    filterOptions: (state) => {
      if(!state.resultSet.filterOptions || state.resultSet.filterOptions.length == 0) return null
      return state.resultSet.filterOptions
    },
    filteredValues: (state) => {
      if(!state.filteredValues || Object.keys(state.filteredValues).length == 0) return null
      return state.filteredValues
    },
    filteredValuesTrimed: (getters) => {
      if(!getters.filteredValues) return null
        
      // hard cloning of the object
      let js = JSON.parse(JSON.stringify(getters.filteredValues))
      // remove empty element, becuase not accepted emtpy element to the database
      Object.keys(js).forEach(k => {
        if(!js[k] || (typeof js[k] == 'object' && Array.isArray(js[k]) && js[k].length === 0)) delete js[k]
      })

      if(Object.keys(js).length === 0) return null

      return js
    },
    configProps: (state) => {
      if(!state.configProps || state.configProps.length === 0) return null
      return state.configProps
    },
    pagination: (state) => state.resultSet.pagination,
    tableAttrs: (state) => ({
      style: state.datagridItem.styleAttrs || {},
      svg: state.datagridItem.svgAttrs || {},
    }),
  },
  actions: {
    initService({ rootState, commit }) {
      if(
        !rootState[__C.STORE_NAMESPACE.APPLICATION].navState || 
        !rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id
      ) {
        commit('setDatagridId', 0)
        return
      }
      
      let id___ = rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id
      if(!id___) return

      // id : nav-menu idx (item || subitem)
      let docID__ = `DATATABLE:PAGE:${id___}`
      commit('setDocID', docID__)

      return new Promise((resolve) => {
        _db.getInfo(docID__).then(info => {
          _db.getConfigProps(docID__).then(props => {
            _db.getChartFilter(docID__).then(filter => {
              _db.getFilteredValues(docID__).then(values => {
                commit('setDatagridId', id___)
                commit('setInfo', info)
                commit('setConfigProps', props)
                commit('setChartFilter', filter)
                commit('setFilteredValues', values)
                resolve(true)
              })
            })
          })
        })
      })
    },
    OriginalIdx({ commit }, idx_) {
      let type_ = 'DATATABLE'
      return new Promise((resolve) => {
        _pageCompService.getIdx(idx_, type_, idx => {
          if(!idx) {
            console.log(`[USER: #DEBUG] Cannot find Table IDX.`)
            resolve(null)
            return
          } else {
            commit('setDataOriginalId',idx)
          }
          resolve(idx)
        })
      })
   },
   
    requestDataCollectionIDG({ state, getters, commit, dispatch }, requestType) {
      // Request Type
      // - init : Request all parts of the data
      // - filtered : Request all parts of the data, except filtering options
      // - input : Request only datalist by search filter (input filter)
      // - page : Request next page's rows of the data
      
      if(!state.datagridID || state.datagridID < 1) 
        return new Promise((resolve) => { resolve(false) })

      // init page number 1
      if(requestType == 'init' || requestType == 'filtered' || requestType == 'input') commit('setPageNumber', 1)

      // fValues      Filtered values
      // {
      //   "COL1":["1","Rabbit","Mongoose"],
      //   "COL2":"3",
      //   "COL3":"Hello! Peter!"
      // }
      let info = (!state.info || Object.keys(state.info).length == 0) ? '' : JSON.stringify(state.info)
      let iFilter = JSON.stringify({
        chartFilter: state.chartFilter.qFilters,
        inputFilter: state.inputFilter
      })
      let fValues = getters.filteredValuesTrimed ? JSON.stringify(getters.filteredValuesTrimed) : ''
      let cProps = getters.configProps ? JSON.stringify(getters.configProps) : ''
      let pagination = JSON.stringify(state.resultSet.pagination)
 
      let initParameters = new URLSearchParams()
      initParameters.append('idx', state.datagridID)    // menu-item or sub-item's idx
      initParameters.append('catCode', __C.PAGE_COMPONENT.TYPE_PAGE)
      initParameters.append('info', info)
      initParameters.append('iFilter', iFilter)
      initParameters.append('fValues', fValues)
      initParameters.append('cProps', cProps)           // Configuring properties for the Intended DataGrid
      initParameters.append('pInfo', pagination)        // User updated pagenation info
      initParameters.append('reqType', requestType)

      console.log('[USER: #DEBUG# MENU-IDX]', state.datagridID);
      console.log('[USER: #DEBUG# PROPINFO]', info);
      console.log('[USER: #DEBUG# IFILTERS]', iFilter);
      console.log('[USER: #DEBUG# F-VALUES]', fValues);
      console.log('[USER: #DEBUG# JSONPROP]', cProps);
      console.log('[USER: #DEBUG# PAGINATE]', pagination);
      console.log('[USER: #DEBUG# REQ-TYPE]', requestType);

      dispatch('OriginalIdx', state.datagridID)
      commit('setDataIFLTERS', iFilter)
      // console.log('idx', state.datagridID)
      // console.log('catCode', __C.PAGE_COMPONENT.TYPE_PAGE)
      // console.log('info', info)
      // console.log('iFilter', iFilter)
      // console.log('fValues', fValues)
      // console.log('cProps', cProps)
      // console.log('pInfo', pagination)
      // console.log('reqType', requestType)

      return new Promise((resolve) => {
        _appService.requestDataCollectionIDG(
          initParameters,
          (resultSet) => {
            // responded data structure
            // {
            //   info: {
            //     version: v99.99.99,  // IDG data-source version number
            //   },
            //   filterChains: [
            //    [ ...filterOptions ],
            //    [ ...filterOptions ],
            //    ...
            //   ],
            //   filterOptions: [{      // LNB Filtering Options
            //     colName: String,     // Physical Column Name
            //     label: String,       // Displaying Column Name
            //     type: String,        // Component type
            //     options: Array       // Component Values
            //   }],
            //   chartValues: [         // SVG JSON Props and Aggregate values for the Charts 
            //     {
            //       chartNo: String,
            //       chartName: String,
            //       type: String,
            //       values: { ...<KEY: VALUE> },
            //       qFilters: String,  // Query Filters (SQL Script to be injected into WHERE clause.)
            //       jsonProps: { svg: { ...<SVG ATTRIBUTES> }}
            //     }
            //   ],
            //   dataProps: [],       // IDG Configuration values
            //   dataList: [],        // Intended DataGrid List
            //   pagination: {        // Pagination Info including the Total Rows
            //     page: Number,
            //     rowsPerPage: Number,
            //     totalRows: Number
            //   },      
            //   summaryData: [],     // Summary by the current filters
            //   titleData: [],       // Title Query Result
            //   tableAttrs: {        // Data-List Display Options
            //     title: {},
            //     table: {},
            //     header: {},
            //     body: {},
            //     group: {},
            //     style: {},
            //     svg: {},
            //   },      
            //   tableType: String,      
            // }

            // version controll
            if(!state.info || _version(state.info.version) < _version(resultSet.info.version)) {
              dispatch('updateInfo', resultSet.info).then(() => { 
                commit('setInfo', resultSet.info)

                if(state.configProps.length > 0) dispatch('removeConfigProps')
                if(Object.keys(state.filteredValues).length > 0) dispatch('removeFilteredValues')
                if(state.chartFilter.chartNo) dispatch('removeChartFilter')

                setTimeout(() => { 
                  commit('setResultSet', resultSet) 
                  resolve(true)
                }, 100)
              })

            } else {
              if(requestType == 'init') {
                if(getters.configProps) resultSet.dataProps = getters.configProps
                commit('setResultSet', resultSet)
                commit('setPagination', resultSet.pagination)

              } else if(requestType == 'filtered' || requestType == 'input') {
                commit('setChartValues', resultSet.chartValues)
                commit('setDataList', resultSet.dataList)
                commit('setSummaryData', resultSet.summaryData)
                commit('setPagination', resultSet.pagination)

              } else if(requestType == 'page') {
                commit('setDataList', resultSet.dataList)

              } else if(requestType == 'scroll')  {
                if(!resultSet.dataList || resultSet.dataList.length === 0) {
                  resolve(false)
                  return
                }
                commit('appendDataList', resultSet.dataList)
                
              } else if(requestType == 'full')  {
                resolve(resultSet.dataList)
                return
              }

              resolve(true)
            }
          }
        )
      })
    },
    sendRequest({ dispatch }, type='init') {
      // return dispatch(state.patronage ? 'requestPatDataCollectionIDG' : 'requestDataCollectionIDG', type)
      return dispatch('requestDataCollectionIDG', type)
    },
    updateInfo ({ state, commit }, info) { 
      return new Promise((resolve) => {
        _db.updateInfo(state.docID, info).then(res => {
          commit('setInfo', info)
          resolve(true)
        })
      })
    },
    updateConfigProps ({ state, commit }, props) { 
      return new Promise((resolve) => {
        _db.updateConfigProps(state.docID, props).then(() => {
          commit('setConfigProps', props)
          resolve(true)
        })
      })
    },
    updateChartFilter ({ state, commit }, filter) { 
      return new Promise((resolve) => {
        _db.updateChartFilter(state.docID, filter).then(() => {
          commit('setChartFilter', filter)
          resolve(true)
        })
      })
    },
    updateFilteredValues ({ state, commit }, values) { 
      return new Promise((resolve) => {
        _db.updateFilteredValues(state.docID, values).then(() => {
          commit('setFilteredValues', values)
          resolve(true)
        })
      })
    },
    removeConfigProps ({ state, commit }) { 
      return new Promise((resolve) => {
        _db.removeConfigProps(state.docID).then(() => {
          commit('setConfigProps', [])
          resolve(true)
        })
      })
    },
    removeChartFilter ({ state, commit }) { 
      return new Promise((resolve) => {
        _db.removeChartFilter(state.docID).then(() => {
          commit('setChartFilter', { chartNo: '', qFilters: '' })
          resolve(true)
        })
      })
    },
    removeFilteredValues ({ state, commit }) { 
      return new Promise((resolve) => {
        _db.removeFilteredValues(state.docID).then(() => {
          commit('setFilteredValues', {})
          resolve(true)
        })
      })
    },
    async GET_EXCEL ({ state }, payload) {
      try {
        const bufferArray = []
 
        const contentType  = await new Promise(async (resolve, reject) => {
          try {
            const response = await fetch(payload.url_, {
              method: 'get',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
            })
            const stream = response.body
            const reader = stream.getReader()
            reader.read().then(function processText ({done, value}) {
              if (done) {
                resolve({
                  contentType: response.headers.get('Content-Type'),
                })
                return
              }
              const buff = Buffer.from(value)
              bufferArray.push(buff)
              return reader.read().then(processText)
            })
          } catch (err) {
            reject(err)
          }
        })
        const buffer = Buffer.concat(bufferArray)
        const blob = new Blob([buffer], { type: contentType })
        saveAs(blob, `${payload.title}.xlsx`)
      } catch (err) {
        throw err
      }
    },
    async GET_EXCEL_POST ({ state }, payload) {
      try {
        const bufferArray = []
        let params = new FormData()
        params.append('items', JSON.stringify(payload.data.items)) 
        params.append('headers',JSON.stringify(payload.data.headers))
        params.append('level1', JSON.stringify(payload.data.level1))
        params.append('datasource', payload.data.datasource)
        params.append('key', payload.data.key)
        const contentType  = await new Promise(async (resolve, reject) => {
          try {
            const response = await fetch(payload.url_, {
              method: 'post',
              body : params,
            })
            const stream = response.body
            const reader = stream.getReader()
            reader.read().then(function processText ({done, value}) {
              if (done) {
                resolve({
                  contentType: response.headers.get('Content-Type'),
                })
                return
              }
              const buff = Buffer.from(value)
              bufferArray.push(buff)
              return reader.read().then(processText)
            })
          } catch (err) {
            reject(err)
          }
        })
        const buffer = Buffer.concat(bufferArray)
        const blob = new Blob([buffer], { type: contentType })
        saveAs(blob, `${payload.title}.xlsx`)
      } catch (err) {
        throw err
      }
    },
  }
}
