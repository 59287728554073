import JLibChartCustoms from '../../lib/jin/svgchartlib/01_Charts/00_Customs'
import JLibChartDonut from '../../lib/jin/svgchartlib/01_Charts/01_Donut'
import JLibChartPie from '../../lib/jin/svgchartlib/01_Charts/02_Pie'
import JLibChartBarAxis from '../../lib/jin/svgchartlib/01_Charts/03_Bar_Normal'
import JLibChartBarSolid from '../../lib/jin/svgchartlib/01_Charts/04_Bar_Solid'
import JLibChartBarProgress from '../../lib/jin/svgchartlib/01_Charts/05_Bar_Progress'
import JLibChartBarDelta from '../../lib/jin/svgchartlib/01_Charts/06_Bar_Delta'
import JLibChartHistogram from '../../lib/jin/svgchartlib/01_Charts/06_Histogram'
import JLibChartRadar from '../../lib/jin/svgchartlib/01_Charts/07_Radar'
import JLibChartSkyline from '../../lib/jin/svgchartlib/02_PageComponents/03_Skylines'
import JLibChartTableSummary from '../../lib/jin/svgchartlib/02_PageComponents/02_SummaryTable'
import JLibComponentSvg from '../../lib/jin/svgchartlib/02_PageComponents/00_Customs'

import JLibV2ChartCustoms from '../../lib/jin/svgchartlib-v2/export'

export default {
  ...JLibChartCustoms,
  ...JLibV2ChartCustoms,
  JLibChartDonut,
  JLibChartPie,
  JLibChartBarAxis,
  JLibChartBarSolid,
  JLibChartBarProgress,
  JLibChartBarDelta,
  JLibChartHistogram,
  JLibChartRadar,
  JLibChartSkyline,
  JLibChartTableSummary,
  ...JLibComponentSvg
}
