import * as d3 from 'd3'

export default {
  methods: {
    draw_Legends() {
      let styleAttrs_ = JSON.parse(JSON.stringify(this.style))
      let Legends = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.skyline.legend.x},${this.skyline.legend.y})`)

      // Background for whole the legend contents
      Legends
      .append('path')
      .attr('d', `
        M-10,-20
        L600,-20
        C900,350,350,350,-10,350
        L-10,350
        Z
      `)
      .attr('fill', '#fff')

      Legends
      .append('text')
      .attr('x', 0).attr('y', 0)
      .style('font-family', 'roboto').style('font-size', 24).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Skyline Chart for Mechanical Completion`)

      Legends
      .append('text')
      .attr('x', 0).attr('y', 18)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`The timeline schedule based on Mechanical Completion end date.`)

      Legends
      .append('text')
      .attr('x', 0).attr('y', 30)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.Queries.SQL1[0].DESC)
     
      styleAttrs_.forEach((style, i) => {
        Legends
        .append('text')
        .attr('x', 0)
        .attr('y', style.position.y)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.group.tSize)
        .style('fill', this.skyline.legend.group.tColot)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(style.group)

        style.position.y += this.skyline.legend.group.lineSpace

        style.item.forEach((item,j) => {
          let legendItem = Legends
          .datum(item)
          .append('g')
          .attr('id', `item_${i}_${j}`)
          .attr('transform', `translate(0,0)`)
          .style('cursor', 'pointer')
          .call(this.callFuncLegend)
          
          legendItem
          .append('rect')
          .attr('x', 10)
          .attr('y', style.position.y)
          .attr('width', 10)
          .attr('height', 10)
          .attr('fill', item.bColor)
          .attr('stroke', item.sColor)
          .attr('stroke-width', item.sWidth)

          legendItem
          .append('text')
          .attr('x', 23)
          .attr('y', style.position.y + 6)
          .style('font-family', this.skyline.legend.font)
          .style('font-size', this.skyline.legend.item.tSize)
          .style('fill', this.skyline.legend.item.tColor)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text(`${item.name} (${this.dataSet.filter(f => f[item.refColumn] == item.codeValue).length})`)

          if (j > 0) {
            style.position.x += this.getNodeElValue(`#item_${i}_${j-1}`, 'width') + this.skyline.legend.item.space
            d3.select(`#item_${i}_${j}`).attr('transform', `translate(${style.position.x},0)`)
          }
          
          if(item.on) this.setFilter('multi', item.refColumn, item.codeValue)
        })

        // let legendNotes = Legends
        // .append('g')
        // .attr('transform', `translate(${this.skyline.legend.notes.x},${this.skyline.legend.notes.y})`)

        // legendNotes
        // .append('text')
        // .attr('id', 'noteText')
        // .attr('x', 0).attr('y', 250)
        // .style('font-family', this.skyline.legend.font).style('font-size', this.skyline.legend.notes.tSize).style('fill', this.skyline.legend.notes.tColor)
        // .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        // .text(`The timeline schedule based on finish date.`)
      })

      // Legends
      // .append('line').attr('x1', 10).attr('x2', 30).attr('y1', 120).attr('y2', 120).attr('stroke-width', 1).attr('stroke', '#FF75A5')
      // Legends
      // .append('line').attr('x1', 130).attr('x2', 150).attr('y1', 120).attr('y2', 120).attr('stroke-width', 1).attr('stroke', '#bcbcbc')

      // Legends
      // .append('circle')
      // .attr('cx', 20).attr('cy', 120).attr('r', 3)
      // .style('stroke', '#FF75A5').style('stroke-width', 1.5).attr('stroke-opacity', 0.5).style('fill', '#fff')

      // Legends
      // .append('circle')
      // .attr('cx', 140).attr('cy', 120).attr('r', 3)
      // .style('stroke', '#bcbcbc').style('stroke-width', 1.5).attr('stroke-opacity', 0.5).style('fill', '#fff')

      // Legends
      // .append('text')
      // .attr('x', 35).attr('y', 120)
      // .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(`Plan Progress(%)`)

      // Legends
      // .append('text')
      // .attr('x', 155).attr('y', 120)
      // .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(`Actual Progress(%)`)
    
      this.putFilters()
      this.putSearch()
    },
    putSearch() {
      let searchArea_ = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(490, 220)`)

      searchArea_
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 181)
      .attr('height', 80)
      .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      searchArea_
      .append('rect')
      .attr('transform', `translate(10, -5)`)
      .attr('width', 55)
      .attr('height', 10)
      .attr('fill', '#fff')

      searchArea_
      .append('text')
      .attr('transform', `translate(20, 1)`)
      .style('font-family', this.skyline.legend.font)
      .style('font-size', this.skyline.legend.group.tSize)
      .style('fill', this.skyline.legend.group.tColot)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Search')

      searchArea_
      .append('rect')
      .attr('transform', `translate(15, 40)`)
      .attr('width', 150)
      .attr('height', 18)
      .attr('fill', '#fff')
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      let searchOptions_ = searchArea_
      .append('g')
      .attr('transform', `translate(20, 20)`)

      // ### draw search options (filtering target) in the search group box
      this.inputFilter.values.forEach((v_, i) => {
        searchOptions_
        .append('circle')
        .attr('class', `search_circle__${i}`)
        .attr('cx', v_.x)
        .attr('cy', 0)
        .attr('r', 3)
        .style('stroke', v_.on ? this.styleFilter.selected.stroke : this.styleFilter.out.stroke)
        .style('stroke-width', .5)
        .style('fill', v_.on ? this.styleFilter.selected.fill : this.styleFilter.out.fill)

        searchOptions_
        .append('text')
        .attr('class', `search_text__${i}`)
        .attr('transform', `translate(${v_.x + 7}, 1)`)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.item.tSize)
        .style('fill', v_.on ? this.styleFilter.selected.color : this.styleFilter.out.color)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(v_.text)

        searchOptions_
        .datum({ filter: this.inputFilter, ...v_})
        .append('rect')
        .attr('id', `search_mask__${i}`)
        .attr('transform', `translate(${v_.x - 5}, -8)`)
        .attr('width', v_.textWidth)
        .attr('height', 16)
        .attr('fill', '#000')
        .attr('fill-opacity', 0)
        .call(this.callFuncSearchFilter)

        // if(v_.on) this.setFilter(this.filterCodes[k_].attrs.type, v_.colName, v_.value)
      })
      
      // ### draw search input in the search group box
      searchArea_
      .append("foreignObject")
      .attr('x', 15)
      .attr('y', 40)
      .attr('width', 150)
      .attr('height', 18)
      .append('xhtml:div')
      .append('div')
      .attr('id', 'search_input')     // for the javascript document.getElementById
      .attr('class', 'search_input')  // for the d3 general purpose
      .attr('contentEditable', true)
      .attr('style', `padding: 2px 5px; font-size: 10px; line-height: 14px; text-align: left; color: ${this.skyline.legend.item.tColor}`)
      .call(this.callFuncSearchInput)

      // mask for the icon background area
      searchArea_
      .append('rect')
      .attr('transform', `translate(140, 41)`)
      .attr('width', 24)
      .attr('height', 16)
      .attr('fill', '#fff')

      searchArea_
      .append('image') 
      .attr('transform', `translate(148,43)`)
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/iconmonstr-magnifier-2.svg'))
      .attr('width', 12)
      .attr('height', 12)
      .style('cursor', 'pointer')
      .style('opacity', .3)
      .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      .on('click', () => { this.search() })
    },
    putFilters() {
      let filterArea_ = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.skyline.legend.x}, 220)`)

      Object.keys(this.filterCodes).forEach((k_, i) => {
        let filterItem_ = filterArea_
        .append('g')
        .attr('transform', `translate(${this.filterCodes[k_].attrs.x},${this.filterCodes[k_].attrs.y})`)

        filterItem_
        .append('rect')
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('width', this.filterCodes[k_].attrs.width)
        .attr('height', this.filterCodes[k_].attrs.height)
        .attr('fill-opacity', 0)
        .attr('stroke', '#dadada')
        .attr('stroke-width', .5)

        filterItem_
        .append('rect')
        .attr('transform', `translate(10, -5)`)
        .attr('width', this.filterCodes[k_].attrs.labelWidth)
        .attr('height', 10)
        .attr('fill', '#fff')

        filterItem_
        .append('text')
        .attr('transform', `translate(20, 1)`)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.group.tSize)
        .style('fill', this.skyline.legend.group.tColot)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(k_)

        let yGap_ = 20
        this.filterCodes[k_].values.forEach((v_, j) => {
          filterItem_
          .append('circle')
          .attr('class', `option_circle__${i}__${j}`)
          .attr('cx', 15)
          .attr('cy', yGap_ * (j+1))
          .attr('r', 3)
          .style('stroke', v_.on ? this.styleFilter.selected.stroke : this.styleFilter.out.stroke)
          .style('stroke-width', .5)
          .style('fill', v_.on ? this.styleFilter.selected.fill : this.styleFilter.out.fill)

          filterItem_
          .append('text')
          .attr('class', `option_text__${i}__${j}`)
          .attr('transform', `translate(23, ${yGap_ * (j+1) + 1})`)
          .style('font-family', this.skyline.legend.font)
          .style('font-size', this.skyline.legend.item.tSize)
          .style('fill', v_.on ? this.styleFilter.selected.color : this.styleFilter.out.color)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text(v_.text)

          filterItem_
          .datum({ filters: this.filterCodes, name: k_, type: this.filterCodes[k_].attrs.type, ...v_})
          .append('rect')
          .attr('id', `option_mask__${i}__${j}`)
          .attr('transform', `translate(10, ${yGap_ * (j+1) - 8})`)
          .attr('width', this.filterCodes[k_].attrs.textWidth)
          .attr('height', 16)
          .attr('fill', '#000')
          .attr('fill-opacity', 0)
          .call(this.callFuncFilter)

          if(v_.on) this.setFilter(this.filterCodes[k_].attrs.type, v_.colName, v_.value)
        })
      })
    },

    // Event Call Functions ---------------------------------------------
    callFuncSearchFilter(selection) {
      selection
      .on('mouseover', (_, i, a) => {
        d3.select(a[i]).style('cursor', 'pointer')

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.search_circle__${directionEls_[1]}`)
        .style('stroke', this.styleFilter.over.stroke)
        .style('fill', this.styleFilter.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${directionEls_[1]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => { 
        d3.select(a[i]).style('cursor', 'default')

        let index_ = d.filter.values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filter.values))
        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${directionEls_[1]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${directionEls_[1]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${directionEls_[1]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${directionEls_[1]}`)
          .style('fill', this.styleFilter.out.color)
        }
      })
      .on('click', (d, i, a) => {
        let index_ = d.filter.values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filter.values))
        let length_ = values_.length

        var prevIndex_ = d.filter.values.findIndex(v_ => v_.on)
        for(let i = 0; i<length_; i++) {
          if(values_[i].text == d.text) values_[i].on = true
          else values_[i].on = false
        } 

        d.filter.values = values_

        if(prevIndex_ != index_) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${prevIndex_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${prevIndex_}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${index_}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${index_}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.search_circle__${index_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.search_text__${index_}`)
          .style('fill', this.styleFilter.out.color)
        }

        d3.select(`#${this.localId}`).select('.search_input').text('')
        this.search(false)
        this.Chart_filtering()
      })
    },
    callFuncSearchInput(selection) {
      selection
      .on('keydown', (_, i, a) => {
        if(d3.event.keyCode === 13){
          this.trimDivText(d3.select(a[i]))   // declared in Declares.mixins
          setTimeout(() => { this.setCaret(document.getElementById('search_input')) })
        }
        this.register(this.search, 500)       // declared in Declares.mixins
      })
      .on('keyup', (_, i, a) => {
        if(d3.event.keyCode === 13){
          this.trimDivText(d3.select(a[i]))
          this.setCaret(document.getElementById('search_input'))
        }
      })
    },
    callFuncLegend(selection) {
      selection
      .on('click', (d, i, a) => {
        d.on = !d.on
        if(d.on) d3.select(a[i]).style('opacity', 1)
        else d3.select(a[i]).style('opacity', 0.3)

        this.setFilter('multi', d.refColumn, d.codeValue)
        this.search(false)
        this.Chart_filtering()
      })
    },
    callFuncFilter(selection) {
      selection
      .on('mouseover', (_, i, a) => {
        
        let index_ = _.filters[_.name].values.findIndex(v_ => v_.text == _.text)
        let values_ = JSON.parse(JSON.stringify(_.filters[_.name].values))

        if (values_[index_].disable) return

        d3.select(a[i]).style('cursor', 'pointer')

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
        .style('stroke', this.styleFilter.over.stroke)
        .style('fill', this.styleFilter.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => { 
        d3.select(a[i]).style('cursor', 'default')

        let index_ = d.filters[d.name].values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filters[d.name].values))
        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.out.color)
        }
      })
      .on('click', (d, i, a) => {
        let index_ = d.filters[d.name].values.findIndex(v_ => v_.text == d.text)
        let values_ = JSON.parse(JSON.stringify(d.filters[d.name].values))
        let length_ = values_.length

        if (values_[index_].disable) return

        if(d.type == 'single') {
          var prevIndex_ = d.filters[d.name].values.findIndex(v_ => v_.on)
          for(let i = 0; i<length_; i++) {
            if(values_[i].text == d.text) values_[i].on = true
            else values_[i].on = false
          } 
        } else {
          values_[index_].on = !values_[index_].on
        }

        d.filters[d.name].values = values_

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        if(prevIndex_ >= 0 && prevIndex_ != index_) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${prevIndex_}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${prevIndex_}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(values_[index_].on) {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.selected.stroke)
          .style('fill', this.styleFilter.selected.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.selected.color)
          
        } else {
          d3.select(`#${this.localId}`)
          .select(`.option_circle__${directionEls_[1]}__${directionEls_[2]}`)
          .style('stroke', this.styleFilter.out.stroke)
          .style('fill', this.styleFilter.out.fill)
  
          d3.select(`#${this.localId}`)
          .select(`.option_text__${directionEls_[1]}__${directionEls_[2]}`)
          .style('fill', this.styleFilter.out.color)
        }

        if(d.colName == 'DATE') {
          if(this.activity == d.value) return 

          this.activity = d.value
          this.activityChanged()

        } else {
          this.setFilter(d.type, d.colName, d.value)
          this.search(false)
          this.Chart_filtering()
        }
      })
    },
  }
}
