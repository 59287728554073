import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    
    Draw_Charts() {
      
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null,
          item = null

      let PunchABStatus = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

     


      // SummaryTable_03
      item = this.dataSet.find(f=> f.TYPE == 'A' && f.DISC == 'Overall')
      style = {
        id:       'PA',
        title:    ['A Total', 'Active'],
        cell:     [45, 90],
        x:        15,
        y:        40,
        y1:       10,
        y2:       23,
        width:    100,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.REMAIN],
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 193,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS,
              CAT: 'A'
            },
            iFilters: {
              filterString : "",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 193,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS,
              CAT: 'A'
            },
            iFilters: {
              filterString : "[COMPLETED_DATE] IS NOT NULL",
              inputFilter  : ''
            }
          }
        ]
      }
      this.SummaryTable_03(PunchABStatus, style) // ---------------------------> 



      // SummaryTable_03
      item = this.dataSet.find(f=> f.TYPE == 'B' && f.DISC == 'Overall')
      style = {
        id:       'PB',
        title:    ['B Total', 'Active'],
        cell:     [45, 90],
        x:        155,
        y:        40,
        y1:       10,
        y2:       23,
        width:    100,
        height:   32,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [item.TOTAL, item.REMAIN],
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 193,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS,
              CAT: 'B'
            },
            iFilters: {
              filterString : "",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 193,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS,
              CAT: 'B'
            },
            iFilters: {
              filterString : "[COMPLETED_DATE] IS NOT NULL",
              inputFilter  : ''
            }
          }
        ]
      }
      this.SummaryTable_03(PunchABStatus, style) // ---------------------------> 



      // Bar_Punch_03
      data = this.dataSet.filter(f=> f.TYPE == 'A' && f.DISC !== 'Overall')
      style = {
        id:       'PA',
        x:        10,
        y:        80,
        cell:     [30, 40, 0, 0],
        bColor:   '#309CD5', 
        sColor:   '#44A9DF', 
        tColor1:  '#fff', 
        tColor2:  '#757575', 
        opacity:  1,
        length:   75,
        lineHeight: 16,
        min: 25,
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 193,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS,
              CAT: 'A'
            },
            iFilters: {
              filterString : "[COMPLETED_DATE] IS NOT NULL",
              inputFilter  : ''
            }
          },
        ]
      }
      this.Bar_Punch_03(PunchABStatus, data, style) // ---------------------------> 


      // Bar_Punch_03
      data = this.dataSet.filter(f=> f.TYPE == 'B' && f.DISC !== 'Overall')
      style = {
        id:       'PB',
        x:        150,
        y:        80,
        cell:     [30, 40, 0, 0],
        bColor:   '#83D2F5', 
        sColor:   '#44A9DF', 
        tColor1:  '#fff', 
        tColor2:  '#757575', 
        opacity:  1,
        length:   75,
        lineHeight: 16,
        min: 25,
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 193,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS,
              CAT: 'B'
            },
            iFilters: {
              filterString : "[COMPLETED_DATE] IS NOT NULL",
              inputFilter  : ''
            }
          },
        ]
      }
      this.Bar_Punch_03(PunchABStatus, data, style) // ---------------------------> 


      
    },
  }
}
           