import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    draw_Construction() {

      let Discipline = ['STR','PNT','MEC','PIP','HVC','ENI','ARC','INU',]

      this.dataSet.forEach((data,i) => {
        let pointY = 10
        let disciplineGroup = this.chartGroup
        .append('g')
        .attr('transform', 'translate(32, 13)')

        Discipline.forEach((_, j) => {
          if (!data[`${Discipline[j]}_M_PP`]) return
          
          let disciplineCharts = disciplineGroup
          .append('g')
          .attr('transform', 'translate(0, 0)')

          .on('mouseover', () => {
            disciplineCharts.style('cursor', 'pointer')
            d3.select(`#disc${i}_${j}`)
            .transition().duration(400).attr('fill', '#E0E0DF')
            .transition().duration(400).attr('fill', '#333')
            
            d3.select(`#planBar${i}_${j}`)
            .transition().duration(400).attr('width', 0)
            .transition().duration(400).attr('width', data[`${Discipline[j]}_M_PP`])

            d3.select(`#actualBar${i}_${j}`)
            .transition().duration(400).attr('width', 0)
            .transition().duration(400).attr('width', data[`${Discipline[j]}_A_PP`])
          })
          .on('mouseout', () => { 
            disciplineCharts.style('cursor', 'pointer')
            .style('cursor', 'default')
          })
          .on('click', () => { 
            console.log(this.dataSet[i].MOD, Discipline[j])
          })

          disciplineCharts
          .append('text')
          .attr('id',`disc${i}_${j}`)
          .attr('transform', d => `translate(${data.x - 2}, ${data.y + pointY - 1})`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
          .text(Discipline[j])

          disciplineCharts
          .append('rect')
          .attr('id', `planBar${i}_${j}`)
          .attr('rx', 0).attr('ry', 0)
          .attr('x', data.x).attr('y', data.y + pointY)
          .attr('width', 0).attr('height', 5).attr('fill', '#E0E0DF')
          .transition()
          .duration(500)
          .attr('width', data[`${Discipline[j]}_M_PP`])
        
          disciplineCharts
          .append('rect')
          .attr('id', `actualBar${i}_${j}`)
          .attr('rx', 0).attr('ry', 0)
          .attr('x', data.x).attr('y', data.y + pointY)
          .attr('width', 0).attr('height', 5)
          .attr('opacity', 0.7)
          .attr('fill', d => {
            let plan = data[`${Discipline[j]}_M_PP`]
            let actual = data[`${Discipline[j]}_A_PP`]
            let color =''
    
            if (plan < actual) color = '#83D2F5'
            if (plan >= actual) color = '#F7BACF'
            if (actual == 100) color = '#CDDC37'
            return color
          })
          .transition()
          .duration(500)
          .attr('width', data[`${Discipline[j]}_A_PP`])

          pointY += 13
        })

      })
    },



  }
}