import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {
      
      let data = null, 
          style = null, 
          cellX = null, 
          tmp = null, 
          Circle = null, 
          Guideline = null,
          item = null

      let systemsummary = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      // Header_01
      data  = this.Queries.SQL1[0]
      style = {
        title: 'Mechanical Completion',
        x: 30,
        y: 70,
        width: 143,
        height: 25,
        radius: 3,
        tSize: 12,
        tColor: '#333',
        bColor: '#E0E0DF',
        progSize: 15,
      }
      this.Header_01( systemsummary, data.PROG, style) // ---------------------------> 



      // SummaryTable_01
      data  = this.Queries.SQL1[0]
      style = {
        id:       'AITR',
        title:    ['Total', 'Completed', 'Remain'],
        cell:     [50, 110, 160],
        x:        30,
        y:        100,
        y1:       10,
        y2:       26,
        width:    170,
        height:   35,
        radius:   3,
        opacity:  1,
        bColor:   '#83D2F5',
        tSize:    9,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        value:    [data.TOTAL, data.ACTUAL, data.REMAIN],
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 104,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS
            },
            iFilters: {
              filterString : "[STAGE] = 'A'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 104,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS
            },
            iFilters: {
              filterString : "[STAGE] = 'A' AND [STATUS] = 'Y'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 104,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS
            },
            iFilters: {
              filterString : "[STAGE] = 'A' AND [STATUS] = 'N'",
              inputFilter  : ''
            }
          },
        ]
      }
      this.SummaryTable_01(systemsummary, style) // ---------------------------> 


      // SummaryTable_02
      data  = this.Queries.SQL2
      style = {
        id:       'AITR',
        title:    ['Punch', 'Raised', 'Closed', 'Opened'],
        cell:     [40, 90, 140, 190],
        x:        30,
        y:        170,
        y1:       12,
        cellY:    [28, 43],
        width:    210,
        height:   53,
        radius:   3,
        opacity:  0.7,
        bColor:   '#83D2F5',
        tSize:    10,
        tColor:   '#757575',
        vSize:    11,
        vColor:   '#fff',
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 105,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS
            },
            iFilters: {
              filterString : "",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 105,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS
            },
            iFilters: {
              filterString : "[COMPLETED_DATE] IS NOT NULL",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 105,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.FilterValues.SYS
            },
            iFilters: {
              filterString : "[COMPLETED_DATE] IS NULL",
              inputFilter  : ''
            }
          },
        ]
      }
      this.SummaryTable_02(systemsummary, data, style) // ---------------------------> 


      let systemInfo = this.svg
      .append('g')
      .attr('transform', `translate(270.5, 40.5)`)

      let drawY = 0
      this.dataSet.forEach((d,i) => {
        if(i>5) return
        systemInfo // CAT
        .append('text')
        .attr('transform', `translate(${150}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#757575').style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title)

        systemInfo // CAT
        .append('text')
        .attr('transform', `translate(${160}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#333').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(d.value)

        if (i==1 || i ==3) drawY += 28; else drawY += 18
      })

      drawY = 140
      this.dataSet.forEach((d,i) => {
        if(i == 6 || i == 8 || i == 11) {
        systemInfo
        .append('text')
        .attr('transform', `translate(${150}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#757575').style('text-anchor', 'end').style('alignment-baseline', 'middle').text(d.title)

        systemInfo
        .append('text')
        .attr('transform', `translate(${180}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10)
        .style('fill', '#bcbcbc').style('text-anchor', 'end').style('alignment-baseline', 'middle').text('Plan')

        systemInfo
        .append('rect')
        .attr('transform', `translate(315, ${drawY-9})`)
        .style('width', 65).style('height', 15).style('fill', '#E0E0DF').attr('rx', 0).attr('ry', 0).style('opacity', 0.3)

        systemInfo
        .append('text')
        .attr('transform', `translate(${310}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10)
        .style('fill', '#bcbcbc').style('text-anchor', 'end').style('alignment-baseline', 'middle')
        .text(() => { if(i == 8) return 'Forecast'; else return 'Actual'})
        
        systemInfo
        .append('rect')
        .attr('transform', `translate(185, ${drawY-9})`)
        .style('width', 65).style('height', 16).style('fill', '#E0E0DF').attr('rx', 0).attr('ry', 0).style('opacity', 0.3)


        systemInfo
        .append('text')
        .attr('transform', `translate(${320}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#44A9DF').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(this.dataSet[i+1].value)

        if(i == 8) {
          systemInfo
          .append('text')
          .attr('transform', `translate(${430}, ${drawY})`)
          .style('font-family', 'roboto').style('font-size', 10)
          .style('fill', '#bcbcbc').style('text-anchor', 'end').style('alignment-baseline', 'middle')
          .text(() => { if(i == 8) return 'Actual'})
          
          systemInfo
          .append('rect')
          .attr('transform', `translate(435, ${drawY-9})`)
          .style('width', 65).style('height', 16).style('fill', '#E0E0DF').attr('rx', 0).attr('ry', 0).style('opacity', 0.3)

          systemInfo
          .append('text')
          .attr('transform', `translate(${440}, ${drawY})`)
          .style('font-family', 'roboto').style('font-size', 11)
          .style('fill', '#44A9DF').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(this.dataSet[i+2].value)
        }


        systemInfo 
        .append('text')
        .attr('transform', `translate(${190}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 11)
        .style('fill', '#44A9DF').style('text-anchor', 'start').style('alignment-baseline', 'middle').text(d.value)

        if (i==1 || i ==3) drawY += 28; else drawY += 23
        }
      })
      







    }, 
  }
}
           