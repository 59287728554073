import * as d3 from 'd3'

export default {
  data: () => ({

  }),

  methods: {

    draw_Charts() {

      let self = this
      let BoxesChart = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX},0)`)

     
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
        this.parentNode.appendChild(this);
        });
      }
      // console.log(this.timeline.week)



      this.timeline.week.forEach( (w,i) => {

        let drawY = this.timeline.baseY - this.skyline.box.height - (this.skyline.box.gap*2)

        this.dataSet.filter(f => f.cDate == w.eDate).forEach(d => {

          let x1 = this.timeline.scale(d.sDate) + this.skyline.box.gap
          let y1 = drawY

          let Boxes = BoxesChart
          .append('g')
          .attr('id', `${d.name}`)
          .attr('transform', `translate(${x1},${y1})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            d3.select(this).moveToFront().transition().duration(100)
            .attr('transform', `translate(${x1-(self.skyline.box.width/4)},${y1-(self.skyline.box.height/4)}) scale(1.5)`)
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(100)
            .attr('transform', `translate(${x1},${y1}) scale(1)`)
          })
          .on('click', () => {
            let request_ = {
              dataType: 'row',    
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDashboard',
                id: 7,
                no: ''
              },
              filters: { 
                
                ...this.FilterValues,
                ...this.inFilters,
                BLOCK: d.name,
              },
              iFilters: {}
            }
            this.$emit('request-action', request_)
          })

          Boxes
          .append('rect') // the background color should be white(#fff)
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', this.skyline.box.width)
          .attr('height', this.skyline.box.height)
          .attr('fill', '#fff')
          .attr('stroke', 'Transparent')
          .attr('stroke-width', d.sWidth)

          Boxes
          .append('rect') // Fill Color
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', () => {
            if (d.progress == 'Y') return this.skyline.box.width * (d.PRO/100); else return this.skyline.box.width
          })
          .attr('height', this.skyline.box.height)
          .attr('fill', d.bColor)
          .attr('stroke', 'Transparent')
          .attr('stroke-width', d.sWidth)

          Boxes
          .append('rect') // Stroke
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', this.skyline.box.width)
          .attr('height', this.skyline.box.height)
          .attr('fill', 'Transparent')
          .attr('stroke', d.sColor)
          .attr('stroke-width', d.sWidth)

          Boxes
          .append('text')
          .attr('x', this.timeline.weekSize/2 - 1)
          .attr('y', this.skyline.box.height/2 + 1)
          .attr('font-family', this.skyline.box.font)
          .attr('font-size', d.tSize)
          .attr('fill', d.tColor)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .text(d.name)

          drawY = drawY - this.skyline.box.height - (this.skyline.box.gap*2)
        })
      })




    }, 

  }
}
