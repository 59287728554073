import * as d3 from 'd3'

export default {
  props: {
    zoomScaleExtent: {
      type: Array,
      default: () => ([0.5, 2])
    },
    zoomable: Boolean
  },
  data: () => ({
    zoopDelta: {
      w: 0,
      h: 0,
      x: 0,
      y: 0,
    },
    el2Zoom: null,
    el2ZoomDrag: null,
    setCursorMovable: null,

    zoomScale: null,
    zoomTransformInit: {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
    zoomTransform: {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    },
  }),
  created() {

    // Note.
    // "el2Drag" and "setCursorMovable" sould be defined as a data variable.
    // If they are defined as a method, it is not working on d3.drag features.
    // - Need to be considered as a computed method, but not verified yet.

    this.el2Zoom = this.zoomable ?
      d3.zoom()
        .scaleExtent(this.zoomScaleExtent)
        .on('zoom', this.zoom) : 
      () => { }

    this.setCursorZoomable = this.zoomable ?
      (selection) => {
        selection
          .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'zoom-in') })
          .on('mouseout', (_, i, a) => { d3.select(a[i]).style('cursor', 'default') })
      } :
      () => { }
  },
  methods: {
    // By Wheel Action
    zoom(_, i, a) {
      let transform_ = d3.event.transform
      let scale_ = transform_.k
      // this.zoomTransform = {
      //   x: -transform_.x / scale_,
      //   y: -transform_.y / scale_,
      //   w: this.zoomTransformInit.w / scale_,
      //   h: this.zoomTransformInit.h / scale_
      // }
      // d3.select(a[i]).attr("viewBox", Object.values(this.zoomTransform).join(' '))
      this.zoomTransform = {
        x: 0,
        y: 0,
        w: this.zoomTransformInit.w / scale_,
        h: this.zoomTransformInit.h / scale_
      }
      this.$emit('zoomed', {
        CanvasChartWidth: this.zoomTransform.w,
        CanvasChartHeight: this.zoomTransform.h
      })
      d3.select(a[i]).attr("viewBox", Object.values(this.zoomTransform).join(' '))
    },
  }
}
