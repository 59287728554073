import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    loadSvg(path) {
      return d3.xml(path).then(source => {
        if(this.innerSvg) {
          this.innerSvg.html()
          this.innerSvg.remove()
          this.innerSvg = null
        }

        this.innerSvg = source.documentElement
        this.svg.node().append(this.innerSvg)

        this.innerSvg = d3.select(this.innerSvg)
        this.innerSvg
        .attr('x',0).attr('y',0)
        .attr('width',1200).attr('height',850)
        .attr('opacity', 0)
        .transition()
        .duration(500)
        .attr('opacity', 1)
        // .delay(200)

        return new Promise(resolve => {
          resolve('test')
        })
      })
    },

    
    Draw_ISO(mod) {
      let self_ = this

      // KeyPlan
      // draw the KeyPlan from svg file
      d3.xml(`${this.__HOST_NAME_RESOURCE}/TCO/Equipment/${mod}/${mod}_ISO.svg`).then(source => {
        let svgISO = source.documentElement
        this.svg.node().append(svgISO)
        svgISO = d3.select(svgISO).attr('x',15).attr('y',280)
        
        
        // this.ISOSvg = d3.select(svgISO)
        // console.log(this)
        let maskLayer = svgISO.select(`#MASK`)

        maskLayer.selectAll('g').each((_, i, g) => {

          let setAction_ = (selection) => {
            selection
              .on('mouseover', (_, i, a) => { 
                d3.select(a[i])
                .style('cursor', 'pointer')
                .transition().duration(200).style('opacity', 0.7)
              })
              .on('mouseout', (_, i, a) => { 
                d3.select(a[i])
                .style('cursor', 'default')
                .transition().duration(100).style('opacity', 0)
              })
              .on('click', () => { this.PAU = name; self_.reforming(name) })
          }
  
          let elMask = d3.select(d3.select(g[i]).node().children[1]) // Polygon ,rect, path, circle, ellipse
          let elText = d3.select(g[i]).select('text')
          let name = elText.text()
  
          elText.style('opacity', 0.0)
          elMask.style('opacity', 0.0).attr('fill','#fff').call(setAction_)
        })

      })
    },
    

    Draw_BarChart() {
      // Module No. & Title
      let x = -85
      let y = (i) => -55 + (15*i)
      let progressChart = this.svg
      .append('g')
      .attr('transform', 'translate(200, 150.5)')
      .selectAll('g')
      .data(this.ChartData)
      .enter()

      progressChart
        .append('text')
        .attr('transform', (draw, i) => `translate(${x + 0}, ${y(i)})`)
        .style('font-family', 'roboto').style('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
        .text(d => d.title)
      
      progressChart
        .append('rect')
        .attr('x', x + 5).attr('y', (d, i) => y(i))
        .attr('width', 100).attr('height', 10)
        .attr('fill', '#F4F5F5')

      progressChart
        .append('rect')
        .attr('x', x + 5).attr('y', (d, i) => y(i))
        .attr('width', 0).attr('height', 10)
        .attr('fill', d => this.Status.find(f => f.type == d.type).bColor)
        .transition()
        .duration(500)
        .attr('width', d => (d.actual/d.total)*100 )

      progressChart
        .append('text')
        .attr('id', (d, i) => `act_${i}`)
        .attr('transform', (d, i) => `translate(${x + 3}, ${y(i)})`)
        .style('font-family', 'roboto').style('font-size', 10).attr('fill', '#fff').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
        .text(d => d.actual)
        .transition()
        .duration(500)
        .attr('transform', (d, i) => `translate(${x + (d.actual/d.total)*100 + 3}, ${y(i)})`)

      progressChart
        .append('text')
        .attr('transform', (d, i) => `translate(${x + 107}, ${y(i)})`)
        .style('font-family', 'roboto').style('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text(d => d.total)
    },



    Draw_Forms() {
      this.svg
      .append('text')
      .attr('x', 1280).attr('y', 885)
      .style('font-family', 'roboto').style('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'end')
      .text('If you put your mouse cursor on the number, a description box of each equipment will pop up, and the detail information will be shown on the right when you click it.')

      
      // Legends
      // draw the legends from svg file
      d3.xml(`${this.__HOST_NAME_RESOURCE}/TCO/Equipment/forms/Legends.svg`).then(source => {
        let svgLegends = source.documentElement
        this.svg.node().append(svgLegends)
        d3.select(svgLegends).attr('x',1180).attr('y',710)
      })  
    },

    


  }
}
           