import * as d3 from 'd3'
import { entries } from 'd3'

export default {
  data: () => ({
    scaleY: null,
  }),

  methods: {

    draw_LineCharts() {

      if (this.skyline.line.display != 'Y') return

      this.scaleY = d3
          .scaleLinear()
          .domain([0, 100])
          .range([this.skyline.minSize, this.timeline.baseY - this.skyline.line.height])

      this.lineZero = d3.line()
          .x(d => this.timeline.scale(d.eDate))
          .y(this.scaleY(0))

      this.lineFunc = d3.line()
          .x(d => this.timeline.scale(d.eDate))
          .y(d => this.scaleY(d.value))


      let LineChart = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX},0)`)

      // PLAN
      LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.timeline.plan))
      .style('stroke', '#bcbcbc').style('stroke-width', 1.5).attr('opacity', .5)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.plan))

      LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.plan)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#bcbcbc').style('stroke-width', 1.5).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))

      // Actual
      LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.timeline.actual))
      .style('stroke', '#FF75A5').style('stroke-width', 1.5).attr('opacity', .5)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.timeline.actual))

      LineChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll('circle')
      .data(this.timeline.actual)
      .enter()

      .append('circle')
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(0))
      .attr('r', 3)
      .style('stroke', '#FF75A5').style('stroke-width', 1.5).attr('stroke-opacity', 0.5)
      .style('fill', '#fff')
      .transition()
      .duration(500)
      .attr('cx', d => this.timeline.scale(d.eDate))
      .attr('cy', d => this.scaleY(d.value))
    }, 

  }
}
