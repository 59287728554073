import * as d3 from 'd3'

export default {
  data: () => ({

  }),

  methods: {

    draw_Legends() {
      let style= null, data = null

      let Legends = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.skyline.legend.x},${this.skyline.legend.y})`)

      Legends
      .append('text')
      .attr('x', -15).attr('y', 0)
      .style('font-family', 'roboto').style('font-size', 24).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Skyline Chart for Commissioning`)

      Legends
      .append('text')
      .attr('x', -15).attr('y', 18)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`The timeline schedule based on commissioning end date.`)

      Legends
      .append('text')
      .attr('x', -15).attr('y', 30)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(this.Queries.SQL1[0].DESC)
     

      let itemY = 80
      
      this.style.forEach((style,i) => {
        let itemX = 0
        Legends
        .append('text')
        .attr('x', 0)
        .attr('y', itemY)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.group.tSize)
        .style('fill', this.skyline.legend.group.tColot)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(style.group)

        itemY += this.skyline.legend.group.lineSpace

        style.item.forEach((item,j) => {

          let legendItem = Legends
          .append('g')
          .attr('id', `Legend_${i}_${j}`)
          .attr('transform', `translate(0,0)`)
          .style('opacity', 1)
          .style('cursor', 'pointer')
          .on('click', () => { 
            this.Chart_filtering(i, j, item.refColumn, item.codeValue)
          })

          legendItem
          .append('rect')
          .attr('x', 10)
          .attr('y', itemY)
          .attr('width', 10)
          .attr('height', 10)
          .attr('fill', item.bColor)
          .attr('stroke', item.sColor)
          .attr('stroke-width', item.sWidth)

          legendItem
          .append('text')
          .attr('x', 23)
          .attr('y', itemY + 6)
          .style('font-family', this.skyline.legend.font)
          .style('font-size', this.skyline.legend.item.tSize)
          .style('fill', this.skyline.legend.item.tColor)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text(`${item.name} (${this.dataSet.filter(f => f.COMM_DATE !== null && f[item.refColumn] == item.codeValue).length})`)

          if (j > 0) {
            itemX += this.getNodeElValue(`#Legend_${i}_${j-1}`, 'width') + this.skyline.legend.item.space
            d3.select(`#Legend_${i}_${j}`).attr('transform', `translate(${itemX},0)`)
          }
        })
        itemY += 40
      })


      // Legend Plan Line Chart ------------------------------------------------------------------------------------------------------------------
      let lineChart = Legends
      .append('g')
      .attr('transform', `translate(${this.skyline.legend.charts.x},${this.skyline.legend.charts.y})`)

      Legends
      .append('text')
      .attr('x', 0)
      .attr('y', itemY)
      .style('font-family', this.skyline.legend.font)
      .style('font-size', this.skyline.legend.group.tSize)
      .style('fill', this.skyline.legend.group.tColot)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Line Chart(ITR B)')

      let LegendPlanLine = lineChart
      .append('g')
      .attr('id', 'LegendPlanChart')
      .attr('transform', `translate(0, 0)`)
      .style('cursor', 'pointer')
      .on('click', () => { 
        if (this.ChartPlan == false) {
          d3.select('#LegendPlanChart').style('opacity', 0.3)
          d3.select('#LineChartPlan').style('opacity', 0)
          this.ChartPlan = true
        } else {
          d3.select('#LegendPlanChart').style('opacity', 1)
          d3.select('#LineChartPlan').style('opacity', 1)
          this.ChartPlan = false
        }
      })

      LegendPlanLine
      .append('line')
      .attr('x1', 0).attr('x2', 20).attr('y1', 18).attr('y2', 18)
      .attr('stroke-width', 1).attr('stroke', '#bcbcbc')

      LegendPlanLine
      .append('circle')
      .attr('cx', 10).attr('cy', 18).attr('r', 3)
      .style('stroke', '#bcbcbc').style('stroke-width', 1.5).attr('stroke-opacity', 0.5).style('fill', '#fff')

      LegendPlanLine
      .append('text')
      .attr('x', 25).attr('y', 18)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Plan Progress`)



      // Legend Actual Line Chart ------------------------------------------------------------------------------------------------------------------
      let LegendActLine = lineChart
      .append('g')
      .attr('id', 'LegendActChart')
      .attr('transform', `translate(0, 0)`)
      .style('cursor', 'pointer')
      .on('click', () => { 
        if (this.ChartPlanLine == false) {
          d3.select('#LegendActChart').style('opacity', 0.3)
          d3.select('#LineChartActual').style('opacity',0)
          this.ChartPlanLine = true
        } else {
          d3.select('#LegendActChart').style('opacity', 1)
          d3.select('#LineChartActual').style('opacity', 1)
          this.ChartPlanLine = false
        }
      })

      LegendActLine
      .append('line')
      .attr('x1', 120).attr('x2', 140).attr('y1', 18).attr('y2', 18)
      .attr('stroke-width', 1).attr('stroke', '#FF75A5')

      LegendActLine
      .append('circle')
      .attr('cx', 130).attr('cy', 18).attr('r', 3)
      .style('stroke', '#FF75A5').style('stroke-width', 1.5).attr('stroke-opacity', 0.5).style('fill', '#fff')

  
      LegendActLine
      .append('text')
      .attr('id', 'LegendActValue')
      .attr('x', 145).attr('y', 18)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`Actual Progress`)

      Legends
      .append('text')
      .attr('transform', `translate(10, 230)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'start')
      .text('Chart Value')

      Legends
      .append('text')
      .attr('id', 'valueQuantity')
      .attr('transform', `translate(20, 250)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start')
      .text('Quantity')
      .style('opacity', () => { if (this.ChartValue == 'quantity') return 1; else return 0.5 })
      .style('cursor', 'pointer')
      .on('click', () => { 
        this.ChartValue == 'quantity'
        d3.select('#valueQuantity').style('opacity', 1)
        d3.select('#valueProgress').style('opacity', 0.5)
        this.changeChartValue('qty', '', 0)
      })

      Legends
      .append('text')
      .attr('id', 'valueProgress')
      .attr('transform', `translate(70, 250)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start')
      .text('Progress(%)')
      .style('opacity', () => { if (this.ChartValue == 'progress') return 1; else return 0.5 })
      .style('cursor', 'pointer')
      .on('click', () => { 
        this.ChartValue == 'progress'
        d3.select('#valueQuantity').style('opacity', 0.5)
        d3.select('#valueProgress').style('opacity', 1)
        this.changeChartValue('prog', '%', 1)
      })


      this.Header_TitleBox( this.SkylineCharts, 'Pre-Commissioning', 780, 170, 125, 20, 3, '#333', '#E0E0DF') // -------------------------->

      data = {TOTAL:0, ACTUAL:0, REMAIN:0}
      this.Queries.SQL5.forEach(d => {
        data.TOTAL += d.TOTAL
        data.ACTUAL += d.ACTUAL
        data.REMAIN += d.REMAIN
      })
      style = {
        id: 'SC',
        title: 'ITRs(B)',
        x: 780,
        y: 195,
        width: 210,
        height: 40,
        cell: [10, 90, 140, 190],
        bColor: '#83D2F5',
        opacity: 1,
        requests: 
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 191,
              no: ''
            },
            filters: {},
            iFilters: {
              filterString : "[STAGE] = 'B'",
              inputFilter  : ''
            }
          },
      }
      this.SummaryTable2(this.SkylineCharts, data, style) // -------------------------->


      // Progress Summary Table
      let colStyle = [
                      {name: ''        , x: 70,  align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Total'   , x: 110, align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Closed'  , x: 150, align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Remain'  , x: 190, align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Prog.(%)', x: 200, align: 'start' , tColor:'#44A9DF'}
      ]
      let cellStyle = {
        x: 1040, 
        y:200, 
        id: 'B', 
        barSize:100, 
        barColor: '#B4E3FA', 
        tColor: '#757575', 
        fSize: [9, 10, 9],
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 191,
              no: ''
            },
            filters: { 
            },
            iFilters: {
              filterString : "[STAGE] = 'B'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 191,
              no: ''
            },
            filters: { 
            },
            iFilters: {
              filterString : "[STAGE] = 'B' AND [STATUS] = 'Y'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 191,
              no: ''
            },
            filters: { 
            },
            iFilters: {
              filterString : "[STAGE] = 'B' AND [STATUS] = 'N'",
              inputFilter  : ''
            }
          },
        ]
      }
      let dataTrans = this.Queries.SQL5

      this.ProgressTable_01(this.SkylineCharts, dataTrans, colStyle, cellStyle, 'B ITRs Status', 'on') // -------------------------->
    },


    changeChartValue(data, suffix, point) {

      d3.select('#ChartPlanValueGroup')
      .selectAll('#ChartPlanValue')
      .datum(this.timeline.plan)
      .each((d, i, a) => {
        d3.select(a[i]).text(`${d[i][data].toFixed(point)}${suffix}`)
      })

      d3.select('#ChartActualValueGroup')
      .selectAll('#ChartActualValue')
      .datum(this.timeline.actual)
      .each((d, i, a) => {
        d3.select(a[i]).text(`${d[i][data].toFixed(point)}${suffix}`)
      })
      
    },


  }
}
