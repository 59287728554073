import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
  }),

  methods: {
    Dashboard_Area() {
      let style = null, data = null

      this.stage = this.STG_Dashboard_Area

      this.SVG_DashboardArea = this.svg.append('svg')

      this.SVG_DashboardArea
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)


      // Area Image ---------------------------------------------------------------------------------------------------------------------------
      this.SVG_DashboardArea
      .append('image')
      .attr('id', 'MD2_Topside')
      .attr('transform', `translate(100,130)`).attr('width', 320)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/Images/Overall/Topside.jpg`)
      .style('opacity', 0)
      .style('cursor', 'pointer')
      .call(s => this.Goto_ActivateModuling(s,'Topside'))

      this.SVG_DashboardArea
      .append('image')
      .attr('id', 'MD2_Deckbox')
      .attr('transform', `translate(100,390)`).attr('width', 320)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/Images/Overall/Deckbox.jpg`)
      .style('opacity', 0)
      .style('cursor', 'pointer')
      .call(s => this.Goto_ActivateModuling(s,'Deckbox'))


      this.SVG_DashboardArea
      .append('image')
      .attr('id', 'MD2_Hull')
      .attr('transform', `translate(100,650)`).attr('width', 320)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/Images/Overall/Hull.jpg`)
      .style('opacity', 0)
      .style('cursor', 'pointer')
      .call(s => this.Goto_ActivateModuling(s,'Hull'))

      d3.select(`#MD2_Topside`).transition().duration(750).style('opacity', 1)
      d3.select(`#MD2_Deckbox`).transition().duration(750).style('opacity', 1)
      d3.select(`#MD2_Hull`).transition().duration(750).style('opacity', 1)
 


      setTimeout(() => {
        
        // Progress Title Summary ---------------------------------------------------------------------------------------------------------------------------
        let yPoint = [320, 570, 840]
        let progSummary = this.SVG_DashboardArea
        .append('g')
        .attr('id', 'progSummary')
        .attr('transform', `translate(0,0)`)
        .style('opacity', 0)

        this.Queries.SQL_MasterProg.filter(f => f.AREA !=='Overall').forEach((d,i) => {

          progSummary
          .append('text').attr('transform', `translate(90, ${yPoint[i]})`)
          .style('font-family', 'roboto').style('font-size', 15).style('fill', '#333').style('text-anchor', 'start').text(d.AREA)

          this.Header_Progress_PAD( progSummary,'Construction', 90, yPoint[i]+5, 88, 20, 3, [d.CON_P_PG, d.CON_A_PG, d.CON_D_PG], '#fff', '#E0E0DF' )


          this.Header_TitleBox( progSummary, 'System Completion Status', 450, yPoint[i]-195, 165, 20, 3, '#757575', '#E0E0DF')

          style = {
            id: 'MC',
            title: 'ITRs(A)',
            x: 450,
            y: yPoint[i]-170,
            width: 210,
            height: 40,
            cell: [10, 90, 140, 190],
            bColor: '#88C8EB',
            opacity: 1,
            requests: {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 173,
                no: ''
              },
              filters     : {
              },
              iFilters    : {
              }
            }
          }
          data = {TOTAL:d.A_T, ACTUAL:d.A_A, REMAIN:d.A_O,}
          this.SummaryTable2( progSummary, data, style) // ------------>


          style = {
            id: 'Punch',
            x: 450,
            y: yPoint[i]-125,
            width: 210,
            height: 65,
            cell: [10, 90, 140, 190],
            bColor: '#83D2F5',
            opacity: 0.9,
            requests: {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 173,
                no: ''
              },
              filters     : {
              },
              iFilters    : {
              }
            }
          }
          let data1 = ['Cat. A', d.PA_T, d.PA_A, d.PA_O]
          let data2 = ['Cat. B', d.PB_T, d.PB_A, d.PB_O]
          this.SummaryTable3( progSummary, data1, data2, style) // ------------>


          style = {
            id: 'SC',
            title: 'ITRs(B)',
            x: 450,
            y: yPoint[i]-55,
            width: 210,
            height: 40,
            cell: [10, 90, 140, 190],
            bColor: '#83D2F5',
            opacity: 0.9,
            requests: {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 173,
                no: ''
              },
              filters     : {
              },
              iFilters    : {
              }
            }
          }
          data = {TOTAL:d.B_T, ACTUAL:d.B_A, REMAIN:d.B_O,}
          this.SummaryTable2( progSummary, data, style) // ------------>


          this.SVG_DashboardArea
          .append('text')
          .attr('x', 850).attr('y', yPoint[i]-190)
          .style('font-family', 'roboto').style('font-size', 12).style('fill', '#44A9DF').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text('MC ITRs')
          
          // Radar Chart
          this.Radar.chart.size = 150
           style = {
            x: 850,
            y: yPoint[i]-75,
          }
          data = this.Queries.SQL3.filter(f => f.AREA == d.AREA && f.PHASE =='MC')
          this.draw_Radar(this.SVG_DashboardArea, data, style)


          // MC Bar Chart
          let itemSet = []
          this.Queries.SQL3.filter(f => f.AREA == d.AREA && f.PHASE =='COM').forEach((d,i) => {
            itemSet.push({
              name: d.DISC,
              total: d.TOTAL,
              actual: d.ACTUAL,
            })
          })
          style = [
                      {bColor:'#44A9DF', opacity: 0.3},
                      {bColor:'#44A9DF', opacity: 0.4},
                      {bColor:'#44A9DF', opacity: 0.5},
                      {bColor:'#44A9DF', opacity: 0.6},
                      {bColor:'#44A9DF', opacity: 0.7},
                      {bColor:'#44A9DF', opacity: 0.8},
                      {bColor:'#44A9DF', opacity: 0.9},
                      {bColor:'#44A9DF', opacity: 1.0},       
              ]
          this.Bar_Quantity_01(this.SVG_DashboardArea, 1100, yPoint[i]-160, itemSet, 12, 120, 20, 10, style, 'on')
        })

        d3.select('#progSummary').transition().duration(500).style('opacity', 1)
      }, 300);


    },



  }
}
           