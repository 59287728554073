import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    
  }),


  methods: {

    // --------------------------------------------------------------------------------------------------------------------------- Bar_Punch_01
    Bar_Punch_01(selection, _data, _style) {

      // let _data = [
      //   { DISC: 'PI'  ,TOTAL: 123  ,ACTUAL: 50    ,REMAIN: 25 ,}, 
      //   { DISC: 'CO'  ,TOTAL: 325  ,ACTUAL: 123   ,REMAIN: 25 ,},
      //   { DISC: 'IN'  ,TOTAL: 100  ,ACTUAL: 75    ,REMAIN: 25 ,},
      //   { DISC: 'ST'  ,TOTAL: 150  ,ACTUAL: 75    ,REMAIN: 25 ,},
      //   { DISC: 'SE'  ,TOTAL: 200  ,ACTUAL: 120   ,REMAIN: 25 ,},
      //   { DISC: 'AR'  ,TOTAL: 100  ,ACTUAL: 75    ,REMAIN: 25 ,},
      //   { DISC: 'HV'  ,TOTAL: 350  ,ACTUAL: 236   ,REMAIN: 25 ,},
      //   { DISC: 'TE'  ,TOTAL: 150  ,ACTUAL: 75    ,REMAIN: 25 ,},
      //   { DISC: 'IC'  ,TOTAL: 75   ,ACTUAL: 45    ,REMAIN: 25 ,},
      //   { DISC: 'EL'  ,TOTAL: 230  ,ACTUAL: 12    ,REMAIN: 25 ,},
      //   { DISC: 'ME'  ,TOTAL: 230  ,ACTUAL: 12    ,REMAIN: 25 ,},
      // ]  
      let _self = this

      _style.cell[4] =  _style.cell[3] + (_style.length/2)
      _style.cell[5] =  _style.cell[3] + (_style.length)

      let maxValue = Math.max(..._data.map(d => d.TOTAL))
      maxValue = this.getMaxBound(maxValue)

      let gridLength = ((_data.length+1) * _style.lineHeight) + _style.lineHeight/2

      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, maxValue])
      .range([0, _style.length])

      _data.forEach(data => {
        data.lenTot = _style.length * (data.ACTUAL/data.TOTAL)
        data.lenAct = _style.length * (data.ACTUAL/data.TOTAL)
      })

      let discPunch = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      discPunch
      .append('text')
      .attr('x', _style.cell[1]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Total')

      discPunch
      .append('text')
      .attr('x', _style.cell[2]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Closed')

      discPunch
      .append('text')
      .attr('x', _style.cell[3]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Active')


      discPunch
      .append('text')
      .attr('x', _style.cell[3]).attr('y', 25)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('0')

      discPunch
      .append('text')
      .attr('x', _style.cell[4]).attr('y', 25)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(maxValue/2)

      discPunch
      .append('text')
      .attr('x', _style.cell[5]).attr('y', 25)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(maxValue)

      discPunch
      .append('line')
      .attr('x1', _style.cell[3]).attr('y1', 30).attr('x2', _style.cell[3]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      discPunch
      .append('line')
      .attr('x1', _style.cell[4]).attr('y1', 30).attr('x2', _style.cell[4]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      discPunch
      .append('line')
      .attr('x1', _style.cell[5]).attr('y1', 30).attr('x2', _style.cell[5]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      
      let drawY = 40
      _data.forEach((d,i) => {
        d.y = drawY

        discPunch
        .append('text')
        .attr('x', _style.cell[0]).attr('y', drawY)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.DISC)

        discPunch
        .append('text')
        .attr('id', `${_style.id}_TOT_${i}`)
        .attr('transform', `translate(${_style.cell[1]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${_style.id}_TOT_${i}`, 'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[1] + node}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[1]}, ${d.y}) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => { 
          //
        })


        discPunch
        .append('text')
        .attr('id', `${_style.id}_ACT_${i}`)
        .attr('transform', `translate(${_style.cell[2]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${_style.id}_ACT_${i}`, 'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[2] + node}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[2]}, ${d.y}) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => { 
          //
        })


        discPunch
        .append('rect')
        .attr('transform', `translate(${_style.cell[3]}, ${drawY - 7})`)
        .attr('width', barScale(d.TOTAL)).attr('height', 12)
        .attr('fill', '#F4F5F5')

        discPunch
        .append('rect')
        .attr('transform', `translate(${_style.cell[3]}, ${drawY - 7})`)
        .attr('width', 0).attr('height', 12)
        .attr('stroke-width', 0.3).attr('stroke', _style.sColor).attr('fill', _style.bColor).style('opacity', _style.opacity)
        .transition().duration(500).attr('width', barScale(d.ACTUAL))

        discPunch
        .append('text')
        .attr('id', `${_style.id}_REM_${i}`)
        .attr('transform', `translate(${_style.cell[3] + barScale(d.ACTUAL + 1)}, ${drawY})`)
        .attr('text-anchor', ()=> {
          if(barScale(d.ACTUAL) < _style.min) return 'start'; else return 'end'
        })
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('alignment-baseline', 'middle')
        .text(()=> { if(d.TOTAL-d.ACTUAL > 0) return d.TOTAL-d.ACTUAL })

        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${_style.id}_REM_${i}`, 'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', ()=> {
            if(barScale(d.ACTUAL) < _style.min) return `translate(${_style.cell[3] + barScale(d.ACTUAL) - node}, ${d.y}) scale(1.3)`
            else return `translate(${_style.cell[3] + barScale(d.ACTUAL) + node}, ${d.y}) scale(1.3)`
          })
          .style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[3] + barScale(d.ACTUAL)}, ${d.y}) scale(1)`).style('fill', '#333')
        })
        .on('click', () => { 
          //
        })

        drawY += _style.lineHeight
      })

    },

    // --------------------------------------------------------------------------------------------------------------------------- Bar_Punch_02
    Bar_Punch_02(selection, _data, _style) {
      
      let maxValue = Math.max(..._data.map(d => d.ACTUAL), ..._data.map(d => d.REMAIN))
      maxValue = this.getMaxBound(maxValue)

      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, maxValue])
      .range([_style.barLen, 0])


      let overallPunch = this.svg
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      
      for(var i=0; i<6; i++) {
        overallPunch
        .append('line')
        .attr('x1', _style.stX - 5).attr('x2', 150)
        .attr('y1', barScale((maxValue/5)*i)).attr('y2', barScale((maxValue/5)*i))
        .attr('stroke', '#bcbcbc')
        .attr('stroke-width', 0.2)

        overallPunch
        .append('text')
        .attr('transform', `translate(${_style.stX - 7}, ${barScale((maxValue/5)*i)})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text((maxValue/5)*i)
      }

      overallPunch
      .append('line')
      .attr('x1', _style.stX - 5).attr('y1', _style.barLen).attr('x2', 150).attr('y2', _style.barLen)
      .attr('stroke', '#757575')
      .attr('stroke-width', 0.3)

      let x = _style.stX
      _data.forEach((d,i) => {
        overallPunch
        .append('path')
        .attr('d', ` M ${x}, ${_style.barLen}
                     V ${barScale(d.ACTUAL)}
                     H ${x + _style.width}
                     V ${_style.barLen} Z
                  `
        ).style('fill', _style.pColor[0]).attr('stroke', _style.pColor[1]).attr('stroke-width', 0.5)

        overallPunch
        .append('text')
        .attr('transform', `translate(${x + _style.width + (_style.space/2)}, ${_style.barLen + 12})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`Punch ${d.TYPE}`)

        overallPunch
        .append('text')
        .attr('transform', `translate(${x + (_style.width/2)}, ${barScale(d.ACTUAL) - 6})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`${d.ACTUAL}`)

        x += _style.width + _style.space

        overallPunch
        .append('path')
        .attr('d', ` M ${x}, ${_style.barLen}
                     V ${barScale(d.REMAIN)}
                     H ${x + _style.width}
                     V ${_style.barLen} Z
                  `
        ).style('fill', _style.bColor[i]).attr('stroke', _style.sColor[i]).attr('stroke-width', 0.5)
        
        overallPunch
        .append('text')
        .attr('transform', `translate(${x + (_style.width/2)}, ${barScale(d.REMAIN) - 6})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(`${d.REMAIN}`)

        x += _style.width + _style.space
      })

    },


    // --------------------------------------------------------------------------------------------------------------------------- Bar_Punch_03
    Bar_Punch_03(selection, _data, _style) {

      let _self = this

      _style.cell[2] =  _style.cell[1] + (_style.length/2)
      _style.cell[3] =  _style.cell[1] + (_style.length)

      let maxValue = Math.max(..._data.map(d => d.TOTAL))
      maxValue = this.getMaxBound(maxValue)

      let gridLength = ((_data.length+1) * _style.lineHeight) + _style.lineHeight/2

      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, maxValue])
      .range([0, _style.length])

      _data.forEach(data => {
        data.lenTot = _style.length * (data.ACTUAL/data.TOTAL)
        data.lenAct = _style.length * (data.ACTUAL/data.TOTAL)
      })

      let discPunch = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      discPunch
      .append('text')
      .attr('x', _style.cell[0]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Disc.')

      discPunch
      .append('text')
      .attr('x', _style.cell[1]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Active')


      discPunch
      .append('text')
      .attr('x', _style.cell[1]).attr('y', 25)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('0')

      discPunch
      .append('text')
      .attr('x', _style.cell[2]).attr('y', 25)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(maxValue/2)

      discPunch
      .append('text')
      .attr('x', _style.cell[3]).attr('y', 25)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(maxValue)

      discPunch
      .append('line')
      .attr('x1', _style.cell[1]).attr('y1', 30).attr('x2', _style.cell[1]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      discPunch
      .append('line')
      .attr('x1', _style.cell[2]).attr('y1', 30).attr('x2', _style.cell[2]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      discPunch
      .append('line')
      .attr('x1', _style.cell[3]).attr('y1', 30).attr('x2', _style.cell[3]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      
      let drawY = 40
      _data.forEach((d,i) => {
        d.y = drawY

        discPunch
        .append('text')
        .attr('x', _style.cell[0]).attr('y', drawY)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.DISC)

        discPunch
        .append('rect')
        .attr('transform', `translate(${_style.cell[1]}, ${drawY - 7})`)
        .attr('width', barScale(d.TOTAL)).attr('height', 12)
        .attr('fill', '#F4F5F5')

        discPunch
        .append('rect')
        .attr('transform', `translate(${_style.cell[1]}, ${drawY - 7})`)
        .attr('width', 0).attr('height', 12)
        .attr('stroke-width', 0.3).attr('stroke', _style.sColor).attr('fill', _style.bColor).style('opacity', _style.opacity)
        .transition().duration(500).attr('width', barScale(d.ACTUAL))

        discPunch
        .append('text')
        .attr('id', `${_style.id}_REM_${i}`)
        .attr('transform', `translate(${_style.cell[1] + barScale(d.ACTUAL + 1)}, ${drawY})`)
        .attr('text-anchor', ()=> {
          if(barScale(d.ACTUAL) < _style.min) return 'start'; else return 'end'
        })
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('alignment-baseline', 'middle')
        .text(()=> { if(d.TOTAL-d.ACTUAL > 0) return d.TOTAL-d.ACTUAL })

        .style('cursor', 'pointer')
        .on('mouseover', function() {
          let node = _self.getNodeElValue(`#${_style.id}_REM_${i}`, 'width') / 5.5
          d3.select(this).transition().duration(100)
          .attr('transform', ()=> {
            if(barScale(d.ACTUAL) < _style.min) return `translate(${_style.cell[1] + barScale(d.ACTUAL) - node}, ${d.y}) scale(1.3)`
            else return `translate(${_style.cell[1] + barScale(d.ACTUAL) + node}, ${d.y}) scale(1.3)`
          })
          .style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[1] + barScale(d.ACTUAL)}, ${d.y}) scale(1)`).style('fill', '#333')
        })
        .on('click', () => { 
          if(_style.requests) {
            let filterString_ = _style.requests[0].iFilters.filterString
            if(filterString_) filterString_ = `${filterString_} AND ITR_DISC = '${d.DISC}'`
            else filterString_ = `ITR_DISC = '${d.DISC}'`
            _style.requests[0].iFilters.filterString = filterString_

            this.$emit('request-action', _style.requests[0])

          }
        })

        drawY += _style.lineHeight
      })

    },


    // --------------------------------------------------------------------------------------------------------------------------- Bar_Activity_01
    Bar_Activity_01(selection, _id, _cellX, _y, _data, _height, _len, _dist, _fSize, _status, _title) {
  
      _status.forEach((d, i) => {
        d.total = _data[i].TOTAL
        d.actual = _data[i].ACTUAL
      })

      let blockstatus = selection
      .append('g')
      .attr('transform', `translate(${_cellX}, ${_y})`)
      
      if (_title == 'on') {
        blockstatus
        .append('text')
        .attr('x', 0).attr('y', 0)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text('Status of Blocks')
      }
      _status.forEach((d, i)=> {

        let actualLen = d.total === 0 || d.actual === 0 ? 0 : (d.actual / d.total) * _len
        let distance = _dist

        blockstatus
        .append('text')
        .attr('transform', `translate(${-3}, ${(i*distance) + 18})`)
        .style('font-family', 'roboto').style('font-size', _fSize).style('fill', '#757575').style('text-anchor', 'end').attr('alignment-baseline', 'middle').text(d.name)

        blockstatus
        .append('rect')
        .attr('id', `bar_${_id}_${i}`)
        .attr('transform', `translate(0, ${(i*distance) + 12})`)
        .attr('width', 0).attr('height', _height).style('fill', d.bColor).style('opacity', d.opacity)
        
        d3.select(`#bar_${_id}_${i}`)
        .transition().duration(500).attr('width', actualLen)

        if(d.actual > 0)
          blockstatus
          .append('text')
          .attr('transform', `translate(${actualLen < 10 ? actualLen + 12 : actualLen - 2}, ${(i*distance) + 18})`)
          .style('font-family', 'roboto').style('font-size', 9).style('fill', `${actualLen < 10 ? '#757575' : '#fff'}`).style('text-anchor', 'end').attr('alignment-baseline', 'middle').text(d.actual)

        blockstatus
        .append('text')
        .attr('transform', `translate(${_len + 2}, ${(i*distance) + 18})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.total)
      })
    },
    Bar_Activity_02(selection, _id, _cellX, _y, _data, _height, _len, _dist, _fSize, _status, _title) {

      _status.forEach((d, i) => {
        d.total = _data[i].TOTAL
        d.actual = _data[i].ACTUAL
      })
      let blockstatus = selection
      .append('g')
      .attr('transform', `translate(${_cellX}, ${_y})`)
    
      let filtered = _status.filter(f => f.total > 0 && f.actual > 0)
      
      filtered.forEach((d, i)=> {

        if (d.total === 0 && d.actual === 0) return

        let actualLen = d.total === 0 || d.actual === 0 ? 0 : (d.actual / d.total) * _len
        let distance = _dist

        blockstatus
        .append('text')
        .attr('transform', `translate(${-3}, ${(i*distance) + 18})`)
        .style('font-family', 'roboto').style('font-size', _fSize).style('fill', '#757575').style('text-anchor', 'end').attr('alignment-baseline', 'middle').text(d.name)

        blockstatus
        .append('rect')
        .attr('id', `bar_${_id}_${i}`)
        .attr('transform', `translate(0, ${(i*distance) + 12})`)
        .attr('width', 0).attr('height', _height).style('fill', d.actual == d.total ? '#4CAF50' : d.bColor).style('opacity', d.opacity)
        
        d3.select(`#bar_${_id}_${i}`)
        .transition().duration(500).attr('width', actualLen)

        if(d.actual > 0)
          blockstatus
          .append('text')
          .attr('transform', `translate(${actualLen - 2}, ${(i*distance) + 18})`)
          .style('font-family', 'roboto').style('font-size', 9).style('fill', '#fff').style('text-anchor', 'end').attr('alignment-baseline', 'middle').text( d.actual == d.total ? 'Completed' : d.actual)

        blockstatus
        .append('text')
        .attr('transform', `translate(${_len + 2}, ${(i*distance) + 18})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.total)
      })
    },

    // --------------------------------------------------------------------------------------------------------------------------- Bar_Quantity_01
    Bar_Quantity_01(svg, _cellX, _y, _data, _height, _len, _dist, _fSize, _status, _title) {

      let blockstatus = svg
      .append('g')
      .attr('transform', `translate(${_cellX}, ${_y})`)
      
      if (_title == 'on') {
        blockstatus
        .append('text')
        .attr('x', -55).attr('y', 0)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text('Pre-Comm. ITRs Status')
      }
      _data.forEach((d, i)=> {

        let actualLen = (d.actual / d.total) * _len
        let distance = _dist

        blockstatus
        .append('text')
        .attr('transform', `translate(${-3}, ${(i*distance) + 18})`)
        .style('font-family', 'roboto').style('font-size', _fSize).style('fill', '#757575').style('text-anchor', 'end').attr('alignment-baseline', 'middle').text(d.name)

        blockstatus
        .append('rect')
        .attr('transform', `translate(0, ${(i*distance) + 12})`)
        .style('width', _len).style('height', _height).style('fill', '#F4F5F5').attr('opacity', 1)
        
        blockstatus
        .append('rect')
        .attr('transform', `translate(0, ${(i*distance) + 12})`)
        .style('width', actualLen).style('height', _height).style('fill', _status[i].bColor).attr('opacity', _status[i].opacity)

        blockstatus
        .append('text')
        .attr('transform', () => {
          if (actualLen > 15) return `translate(${actualLen - 2}, ${(i*distance) + 18})`; else return `translate(${actualLen + 2}, ${(i*distance) + 18})`
        })
        .style('text-anchor', () => {
          if (actualLen > 15) return 'end'; else return 'start'
        })
        .style('fill', () => {
          if (actualLen > 15) return '#fff'; else return '#757575'
        })
        .style('font-family', 'roboto').style('font-size', 9).attr('alignment-baseline', 'middle').text(d.actual)

        blockstatus
        .append('text')
        .attr('transform', `translate(${_len + 2}, ${(i*distance) + 18.5})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').style('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.total)
      })
    },


    // --------------------------------------------------------------------------------------------------------------------------- Bar_Quantity_02
    Bar_Quantity_02(selection, _data, _style) {
      // let _data = [
      //   { DISC: 'Process'     ,TOTAL: 123  ,ACTUAL: 50    ,REMAIN: 25 ,}, 
      //   { DISC: 'ICSS'        ,TOTAL: 325  ,ACTUAL: 123   ,REMAIN: 25 ,},
      //   { DISC: 'Instrument'  ,TOTAL: 100  ,ACTUAL: 75    ,REMAIN: 25 ,},
      //   { DISC: 'Telecom'     ,TOTAL: 150  ,ACTUAL: 75    ,REMAIN: 25 ,},
      //   { DISC: 'Mechanical'  ,TOTAL: 200  ,ACTUAL: 120   ,REMAIN: 25 ,},
      //   { DISC: 'Electrical'  ,TOTAL: 100  ,ACTUAL: 75    ,REMAIN: 25 ,},
      //   { DISC: 'Marine'      ,TOTAL: 350  ,ACTUAL: 236   ,REMAIN: 25 ,},
      //   { DISC: 'HVAC'        ,TOTAL: 150  ,ACTUAL: 75    ,REMAIN: 25 ,},
      //   { DISC: 'Utility'     ,TOTAL: 75   ,ACTUAL: 45    ,REMAIN: 25 ,},
      //   { DISC: 'Structure'   ,TOTAL: 230  ,ACTUAL: 12    ,REMAIN: 25 ,},
      // ]

      _style.cell[2] =  _style.cell[1] + (_style.length/2)
      _style.cell[3] =  _style.cell[1] + (_style.length)

      let maxValue = Math.max(..._data.map(d => d.TOTAL))
      let maxGrid = this.getMaxBound(maxValue)
      let gridLength = (_data.length+1) * _style.lineHeight

      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, maxGrid])
      .range([0, _style.length])

      _data.forEach(data => {
        data.lenTot = _style.length * (data.ACTUAL/data.TOTAL)
        data.lenAct = _style.length * (data.ACTUAL/data.TOTAL)
      })

      let discSystem = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

 
      discSystem
      .append('text')
      .attr('x', _style.cell[1]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('0')

      discSystem
      .append('text')
      .attr('x', _style.cell[2]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(maxGrid/2)

      discSystem
      .append('text')
      .attr('x', _style.cell[3]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(maxGrid)

      discSystem
      .append('line')
      .attr('x1', _style.cell[1]).attr('y1', 20).attr('x2', _style.cell[1]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      discSystem
      .append('line')
      .attr('x1', _style.cell[2]).attr('y1', 20).attr('x2', _style.cell[2]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      discSystem
      .append('line')
      .attr('x1', _style.cell[3]).attr('y1', 20).attr('x2', _style.cell[3]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      
      let drawY = 30
      _data.forEach(d => {
        d.y = drawY

        discSystem
        .append('text')
        .attr('x', _style.cell[0]).attr('y', drawY)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.DISC)

        discSystem
        .append('rect') 
        .attr('transform', `translate(${_style.cell[1]}, ${drawY - 5})`)
        .attr('width', barScale(d.TOTAL)).attr('height', 12)
        .attr('fill', '#F4F5F5')

        discSystem
        .append('text')
        .attr('transform', `translate(${_style.cell[1]}, ${d.y+2})`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(d.TOTAL)
        .transition().duration(500)
        .attr('transform', `translate(${_style.cell[1] + barScale(d.TOTAL) + 2}, ${d.y+2})`)
        
        discSystem
        .append('rect')
        .attr('transform', `translate(${_style.cell[1]}, ${drawY - 5})`)
        .attr('width', 0).attr('height', 12)
        .attr('stroke-width', 0.3).attr('stroke', _style.sColor).attr('fill', _style.bColor).style('opacity', _style.opacity)
        .transition().duration(500).attr('width', barScale(d.ACTUAL))

        discSystem
        .append('text')
        .attr('transform', `translate(${_style.cell[1]}, ${d.y + 2})`)
        .style('font-family', 'roboto').style('font-size', 9).attr('alignment-baseline', 'middle')
        .text(()=> { if(d.ACTUAL > 0) return d.ACTUAL })
        .style('fill', () => {
          if (barScale(d.ACTUAL) < _style.min) return _style.tColor2; else return _style.tColor1
        })
        .attr('text-anchor', () => {
          if (barScale(d.ACTUAL) < _style.min) return 'start'; else return 'end'
        })
        .transition().duration(500)
        .attr('transform', `translate(${_style.cell[1] + barScale(d.ACTUAL)}, ${d.y + 2})`)
        
        drawY += _style.lineHeight
      })
    },
    Bar_Quantity_02_Percent(selection, _data, _style) {
      _style.cell[2] =  _style.cell[1] + (_style.length/2)
      _style.cell[3] =  _style.cell[1] + (_style.length)

      let maxValue = Math.max(..._data.map(d => d.PLAN))
      let maxGrid = this.getMaxBound(maxValue)
      let gridLength = (_data.length+1) * _style.lineHeight

      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, maxGrid])
      .range([0, _style.length])

      _data.forEach(data => {
        data.lenTot = _style.length * (data.ACTUAL/data.PLAN)
        data.lenAct = _style.length * (data.ACTUAL/data.PLAN)
      })

      let discSystem = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

 
      // discSystem
      // .append('text')
      // .attr('x', _style.cell[1]).attr('y', 15)
      // .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      // .text('0')

      // discSystem
      // .append('text')
      // .attr('x', _style.cell[2]).attr('y', 15)
      // .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      // .text(50)

      // discSystem
      // .append('text')
      // .attr('x', _style.cell[3]).attr('y', 15)
      // .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      // .text(100)

      discSystem
      .append('line')
      .attr('x1', _style.cell[1]).attr('y1', 20).attr('x2', _style.cell[1]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      discSystem
      .append('line')
      .attr('x1', _style.cell[2]).attr('y1', 20).attr('x2', _style.cell[2]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      discSystem
      .append('line')
      .attr('x1', _style.cell[3]).attr('y1', 20).attr('x2', _style.cell[3]).attr('y2', gridLength).style('stroke', '#bcbcbc').style('stroke-width', 0.3)
      
      let drawY = 30
      _data.forEach(d => {
        d.y = drawY

        discSystem
        .append('text')
        .attr('x', _style.cell[0]).attr('y', drawY)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.DISC)

        discSystem
        .append('rect') 
        .attr('transform', `translate(${_style.cell[1]}, ${drawY - 5})`)
        .attr('width', barScale(d.PLAN)).attr('height', 12)
        .attr('fill', '#F4F5F5')
        // console.log(d.DISC, d.PLAN, d.ACTUAL)
        // if (barScale(d.ACTUAL) > 24) { ----기존 조건 

        if (barScale(d.PLAN) < 24 && barScale(d.ACTUAL) < 24 && d.ACTUAL !== 0) {
          discSystem
          .append('text')
          .attr('transform', `translate(${_style.cell[1]}, ${d.y+2})`)
          .style('font-family', 'roboto').style('font-size', 9).style('fill', '#ccc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(d.PLAN < d.ACTUAL  && d.PLAN == 0 ? '' : d.PLAN)
          .transition().duration(500)
          .attr('transform', `translate(${barScale(d.PLAN) < barScale(d.ACTUAL) ? _style.cell[1] + barScale(d.ACTUAL) +4 : _style.cell[1] + barScale(d.PLAN) + 22}, ${d.y+2})`)
        } else{
          discSystem
          .append('text')
          .attr('transform', `translate(${_style.cell[1]}, ${d.y+2})`)
          .style('font-family', 'roboto').style('font-size', 9).style('fill', '#ccc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(d.PLAN < d.ACTUAL  && d.PLAN == 0 ? '' : d.PLAN)
          .transition().duration(500)
          .attr('transform', `translate(${barScale(d.PLAN) < barScale(d.ACTUAL) ? _style.cell[1] + barScale(d.ACTUAL) +4 : _style.cell[1] + barScale(d.PLAN) + 2}, ${d.y+2})`)
        }
        
        
        discSystem
        .append('rect')
        .attr('transform', `translate(${_style.cell[1]}, ${drawY - 5})`)
        .attr('width', 0).attr('height', 12)
        .attr('stroke-width', 0.3).attr('stroke', _style.sColor).attr('fill', _style.bColor).style('opacity', _style.opacity)
        .transition().duration(500).attr('width', barScale(d.ACTUAL))


        discSystem
        .append('text')
        .attr('transform', `translate(${_style.cell[1]}, ${d.y + 2})`)
        .style('font-family', 'roboto').style('font-size', 9).attr('alignment-baseline', 'middle')
        .text(()=> { if(d.ACTUAL > 0) return 'a', d.ACTUAL })
        .style('fill', () => {
          if (barScale(d.ACTUAL) < _style.min) return _style.tColor2; else return _style.tColor1
          // return 'red'
        })
        .attr('text-anchor', () => {
          if (barScale(d.ACTUAL) < _style.min) return 'start'; else return barScale(d.ACTUAL) < 24 ? 'start' : 'end'
        })
        .transition().duration(500)
        .attr('transform', `translate(${barScale(d.ACTUAL) < 24 ? _style.cell[1] + barScale(d.ACTUAL) +2 : _style.cell[1] + barScale(d.ACTUAL) }, ${d.y + 2})`)
        

        
        drawY += _style.lineHeight
      })
    },

    // --------------------------------------------------------------------------------------------------------------------------- Bar_Quantity_02
    Bar_Quantity_03(selection, _data, _style) {

      let maxValue = Math.max(..._data)
      let maxGrid = this.getMaxBound(maxValue)
      let gridLength = (_data.length) * (_style.barWidth + _style.barSpace)

      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, maxGrid])
      .range([0, -_style.barHeight])


      let quantityBar = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      
      let drawX = 0
      _data.forEach(d => {
        quantityBar
        .append('text')
        .attr('transform', `translate(${drawX + (_style.barWidth/2)}, ${barScale(d) - 5})`)
        .style('font-family', 'roboto').style('font-size', _style.tSize).style('fill', _style.tColor)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d)

        quantityBar
        .append('path') 
        .attr('d', `
                    M ${drawX},0
                    V ${barScale(d)}
                    H ${drawX + _style.barWidth}
                    V 0
                    Z
                   `
        )
        .attr('fill', _style.bColor)
        .attr('stroke', _style.sColor).attr('stroke-width', _style.sWidth)
        
        quantityBar
        .append('line')
        .attr('x1', -(_style.barWidth/2)).attr('x2', gridLength)
        .attr('stroke', _style.sColor).attr('stroke-width', 0.2)

        drawX += (_style.barWidth + _style.barSpace)
      })
    },
    Bar_Progress_01(selection, _data, _style) {

      _style.cell[5] = _style.cell[4] + (_style.width / 2)
      _style.cell[6] = _style.cell[4] + _style.width


      
      
      let discITR = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      discITR
      .append('text')
      .attr('x', _style.title.x).attr('y', -10)
      .style('font-family', 'roboto').style('font-size', _style.title.tSize).style('fill', _style.title.tColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(_style.title.text)

      discITR
      .append('text')
      .attr('x', _style.cell[0]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Discipline')

      discITR
      .append('text')
      .attr('x', _style.cell[1]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Total')

      discITR
      .append('text')
      .attr('x', _style.cell[2]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Actual')

      discITR
      .append('text')
      .attr('x', _style.cell[3]).attr('y', 10)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
      .text('Remain')

      discITR
      .append('text')
      .attr('x', _style.cell[4]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('0')

      discITR
      .append('text')
      .attr('x', _style.cell[5]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('50')

      discITR
      .append('text')
      .attr('x', _style.cell[6]).attr('y', 15)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('100')

      discITR
      .append('line')
      .attr('x1', _style.cell[4]).attr('y1', 20).attr('x2', _style.cell[4]).attr('y2', 23).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
      discITR
      .append('line')
      .attr('x1', _style.cell[5]).attr('y1', 20).attr('x2', _style.cell[5]).attr('y2', 23).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
      discITR
      .append('line')
      .attr('x1', _style.cell[6]).attr('y1', 20).attr('x2', _style.cell[6]).attr('y2', 23).style('stroke', '#bcbcbc').style('stroke-width', 0.5)
      
      let drawY = 30
      _data.forEach((d,i) => {
        d.y = drawY

        discITR
        .append('text')
        .attr('x', _style.cell[0]).attr('y', drawY)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.DISC)

        discITR
        .append('text')
        .attr('id', `disc_Tot_${this.localId}_${i}`)
        .attr('transform', `translate(${_style.cell[1]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.TOTAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[1]+2}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[1]}, ${d.y}) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => { 
          if(_style.requests) {
            _style.requests[0].filters.DISC = d.DISC
            this.$emit('request-action', _style.requests[0])
          }
        })

        discITR
        .append('text')
        .attr('id', `disc_Act_${this.localId}_${i}`)
        .attr('transform', `translate(${_style.cell[2]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.ACTUAL)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[2]+2}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[2]}, ${d.y}) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => { 
          if(_style.requests) {
            _style.requests[1].filters.DISC = d.DISC
            this.$emit('request-action', _style.requests[1])
          }
        })

        discITR
        .append('text')
        .attr('id', `disc_Rem_${this.localId}_${i}`)
        .attr('transform', `translate(${_style.cell[3]}, ${drawY})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(d.REMAIN)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[3]+2}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${_style.cell[3]}, ${d.y}) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => { 
          if(_style.requests) {
            _style.requests[2].filters.DISC = d.DISC
            this.$emit('request-action', _style.requests[2])
          }
        })

        discITR
        .append('rect')
        .attr('transform', `translate(${_style.cell[4]}, ${drawY - 5})`)
        .attr('width', _style.width).attr('height', 12)
        .attr('fill', '#F4F5F5')

        discITR
        .append('rect')
        .attr('transform', `translate(${_style.cell[4]}, ${drawY - 5})`)
        .attr('width', 0).attr('height', 12)
        .attr('stroke-width', 0.3).attr('stroke', _style.sColor).attr('fill', _style.bColor).style('opacity', _style.opacity)
        .transition().duration(500).attr('width', (d.PROG/100) * _style.width)

        discITR
        .append('text')
        .attr('y', drawY + 2) 
        .attr('x', () => {
          if (d.PROG < 25) return _style.cell[4]; else return _style.cell[4] + 20
        })
        .style('font-family', 'roboto').style('font-size', 9).attr('alignment-baseline', 'middle')
        .text(`${d.PROG.toFixed(1)}%`)
        .style('fill', () => {
          if (d.PROG < 25) return _style.tColor2; else return _style.tColor1
        })
        .attr('text-anchor', () => {
          if (d.PROG < 25) return 'start'; else return 'end'
        })
        .transition().duration(500)
        .attr('x', () => {
          if (d.PROG < 25) return _style.cell[4] + d.PROG+1; else return _style.cell[4] + ((d.PROG/100) * _style.width) -1
        })
        drawY += _style.lineHeight
      })
    },
  }
}
