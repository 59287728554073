export default {
  data: () => ({
    positioned: [
      { ID: 'S1L'           ,Type:'group' ,Level: null  ,Blk: null  ,distx: -225 ,disty: -84 ,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S1R'           ,Type:'group' ,Level: null  ,Blk: null  ,distx: 206  ,disty: -46 ,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,}, 
      { ID: 'S1R3_LS'       ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 942  ,disty: 568, x: null, y: null, x1: 899  , y1: 871 ,tx:null ,ty: null,opacity: 0.3 ,Status:null,},
      { ID: 'S1R1_LQ'       ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 822  ,disty: 445, x: null, y: null, x1: 796  , y1: 416 ,tx:null ,ty: null,opacity: 0.3 ,Status:null,},
      { ID: 'S1L_CRANE'     ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 574  ,disty: 406, x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: 0   ,Status:null,},
      { ID: 'S1R_CX19'      ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 1100 ,disty: 556, x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: 0   ,Status:null,},
      { ID: 'S1R2_CD'       ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 910  ,disty: 539, x: null, y: null, x1: 884  , y1: 682 ,tx:null ,ty: null,opacity: 1   ,Status:null,},
      { ID: 'S1R_CX1718'    ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 1108 ,disty: 499, x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: 0   ,Status:null,},
      { ID: 'S1R1_LD'       ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 918  ,disty: 516, x: null, y: null, x1: 892  , y1: 487 ,tx:null ,ty: null,opacity: 1   ,Status:null,},
      { ID: 'S1L2_MD'       ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 489  ,disty: 459, x: null, y: null, x1: 479  , y1: 678 ,tx:null ,ty: null,opacity: 1   ,Status:null,},
      { ID: 'S1L1_UD'       ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 489  ,disty: 402, x: null, y: null, x1: 479  , y1: 435 ,tx:null ,ty: null,opacity: 1   ,Status:null,},
      { ID: 'S1L4_DES'      ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 600  ,disty: 330, x: null, y: null, x1: 590  , y1: 123 ,tx:null ,ty: null,opacity: 0.3 ,Status:null,},
      { ID: 'S1L3_DSM'      ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 634  ,disty: 427, x: null, y: null, x1: 624  , y1: 302 ,tx:null ,ty: null,opacity: 0.3 ,Status:null,},
      { ID: 'S1L1_UDBLOCK'  ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 678  ,disty: 469, x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: 0   ,Status:null,},
      { ID: 'S1R1_HD'       ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 823  ,disty: 506, x: null, y: null, x1: 797  , y1: 477 ,tx:null ,ty: null,opacity: 0.3 ,Status:null,},
      { ID: 'S1R2_ELS'      ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 1159 ,disty: 605, x: null, y: null, x1: 1133 , y1: 748 ,tx:null ,ty: null,opacity: 0.3 ,Status:null,},
      { ID: 'S1L2_FLARE'    ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 729  ,disty: 410, x: null, y: null, x1: 719  , y1: 629 ,tx:null ,ty: null,opacity: 0.3 ,Status:null,},
      { ID: 'S1R_ST'        ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 913  ,disty: 518, x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: 0   ,Status:null,},
      { ID: 'S1L_CRANES'    ,Type:'item'  ,Level: null  ,Blk: null  ,distx: 509  ,disty: 420, x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: 0   ,Status:null,},
      { ID: 'S2L1_UD'       ,Type:'group' ,Level: null  ,Blk: null  ,distx: 235  ,disty:  69 ,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2L2_MD'       ,Type:'group' ,Level: null  ,Blk: null  ,distx: 192  ,disty: -175,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2R1_LD'       ,Type:'group' ,Level: null  ,Blk: null  ,distx: -223 ,disty: 40  ,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2R2_CD'       ,Type:'group' ,Level: null  ,Blk: null  ,distx: -193 ,disty: -192,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2L1_UD_M'     ,Type:'group' ,Level: null  ,Blk: null  ,distx: 0    ,disty: -161,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2L1_UD_D'     ,Type:'group' ,Level: null  ,Blk: null  ,distx: 0    ,disty: 152 ,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2L2_MD_M'     ,Type:'group' ,Level: null  ,Blk: null  ,distx: 54   ,disty: -136,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2L2_MD_D'     ,Type:'group' ,Level: null  ,Blk: null  ,distx: 56   ,disty: 120 ,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2R1_LD_M'     ,Type:'group' ,Level: null  ,Blk: null  ,distx: -47  ,disty: -154,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2R1_LD_D'     ,Type:'group' ,Level: null  ,Blk: null  ,distx: -47  ,disty: 108 ,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2R2_CD_M'     ,Type:'group' ,Level: null  ,Blk: null  ,distx: -83  ,disty: -192,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2R2_CD_D'     ,Type:'group' ,Level: null  ,Blk: null  ,distx: -82  ,disty: 76  ,x: null, y: null, x1: null , y1: null,tx:null ,ty: null,opacity: null,Status:null,},
      { ID: 'S2L1_UD_M_UP75',Type:'item'  ,Level:'MEZZ' ,Blk:'UP75' ,distx: 820	 ,disty: 342, x: null, y: null, x1: 774  , y1: 408, tx: 800, ty: 440, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UP77',Type:'item'  ,Level:'MEZZ' ,Blk:'UP77' ,distx: 822	 ,disty: 353, x: null, y: null, x1: 834  , y1: 433, tx: 854, ty: 493, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UP76',Type:'item'  ,Level:'MEZZ' ,Blk:'UP76' ,distx: 765	 ,disty: 433, x: null, y: null, x1: 611  , y1: 645, tx: 579, ty: 705, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UP78',Type:'item'  ,Level:'MEZZ' ,Blk:'UP78' ,distx: 802	 ,disty: 453, x: null, y: null, x1: 749  , y1: 713, tx: 724, ty: 776, opacity: null,Status:'paint',},
      { ID: 'S2L1_UD_M_UP7A',Type:'item'  ,Level:'MEZZ' ,Blk:'UP7A' ,distx: 894	 ,disty: 386, x: null, y: null, x1: 1028 , y1: 492, tx: 1073,ty: 474, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UP7C',Type:'item'  ,Level:'MEZZ' ,Blk:'UP7C' ,distx: 883	 ,disty: 400, x: null, y: null, x1: 1022 , y1: 652, tx: 1130,ty: 641, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UP7B',Type:'item'  ,Level:'MEZZ' ,Blk:'UP7B' ,distx: 857	 ,disty: 481, x: null, y: null, x1: 854  , y1: 767, tx: 840, ty: 825, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UP7D',Type:'item'  ,Level:'MEZZ' ,Blk:'UP7D' ,distx: 936	 ,disty: 411, x: null, y: null, x1: 1130 , y1: 536, tx: 1208,ty: 581, opacity: null,Status:'assemb',},
      { ID: 'S2L1_UD_M_UV7C',Type:'item'  ,Level:'MEZZ' ,Blk:'UV7C' ,distx: 869	 ,disty: 407, x: null, y: null, x1: 821  , y1: 568, tx: 804, ty: 685, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_MV84',Type:'item'  ,Level:'MEZZ' ,Blk:'MV84' ,distx: 971	 ,disty: 381, x: null, y: null, x1: 1175 , y1: 465, tx: 1218,ty: 442, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UK13',Type:'item'  ,Level:'MEZZ' ,Blk:'UK13' ,distx: 992	 ,disty: 372, x: null, y: null, x1: 1148 , y1: 494, tx: 1185,ty: 517, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD72',Type:'item'  ,Level:'DECK' ,Blk:'UD72' ,distx: 724	 ,disty: 595, x: null, y: null, x1: 716  , y1: 291, tx: 701, ty: 364, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD75',Type:'item'  ,Level:'DECK' ,Blk:'UD75' ,distx: 780	 ,disty: 619, x: null, y: null, x1: 824  , y1: 342, tx: 804, ty: 409, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD71',Type:'item'  ,Level:'DECK' ,Blk:'UD71' ,distx: 656	 ,disty: 608, x: null, y: null, x1: 545  , y1: 387, tx: 536, ty: 503, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD74',Type:'item'  ,Level:'DECK' ,Blk:'UD74' ,distx: 708	 ,disty: 632, x: null, y: null, x1: 648  , y1: 438, tx: 639, ty: 556, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD77',Type:'item'  ,Level:'DECK' ,Blk:'UD77' ,distx: 793	 ,disty: 666, x: null, y: null, x1: 806  , y1: 479, tx: 908, ty: 464, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD7A',Type:'item'  ,Level:'DECK' ,Blk:'UD7A' ,distx: 880	 ,disty: 690, x: null, y: null, x1: 1024 , y1: 459, tx: 1013,ty: 517, opacity: null,Status:'assemb',},
      { ID: 'S2L1_UD_D_UD7D',Type:'item'  ,Level:'DECK' ,Blk:'UD7D' ,distx: 935	 ,disty: 699, x: null, y: null, x1: 1127 , y1: 490, tx: 1117,ty: 566, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD76',Type:'item'  ,Level:'DECK' ,Blk:'UD76' ,distx: 740	 ,disty: 707, x: null, y: null, x1: 712  , y1: 553, tx: 716, ty: 637, opacity: null,Status:'paint',},
      { ID: 'S2L1_UD_D_UD79',Type:'item'  ,Level:'DECK' ,Blk:'UD79' ,distx: 813	 ,disty: 701, x: null, y: null, x1: 851  , y1: 556, tx: 853, ty: 666, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD7C',Type:'item'  ,Level:'DECK' ,Blk:'UD7C' ,distx: 868	 ,disty: 712, x: null, y: null, x1: 956  , y1: 588, tx: 956, ty: 719, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD70',Type:'item'  ,Level:'DECK' ,Blk:'UD70' ,distx: 629	 ,disty: 676, x: null, y: null, x1: 417  , y1: 538, tx: 432, ty: 631, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD73',Type:'item'  ,Level:'DECK' ,Blk:'UD73' ,distx: 681	 ,disty: 698, x: null, y: null, x1: 519  , y1: 586, tx: 519, ty: 665, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD78',Type:'item'  ,Level:'DECK' ,Blk:'UD78' ,distx: 794	 ,disty: 760, x: null, y: null, x1: 734  , y1: 696, tx: 745, ty: 769, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_D_UD7B',Type:'item'  ,Level:'DECK' ,Blk:'UD7B' ,distx: 850	 ,disty: 783, x: null, y: null, x1: 836  , y1: 740, tx: 865, ty: 822, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UK12',Type:'item'  ,Level:'MEZZ' ,Blk:'UK12' ,distx: 811	 ,disty: 271, x: null, y: null, x1: 872  , y1: 310, tx: 920, ty: 321, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UV72',Type:'item'  ,Level:'MEZZ' ,Blk:'UV72' ,distx: 666	 ,disty: 314, x: null, y: null, x1: 745  , y1: 518, tx: 870, ty: 528, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_MZ82',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ82' ,distx: 702	 ,disty: 283, x: null, y: null, x1: 656  , y1: 405, tx: 620, ty: 401, opacity: null,Status:'assemb',},
      { ID: 'S2L1_UD_M_MZ85',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ85' ,distx: 652	 ,disty: 357, x: null, y: null, x1: 479  , y1: 586, tx: 477, ty: 660, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_MZ81',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ81' ,distx: 643	 ,disty: 314, x: null, y: null, x1: 568  , y1: 462, tx: 538, ty: 474, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UV70',Type:'item'  ,Level:'MEZZ' ,Blk:'UV70' ,distx: 638	 ,disty: 375, x: null, y: null, x1: 687  , y1: 585, tx: 699, ty: 641, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UD7E',Type:'item'  ,Level:'MEZZ' ,Blk:'UD7E' ,distx: 756	 ,disty: 358, x: null, y: null, x1: 920  , y1: 378, tx: 1051,ty: 366, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_MZ84',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ84' ,distx: 910	 ,disty: 377, x: null, y: null, x1: 980  , y1: 571, tx: 1100,ty: 599, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_MZ83',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ83' ,distx: 861	 ,disty: 425, x: null, y: null, x1: 893  , y1: 651, tx: 884, ty: 727, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_MV82',Type:'item'  ,Level:'MEZZ' ,Blk:'MV82' ,distx: 708	 ,disty: 274, x: null, y: null, x1: 749  , y1: 329, tx: 763, ty: 306, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_UK11',Type:'item'  ,Level:'MEZZ' ,Blk:'UK11' ,distx: 684	 ,disty: 293, x: null, y: null, x1: 699  , y1: 337, tx: 678, ty: 317, opacity: null,Status:'erec',},
      { ID: 'S2L1_UD_M_MV85',Type:'item'  ,Level:'MEZZ' ,Blk:'MV85' ,distx: 916	 ,disty: 367, x: null, y: null, x1: 1117 , y1: 650, tx: 1193,ty: 776, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MP52',Type:'item'  ,Level:'MEZZ' ,Blk:'MP52' ,distx: 770	 ,disty: 364, x: null, y: null, x1: 721  , y1: 421, tx: 699, ty: 451, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MP63',Type:'item'  ,Level:'MEZZ' ,Blk:'MP63' ,distx: 761	 ,disty: 378, x: null, y: null, x1: 730  , y1: 469, tx: 739, ty: 550, opacity: null,Status:'paint',},
      { ID: 'S2L2_MD_M_MP50',Type:'item'  ,Level:'MEZZ' ,Blk:'MP50' ,distx: 729	 ,disty: 449, x: null, y: null, x1: 599  , y1: 558, tx: 571, ty: 585, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MP5A',Type:'item'  ,Level:'MEZZ' ,Blk:'MP5A' ,distx: 952	 ,disty: 447, x: null, y: null, x1: 1018 , y1: 507, tx: 1064,ty: 491, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MP57',Type:'item'  ,Level:'MEZZ' ,Blk:'MP57' ,distx: 893	 ,disty: 424, x: null, y: null, x1: 918  , y1: 463, tx: 976, ty: 449, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MP56',Type:'item'  ,Level:'MEZZ' ,Blk:'MP56' ,distx: 828	 ,disty: 438, x: null, y: null, x1: 784  , y1: 524, tx: 755, ty: 603, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MP55',Type:'item'  ,Level:'MEZZ' ,Blk:'MP55' ,distx: 808	 ,disty: 488, x: null, y: null, x1: 745  , y1: 640, tx: 736, ty: 696, opacity: null,Status:'assemb',},
      { ID: 'S2L2_MD_M_MP58',Type:'item'  ,Level:'MEZZ' ,Blk:'MP58' ,distx: 868	 ,disty: 520, x: null, y: null, x1: 855  , y1: 695, tx: 846, ty: 748, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MP59',Type:'item'  ,Level:'MEZZ' ,Blk:'MP59' ,distx: 881	 ,disty: 469, x: null, y: null, x1: 1051 , y1: 658, tx: 1055,ty: 748, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD52',Type:'item'  ,Level:'DECK' ,Blk:'MD52' ,distx: 741	 ,disty: 574, x: null, y: null, x1: 670  , y1: 325, tx: 738, ty: 317, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD54',Type:'item'  ,Level:'DECK' ,Blk:'MD54' ,distx: 797	 ,disty: 606, x: null, y: null, x1: 792  , y1: 391, tx: 857, ty: 375, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD57',Type:'item'  ,Level:'DECK' ,Blk:'MD57' ,distx: 892	 ,disty: 669, x: null, y: null, x1: 997  , y1: 505, tx: 1058,ty: 482, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD5A',Type:'item'  ,Level:'DECK' ,Blk:'MD5A' ,distx: 953	 ,disty: 690, x: null, y: null, x1: 1142 , y1: 567, tx: 1200,ty: 549, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD51',Type:'item'  ,Level:'DECK' ,Blk:'MD51' ,distx: 672	 ,disty: 594, x: null, y: null, x1: 531  , y1: 398, tx: 517, ty: 512, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD53',Type:'item'  ,Level:'DECK' ,Blk:'MD53' ,distx: 708	 ,disty: 616, x: null, y: null, x1: 645  , y1: 458, tx: 641, ty: 582, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD56',Type:'item'  ,Level:'DECK' ,Blk:'MD56' ,distx: 812	 ,disty: 657, x: null, y: null, x1: 860  , y1: 553, tx: 861, ty: 678, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD59',Type:'item'  ,Level:'DECK' ,Blk:'MD59' ,distx: 884	 ,disty: 694, x: null, y: null, x1: 1008 , y1: 627, tx: 993, ty: 746, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD50',Type:'item'  ,Level:'DECK' ,Blk:'MD50' ,distx: 644	 ,disty: 658, x: null, y: null, x1: 439  , y1: 521, tx: 412, ty: 607, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD55',Type:'item'  ,Level:'DECK' ,Blk:'MD55' ,distx: 805	 ,disty: 740, x: null, y: null, x1: 783  , y1: 693, tx: 760, ty: 760, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD58',Type:'item'  ,Level:'DECK' ,Blk:'MD58' ,distx: 854	 ,disty: 763, x: null, y: null, x1: 911  , y1: 754, tx: 889, ty: 828, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_D_MD5B',Type:'item'  ,Level:'DECK' ,Blk:'MD5B' ,distx: 944	 ,disty: 748, x: null, y: null, x1: 1141 , y1: 716, tx: 1128,ty: 793, opacity: null,Status:'assemb',},
      { ID: 'S2L2_MD_M_MZ66',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ66' ,distx: 715	 ,disty: 324, x: null, y: null, x1: 628  , y1: 363, tx: 726, ty: 341, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MZ65',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ65' ,distx: 640	 ,disty: 364, x: null, y: null, x1: 513  , y1: 434, tx: 489, ty: 539, opacity: null,Status:'paint',},
      { ID: 'S2L2_MD_M_MZ64',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ64' ,distx: 799	 ,disty: 353, x: null, y: null, x1: 762  , y1: 417, tx: 816, ty: 389, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MZ63',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ63' ,distx: 672	 ,disty: 368, x: null, y: null, x1: 632  , y1: 469, tx: 654, ty: 583, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MZ62',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ62' ,distx: 817	 ,disty: 408, x: null, y: null, x1: 836  , y1: 524, tx: 827, ty: 629, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MZ61',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ61' ,distx: 877	 ,disty: 446, x: null, y: null, x1: 986  , y1: 596, tx: 964, ty: 698, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MV53',Type:'item'  ,Level:'MEZZ' ,Blk:'MV53' ,distx: 741	 ,disty: 391, x: null, y: null, x1: 796  , y1: 484, tx: 844, ty: 459, opacity: null,Status:'erec',},
      { ID: 'S2L2_MD_M_MV56',Type:'item'  ,Level:'MEZZ' ,Blk:'MV56' ,distx: 834	 ,disty: 461, x: null, y: null, x1: 949  , y1: 596, tx: 930, ty: 629, opacity: null,Status:'assemb',},
      { ID: 'S2L2_MD_M_UV79',Type:'item'  ,Level:'MEZZ' ,Blk:'UV79' ,distx: 825	 ,disty: 425, x: null, y: null, x1: 996  , y1: 549, tx: 1060,ty: 563, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LP3A',Type:'item'  ,Level:'MEZZ' ,Blk:'LP3A' ,distx: 753  ,disty: 402, x: null, y: null, x1: 943  , y1: 577, tx: 918, ty: 652, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LP32',Type:'item'  ,Level:'MEZZ' ,Blk:'LP32' ,distx: 672  ,disty: 318, x: null, y: null, x1: 769  , y1: 324, tx: 825, ty: 305, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LP35',Type:'item'  ,Level:'MEZZ' ,Blk:'LP35' ,distx: 724  ,disty: 339, x: null, y: null, x1: 872  , y1: 370, tx: 927, ty: 355, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LP38',Type:'item'  ,Level:'MEZZ' ,Blk:'LP38' ,distx: 780  ,disty: 367, x: null, y: null, x1: 978  , y1: 422, tx: 1027,ty: 407, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LP3B',Type:'item'  ,Level:'MEZZ' ,Blk:'LP3B' ,distx: 821  ,disty: 388, x: null, y: null, x1: 1070 , y1: 468, tx: 1126,ty: 454, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LP3D',Type:'item'  ,Level:'MEZZ' ,Blk:'LP3D' ,distx: 880  ,disty: 414, x: null, y: null, x1: 1180 , y1: 519, tx: 1241,ty: 510, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LP30',Type:'item'  ,Level:'MEZZ' ,Blk:'LP30' ,distx: 613  ,disty: 395, x: null, y: null, x1: 554  , y1: 529, tx: 528, ty: 574, opacity: null,Status:'paint',},
      { ID: 'S2R1_LD_M_LP33',Type:'item'  ,Level:'MEZZ' ,Blk:'LP33' ,distx: 626  ,disty: 400, x: null, y: null, x1: 617  , y1: 557, tx: 634, ty: 619, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LP36',Type:'item'  ,Level:'MEZZ' ,Blk:'LP36' ,distx: 695  ,disty: 433, x: null, y: null, x1: 737  , y1: 617, tx: 736, ty: 679, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LP39',Type:'item'  ,Level:'MEZZ' ,Blk:'LP39' ,distx: 735  ,disty: 457, x: null, y: null, x1: 827  , y1: 662, tx: 843, ty: 720, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_MP45',Type:'item'  ,Level:'MEZZ' ,Blk:'MP45' ,distx: 685  ,disty: 344, x: null, y: null, x1: 719  , y1: 454, tx: 706, ty: 521, opacity: null,Status:'paint',},
      { ID: 'S2R1_LD_M_LP3C',Type:'item'  ,Level:'MEZZ' ,Blk:'LP3C' ,distx: 799  ,disty: 408, x: null, y: null, x1: 1006 , y1: 619, tx: 1003,ty: 752, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LP3E',Type:'item'  ,Level:'MEZZ' ,Blk:'LP3E' ,distx: 851  ,disty: 447, x: null, y: null, x1: 1105 , y1: 654, tx: 1184,ty: 715, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD32',Type:'item'  ,Level:'DECK' ,Blk:'LD32' ,distx: 668  ,disty: 554, x: null, y: null, x1: 680  , y1: 305, tx: 742, ty: 293, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD35',Type:'item'  ,Level:'DECK' ,Blk:'LD35' ,distx: 723  ,disty: 569, x: null, y: null, x1: 795  , y1: 347, tx: 853, ty: 347, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD38',Type:'item'  ,Level:'DECK' ,Blk:'LD38' ,distx: 780  ,disty: 616, x: null, y: null, x1: 932  , y1: 436, tx: 980, ty: 414, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD3B',Type:'item'  ,Level:'DECK' ,Blk:'LD3B' ,distx: 819  ,disty: 637, x: null, y: null, x1: 1030 , y1: 486, tx: 1104,ty: 456, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD3D',Type:'item'  ,Level:'DECK' ,Blk:'LD3D' ,distx: 879  ,disty: 659, x: null, y: null, x1: 1169 , y1: 543, tx: 1235,ty: 521, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD31',Type:'item'  ,Level:'DECK' ,Blk:'LD31' ,distx: 593  ,disty: 568, x: null, y: null, x1: 526  , y1: 383, tx: 509, ty: 486, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD34',Type:'item'  ,Level:'DECK' ,Blk:'LD34' ,distx: 650  ,disty: 587, x: null, y: null, x1: 645  , y1: 430, tx: 637, ty: 547, opacity: null,Status:'assemb',},
      { ID: 'S2R1_LD_D_LD37',Type:'item'  ,Level:'DECK' ,Blk:'LD37' ,distx: 701  ,disty: 615, x: null, y: null, x1: 778  , y1: 497, tx: 766, ty: 609, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD3A',Type:'item'  ,Level:'DECK' ,Blk:'LD3A' ,distx: 750  ,disty: 637, x: null, y: null, x1: 886  , y1: 547, tx: 877, ty: 665, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD3C',Type:'item'  ,Level:'DECK' ,Blk:'LD3C' ,distx: 775  ,disty: 648, x: null, y: null, x1: 992  , y1: 597, tx: 1004,ty: 734, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD30',Type:'item'  ,Level:'DECK' ,Blk:'LD30' ,distx: 571  ,disty: 641, x: null, y: null, x1: 429  , y1: 518, tx: 412, ty: 579, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD33',Type:'item'  ,Level:'DECK' ,Blk:'LD33' ,distx: 605  ,disty: 659, x: null, y: null, x1: 536  , y1: 565, tx: 540, ty: 636, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD36',Type:'item'  ,Level:'DECK' ,Blk:'LD36' ,distx: 684  ,disty: 694, x: null, y: null, x1: 686  , y1: 636, tx: 672, ty: 688, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD39',Type:'item'  ,Level:'DECK' ,Blk:'LD39' ,distx: 728  ,disty: 705, x: null, y: null, x1: 794  , y1: 676, tx: 806, ty: 746, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_D_LD3E',Type:'item'  ,Level:'DECK' ,Blk:'LD3E' ,distx: 849  ,disty: 695, x: null, y: null, x1: 1125 , y1: 673, tx: 1104,ty: 773, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_MZ42',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ42' ,distx: 632  ,disty: 282, x: null, y: null, x1: 657  , y1: 379, tx: 647, ty: 371, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_MZ41',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ41' ,distx: 571  ,disty: 329, x: null, y: null, x1: 577  , y1: 442, tx: 567, ty: 435, opacity: null,Status:'paint',},
      { ID: 'S2R1_LD_M_MZ45',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ45' ,distx: 680  ,disty: 333, x: null, y: null, x1: 767  , y1: 463, tx: 760, ty: 544, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_MZ44',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ44' ,distx: 811  ,disty: 452, x: null, y: null, x1: 955  , y1: 664, tx: 953, ty: 707, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LV3A',Type:'item'  ,Level:'MEZZ' ,Blk:'LV3A' ,distx: 752  ,disty: 393, x: null, y: null, x1: 892  , y1: 542, tx: 918, ty: 652, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_LV3C',Type:'item'  ,Level:'MEZZ' ,Blk:'LV3C' ,distx: 841  ,disty: 420, x: null, y: null, x1: 1115 , y1: 612, tx: 1176,ty: 597, opacity: null,Status:'erec',},
      { ID: 'S2R1_LD_M_MZ43',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ43' ,distx: 819  ,disty: 387, x: null, y: null, x1: 1033 , y1: 542, tx: 1088,ty: 529, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_D_CD11',Type:'item'  ,Level:'DECK' ,Blk:'CD11' ,distx: 592  ,disty: 573, x: null, y: null, x1: 576  , y1: 393, tx: 688, ty: 379, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_D_CD14',Type:'item'  ,Level:'DECK' ,Blk:'CD14' ,distx: 709  ,disty: 598, x: null, y: null, x1: 828  , y1: 399, tx: 899, ty: 375, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_D_CD13',Type:'item'  ,Level:'DECK' ,Blk:'CD13' ,distx: 657  ,disty: 623, x: null, y: null, x1: 706  , y1: 474, tx: 802, ty: 460, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_D_CD17',Type:'item'  ,Level:'DECK' ,Blk:'CD17' ,distx: 785  ,disty: 606, x: null, y: null, x1: 961  , y1: 445, tx: 1032,ty: 428, opacity: null,Status:'assemb',},
      { ID: 'S2R2_CD_D_CD1A',Type:'item'  ,Level:'DECK' ,Blk:'CD1A' ,distx: 820  ,disty: 632, x: null, y: null, x1: 1076 , y1: 499, tx: 1159,ty: 483, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_D_CD1C',Type:'item'  ,Level:'DECK' ,Blk:'CD1C' ,distx: 883  ,disty: 670, x: null, y: null, x1: 1212 , y1: 573, tx: 1291,ty: 546, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_D_CD10',Type:'item'  ,Level:'DECK' ,Blk:'CD10' ,distx: 566  ,disty: 624, x: null, y: null, x1: 496  , y1: 491, tx: 473, ty: 587, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_D_CD12',Type:'item'  ,Level:'DECK' ,Blk:'CD12' ,distx: 627  ,disty: 641, x: null, y: null, x1: 616  , y1: 538, tx: 610, ty: 644, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_D_CD16',Type:'item'  ,Level:'DECK' ,Blk:'CD16' ,distx: 714  ,disty: 621, x: null, y: null, x1: 830  , y1: 510, tx: 970, ty: 530, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_D_CD19',Type:'item'  ,Level:'DECK' ,Blk:'CD19' ,distx: 755  ,disty: 666, x: null, y: null, x1: 948  , y1: 587, tx: 1057,ty: 579, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_D_CD15',Type:'item'  ,Level:'DECK' ,Blk:'CD15' ,distx: 681  ,disty: 697, x: null, y: null, x1: 746  , y1: 625, tx: 742, ty: 702, opacity: null,Status:'paint',},
      { ID: 'S2R2_CD_D_CD18',Type:'item'  ,Level:'DECK' ,Blk:'CD18' ,distx: 728  ,disty: 698, x: null, y: null, x1: 862  , y1: 665, tx: 875, ty: 760, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_D_CD1B',Type:'item'  ,Level:'DECK' ,Blk:'CD1B' ,distx: 803  ,disty: 691, x: null, y: null, x1: 1069 , y1: 648, tx: 1186,ty: 644, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_CV13',Type:'item'  ,Level:'MEZZ' ,Blk:'CV13' ,distx: 672  ,disty: 369, x: null, y: null, x1: 902  , y1: 430, tx: 980, ty: 380, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_CV10',Type:'item'  ,Level:'MEZZ' ,Blk:'CV10' ,distx: 572  ,disty: 306, x: null, y: null, x1: 575  , y1: 376, tx: 567, ty: 486, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_CV11',Type:'item'  ,Level:'MEZZ' ,Blk:'CV11' ,distx: 607  ,disty: 318, x: null, y: null, x1: 842  , y1: 372, tx: 939, ty: 351, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_MZ27',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ27' ,distx: 602  ,disty: 308, x: null, y: null, x1: 709  , y1: 414, tx: 806, ty: 396, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_MZ26',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ26' ,distx: 579  ,disty: 361, x: null, y: null, x1: 668  , y1: 483, tx: 658, ty: 535, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_MZ25',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ25' ,distx: 643  ,disty: 336, x: null, y: null, x1: 789  , y1: 460, tx: 777, ty: 538, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_MZ28',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ28' ,distx: 609  ,disty: 390, x: null, y: null, x1: 759  , y1: 591, tx: 750, ty: 666, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_CV15',Type:'item'  ,Level:'MEZZ' ,Blk:'CV15' ,distx: 681  ,disty: 444, x: null, y: null, x1: 869  , y1: 664, tx: 855, ty: 706, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_CP13',Type:'item'  ,Level:'MEZZ' ,Blk:'CP13' ,distx: 731  ,disty: 352, x: null, y: null, x1: 939  , y1: 466, tx: 987, ty: 448, opacity: null,Status:'paint',},
      { ID: 'S2R2_CD_M_MZ23',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ23' ,distx: 706  ,disty: 380, x: null, y: null, x1: 889  , y1: 522, tx: 867, ty: 574, opacity: null,Status:'assemb',},
      { ID: 'S2R2_CD_M_MZ21',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ21' ,distx: 714  ,disty: 371, x: null, y: null, x1: 963  , y1: 543, tx: 954, ty: 634, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_MZ22',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ22' ,distx: 719  ,disty: 367, x: null, y: null, x1: 964  , y1: 490, tx: 1041,ty: 466, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_MZ24',Type:'item'  ,Level:'MEZZ' ,Blk:'MZ24' ,distx: 799  ,disty: 412, x: null, y: null, x1: 1096 , y1: 609, tx: 1123,ty: 702, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_CV19',Type:'item'  ,Level:'MEZZ' ,Blk:'CV19' ,distx: 773  ,disty: 400, x: null, y: null, x1: 1041 , y1: 594, tx: 1020,ty: 664, opacity: null,Status:'assemb',},
      { ID: 'S2R2_CD_M_CV17',Type:'item'  ,Level:'MEZZ' ,Blk:'CV17' ,distx: 811  ,disty: 335, x: null, y: null, x1: 1096 , y1: 476, tx: 1145,ty: 464, opacity: null,Status:'erec',},
      { ID: 'S2R2_CD_M_CV1B',Type:'item'  ,Level:'MEZZ' ,Blk:'CV1B' ,distx: 811  ,disty: 455, x: null, y: null, x1: 1223 , y1: 676, tx: 1204,ty: 732, opacity: null,Status:'erec',},
    ]
  }),
}