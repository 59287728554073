import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs
// import Defs from '../../../../../../includes/primitives/Color_Defs'
import Filter from '../../../../../../includes/primitives/Filter_Defs'

export default {
  data: () => ({

  }),


  methods: {
    chart_QVD_Summary(qvd) {

      var config = ['#44A9DF', '#4CAE4E','#FF75A5',]
    
      let boxDistance = 10
      let drawY = 40
      let barProg = ((this.DataItems[1].value - this.DataItems[3].value)/this.DataItems[0].value) * 100

      // -------------------------------Color defs
      let color_A1_box = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `${this.localId}_a1_box_Gradient`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      color_A1_box.append('stop').attr('stop-color', '#d4eaf3').attr('offset', '0')
      color_A1_box.append('stop').attr('stop-color', '#A3D0E8').attr('offset', '1')

      let color_A1_bar = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `${this.localId}_a1_bar_Gradient`).attr('x1', 0.5).attr('y1', 0.03).attr('x2', 0.5).attr('y2', 1.37)
      color_A1_bar.append('stop').attr('stop-color', '#83d2f5').attr('offset', '0')
      color_A1_bar.append('stop').attr('stop-color', '#44a9df').attr('offset', '1')

      let color_B1_box = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `${this.localId}_b1_box_Gradient`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      color_B1_box.append('stop').attr('stop-color', '#ddf1b6').attr('offset', '0')
      color_B1_box.append('stop').attr('stop-color', '#c6e481').attr('offset', '1')

      let color_B1_bar = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `${this.localId}_b1_bar_Gradient`).attr('x1', 0.5).attr('y1', 0.03).attr('x2', 0.5).attr('y2', 1.37)
      color_B1_bar.append('stop').attr('stop-color', '#d7e549').attr('offset', '0')
      color_B1_bar.append('stop').attr('stop-color', '#9bcf5c').attr('offset', '1')

      // ----------------------------------------------------------------------------------------------------------
      let summary = this.svg
      .append('g')
      .attr('id', `${this.localId}__summary`)
      .attr('transform', `translate(5, 0.5)`)


      this.DataItems.forEach((data,i) => {
        if (i == 3) return
        summary
        .append('rect')
        .attr('id', `${this.localId}__summary_box_${i}`)
        .attr('x', boxDistance).attr('y', drawY).attr('rx', 5).attr('ry', 5).attr('width', 80).attr('height', 40).attr('fill', `url(#${this.localId}_${qvd}_box_Gradient)`)
        
        summary
        .append('text')
        .attr('id', `${this.localId}__summary_text_${i}`)
        .attr('x', boxDistance + 10).attr('y', drawY + 15).style('font-size',11).style('font-family','roboto').attr('fill', '#FFFFFF').text(data.title)

        summary
        .append('text')
        .attr('id', `${this.localId}__summary_text_${i}`)
        .attr('x', boxDistance + 70)
        .attr('y', drawY + 33)
        .style('font-size',16)
        .style('font-family','roboto')
        .attr('text-anchor', 'end')
        .attr('fill', config[i])
        .transition().duration(1000)
        .tween("text", function() {
          var i = d3.interpolateNumber(1, data.value)
          return function(t) {
          d3.select(this).text( Number(i(t).toFixed(0)).toLocaleString() )
          }
        })
        boxDistance += 90
      })

      summary
      .append('text')
      .attr('x', 10).attr('y', drawY + 60).style('font-size',11).style('font-family','roboto').attr('fill', '#757575').text('PCOW')

      summary
      .append('text')
      .attr('x', 90).attr('y', drawY + 60).style('font-size',14).style('font-family','roboto').attr('text-anchor', 'end').attr('fill', '#000').text(Number(this.DataItems[3].value).toLocaleString())
      
      summary // Background
        .append('rect')
        .attr('x', 10).attr('y', 135).attr('rx', 5).attr('ry', 5).attr('height', 16)
        .attr('width', 260)
        .attr('fill', '#E6E6E6')

        summary // actual Bar
        .append('rect')
        .attr('x', 10).attr('y', 135).attr('rx', 5).attr('ry', 5).attr('height', 16)
        .attr('width', 0)
        .attr('fill', `url(#${this.localId}_${qvd}_bar_Gradient)`)
        .transition().duration(500).attr('width', (barProg/100) * 260)
        
        let basePointX = (barProg/100) * 260 + 10
        
        if (barProg < 10) {
          summary
          .append('text')
          .attr('id', `${this.localId}_progress`)
          .attr('x', 0).attr('y', drawY + 107).style('font-size',11).style('font-family','roboto').attr('text-anchor', 'start').attr('fill', '#757575').text(`${(barProg.toFixed(1))}%`)
        } else {
          summary
          .append('text')
          .attr('x', 5).attr('y', drawY + 107).style('font-size',11).style('font-family','roboto').attr('text-anchor', 'end').attr('fill', '#fff').text(`${(barProg.toFixed(1))}%`)
          .transition().duration(500).attr('x', basePointX - 5)
        }
       
        summary
        .append('text')
        .attr('x', 265).attr('y', drawY + 92).style('font-size',11).style('font-family','roboto').attr('text-anchor', 'end').attr('fill', config[2]).text(Number(this.DataItems[2].value).toLocaleString())
        summary
        .append('text')
        .attr('x', 265).attr('y', drawY + 122).style('font-size',11).style('font-family','roboto').attr('text-anchor', 'end').attr('fill', config[0]).text(Number(this.DataItems[0].value).toLocaleString())

    }, 

  }
}
