import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    props:[
      {key:'SIZE', head1: 'Size',     x: 40,  tx: 30, cy: 0, textanchor: 'start'},
      {key:'TOTAL', head1: 'Total',   x: 170, tx: 170, cy: 0},
      {key:'FAB', head1: 'Fabricated',x: 230, tx: 230, cy: 0},
      {key:'DEL', head1: 'Delivered', x: 290, tx: 290, cy: 0},
      {key:'INST', head1: 'Installed',x: 350, tx: 350, cy: 0, color: 'green'},
      {key:'REM', head1: 'Remained',  x: 410, tx: 410, cy: 0, color: 'red'},
    ],
    width: 460,
    rowHeight: 18

  }),
  

  methods: {
    drawChart() {
      let PreOutfitting = this.svg
      .append('g')
      .attr('transform', `translate(0, 30.5)`)

      PreOutfitting
      .append('rect')
      .attr('x',20).attr('y', 5).attr('width',this.width - 20).attr('height',25).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)

      PreOutfitting.append('line').attr('x1',20).attr('y1',5).attr('x2',this.width).attr('y2',5).attr('stroke-width', 0.2).attr('stroke', '#000')
      PreOutfitting.append('line').attr('x1',20).attr('y1',30).attr('x2',this.width).attr('y2',30).attr('stroke-width', 0.2).attr('stroke', '#000')

      // header
      PreOutfitting
        .append('g')
        .attr('transform', `translate(0, 20)`)
        .selectAll('text')
        .data(this.props)
        .enter()
        .append('text')
        .attr('x', d => d.x)
        .attr('y', 2)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'middle')
        .attr('fill', '#333')
        .text(d => d.head1)

      let row_group = PreOutfitting
        .append('g')
        .attr('class', '_row_data_group')
        .attr('transform', `translate(0, 45)`)

      let line_group = PreOutfitting
        .append('g')
        .attr('class', '_row_line_group')
        .attr('transform', `translate(0, 50)`)

      this.DataItems.forEach((row, r) => {
        let keys = Object.keys(row)

        keys.forEach(key => {
          let idx = this.props.findIndex(e => e.key === key)
          row_group
            .append('text')
            .text(row[key])
            .attr('x', (d,i) => this.props[idx].tx)
            .attr('y', () => r * 18)
            .attr('text-anchor', (d, i) =>  this.props[idx].textanchor ? this.props[idx].textanchor : 'middle' )
            .style('font-family','roboto')
            .style('font-size',10)
            .style('font-weight', () => row.SIZE === 'Total' ? 'bold' : 'normal')
            .attr('fill', (d, i) => this.props[idx].color && row[key] !== 0 ? this.props[idx].color : '#757575' )

            line_group
            .append('line')
            .attr('x1',20).attr('y1', r * 18)
            .attr('x2', this.width).attr('y2', r * 18)
            .attr('stroke-width', 0.2).attr('stroke', '#E0E0E0')
        })
      })
    }

  }
}
