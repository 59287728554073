import * as d3 from 'd3'

export default {
  data: () => ({
    
  }),

  methods: {

    draw_Summary() {
      let s = this.style
      let d = this.dataSet
      let y_ = s.padding

      let Table = this.svg
      .append('g')
      .attr('id', `table-${this.localId}`)
      .attr('transform', `translate(1, 1)`)

      for(let i=0; i < d.count; i++) {
        y_ += s.box.height/2
        Table
        .append('text')
        .attr('id', `text_${this.localId}_${i}`)
        .attr('x', 0).attr('y', y_ + s.plusY)
        .attr('fill', s.text.fColor).attr('font-size', s.text.fSize)
        .attr('font-weight', s.text.fWeight)
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle') // hanging
        .attr('font-family', 'roboto')
        .text(d.text[i])

        d.width[i] = d3.select(`#text_${this.localId}_${i}`).node().getBBox().width
        d.y[i] = y_
        if(i < (d.count-1)) y_ += s.box.padding + ((s.box.height - s.box.sSize) / 2)
      }

      let maxLen = Math.max(...d.width) + s.padding
      let box_y = 0

      for(let i=0; i < d.count; i++) {

        d3.select(`#text_${this.localId}_${i}`)
        .attr('x', maxLen)

        Table
        .append('rect')
        .attr('x', maxLen + 5).attr('y', d.y[i] - (s.box.height/2))
        .attr('width', s.box.width)
        .attr('height', s.box.height)
        .attr('fill', s.box.xColor)
        .attr('stroke', s.box.sColor)
        .attr('stroke-width', s.box.sSize)
        .attr('opacity', s.box.opacity)
        .attr('rx', s.box.radius).attr('ry', s.box.radius)
        
        Table
        .append('text')
        .attr('id', `value_${this.localId}_${i}`)
        .attr('x', maxLen + 5 + s.box.width - 5)
        .attr('y', d.y[i] + s.plusY)
        .attr('fill', d.color[i]).attr('font-size', s.value.fSize)
        .attr('font-weight', s.value.fWeight)
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle') // hanging
        .attr('font-family', 'roboto')
        .style('cursor', 'pointer')
        .text(() => {
          if(d.unit[i] == '$') {
            return d.value[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          } else if(d.unit[i] == '%') {
            return `${d.value[i]}%`
          } else {
            return d.value[i]
          }
        })
        .call(this.event_Link, i)
      }
    },
  } 
}