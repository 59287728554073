import Defs from '../../../../../../includes/primitives/Color_Defs'
import Data from '../../../../../../includes/primitives/Color_Data'

import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    lineChartData: [],

    activity: 'Erection',

    skyline: {
                sizeType    : 'auto',
                fixedSize   : 450,
                minSize     : 280,
                startFloat  : 0,      // Fixed
                endFloat    : 0,      // Fixed
                weekEnd     : 'friday',
                font        : 'roboto', 

                box: {
                            font      : 'roboto', 
                            width     : 25,     // Fixed
                            height    : 13,     // Fixed
                            gap       : 1,      // Fixed
                },
                line : {
                            display   : 'Y',
                            height    : 300,
                            gap       : 1,
                },
                legend: {
                            x         : 20,
                            y         : 20,
                            font      : 'roboto', 
                            group: {
                                      tSize     : 11,
                                      tColot    : '#333',
                                      lineSpace : 15,
                            },
                            item: {
                                      tSize     : 10,
                                      tColor    : '#757575',
                                      space     : 10,
                            },
                            notes: {
                                      x         : 10,
                                      y         : 60,
                                      tSize     : 10,
                                      tColor    : '#bcbcbc',
                                      space     : 15,
                            },
                },
                dropdown: {
                            x         : 25,
                            y         : 90,
                            option: [
                                      {text: 'Steel Sutting',   value: 'SC'},
                                      {text: 'Block Assembly',  value: 'Assembly'},
                                      {text: 'Painting',        value: 'Painting'},
                                      {text: 'Block Erection',  value: 'Erection'},
                            ],
                },
    },

    timeline: { 
                week        : [],
                month       : [],
                year        : [],
                plan        : [],
                actual      : [],
                scale       : null,     

                weekCount   : [],
                weekSize    : null,    // Auto
                length      : null,    // Auto
                height      : null,    // Auto
                font        : 'roboto', 
                baseX       : 30,
                baseY       : 300,

                style: {
                        week: {
                                display   : 'Y',
                                height    : 15,
                                line      : 12,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 9,
                                tColor    : '#757575',
                        },
                        month: {
                                display   : 'Y',
                                height    : 14,
                                line      : 15,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 10,
                                tColor    : '#333',
                        },
                        year: {
                                display   : 'Y',
                                height    : 10,
                                line      : 20,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 11,
                                tColor    : '#000',
                        },
                },

    },

    style:[
              {
                group: 'Block Status',
                item: [
                          {
                            name        : 'Not Start',
                            refColumn   : 'STATUS',
                            codeValue   : 'NOT',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 10.5,
                            tColor      : '#000',
                            sWidth      : 0.5,
                            sColor      : '#bcbcbc',
                            bColor      : '#fff',
                            opacity     : 1,
                            radius      : 2,
                          },
                          {
                            name        : 'in Progress',
                            refColumn   : 'STATUS',
                            codeValue   : 'PROGRESS',
                            dataColumn  : 'PROGRESS',
                            tStyle      : 'regular',
                            tSize       : 10.5,
                            tColor      : '#000',
                            sWidth      : 0.5,
                            sColor      : '#83D2F5',
                            bColor      : '#B4E3FA',
                            opacity     : 1,
                            radius      : 2,
                          },
                          {
                            name        : 'Completion',
                            refColumn   : 'STATUS',
                            codeValue   : 'COMPLETED',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 10.5,
                            tColor      : '#000',
                            sWidth      : 0.5,
                            sColor      : '#8BC248',
                            bColor      : '#CDDC37',
                            opacity     : 1,
                            radius      : 2,
                          },
                ],
              },
    ],
  }),

  computed: {
  },

  methods: {
    setStyles() {

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
      
    },

  }
}