import * as d3 from 'd3'
import moment from 'moment'
export default {
  data: () => ({

  }),

  methods: {
    /*

    */


    init_gmx_TimelineValues_v1(_data) {
      // Add a timelineFloat to the start and end dates, and recalculate the start and end dates
      let startDate = moment(moment(`${_data.startDate} 00:00:00`).toDate()).add(-_data.float.start, 'months').toDate()
      let endDate = moment(moment(`${_data.endDate} 23:59:59`).toDate()).add(_data.float.end, 'months').toDate()
      
      // "To calculate the total number of months in the Timeline
      let noOfMonth = endDate.getMonth() - startDate.getMonth()
      let noOfYear = endDate.getYear() - startDate.getYear()
      noOfMonth = noOfMonth + (noOfYear*12)

      // ### Set Timeline-Values for the Year ### ---------------------------
      let prevYearDate_ = startDate

      for(var i=0; i<noOfMonth; i++) {

        // To calculate the value of the 1st day of the next month
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i)) //

        // For the Year ---
        if(d3.timeFormat('%Y')(prevYearDate_) != d3.timeFormat('%Y')(_nextMonthDate__)) {
          let midDateYear_ = new Date(
            d3.mean([
              prevYearDate_,                                                                      // 1st date of the year
              new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)  // last date of the year
            ])
          )
          _data.values.year.push({ name: d3.timeFormat('%Y')(midDateYear_), midDate: new Date(midDateYear_) })
          prevYearDate_ = _nextMonthDate__
        }
      }

      // Year remained
      let midDateYear_ = new Date(
        d3.mean([
          prevYearDate_,  // 1st date of the year
          endDate        // last date of the year
        ])
      )

      // ### Set Timeline-Values(timelineValues.year) for the Year ### ---------------------------
      // timelineValues {name: year, value: middle of year}
      _data.values.year.push({ name: d3.timeFormat('%Y')(midDateYear_), midDate: new Date(midDateYear_) })

      // ### Set Timeline-Values(timelineValues.month) for the Month ### --------------------------
      for(i=0; i<=noOfMonth; i++) {
        let _nextMonthDate__ = new Date(new Date(startDate).setMonth(startDate.getMonth() + i))

        // For the Month ---
        let endMonth_ = new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)

        let midDate_ = new Date(
          d3.mean([
            _nextMonthDate__, // 1st date of the month
            endMonth_         // last date of the month
          ])
        )
        _data.values.month.push({
          name: d3.timeFormat('%b')(midDate_),
          midDate: new Date(midDate_),
          endDate: endMonth_
        })
      }
      
      _data.width = _data.values.month.length * _data.monthWidth

      // get min & max values of the timeline-scale
      _data.scale = d3.scaleTime()
      .domain([startDate, endDate])
      .range([0, _data.width])

      return _data
    },
    



  }
}
