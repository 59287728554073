import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    draw_Timeline2021() {

      d3.select('#commodity_summary_timeline_chart').remove()
      
      this.chart = this.svg
      .append('g')
      .attr('transform', `translate(1321, 16)`)
      .attr('id', 'commodity_summary_timeline_chart')

      let legend = this.chart
      .append('g')
      .attr('transform', `translate(250, 5)`)
      .attr('class', 'legendGroup')

      legend
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', 30).attr('height', 2).attr('fill', '#BDBDBD')

      legend.append('text')
      .attr('x', 35)
      .attr('y', 2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Plan')

      legend
      .append('rect') 
      .attr('x', 70).attr('y', 0).attr('width', 30).attr('height', 2).attr('fill', '#4FC3F7')

      legend.append('text')
      .attr('x', 105)
      .attr('y', 2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Forecast')

      
      legend
      .append('rect') 
      .attr('x', 160).attr('y', 0).attr('width', 30).attr('height', 2).attr('fill', '#4CAF50')

      legend.append('text')
      .attr('x', 195)
      .attr('y', 2).style('font-size', 10).style('fill', '#212121').attr('font-weight', 500)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Actual')
      // Timeline ----------------------------------------------------------------------------
      let mainTimeline = this.chart
      .append('g')
      .attr('transform', `translate(0, 15)`)

      mainTimeline
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('width', this.timelineWidth).attr('height', 50).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`).attr('opacity', .5)

      // Timeline Text using the scale function
      // Year
      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timelineValues.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 15).style('font-size', 15).style('fill', '#EC407A').attr('font-weight', 500)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Month
      mainTimeline
      .append('g')
      .selectAll('text')
      .data(this.timelineValues.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeScale(d.value))
      .attr('y', 30).style('font-size', 10).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      // Timeline Seperator
      mainTimeline
      .append('g')
      .selectAll('path')
      .data(this.timelineValues.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (this.timelineValues.month.length-1 > i) {
          if (d.name == 'Dec')return `M${this.timeScale(d.endDate)}, 7 V35`
          else return `M${this.timeScale(d.endDate)}, 25 V35`
        }
      })
      .attr('stroke', '#757575').attr('stroke-width', 0.3)

      // Draw Line Charts
      let groupY = 5

      let chartGroup = this.chart
      .append('g')
      .attr('transform', `translate(0, 65)`)
      .attr('clip-path', 'url(#mask_barchart')

      let date = new Date()
      let thisDate = new Date(`${date.getFullYear()}-${date.getMonth()+1}-1`)
      

      // This Date Rect Bar
      chartGroup
        .append('rect') 
        .attr('x', this.timeScale(thisDate)).attr('y', 0)
        .attr('width', this.timelineWidth / 12 )
        .attr('height', (this.filteredList.length) * 23).attr('fill', '#E1F5FE') 



      this.filteredList.forEach(item => {
        let itemGroup = chartGroup
        .append('g')
        .attr('transform', `translate(0, ${groupY})`)
        .attr('class', `chartUomGroup_${item.DISC_DESCR}_${item.UOM}_${item.WCCODE}`)


        if (item.PLAN_START_DATE) {
          // Plan BarChart
          let PS = new Date(item.PLAN_START_DATE)
          let PF = new Date(item.PLAN_FINISH_DATE ? item.PLAN_FINISH_DATE : new Date())

          itemGroup
          .append('rect') 
          .attr('x', this.timeScale(PS)).attr('y', 0).attr('width', this.timeScale(PF)-this.timeScale(PS)).attr('height', 2).attr('fill', '#BDBDBD') 
        }

        if (item.FORECAST_START_DATE) {
          // Forecast BarChart
          let FS = new Date(item.FORECAST_START_DATE)
          let FF = new Date(item.FORECAST_FINISH_DATE ? item.FORECAST_FINISH_DATE : new Date())

          itemGroup
          .append('rect') 
          .attr('x', this.timeScale(FS)).attr('y', 5).attr('width', this.timeScale(FF)-this.timeScale(FS)).attr('height', 2).attr('fill', '#4FC3F7') 
        }

        // Actual BarChart
        if (item.ACTUAL_START_DATE) {
          let AS = new Date(item.ACTUAL_START_DATE)
          let AF = new Date(item.ACTUAL_FINISH_DATE ? item.ACTUAL_FINISH_DATE : new Date())

          itemGroup
          .append('rect') 
          .attr('x', this.timeScale(AS)).attr('y', 10).attr('width', this.timeScale(AF)-this.timeScale(AS)).attr('height', 2).attr('fill', '#4CAF50') 
        }

        groupY += 23
        
      })


      chartGroup.attr('clip-path', 'url(#mask_barchart')
    }, 
  }
}
