import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    dDMembers: [
      {text: 'Disciplines', value: 0},
      {text: 'BMW', value: 1},
      {text: 'Audi',value: 2},
      {text: 'Mercedes',value: 3},
      {text: 'Hyundai',value: 4},
    ],

    discTitle: ['Structural', 'Painting & PFP', 'Piping', 'Mechanical', 'E&I', 'HVAC', 'Insulation','Architectural', 'Others']
    
  }),



  methods: {
    
    drawChart() {
      let lightPink = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightPink`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightPink.append('stop').attr('stop-color', '#fce3eb').attr('offset', '0')
      lightPink.append('stop').attr('stop-color', '#fcafc9').attr('offset', '1')

      let lightGreen = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightGreen`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightGreen.append('stop').attr('stop-color', '#ddf1b6').attr('offset', '0')
      lightGreen.append('stop').attr('stop-color', '#c6e481').attr('offset', '1')

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightBlue`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#d4eaf3').attr('offset', '0')
      lightBlue .append('stop').attr('stop-color', '#a3d0e8').attr('offset', '1')


      let mainGroup = this.svg
      .append('g')
      .attr('transform', `translate(15, 30)`)

          // mainGroup
          // .append('rect') // Background Window
          // .attr('x', 0).attr('y', 0).attr('width', 310).attr('height', 530)
          // .attr('fill', '#fff').attr('stroke', 'gray').attr('stroke-width',1)

          let headerX = 135
          let header1 = mainGroup
          .append('g')
          .attr('transform', `translate(0, 58)`)
          header1.append('text').attr('x', headerX).attr('y', 0).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'start').attr('fill', '#BCBCBC').text('0%')
          header1.append('text').attr('x', headerX + 68).attr('y', 0).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end').attr('fill', '#BCBCBC').text('50%')
          header1.append('text').attr('x', headerX + 130).attr('y', 0).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end').attr('fill', '#BCBCBC').text('100%')

          header1.append('line').attr('x1', headerX).attr('y1', 3).attr('x2', headerX).attr('y2', 437).attr('stroke', '#bcbcbc').attr('stroke-width', .2)
          header1.append('line').attr('x1', headerX + 60).attr('y1', 3).attr('x2', headerX + 60).attr('y2', 437).attr('stroke', '#bcbcbc').attr('stroke-width', .2)
          header1.append('line').attr('x1', headerX + 120).attr('y1', 3).attr('x2', headerX + 120).attr('y2', 437).attr('stroke', '#bcbcbc').attr('stroke-width', .2)
          
          let legends = mainGroup
          .append('g')
          .attr('transform', `translate(20, 25)`)

          legends.append('rect') .attr('x', 0).attr('y', 0).attr('width', 10).attr('height', 10).attr('opacity', .7).attr('fill',  `url(#lightBlue)`)
          legends.append('text').attr('x', 13).attr('y', 8).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'start').attr('fill', '#757575').text(`Progress`)
          
          legends.append('rect') .attr('x', 60).attr('y', 0).attr('width', 10).attr('height', 10).attr('opacity', .7).attr('fill',  `url(#lightPink)`)
          legends.append('text').attr('x', 73).attr('y', 8).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'start').attr('fill', '#757575').text(`Behind`)
         
          legends.append('rect') .attr('x', 115).attr('y', 0).attr('width', 10).attr('height', 10).attr('opacity', .7).attr('fill',  `url(#lightGreen)`)
          legends.append('text').attr('x', 128).attr('y', 8).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'start').attr('fill', '#757575').text(`Completion(100%)`)



          let pointY = 30
          let dataTable = mainGroup
          .append('g')
          .attr('transform', `translate(10, 30)`)

          let pointX = 120
          this.discTitle.forEach((disc, i) => {
      
            dataTable
            .append('text')
            .attr('x', pointX).attr('y', pointY + 12).style('font-family','roboto').style('font-size',11).style('font-weight', 500).attr('text-anchor', 'end')
            .attr('fill', '#333')
            .text(disc)
            pointY += 19

            this.DataItems.forEach((act, i) => {
              
              if (disc == act.disc) {
                
                dataTable
                .append('text')
                .attr('id', `activity_${i}`)
                .attr('x', pointX).attr('y', pointY + 10).style('font-family', 'roboto').style('font-size', 9.5).attr('text-anchor', 'end')
                .attr('fill', () => {
                  if (act.plan == null) return '#E0E0DF'
                  else return '#757575'
                })
                .text(act.activity)

                dataTable 
                .append('rect') // Plan
                .attr('x', pointX + 5).attr('y', pointY + 2).attr('width', 0).attr('height', 10).attr('fill', '#F4F5F5').attr('opacity', 1)
                .transition().duration(500).attr('width', (act.plan*0.01) * 120)

                dataTable
                .append('rect') // Actual
                .attr('x', pointX + 5).attr('y', pointY + 2).attr('width', 0).attr('height', 10).attr('opacity', .9)
                .attr('fill',  () =>{
                  if ((act.actual) > 99.5) return `url(#lightGreen)`
                  if ((act.actual-act.plan) > 0) return `url(#lightBlue)`
                  if ((act.actual-act.plan) < 0) return `url(#lightPink)`
                })
                .transition().duration(500).attr('width', (act.actual*0.01) * 120)

                if (act.actual > 20) {
                  dataTable
                  .append('text')
                  .attr('x', pointX + 20).attr('y', pointY + 10)
                  .style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end')
                  .attr('fill', '#757575')
                  .text(`${act.actual.toFixed(0)}%`)
                  .transition().duration(500).attr('x', pointX + 5 + ((act.actual*0.01) * 120)-2)
                }
                pointY += 19
              }
              
            })
            pointY += 10
          })
          
    }, 

  }
}
