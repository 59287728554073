import __C from '../../primitives/_constant_'
import Api from './api'
import Url from './request.url'
import axios from 'axios'


export default class ApplicationApi extends Api {

  constructor() {
    super()
  }

  createExport(params, callback) {
    return this.axios().post(Url.application.create.export, params).then(res => callback(res.data))
  }

  getNavigationMenu(callback, permitGroups) {
    this.axios().get(`${Url.application.layout.navigation.menu}/${permitGroups}`).then(res => callback(res.data))
  }
  getNavigationRoutes(callback, permitGroups) {
    this.axios().get(`${Url.application.config.routes}/${permitGroups}`).then(res => callback(res.data))
  }
  requestDataCollectionIDG(params, callback) {
    this.axios().post(Url.application.idgservice.init, params).then(res => callback(res.data))
  }
  requestPatDataCollectionIDG(params, callback) {
    this.axios().post(Url.application.idgservice.patronage, params).then(res => callback(res.data))
  }
  requestChartValues(params, callback) {
    this.axios().post(Url.application.chart.dntValues, params).then(res => callback(res.data))
  }
  requestFilteredChainOptions(params, callback) {
    this.axios().post(Url.application.idgservice.chainoptions, params).then(res => callback(res.data))
  }

  // Dashboard Service
  getDashboard(params, callback) {
    if(params.get('reqType') == __C.PAGE_COMPONENT.REQUEST_TYPE_EXPORT) axios.post(Url.export.dashboard.get, params).then(res => callback(res.data))
    else this.axios().post(Url.application.dashboard.get, params).then(res => callback(res.data))
  }
  // Dashboard Container Service
  getContainer(params, callback) {
    axios.post(Url.export.container.get, params).then(res => callback(res.data))
  }
  getContainerActionQResult(params, callback) {
    this.axios().post(Url.application.dashboard.contqres, params).then(res => callback(res.data))
  }
  // Page Svg Service
  getPageSvg(params, callback) {
    if(params.get('reqType') == __C.PAGE_COMPONENT.REQUEST_TYPE_EXPORT) axios.post(Url.export.pagesvg.get, params).then(res => callback(res.data))
    else this.axios().post(Url.application.pagesvg.get, params).then(res => callback(res.data))
  }


  uniqMember(userId) {
    return new Promise(resolve => {
      this.axios().get(`${Url.application.account.uniqMember}/${userId}`).then(res => { resolve(res.data) })
    })
  }

  forgotMember(userId) {
    return new Promise(resolve => {
      this.axios().get(`${Url.application.account.forgotMember}/${userId}`).then(res => { resolve(res.data) })
    })
  }

  reqMember(params) {
    return new Promise(resolve => {
      axios.put(Url.application.account.reqmember, params).then(res => {
        resolve(res) 
      })
    }) 
  }

  putRequestHistory(params) {
    return new Promise(resolve => {
      axios.put(Url.application.account.reqhist, params).then(res => {
        resolve(res) 
      })
    }) 
  }

  LevedRequestHistory(params) {
    return new Promise(resolve => {
      axios.post(Url.application.account.reqhist, params).then(res => {
        resolve(res) 
      })
    }) 
  }

  // getRequestHistory(sysadmin, compName, userId, permit) {
  //   return new Promise(resolve => {
  //     axios.get(`${Url.application.reqhist.overview}/${sysadmin}/${compName}/${userId}/${permit}`).then(res => {
  //       resolve(res.data) 
  //     })
  //   }) 
  // }

  getRequestHistory(params) {
    return new Promise(resolve => {
      axios.post(Url.application.reqhist.overview, params).then(res => {
        resolve(res.data) 
      })      
      // axios.get(`${Url.application.reqhist.overview}/${sysadmin}/${compName}/${userId}/${permit}`).then(res => {
      //   resolve(res.data) 
      // })
    }) 
  }

  putBoardReport(params, config) {
    return new Promise(resolve => {
      this.axios().put(Url.application.board.report, params, config).then(res => { resolve(res.data) })
    })
  }
  getBoardReport(idx) {
    return new Promise(resolve => {
      this.axios().get(`${Url.application.board.report}/${idx}`).then(res => { resolve(res.data) })
    })
  }
  getBoardReports(catcode, groupcode) {
    return new Promise(resolve => {
      this.axios().get(`${Url.application.board.reports}/${catcode}/${groupcode}`).then(res => { resolve(res.data) })
    })
  }
  updBoardReport(params, config) {
    return new Promise(resolve => {
      this.axios().post(Url.application.board.report, params, config).then(res => { resolve(res.data) })
    })
  }
  delBoardReport(idx) {
    return new Promise(resolve => {
      this.axios().delete(`${Url.application.board.report}/${idx}`).then(res => { resolve(res.data) })
    })
  }
  delBoardReportFile(idx) {
    return new Promise(resolve => {
      this.axios().delete(`${Url.application.board.file}/${idx}`).then(res => { resolve(res.data) })
    })
  }
  //Taps Item Check
  getTabs(params, callback) {  
   this.axios().post(Url.application.tabs.check, params).then(res => callback(res.data))
  }

}