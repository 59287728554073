import * as d3 from 'd3'

export default {
  methods: {
    
    setEvent_Hull_AFT() {

      // Goto_AFT_Division_Lv3
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_AFT_Division_Lv3 = (selection) => {
        selection
        .on('mouseover', () => {
          d3.select(`#Mask_AFT_Group`).transition().style('opacity',0.5)
        })
        .on('mouseout', () => {
          d3.select(`#Mask_AFT_Group`).transition().style('opacity',0)
        })
        .on('click', () => {
          
          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)

          this.hiddenGroupID('#Mask_AFT_Group', 0, 10)

          // Explode
          this.Hull_AFT.forEach(d => {
            d3.select(`#Blk_${d.Blk}`)
            .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
          })
  
          setTimeout(() => {

            // Display
            // this.displayGroupID(`#Mask_HL02`, 0, 10, 0)
            // this.displayGroupID(`#Mask_MD00a`, 0, 10, 0)
            // this.displayGroupID(`#Mask_RB01_02`, 0, 10, 0)

            this.Lev1 = 'HULL'; this.Lev2 = 'HULL-AFT'
            this.stage = this.STG_Division_Lv3
            this.fncName = 'Goto_AFT_Division_Lv3'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)

            // ### Call Canvas ###
            this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
          }, 1000);
          
        })
      }
    },
  }
}
