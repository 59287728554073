import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    draw_Forms() {

      this.loadSvg(0, 0, '/TORTUE/Common/demo.svg')

      //Note_Icon
      let Note_Btn = this.svg

      Note_Btn
      .append('image')
      .attr('id','Note_Btn')
      .attr('transform',`translate(1460, 90)`)
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/note_btn.svg'))
      .call(this.btnNote)

      Note_Btn
      .append('image')
      .attr('id','Note_Text')
      .attr('transform',`translate(1070, 90)`)
      .attr('opacity', 0)
      .style('cursor', 'pointer')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/note_text.svg'))


      let Form_Hull = this.svg

      Form_Hull
      .append('text')
      .attr('transform', `translate(30, 90)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Block Division of')

      Form_Hull
      .append('text')
      .attr('id', 'Title')
      .attr('transform', `translate(155, 90)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('font-weight', 400)
      .text('FPSO Overall')


      // Keyplan
      let Keyplan = Form_Hull

      .append('g')
      .attr('id', 'Keyplan_Group')
      .attr('transform', `translate(40.5, 140.5)`)
      .style('visibility', 'hidden').style('opacity', 1)

        Keyplan
        .append('image')
        .attr('id', 'Keyplan')
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/TORTUE.png`)
        .attr('transform', `translate(10, 0)`)

        Keyplan
        .append('text')
        .attr('transform', `translate(0, 53)`)
        .style('font-family', 'roboto').style('font-size', 7.5).style('fill', '#333').attr('text-anchor', 'middle').text('AFT')

        Keyplan
        .append('text')
        .attr('transform', `translate(130, 43)`)
        .style('font-family', 'roboto').style('font-size', 7.5).style('fill', '#333').attr('text-anchor', 'middle').text('PORT')

        Keyplan
        .append('text')
        .attr('transform', `translate(215, 110)`)
        .style('font-family', 'roboto').style('font-size', 7.5).style('fill', '#333').attr('text-anchor', 'middle').text('FORWARD')

        Keyplan
        .append('text')
        .attr('transform', `translate(80, 113)`)
        .style('font-family', 'roboto').style('font-size', 7.5).style('fill', '#333').attr('text-anchor', 'middle').text('STARBOARD')

        Keyplan
        .append('text')
        .attr('transform', `translate(100, 145)`)
        .style('font-family', 'roboto').style('font-size', 14).style('fill', '#333').attr('text-anchor', 'middle').attr('font-weight', 400).text('Keyplan')
      

      // Legends
      Form_Hull
      .append('image')
      .attr('id', 'Legends')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Common/Legend_Division.svg`)
      .attr('transform', `translate(45.5,330.5)`).style('visibility', 'hidden').style('opacity', 1)

      

      //button Menu_Back
      let Menu_Back_Wrap = this.svg

      .append('g')
      .attr('id', 'Menu_Back')
      .style('visibility', 'hidden')
      .attr('opacity', 1)
      .attr('transform', `translate(330, 94)`)

      let Menu_Back = Menu_Back_Wrap

      Menu_Back
      .append('image')
      .attr('class', 'Back_Btn')
      .attr('transform', `translate(0, 0)`)
      .style('cursor', 'pointer')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/back_btn.svg'))
      .call(this.btnGoBack) // --------------------------------------------------------------## btnGoBack

      Menu_Back
      .append('image')
      .attr('class', 'Home_Btn')
      .attr('transform', `translate(20, 0)`)
      .style('cursor', 'pointer')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/home_btn.svg'))
      .call(this.btnGoHome) // --------------------------------------------------------------## btnGoHome
      

      // Navigation got developing
      // this.Navigation()

    },

    Navigation() {
      let navigation = this.svg

      .append('g')
      .attr('id', 'navigation')
      .attr('transform', `translate(500, 20)`)
      // .style('visibility', 'hidden')

      // Lev 1
      navigation
      .append('text')
      .attr('transform', `translate(0, 8)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'start').text('Lev1')
  
      navigation
      .append('text')
      .attr('id', 'L1').attr('transform', `translate(0, 20)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').text(this.Lev1)

      // Lev 2
      navigation
      .append('text')
      .attr('transform', `translate(100, 8)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'start').text('Lev2')

      navigation
      .append('text')
      .attr('id', 'L2').attr('transform', `translate(100, 20)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').text(this.Lev2)

       // Lev 3
       navigation
      .append('text')
      .attr('transform', `translate(200, 8)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'start').text('Lev3')

      navigation
      .append('text')
      .attr('id', 'L3').attr('transform', `translate(200, 20)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').text(this.Lev3)

      // Lev 4
      navigation
      .append('text')
      .attr('transform', `translate(300, 8)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'start').text('Lev4')

      navigation
      .append('text')
      .attr('id', 'L4').attr('transform', `translate(300, 20)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'start').text(this.Lev4)

      // Stage
      navigation
      .append('text')
      .attr('transform', `translate(400, 8)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'start').text('Stage')

      navigation
      .append('text')
      .attr('id', 'stage').attr('transform', `translate(400, 20)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#44A9DF').attr('text-anchor', 'start').text(this.stage)

      // Function
      navigation
      .append('text')
      .attr('transform', `translate(500, 8)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').attr('text-anchor', 'start').text('Function')

      navigation
      .append('text')
      .attr('id', 'fncName').attr('transform', `translate(500, 20)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#EC407A').attr('text-anchor', 'start').text(this.fncName)
    },

    StageCode() {
      d3.select('#L1').text(this.Lev1)
      d3.select('#L2').text(this.Lev2)
      d3.select('#L3').text(this.Lev3)
      d3.select('#L4').text(this.Lev4)
      d3.select('#stage').text(this.stage)
      d3.select('#fncName').text(this.fncName)
    },

  }
}
           