import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    draw_ModuleCode(seletion) {
      
      // Draw border of code
      // The border of code provides two types Rect and ellipse.

      let Module_No_Group = seletion
      .append('g')
      .attr('id', `Module_No_Group`)
      .style('visibility', 'hidden')
      this.MODULE[this.CurrentArea].filter(f=> f.type == 'Module').forEach(d => {

        Module_No_Group
        .append('text')
        .attr('id', `${d.module}_No`)
        .attr('transform', `translate(${d.x}, ${d.y + .5})`)
        .style('font-family', 'roboto').style('font-size', 14).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text(`${d.module}`)
      })
    },


    draw_SingleBlockCode(seletion) {
      
      // Draw border of code
      // The border of code provides two types Rect and ellipse.

      let Module_CodesGroup = seletion
      .append('g')
      .attr('id', `Hull_SideBlock_Codes`)
      .style('visibility', 'hidden')
        
      this.dataSet.filter(f => f.MOD == 'MISC').forEach(data => {

        let HullBlockNo = Module_CodesGroup
        .append('g')
        .attr('id', `${data.BLOCK}_No`)

        if (data.shape == 'rect') {
          HullBlockNo
          .append('rect')
          .attr('x', data.x - data.width/2).attr('y', data.y - data.height/2)
          .attr('width', data.width).attr('height', data.height)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
        }  
        if (data.shape == 'ellipse') {
          HullBlockNo
          .append('ellipse')
          .attr('cx', data.x).attr('cy', data.y - 1)
          .attr('rx', (data.width/2) + 2).attr('ry', (data.height/2) + 1)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
        }
        HullBlockNo
        .append('text')
        .attr('transform', `translate(${data.x}, ${data.y + .5})`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text(`${data.BLOCK}`)
      })
    },


    draw_BlockCode(seletion, mod) {
      
      // Draw border of code
      // The border of code provides two types Rect and ellipse.
      
      let Module_CodesGroup = seletion
      .append('g')
      .attr('id', `${mod}_No_Group`)
      .style('visibility', 'hidden')
      // console.log(this.dataSet.filter(f => f.MOD == mod))
      this.dataSet.filter(f => f.MOD == mod).forEach(data => {
        let BlockNo = Module_CodesGroup
        .append('g')
        .attr('id', `${data.BLOCK}_No`)

        if (data.shape == 'rect') {
          BlockNo
          .append('rect')
          .attr('x', data.x - data.width/2).attr('y', data.y - data.height/2)
          .attr('width', data.width).attr('height', data.height)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
        }  
        if (data.shape == 'ellipse') {
          BlockNo
          .append('ellipse')
          .attr('cx', data.x).attr('cy', data.y)
          .attr('rx', data.width/2).attr('ry', data.height/2)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
        }
        BlockNo
        .append('text')
        .attr('transform', `translate(${data.x}, ${data.y + .5})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text(`${data.BLOCK}`)
      })
    }






    
  }
}