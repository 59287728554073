<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'        

export default {
  name: "j-information-guide-v2",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  
    
    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },

    callback: { type: Object, default: () => ({}) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }
      this.clear()
      this.set_LocalValues()
      this.drawCanvas()
      this.drawing()
      this.drawTexts()
      this.drawNotes()
      this.complete()
    },
    // change area page by info comment seicode change
    redraw() {
      // this.stage_Main.remove()
      // this.stage_Header.remove()
      // this.innerSvg.remove()
      // this.stage_List.remove()
      this.stage_SEI.remove()

      this.set_LocalValues()
      this.draw_SEI_Page(this.CurrentArea)
      this.complete()
    },
    drawing() {
      this.set_Gradients()
      this.draw_HomePage()

      // for export pdf
      if(this.getSelectItems) {
        this.getItems = JSON.parse(this.getSelectItems)

        if(this.getItems) {
          this.Toggle = this.getItems.lang
          this.CurrentPlant = this.getItems.plant
          this.CurrentPkg = this.getItems.pkg
          this.CurrentArea = this.getItems.area
          this.CurrentInfo = this.getItems.info

          if(this.getItems.level == 'plant') {   
            this.load_Plants(this.CurrentPlant) // --> draw_Homepage.js 
          }else if(this.getItems.level == 'pkg') {
            if(this.CurrentPlant == 'MN') {
              if(this.stage_Main) {
                this.stage_Main.remove()
              }
              this.draw_Forms()
              this.load_MainPlant()
              this.draw_Checkbox()
              this.draw_ListboxHeader()
              setTimeout(() => { this.draw_ListboxDetail() }, 350)
            }
          }else if(this.getItems.level == 'area') {
            this.draw_SEI_Page(this.CurrentArea)
            this.complete()
          }
        }
      } 
    },
  }
}
</script>

<style lang="scss" scoped>
  .svg_wrapper {
    min-width: 1600px;
    min-height: 1120px;
    max-width: 1600px;
    max-height: 1120px;

    overflow: hidden;
  }
</style>