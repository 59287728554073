import * as d3 from 'd3'

export default {
  methods: {
    Goto_Back_Stage0() {
      this.moveItemBack('S1').then(()=>{
        this.moveGroupBack('S1')
        d3.select('#Progress_Chart').remove(); 
        d3.select(`#S1_BOTTOM`).transition().duration(500).delay(200).style('opacity',1)

        this.displayGroupID(`#Overall_Mask`, 10, 10, 0)
        this.hiddenGroupID('#Menu_Back', 0, 10)
        this.hiddenGroupID('#S2L1_UD_Mask', 0, 10)
        this.hiddenGroupID('#S2L2_MD_Mask', 0, 10)
        this.hiddenGroupID('#S2R1_LD_Mask', 0, 10)
        this.hiddenGroupID('#S2R2_CD_Mask', 0, 10)
      })
      setTimeout(()=>{
        this.drawProgress('Overall', null)
        this.drawMainProgress()
      },900)
      
    },
    async Goto_Back_Stage1() {
      // console.log(this.curstage, this.curid.length, this.curid[this.curstage])
      await this.moveGroupBack(`${this.curid[this.curstage]}`, 'nonself')
      await this.moveGroupBack(`${this.curid[this.curstage]}`, 'self')
      this.drawOverall(true)
      d3.select('#overall_prog').remove();
      d3.select('#Progress_Chart').remove();
    },
    Goto_Back_Stage2() {
      d3.selectAll('#blockNames').remove()
      // this.Stage2_Division_Mask()
      // console.log(this.curstage, this.curid.length, this.curid[this.curstage])
      
      let parentIDs = `${this.curid[this.curstage]}`.split('_')
      d3.selectAll(`[id*="${parentIDs[0]}"]`).each((_, i, a) => {
        let target = d3.select(a[i])
        if(target.node().tagName != 'image') return 
        if(target.node().id.includes(`${this.curid[this.curstage]}`)) return 
        target.transition().style('visibility', 'visible')
        this.displayGroupID(`#${parentIDs[0]}_${parentIDs[1]}_M_Mask`, 10, 10, 0)
        this.displayGroupID(`#${parentIDs[0]}_${parentIDs[1]}_D_Mask`, 10, 10, 0)
      })
      let id__ = `${parentIDs[0]}_${parentIDs[1]}`
      setTimeout(()=>{
        this.drawProgress('selectDeck',id__)
        this.innerSvg
        .append('image')
        .attr('id', 'Keyplan')
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/BlockDivision/Hebron/Keyplan/${id__}.jpg`)
        .attr('transform', `translate(19, 131)`)
      },300)
      this.moveItemBack(`${`${this.curid[this.curstage]}`}`)
      d3.select('#Progress_Chart').remove();
    },
  }
}