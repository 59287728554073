export default {
  methods: {
    drawProgress(scrName, id=null) {
      this.CANVAS = this.svg.append('svg').attr('id','Progress_Chart')
      this.CANVAS
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)

      let style = null
      this.BlockStatus_Coordinate.filter(c => (
        id ? c.ID == id : c.SCRNAME == scrName
      )).forEach((d, sn) => {
        // Block Status
        let itemSet = []
        style = {
          x: d.x,
          y: d.y,
          headTextSize: 16,
          headTitle: d.NAME,
          prog:{PLAN:100,ACTUAL:100,DELTA:0}
        }
        let localDataSet = this.Queries.SQL1.filter(f => ( 
          f.LV1 == d.LV1 &&
          f.LV2 == d.LV2 &&
          f.LV3 == d.LV3
        ))// 여기서는 같은것만 찾아오자
        // console.log(localDataSet)
        let levelFilter__ = {}
        Object.keys(d).forEach(k => {
          if(['NAME', 'SCRNAME', 'x', 'y'].includes(k)) return //이것들에 해당하는 key는 밖으로 빼기
          if(d[k]) levelFilter__[k] = d[k]
          // 나머지 key들의 값을 확인해서 있으면 값을 넣는다 
        })
        this.Status.forEach((d__, i) => {
          let tColName = this.Status[i]['colName']['total']
          let aColName = this.Status[i]['colName']['actual']

          itemSet.push({
            name: d__.title,
            total: localDataSet.reduce((a, b) => a + (b[tColName] || 0), 0),
            actual: localDataSet.reduce((a, b) => a + (b[aColName] || 0), 0),
            delta: localDataSet,
            filter: {
              STATUS: d__.code,
              ...levelFilter__
            },
            bColor: this.Status[i].bColor,
            opacity: this.Status[i].opacity,
          })
        })
        this.Bar_Quantity_04(this.CANVAS, itemSet, style, sn) // <---- Chart Library
      })
    },
    drawMainProgress(){
      let style = null
      let item = this.Queries.SQL1.find(f => f.Lv1 == null && f.Lv2 == null)

      let ProjectProgress = this.svg.append('svg')
      .append('g')
      .attr('id', 'overall_prog')
      .attr('transform', `translate(20, 930)`)
      ProjectProgress
      .append('text')
      .attr('transform', `translate(0, 48)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').style('text-anchor', 'start').text('Overall Progress')

      ProjectProgress
      .append('rect')
      .attr('transform', `translate(0, 20)`).attr('width', 1000).attr('height', 16).attr('fill', '#F4F5F5')
      ProjectProgress
      .append('rect')
      .attr('transform', `translate(0, 20)`).attr('width', item.ACTUAL*10).attr('height', 16).attr('fill', '#8BC34A')
      ProjectProgress
      .append('text')
      .attr('transform', `translate(${(item.ACTUAL*10)-2}, 32)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end').text(`Actual : ${item.ACTUAL}%`)
      ProjectProgress
      .append('text')
      .attr('transform', `translate(${(item.ACTUAL*10)-2}, 48)`)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').style('text-anchor', 'end').text(`Plan Cuml.(%)`)
      ProjectProgress
      .append('text')
      .attr('transform', `translate(${(item.ACTUAL*10)-2}, 60)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').style('text-anchor', 'end').text(`${item.PLAN}%`)
      ProjectProgress
      .append('text')
      .attr('transform', `translate(${(item.ACTUAL*10)+-2}, 78)`)
      .style('font-family', 'roboto').style('font-size', 11).style('text-anchor', 'end')
      .style('fill', () => {
        if(item.DELTA < 0) return '#EC407A'; else return '#44A9DF'
      })
      .text(`Delta : ${item.DELTA}%`)

      // grids 
      for (let i = 0; i <= 100; i += 20) {
        ProjectProgress
        .append('text')
        .attr('transform', `translate(${i*10}, 10)`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').style('text-anchor', 'middle').text(`${i}%`)

        ProjectProgress
        .append('line')
        .attr('x1', i*10).attr('y1', 13).attr('x2', i*10).attr('y2', 18)
        .style('stroke-width', 0.5).style('stroke', '#bcbcbc')
      }

      // Progress for Topside ------------------------------------------------------------------------------------------------- Chart Library
      let idArray = ['S2L1_UD','S2L2_MD','S2R1_LD','S2R2_CD']
      idArray.forEach((e,i)=>{
        style = {
          headTitle: this.Queries.SQL1.find(e => e.ID == idArray[i] && e.LV4 == null).NAME,
          headColor: '#fff',
          title:    ['Area', 'Plan', 'Actual', 'Delta'],
          cell:     [50, 110, 155, 200],
          x:        50,
          y:        355+i*100,
          boxWidth: 90,
          boxHeight: 22,
          tColor: '#fff',
          lineHeight: 16,
          tblWidth: 210,
          tblHeight: 60,
          progDisplay: 'Y',
          prog: this.Queries.SQL1.find(e => e.ID == idArray[i] &&  e.LV4 == null).ACTUAL,
        }
        let dataTrans = this.Queries.SQL1.filter(e => e.ID == idArray[i] && e.LV4 == null && e.LV3 != null)
        this.SummaryTable_04(this.CANVAS, dataTrans, style) // <---- Chart Library
      })
    }
  }
}