import * as d3 from 'd3'

export default {
  methods: {
    
    setEvent_Hull_MS() {

      // Goto_Ms
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_MS_Level = (selection, _mask, _level) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Activate) return
          d3.select(_mask).transition().style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Activate) return
          d3.select(_mask).transition().style('opacity', 0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Activate) return

          // Remove SVG
          this.removeSvg(this.CANVAS, 1000)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 0)
          this.hiddenGroupID('#Mask_Lev1', 0, 0)
          this.hiddenGroupID('#Mask_Lev2', 0, 0)
          this.hiddenGroupID('#Mask_Lev3', 0, 0)
          this.hiddenGroupID('#Mask_Lev4', 0, 0)
          
          setTimeout(() => {
            this.Hull_Mechinaery.forEach(d => {
              if(d.BLK == `${_level}_EQ`) {
                d3.select(`#Blk_${_level}_EQ`).transition().duration(1000).attr('transform', `translate(${d.x5}, ${d.y5}) scale(${1})`)
              } else {
                this.hiddenGroupID(`#Blk_${d.BLK}`, 0, 500)
              }
            })
          }, 700);

          setTimeout(() => {
            this.loadSvg(0, 0, `/TORTUE/Equipment/SVG/${_level}.svg`).then((innerSvg) => {
               this.Draw_EQ_Code(innerSvg, _level)
               this.Draw_EQ_Tag(innerSvg, _level)
               this.Draw_EQ_Block(innerSvg, _level)
               this.innerSvg = innerSvg
            })
 
            this.Lev4 = _level
            this.stage = this.STG_Division_Lv3//this.STG_Layout
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : 'MS',
              level: _level
             }
             this.selectedItems.push(dStage)
            this.fncName = 'Goto_MS_Level'
            this.StageCode() // AREA / STAGE
            this.displayGroupID(`#Menu_Back`, 0, 200, 1)
            this.drawProgress(_level, false)

            // Internal-Filter-Value for the common control
            this.inFilters.LV1 = this.Lev1
            this.inFilters.LV4 = _level                      
          }, 2000)
        })
      }
    },
  }
}
