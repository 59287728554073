import * as d3 from 'd3'

export default {
  data: () => ({

  }),
  computed: {

    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },



  methods: {
    setDefaultValues(){
      this.timeline.plan = []
      this.timeline.actual = []

      this.dataSet = JSON.parse(JSON.stringify(this.DataItems.filter(f => f.stage == this.activity)))
      this.dataSet.forEach(d => {
        d.cDate = this.getTimelineCDate(d.date).eDate
        d.sDate = this.getTimelineCDate(d.date).sDate
        d.date = d3.timeParse('%Y-%m-%d')(d.date)
      })

      // this.lineChartData = JSON.parse(JSON.stringify(this.Queries.SQL1.filter(f => f.stage == this.activity)))
      // this.lineChartData.forEach(d => {
      //   d.cDate = this.getTimelineCDate(d.date).eDate
      // })

      this.style.forEach(style => {

        style.item.forEach(item => {

          this.dataSet.filter( f => f[item.refColumn] == item.codeValue).forEach(d => {
            d.tStyle   = item.tStyle
            d.tSize    = item.tSize
            d.tColor   = item.tColor
            d.sWidth   = item.sWidth
            d.sColor   = item.sColor
            d.bColor   = item.bColor
            d.opacity  = item.opacity
            d.radius   = item.radius
            if (item.dataColumn != '') d.progress = 'Y'
          })
        })

      })

      this.timeline.height = this.timeline.style.week.height
      if (this.timeline.style.month.display == 'Y') this.timeline.height += this.timeline.style.month.height
      if (this.timeline.style.year.display == 'Y') this.timeline.height += this.timeline.style.year.height
      
      // Count thr number of Box by week
      this.timeline.week.forEach( (w, i) => {
        let count = 0
        this.dataSet.filter(f => f.cDate == w.eDate).forEach( d => {
          count += 1
        })
        this.timeline.weekCount.push(count)

        this.timeline.plan.push({ 
          eDate: w.eDate, 
          mDate: w.mDate,
          sDate: w.sDate,
          // value: this.lineChartData.find(f => f.cDate == w.eDate).plan
        })
        this.timeline.actual.push({ 
          eDate: w.eDate, 
          mDate: w.mDate,
          sDate: w.sDate,
          // value: this.lineChartData.find(f => f.cDate == w.eDate).actual
        })
      })

      let realHeight = Math.max(...this.timeline.weekCount) * (this.skyline.box.height + (this.skyline.box.gap * 2))
      if (realHeight > this.skyline.minSize) {
        this.timeline.baseY = realHeight
      } else {
        this.timeline.baseY = this.skyline.minSize
      }

      // console.log(Math.max(...this.timeline.weekCount))
      // console.log(this.dataSet)
    },
    
  }
}