import __C from '../../primitives/_constant_'
import Api from './api'
import Url from './request.url'

export default class FileApi extends Api {

  constructor() {
    super()
  }

  download(fileId, config) {
    return new Promise(reslove => {
      this.axios().get(`${Url.file.download}/${fileId}`, config).then(res => {
        reslove(res.data)
      })
    })
  }
  getOptionsList() {
    return new Promise(reslove => {
      this.axios().get(`${Url.file.gallery.list}`).then(res => {
        reslove(res.data)
      })
    })
  }
  getFilterOptionsList() {
    return new Promise(reslove => {
      this.axios().get(`${Url.file.gallery.filterlist}`).then(res => {
        reslove(res.data)
      })
    })
  }
  getEqFilterOptionsList() {
    return new Promise(reslove => {
      this.axios().get(`${Url.file.gallery.eqfilterlist}`).then(res => {
        reslove(res.data)
      })
    })
  }
  getEditInfo(idx) {
    return new Promise(reslove => {
      this.axios().get(`${Url.file.gallery.edit}/${idx}`).then(res => {
        reslove(res.data)
      })
    })
  }
  getEqEditInfo(idx) {
    return new Promise(reslove => {
      this.axios().get(`${Url.file.gallery.eqedit}/${idx}`).then(res => {
        reslove(res.data)
      })
    })
  }
  getFilesFromGallery(params) {
    return new Promise(reslove => {
      this.axios().post(Url.file.gallery.files, params).then(res => {
        reslove(res.data)
      })
    })
  }
  getFilesFromEqGallery(params) {
    return new Promise(reslove => {
      this.axios().post(Url.file.gallery.eqfiles, params).then(res => {
        reslove(res.data)
      })
    })
  }
  putFile2Gallery(file) {
    return new Promise(reslove => {
      this.axios().put(Url.file.gallery.file, file).then(res => {
        reslove(res.data)
      })
    })
  }
  putFile2EqGallery(file) {
    return new Promise(reslove => {
      this.axios().put(Url.file.gallery.eqfile, file).then(res => {
        reslove(res.data)
      })
    })
  }
  updFile2Gallery(file) {
    return new Promise(reslove => {
      this.axios().post(Url.file.gallery.file, file).then(res => {
        reslove(res.data)
      })
    })
  }
  updFile2EqGallery(file) {
    return new Promise(reslove => {
      this.axios().post(Url.file.gallery.eqfile, file).then(res => {
        reslove(res.data)
      })
    })
  }
  delFileFromGallery(idx, fileId) {
    return new Promise(reslove => {
      this.axios().delete(`${Url.file.gallery.file}/${idx}/${fileId}`).then(res =>  { 
        reslove(res.data) 
      })
    })
  }
  delFileFromEqGallery(idx, fileId) {
    return new Promise(reslove => {
      this.axios().delete(`${Url.file.gallery.eqfile}/${idx}/${fileId}`).then(res =>  { 
        reslove(res.data) 
      })
    })
  }
}
