import ServiceDashboard from '@/views/service/ServiceDashboardSingle'
import ServiceDashboardContainer from '@/views/service/ServiceDashboardContainer'
import ServiceDatatable from '@/views/service/ServiceDatatable'
import ServicePageSvg from '@/views/service/ServicePageSvgSingle'
import ServiceSvgTabs from '@/views/service/ServiceSvgTabs'
import ServiceCompPage from '@/views/service/ServiceCompPage'
import ServiceModelViewer from '@/views/public/3DViewerCUSTOM'

import JModalContentCOIItem from '@/components/JModalContentCOIItem'
import JCommentsBoard from '@/components/JCommentsBoard'

export default {
  ServiceDashboard,
  ServiceDashboardContainer,
  ServiceDatatable,
  ServicePageSvg,
  ServiceSvgTabs,
  ServiceCompPage,
  ServiceModelViewer,

  JModalContentCOIItem,
  JCommentsBoard,
}

