import * as d3 from 'd3'

export default {
  methods: {
    Activate_Topside() {

      this.svgModeling = this.svg.append('svg')
      this.svgModeling
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .style('opacity', 1)

      // Overall_Hull_Group
      let ModuleGroup = this.svgModeling
      .append('g')
      .attr('id', `Mask_Module`)
      .attr('transform', `translate(0,0) scale(1)` )
      .attr('opacity', 1)

      this.innerSvg = ModuleGroup

      return ModuleGroup
    },
    Activate_Topside_Module_Single(_mod) {
    
      // Overall_Hull_Group
      let ModuleGroup = this.Activate_Topside()

      // ### Module Blocks
      let d = this.TOPSIDES.find(f => f.Lv3 == _mod)
      let _level = _mod
      setTimeout(()=>{
        ModuleGroup
        .append('image')
        .attr('id', `Blk_${d.BLK}`)
        .style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Blocks/Topside/${d.BLK}.png`)
        .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.35})`)
      }, 300)

      setTimeout(() => {
        d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x5}, ${d.y5}) scale(${1})`)
      }, 700)

      setTimeout(() => {
        // console.log(_level)
        this.loadSvg(0, 0, `/TORTUE/Equipment/SVG/${_level}.svg`).then((innerSvg) => {
          this.Draw_EQ_Code(innerSvg, _level)
          this.Draw_EQ_Tag(innerSvg, _level)
          this.Draw_EQ_Block(innerSvg, _level)
          this.innerSvg = innerSvg
        })
        this.Lev1 = 'TOPSIDES'
        this.Lev3 = _mod
        this.stage = this.STG_Division_Lv3
        this.fncName = 'Goto_TS_Level'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)
        // ### Call Summary ###
        this.drawProgress(_mod, false)
     
        // Internal-Filter-Value for the common control
        this.inFilters.LV1 = 'TOPSIDES'
        this.inFilters.LV4 = _level          
      }, 1600)
    },

    Activate_Topside_Module(_mod) {
      // Overall_Hull_Group
      let ModuleGroup = this.Activate_Topside()
      
      setTimeout(()=>{
      // ### Module Blocks
        this.TOPSIDES.filter(f => f.Lv3 == _mod).forEach(d => {

          ModuleGroup
          .append('image')
          .attr('id', `Blk_${d.BLK}`)
          .style('opacity', 1).style('visibility', 'visible')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Blocks/Topside/${d.BLK}.png`)
          .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${d.BLK === 'ME05DC' ? 0.235 : 0.35})`)
        })
      },300);

      setTimeout(() => {
        this.TOPSIDES.filter(f => f.Lv3 == _mod).forEach(d => {
          ModuleGroup
          d3.select(`#Blk_${d.BLK}`).transition().duration(700).attr('transform', `translate(${d.x2}, ${d.y2}) scale(${d.BLK === 'ME05DC' ? 0.235 : 0.35})`)
        })
      }, 1000);

      setTimeout(() => {
        this.TOPSIDES.filter(f => f.Lv3 == _mod).forEach(d => {
          ModuleGroup
          d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(${d.BLK === 'ME05DC' ? 0.235 : 0.35})`)
        })
      }, 1700);

      setTimeout(() => {
        this.TOPSIDES.filter(f => f.Lv3 == _mod && f.Lv3 == 'ME05').forEach(d => {
          ModuleGroup
          d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(${d.BLK === 'ME05DC' ? 0.235 : 0.35})`)
        })
      },2700);

      setTimeout(() => {
        this.TOPSIDES.filter(f => f.layout == null).forEach(d => {
          ModuleGroup
          d3.select(`#Blk_${d.BLK}`).transition().duration(300).style('opacity', 0.3)
        })

        // let masks = this.Masks.find(f => f.mod == _mod && f.level == _level)
        // ModuleGroup
        // .append('polygon')
        // .attr('id', `Mask_${_mod}`)
        // .attr('transform', `translate(${masks.x}, ${masks.y}) scale(0.9)`)
        // .attr('points', masks.point)
        // .attr('fill', '#fff').style('opacity', 0).style('visibility', 'visible').style('cursor', 'pointer')
        // .call(s => { this.Goto_TS_Level(s, id, _level, _mod ) }) // <--------
      }, 3500);
      setTimeout(() => {
        d3.selectAll(`[id*="Mask_${_mod}"]`).each((_, i, a) => {
          this.displayGroupID(`#${d3.select(a[i]).attr('id')}`, 0, 0, 0)
        })

        this.Lev1 = 'TOPSIDES'
        this.Lev2 = this.Lev2
        this.Lev3 = _mod
        this.stage = this.STG_Lv4_Block_Division
        this.fncName = 'Activate_Topside_Module'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)
        // this.Canvas_Division_Lv4(this.Lev1, this.Lev2)
        // ### Display Canvas ###
        this.drawProgress(this.Lev3)     
      }, 2900);

      
      this.Mask_TopsideModule_Group()
    },
    

    Mask_TopsideModule_Group(){
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME01DA') // ME01DA
      .attr('points', '0,76.063 182.901,-0.018 305,65.535 305,88 123.747,158')
      .attr('fill', '#fff').attr('transform', `translate(713,654) scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME01DA', 'ME01DA', 'ME01') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME01DB') // ME01DB
      .attr('points', '0,59.33 144.83,0 251,62.67 251,107 93.17,170.33 0,125')
      .attr('fill', '#fff').attr('transform', `translate(655,525)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME01DB', 'ME01DB' ,'ME01') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME01DC') // ME01DC
      .attr('points', '2,61.5 165.33,0.08 259,55 259,103 98.67,170 2,113')
      .attr('fill', '#fff').attr('transform', `translate(550,394)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME01DC', 'ME01DC' , 'ME01') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME02DA') // ME02DA
      .attr('points', '1,154.17 135,93.33 135,30 153,2.33 178,0 243.33,28 264,135.33 303.67,162 95.33,242 1,189.33')
      .attr('fill', '#fff').attr('transform', `translate(764,611)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME02DA', 'ME02DA' , 'ME02') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME02DB') // ME02DB
      .attr('points', '0,64.67 136.17,1.33 241,66 241,114 107.17,165 0,107')
      .attr('fill', '#fff').attr('transform', `translate(649,569)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME02DB', 'ME02DB' , 'ME02') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME02DC') // ME02DC
      .attr('points', '1,55.33 124.5,0 213,51.67 213,113 101.83,156 1,106')
      .attr('fill', '#fff').attr('transform', `translate(544,437)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME02DC', 'ME02DC', 'ME02') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME04DA') // ME04DA
      // .attr('points', '1,56.33 127.5,0 193.92,18.33 234.96,100 98.81,153 1,94.67')
      .attr('points', '1,56.33 127.5,0 263.92,-30.33 234.96,150 98.81,153 1,94.67')
      .attr('fill', '#fff').attr('transform', `translate(734,661)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME04DA', 'ME04DA', 'ME04') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME04DB') // ME04DB
      .attr('points', '2,217.5 44,125.67 44,34 81,0 137.33,2.67 156.67,203.33 200,231.33 200,271 84.5,319 2,268')
      .attr('fill', '#fff').attr('transform', `translate(626,366)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME04DB', 'ME04DB', 'ME04') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME05DA') // ME05DA
      .attr('points', '1,60.33 165.5,0 250,46 250,87 101.83,138.33 1,83')
      .attr('fill', '#fff').attr('transform', `translate(889,635)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME05DA', 'ME05DA', 'ME05') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME05DB') // ME05DB
      .attr('points', '2,45.67 121.17,0 206,41 206,91 82.17,126 2,85.33')
      .attr('fill', '#fff').attr('transform', `translate(920,319)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME05DB', 'ME05DB', 'ME05') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME05DC') // ME05DC
      .attr('points', '1.17,68 19.67,5 39,5 48.67,28 138.67,0 226,35.33 226,65 91.67,115')
      .attr('fill', '#fff').attr('transform', `translate(640,617)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME05DC', 'ME05DC', 'ME05') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME05DD') // ME05DD
      .attr('points', '2,49.5 126.17,0.08 219,49.33 219,82 90.17,132 2,84.67')
      .attr('fill', '#fff').attr('transform', `translate(643,437)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME05DD', 'ME05DD', 'ME05') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_ME05DE') // ME05DE
      .attr('points', '0,42.83 94.67,0.08 180,47.67 180,92 90,125.33 0,79.33')
      .attr('fill', '#fff').attr('transform', `translate(641,251)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_ME05DE', 'ME05DE', 'ME05') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW01DA') // MW01DA
      .attr('points', '0,64.33 176.83,0 285,62 285,87 118.25,151.33 0,89.67')
      .attr('fill', '#fff').attr('transform', `translate(755,709)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW01DA', 'MW01DA', 'MW01') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW01DB') // MW01DB
      .attr('points', '0,57.5 155.5,-0.08 254,55.33 254,111 101.83,174 0,120')
      .attr('fill', '#fff').attr('transform', `translate(688,578)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW01DB', 'MW01DB', 'MW01') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW01DC') // MW01DC
      .attr('points', '1,54.17 145.17,0.08 247,54 247,94 93.5,153.67 1,108')
      .attr('fill', '#fff').attr('transform', `translate(611,461)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW01DC', 'MW01DC', 'MW01') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW01DD') // MW01DD
      .attr('points', '2,51.83 124.67,0.08 211,44.67 211,89 71.67,141.67 2,102')
      .attr('fill', '#fff').attr('transform', `translate(544,330)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW01DD', 'MW01DD', 'MW01') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW02DA') // MW02DA
      .attr('points', '2,100.5 45.17,37.58 156.42,-0.04 276,88.67 276,122 97.83,188.67 2,138')
      .attr('fill', '#fff').attr('transform', `translate(790,684)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW02DA', 'MW02DA', 'MW02') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW02DB') // MW02DB
      .attr('points', '2,60.83 171,0.08 228,40.91 272,46 272,108 98.33,176.33 2,120')
      .attr('fill', '#fff').attr('transform', `translate(657,561)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW02DB', 'MW02DB', 'MW02') }) // <--------
 
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW02DC') // MW02DC
      .attr('points', '1,61.83 161.17,0.08 252,53.67 252,95 97.5,154 1,103.33')
      .attr('fill', '#fff').attr('transform', `translate(571,445)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW02DC', 'MW02DC', 'MW02') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW02DD') // MW02DD
      .attr('points', '1,37.67 114.83,0 208,52 208,102 79.5,152.67 1,113.33')
      .attr('fill', '#fff').attr('transform', `translate(505,328)scale(0.9)`).style('opacity', 5).style('cursor', 'pointer')
      .style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW02DD', 'MW02DD', 'MW02') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW04DA') // MW04DA
      .attr('points', '1.33,132.33 26.33,33 110.5,1.33 149,55.33 221,108.33 221,143 105,187.67')
      .attr('fill', '#fff').attr('transform', `translate(707,597)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW04DA', 'MW04DA', 'MW04') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW04DB') // MW04DB
      .attr('points', '1,218.17 44.17,185.92 64.58,21.63 145.29,-0.02 157.98,123.32 207,242.33 207,281 101.83,327.67 1,270')
      .attr('fill', '#fff').attr('transform', `translate(565,316)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW04DB', 'MW04DB', 'MW04') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW05DA') // MW05DA
      .attr('points', '1,69.5 167.5,1.75 269,65 269,94 98.83,164.67 1,108.33')
      .attr('fill', '#fff').attr('transform', `translate(737,656)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW05DA', 'MW05DA', 'MW05') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW05DB') // MW05DB
      .attr('points', '2,94.17 169,29.08 179.33,0 215,0 215,55 272,89 272,145 101.75,211.67 2,153.67')
      .attr('fill', '#fff').attr('transform', `translate(632,491)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW05DB', 'MW05DB', 'MW05') }) // <--------
      
      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_MW05DC') // MW05DC
      .attr('points', '1,98.83 139.83,44.92 151.58,1.96 243.96,12.31 245.31,63.66 277,83.67 277,129 104.5,196 1,137.33')
      .attr('fill', '#fff').attr('transform', `translate(526,364)scale(0.9)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_TS_Level(s, '#Mask_MW05DC', 'MW05DC', 'MW05') }) // <--------
      
      // this.svgModeling
      // .append('polygon')
      // .attr('id', 'Mask_LD00DA') // LD00DA
      // .attr('points', '0,41 0,57 122,124 203,94 194,64 83,0')
      // .attr('fill', '#fff').attr('transform', `translate(410,598)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      // .call(s => { this.Goto_TS_Level(s, '#Mask_LD00DA', 'LD00DA') }) // <--------
      
      // this.svgModeling
      // .append('polygon')
      // .attr('id', 'Mask_KO01') // KO01
      // .attr('points', '0,41 0,57 122,124 203,94 194,64 83,0')
      // .attr('fill', '#fff').attr('transform', `translate(410,598)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      // .call(s => { this.Goto_TS_Level(s, '#Mask_KO01', 'KO01') }) // <--------
    }
  }
}
