<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    
    FilterValues: {
      type: Object,
      default: () => ({})
    },
    
    // Chart Data
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    // Database
    DataItems: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({
      })
    }
  },
  computed: {
    computedMod() {
      return !this.FilterValues || !this.FilterValues.MOD ? '41M001' : this.FilterValues.MOD
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()    // Global

      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
    },

    drawChart() {
      // this.draw_FormElements()
      // this.draw_BarChart()

      // console.log(!this.FilterValues ? '41M001' : this.FilterValues.MOD)
      if(!this.FilterValues) {
        this.svg.append('text').attr('x', 15).attr('y', 35).attr('font-size', 20).attr('fill','#40c4ff')
        .style('font-family','roboto').text('41M001')

        this.svg.append('text').attr('x', 15).attr('y', 45).attr('font-size', 10).attr('fill','#9E9E9E')
        .style('font-family','roboto').text('PBF Compressor - Train 4')
      }
      this.loadSvg(`${this.__HOST_NAME_RESOURCE}/TCO/SystemBoundary/${this.computedMod}.svg`).then(res => {
      })
    },

  }
}
</script>