import * as d3 from 'd3'

export default {
  data: () => ({
    innerSvg: null,
    
    style: null,
    dataSet: null,
    linkStatus: null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      let s_ = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      let d_ = JSON.parse(JSON.stringify(this.DataItems[0]))

      this.style = {
        title: {
                  visible:  s_.TITLE_VISIBLE,
                  padding:  s_.TITLE_V_PADDING,
                  fSize:    s_.TITLE_TEXT_SIZE,
                  fColor:   s_.TITLE_TEXT_COLOR,
                  xColor:   s_.TITLE_BOX_COLOR, 
                  xRadius:  s_.TITLE_BOX_RADIUS,
                  plusY:    s_.PLUS_Y,
               },
        prog: {
                  visible:  s_.PROG_VISIBLE,
                  fSize:    s_.PROG_TEXT_SIZE,
                  fColor:   s_.PROG_TEXT_COLOR,
                  unit:     s_.PROG_TEXT_UNIT,
              },
        table: {
                
                width:      s_.TABLE_BOX_WIDTH,
                height:     s_.TABLE_BOX_HEIGHT,
                xColor:     s_.TABLE_BOX_COLOR,
                opacity:     s_.TABLE_BOX_OPACITY,
                radius:     s_.TABLE_RADIUS,
                header: {
                  y:        s_.TABLE_HEADER_Y,
                  fSize:    s_.TABLE_HEADER_TEXT_SIZE,
                  fColor:   s_.TABLE_HEADER_TEXT_COLOR,
                },
                value: {
                  y:        s_.TABLE_VALUE_Y,
                  fSize:    s_.TABLE_VALUE_TEXT_SIZE,
                }
              },
      }

      this.dataSet = {
        count:  d_.COUNT,
        title:  d_.TITLE,
        prog:   d_.PROG,
        text:   [],
        value:  [],
        color:  [],
        x:      [],
        unit:   [],
        filter: [],
      }

      for(let i=0; i < this.dataSet.count; i++) {
        this.dataSet.text[i] = d_[`TEXT${i+1}`]
        this.dataSet.value[i] = d_[`VALUE${i+1}`]
        this.dataSet.color[i] = d_[`COLOR${i+1}`]
        this.dataSet.filter[i] = d_[`FILTER${i+1}`]
      }

      this.dataSet.text.forEach( (d,i)=> {
        if(d.substr(d.length-1, 1) == '$' || d.substr(d.length-1, 1) == '%') {
          this.dataSet.unit[i] = d.substr(d.length-1, 1)
          this.dataSet.text[i] = d.slice(0, -1)
        } else {
          this.dataSet.unit[i] = ''
        }
        
      })

      this.Canvas.CanvasWidth = s_.TABLE_BOX_WIDTH + 2
      this.Canvas.CanvasHeight = s_.TITLE_TEXT_SIZE + (s_.TITLE_V_PADDING*3) + s_.TABLE_BOX_HEIGHT + 2
    },

  }
}