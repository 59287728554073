import { values } from 'core-js/fn/array'
import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    blockColor: [
      { commodity: 'Assembly', color: '#FFCDD2' },
      { commodity: 'Assembly&Erection', color: '#F8BBD0' },
      { commodity: 'Cable Pulling', color: '#E1BEE7' },
      { commodity: 'Cable Termination', color: '#D1C4E9' },
      { commodity: 'Cable Tray', color: '#C5CAE9' },
      { commodity: 'Compartment', color: '#BBDEFB' },
      { commodity: 'Cranes', color: '#B3E5FC' },
      { commodity: 'Duct', color: '#B2EBF2' },
      { commodity: 'Equipment', color: '#B2DFDB' },
      { commodity: 'Equipment & Furniture', color: '#C8E6C9' },
      { commodity: 'Erection', color: '#DCEDC8' },
      { commodity: 'Fabrication', color: '#F0F4C3' },
      { commodity: 'Field Instrumentation', color: '#FFF9C4' },
      { commodity: 'Fire Protection System', color: '#FFECB3' },
      { commodity: 'Floor Works', color: '#FFE0B2' },
      { commodity: 'Machinery Erection - Rotating', color: '#FFCCBC' },
      { commodity: 'Outfitting', color: '#D7CCC8' },
      { commodity: 'PFP', color: '#CFD8DC' },
      { commodity: 'Piping', color: '#FFCDD2' },
      { commodity: 'Prefabrication', color: '#F8BBD0' },
      { commodity: 'Pressure Test', color: '#E1BEE7' },
      { commodity: 'Safety Equipment', color: '#D1C4E9' },
      { commodity: 'Skid, Packages & Misc.', color: '#C5CAE9' },
      { commodity: 'Spool', color: '#BBDEFB' },
      { commodity: 'Steel Structure', color: '#B3E5FC' },
      { commodity: 'Support', color: '#B2EBF2' },
      { commodity: 'Tertiary', color: '#F0F4C3' },
      { commodity: 'Vessels & Exchangers', color: '#FFF9C4' },
      { commodity: 'Wall & Ceiling', color: '#B2DFDB' },
    ],
    thead: [
      { key: 'WC_DESCRIPTION', html: 'Work Class Description', row: 1, rowspan: 3 },
      { key: 'UOM', html: 'UOM', row:1, rowspan: 3 },
      { key: 'INITIALQTY', html: 'Initial <br/> Q\'ty', row: 1, rowspan: 3 },
      { key: 'TARGET', html: 'Target<br/><span class="thead_subtitle__">(Based on Initial Q\'ty)</span>', row: 1, colspan: 2 },  // custom
      { key: 'ACTUAL', html: 'Actual<br/><span class="thead_subtitle__">(Actual / initial Q\'ty)</span>', row: 1, colspan: 4 },  // custom
      { key: 'Remain', html: 'Remain<br/><span class="thead_subtitle__">(Target - Actual)</span>', row: 1, colspan: 2 },  // custom

      { key: 'TARGET_QTY', html: 'Q\'ty', row: 2, rowspan: 2 },
      { key: 'TARGET_PROG', html: 'Prog(%)', row: 2, rowspan: 2 },
      { key: 'ACTUAL_CUMULATIVE', html: 'Cumulative', row: 2, colspan: 2 },
      { key: 'ACTUAL_WEEKLY', html: 'Weekly', row: 2, colspan: 2 },
      { key: 'REMAIN_QTY', html: 'Q\'ty', row: 2, rowspan: 2 },
      { key: 'REMAIN_PROG', html: 'Prog(%)', row: 2, rowspan: 2 },

      { key: 'ACTUAL_CUM_QTY', html: 'Q\'ty', row: 3 },
      { key: 'ACTUAL_CUM_PROG', html: 'Prog(%)', row: 3 },
      { key: 'ACTUAL_WEEK_QTY', html: 'Q\'ty', row: 3 },
      { key: 'ACTUAL_WEEK_PROG', html: 'Prog(%)', row: 3 },
    ],
    theadrows: 3,
    tbody: [
      { key: 'WC_DESCRIPTION', w: 310, align: 'left' },
      { key: 'UOM', w: 50, align: 'center' },
      { key: 'INITIALQTY', w: 50 },
      { key: 'TARGET_QTY', w: 60 },
      { key: 'TARGET_PROG', w: 60 },
      { key: 'ACTUAL_CUM_QTY', w: 60 },
      { key: 'ACTUAL_CUM_PROG', w: 60 },
      { key: 'ACTUAL_WEEK_QTY', w: 60 },
      { key: 'ACTUAL_WEEK_PROG', w: 60 },
      { key: 'REMAIN_QTY', w: 60 },
      { key: 'REMAIN_PROG', w: 60 },
    ],
    // itemWidth: 105,
    width: 960,
    
    scrollProps: {
      w: 932,
      h: 300,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),

  methods: {
    drawChart() {
      let discValues_ = this.DataItems
      let discItemWidth = 95
      
      let svg = this.svg

      let legendGroup = svg.append('g').attr('class', 'ogm_status_group_legend__').attr('transform', `translate(0, 0)`)

      // Target
      legendGroup.append('rect')
      .attr('x', 0).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', 50).attr('height', 20).attr('fill', '#E1F5FE')

      legendGroup.append('text').attr('x', 25).attr('y', 23).attr('text-anchor', 'middle')
      .text('Target')
      .style('font-size',10)
      .attr('fill', '#01579B')

      // Plan
      legendGroup.append('rect')
      .attr('x', 55).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', 45).attr('height', 20).attr('fill', '#EEEEEE')

      legendGroup.append('text').attr('x', 78).attr('y', 23).attr('text-anchor', 'middle')
      .text('Plan')
      .style('font-size',10)
      .attr('fill', '#212121')

      // Actual
      legendGroup.append('rect')
      .attr('x', 105).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', 50).attr('height', 20).attr('fill', '#66BB6A')

      legendGroup.append('text').attr('x', 130).attr('y', 23).attr('text-anchor', 'middle')
      .text('Actual')
      .style('font-size',10)
      .attr('fill', '#fff')

      // Delta
      legendGroup.append('rect')
      .attr('x', 160).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', 45).attr('height', 20).attr('fill', 'Transparent').attr('stroke', '#ccc')

      legendGroup.append('text').attr('x', 183).attr('y', 23).attr('text-anchor', 'middle')
      .text('Delta')
      .style('font-size',10)
      .attr('fill', 'red')

      let discgroup = svg.append('g').attr('class', 'ogm_status_group__').attr('transform', `translate(0.5, 40)`)

      discgroup.append('rect').attr('class', 'ogm_background_bar__')
      .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', discItemWidth).attr('height', 280).attr('fill', '#eee').attr('opacity', '0.7')

      discValues_.forEach((v, i) => {
        let g = discgroup.append('g').attr('class', `${v.DISC_DESCR}_status__`).attr('transform', `translate(${i*(discItemWidth + 12)}, 0.5)`)

        // Imgae box
        g.append('rect')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 90).attr('height', 90)
        .attr('fill', v.DELTA > 0 ? '#4CAF50' : !v.DELTA || v.DELTA === 0 ? '#fff' : '#F44336')
        .attr('opacity', '0.7')

        if (v.ACTUAL_PROG !== 0) {
          g.append('rect')
          .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 90)
          .attr('height', parseInt(90 - (v.ACTUAL_PROG < 0 ? v.ACTUAL_PROG * -1 : v.ACTUAL_PROG) / 100 * 90))
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        } else {
          g.append('rect')
          .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 90)
          .attr('height', 90)
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        }

        g.append('image')
        .attr('id', `omg_img_${v.DISC_DESCR.toLowerCase()}`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/LQDashboard/icons/${v.DISC_DESCR.toLowerCase()}.png`)
        .attr('x', 0)
        .attr('width', '90px')
        .attr('height', '90px')

        // Discipline desc
        g.append('text')
        .attr('x', 45 + 0).attr('y', 90 + 15).attr('text-anchor', 'middle')
        .text(v.DISC_DESCR)
        .style('font-size',12)
        .style('color', '#0D47A1')
        .style('font-weight', 'bold')

        // Target
        g.append('rect')
        .attr('x', 10 + 0).attr('y', 120).attr('rx', 3).attr('ry', 3).attr('width', 70).attr('height', 25).attr('fill', '#E1F5FE')

        g.append('text').attr('x', 45 + 0).attr('y', 137).attr('text-anchor', 'middle')
        .text(v.TARGET_PROG + '%')
        .style('font-size',12)
        .attr('fill', '#01579B')

        // Plan
        g.append('rect')
        .attr('x', 10 + 0).attr('y', 160).attr('rx', 3).attr('ry', 3).attr('width', 70).attr('height', 25).attr('fill', '#EEEEEE')

        g.append('text').attr('x', 45 + 0).attr('y', 177).attr('text-anchor', 'middle')
        .text(v.PLAN_PROG + '%')
        .style('font-size',12)
        .attr('fill', '#212121')

        // Actual
        g.append('rect')
        .attr('x', 10 + 0).attr('y', 200).attr('rx', 3).attr('ry', 3).attr('width', 70).attr('height', 25).attr('fill', '#66BB6A')

        g.append('text').attr('x', 45 + 0).attr('y', 217).attr('text-anchor', 'middle')
        .text(v.ACTUAL_PROG + '%')
        .style('font-size',12)
        .attr('fill', '#fff')

        // Delta
        g.append('rect')
        .attr('x', 10 + 0).attr('y', 240).attr('rx', 3).attr('ry', 3).attr('width', 70).attr('height', 25).attr('fill', 'Transparent').attr('stroke', '#ccc')

        g.append('text').attr('x', 50 + 0).attr('y', 257).attr('text-anchor', 'middle')
        .text(`${v.DELTA > 0 ? '▲ ' : v.DELTA === 0 ? '': '▼ '}` + v.DELTA + '%')
        .style('font-size',12)
        .attr('fill', v.DELTA > 0 ? 'green' : v.DELTA === 0 ? '#ccc' : 'red')

        // Mask
        let mask = g.append('rect').attr('class', '_ogm_discgroup_mask__')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', discItemWidth).attr('height', 270).attr('fill', 'Transparent')

        mask.on('mouseover', () => {
          mask.style('cursor', 'pointer')
          d3.select('.ogm_background_bar__').transition().duration(100).attr('x', `${i*(discItemWidth + 12)}`)
        })

        mask.on('click', () => { this.drawTable(v.DISC, v.DISC_DESCR) })
      })


      this.drawTable(discValues_[0].DISC, discValues_[0].DISC_DESCR)
    },
    drawTable(disc, desc) {
      this.svg.selectAll('.ogm_table_group__formodal_table').remove()
      this.svg.selectAll('.ogm_table_group__formodal_commodities').remove()
      this.svg.selectAll('.ogm_table_group__formodal_description').remove()

      let svg = this.svg

      let commItemWidth = 165
      let commValues_ = this.Queries.SQL1.filter(d => d.DISC === disc)

      let descgroup = svg.append('g').attr('class', 'ogm_table_group__formodal_description').attr('transform', `translate(0, 320)`)
      descgroup.append('text')
        .attr('x', 10).attr('y', 15).attr('text-anchor', 'start')
        .text(desc)
        .style('font-size',14)
        .style('color', '#212121')
        .style('font-weight', 'bold')

      let commgroup = svg.append('g').attr('class', 'ogm_table_group__formodal_commodities').attr('transform', `translate(0, 340)`)

      commValues_.forEach((v, i) => {

        let g = commgroup.append('g').attr('class', `${v.COMMODITY}_status__`).attr('transform', `translate(${i*commItemWidth}, 0.5)`)

        // commodity text
        g.append('rect')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 160).attr('height', 25).attr('fill', this.blockColor.filter(d => d.commodity === v.COMMODITY)[0].color).attr('opacity', '0.7')

        g.append('text')
        .attr('x', 77.5).attr('y', 17).attr('text-anchor', 'middle')
        .text(v.COMMODITY)
        .style('font-size',12)
        .style('color', '#212121')
        // .style('font-weight', 'bold')


        // Imgae box
        g.append('rect')
        .attr('x', 0).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 95).attr('height', 95)
        .attr('fill', v.DELTA > 0 ? '#4CAF50' : !v.DELTA || v.DELTA === 0 ? '#fff' : '#F44336')
        .attr('opacity', '0.7')

        if (v.ACTUAL_CUM_PROG !== 0) {
          g.append('rect')
          .attr('x', 0).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 95)
          .attr('height', parseInt(95 - (v.ACTUAL_PROG < 0 ? v.ACTUAL_CUM_PROG * -1 : v.ACTUAL_CUM_PROG) / 100 * 95))
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        } else {
          g.append('rect')
          .attr('x', 0).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 95)
          .attr('height', 95)
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        }

        g.append('image')
        .attr('id', `omg_img_${v.COMMODITY.toLowerCase()}`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Icons/constructionItem/${v.COMMODITY.toLowerCase()}.png`)
        .attr('x', 0).attr('y', 42)
        .attr('width', '95px')
        .attr('height', '95px')

        


        // Plan
        g.append('rect')
        .attr('x', 100).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 25).attr('fill', '#eeeeee')

        g.append('text').attr('x', 130).attr('y', 58).attr('text-anchor', 'middle')
        .text(v.PLAN_PROG + '%')
        .style('font-size',12)
        .attr('fill', '#212121')

        // Actual
        g.append('rect')
        .attr('x', 100).attr('y', 76).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 25).attr('fill', '#66BB6A')

        g.append('text').attr('x', 130).attr('y', 92).attr('text-anchor', 'middle')
        .text(v.ACTUAL_CUM_PROG + '% ')
        .style('font-size',12)
        .attr('fill', '#fff')

        // Delta
        g.append('rect')
        .attr('x', 100).attr('y', 110).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 25).attr('fill', 'Transparent').attr('stroke', '#ccc')


        g.append('text').attr('x', 130).attr('y', 126).attr('text-anchor', 'middle')
        .text(`${v.DELTA > 0 ? '▲ ' : v.DELTA === 0 ? '': '▼ '}` + v.DELTA + '%')
        .style('font-size',12)
        .attr('fill', v.DELTA > 0 ? 'green' : v.DELTA === 0 ? '#ccc' : 'red')

      })



      let group = svg.append('g').attr('class', 'ogm_table_group__formodal_table')
      
      let table_group = group.append('g').attr('transform', `translate(0.5, 490.5)`)

      let list = this.Queries.SQL2.filter(d => d.DISC === disc)
      let tableViewHeight = 96 + (list.length * 18) // thead + tbody

      // if (tableViewHeight > this.scrollProps.h) this.setScroll(table_group, this.scrollProps)

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', `${tableViewHeight}px`)
        .append('xhtml:body')

      let table = table_obj.append('table')
                  .style('border', 'solid 0.7px #eee')
                  .style('border-collapse', 'collapse')
                  .style('font-size', '10px')
                  .style('width', `${this.width}px`)
                  .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr').style('background', '#FAFAFA')

        row.forEach(item => {
          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('border', 'solid 0.7px #eee')
          .style('height', '18px')
          .style('padding', '5px')
          .html(item.html)
        })        
      }
        
      let tbodykeys = this.tbody
      
      let tbody = table.append('tbody')

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {
          let td = tr.append('td')
            .style('border', 'solid 0.7px #eee')
            .style('height', '18px')
            .style('text-align', k.align ? k.align : 'right')
            .style('padding', '0 3px 0 3px')

          if(i === 0) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }

          td.on('mouseover', () => tr.style('cursor', 'pointer'))
          td.on('click', () => {
            let dataSource = {
              GWBS_LV2: list[r].GWBS_LV2,
              WCCODE: list[r].WCCODE
            }
            let request_ = this.getRequestColumnProps('status', dataSource, this.ColumnProps)
            this.$emit('request-action', request_)
          })

          if (!list[r][k.key]) return

          if(k.key.includes('PROG')) {
            td.style('position', 'relative')
              .append('div')
              .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
              .style('height', '12px')
              .style('background-color', list[r][k.key] < 100 ? '#fde05d' : '#43A047')

            td.append('div')
            .style('position', 'absolute')
            .style('top', '1px')
            .style('right', '5px')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : '#000')
            .html(list[r][k.key]+'%')
          }else {
            if (k.key === 'INITIALQTY' || k.key === 'REMAIN_QTY' || k.key === 'TARGET_QTY' || k.key === 'ACTUAL_CUM_QTY' || k.key === 'ACTUAL_WEEK_QTY') td.html(list[r][k.key].toFixed(1))
            else td.html(list[r][k.key])
          }


        })
      }

      d3.selectAll('.thead_subtitle__').style('color', '#00B0FF').style('font-weight', 'normal')

    }


  }
}