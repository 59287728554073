export default {
  data: () => ({
    
  }),

  methods: {

    Canvas_Overall() {
      
      this.CANVAS = this.svg.append('svg')

      this.CANVAS
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)

      let style = null


      // Overall Progress with grids -------------------------------------------------------------------------------------------------

      let item = this.Queries.MasterProg.find(f => f.Lv1 == 'PROJECT' && f.Lv2 == 'OVERALL')


      let ProjectProgress = this.CANVAS
      .append('g')
      .attr('transform', `translate(40, 120)`)

      ProjectProgress
      .append('rect')
      .attr('transform', `translate(0, 20)`).attr('width', 1000).attr('height', 16).attr('fill', '#F4F5F5')

      ProjectProgress
      .append('rect')
      .attr('transform', `translate(0, 20)`).attr('width', item.ACTUAL*10).attr('height', 16).attr('fill', '#7BAF30')

      ProjectProgress
      .append('text')
      .attr('transform', `translate(${(item.ACTUAL*10)-2}, 32)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end').text(`${item.ACTUAL}%`)

      ProjectProgress
      .append('text')
      .attr('transform', `translate(${(item.ACTUAL*10)-2}, 48)`)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').style('text-anchor', 'end').text(`Plan Cuml.(%)`)

      ProjectProgress
      .append('text')
      .attr('transform', `translate(${(item.ACTUAL*10)-2}, 60)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').style('text-anchor', 'end').text(`${item.PLAN}%`)

      ProjectProgress
      .append('text')
      .attr('transform', `translate(${(item.ACTUAL*10)+-2}, 78)`)
      .style('font-family', 'roboto').style('font-size', 14).style('text-anchor', 'end')
      .style('fill', () => {
        if(item.DELTA < 0) return '#EC407A'; else return '#44A9DF'
      })
      .text(`${item.DELTA}%`)

      // grids 
      for (let i = 0; i <= 100; i += 20) {
        ProjectProgress
        .append('text')
        .attr('transform', `translate(${i*10}, 10)`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').style('text-anchor', 'middle').text(`${i}%`)

        ProjectProgress
        .append('line')
        .attr('x1', i*10).attr('y1', 13).attr('x2', i*10).attr('y2', 18)
        .style('stroke-width', 0.5).style('stroke', '#bcbcbc')
      }




      // Phase Progress -------------------------------------------------------------------------------Gantt Chart
      let AreaProgress = this.CANVAS
      .append('g')
      .attr('transform', `translate(50, 970)`)
      // AreaProgress Data Add LQ { TITLE: 'LQ'              , P_PG: 99.99, A_PG: 99.99, D_PG: 2,     bColor: '#FAAA18'},

      let startX = 0
      let length = 250, gapSize = 5 //chart width= 250 

      this.Queries.AreaProg.forEach(d => {

        AreaProgress
        .append('text')
        .attr('transform', `translate(${startX}, -2)`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').style('text-anchor', 'start').text(`${d.TITLE}`)

        this.Arrow_Phase(AreaProgress, startX, (1*length), '#E0E0DF', 30, 10, 0.4)
        this.Arrow_PhaseTrans(AreaProgress, startX, ((d.A_PG/100)*length), d.bColor, 30, 10, 0.75, 750)

        AreaProgress
        .append('text')
        .style('font-family', 'roboto').style('font-size', 14).text(`${d.A_PG}%`)
        .style('fill', () => {
          if(d.A_PG > 35) return '#fff'; else return '#757575'
        })
        .attr('transform', () => {
          if(d.A_PG > 35) return `translate(${startX+(d.A_PG/100)*length-9}, 20)`; else return `translate(${startX+(d.A_PG/100)*length+5}, 20)`
        })
        .style('text-anchor', () => {
          if(d.A_PG > 35) return 'end'; else return 'start'
        })
        
        AreaProgress
        .append('text')
        .attr('transform', `translate(${startX+(d.A_PG/100)*length-9}, 42)`)
        .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').style('text-anchor', 'end').text(`Plan Cuml.(%)`)

        AreaProgress
        .append('text')
        .attr('transform', `translate(${startX+(d.A_PG/100)*length-9}, 57)`)
        .style('font-family', 'roboto').style('font-size', 14).style('fill', '#757575').style('text-anchor', 'end').text(`${d.P_PG.toFixed(2)}%`)

        startX += length 
        startX += gapSize -10
      })

      // Block Status Chart ------------------------------------------------------------------------------------------------- Chart Library
      let itemSet = []
      style = {
                x: 1130,
                y: 380,
                headTitle: 'FPSO',
                prog: this.Queries.MasterProg.find(f=> f.Lv1 == 'PROJECT'),
      }

      this.Status.forEach((d,i) => {
        itemSet.push({
          name: d.title,
          total: this.dataSet.filter(f => f.EREC == 'Y').length,
          actual: this.dataSet.filter(f => f.EREC == 'Y' && f.STATUS == d.code).length,
          bColor: this.Status[i].bColor,
          opacity: this.Status[i].opacity,
        })
      })

      this.blockStatus(itemSet)
      this.Bar_Quantity_01(this.CANVAS, itemSet, style, 1) // <---- Chart Library

      this.drawProgress(this.CANVAS, 'Overall')

      




      // Progress for HULL ------------------------------------------------------------------------------------------------- Chart Library
      
      style = {
        headTitle: 'HULL & LQ',
        headColor: '#fff',
        title:    ['Lv2. Area', 'Plan', 'Actual', 'Delta'],
        cell:     [50, 110, 155, 200],
        x:        50,
        y:        215,
        boxWidth: 90,
        boxHeight: 22,
        tColor: '#757575',
        lineHeight: 16,
        tblWidth: 210,
        tblHeight: 90,

        progDisplay: 'Y',
        prog: this.Queries.MasterProg.find(f=> f.Lv1 == 'HULL' && f.Lv2 == 'OVERALL').ACTUAL,
      }

      let dataTrans = this.Queries.MasterProg.filter(f=> f.Lv1 == 'HULL' && f.Lv3 == 'OVERALL')

      this.SummaryTable_01(this.CANVAS, dataTrans, style) // <---- Chart Library




      // Progress for Topside ------------------------------------------------------------------------------------------------- Chart Library
      
      style = {
        headTitle: 'TOPSIDE',
        headColor: '#fff',
        title:    ['Lv2. Area', 'Plan', 'Actual', 'Delta'],
        cell:     [50, 110, 155, 200],
        x:        50,
        y:        355,
        boxWidth: 90,
        boxHeight: 22,
        tColor: '#757575',
        lineHeight: 16,
        tblWidth: 210,
        tblHeight: 60,

        progDisplay: 'Y',
        prog: this.Queries.MasterProg.find(f=> f.Lv1 == 'TOPSIDE' && f.Lv2 == 'OVERALL').ACTUAL,
      }

      dataTrans = this.Queries.MasterProg.filter(f=> f.Lv1 == 'TOPSIDE' && f.Lv3 == 'OVERALL')

      this.SummaryTable_01(this.CANVAS, dataTrans, style) // <---- Chart Library




      // Progress for Mooring ------------------------------------------------------------------------------------------------- Chart Library
      
      style = {
        headTitle: 'MOORING',
        headColor: '#fff',
        title:    ['Lv2. Area', 'Plan', 'Actual', 'Delta'],
        cell:     [50, 110, 155, 200],
        x:        50,
        y:        465,
        boxWidth: 90,
        boxHeight: 22,
        tColor: '#757575',
        lineHeight: 16,
        tblWidth: 210,
        tblHeight: 45,

        progDisplay: 'Y',
        prog: this.Queries.MasterProg.find(f=> f.Lv1 == 'MOORING' && f.Lv2 == 'OVERALL').ACTUAL,
      }

      dataTrans = this.Queries.MasterProg.filter(f=> f.Lv1 == 'MOORING' && f.Lv3 == 'OVERALL')

      this.SummaryTable_01(this.CANVAS, dataTrans, style) // <---- Chart Library
 
      
    },


  }
}