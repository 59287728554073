import * as d3 from 'd3'

export default {

  methods: {
    drawChart() {
      // console.log(this.DataItems[0])
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightBlue`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#d4eaf3').attr('offset', '0')
      lightBlue .append('stop').attr('stop-color', '#a3d0e8').attr('offset', '1')

      let mainGroup = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      // mainGroup
      // .append('rect') // Background Window
      // .attr('x', 0).attr('y', 0).attr('width', 310).attr('height', 270)
      // .attr('fill', '#fff').attr('stroke', 'gray').attr('stroke-width',1)

      let len = Math.round((this.DataItems[0].SIZE_L || 0) / 1000)
      let wid = Math.round((this.DataItems[0].SIZE_B || 0) / 1000)
      let hei = Math.round((this.DataItems[0].SIZE_H || 0) / 1000)
      let wei = this.DataItems[0].WEIGHT || 0

      let blockPhoto = mainGroup
      .append('g')
      .attr('transform', `translate(20, 40)`)

      // console.log(this.moduleDirName(this.DataItems[0].GWBS_LV1))
      //왜 Topside common 클릭했을때 lv1이 hull 이 나오는것일까??

      blockPhoto
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 5).attr('ry', 5).attr('width', 270).attr('height', 185)
      .attr('fill', '#fff').attr('stroke-width', 0.2).attr('stroke', '#bcbcbc')

      // Display Images ------------------------------------------------------------------
      let path = `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/${this.moduleDirName(this.DataItems[0].GWBS_LV1)}/${this.DataItems[0].BLOCK}.png`
      // let path = `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/${this.DataItems[0].LV1 == 'TOPSIDES' ? this.DataItems[0].BD_LV3 : this.DataItems[0].BLOCK}.png`
      // let path = `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/${this.moduleDirName(this.DataItems[0].GWBS_LV1)}/${this.moduleDirName(this.DataItems[0].GWBS_LV1)=='TOPSIDES' ? this.DataItems[0].BD_LV3 : this.DataItems[0].BLOCK}.png`

      try {
        let http = new XMLHttpRequest();
        http.open('HEAD', path, false)
        http.send()
        var status = http.status

      } catch (e) {
        status = 404
      }
      // console.log(this.DataItems[0])
      if(status != 200) path = require('../../../../../../src/assets/svg/img/noimage.png')

      blockPhoto
      .append('image')
      .attr('id', 'block_image')
      .attr('xlink:href', path)
      .attr('x', 5).attr('y', 5).attr('width', 260).attr('height', 175)
    
      // Display 3D ----------------------------------------------------------------------
      // const path = this.DataItems[0].BLOCK 
      // ? `${this.__HOST_NAME_RESOURCE}/TORTUE/Skyline/${this.DataItems[0].BLOCK}.png`
      // : require('../../../../../../src/assets/svg/img/noimage.png')

      // blockPhoto
      // .append("foreignObject")
      // // .attr('clip-path', 'url(#mask_eq3d_viewer_)')
      // .attr('transform', `translate(1, 1)`)
      // .attr('width', 268)
      // .attr('height', 183)
      // .append('xhtml:iframe')
      // .attr('id', 'eq_3d_viewer')
      // .attr('src', `${this.__HOST_NAME}/#/pub_3d_viewer/token/${this.DataItems[0].BLOCK}/{"d":"blk","w":268,"h":183}`)
      // .attr('width', 270)
      // .attr('height', 185)
      // .attr('frameBorder', 0)
      // .attr('scrolling', 'no')
      // .attr('style', 'border: none;')

      blockPhoto
      .append('rect')
      .attr('x', 0).attr('y', 195).attr('rx', 5).attr('ry', 5).attr('width', 70).attr('height', 24)
      .attr('fill', `url(#lightBlue)`).attr('stroke-width', 0.2).attr('stroke', '#bcbcbc').attr('opacity', .7)

      blockPhoto
      .append('rect')
      .attr('x', 80).attr('y', 195).attr('rx', 5).attr('ry', 5).attr('width', 190).attr('height', 24)
      .attr('fill', '#F4F5F5').attr('stroke-width', 0)

      blockPhoto
      .append('text')
      .attr('x', 10).attr('y', 210)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'start').attr('fill', '#333')
      .text('Dimension')

      blockPhoto
      .append('text')
      .attr('x', 90).attr('y', 210)
      .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'start').attr('fill', '#333')
      .text(`${wid}m x ${len}m x ${hei}m | ${wei} tons`)

      if(status != 200) return

      let btn3DViewer = blockPhoto
      .append('g')
      .attr('id', 'button_wrapper')
      .attr('transform', `translate(210, -20)`)
      .style('font-family', 'roboto')
      .style('cursor', 'pointer') 
      .on('mouseover', function() { d3.select(this).transition().duration(100).style('opacity', .5) })
      .on('mouseout', function() { d3.select(this).transition().duration(100).style('opacity', 1) })
      .on('click', () => {
        //console.log(this.DataItems[0])
        let request_ = this.getRequestColumnProps('blockInfo', { ...this.DataItems[0], ...this.FilterValues }, this.ColumnProps)
        if(request_) this.$emit('request-action', request_)
      })

      btn3DViewer
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('width', 60)
      .attr('height', 14)
      .attr('fill', '#e5e5e5')

      btn3DViewer
      .append('text')
      .attr('transform', `translate(30, 8)`)
      .style('font-family', 'Roboto-Regular, Roboto')
      .style('font-size', 9)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('3D Viewer')
    },
    moduleDirName(name) {
      if(name == 'TOPSIDES') return 'Topsides'
      return 'Hull'
    },
  }
}
