import * as d3 from 'd3'
import __C from '../../../../../../includes/primitives/_constant_'
import __M from 'moment'

export default {
  data: () => ({
    scaleY: null,
    labelY: 45,
    labelYGap: 18,
  }),

  methods: {
    draw_Table() {
      this.putTableForm()
      this.putTableValues()
    }, 
    putTableForm() {
      if(this.chartMounted) return

      // let drawX = 0
      let drawY = 30

      let dataTable = this.SkylineCharts
      .append('g')
      .attr('class', 'table_form')
      .attr('transform', `translate(${this.timeline.baseX},${this.timeline.baseY + 60})`)

      // Vertical Line
      dataTable
      .append('line').attr('x1', -90).attr('x2', -90).attr('y1', 35).attr('y2', 161).attr('stroke-width', 0.5).attr('stroke', '#757575')
      dataTable
      .append('line').attr('x1', 0).attr('x2', 0).attr('y1', 20).attr('y2', 161).attr('stroke-width', 0.5).attr('stroke', '#757575')

      // Horizontal Line
      // drawY = 0
      // dataTable
      // .append('line').attr('x1', 0).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')
      drawY = 20

      dataTable
      .append('line').attr('x1', 0).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')
      drawY += 15

      drawY = 35
      dataTable
      .append('line').attr('x1', -90).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')
      drawY += 18

      dataTable
      .append('line').attr('x1', -90).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -90).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -90).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -90).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -90).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -90).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#bcbcbc')
      drawY += 18

      dataTable
      .append('line').attr('x1', -90).attr('x2', this.timeline.length).attr('y1', drawY).attr('y2', drawY).attr('stroke-width', 0.5).attr('stroke', '#757575')

      dataTable
      .append('g')
      .attr('transform', `translate(30,0)`)
      .selectAll('text')
      .data(this.table.values)
      .enter()
      .append('text')
      .attr('x', -115)
      .attr('y', (_, i) => this.labelY + this.labelYGap * i)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.month.tSize)
      .attr('fill', d => d.tColor || this.timeline.style.month.tColor)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(d => d.text)

      // Month Text & Timeline Seperator for the Month
      // dataTable
      // .append('g')
      // .selectAll('text')
      // .data(this.timeline.month)
      // .enter()
      // .append('text')
      // .attr('x', d => this.timeline.scale(d.mDate))
      // .attr('y', 5)
      // .style('font-family', this.timeline.font)
      // .style('font-size', this.timeline.style.month.tSize)
      // .attr('fill', this.timeline.style.month.tColor)
      // .attr('text-anchor', 'middle')
      // .attr('alignment-baseline', 'hanging')
      // .text(d => d.name.toUpperCase())

      dataTable
      .append('g')
      .selectAll('path')
      .data(this.timeline.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => `M${this.timeline.scale(d.eDate)}, 20 V 161`)
      .attr('stroke', (d,i) => {
        if (i == this.timeline.month.length-1) return '#757575'; else return '#bcbcbc'
      })
      .attr('stroke-width', 0.5)
    
      // Weeks Text & Timeline Seperator for the Week
      dataTable
      .append('g')
      .selectAll('text')
      .data(this.timeline.week)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 23)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.week.tSize)
      .attr('fill', '#bababa')
      // .attr('fill', this.timeline.style.week.tColor)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .text(d => __M(d.eDate).week())

      dataTable
      .append('g')
      .selectAll('path')
      .data(this.timeline.week)
      .enter()
      .append('path')
      .attr('d', (d, i) => `M${this.timeline.scale(d.eDate)}, 20 V 161`)
      .attr('stroke', this.timeline.style.week.sColor)
      .attr('stroke-width', 0.5)
    },
    putTableValues() {
      if(this.chartMounted) {
        d3.select(`#${this.localId}`).select('.table_values').remove()
        if(!this.Queries.SQL3 || this.Queries.SQL3.length === 0) return
      }

      let dataTable = this.SkylineCharts
      .append('g')
      .attr('class', 'table_values')
      .attr('transform', `translate(${this.timeline.baseX},${this.timeline.baseY + 60})`)

      let prevWeek_ = null
      // Group of all values
      dataTable
      .append('g')
      .selectAll('g')
      .data(this.timeline.week)
      .enter()
      .append('g')
      .attr('transform', (_, i) => `translate(${this.timeline.weekSize * i}, 45)`)
      .selectAll('text')
      .data(d => {
        let data_ = {...this.Queries.SQL3.find(d___ => __M(d.eDate).format('YYYY-MM-DD') == d___[this.timelineKeyName])}
        let cumm_ = this.Queries.SQL4.find(d___ => __M(d.eDate).format('YYYY-MM-DD') == d___.MC_PLAN)
        if(!data_) {
          if(!prevWeek_) return {}
          
          data_ = prevWeek_
          Object.keys(data_).forEach(k_ => { if(!['A_CUM_PG'].includes(k_)) data_[k_] = 0 })
        } else prevWeek_ = { ...data_ }

        data_.A_CUM_PG = cumm_ ? cumm_.A_CUM_PG : data_.A_CUM_PG
        
        return this.table.values.map((v_, j) => ({
          y: this.labelYGap * j,
          tColor: v_.tColor || '#757575',
          value: v_.colName == 'A_CUM_PG' && __M(d.eDate).format('YYYY-MM-DD') > this.Queries.SQL2[0].CDATE ? 0 : data_[v_.colName]
        }))
      })
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.weekSize-2},${d.y})`)
      .attr('font-family', 'roboto')
      .attr('font-size', 9)
      .attr('fill', d => d.tColor)
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .text(d => d.value ? Number(d.value).toLocaleString() : '')
      .on('mouseover', (d, j, a) => {
        let node = 2
        d3.select(a[j]).style('cursor', 'pointer')
        d3.select(a[j]).transition().duration(100)
        .attr('transform', `translate(${this.timeline.weekSize-2+node}, ${d.y}) scale(1.5)`).style('fill', '#EC407A')
      })
      .on('mouseout', (d, j, a) => {
        d3.select(a[j]).transition().duration(100)
        .attr('transform', `translate(${this.timeline.weekSize-2}, ${d.y}) scale(1)`).style('fill', d.tColor)
      })
      .on('click', () => {
        let requestTrimmed_ = this.trimFilterPure()
        let request_ = {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 188,
            no: ''
          },
          filters     : { ...requestTrimmed_.filters },
          iFilters    : {
            filterString : '',
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)
      })

      // this.Queries.SQL2.forEach((d, i)=> {
      //   drawY = 45

      //   // Plan Weekly
      //   dataTable
      //   .append('text')
      //   .attr('x', drawX + (this.timeline.weekSize - 3))
      //   .attr('y', drawY)
      //   .attr('font-family', 'roboto')
      //   .attr('font-size', 10)
      //   .attr('fill', '#757575')
      //   .attr('text-anchor', 'end')
      //   .attr('alignment-baseline', 'middle')
      //   .text(() => {
      //     if (i == 0) {
      //       d.value
      //      } else {
      //        return (d.value - this.timeline.plan[i-1].value).toFixed(1)
      //      }
      //   })

      //   drawX += this.timeline.weekSize
      // })
    }
  }
}
