import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    Draw_Content() {
      let svg = this.svg
        .append('g')
        .attr('transform', `translate(0, 0)`)

      svg
        .append('image')
        .attr('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TCO/Hsse/hsse.png`)
        
    }
  }
}