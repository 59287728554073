<template>
  <div :id="localId">
    <div class="service_photo_gallery_single">
      <!-- <div class="header">
        <span class="title">FEATURE Category</span>
        <span class="sub_title">The following images are "Feature".</span>
      </div> -->
      <div class="controller">
        <j-pagination 
          :showStatu="true"
          :pagination="pagination"
          :sideslide="true"
          @first="onPageTo(1)"
          @previous="onPageTo"
          @next="onPageTo"
          @last="onPageTo"
          />
        <a 
          class="btn-add" title="add new photo"
          @click="onAdd"
        >
          <span><i class="mdi mdi-plus"></i></span>
        </a>
        <div class="add_Text">Add </div>
        <div class="date-picker">
          <j-date-picker
            v-model="StartDate"
            class="modal"
            :nudge-top="-23"
            :nudge-left="138"
            :offset-y="true"
            :menu-class="'bottom'"
          />
          <p class="middle-allow"> ~ </p> 
          <j-date-picker
            v-model="FinishDate"
            class="modal"
            :nudge-top="-23"
            :nudge-left="138"
            :offset-y="true"
            :menu-class="'bottom'"
          />          
          <i 
            v-if="chainResetable"
            aria-hidden="true" 
            class="v-icon v-icon--link mdi mdi-close theme--light" 
            title="Clear all filters"
            @click="ClearDate"
          />          
        </div>
        <input class="search" type="text" v-model="SearchText" />
        <div class="search_tail">
          <a 
            class="btn-search" title="add new photo" 
          >
            <i class="mdi mdi-magnify"></i>
          </a>
        </div>
      </div>
      <div class="content">
        <div v-for="(item, i) in filteredItems" class="item" :key="item.idx">
          <div class="border_out">
            <div 
              class="border_in" 
              :id="`__thumb_${i}`" 
              :class="setThumb(`__thumb_${i}`, item.dataUrl)"
              :title="item.title"
              @click="onSelect(item, i)"
            ></div>
          </div>
          <!-- <div class="progress">
            <div class="bar">
              <span class="value">53%</span>
            </div>
          </div> -->
          <div class="discription">
            <span :title="item.title" class="title" style="line-height: 1.8rem !important;">{{ item.title }}</span>
            <span class="sub_title">Posted {{ postedDaysBefore(item.date) }} by <span class="created_by">{{ item.createdBy }}</span> </span>
            <span class="date">{{ item.date }}</span>
          </div>
        </div>
      </div>
    </div>

    <j-overlay
      color="#000"
      opacity=".75"
      :value="view"
    >
      <div class="content_wrapper">
        <div class="prev_wrap" :class="{ disabled: selectedIndex <= 0 }" @click="onPrev"><i class="mdi mdi-chevron-left"></i></div>
        <div class="photo_viewer">
          <!-- <img v-if="selectedIndex >= 0" class="photo" :src="`${__C_.HOST_NAME}${selectedItem.path}`">
           -->
          <img v-if="selectedIndex >= 0" class="photo" id="photo_select" > 
        </div>
        <div class="next_wrap" :class="{ disabled: selectedIndex >= items.length-1 }" @click="onNext"><i class="mdi mdi-chevron-right"></i></div>
      </div>
    </j-overlay>
    <div v-if="full" class="full_size_view" @click="onCloseFullSize">
      <div :style="`width: ${fullSizeInfo.width}px; height: ${fullSizeInfo.height}px`">
        <!-- <img :src="fullSizeInfo.src"> -->
        <img id="video_full">
      </div>
    </div>
    <div v-if="view" class="overlay_footer">
      <div v-html="slideFilenames" class="file_names"></div>
      <div class="action">
        <a class="download" @click="onDownload">
          <i class="mdi mdi-download"></i> <span>DOWNLOAD</span>
        </a>
        <div class="action_spacer"></div>
        <a class="full" @click="onShowFullSize">
          <i class="mdi mdi-eye-outline"></i> <span>VIEW FULL SIZE</span>
        </a>
        <div class="action_spacer"></div>
        <a class="edit" @click="onEdit">
          <i class="mdi mdi-pencil"></i> <span>EDIT</span>
        </a>
        <div class="action_spacer"></div>
        <a class="edit" @click="onDelete">
          <i class="mdi mdi-trash-can-outline"></i> <span>DELETE</span>
        </a>
      </div>
    </div>

    <j-modal-slide v-model="maOpened" :width="750">
      <template #header>
        <div class="header">
          <span class="title">Photo Gallery</span>
          <small class="sub_title">Upload</small>
        </div>
      </template>
      <div class="content">
        <div class="content_header">
          <span class="content_header_title">Upload / Modification</span>
        </div>
        <v-form v-model="valid" ref="form">
          <v-layout wrap>
            <v-flex sm3 class="wrap__select">
              <v-select
                v-model="selectedItem.lv1"
                dense
                required
                class="field"
                label="Level 1"
                item-text="lv1"
                item-value="lv1"
                placeholder="Select a LV1"
                :menu-props="{contentClass:'single_select'}"
                :items="fotions.lv1"
                @input="() => { onFilterChanged('lv1') }"
              ></v-select>
            </v-flex>
            <v-flex sm3 class="wrap__select">
              <div class="form_spacer"></div>
              <v-select
                v-model="selectedItem.lv2"
                dense
                required
                class="field"
                label="Level 2"
                item-text="lv2"
                item-value="lv2"
                placeholder="Select a LV2"
                :menu-props="{contentClass:'single_select'}"
                :items="fotions.lv2"
                @input="() => { onFilterChanged('lv2') }"
              ></v-select>
            </v-flex>
            <v-flex sm3 class="wrap__select">
              <div class="form_spacer"></div>
              <v-select
                v-model="selectedItem.lv3"
                dense
                required
                class="field"
                label="Level 3"
                item-text="lv3"
                item-value="lv3"
                placeholder="Select a LV3"
                :menu-props="{contentClass:'single_select'}"
                :items="fotions.lv3"
                @input="() => { onFilterChanged('lv3') }"
              ></v-select>
            </v-flex>
            <v-flex sm3 class="wrap__select">
              <div class="form_spacer"></div>
              <v-select
                v-model="selectedItem.area"
                dense
                required
                class="field"
                label="Area"
                item-text="area"
                item-value="area"
                placeholder="Select a Area"
                :menu-props="{contentClass:'single_select'}"
                :items="fotions.area"
                @input="() => { onFilterChanged('area') }"
              ></v-select>
            </v-flex>            
            <v-flex sm3 class="wrap__select">
              <v-select
                v-model="selectedItem.block"
                dense
                required
                class="field"
                label="Block No."
                item-text="block"
                item-value="block"
                placeholder="Select a Block No."
                :menu-props="{contentClass:'single_select'}"
                :items="fotions.block"
                @input="() => { onFilterChanged('block') }"
              ></v-select>
            </v-flex>           
            <v-flex sm3 class="wrap__select">
              <div class="form_spacer"></div>
              <j-date-picker
                v-model="updatedDate"
                class="modal"
                :nudge-top="-27"
                :nudge-left="148"
                :offset-y="true"
                :menu-class="'bottom'"
              />
            </v-flex>
            <div class="clear_button">
              <j-button class="type01 clear  type_full"  @click="Clear">Clear</j-button> 
            </div>
          </v-layout>
          <v-layout>
            <v-flex sm5>
              <v-text-field
                v-model="selectedItem.title"
                class="__title"
                label="Title"
                placeholder="Input/Edit Icon Title"
                :rules="defaultRules"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <div class="uploader_wrapper">
            <j-fileuploader 
              ref="fileUploader"
              @loading-start="onLoadingStated"
              @loading-end="onLoadingEnded"
              @complete="onComplete"
              @thumbnail="onThumbnail"
            />
            <div class="form_spacer"></div>
            <div class="botton_wrapper">
              <j-button v-if="modeNew" class="type01 sky  type_full" :class="{ disabled: disabled }" :disabled="disabled" @click="onUpload">Upload</j-button>
              <j-button v-else class="type01 sky  type_full" :class="{ disabled: disabled }" :disabled="disabled" @click="onUpload">Update</j-button>
              <div class="form_spacer"></div>
              <j-button v-if="modeEdit" class="type01 delete  type_full" @click="onDelete">Delete</j-button>
            </div>
          </div>

        </v-form>
      </div>
    </j-modal-slide>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

  </div>
</template>

<script>
import '@/assets/stylus/ui/component/_service.photo.gallery_single.styl'

import * as d3 from 'd3'
import { mapState, mapGetters,mapActions, mapMutations } from "vuex"
// import FileDownloader from 'js-file-download'
import Moment from 'moment'

import __C from '@/primitives/_constant_'
import __data from '@/primitives/_temp_gallery_options'
import Url from '@/services/api/request.url'

import { FileService, FetchStreamService } from "@/services"
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import Loading from '@/mixins/loading.mixin'
import JPagination from '../../../lib/jin/uicomponents/JPagination'

export default {
  name: 'service-photo-gallery',
  components: {
  JPagination,
},
  mixins: [
    Loading,
    SafeIdMixin
  ],
  data: () => ({
    imgWidth: 0,
    imgHeight: 0,
    fetchStreamService: null,
    fileService: null,
    items: [],
    maOpened: false,
    mode: __C.FORM.EDIT_MODE_NEW,
    
    selectedIndex: 0,
    selectedItem: {},

    fullSizeInfo: {
      src: '',
      width: 0,
      height: 0,
    },
    
    pagination: {
      page: 1,
      rowsPerPage: 21,
      totalRows: null
    },

    full: false,
    view: false,
    valid: false,
    processing: false,

    defaultRules: [v => !!v || 'Required'],

    // temporal
    // blockOptions: __data.block,
    moduleOptions: __data.module,
    descOptions: __data.discDesc,

    fsources: {},
    fotions: {},
    fnames: ['lv1', 'lv2', 'lv3', 'area', 'block'],

    lv1_Options: [],
    lv2_Options: [],
    lv3_Options: [],
    areaOptions: [],
    blockOptions: [],
    totOptions: [],
    totalItems: [],
    prevPage: 0,

    msgOpen: false,
    msgInfo: {
      type: '',
      title: '',
      titleDescription: '',
      message: '',
      button: [true, false, true],
      buttonText: ['Yes', 'No', 'Cancel']
    },
    yes: () => {},
    SearchText : '',
    StartDate: '',
    FinishDate: '',
    min: '',
    max: '',
    getFlag : true,
    prevStart : '',
    prevFinish: '',
    im_FirstDate: '',
    im_FinishDate: '',
    mfilter: {},
    miFilter: {}
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    // ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, ['getPhoItems']),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, ['getClear']),

    __C_() { return __C },

    updatedDate: {
      get() { return this.selectedItem.date || Moment().format('YYYY-MM-DD') },
      set(val) { this.selectedItem = { ...this.selectedItem, date: val } } 
    },

    disabled() { return !this.updatable || this.processing },
    updatable() {
      if(!this.selectedItem.title) return false
      if(this.modeNew) {
        if(!this.selectedItem.files || this.selectedItem.files.length === 0) return false
      } else if(this.selectedIndex < 0) return false
      return true
    },

    slideFilenames() {
      if(this.selectedIndex < 0) return ''

      let prevName = this.selectedIndex > 0 ? this.items[this.selectedIndex - 1].title : ''
      let nextName = this.selectedIndex < this.items.length - 1 ? this.items[this.selectedIndex + 1].title : ''
      return  (prevName ? `<span class="file_name_prev"><span>${prevName}</span> <span class="icon"><</span></span>` : '') +
              `<span class="file_name_current">${this.items[this.selectedIndex].title}</span>` +
              (nextName ? `<span class="file_name_next"><span class="icon">></span> <span class="text">${nextName}</span></span>` : '')
    },
    viewValid() { return this.selectedIndex >= 0 && Object.keys(this.selectedItem).length > 0 },
    modeNew() { return this.mode == __C.FORM.EDIT_MODE_NEW },
    modeEdit() { return this.mode == __C.FORM.EDIT_MODE_EDIT },

    filteredItems() {
      
      if(this.SearchText) {
        
        if ((this.StartDate != this.im_FirstDate) || (this.FinishDate != this.im_FirstDate)) {
          this.pagination.page = 1
          this.setPagination(this.totalItems.filter(item => item.date >= this.StartDate && item.date <= this.FinishDate && item.title.toLowerCase().includes(this.SearchText.toLowerCase())))
          return this.items.filter(item => item.date >= this.StartDate && item.date <= this.FinishDate && item.title.toLowerCase().includes(this.SearchText.toLowerCase()))
        }

        if ((this.StartDate != this.min) || (this.FinishDate != this.max)) {
          this.pagination.page = 1
          this.setPagination(this.items.filter(item => item.title.toLowerCase().includes(this.SearchText.toLowerCase()))) 
          return this.items.filter(item => item.title.toLowerCase().includes(this.SearchText.toLowerCase()))
        }else {
          this.pagination.page = 1
          this.setPagination(this.totalItems.filter(item => item.title.toLowerCase().includes(this.SearchText.toLowerCase()))) 
          return this.items.filter(item => item.title.toLowerCase().includes(this.SearchText.toLowerCase()))
        }
      }
      
      if (this.StartDate && this.FinishDate) {
        if ( (this.StartDate != this.im_FirstDate) || (this.FinishDate != this.im_FinishDate)) this.pagination.page = 1
        this.setPagination(this.totalItems.filter(item => item.date >= this.StartDate && item.date <= this.FinishDate))
        return this.items.filter(item => item.date >= this.StartDate && item.date <= this.FinishDate)
      }  
      return this.items
    },
    setImage() {

      return this.selectedItem.dataUrl
    },
    chainResetable() {
      if ((this.im_FirstDate && this.im_FinishDate) && (this.StartDate != this.im_FirstDate || this.FinishDate != this.im_FinishDate)) return true
      else return false
    },
  },
  beforeCreate() {
    this.loading = true
  },
  created() {
    this.localId = this.id || 'j-modal-slide__' + this.safeId('')
    // this.setChild('')
    this.fileService = new FileService()
    this.fetchStreamService = new FetchStreamService()
    this.mfilter = this.filters.filters
    this.miFilter = this.filters.iFilters
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },    
  },
  mounted() {
    this.loading = false
    this.init()
    this.getFiles(__C.FILE.GALLERY_TYPE_PHOTO)
    this.fileService.getOptionsList().then(res => {
      Object.keys(res).forEach(k => {
        this.fsources[k] = [...res[k]]
        this.fotions[k] = [...res[k]]
      })
      this.fotions = { ...this.fotions }      
    })
    window.addEventListener('keydown', this.keyEvent)
  },
  // beforeDestroy() {
  //   window.addEventListener('keydown', this.keyEvent)
  // },
  // watch: {
  //   getPhoItems(v) {
  //     if (v.lv1 == undefined) {
  //       this.getFlag = false
  //     }else {
  //       this.getFlag = true
  //     }
  //     this.getFiles(__C.FILE.GALLERY_TYPE_PHOTO)
  //   },
  //   getClear(v) {
  //     if (v) {
  //       this.ClearDate()
  //     }
  //   }
  // },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'PhotoStatusSet'
    ]), 
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [ 'setChild' ]),

    init() {
      d3.select(`#${this.localId}`).select('.j-overlay__scrim').on('click', this.onOverlayClick)
    },
    onPageTo(p) {
      this.pagination.page = p
      // this.getFiles(__C.FILE.GALLERY_TYPE_PHOTO)
    },
    onLoadingStated() {
      this.processing = true
    },
    onLoadingEnded() {
      this.processing = false
    },
    onFilterChanged(name) {
      let idx = this.fnames.findIndex(n => n == name)
      
      let parentnames = this.fnames.slice(0, idx+1)
      let childnames = this.fnames.slice(idx+1)

      let flagAll = false

      if(idx+1 < this.fnames.length) {
        childnames.forEach(name__ => {
          this.fotions[name__] = this.fsources[name__].filter(s => {
            let wheresource = ''
            let whereselect = ''

            parentnames.forEach(n__ => {
              if(this.selectedItem[n__]) {
                if(this.selectedItem[n__] == 'ALL') {
                  // skip ---
                } else {
                  wheresource += `${s[n__]}`
                  whereselect += `${this.selectedItem[n__]}`
                }
              }
            })
            
            this.selectedItem[name__] = null
            this.selectedItem = { ...this.selectedItem }
               
            return s[name__] == 'ALL' || wheresource == whereselect
          })
        })
      }
    },
    onPrev() {
      if(this.selectedIndex <= 0) return

      this.setFadeout().then(() => {
        this.selectedIndex -= 1
        this.selectedItem = this.items[this.selectedIndex]
        this.getPhotoFile()
        this.setViewDimention()
      })
    },
    onNext() {
      if(this.selectedIndex >= this.items.length-1) return

      this.setFadeout().then(() => {
        this.selectedIndex += 1
        this.selectedItem = this.items[this.selectedIndex]
        this.getPhotoFile()
        this.setViewDimention()
      })
    },

    onAdd() {
      this.selectedItem = {}
      this.selectedItem.date = Moment().format('YYYY-MM-DD')
      this.mode = __C.FORM.EDIT_MODE_NEW
      this.maOpened = true
    },

    ClearDate() {
      this.StartDate = this.im_FirstDate
      this.FinishDate = this.im_FinishDate
      this.getFiles(__C.FILE.GALLERY_TYPE_PHOTO)
    },
    setPagination(list) {
      if (!list || list.length == 0) {
        this.items = []
        this.pagination.totalRows = 1
        return
      }
  
      if (list && list.length <= this.pagination.rowsPerPage) this.pagination.page = 1
      
      this.pagination.totalRows = list.length
      let currentPage = this.pagination.page 
      let start = (currentPage - 1)  * this.pagination.rowsPerPage,
          end = start + this.pagination.rowsPerPage
            
      this.items = list.slice(start,end)

    },
    Clear() {
      if (!this.modeNew) {
        let idx = this.selectedItem.idx ? this.selectedItem.idx : null  
        let file = this.selectedItem.files ? this.selectedItem.files : null
        let type = this.selectedItem.type ? this.selectedItem.type : null
        let date = this.selectedItem.date ? this.selectedItem.date : null
        this.selectedItem = {}
        this.selectedItem.idx = idx
        this.selectedItem.file = file
        this.selectedItem.type = type
        this.selectedItem.date = date
        this.selectedItem = { ...this.selectedItem }
        this.fotions = { ... this.fsources}
        this.getFiles(__C.FILE.GALLERY_TYPE_PHOTO)
      }else {
        this.selectedItem = {}
        this.selectedItem = { ...this.selectedItem }
        this.fotions = { ... this.fsources}
        this.getFiles(__C.FILE.GALLERY_TYPE_PHOTO)
      }
    },
    keyEvent (event) {

      if (this.view) {
       if (event.keyCode === 27) {
          this.onOverlayClick()
          this.close()
        }   
      }  
    },    
    onCloseFullSize() {
       this.toggleFullscreen()
       this.full = false
    },
    onDownload() {
      var iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      let params = new URLSearchParams()
      params.append('token', this.account.token)
      iframe.src = `${Url.download}/${this.selectedItem.fileId}?${params.toString()}`

      // this.fileService.download(this.selectedItem.fileId).then(res => {
      //   FileDownloader(res, this.selectedItem.name)
      //   // this.loading = false
      //   // this.view = false
      //   // this.close()
      //   // this.getFiles(__C.FILE.GALLERY_TYPE_PHOTO)
      // })
    },
    onEdit() {
      this.mode = __C.FORM.EDIT_MODE_EDIT
      this.maOpened = true

      // setTimeout(() => {
      //   if(this.$refs.fileUploader && this.selectedItem && this.selectedItem.dataUrl) {
      //     this.$refs.fileUploader.setThumbnail(this.selectedItem.dataUrl)
      //   }
      // }, 100)
    },

    onComplete(files) {
      this.selectedItem.files = files
      this.selectedItem.ext = files[0].ext
      this.selectedItem = { ...this.selectedItem }
    },
    onDelete() {

      if ( ['SYS_ADMIN', 'SVC_ADMIN'].includes(this.account.userPermit) || (this.selectedItem.createdBy == this.account.userName) ) {
        this.yes = () => {
          this.msgOpen = false
          this.fileService.delFileFromGallery(this.selectedItem.idx, this.selectedItem.fileId).then(() => {
            this.loading = false
            this.view = false
            this.close()
            this.StartDate = ''
            this.FinishDate = ''
            this.getFiles(__C.FILE.GALLERY_TYPE_PHOTO)
          })
          setTimeout(() => {
            this.PhotoStatusSet(true)
          }, 1500)
          this.yes = () => {}
        }

        this.msgInfo.type = "WARN"
        this.msgInfo.title = "Action Approval"
        this.msgInfo.titleDescription = "Important Notification"
        this.msgInfo.message = "Do you want to delete selected Item?"
        this.msgInfo.button = [true, false, true]
        this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
        this.msgInfo.buttonText[0] = "Delete"
        this.msgOpen = true
      } else if ( this.selectedItem.createdBy != this.account.userName ) {
          this.loading = false
          this.yes = () => {
          this.msgOpen = false
          setTimeout(() => {
            this.PhotoStatusSet(true)
          }, 1500)
          this.yes = () => {}
        }

        this.msgInfo.type = "WARN"
        this.msgInfo.title = "You do not have permission to delete."
        this.msgInfo.titleDescription = "Important Notification"
        this.msgInfo.button = [false, false, true]
        this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
        this.msgOpen = true
      }
    },
    onOverlayClick() {
      this.view = false
    },
    onSelect(item, i) {
      this.selectedIndex = i
      this.selectedItem = JSON.parse(JSON.stringify(item))
      this.view = true
      this.getPhotoFile()
      this.fileService.getEditInfo(this.selectedItem.idx).then(res => {
        this.selectedItem.lv1 = res[0].lv1 
        this.selectedItem.lv2 = res[0].lv2 
        this.selectedItem.lv3 = res[0].lv3
        this.selectedItem.area = res[0].area
        this.selectedItem.block = res[0].block   
      })
      setTimeout(() => { this.setViewDimention() } ,150)
    },
    onShowFullSize() {
      this.toggleFullscreen()
    
      let dimention = this.selectedItem.dimention.split(',')
      this.getPhotoFile('video_full')
      this.fullSizeInfo = {
        // src: `${__C.HOST_NAME}${this.selectedItem.path}`,
        width: dimention[0] || 0,
        height: dimention[1] || 0,
      }
      this.full = true
    },
    onThumbnail(data) {
      this.selectedItem.dataUrl = data.dataUrl
    },
    onUpload() {
      this.loading = true

      let params = new FormData()
      params.set('idx', this.modeNew ? 0 : this.selectedItem.idx)
      params.set('fileId', this.modeNew ? 0 : this.selectedItem.fileId)

      if(this.selectedItem.files) {
        this.selectedItem.files.forEach(f => { params.append("photos", f) })
        params.set('name', this.selectedItem.files[0].name)
        params.set('size', this.selectedItem.files[0].size)

        let dimention = (
          this.selectedItem.files[0].width ?
          `${this.selectedItem.files[0].width},${this.selectedItem.files[0].height}` :
          ''
        )
        params.set('dimention', dimention)
        params.set('ext', this.selectedItem.ext)
        params.set('dataUrl', this.selectedItem.dataUrl)
        params.set('added', 'Y')
      }

      // TODO: On multi file upload...

      // let img = document.querySelector(".dz-image img")
      // this.selectedItem.width = parseInt(img.naturalWidth)
      // this.selectedItem.height = parseInt(img.naturalHeight)

      params.set('type', __C.FILE.GALLERY_TYPE_PHOTO)
      params.set('title', this.selectedItem.title)
      params.set('lv1', this.selectedItem.lv1 ? this.selectedItem.lv1 : "ALL")
      params.set('lv2', this.selectedItem.lv2 ? this.selectedItem.lv2 : "ALL")
      params.set('lv3', this.selectedItem.lv3 ? this.selectedItem.lv3 : "ALL")
      params.set('bdlv1', this.selectedItem.bdlv1 ? this.selectedItem.bdlv1 : "ALL")
      params.set('bdlv2', this.selectedItem.bdlv2 ? this.selectedItem.bdlv2 : "ALL")
      params.set('bdlv3', this.selectedItem.bdlv3 ? this.selectedItem.bdlv3 : "ALL")      
      params.set('area', this.selectedItem.area ? this.selectedItem.area : "ALL")
      params.set('mblock', this.selectedItem.mblock ? this.selectedItem.mblock : "ALL")
      params.set('block', this.selectedItem.block ? this.selectedItem.block : "ALL")
      params.set('comment', '')
      params.set('date', this.selectedItem.date)
      params.set('createdBy', this.account.userName)
      params.set('description', '')
      params.set('module', this.selectedItem.module)
      params.set('category', this.account.category)

      if(this.modeNew) this.fileService.putFile2Gallery(params).then(res => {
        this.StartDate = ''
        this.FinishDate = ''
        this.getFiles(__C.FILE.GALLERY_TYPE_PHOTO)
        this.loading = false
        this.close()
        let data = {
          
        }
      }); else if (['SYS_ADMIN', 'SVC_ADMIN'].includes(this.account.userPermit) || (this.selectedItem.createdBy == this.account.userName) ) this.fileService.updFile2Gallery(params).then(res => {
        this.close()
        this.view = false
        this.loading = false

        if(res) {
          let i = this.items.findIndex(item => item.idx === res.idx)
          if(i >= 0) this.items[i] = res
          this.selectedItem = res
        }
      })
      else if ( this.selectedItem.createdBy != this.account.userName ) {
        this.loading = false
        this.yes = () => {
        this.msgOpen = false
        setTimeout(() => {
          this.PhotoStatusSet(true)
        }, 1500)
        this.yes = () => {}
      }

        this.msgInfo.type = "WARN"
        this.msgInfo.title = "You do not have permission to change."
        this.msgInfo.titleDescription = "Important Notification"
        this.msgInfo.button = [false, false, true]
        this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
        this.msgOpen = true
      }
      setTimeout(() => {
        this.PhotoStatusSet(true)
      }, 1500)
    },

    close() {
      this.mode = __C.FORM.EDIT_MODE_NEW
      this.maOpened = false
      this.selectedIndex = -1
      this.selectedItem = {}
      if(this.$refs.fileUploader) this.$refs.fileUploader.init()
    },

    getFiles(type) {
      this.loading = true

      let params = new FormData()
      
      params.set('type', type)
      params.set('lv1', this.mfilter.GWBS_LV1 ? this.mfilter.GWBS_LV1 : '' )
      params.set('lv2', this.mfilter.GWBS_LV2 ? this.mfilter.GWBS_LV2 : '' )
      params.set('lv3', this.mfilter.GWBS_LV3 ? this.mfilter.GWBS_LV3 : '' )
      params.set('area', this.mfilter.AREA ? this.mfilter.AREA : '' )
      params.set('block', this.mfilter.BLOCK ? this.mfilter.BLOCK : '' )
      params.set('start', this.StartDate ? this.StartDate : '')
      params.set('finish', this.FinishDate ? this.FinishDate : '')

      this.fileService.getFilesFromGallery(params).then(res => {
        
        if (res) {
          
            if (!this.getFlag && this.im_FirstDate && this.im_FinishDate) {
              this.StartDate = this.StartDate
              this.FinishDate = this.FinishDate
            } else {
              let im_min = res[0].date
              let im_max = res[0].date

              res.forEach(d => {
                if (d.date < im_min) im_min = d.date
                if (d.date > im_max) im_max = d.date 
              })
              this.min = im_min
              this.max = im_max
              if (!this.StartDate) this.im_FirstDate = im_min
              if (!this.FinishDate) this.im_FinishDate = im_max
              this.StartDate = im_min
              this.FinishDate = im_max
            }
        }
 
        this.totalItems = res ? res : []
        this.setPagination(res)

        this.loading = false
      })
    },
    postedDaysBefore(date) {
      if(!date) return ''
      let days = Moment().diff(Moment(date), 'days')
      return `${days} day${days > 1 ? 's' : ' '} ago`
    },
    setFadeout() {
      return new Promise(resolve => {
        d3.select(`#${this.localId}`).select('.photo').transition().duration(500).style('opacity', 0)
        setTimeout(() => { resolve(true) }, 500)
      })
    },
    getPhotoFile(target) {
      let data = {
        token : this.account.token,
        path: this.selectedItem.path
      }
      target = target ? target : 'photo_select'
      this.fetchStreamService.selectPhotoFile(data)
      .then(response => response.blob())
      .then(blob => {
        let blobURL = URL.createObjectURL(blob)
        const img = document.getElementById(`${target}`)
        img.src = blobURL

      })
    },
    setViewDimention() {
      
      let photoViewHeight = 870
      let dimention = this.selectedItem.dimention.split(',')

      if(!dimention || dimention.length === 0) return 
      
      let w_ = Number(dimention[0])
      let h_ = Number(dimention[1])
      let inW_ = 0
      let inH_ = 0

      if(h_ <= photoViewHeight) {
        inW_ = w_
        inH_ = h_
      } else {
        let ratio_ = photoViewHeight / h_
        inW_ = Math.round(w_ * ratio_)
        inH_ = photoViewHeight
      }
      d3.select(`#${this.localId}`)
      .select('.photo_viewer')
      .transition()
      .duration(750)
      .style('width', `${inW_}px`)
      .style('height', `${inH_}px`)

      d3.select(`#${this.localId}`).select('.photo').transition().duration(500).style('opacity', 1)
    },
    setThumb(id, dataUrl) {
      setTimeout(() => {
        document.querySelector(`#${id}`).style.backgroundImage = 'url("' + dataUrl + '")'
      })
      return ''
    },
    toggleFullscreen(el) {
      el = el || document.documentElement

      if (!document.fullscreenElement && !document.mozFullScreenElement &&
        !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen()
        } else if (el.msRequestFullscreen) {
          el.msRequestFullscreen()
        } else if (el.mozRequestFullScreen) {
          el.mozRequestFullScreen()
        } else if (el.webkitRequestFullscreen) {
          el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
      }
    }
  }
}
</script>

