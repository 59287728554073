import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    module: 'ME04'
  }),
  methods: {
    drawChart() {

      let mainGroup = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      

      let blockPhoto = mainGroup
      .append('g')
      .attr('transform', `translate(10, 50)`)

      const path = `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/AreaDashboard/HULL-FOR.png`

      blockPhoto
      .append('image')
      .attr('id', 'menuIcon')
      .attr('xlink:href', path)
      .on('mouseover', () => blockPhoto.style('opacity', 0.7))
      .on('mouseleave', () => blockPhoto.style('opacity', 1))
      .style('cursor', 'pointer')
      .on('click', () => {
        
        let request_ = {
          dataType: 'row',    
          action: {
            type: 'direct',
            target: 'url',
            path: `/service/construction/construction_dashboards/hull_dashboard/hull_for`
          },
          filters: {}
        }

        this.$emit('request-action', request_)
      })

      let module = mainGroup
        .append('g')
        .attr('transform', `translate(0, 195)`)

        // module
        // .append('rect')
        // .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 90).attr('height', 26)  
        // .attr('fill', '#E0E0DF')

        // module
        // .append('text')
        // .attr('x', 45).attr('y', 18)
        // .style('font-family','roboto').style('font-size',14).attr('text-anchor', 'middle').attr('fill', '#000')
        // .text('HULL-FOR')

      let item = this.DataItems[0]

      let data = mainGroup
        .append('g')
        .attr('transform', `translate(0, 225)`)

      data
        .append('rect')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 175).attr('height', 38)
        .attr('fill', '#A8E0F8')

        data
        .append('text')
        .attr('x', 10).attr('y', 15)
        .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#7A7F7D')
        .text('Plan')

        data
        .append('text')
        .attr('x', 10).attr('y', 30)
        .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'start').attr('fill', '#FFF')
        .text(item.PLAN + '%')

        data
        .append('text')
        .attr('x', 68).attr('y', 15)
        .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#7A7F7D')
        .text('Actual')

        data
        .append('text')
        .attr('x', 68).attr('y', 30)
        .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'start').attr('fill', '#FFF')
        .text(item.ACTUAL + '%')

        data
        .append('text')
        .attr('x', 125).attr('y', 15)
        .style('font-family','roboto').style('font-size',10).attr('text-anchor', 'start').attr('fill', '#7A7F7D')
        .text('Delta')

        data
        .append('text')
        .attr('x', 125).attr('y', 30)
        .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'start').attr('fill', '#FFF')
        .text(item.DELTA + '%')
      
    },
  }
}
