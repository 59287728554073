import * as d3 from 'd3'
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters, mapActions } from "vuex"
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import Movable from '@/mixins/movable.mixin'
import DashboardData from '@/primitives/_dataformDashboardBuilder'
import JLibChartComponents from '@/components/JLibChartComponents'

export default {
  name: "dashboard-mixin",
  components: {
    ...JLibChartComponents
  },
  mixins: [
    SafeIdMixin, 
    Movable
  ],
  props: {
    mode: { type: String, default: 'layout' },
    modeExport: Boolean,

    filters: { type: Object, default: () => ({}) },
    layout: { type: Object, default: () => ({}) },
    containers: { type: Array, default: () => ([]) },
    chartMetaSupply: { type: Object, default: () => ({}) },
    chartItems: { type: Array, default: () => ([]) },
    chartItemUpdated: { type: Object, default: () => ({}) },
    movable: Boolean,
    requestUpdated: { type: Object, default: () => ({}) }
  },
  data: () => ({
    selectedIndex: 0,
    // for the movable update
    containerAttrs: [],
    vBinds: {}
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, [
      'containerAdded', 'dashboardItem'
    ]),
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, [
      'chartData', 'chartItem', 'noteSqlResult'
    ]),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, [
      'database', 'canvas', 'title', 'legend', 'note',
      'axis', 'bar', 'compare', 'values', 'circle', 'guideline',
    ]),

    __C_() { return __C },

    styleWrapper() {
      if(!this.layout) return ''

      let wrapperStyle = []
      if(this.layout.spacing) wrapperStyle.push(`padding: ${this.layout.spacing}px;`)
      if(this.layout.background) wrapperStyle.push(`background-color: ${this.layout.background};`)
      if(wrapperStyle.length > 0) return wrapperStyle.join(' ')

      return ''
    },
    styleGrid() {
      let gridStyle = [
        `display: grid;`
      ]

      if(this.layout.padding) gridStyle.push(`grid-gap: ${this.layout.padding}px;`)
      if(gridStyle.length > 0) return gridStyle.join(' ')

      return ''
    },
  },
  watch: {
    chartItemUpdated: { 
      handler(val) { 
        if(!val) return
        this.onChartItemUpdated(val) 
      }, 
      deep: true 
    },
    chartMetaSupply: { 
      handler(val) { 
        if(!val) return
        this.onChartMetaSupplied(val) 
      }, 
      deep: true 
    },
    containers: { 
      handler(val) {
        if(!val) return
        
        // for 'movable' update
        this.containerAttrs = JSON.parse(JSON.stringify(val))
        setTimeout(() => {
          this.onContainerChanged()
        })
        setTimeout(() => { 
          this.setMovable() 
          this.complete()
        }, 500)
      }, 
      deep: true 
    },
    containerAdded(val) {
      if(val !== 0 && (!val || val < 0)) return
      this.onSelect(val)
    },
    layout: { 
      handler(val) { 
        // 
      }, 
      deep: true 
    },
    movable() { this.setMovable() },
    requestUpdated: {
      handler(val) { 
        if(!val || Object.keys(val) === 0) return
        this.onRequestUpdated(val)
      }, 
      deep: true 
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, [
      'setControllerRoleNo', 'setDashboardItem', 'setContainerIndex'
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, [
      'setChartData', 'setChartItem', 'setEmpty', 'setNoteSqlValue'
    ]),

    ...mapActions(__C.STORE_NAMESPACE.DASHBOARD_ADMIN, ['updDashboardItem']),

    CAT_CODE_CHART_COMPONENT(catCode, chartNo) {
      let componentName__ = {
        DNT: () => 'JLibChartDonut',
        PIE: () => 'JLibChartPie',
        AXS: () => 'JLibChartBarAxis',
        SLD: () => 'JLibChartBarSolid',
        PRO: () => 'JLibChartBarProgress',
        DLT: () => 'JLibChartBarDelta',
        HIS: () => 'JLibChartHistogram',
        RAD: () => 'JLibChartRadar',
        SKY: () => 'JLibChartSkyline',
        TSM: () => 'JLibChartTableSummary',
        ICC: () => 'Icicle',
        GAU: () => 'Gauge',
        GNT: () => 'Gantt',
        CUS: (chartNo) => this.getCustomComponent(chartNo),
        LIBSVGCC: (chartNo) => this.getCustomComponent(chartNo)
      }
      return componentName__[catCode](chartNo)
    },

    init() {
      this.selectedIndex = 0
    },
    complete() {
      // Temporal, reason why call this runction & redraw 
      // after the leaving current location must be found
      // , and fixed this call... when dom is not exist.
      // It may caused by changing the 'codePropagate'...
      // All the leaving process face to this same issue 
      // becuase most of the component watch the 'codePropagete'...
      if(!d3.select(`#${this.localId}`).node()) return

      this.$emit('complete', {
        dimention: this.layout.dimention
      })
      setTimeout(() => { this.$emit('resize', {
        width: d3.select(`#${this.localId}`).node().getBoundingClientRect().width,
        height: d3.select(`#${this.localId}`).node().getBoundingClientRect().height,
      }) }, 350)
    },

    onChartClicked(i, j, chartMetaNested) {
      this.setControllerRoleNo(chartMetaNested.roleNo)
    },
    onChartDblClick(catCode, chartNo) {
      this.$emit('request-modal', { catCode: catCode, chartNo: chartNo })
    },
    onChartMetaSupplied(chartMetaSupply) {
      let containers_ = JSON.parse(JSON.stringify(this.containers))
      let index_ = containers_[this.selectedIndex].items.findIndex(item => item.chartNo == chartMetaSupply.ChartNo)
      let isController = (chartMetaSupply.Role == __C.DASHBOARD.SVG_ROLE_CONTROLLER)

      if(!isController && index_ >= 0) {
        console.log('[Dashboard Builder: Chart existed] Selected chart already exists in the target container.');
        return
      }

      let chartW_ = d3.select(`#container__${this.selectedIndex}_${this.localId}`).node().getBoundingClientRect().width
      let props = DashboardData.item

      
      props.catCode   = chartMetaSupply.CatCode
      props.chartNo   = chartMetaSupply.ChartNo
      props.chartType = chartMetaSupply.ChartType
      props.name      = chartMetaSupply.Name
      props.role      = isController ? chartMetaSupply.Role : ''
      props.roleNo    = isController ? chartMetaSupply.RoleNo : ''
      // props.roleName  = ''   // roleName will be set from the container action or other place with 'name' like action.name.
      props.config    = ''
      props.action    = ''
      props.sql       = ''
      props.width     = chartMetaSupply.CanvasWidth
      props.height    = chartMetaSupply.CanvasHeight
      props.x         = isController ? (chartW_ - chartMetaSupply.CanvasWidth - 7) : 0
      props.y         = isController ? 7 : 0

      containers_[this.selectedIndex].items.push(props)
      this.setDashboardItem({ containerAttrs: JSON.parse(JSON.stringify(containers_)) })
    },
    onChartItemUpdated(updated) {
      this.containerAttrs.forEach((container, i) => {
        container.items.forEach((item_, j) => {
          if(item_.chartNo != updated.ChartNo) return

          this.containerAttrs[i].items[j] = {
            ...this.containerAttrs[i].items[j],
            catCode: updated.CatCode,
            chartNo: updated.ChartNo,
            chartType: updated.ChartType,
            name: updated.Name,
            width: updated.CanvasWidth,
            height: updated.CanvasHeight,
            x: item_.x,
            y: item_.y,
          }          
        })
      })
      this.containerAttrs = JSON.parse(JSON.stringify(this.containerAttrs))
      
      /* *** processed on the parent *** */
      // // just update the current displayed chart item, not to 
      // // update database, it has already updated.
      // this.chartItems.forEach((item_, i) => {
      //   if(item_.ChartNo == item.ChartNo) this.chartItems[i] = item
      // })
      /* *** */

      // update the database for current dashboard using updated 
      // chart item info...
      // It doesn't need to retrieve updated data, its data already
      // reflected at the above script.
      this.setDashboardItem({ containerAttrs: this.containerAttrs })
      // this.updDashboardItem()
    },
    onContainerChanged() {
      let vBinds__ = {}

      this.containers.forEach((container_, i) => {
        container_.items.forEach((metadata, j) => {
          if(!this.chartAvailable(metadata.chartNo)) {
            vBinds__[`${container_.containerNo}_${metadata.chartNo}`] = {}
            return
          }

          let props_ = this.setChartPropsData(metadata, i, j)
          // Not pass the iFilters. iFilters will be defined as every charts own.
          // props_.FilterValues = this.filters

          vBinds__[`${container_.containerNo}_${metadata.chartNo}`] = props_
        })
      })

      this.vBinds = vBinds__
    },
    onRemoveItem(i, j) {
      let containers_ = JSON.parse(JSON.stringify(this.containers))
      containers_[i].items[j] = null
      containers_[i].items = containers_[i].items.filter(v => !!v)

      this.setDashboardItem({ containerAttrs: containers_ })
      this.setControllerRoleNo('')
    },
    onRequestedAction(request, container, chartMeta, i) {
      this.$emit('request-action', {
        parent: {
          containerNo: container.containerNo,
          containerName: container.title,
          dimention: {
            width: container.width,
            height: container.height
          },
          roleNo: chartMeta.roleNo,
          action: chartMeta.action ? JSON.parse(chartMeta.action) : {}, // controller's role action
          location: i
        },
        sender: {
          ...request
        }
      })
    },
    onRequestUpdated(updates) {
      let containerNo_ = this.containers[updates.location].containerNo

      this.containers[updates.location].items.forEach((item, j) => {
        let updated_ = updates.chartItems.find(updated__ => updated__.ChartNo == item.chartNo)
        if(!updated_) return

        let vBindName_ = `${containerNo_}_${item.chartNo}`
        let vBinds_ = JSON.parse(JSON.stringify(this.vBinds[vBindName_]))

        if(vBinds_.Values && updated_.QAppliedMilestone == 'Q') vBinds_.Values.milestone = updated_.QResultMilestone
        if(vBinds_.Title && updated_.QResultTitleSql) vBinds_.Title.TitleSqlText = updated_.QResultTitleSql
        if(updated_.QApplied == 'Q') vBinds_.DataItems = updated_.QResultSummary
        
        this.vBinds[vBindName_] = { ...this.vBinds[vBindName_], ...vBinds_ }
      })
    },
    onSelect(i) {
      if(this.selectedIndex == i) return

      if(this.selectedIndex >= 0) d3.select(`._container_index__${this.selectedIndex}_${this.localId}`).attr('class', `_container_index__${this.selectedIndex}_${this.localId}`)
      d3.select(`._container_index__${i}_${this.localId}`).attr('class', `_container_index__${i}_${this.localId} selected`)

      this.selectedIndex = i
      this.setContainerIndex(i)
    },

    chartAvailable(chartNo) { return this.chartItems.find(chartData_ => chartData_.ChartNo == chartNo) ? true : false },
    parseJsonProps(chartMeta) {
      let chartData = this.chartItems.find(chartData_ => chartData_.ChartNo == chartMeta.chartNo)
      if(!chartData) return []

      return chartData.JsonProps ? JSON.parse(chartData.JsonProps) : []
    },
    extractDataItems(chartMeta) {
      let chartData = (
        chartMeta.role == __C.DASHBOARD.SVG_ROLE_CONTROLLER ?
        this.chartItems.find(chartData_ => chartData_.RoleNo == chartMeta.roleNo) :
        this.chartItems.find(chartData_ => chartData_.ChartNo == chartMeta.chartNo)
      )

      if(!chartData) return []

      let dataItem_ = (
        chartMeta.role == __C.DASHBOARD.SVG_ROLE_CONTROLLER ? (
          chartData.QResultSummary && chartData.QResultSummary.length > 0 ?
          chartData.QResultSummary : (
            chartData.JsonString ?
            JSON.parse(chartData.JsonString) :
            []
          )
        ) : (
          chartData.QApplied == __C.DATABASE.QUERY_APPLIED_JSON ? 
          (chartData.JsonString ? JSON.parse(chartData.JsonString) : []) :
          (chartData.QResultSummary ? chartData.QResultSummary : [])
        )
      )

      return dataItem_
    },
    extractData(chart, typeName) {
      let chartData = this.findChartItem(chart)
      if(!chartData) return {}

      // check the data one column attributes (attributes-in-a-column type)
      if(chartData[typeName]) return chartData[typeName]

      let keyNames = Object.keys(chartData).filter(k => k.toLowerCase().indexOf(typeName.toLowerCase()) === 0)
      let data = {}
      keyNames.forEach(k => { data[k] = chartData[k] })

      return data
    },
    extractSqlValues(chart) {
      let chartData = this.findChartItem(chart)
      if(!chartData) return {}

      let values_ = { 
        milestone: (
          chartData.MilestoneQApplied == 'J' ? 
          (chartData.MilestoneQJson ? JSON.parse(chartData.MilestoneQJson) : []) : 
          chartData.QResultMilestone
        ), 
        // note: '2019-11-01' // deprecated
      }
      // sql-result 'Note' is changed to Title Sql, and its result 
      // is stored in a specified column 'TitleSqlText' at the point
      // of getting the chart data.
      return values_
    },
    extractStyleAttrs(chart) {
      let chartData = this.findChartItem(chart)
      if(!chartData) return {}
      return chartData.StyleAttrs
    },
    extractValue(chart) {
      let chartData = this.findChartItem(chart)
      if(!chartData) return chart.catCode == __C.CHART.CAT_CODE_DONUT ? [] : {}

      if(chart.catCode == __C.CHART.CAT_CODE_DONUT) return chartData.Values

      let keyNames = Object.keys(chartData).filter(k => k.toLowerCase().indexOf('value') === 0)
      let data = {}
      keyNames.forEach(k => { data[k] = chartData[k] })

      return data
    },
    getCustomComponent(chartNo) {
      let chartData = this.chartItems.find(chartData_ => chartData_.ChartNo == chartNo)
      if(!chartData) return ''
      return chartData.UrlEntrance
    },
    getRoleProps(chartMetaNested) {
      return chartMetaNested.config ? JSON.parse(chartMetaNested.config) : {}
    },
    findData(chart, attrName, default_) {
      // Find the data matched that restructed at 
      // the application.service for...
      let chartData = this.findChartItem(chart)
      if(!chartData) return default_

      return chartData[attrName] || default_
    },
    findChartItem(chart) {
      return this.chartItems.find(chartData_ => chartData_.ChartNo == chart.chartNo)
    },
    jsonData(chart, attrName, default_) {
      // Find the data matched that restructed at 
      // the application.service for...
      let chartData = this.findChartItem(chart)
      if(!chartData) return default_

      return chartData[attrName] ? JSON.parse(chartData[attrName]) : default_
    },
    reset() {
      if(this.selectedIndex < 0) return

      let containers_ = JSON.parse(JSON.stringify(this.containers))
      containers_[this.selectedIndex].items = []

      this.setDashboardItem({ containerAttrs: containers_ })
    },
    selected(i) {
      if(this.selectedIndex < 0 && i === 0) {
        this.selectedIndex == 0
        return 'selected'
      }
      return this.selectedIndex === i ? 'selected' : ''
    },
    setMovable() {
      if(this.mode != __C.DASHBOARD.BUILDER_MODE_LAYOUT) {
        this.setMovableExceeded()
        return
      }

      this.containerAttrs.forEach((container, ci) => {
        container.items.forEach((item, j) => {
          d3.select(`#__chart_wrapper_${ci}_${j}___${this.localId}`)
          .datum(item)
          .call(
            d3.drag()
            .on('start', (d, i, a) => {
              if(!this.movable) return

              if(!d.x) d.x = 0
              if(!d.y) d.y = 0
              this.delta.x = d.x - d3.event.x
              this.delta.y = d.y - d3.event.y

              d3.selectAll('.chart').each((d, i, a) => { d3.select(a[i]).style('z-index', 0) })
              d3.selectAll('.controller').each((d, i, a) => { d3.select(a[i]).style('z-index', 3) })
              d3.select(a[i]).style('z-index', 1)
            })
            .on('drag', (d, i, a) => {
              if(!this.movable) return

              // let parent_ = d3.select(`#container__${ci}_${this.localId}`).node().getBoundingClientRect()
              // let width_ = d3.select(a[i]).select('svg').attr('width')
              // let height_ = d3.select(a[i]).select('svg').attr('height')
              // let currentX_ = d3.event.x + this.delta.x
              // let currentY_ = d3.event.y + this.delta.y
              // let limitX_ = -width_ + 50
              // let limitY_ = -height_ + 50

              // if(currentX_ < limitX_) var x_ = limitX_
              // else if(currentX_ > width_ - 50) x_ = width_ - 50
              // else x_ = currentX_

              // if(currentY_ < limitY_) var y_ = limitY_
              // else if(currentY_ > height_ - 50) y_ = height_ - 50
              // else y_ = currentY_

              let x_ = d3.event.x + this.delta.x
              let y_ = d3.event.y + this.delta.y

              d3.select(a[i]).attr('style', `left: ${x_}px; top: ${y_}px`)
              this.dragged.x = x_
              this.dragged.y = y_
              
              d3.selectAll('.chart').each((d, i, a) => { d3.select(a[i]).style('z-index', 0) })
              d3.selectAll('.controller').each((d, i, a) => { d3.select(a[i]).style('z-index', 3) })
              d3.select(a[i]).style('z-index', 1)
            })
            .on('end', (d) => {
              if(!this.movable) return

              d.x = d3.event.x + this.delta.x
              d.y = d3.event.y + this.delta.y

              this.$emit('moved', { x: d.x, y: d.y })
              this.setDashboardItem({ containerAttrs: JSON.parse(JSON.stringify(this.containerAttrs)) })
              // this.updDashboardItem()
            })
          )
          .call(this.setCursorMovable)
          .call(s => { s.on('dblclick', () => { this.onChartDblClick(item.catCode, item.chartNo) }) })
        })
      })
    },
    setMovableExceeded() {

      // For controller movable ?

      this.containerAttrs.forEach((container, i) => {
        container.items.forEach((item, j) => {
          d3.select(`#__chart_wrapper_${i}_${j}___${this.localId}`)
          .datum(item)
          .call(
            d3.drag()
            .on('start', (d) => {
              if(!this.movable) return

              if(!d.x) d.x = 0
              if(!d.y) d.y = 0
              this.delta.x = d.x - d3.event.x
              this.delta.y = d.y - d3.event.y
            })
            .on('drag', (d, i, a) => {
              if(!this.movable) return

              let width_ = d3.select(a[i]).select('svg').attr('width')
              let height_ = d3.select(a[i]).select('svg').attr('height')
              let currentX_ = d3.event.x + this.delta.x
              let currentY_ = d3.event.y + this.delta.y
              let limitX_ = container.width - (Number(width_) + 2)
              let limitY_ = container.height - (Number(height_) + 2)

              if(d.x >= 0 && d.y >= 0 && Number(width_) <= Number(container.width) && Number(height_) <= Number(container.height)) return

              if(currentX_ > 0 || (currentX_ < 0 && Number(width_) < Number(container.width)))  var x_ = 0
              else if(Number(width_) > Number(container.width) && currentX_ < limitX_) x_ = limitX_
              else if(Number(width_) < Number(container.width) && currentX_ < limitX_) x_ = limitX_
              else x_ = currentX_

              if(currentY_ > 0 || (currentY_ < 0 && Number(height_) < Number(container.height))) var y_ = 0
              else if(Number(height_) > Number(container.height) && currentY_ < limitY_) y_ = limitY_
              else y_ = currentY_

              d3.select(a[i]).attr('style', `left: ${x_}px; top: ${y_}px`)
              this.dragged.x = x_
              this.dragged.y = y_
            })
            .on('end', (d) => {
              if(!this.movable) return

              d.x = this.dragged.x
              d.y = this.dragged.y
            })
          )
        })
      })
    },
    // ### Set Styles ### ------------------------------------------------------------------------------
    classHeader(container) {
      let class_ = []

      if(container.fontStyle) class_.push(container.fontStyle)

      if(class_.length > 0) return class_.join(' ')
      return ''
    },
    classChart(chartMetaNested) {
      return chartMetaNested.role == __C.DASHBOARD.SVG_ROLE_CONTROLLER ? __C.DASHBOARD.SVG_ROLE_CONTROLLER : ''
    },
    setChartPropsData(metadata, i, j) {
      let ref_ = `__ref__chart_item_${i}_${j}___${this.localId}`
      // Why undefined ?, Why .$options undefined... ????? Check it...
      if(!this.$refs[ref_]) return null  
      if(typeof this.$refs[ref_][0] == 'undefined') return null
      if(typeof this.$refs[ref_][0].$options == 'undefined') return null 

      let props__ = {
        // For the Chart-Custom style component -------------------------------------------
        // :ModalDraw   :
        // :ModalTaps   :
        // ------------------------------------ -------------------------------------------
        FilterValues: () => this.filters                                                    , 
        ColumnProps : () => this.parseJsonProps(metadata)                                   , 
        DataItems   : () => this.extractDataItems(metadata)                                 , 
        Queries     : () => this.findData(metadata, __C.CHART.ATTR_NAME_Q_RESULT_EXTRAS ,{}), 
        Cutoff      : () => this.findData(metadata, __C.CHART.ATTR_NAME_CUTOFF ,'')         , 
        Axis        : () => this.extractData(metadata, __C.CHART.ATTR_NAME_AXIS)            , 
        AxisY       : () => this.extractData(metadata, __C.CHART.ATTR_NAME_AXIS)            , 
        Bar         : () => this.extractData(metadata, __C.CHART.ATTR_NAME_BAR)             , 
        Boxes       : () => this.extractData(metadata, __C.CHART.ATTR_NAME_BOXES)           , 
        Canvas      : () => this.extractData(metadata, __C.CHART.ATTR_NAME_CANVAS)          , 
        Circle      : () => this.extractData(metadata, __C.CHART.ATTR_NAME_CIRCLE)          , 
        Compare     : () => this.extractData(metadata, __C.CHART.ATTR_NAME_COMPARE)         , 
        Delta       : () => this.extractData(metadata, __C.CHART.ATTR_NAME_DELTA)           , 
        Guideline   : () => this.extractData(metadata, __C.CHART.ATTR_NAME_GUIDELINE)       , 
        Legends     : () => this.extractData(metadata, __C.CHART.ATTR_NAME_LEGENDS)         , 
        Line        : () => this.extractData(metadata, __C.CHART.ATTR_NAME_LINE)            , 
        Milestone   : () => this.extractData(metadata, __C.CHART.ATTR_NAME_MILESTONE)       , 
        Note        : () => this.extractData(metadata, __C.CHART.ATTR_NAME_NOTE)            , 
        Package     : () => this.extractData(metadata, __C.CHART.ATTR_NAME_PACKAGE)         , 
        Timeline    : () => this.extractData(metadata, __C.CHART.ATTR_NAME_TIMELINE)        , 
        Title       : () => this.extractData(metadata, __C.CHART.ATTR_NAME_TITLE)           , 
        Status      : () => this.extractStyleAttrs(metadata)                                , 
        Value       : () => this.extractValue(metadata)                                     , 
        Values      : () => this.extractSqlValues(metadata)                                 , 
        Radar       : () => this.findData(metadata, __C.CHART.ATTR_NAME_RADAR, {})          , 
        Text        : () => this.findData(metadata, __C.CHART.ATTR_NAME_TEXT, {})           , 
        TableSummary: () => this.findData(metadata, __C.CHART.ATTR_NAME_TABLE_SUMMARY, {})  , 
        Table       : () => this.findData(metadata, __C.CHART.ATTR_NAME_TABLE, {})          , 
        Styles      : () => this.findData(metadata, __C.CHART.ATTR_NAME_STYLE, [])          , 
        Columns     : () => this.findData(metadata, __C.CHART.ATTR_NAME_COLUMNS, [])        , 
        Header      : () => this.jsonData(metadata, __C.CHART.ATTR_NAME_HEADER, [])         , 
        Footer      : () => this.findData(metadata, __C.CHART.ATTR_NAME_FOOTER, [])         , 
        RoleProps   : () => this.getRoleProps(metadata)                                     , 
      }
      let propKeys__ = Object.keys(props__)
      let propData_ = {}

      this.$refs[ref_][0].$options._propKeys.forEach(k => {
        if(propKeys__.indexOf(k) < 0) return
        propData_[k] = props__[k]()
      })

      return propData_
    },
    styleContainer(container) {
      let style_ = []
      // if(container.column.start && container.column.span) style_.push(`grid-column: ${container.column.start} / span ${container.column.span};`)
      // if(container.row.start && container.row.span) style_.push(`grid-row: ${container.row.start} / span ${container.row.span};`)

      if(container.row.start && container.row.span && container.column.start && container.column.span) 
        style_.push(`grid-area: ${container.row.start} / ${container.column.start} / span ${container.row.span} / span ${container.column.span};`)

      if(container.width) style_.push(`width: ${container.width}px;`)
      if(container.height) style_.push(`height: ${container.height}px;`)
      if(container.borderWeight && container.borderColor) style_.push(`border: solid ${container.borderWeight}px ${container.borderColor};`)
      if(container.fillColor) style_.push(`background-color: ${container.fillColor};`)

      if(style_.length > 0) return style_.join(' ')

      return ''
    },
    styleHeader(container) {
      let style_ = []

      if(container.titleX) style_.push(`left: ${container.titleX}px;`)
      if(container.titleY) style_.push(`top: ${container.titleY}px;`)
      if(container.font) style_.push(`font-family: ${container.font};`)
      if(container.fontSize) style_.push(`font-size: ${container.fontSize}px;`)
      if(container.color) style_.push(`color: ${container.color};`)

      if(style_.length > 0) return style_.join(' ')
      return ''
    },
    styleChart(chartMetaNested) {
      let style_ = []
      
      style_.push(`top: ${chartMetaNested.y ? chartMetaNested.y : 0}px;`)
      style_.push(`left: ${chartMetaNested.x ? chartMetaNested.x : 0}px;`)

      return style_.join(' ')
    },
  }
}
