export default {
  methods: {
    drawProgress(scrName, hasxy=true) {
      this.svg.select(`#Progress_Chart`).remove()

      this.CANVAS = this.svg.append('svg').attr('id','Progress_Chart')
      this.CANVAS
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)

      let style = null
      
      this.BlockStatus_Coordinate.filter(c => (
        hasxy ? c.SCRNAME == scrName : c.LV4 == scrName
      )).forEach((d, sn) => {
        // Block Status
        let itemSet = []
        style = {
          x: hasxy ? d.x : 50,
          y: hasxy ? d.y : 565,
          headTextSize: 16,
          headTitle: d.NAME,
          headDescrEQ3D: d.NAMEDESC,
        }
        
        let localDataSet = this.Queries.SQL2.filter(f => (
          f.LV1 == (d.LV1 || f.LV1) &&
          f.LV2 == (d.LV2 || f.LV2) &&
          f.LV3 == (d.LV3 || f.LV3) &&
          f.LV4 == (d.LV4 || f.LV4)
        ))

        let levelFilter__ = {}
        Object.keys(d).forEach(k => {
          if(['NAME', 'SCRNAME', 'x', 'y'].includes(k)) return //이것들에 해당하는 key는 밖으로 빼기
          if(d[k]) levelFilter__[k] = d[k]
          // 나머지 key들의 값을 확인해서 있으면 값을 넣는다 
        })

        this.Status.forEach((d__, i) => {
          let tColName = this.Status[i]['colName']['total']
          let aColName = this.Status[i]['colName']['actual']

          itemSet.push({
            name: d__.title,
            total: localDataSet.reduce((a, b) => a + (b[tColName] || 0), 0),
            actual: localDataSet.reduce((a, b) => a + (b[aColName] || 0), 0),
            filter: {
              STATUS: d__.code,
              ...levelFilter__
            },
            bColor: this.Status[i].bColor,
            opacity: this.Status[i].opacity,
          })
        })
        // console.log(itemSet)
        this.Bar_Quantity_02(this.CANVAS, itemSet, style, sn) // <---- Chart Library
      })
    }
  }
}
