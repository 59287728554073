import Vue from 'vue'
import __C from '@/primitives/_constant_'
import { ApplicationService, ChartLibraryService, PageComponentService } from "@/services"

const _appService = new ApplicationService()
const _chartLibraryService = new ChartLibraryService()
const _pageComponentService = new PageComponentService()

export default {
  namespaced: true,
  state: {
    dashboardItem: {},
    containerIndex: 0,
    containerAdded: -1,
    controllerRoleNo: '',
  },
  mutations: {
    setDashboardItem(state, item) {
      if(Object.keys(item).length > 0) {
        Object.keys(item).forEach(k => {
          if(Object.keys(state.dashboardItem).includes(k)) state.dashboardItem[k] = item[k]
          else Vue.set(state.dashboardItem, k, item[k])
        })
      } else state.dashboardItem = {}
    },
    setEmpty(state) { 
      state.dashboardItem = {}
      state.containerIndex = 0
      state.containerAdded = -1
    },
    setContainerIndex(state, index) { state.containerIndex = index},
    setContainerAdded(state, index) { state.containerAdded = index},
    setControllerRoleNo(state, no) { state.controllerRoleNo = no}
  },
  getters: {
    general: (state) => ({
      name: state.dashboardItem.name || '',
      phaseCode: state.dashboardItem.phaseCode || '',
      groupCode: state.dashboardItem.groupCode || '',
      itemCode: state.dashboardItem.itemCode || '',
      subItemCode: state.dashboardItem.subItemCode || '',
      desc: state.dashboardItem.desc || '',
    }),
    database: (state) => ({
      dbName: state.dashboardItem.dbName || '',
      schemaName: state.dashboardItem.schemaName || '',
      tableName: state.dashboardItem.tableName || '',
      directAccess: state.dashboardItem.directAccess || '',
      version: state.dashboardItem.version || '',
      jsonProps: state.dashboardItem.jsonProps || '',
      qApplied: state.dashboardItem.qApplied || '',
      qDataset: state.dashboardItem.qDataset || '',
      qSp: state.dashboardItem.qSp || '',
    }),
  },
  actions: {
    getChartItems({ rootState }, attrs) {
      return new Promise((resolve) => {
        _chartLibraryService.getChartList(
          attrs.catCode, 
          'ALL',  // in DEV mode. [attrs.phaseCode] in production mode,
          'ALL',  // in DEV mode. [attrs.itemCode] in production mode,
          (item) => {
            resolve(item)
          }
        )
      })
    },
    getDashboardItem({ commit }, params) {
      return new Promise((resolve) => {

        // _pageComponentService.getDashboardItem(idx, (item) => {
        //   commit('setDashboardItem', item.dashboard)
        //   resolve(item.chartItems)
        // })

        let params_ = new URLSearchParams()
        params_.append('idx', params.idx)
        params_.append('catCode', params.catCode)
        params_.append('fValues', '')
        params_.append('reqType', __C.DASHBOARD.BUILDER_MODE_LAYOUT)

        _appService.getDashboard(params_, (resultSet) => {
          commit('setDashboardItem', resultSet.dashboard)
          resolve(resultSet.chartItems)
        })
      })
    },
    putDashboardItem({ state }) {
      return new Promise((resolve) => {
        console.log(state.dashboardItem);
        _pageComponentService.putDashboardItem(state.dashboardItem, () => {
            resolve(true)
          }
        )
      })
    },
    updDashboardItem({ state }) {
      return new Promise((resolve) => {
        _pageComponentService.updDashboardItem(state.dashboardItem, () => {
            resolve(true)
          }
        )
      })
    },
    delDashboardItem({ state, commit }) {
      return new Promise((resolve) => {
        console.log(state.dashboardItem)
        _pageComponentService.delDashboardItem(state.dashboardItem.idx, () => {
            commit('setEmpty')
            commit('setDashboardItem', {})
            resolve(true)
          }
        )
      })
    },
  }
}