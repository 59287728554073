import * as d3 from 'd3'
import { transition } from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Code() {
      // let self_ = this
      // CODE Definition
      // The code must be in the 'CODE' layer of AI.
      let codeLayer = this.innerSvg.select(`#NO`)
      let countCode = codeLayer.node().children.length // Get no of Code(text)

      for(var i=0; i < countCode; i++) {
        let equipNo = codeLayer.node().children[i].textContent
        let idx = this.dataSet.findIndex(d => d.NO == equipNo && d.PAU == this.PAU)

        if (idx < 0) continue

        // console.log(i, equipNo, this.dataSet[idx].NO)
        let target_ = this.getMatrixProps(d3.select(codeLayer.node().children[i]))
        this.dataSet[idx].idx = idx
        this.dataSet[idx].x = target_.cx
        this.dataSet[idx].y = target_.cy

        // Draw border of code
        // The border of code provides two types Rect and ellipse.
        let data = this.dataSet[idx]
        let equipmentNo = this.innerSvg
        .append('g')
        .attr('id', `cpi${this.PAU}_${idx}`)
        .on('mouseover', () => { 
          equipmentNo.style('cursor', 'pointer')
          d3.select(`#toolTip${this.PAU}_${idx}`).attr('opacity', 0).attr('visibility', 'display')
          .transition().duration(300).attr('opacity', 1)
        })
        .on('mouseout', () => { 
          equipmentNo.style('cursor', 'default')
          d3.select(`#toolTip${this.PAU}_${idx}`).attr('opacity', 0).attr('visibility', 'hidden')
        })
        .on('click', () => {
          // console.log(this.PAU,data.NO)
          this.$emit('request-action', {
            action: {
            },
            filters: {
              ...this.FilterValues,
              PAU: this.PAU,
              NO: data.NO
            }
          })
        })

        equipmentNo
        .append('circle')
        .attr('cx', data.x).attr('cy', data.y).attr('r', 7.5)
        .attr('stroke', data.sColor).attr('stroke-width', 1).attr('fill', data.bColor)

        equipmentNo
        .append('text')
        .attr('x', data.x).attr('y', data.y+ 0.5)
        .style('font-family', 'roboto').style('font-size', 10).attr('fill', data.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(data.NO)
      }

      

      
      this.dataSet.filter(f => f.PAU == this.PAU).forEach((d,i) => {
        if (countCode == 0) return

        let toolTip = this.innerSvg
        .append('g')
        .attr('id', `toolTip${this.PAU}_${d.idx}`)
        .attr('transform', `translate(${d.x}, ${d.y - 8})`)
        .attr('opacity', 0).attr('visibility', 'hidden')

        toolTip
          .append('path')
          .attr('id', `toolTipBox${this.PAU}_${d.idx}`)
          .attr('d', `${this.topTooltipPath(0,20, 5,3)}`)
          .attr('fill','#F4F5F5')
          .style('stroke-width', 10)
          .style('storke', '#000')
   
        toolTip
          .append('text')
          .attr('id', `toolTipText${this.PAU}_${d.idx}`)
          .attr('transform', `translate( 0, -11)`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#000').attr('text-anchor', 'middle')
          .text(`${d.DESCR}`)

          d3.select(`#toolTipBox${this.PAU}_${d.idx}`)
          .attr('d', `${this.topTooltipPath(this.getNodeElValue(`#toolTipText${this.PAU}_${d.idx}`, 'width') + (this.getNodeElValue(`#toolTipText${this.PAU}_${d.idx}`, 'width') * .15),20, 5,3)}`)
      })

      
    },

    


  }
}
           