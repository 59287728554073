import * as d3 from 'd3'
import __M from 'moment'

export default {
  data: () => ({
    dataSet: [],
    dataMinValue1: 0,
    dataMaxValue1: 0,
    dataAvgValue: 0,

    dataMinValue2: 0,
    dataMaxValue2: 0,

    axisYHeight: 350,
    axisXWidth: 1000,

    scaleY1: null,
    scaleY2: null,
    lineFunc: null,
    lineZero: null,

    dataXTimelineValues: '',
    dataXTimelineValuesMonth: '',

    tickValuesCumS: [],
    tickValuesWKS: [],
    lastDate: 0,
    lastValue: 0,
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.timelineFor = 'history'
      this.timelineKeyName = 'CUTOFF'

      this.timeline.baseX = this.Canvas.CanvasChartX
      this.timeline.baseY = this.Canvas.CanvasChartY + this.axisYHeight
      this.timeline.width = this.axisXWidth

      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.setTimelineEnv(this.dataSet)

      this.dataSet.forEach(d => {
        let date__ = this.getTimelineCDate(d[this.timelineKeyName])
        d.sDate = date__.sDate
        d.mDate = date__.mDate
        d.cDate = date__.eDate
        d.date = d3.timeParse('%Y-%m-%d')(d[this.timelineKeyName])
      })
      
      this.timeline.height = this.timeline.style.week.height
      if (this.timeline.style.month.display == 'Y') this.timeline.height += this.timeline.style.month.height
      if (this.timeline.style.year.display == 'Y') this.timeline.height += this.timeline.style.year.height

      // Get tickValuesCumS / tickValuesWKS..
      this.tickValuesCumS = this.dataSet.filter(d => typeof d.CUM_SUBMITTED != 'undefined').map(d => ({ date: d.mDate, cum: d.CUM_SUBMITTED })) //line
      this.tickValuesWKS  = this.dataSet.filter(d => typeof d.WK_SUBMITTED != 'undefined').map(d => ({ date: d.mDate, week: d.WK_SUBMITTED }))  //bar
      this.tickValuesCumA = this.dataSet.filter(d => typeof d.CUM_APPROVED != 'undefined').map(d => ({ date: d.mDate, cum: d.CUM_APPROVED }))   //line
      this.tickValuesWKA  = this.dataSet.filter(d => typeof d.WK_APPROVED != 'undefined').map(d => ({ date: d.mDate, week: d.WK_APPROVED }))    //bar
      
      // getMaxBound for cum / week
      this.dataMaxValue1 = this.getMaxBound(Math.max(...[...this.tickValuesCumS.map(d => d.cum), ...this.tickValuesCumA.map(d => d.cum)]))  //Max값- 두개의 line값 중에서 제일 큰값 구하기
      this.dataMaxValue2 = this.getMaxBound(Math.max(...[...this.tickValuesWKS.map(d => d.week), ...this.tickValuesWKA.map(d => d.week)]))  //Max값- 두개의 bar값 중에서 제일 큰값 구하기

      // Get Average
      this.tickValuesWKS.forEach(d => { this.dataAvgValue += Number(d.week)})
      if (this.dataAvgValue == 0) this.dataAvgValue = 0; else this.dataAvgValue = this.dataAvgValue/8

      this.lastWeek = this.tickValuesWKS[this.tickValuesWKS.length-1].week
      this.lastValue = this.tickValuesCumS[this.tickValuesCumS.length-1].cum
      this.lastDate = this.tickValuesCumS[this.tickValuesCumS.length-1].date

      this.scaleY1 = d3
      .scaleLinear()
      .domain([this.dataMinValue1, this.dataMaxValue1])
      .range([this.axisYHeight, 0])
      
      this.scaleY2 = d3
      .scaleLinear()
      .domain([this.dataMinValue2, this.dataMaxValue2])
      .range([this.axisYHeight, 0])

      this.lineZero = d3.line()
      .x(d => this.timeline.scale(d.date))
      .y(this.axisYHeight)
      
      this.lineFunc = d3.line()
      .x(d => this.timeline.scale(d.date))
      .y(d => this.scaleY1(d.cum))

      // Table Properties
      this.table.width = this.timeline.width
      this.table.height = this.table.lineHeight * this.table.headers.length
      this.table.cellWidth = (this.axisXWidth / this.timeline.week.length)-15

      if(!this.Queries.SQL2 || this.Queries.SQL2.length === 0) return
      // Summary Data
      this.style.value = [this.Queries.SQL2[0].TOTAL, this.Queries.SQL2[0].OPEN, this.Queries.SQL2[0].BP_REVIEW, this.Queries.SQL2[0].TFMC_DEV]
    },
  }
}