import * as d3 from 'd3'

export default {
  data: () => ({
    
  }),

  methods: {

    draw_Table(_y) {

      let s = this.style
      let d = this.dataSet

      let totalTextWidth = 0

      let Table = this.svg
      .append('g')
      .attr('id', `table-${this.localId}`)
      .attr('transform', `translate(1, ${_y + 6})`)

      Table
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', s.table.width)
      .attr('height', s.table.height)
      .attr('fill', s.table.xColor)
      .attr('opacity', s.table.opacity)
      .attr('rx', s.table.radius).attr('ry', s.table.radius)

      for(let i=0; i < d.count; i++) {
        Table
        .append('text')
        .attr('id', `column_${this.localId}_${i}`)
        .attr('x', 0).attr('y', s.table.header.y)
        .attr('fill', s.table.header.fColor).attr('font-size', s.table.header.fSize)
        .attr('text-anchor', 'end').attr('alignment-baseline', 'hanging') // hanging
        .attr('font-family', 'roboto')
        .text(d.text[i])

        d.x[i] = d3.select(`#column_${this.localId}_${i}`).node().getBBox().width
        totalTextWidth += d3.select(`#column_${this.localId}_${i}`).node().getBBox().width
      }

      let gapTotal = s.table.width - totalTextWidth
      let gaps = gapTotal / (d.count + 1)

      let cols_x = 0
      for(let i=0; i < d.count; i++) {
        cols_x += gaps + d.x[i]
        d3.select(`#column_${this.localId}_${i}`).attr('x', cols_x )

        Table
        .append('text')
        .attr('id', `value_${this.localId}_${i}`)
        .attr('x', cols_x).attr('y', s.table.value.y)
        .attr('fill', d.color[i]).attr('font-size', s.table.value.fSize)
        .attr('text-anchor', 'end').attr('alignment-baseline', 'hanging') // hanging
        .attr('font-family', 'roboto')
        .style('cursor', 'pointer')
        .text(() => {
          if(d.unit[i] == '$') {
            return d.value[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          } else if(d.unit[i] == '%') {
            return `${d.value[i]}%`
          } else {
            return d.value[i]
          }
        })
        .call(this.event_Link, i)
      }
    },

  } 
}