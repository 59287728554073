import { ApplicationService } from '../services'
import Loading from '@/mixins/loading.mixin'
import __C from '../primitives/_constant_'
import * as d3 from 'd3'
import { mapMutations } from 'vuex'
export default {
  mixins: [
    Loading
  ],
  data: () => ({
    appService: null
  }),
  created() {
    this.appService = new ApplicationService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setSelectItems'
    ]),
    // @@params ### ---------------------------
    // @type    pdf | png
    // @info    { name: String, width: Number, height: Number }
    // @action  Function(type):Promise | Object
    async createExport(type, info, action, items=null, idx=null) {
      // console.log('type:', type)
      // console.log('info:', info)

      // if(typeof action == 'string') console.log('action:', action)
      // else console.log(await action(type))

      this.loading = true
      if(typeof action == 'string') var action__ = action
      else action__ = await action(type)

      if(this.dimention && this.dimention.isreal) {
        info.width = this.dimention.width
        info.height = this.dimention.height
      }
      let obj = []
      let idx_ = null
      if (items) {
        items.forEach(d => {
          let af = JSON.stringify(d)
          let result = af 
          obj.push(result)
        })
    
        idx_ = await idx(type)
      }
      
      obj = obj.length == 0 ? null : obj


      let dpi_ = 96
      let width_ = type == 'pdf' ? Number((info.width / dpi_).toFixed(2)) : Number(info.width)
      let height_ = type == 'pdf' ? Number((info.height / dpi_).toFixed(2)) : info.height
      let scale_ = type == 'pdf' ? 1 : 1
  
      let params = new URLSearchParams()
      params.append('action', action__)
      params.append('create', type)
      params.append('width', type == 'pdf' ? (width_ < height_ ? width_ : height_) : width_)
      params.append('height', type == 'pdf' ? (width_ < height_ ? height_ : width_) : height_)
      params.append('scale', scale_)
      params.append('orientation', width_ > height_ ? 'Landscape' : 'Portrait')
      params.append('items', obj)
      params.append('idx', idx_)
   
      console.log(`[USER: #DEBUG] Export Action: ${action__}`)

      return new Promise((resolve) => {
        this.appService.createExport(params, (res) => {
          this.download(type, (info.name || '').replace(/\s/g, '_'), res)
          resolve(res)
          this.loading = false
        })
      })
    },
    download(type, filename, data) {
      if(!data || typeof data != 'string') {
        console.log('[USER: #DEBUG] Cannot download empty data.')
        return null
      }

      let binary_ = atob(data)
      let len_ = binary_.length
      let buffer_ = new ArrayBuffer(len_)
      let view_ = new Uint8Array(buffer_)

      for (var i = 0; i < len_; i++) {
        view_[i] = binary_.charCodeAt(i);
      }

      // create the blob object with content-type "application/pdf"
      let blob = new Blob([view_], { type: type == 'pdf' ? 'application/pdf' : 'image/png' });
      let url = URL.createObjectURL(blob);
      let link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${filename}.${type}`)
      document.body.appendChild(link)
      link.click()
      let defalut = ''
      this.setSelectItems(defalut)
    },
  }
}