import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    headers: [
      { name: 'Milestones', colname: 'KEY_MS' , tColor: '#454545', x: 50 , cx: 33 },
      { name: 'Plan'      , colname: 'PLAN'   , tColor: '#c5c5c5', x: 285, cx: 270 },
      { name: 'Forecast'  , colname: 'FORECAST', tColor: '#4FC3F7', x: 343, cx: 340 },
      { name: 'Actual'    , colname: 'ACTUAL' , tColor: '#43A047', x: 420, cx: 410 },
    ],
    tWidth: 470,

    scrollProps: {
      w: 470,
      h: 240,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#1e88e5',
        stroke: '#1e88e5',
      }
    }
  }),
  methods: {
    drawChart() {
      let table__ = this.svg
      .append('g')
      .attr('class', '__table')
      .attr('transform', `translate(5.5, 19.5)`)

      // Header Rows
      table__.append('rect').attr('x', 260).attr('y', 0).attr('width', 140).attr('height', 24).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)
      table__.append('line').attr('x1', 260).attr('y1', 0).attr('x2',this.tWidth).attr('y2', 0).attr('stroke-width', 0.3).attr('stroke', '#333')
      table__.append('line').attr('x1', 0).attr('y1', 24).attr('x2', this.tWidth).attr('y2', 24).attr('stroke-width', 0.3).attr('stroke', '#333')

      // Header Text
      table__
      .append('g')
      .attr('class', '__header_text')
      .attr('transform', `translate(0, 13)`)
      .selectAll('text')
      .data(this.headers)
      .enter()
      .append('text')
      .attr('x', d => d.x)
      .attr('y', 0)
      .style('font-family','roboto')
      .style('font-size', 10)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .attr('fill', '#757575')
      .text(d => d.name)

      let tablebody = table__
      .append('g')
      .attr('class', '__table_body')
      .attr('transform', `translate(0, 24)`)

      // Set Scroll
      this.setScroll(tablebody, this.scrollProps)

      this.DataItems.forEach((item, i) => {
        let rowdata = tablebody
        .append('g')
        .attr('class', '__row_data')
        .attr('transform', `translate(0, ${this.rowHeight * i})`)

        if(i % 2 == 1)
          rowdata.append('rect').attr('width', this.tWidth).attr('height', this.rowHeight).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)

        this.headers.forEach((h, j) => {
          if(j === 0) {
            rowdata
            .append('text')
            .attr('transform', `translate(12, 9.5)`)
            .style('font-family','roboto')
            .style('font-size', 10)
            .attr('fill', '#c5c5c5')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .text(i + 1)
            
            let milestone = rowdata
            .append('g')
            .attr('clip-path', 'url(#activity_overflow_none_milestone)')
            .append('text')
            .attr('transform', `translate(${h.cx}, 9.5)`)
            .style('font-family','roboto')
            .style('font-size',10)
            .attr('fill', h.tColor)
            .attr('alignment-baseline', 'middle')
            .text(item[h.colname] || '')
            
            // add ellipsis
            let rect = milestone.node().getBoundingClientRect()
            if (rect.width > 210) {
              rowdata.append('text')
              .attr('transform', `translate(240, 9.5)`)
              .style('font-family','roboto')
              .style('font-size',10)
              .attr('fill', h.tColor)
              .attr('alignment-baseline', 'middle')
              .text('...')
            }


          } else 
            rowdata
            .append('text')
            .attr('transform', `translate(${h.cx}, 9.5)`)
            .style('font-family','roboto')
            .style('font-size',10)
            .attr('fill', h.tColor)
            .attr('alignment-baseline', 'middle')
            .text(item[h.colname] || '')
        })
      })
    }, 
  }
}
