import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'


// Locally declared common Data-Props & SVG Drawing Support
import lmx_Watching           from './Watching' 
import lmx_LocalValues         from './set_LocalValues'
import lmx_draw_Table         from './draw_Table'
import lmx_Events             from './Events'


export default {
  
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_Watching,
    lmx_LocalValues,
    lmx_draw_Table,
    lmx_Events,
  ],

}
