import Vue from 'vue'
import Router from 'vue-router'

import __M from 'moment'
import __C from '@/primitives/_constant_'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  // base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  routes: [
    {
      path: '/',
      redirect: '/service',
      name: 'Jin Solution',
      component: () => import('@/containers/layout/index'),
      children: [
        {
          path: 'admin',
          redirect: 'admin/accesshist_OverView',
          name: 'Service Admin',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          // beforeEnter(from, to, next) {
          //   const account = JSON.parse(localStorage.getItem('ACCOUNT'))
          //   if (!!account) return next({ path: '/', replace: true })
          //   next()
          // },
          children: [
            {
              path: 'acctmanager',
              name: 'Account Manager',
              component: () => import('@/views/system/SvcAccessUserAccount')
            },            
            {
              path: 'accesshist_OverView',
              name: 'Access History',
              component: () => import('@/views/system/SysenvAccessHistory')
            },                       
          ]
        },
        {
          path: 'sysenv',
          redirect: '/sysenv/config',
          name: 'System Management',
          beforeEnter(from, to, next) {
            const account = JSON.parse(localStorage.getItem('ACCOUNT'))
            const fullAccess = account.userPermit.split(',').some(r => __C.FULL_ACCESS_PERMISSION.indexOf(r) >= 0)
            if (!fullAccess) return next({ path: '/service', replace: true })
            next()
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'config',
              redirect: '/sysenv/config/code',
              name: 'Configuration',
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: 'code',
                  redirect: '/sysenv/config/code/group',
                  name: 'System Code',
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: 'group',
                      name: 'System Code Group',
                      component: () => import('@/views/system/SysenvCodeGroup')
                    },
                    {
                      path: 'index',
                      name: 'System Code Index',
                      component: () => import('@/views/system/SysenvCodeIndex')
                    }
                  ]
                },
                {
                  path: 'nav',
                  redirect: '/sysenv/config/nav/phase',
                  name: 'Navigation',
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: 'phase',
                      name: 'Menu Phase',
                      component: () => import('@/views/system/SysenvMenuPhase')
                    },
                    {
                      path: 'group',
                      name: 'Menu Group',
                      component: () => import('@/views/system/SysenvMenuGroup')
                    },
                    {
                      path: 'item',
                      name: 'Menu Item',
                      component: () => import('@/views/system/SysenvMenuItem')
                    },
                    {
                      path: 'subitem',
                      name: 'Sub-Item',
                      component: () => import('@/views/system/SysenvMenuSubItem')
                    }
                  ]
                },
                {
                  path: 'account',
                  redirect: '/sysenv/config/account/user',
                  name: 'Account Management',
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: 'user',
                      name: 'Member(User)',
                      component: () => import('@/views/system/SvccontrolUserAccount')
                    }
                  ]
                },
                {
                  path: 'modcon',
                  redirect: '/sysenv/config/modcon/wbtn',
                  name: 'Modifiability Controls',
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: 'wbtn',
                      name: 'Writable Button',
                      component: () => import('@/views/system/SysenvWritableButton')
                    },
                    {
                      path: 'intdg',
                      name: 'One Page Datagrid',
                      component: () => import('@/views/system/SysenvPageDatatable')
                    }
                  ]
                },
                {
                  path: 'page',
                  redirect: '/sysenv/config/page/dashboard',
                  name: 'Page Components',
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: 'dashboard',
                      name: 'Dashboard',
                      component: () => import('@/views/system/SysenvPageDashboard')
                    },
                    {
                      path: 'intdg',
                      name: 'Intended Datagrid',
                      component: () => import('@/views/system/SysenvPageDatatable')
                    },
                    {
                      path: 'svgpages',
                      name: 'Page-SVG',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                  ]
                },
                {
                  path: 'chartlib',
                  redirect: '/sysenv/config/chartlib/donut',
                  name: 'Chart Library',
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: 'donut',
                      name: 'Donut Chart',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'pie',
                      name: 'Pie Chart',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'bar',
                      name: 'Bar Chart',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'barsolid',
                      name: 'Solid Bar Chart',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'barprogs',
                      name: 'Progress Bar Chart',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'bardelta',
                      name: 'Delta Bar',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'histogram',
                      name: 'History Chart',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'radar',
                      name: 'Radar Chart',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'skyline',
                      name: 'Skyline Chart',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'tsummary',
                      name: 'Summary Table',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'tfloat',
                      name: 'Floating Table',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'custom',
                      name: 'Customized',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                  ]
                },
                {
                  path: 'complibsvg',
                  redirect: '/sysenv/config/complibsvg/svgtabs',
                  name: 'Component Library',
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: 'svgtabs',
                      name: 'Tabs',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'chartcntl',
                      name: 'SVG Chart Controller',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'svgnavs',
                      name: 'SVG Navigation',
                      component: () => import('@/views/system/SysenvChartlibrary')
                    },
                    {
                      path: 'svgprops',
                      name: 'SVG Control',
                      component: () => import('@/views/system/SysenvSVGControl')
                    },
                  ]
                },
                {
                  path: 'lib',
                  redirect: '/sysenv/config/lib/icons',
                  name: 'Common Library',
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: 'icons',
                      name: 'Icons',
                      component: () => import('@/views/system/SysenvIcons')
                    },
                    {
                      path: 'pdfbg',
                      name: 'PDF Background',
                      component: () => import('@/views/system/SysenvMediaManager')
                    },
                    {
                      path: 'colorscheme',
                      name: 'Color Scheme',
                      component: () => import('@/views/system/SysenvColorSchemes')
                    },
                    {
                      path: 'charts',
                      name: 'Charts',
                      component: () => import('@/views/system/SysenvCharts')
                    },
                  ]
                },
                {
                  path: 'database',
                  redirect: '/sysenv/config/database/queries',
                  name: 'Database Support',
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: 'queries',
                      name: 'SQL Queries',
                      component: () => import('@/views/system/SysenvDbSqlQueries')
                    },
                  ]
                },
                {
                  path: 'dbupdate',
                  redirect: '/sysenv/config/dbupdate/update',
                  name: 'Database Update',
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: 'update',
                      name: 'DB Update',
                      component: () => import('@/views/system/SysenvDbUpdate')
                    },
                  ]
                },
                {
                  path: 'filebrowser',
                  redirect: '/sysenv/config/filebrowser/files',
                  name: 'File Manager',
                  component: {
                    render(c) {
                      return c('router-view')
                    }
                  },
                  children: [
                    {
                      path: 'files',
                      name: 'Files',
                      component: () => import('@/views/system/SysenvFileManager')
                    },
                    {
                      path: 'files/:path',
                      name: 'Files',
                      component: () => import('@/views/system/SysenvFileManager')
                    },
                  ]
                },
                {
                  path: 'acchistory/:by',
                  name: 'Access History',
                  component: () => import('@/views/system/SysenvAccessHistory')
                },
                {
                  path: 'workorder',
                  name: 'Workorder Status',
                  component: () => import('@/views/system/SysenvWorkorderStatus')
                },
                {
                  path: 'notice',
                  name: 'Update Notice',
                  component: () => import('@/views/system/SysenvNotice')
                },
              ]
            }
          ]
        },
        {
          path: 'signin',
          name: 'SignIn',
          component: () => import('@/views/SignIn'),
          beforeEnter(from, to, next) {
            const account = JSON.parse(localStorage.getItem('ACCOUNT'))
            if (!!account) return next({ path: '/', replace: true })
            next()
          }
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/views/register'),
        },
        {
          path: 'Forgot',
          name: 'Forgot',
          component: () => import('@/views/Forgot'),
        },
        {
          path: 'InvalidAccess',
          name: 'InvalidAccess',
          component: () => import('@/views/Invalid'),
        },
        {
          path: 'UnderConstrucion',
          name: 'UnderConstrucion',
          component: () => import('@/views/public/UnderConstruction'),
        },
        {
          // {
          //   type: pdf | png | svg,
          //   name: dashboard | container,
          //   idx: Number,
          //   refno: container no,
          // }
          path: 'export/:token/:action/:items',
          name: 'Export',
          component: () => import('@/views/public/Export'),
        },
        {
          path: 'pub_3d_viewer/:token/:code/:props',
          name: '3D Viewer',
          component: () => import('@/views/public/3DViewerTBD'),
        }
      ]
    },
    {
      path: '*',
      // redirect: '/',
      beforeEnter(from, to, next) {

        // To Do
        // 1. redirect if from path is not expected. Wrong Path or bad request.
        // 2. keep current path when th f5 refreshing is expected.
        // console.log('from', from, 'to', to)

        next()
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  let timeout = localStorage.getItem(__C.LOCAL_STORAGE_NAME.TIMEOUT)
  let account = JSON.parse(localStorage.getItem(__C.LOCAL_STORAGE_NAME.ACCOUNT))
  // let authRequired = !__C.PUBLIC_URL.includes(to.path.split('/')[1])
  let authRequired = true
  let pathEl = to.path.split('/')[1]
  __C.PUBLIC_URL.forEach(url => { 
    if(pathEl == url.name) {
      authRequired = false
    }
  })

  if (account && (!timeout || __M().diff(__M(timeout, 'YYYY-MM-DD HH:mm:ss'), 'minutes') > __C.CONFIG.TIMEOUT)) {
    localStorage.removeItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)
    window.location.href = `${__C.HOST_NAME}`
    // return next({ path: '/', replace: true })
  }
  if (authRequired && !account) {
    return next({ path: '/signin', replace: true })
  }
  next()
})

export default router
