/* 
  ### SVG Controller for the Page Customized ### 
  Unregulated page components.
*/

// Common
import Comm_TabModalContainer from '../01_ModalContainer'

// Planining
import Plan_Milestones from '../../09_Projects/TCO/03_Planning/Milestones'

// Engineering

// Procurement
import Proc_Equipment3D from '../../09_Projects/TCO/05_Procurement/Equipment'

// Construction
import Const_BlockDivision from '../../09_Projects/TCO/07_Construction/BlockDivision'
import Const_Module from '../../09_Projects/TCO/07_Construction/Module'

// System Completion
import SC_Module from '../../09_Projects/TCO/08_SystemCompletion/Module'




// ------------------------------------------------------------------------------------------------------------------------------------------------

export default {
  Comm_TabModalContainer,

  // Planning
  Plan_Milestones,

  // Procurement
  Proc_Equipment3D,

  // Construction
  Const_BlockDivision,
  Const_Module,

  // System Completion
  SC_Module
}
