import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global L450H
// Global Data & Defs

export default {
  methods: {
    Activate_Deckbox() {

      this.CurrentArea = 'Deckbox'

      this.svgModeling = this.svg.append('svg')
      this.svgModeling
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 0)



       // Overall_Hull_Group
       let Overall_Hull_Group = this.svgModeling
       .append('g')
       .attr('id', 'Overall_Deckbox_Group')
       .attr('transform', `translate(0,0) scale(1)` )
       .attr('opacity', 1)
       .style('cursor', 'pointer')
       .call(this.Goto_Deckbox_ModuleDivision) // --------
       
       // Draw the Module No. & Single Block No.
       this.draw_ModuleCode(this.svgModeling)
 
       // Draw the Block image & Codes
       this.MODULE.Deckbox.forEach(mod => {

        // ### Module
        let ModuleGroup = Overall_Hull_Group
        .append('g')
        .attr('id', `${mod.module}`)
        .attr('transform', `translate(${mod.x1}, ${mod.y1}) scale(${1})`)
        .attr('opacity', 1)
        .call(s => this.Goto_ModuleSummary(s, mod)) // ------
        
        // ### Module Blocks
        this.BLOCKS.Deckbox.filter(f => f.module == mod.module).forEach((d,i) => {
          ModuleGroup
          .append('image')
          .attr('id', `${d.blk}`)
          .attr('opacity', 1)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/Images/Deckbox/${d.blk}.png`)
          .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.45})`)
          .call(s => this.Goto_Unit_Block(s, d))
        })
        this.draw_BlockCode(ModuleGroup, mod.module)
       })

       this.svgModeling
      .transition()
      .delay(150)
      .duration(1000)
      .attr('width', Math.round(this.Canvas.CanvasWidth * 1.3))
      .attr('height', Math.round(this.Canvas.CanvasHeight * 1.3))
      .attr('x', -300).attr('y', -200)
      .attr('opacity', 1)



      // Module_MenuSet
      let Module_Menu = this.svg
      .append('g')
      .attr('id', 'Module_MenuSet')
      .attr('transform', `translate(380, 480)`).style('opacity', 0).style('visibility', 'hidden')

      Module_Menu
      .append('text')
      .attr('id', `Mod_Name`)
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto').style('font-size', 16).style('font-weight', 700).style('fill', '#000').attr('text-anchor', 'start').text('')

      Module_Menu
      .append('text')
      .attr('id', `Mod_Descr`)
      .attr('transform', `translate(0, 15)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#bcbcbc').attr('text-anchor', 'start').text('')

      // button Menu_BlockDivision
      let Menu_BlockDivision = Module_Menu
      .append('g')
      .attr('id', 'Menu_BlockDivision')
      .attr('transform', `translate(20, 22)`)
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .call(s => this.Goto_BlockDivision(s)) // --------------------------------------------------------------> Goto Goto_BlockDivision
      

      Menu_BlockDivision
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', 85).style('height', 16).style('fill', '#44A9DF')

      Menu_BlockDivision
      .append('text')
      .attr('transform', `translate(42, 12)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').attr('text-anchor', 'middle').text('Block Division')
    },
  }
}
           