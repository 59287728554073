<template>
  <div 
    class="j_dashboard_layout_builder__"
    :id='localId' 
    :style="styleWrapper"
  >
    <div class="contents_block_">
      <article 
        class="j_dashboard_layout_grid"
        :style="styleGrid"
      >
        <section 
          v-for="(container, i) in containers" 
          class="_dashboard_section_container__" 
          :id="`container__${i}_${localId}`"
          :key="`container__${i}_${localId}`"
          :style="styleContainer(container)"
          @click="onSelect(i)"
        >
          <div class="header" :class="classHeader(container)" :style="styleHeader(container)">{{ container.title }}</div>

          <div 
            v-if="mode == __C_.DASHBOARD.BUILDER_MODE_LAYOUT" 
            :class="`_container_index__${i}_${localId} ${selected(i)}`"
          >
            <header>
              <div class='title'>
                <div class='_index__'><h1>{{i + 1}}</h1></div>
                <div class='container_dimention'>{{ container.width }} x {{ container.height }}</div>
              </div>
              <div class='item_list'>
                <div 
                  v-for="(chartMetaNested, j) in container.items" :key="`chart_info__${i}_${j}`"
                  class="item_info"
                >
                  <span :class="{ removed: !chartAvailable(chartMetaNested.chartNo) }">{{ j+1 }}. {{ chartMetaNested.name }}</span>
                  <span v-if="!chartAvailable(chartMetaNested.chartNo)" class="removed_notice">(removed)</span>
                  <a v-if="!chartAvailable(chartMetaNested.chartNo)" class="btn_remove_text" @click="onRemoveItem(i, j)">
                    <v-icon>mdi-window-close</v-icon>
                  </a>

                </div>
              </div>
            </header>
            <div class='spacer'></div>
          </div>

          <div
            v-for="(chartMetaNested, j) in container.items" 
            class="chart" 
            :class="classChart(chartMetaNested)"
            :id="`__chart_wrapper_${i}_${j}___${localId}`"
            :style="styleChart(chartMetaNested, i)" 
            :key="`chart__${i}_${j}`"
            @click="onChartClicked(i, j, chartMetaNested)"
          >
            <a v-if="mode == __C_.DASHBOARD.BUILDER_MODE_LAYOUT" class="btn_remove" @click="onRemoveItem(i, j)">
              <v-icon>mdi-window-close</v-icon>
            </a>

            <component
              v-if="chartAvailable(chartMetaNested.chartNo)"
              v-bind="vBinds[`${container.containerNo}_${chartMetaNested.chartNo}`]"

              :mode-export="modeExport"
              
              :ref="`__ref__chart_item_${i}_${j}___${localId}`"
              :id="`__chart_item_${i}_${j}___${localId}`"
              :is="CAT_CODE_CHART_COMPONENT(chartMetaNested.catCode, chartMetaNested.chartNo)"
              :movable="false"
              :ChartType="chartMetaNested.chartType"

              @request-action="(request) => { onRequestedAction(request, container, chartMetaNested, i) }"
            />
            
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>
import '@/assets/stylus/ui/component/_jDashboardLayoutBuilder.styl'
import DashboardMixin from '@/mixins/dashboard.builder.mixin'

export default {
  name: "j-dashboard-layout-builder",
  mixins: [
    DashboardMixin
  ],
  created() {
    this.localId = this.id || 'j-dashboard-layout-builder__' + this.safeId('')
  },
}
</script>
