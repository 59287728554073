/* 
  ### Chart Controllers ### 
  Predefined component's behaviors for the Filter & Action
*/
//-1_Common
import CHV2_MiniTable_A_V0        from '../10_China1/Common/Mini_Tables/Mini_Table_A_V0/index.vue'
import CHV2_MiniTable_B_V0        from '../10_China1/Common/Mini_Tables/Mini_Table_B_V0/index.vue'
import CHV2_MiniTable_C_V0        from '../10_China1/Common/Mini_Tables/Mini_Table_C_V0/index.vue'
import CHV2_MiniTable_D_V0        from '../10_China1/Common/Mini_Tables/Mini_Table_D_V0/index.vue'
import CHV2_MiniTable_D_V1        from '../10_China1/Common/Mini_Tables/Mini_Table_D_V1/index.vue'

import CHV2_ReusableTable_v1      from '../10_China1/Common/Data_Tables/ReusableTable_v1/index.vue'
//00_Home
//01.Project
import CHV2_InformationGuide       from '../10_China1/Project/Information_Guide/index.vue'
import CHV2_InformationGuideV2     from '../10_China1/Project/InformationGuide_v2/index.vue'
import CHV2_Construction_Photo     from '../10_China1/Project/Construction_Photo/index.vue'
//02_Engineering
import CHV2_AreaSummary            from '../10_China1/Engineering/Area_Summary/Progress/index.vue'
//03.Planning
import CHV2_Level1ScheduleNew      from '../10_China1/Planning/Level1ScheduleNew/index.vue'
//04_Construction
//05_Procurement
//06_QualityControl
//08.SystemCompletion
import CHV2_UtilityCommissioning   from '../10_China1/SystemCompletion/UtilityCommissioning/index.vue'
import CHV2_UtilityCommissioningV2 from '../10_China1/SystemCompletion/UtilityCommissioningV2/index.vue'
import CHV2_CsuSequence_P1         from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P1/index.vue'
import CHV2_CsuSequence_P2_GPPE    from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_GPPE/index.vue'
import CHV2_CsuSequence_P2_LDPE    from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_LDPE/index.vue'
import CHV2_CsuSequence_P2_PP      from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P2_PP/index.vue'
import CHV2_CsuSequence_P3         from '../10_China1/SystemCompletion/Startup_Sequence/CSU_P3/index.vue'
//09.Temp

export default {
  // -1_Common
  CHV2_MiniTable_A_V0,
  CHV2_MiniTable_B_V0,
  CHV2_MiniTable_C_V0,
  CHV2_MiniTable_D_V0,
  CHV2_MiniTable_D_V1,
  CHV2_ReusableTable_v1,
  //00_Home
  //01.Project
  CHV2_InformationGuide,
  CHV2_InformationGuideV2,
  CHV2_Construction_Photo,
  //02_Engineering
  CHV2_AreaSummary,
  //03.Planning
  CHV2_Level1ScheduleNew,
  //04_Construction
  //05_Procurement
  //06_QualityControl
  //08.SystemCompletion
  CHV2_UtilityCommissioning,
  CHV2_UtilityCommissioningV2,
  CHV2_CsuSequence_P1,
  CHV2_CsuSequence_P2_GPPE,
  CHV2_CsuSequence_P2_LDPE,
  CHV2_CsuSequence_P2_PP,
  CHV2_CsuSequence_P3,
  //09.Temp
}
