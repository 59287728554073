import Defs from '../../../../../../includes/primitives/Color_Defs'
import Data from '../../../../../../includes/primitives/Color_Data'

import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],

    Status: [
      { status: null,       bColor: '#ffffff', tColor: '#bcbcbc'},
      { status: 'Cutting',  bColor: '#9AD0E9', tColor: '#44A9DF'},
      { status: 'Assembly', bColor: '#4492CF', tColor: '#FFFFFF'},
      { status: 'Painting', bColor: '#F2DA77', tColor: '#44A9DF'},
      { status: '1PE',      bColor: '#CFD88C', tColor: '#333'},
      { status: '3PE',      bColor: '#91C643', tColor: '#333'},
    ],

    Border: [
      {
        type: 'PE',
        shape: 'ellipse',
        width: 55,
        height: 20,
        color: 'gray',
        stroke: .2,
        textSize: 13,
        padding: 1,
      },
      {
        type: '3PE',
        shape: 'ellipse',
        width: 38,
        height: 16,
        color: 'gray',
        stroke: .2,
        textSize: 9.5,
        padding: 0.5,
      },
      {
        type: '1PE',
        shape: 'rect',
        width: 40,
        height: 16,
        color: 'gray',
        stroke: .2,
        textSize: 10,
        padding: 1,
      },
    ],
    

    MODULE: {
      Hull: [
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y680H'   ,x: 263.42  ,y: 510.24   ,x1: 347.00  ,y1: 550.00    ,x2: 213.00  ,y2: 483.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y440H'   ,x: 158.00  ,y: 320.24   ,x1: 378.00  ,y1: 395.00    ,x2: 181.00  ,y2: 305.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y420H'   ,x: 200.42  ,y: 266.24   ,x1: 383.00  ,y1: 365.00    ,x2: 196.00  ,y2: 275.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y530H'   ,x: 288.42  ,y: 365.24   ,x1: 446.00  ,y1: 466.00    ,x2: 254.00  ,y2: 367.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y540H'   ,x: 254.42  ,y: 430.24   ,x1: 428.00  ,y1: 477.00    ,x2: 236.00  ,y2: 378.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y170H'   ,x: 421.42  ,y: 80.246   ,x1: 602.00  ,y1: 185.00    ,x2: 417.00  ,y2: 91.000 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y180H'   ,x: 468.42  ,y: 133.24   ,x1: 612.00  ,y1: 204.00    ,x2: 437.00  ,y2: 110.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y610H'   ,x: 542.42  ,y: 287.24   ,x1: 634.00  ,y1: 325.00    ,x2: 493.00  ,y2: 253.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y210H'   ,x: 1208.4  ,y: 182.24   ,x1: 1025.0  ,y1: 309.00    ,x2: 1189.0  ,y2: 191.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y630H'   ,x: 1134.4  ,y: 306.24   ,x1: 979.00  ,y1: 436.00    ,x2: 1130.0  ,y2: 314.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'R160H'   ,x: 1085.4  ,y: 342.24   ,x1: 956.00  ,y1: 463.00    ,x2: 1107.0  ,y2: 341.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y620H'   ,x: 947.42  ,y: 261.24   ,x1: 741.00  ,y1: 338.00    ,x2: 892.00  ,y2: 216.00 },

                { type: 'Module'  ,module: 'NP1UH'                 ,x: 900.00  ,y: 525.39   ,x1: 551.80  ,y1: 310.00    ,x2: 715.00  ,y2: 389.00      ,x3: 350.00  ,y3: 150.00},
                { type: 'Module'  ,module: 'NP2UH'                 ,x: 1050.0  ,y: 685.39   ,x1: 773.00  ,y1: 449.00    ,x2: 850.00  ,y2: 640.00      ,x3: 350.00  ,y3: 150.00},
                { type: 'Module'  ,module: 'NP4UH'                 ,x: 320.00  ,y: 565.39   ,x1: 347.00  ,y1: 397.00    ,x2: 320.00  ,y2: 415.00      ,x3: 350.00  ,y3: 150.00},
                { type: 'Module'  ,module: 'C10UH'                 ,x: 860.00  ,y: 160.39   ,x1: 594.17  ,y1: 176.00    ,x2: 740.00  ,y2: 135.00      ,x3: 350.00  ,y3: 150.00},
                { type: 'Module'  ,module: 'C20UH'                 ,x: 1045.0  ,y: 390.39   ,x1: 920.63  ,y1: 332.00    ,x2: 930.00  ,y2: 365.00      ,x3: 350.00  ,y3: 150.00},
                { type: 'Module'  ,module: 'C40UH'                 ,x: 430.00  ,y: 255.39   ,x1: 347.85  ,y1: 382.00    ,x2: 320.00  ,y2: 238.00      ,x3: 350.00  ,y3: 150.00},

                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y510H'   ,x: 403.42  ,y: 318.24   ,x1: 563.00  ,y1: 370.00    ,x2: 371.00  ,y2: 271.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y520H'   ,x: 350.42  ,y: 338.24   ,x1: 546.00  ,y1: 383.00    ,x2: 354.00  ,y2: 284.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y670H'   ,x: 319.42  ,y: 774.24   ,x1: 417.00  ,y1: 600.00    ,x2: 260.00  ,y2: 730.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y410H'   ,x: 231.42  ,y: 800.24   ,x1: 373.97  ,y1: 445.04    ,x2: 220.97  ,y2: 571.04 },
                { type: 'Module'  ,module: 'NP3UH'                 ,x: 610.00  ,y: 790.39   ,x1: 477.00  ,y1: 579.00    ,x2: 414.00  ,y2: 675.00      ,x3: 350.00  ,y3: 150.00},
                { type: 'Module'  ,module: 'C30UH'                 ,x: 612.00  ,y: 510.00   ,x1: 669.23  ,y1: 519.71    ,x2: 505.00  ,y2: 470.00      ,x3: 350.00  ,y3: 150.00},
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y660H'   ,x: 544.42  ,y: 885.24   ,x1: 642.00  ,y1: 718.00    ,x2: 485.00  ,y2: 848.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y650H'   ,x: 962.42  ,y: 812.24   ,x1: 824.00  ,y1: 703.00    ,x2: 913.00  ,y2: 758.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y370H'   ,x: 1023.4  ,y: 849.24   ,x1: 814.00  ,y1: 588.00    ,x2: 989.00  ,y2: 676.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y230H'   ,x: 1233.4  ,y: 678.24   ,x1: 1025.0  ,y1: 420.00    ,x2: 1200.0  ,y2: 508.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'Y640H'   ,x: 1145.4  ,y: 655.24   ,x1: 990.02  ,y1: 550.00    ,x2: 1081.0  ,y2: 605.00 },
                { type: 'Block'   ,module: 'MISC'  ,blk: 'PIPES'   ,x: 0.00    ,y: 0.0000   ,x1: 314.85  ,y1: 180.87    ,x2: 314.85  ,y2: 180.87 },
      ],
      Deckbox: [
                { type: 'Module'  ,module: 'N10UH'   ,x: 1050  ,y: 185      ,x1: 588  ,y1: 322     ,x2: 818  ,y2: 220      ,x3:300  ,y3: 230 },
                { type: 'Module'  ,module: 'W10UH'   ,x: 790   ,y: 400      ,x1: 518  ,y1: 353     ,x2: 563  ,y2: 386      ,x3:320  ,y3: 230 },
                { type: 'Module'  ,module: 'E10UH'   ,x: 1015  ,y: 665      ,x1: 638  ,y1: 417     ,x2: 855  ,y2: 534      ,x3:320  ,y3: 230 },
                { type: 'Module'  ,module: 'S10UH'   ,x: 495   ,y: 760      ,x1: 460  ,y1: 414     ,x2: 347  ,y2: 575      ,x3:300  ,y3: 230 },
                { type: 'Module'  ,module: 'HD1UH'   ,x: 490   ,y: 133      ,x1: 424  ,y1: 370     ,x2: 371  ,y2: 123      ,x3:340  ,y3: 260 },
                { type: 'Module'  ,module: 'LQ1UH'   ,x: 315   ,y: 420      ,x1: 452  ,y1: 386     ,x2: 338  ,y2: 311      ,x3:320  ,y3: 250 },
      ],
      Topside: [
                { type: 'Module'  ,module: 'PM1UT'   ,x: 437   ,y: 366      ,x1: 492  ,y1: 285     ,x2: 385  ,y2: 153      ,x3: 300 ,y3: 150 },
                { type: 'Module'  ,module: 'CM1UT'   ,x: 946   ,y: 204      ,x1: 620  ,y1: 360     ,x2: 783  ,y2: 241      ,x3: 300 ,y3: 150 },
                { type: 'Module'  ,module: 'GM1UT'   ,x: 245   ,y: 675      ,x1: 431  ,y1: 435     ,x2: 225  ,y2: 490      ,x3: 300 ,y3: 150 },
                { type: 'Module'  ,module: 'WM1UT'   ,x: 804   ,y: 700      ,x1: 570  ,y1: 374     ,x2: 572  ,y2: 545      ,x3: 300 ,y3: 150 },
                { type: 'Module'  ,module: 'FB1UT'   ,x: 1062  ,y: 572      ,x1: 757  ,y1: 289     ,x2: 962  ,y2: 380      ,x3: 300 ,y3: 150 },
      ],
    }, 

    BLOCKS: {
      // Pontoon
      Hull: [
              { area: 'Hull'  ,module: 'NP1UH', blk: 'R110H'   ,x: 949.30  ,y: 316.60   ,x1: 182.81  ,y1: 53.152   ,x2: 908.60  ,y2: 323.10 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'R120H'   ,x: 994.10  ,y: 339.10   ,x1: 227.82  ,y1: 73.908   ,x2: 953.60  ,y2: 343.90 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'R140H'   ,x: 1103.0  ,y: 391.10   ,x1: 331.09  ,y1: 124.54   ,x2: 1056.0  ,y2: 394.50 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'R130H'   ,x: 1048.0  ,y: 368.20   ,x1: 284.12  ,y1: 100.21   ,x2: 1009.0  ,y2: 370.20 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'R150H'   ,x: 1147.0  ,y: 411.20   ,x1: 381.05  ,y1: 150.09   ,x2: 1106.0  ,y2: 420.00 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'Y710H'   ,x: 314.60  ,y: 311.00   ,x1: 0.0000  ,y1: 78.067   ,x2: 277.70  ,y2: 306.00 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'N110H'   ,x: 639.60  ,y: 346.30   ,x1: 53.408  ,y1: 38.234   ,x2: 492.20  ,y2: 291.20 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'N140H'   ,x: 411.40  ,y: 530.80   ,x1: 7.2030  ,y1: 80.968   ,x2: 275.30  ,y2: 471.90 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'N150H'   ,x: 644.60  ,y: 229.60   ,x1: 53.408  ,y1: 0.0000   ,x2: 492.20  ,y2: 163.00 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'N180H'   ,x: 401.40  ,y: 431.60   ,x1: 6.2030  ,y1: 43.051   ,x2: 275.00  ,y2: 346.00 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'N120H'   ,x: 823.60  ,y: 417.20   ,x1: 120.31  ,y1: 64.699   ,x2: 668.10  ,y2: 370.60 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'N160H'   ,x: 823.60  ,y: 318.70   ,x1: 120.19  ,y1: 27.394   ,x2: 667.90  ,y2: 244.30 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'N130H'   ,x: 589.40  ,y: 617.00   ,x1: 73.956  ,y1: 120.19   ,x2: 451.70  ,y2: 564.10 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'N170H'   ,x: 581.90  ,y: 510.80   ,x1: 73.956  ,y1: 80.226   ,x2: 451.70  ,y2: 436.20 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'P130H'   ,x: 1023.0  ,y: 502.80   ,x1: 223.26  ,y1: 68.887   ,x2: 883.00  ,y2: 426.80 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'P110H'   ,x: 909.00  ,y: 603.20   ,x1: 194.80  ,y1: 121.13   ,x2: 768.60  ,y2: 548.10 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'P120H'   ,x: 900.00  ,y: 525.80   ,x1: 194.97  ,y1: 85.401   ,x2: 769.70  ,y2: 447.40 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'P140H'   ,x: 789.90  ,y: 711.50   ,x1: 176.70  ,y1: 109.22   ,x2: 665.80  ,y2: 607.20 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'P170H'   ,x: 1150.0  ,y: 525.80   ,x1: 310.40  ,y1: 109.75   ,x2: 1081.0  ,y2: 521.70 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'P150H'   ,x: 1078.0  ,y: 698.80   ,x1: 279.67  ,y1: 160.45   ,x2: 965.40  ,y2: 642.40 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'P160H'   ,x: 1072.0  ,y: 614.30   ,x1: 280.11  ,y1: 126.15   ,x2: 965.90  ,y2: 542.10 },
              { area: 'Hull'  ,module: 'NP1UH', blk: 'P180H'   ,x: 957.80  ,y: 800.40   ,x1: 262.25  ,y1: 150.78   ,x2: 861.00  ,y2: 700.70 },

              { area: 'Hull'  ,module: 'NP2UH', blk: 'N220H'   ,x: 839.05  ,y: 319.25   ,x1: 116.49  ,y1: 31.793   ,x2: 696.49  ,y2: 243.79 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'N260H'   ,x: 843.00  ,y: 206.21   ,x1: 116.49  ,y1: 0.0000   ,x2: 696.49  ,y2: 125.00 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'N230H'   ,x: 710.49  ,y: 411.23   ,x1: 102.83  ,y1: 87.826   ,x2: 596.83  ,y2: 371.82 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'N270H'   ,x: 683.36  ,y: 336.76   ,x1: 102.80  ,y1: 44.728   ,x2: 595.80  ,y2: 239.72 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'P280H'   ,x: 581.53  ,y: 524.12   ,x1: 64.680  ,y1: 102.99   ,x2: 471.68  ,y2: 456.99 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'N210H'   ,x: 1126.5  ,y: 461.05   ,x1: 185.81  ,y1: 62.490   ,x2: 990.81  ,y2: 384.49 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'N250H'   ,x: 1136.4  ,y: 346.24   ,x1: 186.24  ,y1: 25.917   ,x2: 990.24  ,y2: 257.91 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'N240H'   ,x: 991.05  ,y: 565.81   ,x1: 140.09  ,y1: 119.35   ,x2: 858.09  ,y2: 511.35 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'N280H'   ,x: 969.83  ,y: 482.89   ,x1: 140.35  ,y1: 78.685   ,x2: 857.35  ,y2: 381.68 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'P250H'   ,x: 732.35  ,y: 595.45   ,x1: 97.643  ,y1: 155.07   ,x2: 616.64  ,y2: 563.07 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'P260H'   ,x: 726.65  ,y: 520.42   ,x1: 97.993  ,y1: 118.82   ,x2: 616.99  ,y2: 459.82 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'P270H'   ,x: 866.14  ,y: 665.83   ,x1: 138.02  ,y1: 138.79   ,x2: 769.02  ,y2: 600.79 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'P240H'   ,x: 430.04  ,y: 653.61   ,x1: 0.0000  ,y1: 143.86   ,x2: 319.00  ,y2: 567.86 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'P210H'   ,x: 594.91  ,y: 716.13   ,x1: 33.351  ,y1: 194.51   ,x2: 465.35  ,y2: 673.51 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'P220H'   ,x: 590.84  ,y: 614.58   ,x1: 32.906  ,y1: 157.13   ,x2: 464.90  ,y2: 570.13 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'P230H'   ,x: 728.35  ,y: 778.81   ,x1: 72.547  ,y1: 177.04   ,x2: 616.54  ,y2: 710.04 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'R210H'   ,x: 1073.4  ,y: 687.25   ,x1: 216.52  ,y1: 154.94   ,x2: 1027.5  ,y2: 634.94 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'R220H'   ,x: 1040.1  ,y: 712.75   ,x1: 199.20  ,y1: 167.13   ,x2: 992.20  ,y2: 664.13 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'R230H'   ,x: 1002.1  ,y: 745.98   ,x1: 172.77  ,y1: 188.39   ,x2: 948.77  ,y2: 699.39 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'R240H'   ,x: 969.53  ,y: 771.29   ,x1: 144.20  ,y1: 210.61   ,x2: 919.20  ,y2: 722.61 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'R250H'   ,x: 940.16  ,y: 796.24   ,x1: 123.87  ,y1: 226.70   ,x2: 882.87  ,y2: 752.70 },
              { area: 'Hull'  ,module: 'NP2UH', blk: 'R260H'   ,x: 908.44  ,y: 821.71   ,x1: 104.96  ,y1: 242.82   ,x2: 847.96  ,y2: 780.82 },
              
              { area: 'Hull'  ,module: 'NP3UH', blk: 'P340H'   ,x: 627.86  ,y: 327.30   ,x1: 53.554  ,y1: 0.0000   ,x2: 496.55  ,y2: 229.00 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'P380H'   ,x: 790.78  ,y: 425.00   ,x1: 139.52  ,y1: 43.302   ,x2: 694.52  ,y2: 327.30 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'N330H'   ,x: 997.81  ,y: 492.35   ,x1: 205.93  ,y1: 98.010   ,x2: 872.93  ,y2: 436.01 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'N370H'   ,x: 983.23  ,y: 390.34   ,x1: 205.93  ,y1: 64.020   ,x2: 872.93  ,y2: 314.02 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'N340H'   ,x: 1120.5  ,y: 605.77   ,x1: 275.49  ,y1: 120.43   ,x2: 1055.4  ,y2: 512.43 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'N380H'   ,x: 1129.0  ,y: 374.26   ,x1: 275.64  ,y1: 77.994   ,x2: 1054.6  ,y2: 380.99 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'P310H'   ,x: 508.55  ,y: 424.24   ,x1: 24.118  ,y1: 50.162   ,x2: 380.11  ,y2: 349.16 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'P320H'   ,x: 498.55  ,y: 345.84   ,x1: 23.884  ,y1: 14.825   ,x2: 379.88  ,y2: 247.82 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'P350H'   ,x: 680.87  ,y: 463.29   ,x1: 109.26  ,y1: 88.427   ,x2: 578.26  ,y2: 443.42 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'P360H'   ,x: 678.23  ,y: 424.24   ,x1: 109.26  ,y1: 56.212   ,x2: 578.26  ,y2: 343.21 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'P330H'   ,x: 386.83  ,y: 524.95   ,x1: 0.0000  ,y1: 38.886   ,x2: 270.00  ,y2: 407.88 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'P370H'   ,x: 565.89  ,y: 604.66   ,x1: 85.407  ,y1: 80.409   ,x2: 468.40  ,y2: 503.40 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'N320H'   ,x: 797.18  ,y: 636.40   ,x1: 148.48  ,y1: 127.06   ,x2: 642.48  ,y2: 604.06 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'N360H'   ,x: 788.56  ,y: 554.17   ,x1: 147.84  ,y1: 82.796   ,x2: 641.84  ,y2: 471.79 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'N310H'   ,x: 975.42  ,y: 718.76   ,x1: 217.65  ,y1: 152.09   ,x2: 824.65  ,y2: 684.09 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'N350H'   ,x: 976.93  ,y: 624.28   ,x1: 217.27  ,y1: 115.62   ,x2: 824.27  ,y2: 558.62 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'R310H'   ,x: 319.78  ,y: 653.40   ,x1: 78.593  ,y1: 126.57   ,x2: 288.59  ,y2: 595.57 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'R320H'   ,x: 387.37  ,y: 687.53   ,x1: 112.16  ,y1: 138.30   ,x2: 365.16  ,y2: 628.30 },
              { area: 'Hull'  ,module: 'NP3UH', blk: 'R330H'   ,x: 450.86  ,y: 714.95   ,x1: 136.50  ,y1: 149.04   ,x2: 434.5   ,y2: 661.04 },
          
              { area: 'Hull'  ,module: 'NP4UH', blk: 'P470H'   ,x: 849.32  ,y: 250.50   ,x1: 164.22  ,y1: 0.0000   ,x2: 754.22  ,y2: 188.25 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'Y720H'   ,x: 679.86  ,y: 202.36   ,x1: 155.68  ,y1: 34.553   ,x2: 651.68  ,y2: 208.80 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'Y730H'   ,x: 609.61  ,y: 257.50   ,x1: 83.510  ,y1: 94.925   ,x2: 582.51  ,y2: 265.17 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'P450H'   ,x: 971.78  ,y: 354.14   ,x1: 185.66  ,y1: 41.392   ,x2: 887.66  ,y2: 284.64 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'P460H'   ,x: 997.55  ,y: 234.30   ,x1: 185.17  ,y1: 7.7500   ,x2: 887.17  ,y2: 184.00 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'P480H'   ,x: 1149.0  ,y: 395.35   ,x1: 224.35  ,y1: 26.813   ,x2: 1039.3  ,y2: 323.06 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'P430H'   ,x: 666.26  ,y: 410.50   ,x1: 98.645  ,y1: 39.543   ,x2: 602.64  ,y2: 297.79 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'P410H'   ,x: 851.93  ,y: 452.11   ,x1: 119.03  ,y1: 83.107   ,x2: 736.03  ,y2: 395.35 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'P420H'   ,x: 858.04  ,y: 354.14   ,x1: 119.33  ,y1: 47.138   ,x2: 736.33  ,y2: 293.38 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'P440H'   ,x: 1018.0  ,y: 496.67   ,x1: 159.66  ,y1: 66.109   ,x2: 887.66  ,y2: 432.35 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'N440H'   ,x: 594.69  ,y: 529.03   ,x1: 52.082  ,y1: 123.71   ,x2: 469.08  ,y2: 452.96 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'N480H'   ,x: 581.46  ,y: 342.93   ,x1: 52.139  ,y1: 91.481   ,x2: 469.13  ,y2: 332.73 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'N430H'   ,x: 870.59  ,y: 664.85   ,x1: 117.60  ,y1: 168.03   ,x2: 759.60  ,y2: 605.28 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'N470H'   ,x: 864.15  ,y: 579.34   ,x1: 118.02  ,y1: 121.16   ,x2: 760.02  ,y2: 470.41 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'N410H'   ,x: 450.71  ,y: 644.97   ,x1: 0.3610  ,y1: 161.60   ,x2: 331.36  ,y2: 560.85 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'N450H'   ,x: 443.49  ,y: 440.16   ,x1: 0.0000  ,y1: 122.73   ,x2: 331.00  ,y2: 433.98 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'N420H'   ,x: 764.06  ,y: 767.93   ,x1: 60.198  ,y1: 196.67   ,x2: 615.19  ,y2: 703.92 },
              { area: 'Hull'  ,module: 'NP4UH', blk: 'N460H'   ,x: 736.33  ,y: 684.51   ,x1: 60.400  ,y1: 160.09   ,x2: 615.4   ,y2: 579.34 },
          
              // Columns    
              { area: 'Hull'  ,module: 'C10UH', blk: 'C110H'   ,x: 840.63  ,y: 608.10   ,x1: 35.358  ,y1: 93.739   ,x2: 682.52  ,y2: 530.73 },
              { area: 'Hull'  ,module: 'C10UH', blk: 'C120H'   ,x: 746.77  ,y: 695.45   ,x1: 0.0000  ,y1: 118.68   ,x2: 578.16  ,y2: 610.68 },
              { area: 'Hull'  ,module: 'C10UH', blk: 'C130H'   ,x: 827.88  ,y: 460.27   ,x1: 35.435  ,y1: 48.499   ,x2: 682.60  ,y2: 351.49 },
              { area: 'Hull'  ,module: 'C10UH', blk: 'C140H'   ,x: 742.05  ,y: 518.13   ,x1: 4.6460  ,y1: 73.052   ,x2: 582.81  ,y2: 432.05 },
              { area: 'Hull'  ,module: 'C10UH', blk: 'C150H'   ,x: 819.78  ,y: 285.02   ,x1: 35.413  ,y1: 0.0000   ,x2: 682.58  ,y2: 170.00 },
              { area: 'Hull'  ,module: 'C10UH', blk: 'C160H'   ,x: 736.95  ,y: 340.00   ,x1: 4.6460  ,y1: 24.623   ,x2: 582.81  ,y2: 251.62 },
              { area: 'Hull'  ,module: 'C10UH', blk: 'Y160H'   ,x: 468.00  ,y: 681.81   ,x1: 9.5570  ,y1: 63.204   ,x2: 486.72  ,y2: 640.20 },
              { area: 'Hull'  ,module: 'C10UH', blk: 'Y110H'   ,x: 472.30  ,y: 769.65   ,x1: 16.026  ,y1: 52.809   ,x2: 493.19  ,y2: 629.80 },
              { area: 'Hull'  ,module: 'C10UH', blk: 'Y120H'   ,x: 499.62  ,y: 793.14   ,x1: 27.625  ,y1: 81.558   ,x2: 504.79  ,y2: 658.55 },
              { area: 'Hull'  ,module: 'C10UH', blk: 'Y130H'   ,x: 515.63  ,y: 811.58   ,x1: 41.676  ,y1: 65.362   ,x2: 518.84  ,y2: 642.36 },
              { area: 'Hull'  ,module: 'C10UH', blk: 'Y140H'   ,x: 535.48  ,y: 827.84   ,x1: 54.400  ,y1: 71.443   ,x2: 531.56  ,y2: 648.44 },
              { area: 'Hull'  ,module: 'C10UH', blk: 'Y150H'   ,x: 582.83  ,y: 787.61   ,x1: 65.129  ,y1: 76.416   ,x2: 542.29  ,y2: 653.41 },
          
              { area: 'Hull'  ,module: 'C20UH', blk: 'C210H'   ,x: 794.00  ,y: 612.92   ,x1: 37.348  ,y1: 95.620   ,x2: 644.34  ,y2: 528.62 },
              { area: 'Hull'  ,module: 'C20UH', blk: 'C220H'   ,x: 697.00  ,y: 696.22   ,x1: 2.7600  ,y1: 120.15   ,x2: 540.76  ,y2: 609.15 },
              { area: 'Hull'  ,module: 'C20UH', blk: 'C230H'   ,x: 794.00  ,y: 441.20   ,x1: 37.348  ,y1: 50.631   ,x2: 644.34  ,y2: 351.63 },
              { area: 'Hull'  ,module: 'C20UH', blk: 'C240H'   ,x: 694.59  ,y: 500.69   ,x1: 6.6270  ,y1: 75.200   ,x2: 544.62  ,y2: 432.20 },
              { area: 'Hull'  ,module: 'C20UH', blk: 'C250H'   ,x: 794.00  ,y: 268.93   ,x1: 37.348  ,y1: 0.0000   ,x2: 644.34  ,y2: 169.00 },
              { area: 'Hull'  ,module: 'C20UH', blk: 'C260H'   ,x: 696.00  ,y: 330.00   ,x1: 2.6340  ,y1: 26.603   ,x2: 540.63  ,y2: 251.60 },
              { area: 'Hull'  ,module: 'C20UH', blk: 'Y220H'   ,x: 507.00  ,y: 637.00   ,x1: 49.122  ,y1: 96.114   ,x2: 499.12  ,y2: 648.11 },
          
              { area: 'Hull'  ,module: 'C30UH', blk: 'Y310H'   ,x: 846.26  ,y: 303.55   ,x1: 74.181  ,y1: 0.0000   ,x2: 828.41  ,y2: 322.71 },
              { area: 'Hull'  ,module: 'C30UH', blk: 'Y320H'   ,x: 868.22  ,y: 319.46   ,x1: 89.483  ,y1: 5.8960   ,x2: 843.71  ,y2: 328.60 },
              { area: 'Hull'  ,module: 'C30UH', blk: 'Y330H'   ,x: 890.40  ,y: 334.13   ,x1: 100.08  ,y1: 10.404   ,x2: 854.31  ,y2: 333.11 },
              { area: 'Hull'  ,module: 'C30UH', blk: 'Y340H'   ,x: 921.20  ,y: 369.49   ,x1: 135.67  ,y1: 51.142   ,x2: 889.91  ,y2: 373.85 },
              { area: 'Hull'  ,module: 'C30UH', blk: 'C320H'   ,x: 812.55  ,y: 602.37   ,x1: 30.406  ,y1: 113.20   ,x2: 650.64  ,y2: 525.91 },
              { area: 'Hull'  ,module: 'C30UH', blk: 'C310H'   ,x: 703.00  ,y: 688.09   ,x1: 0.0000  ,y1: 138.40   ,x2: 550.23  ,y2: 607.11 },
              { area: 'Hull'  ,module: 'C30UH', blk: 'C340H'   ,x: 793.00  ,y: 460.12   ,x1: 30.406  ,y1: 68.576   ,x2: 650.64  ,y2: 348.28 },
              { area: 'Hull'  ,module: 'C30UH', blk: 'C330H'   ,x: 703.00  ,y: 508.18   ,x1: 4.4730  ,y1: 93.159   ,x2: 554.70  ,y2: 429.86 },
              { area: 'Hull'  ,module: 'C30UH', blk: 'C360H'   ,x: 803.00  ,y: 263.06   ,x1: 30.406  ,y1: 17.290   ,x2: 650.64  ,y2: 165.00 },
              { area: 'Hull'  ,module: 'C30UH', blk: 'C350H'   ,x: 703.00  ,y: 337.20   ,x1: 0.1370  ,y1: 45.507   ,x2: 550.37  ,y2: 250.21 },
              { area: 'Hull'  ,module: 'C30UH', blk: 'Y350H'   ,x: 480.00  ,y: 586.22   ,x1: 5.8050  ,y1: 67.767   ,x2: 470.04  ,y2: 605.47 },
              { area: 'Hull'  ,module: 'C30UH', blk: 'Y360H'   ,x: 508.50  ,y: 608.20   ,x1: 35.378  ,y1: 91.467   ,x2: 500.61  ,y2: 628.17 },
          
              { area: 'Hull'  ,module: 'C40UH', blk: 'Y430H'   ,x: 855.86  ,y: 471.03   ,x1: 101.33  ,y1: 14.316   ,x2: 812.18  ,y2: 381.31 },
              { area: 'Hull'  ,module: 'C40UH', blk: 'C420H'   ,x: 793.00  ,y: 614.10   ,x1: 27.109  ,y1: 92.971   ,x2: 638.95  ,y2: 541.97 },
              { area: 'Hull'  ,module: 'C40UH', blk: 'C410H'   ,x: 694.00  ,y: 698.36   ,x1: 0.2050  ,y1: 118.70   ,x2: 543.05  ,y2: 623.70 },
              { area: 'Hull'  ,module: 'C40UH', blk: 'C440H'   ,x: 791.00  ,y: 452.97   ,x1: 26.816  ,y1: 47.878   ,x2: 638.66  ,y2: 366.87 },
              { area: 'Hull'  ,module: 'C40UH', blk: 'C430H'   ,x: 694.00  ,y: 523.93   ,x1: 0.0000  ,y1: 73.819   ,x2: 543.85  ,y2: 447.81 },
              { area: 'Hull'  ,module: 'C40UH', blk: 'C460H'   ,x: 791.00  ,y: 277.09   ,x1: 26.816  ,y1: 0.0000   ,x2: 638.66  ,y2: 187.00 },
              { area: 'Hull'  ,module: 'C40UH', blk: 'C450H'   ,x: 694.00  ,y: 350.00   ,x1: 0.1500  ,y1: 26.148   ,x2: 543.00  ,y2: 269.14 },

              // PIPES
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y680H'   ,x: 263.42  ,y: 510.24   ,x1: 347.00  ,y1: 550.00   ,x2: 213.00  ,y2: 483.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y440H'   ,x: 158.00  ,y: 320.24   ,x1: 378.00  ,y1: 395.00   ,x2: 191.00  ,y2: 305.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y420H'   ,x: 200.42  ,y: 266.24   ,x1: 383.00  ,y1: 365.00   ,x2: 196.00  ,y2: 275.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y530H'   ,x: 288.42  ,y: 365.24   ,x1: 446.00  ,y1: 466.00   ,x2: 254.00  ,y2: 367.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y540H'   ,x: 254.42  ,y: 430.24   ,x1: 428.00  ,y1: 477.00   ,x2: 236.00  ,y2: 378.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y170H'   ,x: 421.42  ,y: 80.246   ,x1: 602.00  ,y1: 185.00   ,x2: 417.00  ,y2: 91.000 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y180H'   ,x: 468.42  ,y: 133.24   ,x1: 612.00  ,y1: 204.00   ,x2: 427.00  ,y2: 110.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y610H'   ,x: 542.42  ,y: 287.24   ,x1: 634.00  ,y1: 325.00   ,x2: 493.00  ,y2: 253.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y210H'   ,x: 1208.4  ,y: 182.24   ,x1: 1025.0  ,y1: 309.00   ,x2: 1189.0  ,y2: 191.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y630H'   ,x: 1134.4  ,y: 306.24   ,x1: 979.00  ,y1: 436.00   ,x2: 1130.0  ,y2: 314.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'R160H'   ,x: 1085.4  ,y: 342.24   ,x1: 956.00  ,y1: 463.00   ,x2: 1107.0  ,y2: 341.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y620H'   ,x: 947.42  ,y: 261.24   ,x1: 741.00  ,y1: 338.00   ,x2: 892.00  ,y2: 216.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y510H'   ,x: 403.42  ,y: 318.24   ,x1: 563.00  ,y1: 370.00   ,x2: 371.00  ,y2: 271.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y520H'   ,x: 350.42  ,y: 338.24   ,x1: 546.00  ,y1: 383.00   ,x2: 354.00  ,y2: 284.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y670H'   ,x: 319.42  ,y: 774.24   ,x1: 417.00  ,y1: 600.00   ,x2: 260.00  ,y2: 730.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y410H'   ,x: 231.42  ,y: 800.24   ,x1: 373.97  ,y1: 445.04   ,x2: 220.97  ,y2: 571.04 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y660H'   ,x: 544.42  ,y: 885.24   ,x1: 642.00  ,y1: 718.00   ,x2: 485.00  ,y2: 848.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y650H'   ,x: 962.42  ,y: 812.24   ,x1: 824.00  ,y1: 703.00   ,x2: 913.00  ,y2: 758.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y370H'   ,x: 1023.4  ,y: 849.24   ,x1: 814.00  ,y1: 588.00   ,x2: 989.00  ,y2: 676.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y230H'   ,x: 1233.4  ,y: 678.24   ,x1: 1025.0  ,y1: 420.00   ,x2: 1200.0  ,y2: 508.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'Y640H'   ,x: 1145.4  ,y: 655.24   ,x1: 990.02  ,y1: 550.00   ,x2: 1081.0  ,y2: 605.00 },
              { area: 'Hull'  ,module: 'MISC'  ,blk: 'PIPES'   ,x: 0.0000  ,y: 0.0000   ,x1: 314.85  ,y1: 180.87   ,x2: 314.85  ,y2: 180.87 },
      ],



      Deckbox: [
               
                // E10UH
                { module: 'E10UH', blk: 'L450H',   x: 768.03 ,y: 509.46   ,x1: 54.206  ,y1: 14.253  ,x2: 682.80 ,y2: 474.80 },
                { module: 'E10UH', blk: 'L460H',   x: 700.82 ,y: 560.57   ,x1: 55.979  ,y1: 43.953  ,x2: 652.18 ,y2: 530.60 },
                { module: 'E10UH', blk: 'L340H',   x: 653.12 ,y: 601.00   ,x1: 3.9870  ,y1: 50.580  ,x2: 573.18 ,y2: 558.82 },
                { module: 'E10UH', blk: 'L470H',   x: 853.56 ,y: 553.32   ,x1: 78.613  ,y1: 38.655  ,x2: 757.61 ,y2: 519.90 },
                { module: 'E10UH', blk: 'L350H',   x: 745.63 ,y: 638.57   ,x1: 47.906  ,y1: 70.405  ,x2: 667.50 ,y2: 598.90 },
                { module: 'E10UH', blk: 'L480H',   x: 920.27 ,y: 584.00   ,x1: 138.51  ,y1: 58.230  ,x2: 864.31 ,y2: 557.92 },
                { module: 'E10UH', blk: 'L360H',   x: 799.79 ,y: 683.47   ,x1: 87.613  ,y1: 106.59  ,x2: 754.46 ,y2: 658.94 },
                { module: 'E10UH', blk: 'M440H',   x: 752.12 ,y: 268.74   ,x1: 33.930  ,y1: 0.0000  ,x2: 649.93 ,y2: 236.00 },
                { module: 'E10UH', blk: 'M340H',   x: 668.80 ,y: 344.25   ,x1: 0.0000  ,y1: 31.950  ,x2: 571.00 ,y2: 304.40 },
                { module: 'E10UH', blk: 'M450H',   x: 840.17 ,y: 316.66   ,x1: 82.066  ,y1: 23.292  ,x2: 743.51 ,y2: 281.79 },
                { module: 'E10UH', blk: 'M350H',   x: 757.86 ,y: 389.97   ,x1: 50.580  ,y1: 54.077  ,x2: 669.28 ,y2: 349.92 },
                { module: 'E10UH', blk: 'M460H',   x: 913.07 ,y: 347.71   ,x1: 139.41  ,y1: 40.842  ,x2: 853.51 ,y2: 322.29 },
                { module: 'E10UH', blk: 'M360H',   x: 805.88 ,y: 440.96   ,x1: 87.613  ,y1: 89.344  ,x2: 754.46 ,y2: 408.59 },
                { module: 'E10UH', blk: 'M920H',   x: 619.97 ,y: 252.06   ,x1: 15.911  ,y1: 50.850  ,x2: 585.11 ,y2: 254.45 },
                { module: 'E10UH', blk: 'E10UH_P', x: 0      ,y: 0        ,x1: 0.0000  ,y1: 0.0000  },

                // W10UH
                { module: 'W10UH',blk: 'L410H',    x: 737.1  ,y: 536.22   ,x1: 31.471 ,y1: 20.547   ,x2: 647.42 ,y2: 498.73 },
                { module: 'W10UH',blk: 'L310H',    x: 641.3  ,y: 614.81   ,x1: 0.0000 ,y1: 54.001   ,x2: 575.00 ,y2: 566.84 },
                { module: 'W10UH',blk: 'T410H',    x: 651.7  ,y: 480.75   ,x1: 36.421 ,y1: 46.709   ,x2: 608.72 ,y2: 444.80 },
                { module: 'W10UH',blk: 'L420H',    x: 819.9  ,y: 576.69   ,x1: 74.550 ,y1: 42.976   ,x2: 738.65 ,y2: 544.11 },
                { module: 'W10UH',blk: 'L320H',    x: 732.7  ,y: 649.20   ,x1: 43.612 ,y1: 74.839   ,x2: 666.76 ,y2: 610.62 },
                { module: 'W10UH',blk: 'L430H',    x: 913.3  ,y: 621.29   ,x1: 122.60 ,y1: 58.730   ,x2: 834.40 ,y2: 582.82 },
                { module: 'W10UH',blk: 'L440H',    x: 835.1  ,y: 644.47   ,x1: 103.57 ,y1: 77.597   ,x2: 794.22 ,y2: 619.68 },
                { module: 'W10UH',blk: 'L330H',    x: 827.0  ,y: 697.54   ,x1: 75.514 ,y1: 92.984   ,x2: 746.36 ,y2: 651.72 },
                { module: 'W10UH',blk: 'M410H',    x: 744.2  ,y: 289.95   ,x1: 46.650 ,y1: 13.050   ,x2: 662.60 ,y2: 259.04 },
                { module: 'W10UH',blk: 'M310H',    x: 659.7  ,y: 357.57   ,x1: 9.0000 ,y1: 44.673   ,x2: 584.00 ,y2: 325.31 },
                { module: 'W10UH',blk: 'M420H',    x: 827.0  ,y: 335.85   ,x1: 75.402 ,y1: 26.551   ,x2: 739.05 ,y2: 295.94 },
                { module: 'W10UH',blk: 'M320H',    x: 744.2  ,y: 401.72   ,x1: 43.275 ,y1: 58.506   ,x2: 666.87 ,y2: 362.09 },
                { module: 'W10UH',blk: 'M430H',    x: 915.8  ,y: 380.83   ,x1: 108.09 ,y1: 42.531   ,x2: 819.44 ,y2: 335.32 },
                { module: 'W10UH',blk: 'M330H',    x: 835.1  ,y: 441.17   ,x1: 76.977 ,y1: 74.834   ,x2: 747.82 ,y2: 400.92 },
                { module: 'W10UH',blk: 'M910H',    x: 792.2  ,y: 250.91   ,x1: 71.802 ,y1: 78.059   ,x2: 743.10 ,y2: 221.00 },
                { module: 'W10UH',blk: 'W10UH_P',  x: 0      ,y: 0        ,x1: 4.9500 ,y1: 0.0000   },

                // N10UH
                { module: 'N10UH', blk: 'L610H',   x: 646.62 ,y: 611.68   ,x1: 20.557 ,y1: 6.8520   ,x2: 545.55 ,y2: 551.23 },
                { module: 'N10UH', blk: 'L510H',   x: 580.46 ,y: 663.41   ,x1: 0.4500 ,y1: 31.835   ,x2: 489.00 ,y2: 606.36 },
                { module: 'N10UH', blk: 'L620H',   x: 747.74 ,y: 665.68   ,x1: 90.519 ,y1: 47.678   ,x2: 663.66 ,y2: 615.45 },
                { module: 'N10UH', blk: 'L520H',   x: 688.00 ,y: 715.38   ,x1: 66.185 ,y1: 63.974   ,x2: 602.43 ,y2: 661.90 },
                { module: 'N10UH', blk: 'L630H',   x: 849.39 ,y: 707.94   ,x1: 147.29 ,y1: 74.999   ,x2: 768.59 ,y2: 665.72 },
                { module: 'N10UH', blk: 'L530H',   x: 788.65 ,y: 758.96   ,x1: 123.39 ,y1: 91.635   ,x2: 707.79 ,y2: 712.51 },
                { module: 'N10UH', blk: 'L640H',   x: 968.60 ,y: 758.96   ,x1: 194.89 ,y1: 87.626   ,x2: 864.34 ,y2: 701.75 },
                { module: 'N10UH', blk: 'L540H',   x: 907.05 ,y: 810.02   ,x1: 172.59 ,y1: 116.55   ,x2: 804.69 ,y2: 760.38 },
                { module: 'N10UH', blk: 'E310H',   x: 660.81 ,y: 412.04   ,x1: 44.955 ,y1: 3.1500   ,x2: 609.10 ,y2: 376.98 },
                { module: 'N10UH', blk: 'T710H',   x: 636.30 ,y: 462.66   ,x1: 18.630 ,y1: 3.6340   ,x2: 549.93 ,y2: 403.56 },
                { module: 'N10UH', blk: 'T610H',   x: 582.53 ,y: 502.05   ,x1: 7.0570 ,y1: 18.421   ,x2: 501.45 ,y2: 448.50 },
                { module: 'N10UH', blk: 'E410H',   x: 1002.4 ,y: 569.08   ,x1: 236.99 ,y1: 93.512   ,x2: 937.94 ,y2: 535.29 },
                { module: 'N10UH', blk: 'T720H',   x: 968.60 ,y: 611.68   ,x1: 208.81 ,y1: 93.685   ,x2: 877.36 ,y2: 562.91 },
                { module: 'N10UH', blk: 'T620H',   x: 908.53 ,y: 656.27   ,x1: 186.86 ,y1: 107.43   ,x2: 818.51 ,y2: 606.81 },
                { module: 'N10UH', blk: 'NP10H',   x: 720.57 ,y: 208.36   ,x1: 84.565 ,y1: 7.3350   ,x2: 645.11 ,y2: 173.26 },
                { module: 'N10UH', blk: 'NP20H',   x: 816.84 ,y: 258.30   ,x1: 130.25 ,y1: 21.244   ,x2: 740.30 ,y2: 210.57 },
                { module: 'N10UH', blk: 'M610H',   x: 646.62 ,y: 256.05   ,x1: 54.573 ,y1: 10.690   ,x2: 579.12 ,y2: 206.77 },
                { module: 'N10UH', blk: 'M510H',   x: 580.46 ,y: 308.37   ,x1: 14.355 ,y1: 18.888   ,x2: 502.00 ,y2: 245.11 },
                { module: 'N10UH', blk: 'M620H',   x: 755.28 ,y: 308.37   ,x1: 88.298 ,y1: 30.304   ,x2: 661.44 ,y2: 250.23 },
                { module: 'N10UH', blk: 'M520H',   x: 694.00 ,y: 354.56   ,x1: 64.248 ,y1: 46.895   ,x2: 599.59 ,y2: 296.07 },
                { module: 'N10UH', blk: 'M630H',   x: 855.57 ,y: 354.56   ,x1: 149.89 ,y1: 55.796   ,x2: 770.74 ,y2: 298.67 },
                { module: 'N10UH', blk: 'M530H',   x: 791.28 ,y: 397.60   ,x1: 125.83 ,y1: 76.104   ,x2: 709.78 ,y2: 348.68 },
                { module: 'N10UH', blk: 'M640H',   x: 944.37 ,y: 386.62   ,x1: 200.78 ,y1: 84.766   ,x2: 870.68 ,y2: 351.04 },
                { module: 'N10UH', blk: 'M540H',   x: 905.27 ,y: 440.93   ,x1: 176.87 ,y1: 100.77   ,x2: 808.97 ,y2: 397.65 },
                { module: 'N10UH', blk: 'M950H',   x: 797.14 ,y: 185.37   ,x1: 122.99 ,y1: 7.3350   ,x2: 731.69 ,y2: 150.31 },
                { module: 'N10UH', blk: 'M940H',   x: 701.97 ,y: 139.83   ,x1: 107.83 ,y1: 8.5100   ,x2: 668.83 ,y2: 128.09 },
                { module: 'N10UH', blk: 'M930H',   x: 641.70 ,y: 124.67   ,x1: 94.173 ,y1: 4.5690   ,x2: 607.47 ,y2: 103.00 },
                { module: 'N10UH', blk: 'M960H',   x: 854.20 ,y: 220.91   ,x1: 171.45 ,y1: 30.735   ,x2: 833.25 ,y2: 193.96 },
                { module: 'N10UH', blk: 'N10UH_P', x: 0      ,y: 0        ,x1: 0.0000 ,y1: 0.0000   },

                // S10UH
                { module: 'S10UH', blk: 'L210H',   x: 670.06 ,y: 613.16   ,x1: 30.111 ,y1: 14.502   ,x2: 561.76 ,y2: 563.05 },
                { module: 'S10UH', blk: 'L110H',   x: 580.50 ,y: 684.16   ,x1: 1.4480 ,y1: 38.092   ,x2: 497.54 ,y2: 615.89 },
                { module: 'S10UH', blk: 'L220H',   x: 797.08 ,y: 674.05   ,x1: 98.736 ,y1: 47.834   ,x2: 701.48 ,y2: 631.48 },
                { module: 'S10UH', blk: 'L120H',   x: 715.19 ,y: 743.81   ,x1: 71.331 ,y1: 71.163   ,x2: 638.08 ,y2: 683.61 },
                { module: 'S10UH', blk: 'L230H',   x: 894.91 ,y: 721.76   ,x1: 153.95 ,y1: 74.313   ,x2: 804.85 ,y2: 680.91 },
                { module: 'S10UH', blk: 'L130H',   x: 818.14 ,y: 789.78   ,x1: 127.06 ,y1: 98.121   ,x2: 741.96 ,y2: 733.52 },
                { module: 'S10UH', blk: 'L240H',   x: 1029.2 ,y: 780.62   ,x1: 204.63 ,y1: 98.852   ,x2: 927.08 ,y2: 740.10 },
                { module: 'S10UH', blk: 'L140H',   x: 950.88 ,y: 852.13   ,x1: 177.57 ,y1: 122.68   ,x2: 864.47 ,y2: 792.28 },
                { module: 'S10UH', blk: 'T310H',   x: 784.27 ,y: 403.45   ,x1: 70.807 ,y1: 23.210   ,x2: 688.85 ,y2: 345.41 },
                { module: 'S10UH', blk: 'T320H',   x: 898.73 ,y: 451.19   ,x1: 139.55 ,y1: 56.060   ,x2: 805.75 ,y2: 401.66 },
                { module: 'S10UH', blk: 'T210H',   x: 651.06 ,y: 403.45   ,x1: 30.111 ,y1: 28.205   ,x2: 561.76 ,y2: 357.15 },
                { module: 'S10UH', blk: 'T110H',   x: 586.91 ,y: 451.19   ,x1: 0.0580 ,y1: 35.835   ,x2: 497.50 ,y2: 393.13 },
                { module: 'S10UH', blk: 'E110H',   x: 524.07 ,y: 482.58   ,x1: 0.5500 ,y1: 56.787   ,x2: 462.00 ,y2: 444.23 },
                { module: 'S10UH', blk: 'T220H',   x: 782.15 ,y: 448.77   ,x1: 85.207 ,y1: 53.286   ,x2: 688.40 ,y2: 416.43 },
                { module: 'S10UH', blk: 'T230H',   x: 889.51 ,y: 497.98   ,x1: 141.45 ,y1: 80.286   ,x2: 792.35 ,y2: 466.83 },
                { module: 'S10UH', blk: 'T120H',   x: 721.61 ,y: 510.22   ,x1: 66.831 ,y1: 69.036   ,x2: 635.38 ,y2: 460.98 },
                { module: 'S10UH', blk: 'T130H',   x: 827.78 ,y: 560.92   ,x1: 124.13 ,y1: 96.264   ,x2: 740.38 ,y2: 511.16 },
                { module: 'S10UH', blk: 'T240H',   x: 1015.6 ,y: 572.70   ,x1: 202.57 ,y1: 108.57   ,x2: 924.57 ,y2: 529.32 },
                { module: 'S10UH', blk: 'T140H',   x: 961.89 ,y: 620.58   ,x1: 182.52 ,y1: 123.14   ,x2: 870.32 ,y2: 572.24 },
                { module: 'S10UH', blk: 'E210H',   x: 898.73 ,y: 663.33   ,x1: 201.28 ,y1: 151.92   ,x2: 852.18 ,y2: 631.17 },
                { module: 'S10UH', blk: 'M210H',   x: 665.83 ,y: 110.64   ,x1: 39.064 ,y1: 2.0000   ,x2: 570.81 ,y2: 66.000 },
                { module: 'S10UH', blk: 'M110H',   x: 590.57 ,y: 174.13   ,x1: 23.541 ,y1: 25.724   ,x2: 519.74 ,y2: 118.52 },
                { module: 'S10UH', blk: 'M220H',   x: 762.54 ,y: 157.56   ,x1: 89.411 ,y1: 27.937   ,x2: 669.31 ,y2: 113.98 },
                { module: 'S10UH', blk: 'M120H',   x: 682.02 ,y: 220.22   ,x1: 55.481 ,y1: 50.180   ,x2: 600.28 ,y2: 165.93 },
                { module: 'S10UH', blk: 'M230H',   x: 854.03 ,y: 200.78   ,x1: 139.64 ,y1: 52.958   ,x2: 767.59 ,y2: 161.85 },
                { module: 'S10UH', blk: 'M130H',   x: 778.11 ,y: 265.72   ,x1: 105.90 ,y1: 75.141   ,x2: 698.30 ,y2: 213.29 },
                { module: 'S10UH', blk: 'M240H',   x: 949.04 ,y: 249.55   ,x1: 183.58 ,y1: 75.008   ,x2: 860.13 ,y2: 206.40 },
                { module: 'S10UH', blk: 'M140H',   x: 875.72 ,y: 312.19   ,x1: 157.63 ,y1: 97.097   ,x2: 797.73 ,y2: 257.74 },
                { module: 'S10UH', blk: 'M250H',   x: 1020.8 ,y: 298.34   ,x1: 233.39 ,y1: 98.934   ,x2: 958.09 ,y2: 253.28 },
                { module: 'S10UH', blk: 'M150H',   x: 959.13 ,y: 347.51   ,x1: 207.52 ,y1: 121.93   ,x2: 895.77 ,y2: 305.08 },
                { module: 'S10UH', blk: 'SP10H',   x: 651.06 ,y: 265.72   ,x1: 33.398 ,y1: 74.849   ,x2: 551.99 ,y2: 213.89 },
                { module: 'S10UH', blk: 'M970H',   x: 522.86 ,y: 299.48   ,x1: 76.258 ,y1: 84.599   ,x2: 489.85 ,y2: 258.89 },
                { module: 'S10UH', blk: 'M980H',   x: 582.34 ,y: 318.35   ,x1: 92.866 ,y1: 97.172   ,x2: 548.76 ,y2: 287.22 },
                { module: 'S10UH', blk: 'S10UH_P', x: 0      ,y: 0        ,x1: 0.0000 ,y1: 0.0000   },

                // LQ1UH
                { module: 'LQ1UH', blk: 'Q150H',   x: 779.87 ,y: 641.54   ,x1: 66.872 ,y1: 14.200   ,x2: 728.07 ,y2: 632.40 },
                { module: 'LQ1UH', blk: 'Q140H',   x: 833.19 ,y: 674.27   ,x1: 85.508 ,y1: 22.075   ,x2: 770.10 ,y2: 651.07 },
                { module: 'LQ1UH', blk: 'Q110H',   x: 664.35 ,y: 719.92   ,x1: 12.447 ,y1: 38.697   ,x2: 598.04 ,y2: 718.09 },
                { module: 'LQ1UH', blk: 'Q130H',   x: 779.67 ,y: 721.75   ,x1: 56.448 ,y1: 39.521   ,x2: 705.04 ,y2: 698.22 },
                { module: 'LQ1UH', blk: 'Q120H',   x: 708.43 ,y: 774.13   ,x1: 33.750 ,y1: 66.936   ,x2: 642.75 ,y2: 757.58 },
                { module: 'LQ1UH', blk: 'Q160H',   x: 892.25 ,y: 701.92   ,x1: 128.70 ,y1: 37.674   ,x2: 860.10 ,y2: 689.17 },
                { module: 'LQ1UH', blk: 'Q240H',   x: 785.15 ,y: 494.78   ,x1: 50.072 ,y1: 5.4030   ,x2: 711.27 ,y2: 484.55 },
                { module: 'LQ1UH', blk: 'Q230H',   x: 831.41 ,y: 518.50   ,x1: 76.322 ,y1: 18.315   ,x2: 760.92 ,y2: 508.37 },
                { module: 'LQ1UH', blk: 'Q250H',   x: 723.87 ,y: 551.88   ,x1: 28.894 ,y1: 33.303   ,x2: 661.74 ,y2: 534.50 },
                { module: 'LQ1UH', blk: 'Q210H',   x: 664.35 ,y: 602.37   ,x1: 2.2500 ,y1: 45.744   ,x2: 591.00 ,y2: 582.94 },
                { module: 'LQ1UH', blk: 'Q220H',   x: 728.32 ,y: 617.30   ,x1: 36.544 ,y1: 45.378   ,x2: 648.69 ,y2: 594.27 },
                { module: 'LQ1UH', blk: 'Q330H',   x: 774.95 ,y: 356.16   ,x1: 37.695 ,y1: 5.7390   ,x2: 699.34 ,y2: 346.28 },
                { module: 'LQ1UH', blk: 'Q340H',   x: 831.80 ,y: 385.42   ,x1: 64.022 ,y1: 18.591   ,x2: 749.07 ,y2: 370.39 },
                { module: 'LQ1UH', blk: 'Q310H',   x: 671.66 ,y: 441.69   ,x1: 0.5360 ,y1: 34.927   ,x2: 592.88 ,y2: 430.37 },
                { module: 'LQ1UH', blk: 'Q320H',   x: 717.18 ,y: 466.77   ,x1: 36.134 ,y1: 47.527   ,x2: 651.43 ,y2: 454.22 },
                { module: 'LQ1UH', blk: 'Q420H',   x: 762.25 ,y: 218.51   ,x1: 26.313 ,y1: 6.8320   ,x2: 687.96 ,y2: 208.33 },
                { module: 'LQ1UH', blk: 'Q430H',   x: 806.75 ,y: 240.19   ,x1: 47.822 ,y1: 17.530   ,x2: 733.32 ,y2: 230.28 },
                { module: 'LQ1UH', blk: 'Q410H',   x: 706.14 ,y: 333.21   ,x1: 9.4850 ,y1: 34.858   ,x2: 621.63 ,y2: 306.55 },
                { module: 'LQ1UH', blk: 'Q510H',   x: 816.61 ,y: 166.92   ,x1: 99.550 ,y1: 22.275   ,x2: 785.44 ,y2: 144.10 },
                { module: 'LQ1UH', blk: 'Q520H',   x: 791.33 ,y: 183.63   ,x1: 65.522 ,y1: 50.397   ,x2: 752.37 ,y2: 171.34 },
                { module: 'LQ1UH', blk: 'HD30H',   x: 652.37 ,y: 151.43   ,x1: 24.738 ,y1: 9.9000   ,x2: 668.38 ,y2: 125.00 },
                { module: 'LQ1UH', blk: 'LQ1UH_P', x: 0      ,y: 0        ,x1: 4.5900 ,y1: 0.0000   },


                // HD1UH
                { module: 'HD1UH', blk: 'HD20H',   x: 798.14 ,y: 515.29  ,x1: 17.510  ,y1: 23.227  ,x2: 708.51 ,y2: 473.72 },
                { module: 'HD1UH', blk: 'HD10H',   x: 798.14 ,y: 415.21  ,x1: 0.0000  ,y1: 0.0000  ,x2: 691.00 ,y2: 383.00 },
      ],


      
      Topside: [
              // CM1UT
              { module: 'CM1UT', blk: 'B410V'   ,x: 811.11  ,y: 719.84    ,x1: 111.77  ,y1: 50.543    ,x2: 760.82 ,y2: 672.81 },
              { module: 'CM1UT', blk: 'B110T'   ,x: 594.79  ,y: 729.10    ,x1: 3.2760  ,y1: 49.695    ,x2: 584.82 ,y2: 668.06 },
              { module: 'CM1UT', blk: 'B120T'   ,x: 696.63  ,y: 781.47    ,x1: 40.212  ,y1: 62.729    ,x2: 688.06 ,y2: 713.20 },
              { module: 'CM1UT', blk: 'B130T'   ,x: 800.33  ,y: 827.37    ,x1: 75.394  ,y1: 84.887    ,x2: 790.14 ,y2: 767.46 },
              { module: 'CM1UT', blk: 'B110F'   ,x: 673.96  ,y: 735.48    ,x1: 55.815  ,y1: 97.739    ,x2: 682.96 ,y2: 716.11 },
              { module: 'CM1UT', blk: 'B120F'   ,x: 726.46  ,y: 701.18    ,x1: 58.365  ,y1: 92.393    ,x2: 685.81 ,y2: 691.86 },
              { module: 'CM1UT', blk: 'B130F'   ,x: 874.23  ,y: 760.51    ,x1: 121.64  ,y1: 81.539    ,x2: 835.49 ,y2: 744.01 },
              { module: 'CM1UT', blk: 'B210T'   ,x: 663.44  ,y: 541.19    ,x1: 23.235  ,y1: 33.228    ,x2: 604.78 ,y2: 487.50 },
              { module: 'CM1UT', blk: 'B220T'   ,x: 763.29  ,y: 586.57    ,x1: 56.496  ,y1: 49.565    ,x2: 704.94 ,y2: 535.93 },
              { module: 'CM1UT', blk: 'B230T'   ,x: 897.10  ,y: 620.53    ,x1: 94.640  ,y1: 66.894    ,x2: 826.19 ,y2: 571.56 },
              { module: 'CM1UT', blk: 'B240T'   ,x: 823.07  ,y: 644.63    ,x1: 79.829  ,y1: 89.244    ,x2: 794.27 ,y2: 607.71 },
              { module: 'CM1UT', blk: 'B250F'   ,x: 661.99  ,y: 469.91    ,x1: 28.300  ,y1: 37.344    ,x2: 610.45 ,y2: 459.21 },
              { module: 'CM1UT', blk: 'B230F'   ,x: 780.66  ,y: 491.94    ,x1: 75.158  ,y1: 46.339    ,x2: 723.30 ,y2: 467.61 },
              { module: 'CM1UT', blk: 'B220F'   ,x: 771.84  ,y: 509.22    ,x1: 64.222  ,y1: 55.890    ,x2: 711.47 ,y2: 486.76 },
              { module: 'CM1UT', blk: 'B210F'   ,x: 766.14  ,y: 533.40    ,x1: 55.965  ,y1: 62.414    ,x2: 705.31 ,y2: 505.58 },
              { module: 'CM1UT', blk: 'B240F'   ,x: 888.67  ,y: 567.97    ,x1: 115.09  ,y1: 65.315    ,x2: 846.04 ,y2: 548.38 },
              { module: 'CM1UT', blk: 'B310R'   ,x: 653.07  ,y: 389.11    ,x1: 13.450  ,y1: 49.405    ,x2: 589.90 ,y2: 338.37 },
              { module: 'CM1UT', blk: 'B320R'   ,x: 770.15  ,y: 440.84    ,x1: 50.946  ,y1: 72.205    ,x2: 696.69 ,y2: 392.97 },
              { module: 'CM1UT', blk: 'B310G'   ,x: 852.80  ,y: 470.04    ,x1: 97.894  ,y1: 81.852    ,x2: 812.94 ,y2: 445.52 },
              { module: 'CM1UT', blk: 'B310T'   ,x: 639.31  ,y: 331.22    ,x1: 1.4500  ,y1: 0.0000    ,x2: 583.00 ,y2: 245.77 },
              { module: 'CM1UT', blk: 'B320T'   ,x: 749.98  ,y: 377.77    ,x1: 40.746  ,y1: 34.331    ,x2: 688.29 ,y2: 312.50 },
              { module: 'CM1UT', blk: 'B330T'   ,x: 854.48  ,y: 431.86    ,x1: 72.994  ,y1: 49.476    ,x2: 787.44 ,y2: 360.65 },
              { module: 'CM1UT', blk: 'B410E'   ,x: 668.56  ,y: 224.75    ,x1: 8.7000  ,y1: 9.4000    ,x2: 588.85 ,y2: 200.77 },
              { module: 'CM1UT', blk: 'B420E'   ,x: 655.94  ,y: 189.19    ,x1: 37.383  ,y1: 13.625    ,x2: 617.83 ,y2: 172.00 },
              { module: 'CM1UT', blk: 'B310F'   ,x: 776.92  ,y: 297.23    ,x1: 55.901  ,y1: 24.400    ,x2: 703.55 ,y2: 269.17 },
              { module: 'CM1UT', blk: 'B320F'   ,x: 871.35  ,y: 335.93    ,x1: 114.36  ,y1: 47.889    ,x2: 833.01 ,y2: 316.46 },
              { module: 'CM1UT', blk: 'B330F'   ,x: 871.35  ,y: 298.46    ,x1: 114.36  ,y1: 43.950    ,x2: 833.01 ,y2: 280.12 },
              { module: 'CM1UT', blk: 'BR20T'   ,x: 909.03  ,y: 428.67    ,x1: 115.91  ,y1: 40.232    ,x2: 874.46 ,y2: 370.90 },
              { module: 'CM1UT', blk: 'CM1UT_P'                           ,x1: 0.0000  ,y1: 2.4000                            },

              // FB1UT
              { module: 'FB1UT', blk: 'F110T'   ,x: 701.45  ,y: 550.23    ,x1: 0.0000  ,y1: 99.721    ,x2:653.00  ,y2: 489.12 },
              { module: 'FB1UT', blk: 'F120T'   ,x: 755.68  ,y: 474.60    ,x1: 45.412  ,y1: 50.011    ,x2:699.61  ,y2: 416.61 },
              { module: 'FB1UT', blk: 'F130T'   ,x: 809.97  ,y: 397.25    ,x1: 103.58  ,y1: 0.0000    ,x2:757.19  ,y2: 345.00 },
              { module: 'FB1UT', blk: 'FB1UT_P'                           ,x1: 1.8500  ,y1: 3.5000                            },

              // GM1UT
              { module: 'GM1UT', blk: 'G110T'   ,x: 786.49 ,y: 658.52     ,x1: 34.488  ,y1: 36.890    ,x2: 685.20 ,y2: 634.79 },
              { module: 'GM1UT', blk: 'G130T'   ,x: 671.54 ,y: 767.72     ,x1: 0.0000  ,y1: 58.979    ,x2: 583.02 ,y2: 712.07 },
              { module: 'GM1UT', blk: 'G120T'   ,x: 908.08 ,y: 713.19     ,x1: 92.832  ,y1: 67.657    ,x2: 832.05 ,y2: 707.85 },
              { module: 'GM1UT', blk: 'G140T'   ,x: 790.47 ,y: 828.49     ,x1: 64.415  ,y1: 88.092    ,x2: 734.93 ,y2: 783.49 },
              { module: 'GM1UT', blk: 'G110F'   ,x: 686.58 ,y: 704.70     ,x1: 56.886  ,y1: 65.128    ,x2: 635.30 ,y2: 677.72 },
              { module: 'GM1UT', blk: 'G120F'   ,x: 751.04 ,y: 737.28     ,x1: 76.814  ,y1: 76.276    ,x2: 744.03 ,y2: 730.27 },
              { module: 'GM1UT', blk: 'G130F'   ,x: 814.68 ,y: 764.50     ,x1: 98.315  ,y1: 86.398    ,x2: 765.53 ,y2: 740.39 },
              { module: 'GM1UT', blk: 'G220R'   ,x: 651.51 ,y: 305.12     ,x1: 59.328  ,y1: 14.568    ,x2: 666.24 ,y2: 295.66 },
              { module: 'GM1UT', blk: 'G250T'   ,x: 642.64 ,y: 221.63     ,x1: 46.455  ,y1: 0.0000    ,x2: 655.47 ,y2: 209.40 },
              { module: 'GM1UT', blk: 'G240F'   ,x: 671.07 ,y: 630.99     ,x1: 63.253  ,y1: 41.840    ,x2: 644.67 ,y2: 628.04 },
              { module: 'GM1UT', blk: 'G110E'   ,x: 575.32 ,y: 681.93     ,x1: 12.100  ,y1: 36.275    ,x2: 592.02 ,y2: 645.87 },
              { module: 'GM1UT', blk: 'G120E'   ,x: 575.29 ,y: 622.35     ,x1: 12.166  ,y1: 23.688    ,x2: 592.08 ,y2: 589.78 },
              { module: 'GM1UT', blk: 'G230F'   ,x: 655.88 ,y: 571.54     ,x1: 49.186  ,y1: 33.523    ,x2: 626.70 ,y2: 565.72 },
              { module: 'GM1UT', blk: 'G210R'   ,x: 908.04 ,y: 415.45     ,x1: 87.381  ,y1: 35.186    ,x2: 823.90 ,y2: 372.08 },
              { module: 'GM1UT', blk: 'G230R'   ,x: 662.65 ,y: 487.15     ,x1: 54.291  ,y1: 36.526    ,x2: 675.61 ,y2: 457.42 },
              { module: 'GM1UT', blk: 'G210G'   ,x: 669.44 ,y: 439.86     ,x1: 70.491  ,y1: 42.559    ,x2: 682.51 ,y2: 427.45 },
              { module: 'GM1UT', blk: 'G240R'   ,x: 721.14 ,y: 506.26     ,x1: 75.586  ,y1: 51.476    ,x2: 693.00 ,y2: 468.47 },
              { module: 'GM1UT', blk: 'G220G'   ,x: 792.03 ,y: 462.09     ,x1: 88.364  ,y1: 54.176    ,x2: 744.78 ,y2: 458.57 },
              { module: 'GM1UT', blk: 'G250R'   ,x: 812.18 ,y: 497.55     ,x1: 91.214  ,y1: 62.032    ,x2: 759.33 ,y2: 499.13 },
              { module: 'GM1UT', blk: 'G230G'   ,x: 813.70 ,y: 474.85     ,x1: 108.96  ,y1: 63.483    ,x2: 765.68 ,y2: 468.48 },
              { module: 'GM1UT', blk: 'G290R'   ,x: 660.65 ,y: 521.38     ,x1: 50.955  ,y1: 67.230    ,x2: 674.07 ,y2: 509.73 },
              { module: 'GM1UT', blk: 'G210T'   ,x: 830.08 ,y: 280.60     ,x1: 64.684  ,y1: 19.948    ,x2: 755.90 ,y2: 266.84 },
              { module: 'GM1UT', blk: 'G220T'   ,x: 920.08 ,y: 319.03     ,x1: 94.726  ,y1: 33.182    ,x2: 834.54 ,y2: 301.38 },
              { module: 'GM1UT', blk: 'G260T'   ,x: 532.00 ,y: 333.55     ,x1: 5.9680  ,y1: 40.876    ,x2: 545.38 ,y2: 303.97 },
              { module: 'GM1UT', blk: 'G230T'   ,x: 731.17 ,y: 374.24     ,x1: 10.278  ,y1: 41.776    ,x2: 636.39 ,y2: 344.77 },
              { module: 'GM1UT', blk: 'G240T'   ,x: 815.79 ,y: 419.47     ,x1: 62.615  ,y1: 55.426    ,x2: 734.33 ,y2: 379.42 },
              { module: 'GM1UT', blk: 'G280R'   ,x: 665.46 ,y: 541.34     ,x1: 55.641  ,y1: 81.569    ,x2: 679.96 ,y2: 530.06 },
              { module: 'GM1UT', blk: 'G270R'   ,x: 785.41 ,y: 584.04     ,x1: 65.779  ,y1: 85.340    ,x2: 729.09 ,y2: 558.74 },
              { module: 'GM1UT', blk: 'G260R'   ,x: 839.23 ,y: 514.98     ,x1: 110.74  ,y1: 71.219    ,x2: 783.36 ,y2: 509.51 },
              { module: 'GM1UT', blk: 'G210F'   ,x: 911.47 ,y: 259.97     ,x1: 117.38  ,y1: 29.449    ,x2: 857.50 ,y2: 253.84 },
              { module: 'GM1UT', blk: 'G220F'   ,x: 905.17 ,y: 214.04     ,x1: 122.78  ,y1: 23.400    ,x2: 862.90 ,y2: 204.00 },
              { module: 'GM1UT', blk: 'G320V'   ,x: 949.74 ,y: 759.70     ,x1: 134.74  ,y1: 64.560    ,x2: 908.16 ,y2: 720.75 },
              { module: 'GM1UT', blk: 'G310V'   ,x: 613.06 ,y: 784.98     ,x1: 18.979  ,y1: 62.850    ,x2: 568.00 ,y2: 743.65 },
              { module: 'GM1UT', blk: 'GM1UT_P'                           ,x1: 0.3500  ,y1: 2.9000                            },

              // PM1UT
              { module: 'PM1UT', blk: 'A410V'   ,x: 738.91 ,y: 669.94     ,x1: 55.650  ,y1: 29.870    ,x2: 686.86 ,y2: 618.99 },
              { module: 'PM1UT', blk: 'A110T'   ,x: 666.26 ,y: 746.43     ,x1: 12.150  ,y1: 62.889    ,x2: 608.26 ,y2: 679.01 },
              { module: 'PM1UT', blk: 'A120T'   ,x: 759.73 ,y: 798.81     ,x1: 52.481  ,y1: 86.847    ,x2: 715.19 ,y2: 735.07 },
              { module: 'PM1UT', blk: 'A130T'   ,x: 878.81 ,y: 846.19     ,x1: 91.260  ,y1: 105.66    ,x2: 821.17 ,y2: 786.29 },
              { module: 'PM1UT', blk: 'A110F'   ,x: 624.73 ,y: 660.34     ,x1: 43.200  ,y1: 59.352    ,x2: 641.11 ,y2: 646.07 },
              { module: 'PM1UT', blk: 'A120F'   ,x: 784.73 ,y: 723.50     ,x1: 84.599  ,y1: 71.442    ,x2: 750.01 ,y2: 700.46 },
              { module: 'PM1UT', blk: 'A210G'   ,x: 776.41 ,y: 617.43     ,x1: 75.299  ,y1: 86.503    ,x2: 723.61 ,y2: 588.93 },
              { module: 'PM1UT', blk: 'A220G'   ,x: 868.48 ,y: 662.44     ,x1: 108.38  ,y1: 108.25    ,x2: 823.29 ,y2: 643.07 },
              { module: 'PM1UT', blk: 'A210T'   ,x: 696.49 ,y: 506.55     ,x1: 12.750  ,y1: 49.165    ,x2: 608.86 ,y2: 489.49 },
              { module: 'PM1UT', blk: 'A240T'   ,x: 821.59 ,y: 540.46     ,x1: 81.279  ,y1: 57.899    ,x2: 761.09 ,y2: 516.82 },
              { module: 'PM1UT', blk: 'A220T'   ,x: 786.25 ,y: 568.72     ,x1: 58.799  ,y1: 66.565    ,x2: 721.51 ,y2: 538.99 },
              { module: 'PM1UT', blk: 'A230T'   ,x: 918.21 ,y: 614.97     ,x1: 100.86  ,y1: 82.413    ,x2: 830.17 ,y2: 587.24 },
              { module: 'PM1UT', blk: 'A210F'   ,x: 670.68 ,y: 452.75     ,x1: 14.400  ,y1: 66.759    ,x2: 611.41 ,y2: 440.78 },
              { module: 'PM1UT', blk: 'A310R'   ,x: 778.25 ,y: 443.40     ,x1: 39.127  ,y1: 79.659    ,x2: 712.33 ,y2: 402.68 },
              { module: 'PM1UT', blk: 'A320R'   ,x: 889.98 ,y: 494.30     ,x1: 78.385  ,y1: 101.21    ,x2: 820.89 ,y2: 458.44 },
              { module: 'PM1UT', blk: 'A310G'   ,x: 886.14 ,y: 456.19     ,x1: 102.98  ,y1: 96.909    ,x2: 853.29 ,y2: 443.33 },
              { module: 'PM1UT', blk: 'A310T'   ,x: 705.19 ,y: 283.02     ,x1: 9.4500  ,y1: 29.957    ,x2: 605.56 ,y2: 250.68 },
              { module: 'PM1UT', blk: 'A320T'   ,x: 815.61 ,y: 334.05     ,x1: 44.460  ,y1: 53.626    ,x2: 707.17 ,y2: 306.75 },
              { module: 'PM1UT', blk: 'BR10T'   ,x: 939.94 ,y: 341.85     ,x1: 144.96  ,y1: 44.926    ,x2: 903.67 ,y2: 304.65 },
              { module: 'PM1UT', blk: 'A330T'   ,x: 922.41 ,y: 379.00     ,x1: 86.938  ,y1: 72.419    ,x2: 815.64 ,y2: 357.64 },
              { module: 'PM1UT', blk: 'A330F'   ,x: 597.17 ,y: 248.51     ,x1: 16.500  ,y1: 37.909    ,x2: 612.31 ,y2: 235.53 },
              { module: 'PM1UT', blk: 'A310F'   ,x: 704.00 ,y: 179.75     ,x1: 24.750  ,y1: 28.950    ,x2: 620.56 ,y2: 162.07 },
              { module: 'PM1UT', blk: 'A320F'   ,x: 807.20 ,y: 249.43     ,x1: 66.899  ,y1: 51.596    ,x2: 727.21 ,y2: 230.02 },
              { module: 'PM1UT', blk: 'A340F'   ,x: 704.00 ,y: 114.89     ,x1: 46.350  ,y1: 33.873    ,x2: 641.86 ,y2: 101.00 },
              { module: 'PM1UT', blk: 'CP10T'   ,x: 597.21 ,y: 209.11     ,x1: 15.750  ,y1: 39.900    ,x2: 612.16 ,y2: 194.32 },
              { module: 'PM1UT', blk: 'A350F'   ,x: 582.00 ,y: 136.14     ,x1: 0.0000  ,y1: 30.823    ,x2: 596.71 ,y2: 120.05 },
              { module: 'PM1UT', blk: 'A420V'   ,x: 837.70 ,y: 877.22     ,x1: 99.360  ,y1: 117.51    ,x2: 795.07 ,y2: 825.74 },
              { module: 'PM1UT', blk: 'PM1UT_P'                           ,x1: 0.0000  ,y1: 0.000                            },

              // WM1UT
              { module: 'WM1UT', blk: 'W110T'   ,x: 776.54 ,y: 660.55     ,x1: 45.687  ,y1: 86.976    ,x2: 686.68 ,y2: 642.50 },
              { module: 'WM1UT', blk: 'W130T'   ,x: 648.53 ,y: 779.72     ,x1: 11.991  ,y1: 114.56    ,x2: 585.18 ,y2: 725.59 },
              { module: 'WM1UT', blk: 'W120T'   ,x: 921.34 ,y: 739.93     ,x1: 83.642  ,y1: 105.13    ,x2: 825.13 ,y2: 708.66 },
              { module: 'WM1UT', blk: 'W140T'   ,x: 795.64 ,y: 846.88     ,x1: 49.442  ,y1: 134.03    ,x2: 722.83 ,y2: 793.36 },
              { module: 'WM1UT', blk: 'W110F'   ,x: 660.17 ,y: 713.52     ,x1: 23.691  ,y1: 108.38    ,x2: 596.88 ,y2: 676.81 },
              { module: 'WM1UT', blk: 'W210T'   ,x: 770.42 ,y: 554.08     ,x1: 59.592  ,y1: 77.814    ,x2: 700.58 ,y2: 533.74 },
              { module: 'WM1UT', blk: 'W220T'   ,x: 904.46 ,y: 625.30     ,x1: 91.742  ,y1: 91.326    ,x2: 833.23 ,y2: 596.15 },
              { module: 'WM1UT', blk: 'W230T'   ,x: 816.86 ,y: 694.86     ,x1: 52.237  ,y1: 114.98    ,x2: 724.63 ,y2: 674.01 },
              { module: 'WM1UT', blk: 'W310R'   ,x: 679.20 ,y: 388.35     ,x1: 39.192  ,y1: 51.813    ,x2: 692.18 ,y2: 347.24 },
              { module: 'WM1UT', blk: 'W330R'   ,x: 694.72 ,y: 431.41     ,x1: 52.692  ,y1: 97.797    ,x2: 712.58 ,y2: 402.22 },
              { module: 'WM1UT', blk: 'W320R'   ,x: 572.24 ,y: 477.04     ,x1: 3.0100  ,y1: 75.946    ,x2: 587.00 ,y2: 427.47 },
              { module: 'WM1UT', blk: 'W310G'   ,x: 913.93 ,y: 418.50     ,x1: 139.29  ,y1: 86.117    ,x2: 879.88 ,y2: 403.74 },
              { module: 'WM1UT', blk: 'W310T'   ,x: 774.64 ,y: 302.74     ,x1: 33.837  ,y1: 49.271    ,x2: 676.03 ,y2: 274.19 },
              { module: 'WM1UT', blk: 'W330T'   ,x: 559.00 ,y: 389.22     ,x1: 3.8000  ,y1: 78.046    ,x2: 575.99 ,y2: 358.77 },
              { module: 'WM1UT', blk: 'W320T'   ,x: 911.76 ,y: 365.44     ,x1: 84.142  ,y1: 71.471    ,x2: 823.93 ,y2: 344.99 },
              { module: 'WM1UT', blk: 'W340T'   ,x: 816.68 ,y: 458.65     ,x1: 50.587  ,y1: 101.65    ,x2: 722.98 ,y2: 429.98 },
              { module: 'WM1UT', blk: 'W360F'   ,x: 819.47 ,y: 205.66     ,x1: 103.79  ,y1: 89.200    ,x2: 837.79 ,y2: 194.12 },
              { module: 'WM1UT', blk: 'W410E'   ,x: 584.11 ,y: 249.12     ,x1: 30.542  ,y1: 76.940    ,x2: 603.73 ,y2: 214.56 },
              { module: 'WM1UT', blk: 'W380F'   ,x: 617.64 ,y: 153.68     ,x1: 64.442  ,y1: 84.772    ,x2: 682.93 ,y2: 206.57 },
              { module: 'WM1UT', blk: 'W310F'   ,x: 697.43 ,y: 203.52     ,x1: 41.642  ,y1: 47.950    ,x2: 682.93 ,y2: 206.57 },
              { module: 'WM1UT', blk: 'W420E'   ,x: 593.38 ,y: 190.65     ,x1: 39.839  ,y1: 66.972    ,x2: 611.63 ,y2: 171.70 },
              { module: 'WM1UT', blk: 'W320F'   ,x: 894.84 ,y: 267.37     ,x1: 90.294  ,y1: 50.540    ,x2: 835.99 ,y2: 240.66 },
              { module: 'WM1UT', blk: 'W330F'   ,x: 883.19 ,y: 300.94     ,x1: 82.892  ,y1: 61.903    ,x2: 827.68 ,y2: 272.73 },
              { module: 'WM1UT', blk: 'W340F'   ,x: 901.16 ,y: 225.26     ,x1: 113.09  ,y1: 78.140    ,x2: 854.59 ,y2: 215.16 },
              { module: 'WM1UT', blk: 'W350F'   ,x: 901.22 ,y: 178.16     ,x1: 113.39  ,y1: 72.072    ,x2: 855.79 ,y2: 163.30 },
              { module: 'WM1UT', blk: 'W370F'   ,x: 823.07 ,y: 155.48     ,x1: 103.79  ,y1: 84.772    ,x2: 837.79 ,y2: 143.20 },
              { module: 'WM1UT', blk: 'CP20T'   ,x: 780.54 ,y: 263.33     ,x1: 61.487  ,y1: 105.56    ,x2: 732.88 ,y2: 229.59 },
              { module: 'WM1UT', blk: 'W390F'   ,x: 754.13 ,y: 183.98     ,x1: 65.424  ,y1: 99.192    ,x2: 737.82 ,y2: 192.22 },
              { module: 'WM1UT', blk: 'WM1UT_P'                           ,x1: 0.0000  ,y1: 0.0000                            },
      ],
    },  
    
    
  }),

  computed: {
  },

  methods: {
    setStyles() {

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
      
    },

  }
}