<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'        

export default {
  name: "j-chart-project-1",
  mixins: [mx_Core],
  props: {
    id: String,

    FilterValues: {
      type: Object,
      default: () => ({})
    },

    // Chart Data
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    Queries: {type: Object, default: () => ({}) },

    // Database
    DataItems: { type: Array, default: () => ([]) },
    ColumnProps: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({
        milestone: [],
        note: '',
      })
    }

  },


  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    // console.log('this.id:', this.localId)
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.set_LocalValues()
      this.drawCanvas()
      this.drawTexts()

      this.drawing()

      this.complete()
    },

    drawing() {
      this.draw_Summary()
    },

  }
}
</script>