import * as d3 from 'd3'
import __M from 'moment'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    DataTable_01(selection, _data, _style) {

      let _self = this
      let column  =  _style.head1.filter(f=> f.col == 'Y').concat( _style.head2.filter(f=> f.col == 'Y'))
      let field = ['NO', 'SUBSYSTEM', 'MC_PLAN', 'PA_TOTAL', 'PA_REMAIN', 'PB_TOTAL', 'PB_REMAIN']

      let dataList = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      dataList
      .append('line')
      .attr('x1', 0).attr('x2', _style.table.width).attr('y1', 0).attr('y2', 0)
      .attr('stroke', _style.table.sColor).attr('stroke-width', _style.table.sWidth)

      dataList
      .append('line')
      .attr('x1', 0).attr('x2', _style.table.width).attr('y1', _style.table.head).attr('y2', _style.table.head)
      .attr('stroke', _style.table.sColor).attr('stroke-width', _style.table.sWidth)

      _style.head1.forEach(d => {
        dataList
        .append('text')
        .attr('transform', `translate(${d.x}, ${d.y})`)
        .style('font-family', 'roboto').style('font-size', d.tSize).style('fill', d.tColor).attr('text-anchor', d.align).attr('alignment-baseline', 'middle')
        .text(d.name)
      })
      _style.head2.forEach(d => {
        dataList
        .append('text')
        .attr('transform', `translate(${d.x}, ${d.y})`)
        .style('font-family', 'roboto').style('font-size', d.tSize).style('fill', d.tColor).attr('text-anchor', d.align).attr('alignment-baseline', 'middle')
        .text(d.name)
      })

      let dwarY = _style.table.dwarY
      this.dataSet.forEach((d,i)=> {

        let dataRow = dataList
        .append('g')
        .attr('transform', `translate(0,0)`)
        .on('mouseover', function() {
          d3.select(`#${_style.id}_${i}`).transition().duration(100).style('fill', '#F4F5F5')
        })
        .on('mouseout', function(){
          d3.select(`#${_style.id}_${i}`).transition().duration(100).style('fill', '#fff')
        })

        if(i > _style.table.no-1) return
        d.y = dwarY


        dataRow
        .append('rect')
        .attr('id', `${_style.id}_${i}`)
        .attr('transform', `translate(0,${dwarY - (_style.table.lineHeight/2) - 1})`)
        .attr('width', _style.table.width).attr('height', _style.table.lineHeight - 1).attr('stroke-width', 0).attr('stroke', '#fff')
        .attr('fill', '#fff')
      

        column.forEach((c,j) => {
          dataRow
          .append('text')
          .attr('id', `CELL_${i}_${j}`)
          .attr('transform', `translate(${c.x}, ${d.y})`)
          .style('font-family', 'roboto').style('font-size', c.dSize).style('fill', c.dColor).attr('text-anchor', c.align).attr('alignment-baseline', 'middle')
          .text(d[field[j]])

          .style('cursor', ()=> { if(c.link == 'N') return 'default'; else return 'pointer'})
          .on('mouseover', function() {
            if(c.link == 'N') return
            let node = _self.getNodeElValue(`#CELL_${i}_${j}`,'width') / 5.5

            d3.select(this).transition().duration(100)
            .attr('transform', `translate(${c.x + node}, ${d.y}) scale(1.3)`).style('fill', '#EC407A')
          })
          .on('mouseout', function(){
            if(c.link == 'N') return

            d3.select(this).transition().duration(100)
            .attr('transform', `translate(${c.x}, ${d.y}) scale(1)`).style('fill', c.dColor)
          })
          .on('click', () => { 
            if(c.link == 'N') return

            //
          })
        })

        dataList
        .append('line')
        .attr('x1', 0).attr('x2', _style.table.width).attr('y1', dwarY + (_style.table.lineHeight/2) - 1).attr('y2', dwarY + (_style.table.lineHeight/2) - 1)
        .attr('stroke', '#BCBCBC').attr('stroke-width', _style.table.sWidth)

        dwarY += _style.table.lineHeight
      })
    },
  
    DataTable_Timeline(_table, _data, _style) {
      // ### Table Form --------------------------------------
      let form_ = _table.append('g').attr('class', '__table_form___')
      // header lines
      // form_.append('path').attr('d', `M0,0H${_style.width}`).style('stroke', _style.sColor).style('stroke-width', _style.sWidth)
      form_.append('path').attr('d', `M${_style.labelX},${_style.hHeight}H${_style.width}`).style('stroke', _style.sColor).style('stroke-width', _style.sWidth)

      // row lines & effect
      _style.headers.forEach((_, i) => {
        // row effect
        form_
        .append('rect')
        .attr('transform', `translate(${_style.labelX},${_style.hHeight + (_style.lineHeight * i)})`)
        .attr('fill', '#fff')
        .attr('width', _style.width + Math.abs(_style.labelX))
        .attr('height', _style.lineHeight)
        .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('fill', '#F4F5F5') })
        .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('fill', '#fff') })
        
        // line
        form_
        .append('path')
        .attr('d', `M${_style.labelX},${_style.hHeight + _style.lineHeight + (_style.lineHeight * i)}H${_style.width}`)
        .style('stroke', i == _style.headers.length-1 ? _style.sColor :'#BCBCBC')
        .style('stroke-width', _style.sWidth)
        .style('opacity', i == _style.headers.length-1 ? 1 : .5)
      })

      // ### Header Text & Values ----------------------------
      let text_ = _table.append('g').attr('class', '__table_text_values___')

      // header timeline week text
      text_
      .selectAll('text')
      .data(_style.timeline.week)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${_style.timeline.scale(d.mDate)}, ${_style.hHeight/2})`)
      .style('font-family', 'roboto')
      .style('font-size', 9)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(d => __M(d.eDate).format('DD MMM'))

      // row timeline label
      let columns  =  _style.headers.filter(f=> f.col == 'Y')
      let rowLabels_ = text_
      .append('g')
      .attr('transform', `translate(${_style.labelX+4}, ${_style.hHeight + 9})`)
      .selectAll('text')
      .data(columns)
      .enter()

      rowLabels_
      .append('text')
      .attr('transform', (_, i) => `translate(0, ${_style.lineHeight * i})`)
      .style('font-family', 'roboto')
      .style('font-size', 9)
      .style('fill', '#757575')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(d => d.name)

      _data.forEach(d___ => {
        text_
        .append('g')
        .attr('transform', `translate(${_style.timeline.scale(d___.sDate)}, ${_style.hHeight})`)
        .selectAll('text')
        .data(columns)
        .enter()
        .append('text')
        .attr('transform', (d, j) => `translate(${_style.cellWidth-4}, ${_style.lineHeight*j + d.dSize})`)
        .style('font-family', 'roboto')
        .style('font-size', d => d.dSize)
        .style('fill', d => d.dColor)
        // .style('fill', d == 0 ? '#fff' :  d => d.dColor)
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(d => !['A_PG', 'A_CUM_PG'].includes(d.colName) ? d___[d.colName].toLocaleString() : d___[d.colName])
        .on('mouseover', (d, j, a) => {
          if(d.link == 'N') return

          let node = 2
          d3.select(a[j]).style('cursor', 'pointer')
          d3.select(a[j]).transition().duration(100)
          .attr('transform', `translate(${_style.cellWidth-4+node}, ${_style.lineHeight*j + d.dSize}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', (d, j, a) => {
          if(d.link == 'N') return

          d3.select(a[j]).transition().duration(100)
          .attr('transform', `translate(${_style.cellWidth-4}, ${_style.lineHeight*j + d.dSize}) scale(1)`).style('fill', d.dColor)
        })
        // .on('click', () => { 
        //   if(c.link == 'N') return
        // })
      })
    },
    DataTable_Timeline1(_table, _data, _style) {
      // ### Table Form --------------------------------------
      let form_ = _table.append('g').attr('class', '__table_form___')
      // header lines
      // form_.append('path').attr('d', `M0,0H${_style.width}`).style('stroke', _style.sColor).style('stroke-width', _style.sWidth)
      form_.append('path').attr('d', `M${_style.labelX},${_style.hHeight}H${_style.width}`).style('stroke', _style.sColor).style('stroke-width', _style.sWidth)

      // row lines & effect
      _style.headers.forEach((_, i) => {
        // row effect
        form_
        .append('rect')
        .attr('transform', `translate(${_style.labelX},${_style.hHeight + (_style.lineHeight * i)})`)
        .attr('fill', '#fff')
        .attr('width', _style.width + Math.abs(_style.labelX))
        .attr('height', _style.lineHeight)
        .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('fill', '#F4F5F5') })
        .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('fill', '#fff') })
        
        // line
        form_
        .append('path')
        .attr('d', `M${_style.labelX},${_style.hHeight + _style.lineHeight + (_style.lineHeight * i)}H${_style.width}`)
        .style('stroke', i == _style.headers.length-1 ? _style.sColor : (i == _style.headers.length-3 ? '#000' :'#BCBCBC')) //table 중간에 두칸 구분짓기
        .style('stroke-width', _style.sWidth)
        .style('opacity', i == _style.headers.length-1 ? 1 : .5)
      })

      // ### Header Text & Values ----------------------------
      let text_ = _table.append('g').attr('class', '__table_text_values___')

      // header timeline week text
      text_
      .selectAll('text')
      .data(_style.timeline.week)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${_style.timeline.scale(d.mDate)}, ${_style.hHeight/2})`)
      .style('font-family', 'roboto')
      .style('font-size', 9)
      .style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(d => __M(d.eDate).format('DD MMM'))

      // row timeline label
      let columns  =  _style.headers.filter(f=> f.col == 'Y')
      let rowLabels_ = text_
      .append('g')
      .attr('transform', `translate(${_style.labelX+4}, ${_style.hHeight + 9})`)
      .selectAll('text')
      .data(columns)
      .enter()

      rowLabels_
      .append('text')
      .attr('transform', (_, i) => `translate(0, ${_style.lineHeight * i})`)
      .style('font-family', 'roboto')
      .style('font-size', 9)
      .style('fill', '#757575')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(d => d.name)

      _data.forEach(d___ => {
        text_
        .append('g')
        .attr('transform', `translate(${_style.timeline.scale(d___.sDate)+7}, ${_style.hHeight})`)  //table의 폭 조정
        .selectAll('text')
        .data(columns)
        .enter()
        .append('text')
        .attr('transform', (d, j) => `translate(${_style.cellWidth-4}, ${_style.lineHeight*j + d.dSize})`)
        .style('font-family', 'roboto')
        .style('font-size', d => d.dSize)
        // .style('fill', d => d.dColor)
        .style('fill', (d) => d___[d.colName] == 0 ? '#E0E0E0' : d.dColor) //data의 값이 0이면 연한색
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(d => !['A_PG', 'A_CUM_PG'].includes(d.colName) ? d___[d.colName].toLocaleString() : d___[d.colName])
        
        .on('mouseover', (d, j, a) => {
          if(d.link == 'N') return

          let node = 2
          d3.select(a[j]).style('cursor', 'pointer')
          d3.select(a[j]).transition().duration(100)
          .attr('transform', `translate(${_style.cellWidth-4+node}, ${_style.lineHeight*j + d.dSize}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', (d, j, a) => {
          if(d.link == 'N') return

          d3.select(a[j]).transition().duration(100)
          .attr('transform', `translate(${_style.cellWidth-4}, ${_style.lineHeight*j + d.dSize}) scale(1)`).style('fill', d.dColor)
        })
        // .on('click', () => { 
        //   if(c.link == 'N') return
        // })
      })
    }
  }
}
           