export default {
  data: () => ({

    DataTable: null, 
    Header: {},
    Data: {},
    Columns: [], 

    TableWidth: 0,

  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))

      let h_ = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      this.Header =
      {
        x: h_.X,
        y: h_.Y,
        background: h_.BACKGROUND,
        opacity:    h_.OPACITY,
        height:     h_.HEIGHT,

        line: {
          color:    h_.LINE_COLOR,
          size:     h_.LINE_SIZE,
        },

        font: {
          color:    h_.FONT_COLOR,
          weight:   h_.FONT_WEIGHT,
          size:     h_.FONT_SIZE,
        }
      }
      
      let d_ = JSON.parse(JSON.stringify(this.Queries.SQL2[0]))
      this.Data =
      {
        height:     d_.HEIGHT,
        hovor:      d_.HOVOR,
        line: {
          color:    d_.LINE_COLOR,
          size:     d_.LINE_SIZE,
        },

        font: {
          weight:   d_.FONT_WEIGHT,
          size:     d_.FONT_SIZE,
        }
      }

      this.Columns = JSON.parse(JSON.stringify(this.Queries.SQL3))

      this.TableWidth = 0
      this.Columns.forEach(d => {

        if (d.align == 'start' && d.type !== 'PROG') d.x = this.TableWidth + d.padding
        if (d.align == 'middle' && d.type !== 'PROG') d.x = this.TableWidth + (d.width/2)
        if (d.align == 'end' && d.type !== 'PROG') d.x = this.TableWidth + d.width - d.padding
       
        if (d.type == 'PROG') d.x = this.TableWidth 

        this.TableWidth += d.width
        d.col = this.TableWidth

        if (d.type.substr(d.type.length-1, 1) == '$') {
          d.format = '$'
          d.type = d.type.slice(0, -1)
        }
      })

      // Resize Canvas
      this.Canvas.CanvasWidth = this.TableWidth+ this.Header.x + 1
      this.Canvas.CanvasHeight = (this.dataSet.length * this.Data.height) + this.Header.height + this.Header.y + 1

      this.Canvas.CanvasChartWidth = this.TableWidth+ this.Header.x + 1
      this.Canvas.CanvasChartHeight = (this.dataSet.length * this.Data.height) + this.Header.height + this.Header.y + 1
    },

   
  }
}