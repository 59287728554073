import * as d3 from 'd3'

export default {
  name: 'scrollable',
  data: () => ({
    scrollables: {},
    scrollBarCommon: {
      deltaX: 0,
      deltaY: 0,
    },
  }),
  computed: {
    scorllBarMoving() {
      return d3.drag()
      .on('start', this.scorllStart)
      .on('drag', this.scorllDrag)
      .on('end', this.scorllEnd)

    },
  },
  methods: {
    setHorizonScroll(s, props=null, id, bar_target) {

      console.log(bar_target)
      console.log(s)
      console.log(id)

      if(props) {
        props.w = props.w || 300
        props.h = props.h || 300
        props.g = typeof props.g == 'number' ? props.g : 20

        if(props.bar) props.bar = {
          align       : props.bar.align || 'right',
          gap         : typeof props.bar.gap == 'number' ? props.bar.gap : 1,
          radius      : typeof props.bar.radius == 'number' ? props.bar.radius : 1,
          height      : typeof props.bar.height == 'number' ? props.bar.height : 4,
          stroke      : props.bar.stroke || '#03a9f4',
          strokeWidth : typeof props.bar.strokeWidth == 'number' ? props.bar.strokeWidth : .5,
          fill        : props.bar.fill || '#29b6f6',
          opacity     : typeof props.bar.opacity == 'number' ? props.bar.opacity : .5,
        }; else props.bar = {
          align       : 'right',
          gap         : 1,
          radius      : 1,
          height      : 4,
          stroke      : '#03a9f4',
          strokeWidth : .5,
          fill        : '#29b6f6',
          opacity     : .5,
        }
      } else props = {
        w: 300,                      // mask width
        h: 300,                      // mask height
        g: 20,                       // mask gap bottom
        bar : {                      // optional, if not defined, default values will be assigned
          align       : 'right',     // 'left' | 'right'
          gap         : 1,
          radius      : 1,
          height       : 150,
          stroke      : '#03a9f4',
          strokeWidth : .5,
          fill        : '#29b6f6',
          opacity     : .5,
        }
      }

      this.cleanUnscrollables()
      
      let parent = d3.select(s.node().parentNode)
      let scrollId = `scrollable_${id}`

      if (d3.select(`#${scrollId}___bar`))  d3.select(`#${scrollId}___bar`).remove()
        
      let scrollBound = { 
        x: null,        // scroll wrapper x, original target's x
        y: null,        // scroll wrapper y, original target's y
        w: props.w,     // scroll wrapper width, not the mask's width
        h: props.h,     // scroll wrapper height, not the mask's height
        bar: {},
        mask: {},
        target: {}
      }

      // // 0. Set Whell Event
      // s.on('wheel', (_, i, a) => {
      //   // var direction = d3.event.wheelDelta < 0 ? 'down' : 'up';
      //   // zoom(direction === 'up' ? d : d.parent);

      //   console.log('1. ', i, a);
      //   console.log('2. ', d3.event.wheelDelta);
      // });


      // 1. Get original target's coordinate that will be assigned to the wrapper.
      scrollBound.x = 0
      scrollBound.y = 0

      // 1. Get values by scroll-bar position
      scrollBound.bar.x = 0
      scrollBound.mask.x = 0

      scrollBound.bar.y = props.h || 300
      scrollBound.mask.y = 0
      scrollBound.mask.w = scrollBound.w
      scrollBound.mask.h = scrollBound.h - props.bar.height - props.bar.gap
      scrollBound.target.x = 0
      scrollBound.target.y = scrollBound.mask.y

      // 2. create clip-path
      let mask__ = parent
      .append('clipPath')
      .attr('id', `${scrollId}___clip_path`)

      console.log(mask__)

      let maskClipPath__ = mask__
      .append('rect')
      // translate(<x in the target area, not by parent>,<y in the target area, not by parent>)
      // .attr('transform', `translate(${scrollBound.x},${scrollBound.y})`) => wrong
      // it should be same with 's.attr('transform', `translate(0,0)`)' below.
      .attr('transform', `translate(${scrollBound.mask.x},${scrollBound.mask.y})`)
      .attr('width', scrollBound.mask.w)
      .attr('height', scrollBound.mask.h)

      // 3. create new group to be wrapper of the targeted scroll area
      let scrollWrapper = parent.append('g')
      .attr('id', `${scrollId}___clipped_area`)
      .attr('transform', `translate(${scrollBound.x},${scrollBound.y})`)
      // Set scroll clippath to the wrapper
      // s.attr('clip-path', `url(#${this.localId}#${scrollId})`)
      .attr('clip-path', `url(#${scrollId}___clip_path)`)

      // 4. move targeted element into the wrapper
      let class_ = s.attr('class')
      let classTarget = `${scrollId}___target`
      s.attr('class', class_ ? `${class_} ${classTarget}` : classTarget)
      s.attr('id', classTarget)
      
      scrollWrapper.node().appendChild(s.node())
      s.attr('transform', `translate(${scrollBound.target.x},${scrollBound.target.y})`)

      // 5. Append vertical scroll-bar
      // let scrollBar__ = parent
      let scrollBar__ = bar_target
      .append('rect')
      .attr('id', `${scrollId}___bar`)
      .attr('transform', `translate(${scrollBound.bar.x},${scrollBound.bar.y})`)
      .attr('rx', props.bar.radius)
      .attr('ry', props.bar.radius)
      .attr('width', 0)
      .attr('height', props.bar.height)
      .attr('stroke', props.bar.stroke)
      .attr('stroke-width', props.bar.strokeWidth)
      .attr('fill', props.bar.fill)
      .attr('opacity', props.bar.opacity)
      .call(this.scorllBarMoving)

      this.scrollables[scrollId] = {
        scale: null,
        wrapper: {
          x: scrollBound.x,
          y: scrollBound.y,
          h: scrollBound.h
        },
        bar: {
          x: scrollBound.bar.x,
          y: scrollBound.bar.y,
          w: null
        },
        clippath: {   // mask
          x: scrollBound.mask.x,
          y: scrollBound.mask.y,
          w: scrollBound.mask.w,
          h: scrollBound.mask.h,
        },
        target: {
          x: scrollBound.target.x,
          y: scrollBound.target.y,
          w: null,
          h: null,
          g: props.g, // gap bottom
        },
      }

      // Resizeing scroll-bar when target resized.
      setTimeout(() => {
        this.scrollables[scrollId].target.w = s.node().getBoundingClientRect().width
        this.scrollables[scrollId].target.h = s.node().getBoundingClientRect().height
        this.scrollables[scrollId].bar.w = Math.round(this.scrollables[scrollId].clippath.w * (this.scrollables[scrollId].clippath.w / this.scrollables[scrollId].target.w))
        scrollBar__.attr('width', this.scrollables[scrollId].bar.w)

        let minX_ = this.scrollables[scrollId].wrapper.x
        let maxX_ = minX_ + this.scrollables[scrollId].clippath.w - this.scrollables[scrollId].bar.w

        if(this.scrollables[scrollId].target.w <= this.scrollables[scrollId].clippath.w) {
          this.scrollables[scrollId].target.x = 0
          this.scrollables[scrollId].clippath.x = 0
          this.scrollables[scrollId].clippath.h = this.scrollables[scrollId].wrapper.h
          
          s.transition().duration(500)
          .attr('transform', `translate(${this.scrollables[scrollId].target.x},${this.scrollables[scrollId].target.y})`)

          maskClipPath__
          .attr('transform', `translate(${this.scrollables[scrollId].clippath.x},${this.scrollables[scrollId].clippath.y})`)
          .attr('width', this.scrollables[scrollId].clippath.w)

          scrollBar__.attr('opacity', 0)
        }
        // else scrollBar__.attr('opacity', .75)
        this.scrollables[scrollId].scale = d3.scaleLinear()
        .domain([minX_, maxX_])
        .range([ this.scrollables[scrollId].target.x, this.scrollables[scrollId].clippath.w - this.scrollables[scrollId].target.w - this.scrollables[scrollId].target.g])

      }, 500)
    },
    scorllStart(_, i, a) {
      let scrollId = this.getScrollId(d3.select(a[i]).attr('id'))

      this.scrollBarCommon.deltaX = this.scrollables[scrollId].bar.x - d3.event.x
    },
    scorllDrag(_, i, a) {
      let scrollId = this.getScrollId(d3.select(a[i]).attr('id'))
      let transform_ = d3.select(a[i]).attr('transform')

      let x_ = this.scrollBarCommon.deltaX + d3.event.x

      let minX_ = this.scrollables[scrollId].wrapper.x
      let maxX_ = minX_ + this.scrollables[scrollId].clippath.w - this.scrollables[scrollId].bar.w
      if(x_ < minX_) x_ = minX_
      else if(x_ > maxX_) x_ = maxX_

      let translate_ = `translate(${x_},${this.scrollables[scrollId].bar.y})`
      d3.select(a[i]).attr('transform', transform_.replace(/\s/g, '').replace(/translate(.*?)\)/gi, translate_))

      // target
      transform_ = d3.select(`.${scrollId}___target`).attr('transform')
      translate_ = `translate(${this.scrollables[scrollId].scale(x_)}, ${this.scrollables[scrollId].target.y})`
      d3.select(`.${scrollId}___target`).attr('transform', transform_.replace(/\s/g, '').replace(/translate(.*?)\)/gi, translate_))
    },
    scorllEnd(_, i, a) {
      let scrollId = this.getScrollId(d3.select(a[i]).attr('id'))
      let transform_ = d3.select(a[i]).attr('transform')

      // let x_ =
      let x_ = this.scrollBarCommon.deltaX + d3.event.x
      let minX_ = this.scrollables[scrollId].wrapper.x
      let maxX_ = minX_ + this.scrollables[scrollId].clippath.w - this.scrollables[scrollId].bar.w
      if(x_ < minX_) x_ = minX_
      else if(x_ > maxX_) x_ = maxX_

      // scroll bar
      let translate_ = `translate(${x_}, ${this.scrollables[scrollId].bar.y})`
      this.scrollables[scrollId].bar.x = x_
      d3.select(a[i]).attr('transform', transform_.replace(/\s/g, '').replace(/translate(.*?)\)/gi, translate_))
      
      // target
      this.scrollables[scrollId].target.x = this.scrollables[scrollId].scale(x_)
      transform_ = d3.select(`.${scrollId}___target`).attr('transform')
      translate_ = `translate(${this.scrollables[scrollId].scale(x_)},${this.scrollables[scrollId].target.y})`
      d3.select(`.${scrollId}___target`).attr('transform', transform_.replace(/\s/g, '').replace(/translate(.*?)\)/gi, translate_))
    },
    cleanUnscrollables() {
      Object.keys(this.scrollables).forEach(scrollId => {
        if(!d3.select(`.${scrollId}___target`)) delete this.scrollables[scrollId]
      })
    },
    getScrollId(id) {
      return id.split('___')[0]
    }
  }
}
