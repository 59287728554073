import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    draw_Forms() {

      let Form_Hull = this.svg
      Form_Hull
      .append('image')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/Common/demo_logo.svg`)
      .attr('transform', `translate(30,40)`)

      Form_Hull
      .append('text')
      .attr('transform', `translate(30, 90)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('Block Division of Pontoon & Column')

      Form_Hull
      .append('text')
      .attr('transform', `translate(30, 105)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text('HULL Block Division Rev.2 (01 Jun 2018) / Block Schedule (07-Feb-2020)')

      Form_Hull
      .append('image')
      .attr('id', 'keyPlan')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/Common/keyplan_Hull.svg`)
      .attr('transform', `translate(30.5,130.5)`).style('opacity', 0)
      
      Form_Hull
      .append('image')
      .attr('id', 'Legends')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/Common/Legends.svg`)
      .attr('transform', `translate(45.5,300.5)`).style('opacity', 0)

      

      // keyplan area for Module
      let keyPlan = Form_Hull 
      .append('g')
      .attr('transform', `translate(15, 66)`)

      keyPlan // NP1UH
      .append('path')
      .attr('id', 'Key_NP1UH')
      .attr('d', 'M40.955,79.561H98.4V99.444h0v.068H65.185l-7.254,7.256v1.893H38.018V82.5A2.937,2.937,0,0,1,40.955,79.561Z').attr('fill', '#6acdf6').attr('opacity', 0).attr('visibility', 'hidden')

      keyPlan // NP2UH
      .append('path')
      .attr('id', 'Key_NP2UH')
      .attr('d', 'M127.5,82.5v57.449H107.553v-33.22L100.3,99.473H98.4V79.561h26.162A2.938,2.938,0,0,1,127.5,82.5Z').attr('fill', '#6acdf6').attr('opacity', 0).attr('visibility', 'hidden')

      keyPlan // NP3UH
      .append('path')
      .attr('id', 'Key_NP3UH')
      .attr('d', 'M124.545,169.045H67.1V149.161h0v-.068h33.22l7.254-7.254v-1.893h19.913v26.161A2.938,2.938,0,0,1,124.545,169.045Z').attr('fill', '#6acdf6').attr('opacity', 0).attr('visibility', 'hidden')

      keyPlan // NP4UH
      .append('path')
      .attr('id', 'Key_NP4UH')
      .attr('d', 'M38,166.106V108.657H57.948v33.221l7.255,7.254H67.1v19.913H40.934A2.939,2.939,0,0,1,38,166.106Z').attr('fill', '#6acdf6').attr('opacity', 0).attr('visibility', 'hidden')

      keyPlan // C10UH
      .append('path')
      .attr('id', 'Key_C10UH')
      .attr('d', 'M44.237,79.63H54.943a2.939,2.939,0,0,1,2.938,2.937V96.6a2.939,2.939,0,0,1-2.938,2.938H40.906A2.938,2.938,0,0,1,37.968,96.6V82.567a2.937,2.937,0,0,1,2.938-2.937').attr('fill', '#6acdf6').attr('opacity', 0).attr('visibility', 'hidden')

      keyPlan // C20UH
      .append('path')
      .attr('id', 'Key_C20UH')
      .attr('d', 'M113.732,79.63h10.706a2.938,2.938,0,0,1,2.938,2.937V96.6a2.939,2.939,0,0,1-2.938,2.938H110.4a2.938,2.938,0,0,1-2.938-2.938V82.567A2.937,2.937,0,0,1,110.4,79.63').attr('fill', '#6acdf6').attr('opacity', 0).attr('visibility', 'hidden')

      keyPlan // C30UH
      .append('path')
      .attr('id', 'Key_C30UH')
      .attr('d', 'M44.237,149.126H54.943a2.938,2.938,0,0,1,2.938,2.937V166.1a2.94,2.94,0,0,1-2.938,2.938H40.906a2.939,2.939,0,0,1-2.938-2.938V152.063a2.937,2.937,0,0,1,2.938-2.937').attr('fill', '#6acdf6').attr('opacity', 0).attr('visibility', 'hidden')

      keyPlan // C40UH
      .append('path')
      .attr('id', 'Key_C40UH')
      .attr('d', 'M113.732,149.126h10.706a2.937,2.937,0,0,1,2.938,2.937V166.1a2.939,2.939,0,0,1-2.938,2.938H110.4a2.939,2.939,0,0,1-2.938-2.938V152.063a2.937,2.937,0,0,1,2.938-2.937').attr('fill', '#6acdf6').attr('opacity', 0).attr('visibility', 'hidden')


      // button Menu_Back
      let Menu_Back = this.svg

      .append('g')
      .attr('id', 'Menu_Back')
      .attr('transform', `translate(400, 94)`)
      .attr('opacity', 0)
      .style('cursor', 'pointer')
      .style('visibility', 'hidden')
      .call(s => this.btnGoBack(s)) // --------------------------------------------------------------## btnGoBack

      Menu_Back
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', 16).style('height', 16).style('fill', '#44A9DF')

      Menu_Back
      .append('text')
      .attr('transform', `translate(8, 12)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').attr('text-anchor', 'middle')
      .text('B')

      this.svg
      .append('text')
      .attr('id', 'stage')
      .attr('transform', `translate(320, 65)`)
      .style('font-family', 'roboto').style('font-size', 14).style('fill', '#EC407A').attr('text-anchor', 'start')
      .text(this.stage)
    },
  }
}