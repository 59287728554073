
import router from '@/router'

import __C from '../../primitives/_constant_'
import { AccountService, ApplicationService } from '@/services'

const ACCOUNT = JSON.parse(localStorage.getItem('ACCOUNT'))
const accountService = new AccountService()
const applicationService = new ApplicationService()

const state = !!ACCOUNT
    ? { 
      status: { loggedIn: true }, 
      account: ACCOUNT,
      isAdmin: ACCOUNT.userPermit.split(',').some(r => __C.FULL_ACCESS_PERMISSION.indexOf(r) >= 0),
      fullAccess: !ACCOUNT.userPermit
    } : { 
      status: {}, 
      account: null,
      fullAccess: false, 
    }
const mutations = {
  signinRequest(state, account) {
    state.status = { loggedIn: true }
    state.account = account
    state.isAdmin = account.userPermit.split(',').some(r => __C.FULL_ACCESS_PERMISSION.indexOf(r) >= 0)
    state.fullAccess = !account.userPermit
  },
  signinSuccess(state, account) {
    state.status = { loggedIn: true }
    state.account = account
    state.isAdmin = account.userPermit.split(',').some(r => __C.FULL_ACCESS_PERMISSION.indexOf(r) >= 0)
    state.fullAccess = !account.userPermit
  },
  signout(state) {
    state.status = {}
    state.account = null
    state.isAdmin = false
    state.fullAccess = false
  },
}
const getters = {
  getToken: (state) => {
    return state.account
  }
}
const actions = {
  // signin({ dispatch, commit, getters }, creds) {
  signin({ commit }, creds) {
      // commit('signinRequest', creds)
    return new Promise(resolve => {
      accountService.signin(creds, account => {
        if(!!account.token) {
          commit('signinSuccess', account)
          resolve(account)
        } else {
          // commit('signinFailure', error);
          // dispatch('alert/error', error, { root: true });
          resolve(account)
        }
      })
    })
  },
  signout({ commit }) {
    accountService.signout(() => {
      commit('signout')
      router.replace('/signin', () => {})
    })
  },
  putRequestHistory({ state }, params) {
    let reqInfo = {
      userId: state.account.id,
      ...params
    }
    return new Promise(resolve => {
      if(!state.status || !state.status.loggedIn) resolve(null)
      else applicationService.putRequestHistory(reqInfo).then(res => {
        resolve(res)
      })
    })
  },
  LevedRequestHistory({ state }, params) {
    let reqInfo = {
      userId: state.account.id,
      ...params
    }
    return new Promise(resolve => {
      if(!state.status || !state.status.loggedIn) resolve(null)
      else applicationService.LevedRequestHistory(reqInfo).then(res => {
        resolve(res)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
