import * as d3 from 'd3'

export default {

  methods: {

    event_Link(selection, no) {

      if(this.linkStatus) return

      selection
      .on('mouseover', () => {
          d3.select(`#value_${this.localId}_${no}`)
          .transition().duration(50)
          .style('opacity', .4)
        })
        .on('mouseout', () => {
          d3.select(`#value_${this.localId}_${no}`)
          .transition().duration(50)
          .style('opacity', 1)
        })
        
        .on('click', () => { this.link_Action(no) }) // --->
    },

    link_Action(no_) {
      // Get Modal Number
      let columnProps = this.getRequestColumnProps('request', {}, this.ColumnProps)
      let filterString = this.dataSet.filter[no_] || ''
      if(columnProps.iFilters.filterString) filterString += ` AND ${columnProps.iFilters.filterString} `

      let request_ = {
        dataType: 'row',
        action: {
          type: 'direct',
          target: columnProps.action.target,
          component: columnProps.action.component,
          id: columnProps.action.id,
          no: columnProps.action.no
        },
        filters     : {
          ...this.FilterValues,
          ...columnProps.filters
        },
        iFilters    : {
          filterString : filterString || '',
          inputFilter  : ''
        }
      }
      
      this.$emit('request-action', request_)
    },

  } 
}