export default {
  data: () => ({

    Status: [
      { code: 'FABSTART',    title: 'Fab. Commenced',     colName: { total: 'FABSTART_TOTAL'   , actual: 'FABSTART_ACTUAL'    }, bColor: '#44A9DF', tColor: '#44A9DF', opacity: 0.20,},
      { code: 'FABRICATION', title: 'Fab. Completed',     colName: { total: 'FABRICATION_TOTAL', actual: 'FABRICATION_ACTUAL'   }, bColor: '#44A9DF', tColor: '#FFFFFF', opacity: 0.40,},
      { code: 'PAINTING',    title: 'Painting Completed', colName: { total: 'PAINTING_TOTAL'   , actual: 'PAINTING_ACTUAL'   }, bColor: '#44A9DF', tColor: '#44A9DF', opacity: 0.60,},
      { code: 'ASSEMBLY',    title: 'Assembly Completed', colName: { total: 'ASSEMBLY_TOTAL'   , actual: 'ASSEMBLY_ACTUAL'}, bColor: '#44A9DF', tColor: '#333',    opacity: 0.80,},
      { code: 'ERECTION',    title: 'Erec. Commenced',    colName: { total: 'ERECTION_TOTAL'   , actual: 'ERECTION_ACTUAL'  }, bColor: '#44A9DF', tColor: '#333',    opacity: 1.00,},
    ],


    BlockStatus_Coordinate: [
      {SCRNAME:'Overall',       LV1:null      , LV2: null,           LV3: null,     LV4: null,       ID: null,        NAME: 'Overall FP', x: 1145,  y: 381,},
      {SCRNAME:'4_Decks',       LV1:'TOPSIDES', LV2: 'UPPERDECK',    LV3: null,     LV4: null,       ID: null,        NAME: 'Upper Deck',  x: 180,   y: 435,},
      {SCRNAME:'4_Decks',       LV1:'TOPSIDES', LV2: 'MAINDECK',     LV3: null,     LV4: null,       ID: null,        NAME: 'Main Deck',   x: 180,   y: 672,},
      {SCRNAME:'4_Decks',       LV1:'TOPSIDES', LV2: 'LOWERDECK',    LV3: null,     LV4: null,       ID: null,        NAME: 'Lower Deck',  x: 1260,  y: 435,},
      {SCRNAME:'4_Decks',       LV1:'TOPSIDES', LV2: 'CELLARDECK',   LV3: null,     LV4: null,       ID: null,        NAME: 'Cellar Deck', x: 1260,  y: 672,},
      {SCRNAME:'selectDeck',    LV1:'TOPSIDES', LV2: 'UPPERDECK_UD', LV3: 'S2L1_UD',LV4: null,       ID: 'S2L1_UD',   NAME: 'Upper Mezz',  x: 1118,  y: 333,},
      {SCRNAME:'selectDeck',    LV1:'TOPSIDES', LV2: 'UPPERDECK_UD', LV3: 'S2L1_UD',LV4: null,       ID: 'S2L1_UD',   NAME: 'Upper Deck',  x: 1118,  y: 680,},
      {SCRNAME:'selectDeck',    LV1:'TOPSIDES', LV2: 'MAINDECK_MD',  LV3: 'S2L2_MD',LV4: null,       ID: 'S2L2_MD',   NAME: 'Main Mezz',   x: 1118,  y: 333,},
      {SCRNAME:'selectDeck',    LV1:'TOPSIDES', LV2: 'MAINDECK_MD',  LV3: 'S2L2_MD',LV4: null,       ID: 'S2L2_MD',   NAME: 'Main Deck',   x: 1118,  y: 657,},
      {SCRNAME:'selectDeck',    LV1:'TOPSIDES', LV2: 'LOWERDECK_LD', LV3: 'S2R1_LD',LV4: null,       ID: 'S2R1_LD',   NAME: 'Lower Mezz',  x: 1118,  y: 333,},
      {SCRNAME:'selectDeck',    LV1:'TOPSIDES', LV2: 'LOWERDECK_LD', LV3: 'S2R1_LD',LV4: null,       ID: 'S2R1_LD',   NAME: 'Lower Deck',  x: 1118,  y: 657,},
      {SCRNAME:'selectDeck',    LV1:'TOPSIDES', LV2: 'CELLARDECK_CD',LV3: 'S2R2_CD',LV4: null,       ID: 'S2R2_CD',   NAME: 'Cellar Mezz', x: 1118,  y: 333,},
      {SCRNAME:'selectDeck',    LV1:'TOPSIDES', LV2: 'CELLARDECK_CD',LV3: 'S2R2_CD',LV4: null,       ID: 'S2R2_CD',   NAME: 'Cellar Deck', x: 1118,  y: 657,},
      {SCRNAME:'selectDivision',LV1:'TOPSIDES', LV2: 'UPPERDECK_UD', LV3: 'S2L1_UD',LV4: 'S2L1_UD_M',ID: 'S2L1_UD_M', NAME: 'Upper Mezz',  x: 50,    y: 640,},
      {SCRNAME:'selectDivision',LV1:'TOPSIDES', LV2: 'UPPERDECK_UD', LV3: 'S2L1_UD',LV4: 'S2L1_UD_D',ID: 'S2L1_UD_D', NAME: 'Upper Deck',  x: 50,    y: 640,},
      {SCRNAME:'selectDivision',LV1:'TOPSIDES', LV2: 'MAINDECK_MD',  LV3: 'S2L2_MD',LV4: 'S2L2_MD_M',ID: 'S2L2_MD_M', NAME: 'Main Mezz',   x: 50,    y: 640,},
      {SCRNAME:'selectDivision',LV1:'TOPSIDES', LV2: 'MAINDECK_MD',  LV3: 'S2L2_MD',LV4: 'S2L2_MD_D',ID: 'S2L2_MD_D', NAME: 'Main Deck',   x: 50,    y: 640,},
      {SCRNAME:'selectDivision',LV1:'TOPSIDES', LV2: 'LOWERDECK_LD', LV3: 'S2R1_LD',LV4: 'S2R1_LD_M',ID: 'S2R1_LD_M', NAME: 'Lower Mezz',  x: 50,    y: 640,},
      {SCRNAME:'selectDivision',LV1:'TOPSIDES', LV2: 'LOWERDECK_LD', LV3: 'S2R1_LD',LV4: 'S2R1_LD_D',ID: 'S2R1_LD_D', NAME: 'Lower Deck',  x: 50,    y: 640,},
      {SCRNAME:'selectDivision',LV1:'TOPSIDES', LV2: 'CELLARDECK_CD',LV3: 'S2R2_CD',LV4: 'S2R2_CD_M',ID: 'S2R2_CD_M', NAME: 'Cellar Mezz', x: 50,    y: 640,},
      {SCRNAME:'selectDivision',LV1:'TOPSIDES', LV2: 'CELLARDECK_CD',LV3: 'S2R2_CD',LV4: 'S2R2_CD_D',ID: 'S2R2_CD_D', NAME: 'Cellar Deck', x: 50,    y: 640,},
    ]
  }),
}