import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs
// import Defs from '../../../../../../includes/primitives/Color_Defs'
// import Filter from '../../../../../../includes/primitives/Filter_Defs'

export default {
  data: () => ({

  }),

  methods: {

    draw_TrendChart() {
      if (!this.Queries.SQL1[0].value) {
        
        this.svg
        .append('text')
        .attr('x', 150)
        .attr('y', 120).style('font-size',16)
        .style('font-family','roboto')
        .attr('text-anchor', 'middle')
        .attr('fill', '#bcbcbc')
        .text('Not Applicable' )
        return 
      }

      var config = ['#757575', '#4CAE4E','#FF75A5',]
      let boxDistance = 30
      
      let SC_DiscGroup = this.svg
          .append('g')
          .attr('transform', `translate(0.5, 30.5)`)

      let summary = SC_DiscGroup
          .append('g')

          
      // draw the rects of summary and values
      // summary
      this.Queries.SQL1.forEach((data,i) => {
        summary.append('rect')
        .attr('x', boxDistance).attr('y', 12).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 30).attr('fill', `url(#${this.localId}__LinearA4${'LightGray'}0)`).attr('opacity', 0.7)
        summary.append('text')
        .attr('x', boxDistance + 8).attr('y', 23).style('font-size',9).style('font-family','roboto').attr('fill', '#757575').text(data.title)
        summary.append('text')
        .attr('x', boxDistance + 53).attr('y', 36).style('font-size',11).style('font-family','roboto').attr('fill', config[i]).attr('text-anchor', 'end')
        .transition().duration(1000)
        .tween("text", function() {
          var i = d3.interpolateNumber(1, data.value)
          return function(t) {
          d3.select(this).text( Number(i(t).toFixed(0)).toLocaleString() )
          }
        })
        boxDistance += 80
      })

      let chartArea = SC_DiscGroup
          .append('g')
          .attr('transform', `translate(30, 62)`)
          chartArea
          .append('line').attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', 120).attr('stroke-width', .3).attr('stroke', '#333')
          chartArea
          .append('line').attr('x1', 0).attr('x2', 230).attr('y1', 120).attr('y2', 120).attr('stroke-width', .3).attr('stroke', '#333')

      let dataSource__ = this.DataItems.filter(data => data.value)

      let dataMinValue = 0
      let dataMaxValue = this.getMaxBound(Math.max(...dataSource__.map(data => data.value)))
      let dataAvgValue = 0
      dataSource__.forEach((data, i) => { if (i > dataSource__.length-9) dataAvgValue += data.value })
      dataAvgValue = dataAvgValue / 8

      let deltaValue = dataSource__[dataSource__.length-1].value- dataAvgValue
      let deltaY = dataAvgValue + (deltaValue/2)

      let axisYHeight = 120
      let axisXWidth = 220


      let scaleY = d3
          .scaleLinear()
          .domain([dataMinValue, dataMaxValue])
          .range([axisYHeight, 0])
          
      // ### DAY #######
      // convert string date to datetime
      let dataXTimelineValues = this.DataItems.map(d => this.parseDate(d.date))

      // ### MONTH #######
      // set default month-parser
      let _mParser__ = d3.timeFormat('%Y-%m')
      // set month start point
      let prevMonth_ = _mParser__(dataXTimelineValues[0])

      let daysForAMonth = []
      let dataXTimelineValuesMonth = []

      dataXTimelineValues.forEach(day => {
        if(prevMonth_ != _mParser__(day)) {
          dataXTimelineValuesMonth.push(new Date(d3.mean(daysForAMonth)))
          // init. daysForAMonth to calculate the next month's 
          // position value.
          daysForAMonth = []
          // set new month to compare
          prevMonth_ = _mParser__(day)
        }
        // stack the days for a month
        daysForAMonth.push(day)
      })
      // set last calculated month
      dataXTimelineValuesMonth.push(new Date(d3.mean(daysForAMonth)))
      // #######

      let scaleXTimeline = d3.scaleTime()
          // get two of the min & max values from the 'dataXTimelineValues'
          .domain(d3.extent(dataXTimelineValues, (d) => d))
          .range([0, axisXWidth]) 

      let lineFunc = d3.line()
          .x(d => scaleXTimeline(this.parseDate(d.date))+5)
          .y(d => scaleY(d.value))
      
      let lineZero = d3.line()
          .x(d => scaleXTimeline(this.parseDate(d.date))+5)
          .y(d => axisYHeight)
      
          
      // draw 0 and maxBounds on Axis Y
      chartArea
          .append('text') // Mininum Value
          .attr('x', -2).attr('y',axisYHeight).attr('font-size', 9)
          .attr('text-anchor','end').attr('alignment-baseline','middle')
          .attr('fill','#bcbcbc').text(dataMinValue)
      chartArea
          .append('text') // Maxinum Value
          .attr('x', -2).attr('y',0).attr('font-size', 9)
          .attr('text-anchor','end').attr('alignment-baseline','middle')
          .attr('fill','#bcbcbc').text(dataMaxValue)


      // Draw the data of average
      // Value / Note / Line
      chartArea
          .append('text') // Value
          .attr('x',-2).attr('y',scaleY(dataAvgValue)).attr('font-size', 10)
          .attr('text-anchor','end').attr('alignment-baseline','middle')
          .attr('fill','#83D2F5').text(dataAvgValue.toFixed(0))
      chartArea
          .append('text') // Note
          .attr('x',+5).attr('y',scaleY(dataAvgValue)-3).attr('font-size', 8.5)
          .attr('text-anchor','start').attr('alignment-baseline','middle')
          .attr('fill','#83D2F5').text('8 weeks Avg.')
      
      chartArea
          .append('line') // Line
          .attr('x1',0).attr('x2',axisXWidth + 18)
          .attr('y1',scaleY(dataAvgValue)).attr('y2', scaleY(dataAvgValue))
          .attr('stroke-width', 0.5)
          .attr('stroke','#83D2F5')



      


      // Draw Delta Area -----------------------------------------------------------------------------------------------------
      // Arrow & Value
      chartArea
          .append('line')
          .attr('x1',axisXWidth - 10).attr('x2',axisXWidth + 18)
          .attr('y1',scaleY(0))
          .attr('y2', scaleY(0))
          .attr('stroke-width', 0.3)
          .attr('stroke','#bcbcbc')
          .transition().duration(500)
          .attr('y1',scaleY(dataSource__[dataSource__.length-1].value))
          .attr('y2', scaleY(dataSource__[dataSource__.length-1].value))

      if (deltaValue > 0) {
        chartArea
          .append('text') // Last Value
          .attr('x', axisXWidth + 5)
          .attr('y', scaleY(0)-7)
          .attr('font-size', 10).attr('text-anchor','middle').attr('alignment-baseline','middle').attr('fill','#757575')
          .text(dataSource__[dataSource__.length-1].value)
          .transition().duration(500)
          .attr('y', scaleY(dataSource__[dataSource__.length-1].value)-7)
        chartArea
            .append('image')
            .attr('id', 'deltaArrow')
            .attr('xlink:href', require('../../../../../../src/assets/svg/delta_up_arrow.svg'))
            .attr('x', axisXWidth + 10).attr('width', 8).attr('height', 8)
            .attr('y', scaleY(dataSource__[dataSource__.length-1].value)-1)
            .attr('opacity', 0)
            .transition().duration(1000).attr('opacity', 1)
        chartArea
            .append('line')
            .attr('x1', axisXWidth + 14).attr('y1', scaleY(dataSource__[dataSource__.length-1].value) + 4)
            .attr('x2', axisXWidth + 14).attr('y2', scaleY(dataAvgValue))
            .attr('stroke-width', 2.5).attr('stroke','#8BC248')
            .attr('opacity', 0)
            .transition().duration(1000).attr('opacity', 1)
        chartArea
            .append('text') 
            .attr('x', axisXWidth + 17).attr('y', scaleY(deltaY))
            .attr('font-size', 12).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#8BC248')
            .text(`+${deltaValue.toFixed(0)}`)
            .attr('opacity', 0)
            .transition().duration(1000).attr('opacity', 1)
      } else if (deltaValue < 0) {
        chartArea
          .append('text') // Last Value
          .attr('x', axisXWidth + 5)
          .attr('y', scaleY(0)+8)
          .attr('font-size', 10).attr('text-anchor','middle').attr('alignment-baseline','middle').attr('fill','#757575')
          .text(dataSource__[dataSource__.length-1].value)
          .transition().duration(500).attr('y', scaleY(dataSource__[dataSource__.length-1].value)+8)
        chartArea
            .append('image')
            .attr('id', 'deltaArrow')
            .attr('xlink:href', require('../../../../../../src/assets/svg/delta_down_arrow.svg'))
            .attr('x', axisXWidth + 10).attr('width', 8).attr('height', 8)
            .attr('y', scaleY(dataSource__[dataSource__.length-1].value) - 7)
            .attr('opacity', 0)
            .transition().duration(1000).attr('opacity', 1)
        chartArea
            .append('line')
            .attr('x1', axisXWidth + 14).attr('y2', scaleY(dataAvgValue))
            .attr('x2', axisXWidth + 14).attr('y1', scaleY(dataSource__[dataSource__.length-1].value) - 5)
            .attr('stroke-width', 2.5).attr('stroke','#EC407A')
            .attr('opacity', 0)
            .transition().duration(1000).attr('opacity', 1)
        chartArea
            .append('text') 
            .attr('x', axisXWidth + 17).attr('y', scaleY(deltaY))
            .attr('font-size', 12).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#EC407A')
            .text(`${deltaValue.toFixed(0)}`)
            .attr('opacity', 0)
            .transition().duration(1000).attr('opacity', 1)
      }
      

      

      // draw line chart in the line-group-box
      chartArea
          .append('g')
          .attr('transform', `translate(0, 0)`)
          .append('path')
          .attr('d', lineZero(dataSource__))
          .style('stroke', '#757575').style('stroke-width', 1.5).attr('opacity', .2)
          .style('fill', 'none')
          .transition()
          .duration(500)
          .attr('d', lineFunc(dataSource__))

      chartArea
          .append('g')
          .attr('transform', `translate(0, 0)`)
          .selectAll()
          .data(dataSource__)
          .enter()
          .append('circle')
          .attr('fill', '#bcbcbc')
          .attr('stroke', '#bcbcbc')
          .attr('stroke-width', 0.3)
          .attr('cx', d => scaleXTimeline(this.parseDate(d.date))+5)
          .attr('cy', axisYHeight)
          .attr('r', 2)
          .transition()
          .duration(500)
          .attr('cy', d => scaleY(d.value))
          
      // // draw timeline on the X aixs
      // let timeline = chartArea
      //     .append('g')
      //     .attr('transform', `translate(0, ${axisYHeight})`)
      //     .selectAll()
      //     .data(dataSource__)
      //     .enter()
      //     .append('circle')
      //     .attr('cx', d => scaleXTimeline(this.parseDate(d.date))+5)
      //     .attr('cy', + 2)
      //     .attr('r', .5)
      //     .attr('fill','#bcbcbc')
          


      // Draw Weeks and Month in Timeline ##
      // 1. put timeline text under the aixs X(Timeline)
      chartArea
      .append('g')
      .attr('transform', `translate(0, ${axisYHeight + 10})`)
      .selectAll()
      .data(dataXTimelineValues)
      .enter()
      .append('text')
      .attr('x', d => scaleXTimeline(d)).attr('y', 0).attr('font-size', 8).attr('fill','#bcbcbc').style('font-family','roboto')
      .text(d => d3.timeFormat('%d')(d))

      // 2. put timeline-month text under the aixs X(Timeline)
      chartArea
      .append('g')
      .attr('transform', `translate(0, ${axisYHeight + 20})`)
      .selectAll()
      .data(dataXTimelineValuesMonth)
      .enter()
      .append('text')
      .attr('x', d => scaleXTimeline(d)).attr('y', 0).attr('font-size', 9).attr('fill','#757575').style('font-family','roboto')
      .text(d => d3.timeFormat('%b')(d))
    }, 

  }
}
