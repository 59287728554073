import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    props:[
      {head1: 'Estimate', head2: 'Qty',       x: 0, cx: 277},
      {head1: 'Current',  head2: 'Qty',       x: 65, cx: 342},
      {head1: 'Actual',   head2: 'Qty',       x: 130, cx: 407},
      {head1: 'Delivery', head2: 'Prog(%)',   x: 200,  cx: 520},
      {head1: 'Actual',   head2: 'Qty',       x: 410, cx: 690},
      {head1: 'Install',  head2: 'Prog(%)',   x: 1000,  cx: 800},
    ],
    head1: ['DSME', 'Current', 'Actual', 'Delivery', 'Actual', 'Install',],
    head2: ['Estimate', 'Qty', 'Qty', 'Prog(%)', 'Qty', 'Prog(%)',],
    disc: ['Structural', 'Piping', 'Electrical', 'HVAC', 'Mechanical',],
  }),
  
  methods: {
    
    drawChart() {
      let PreOutfitting = this.svg
      .append('g')
      .attr('transform', `translate(0, 10.5)`)

      PreOutfitting
      .append('rect')
      .attr('x',220).attr('y',40).attr('width',375).attr('height',30).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)
      PreOutfitting
      .append('rect')
      .attr('x',640).attr('y',40).attr('width',240).attr('height',30).attr('stroke-width', 0).attr('fill', '#F4F5F5')

      PreOutfitting.append('line').attr('x1',220).attr('y1',40).attr('x2',595).attr('y2',40).attr('stroke-width', 0.3).attr('stroke', '#333')
      PreOutfitting.append('line').attr('x1',20).attr('y1',70).attr('x2',595).attr('y2',70).attr('stroke-width', 0.3).attr('stroke', '#333')
     
      PreOutfitting.append('line').attr('x1',640).attr('y1',40).attr('x2',880).attr('y2',40).attr('stroke-width', 0.3).attr('stroke', '#333')
      PreOutfitting.append('line').attr('x1',640).attr('y1',70).attr('x2',880).attr('y2',70).attr('stroke-width', 0.3).attr('stroke', '#333')
    
      PreOutfitting
      .append('text')
      .attr('x', 420).attr('y', 35).style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#333').text('Material Delivery')

      PreOutfitting
      .append('text')
      .attr('x', 770).attr('y', 35).style('font-family','roboto').style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#333').text('Installation')


      PreOutfitting
      .append('g')
      .attr('transform', `translate(20, 107)`)
      .selectAll('line')
      .data(this.disc)
      .enter()
      .append('line')
      .attr('x1', 0)
      .attr('y1', (d,i) => {
        if (i == this.disc.length-1) return (i*36) - 18
        else return i*36
      })
      .attr('x2', 820)
      .attr('y2', (d,i) => {
        if (i == this.disc.length-1) return (i*36) - 18
        else return i*36
      })
      .attr('stroke-width', 0.3)
      .attr('stroke', '#bcbcbc')

      // Discipline Name
      PreOutfitting
      .append('g')
      .attr('transform', `translate(70, 83)`)
      .selectAll('text')
      .data(this.disc)
      .enter()
      .append('text')
      .attr('x', 0)
      .attr('y', (d,i) => i*36)
      .style('font-family','roboto')
      .style('font-size',10)
      .attr('text-anchor', 'middle')
      .attr('fill', '#000')
      .text(d => d)

      // Work Type Name
      PreOutfitting
      .append('g')
      .attr('transform', `translate(140, 83)`)
      .selectAll('text')
      .data(this.dataSet)
      .enter()
      .append('text')
      .attr('x', 0)
      .attr('y', (d,i) => i*18)
      .style('font-family','roboto')
      .style('font-size',10)
      .attr('text-anchor', 'start')
      .attr('fill', '#333')
      .text(d => d.OUTFIT)

      PreOutfitting
      .append('g')
      .attr('transform', `translate(-20, 52)`)
      .selectAll('text')
      .data(this.props)
      .enter()
      .append('text')
      .attr('x', d => d.cx)
      .attr('y', 0)
      .style('font-family','roboto')
      .style('font-size',10)
      .attr('text-anchor', 'middle')
      .attr('fill', '#757575')
      .text(d => d.head1)
      console.log(this.props)

      PreOutfitting
      .append('g')
      .attr('transform', `translate(-20, 63)`)
      .selectAll('text')
      .data(this.props)
      .enter()
      .append('text')
      .attr('x', d => d.cx)
      .attr('y', 0)
      .style('font-family','roboto')
      .style('font-size',10)
      .attr('text-anchor', 'middle')
      .attr('fill', '#757575')
      .text(d => d.head2)


      let Items = PreOutfitting
      .append('g')
      .attr('transform', `translate(275,83)`)

      this.dataSet.forEach((data,r) => {
        let values_ = Object.values(data)
        
        let sub_Item_ = Items
        .append('g')
        .attr('transform', `translate(0,0)`)
        .selectAll('text')
        .data(values_.slice(2,8))
        .enter()

        sub_Item_
        .append('text')
        .attr('x', (d,i) => this.props[i].x)
        .attr('y', r*18)
        .style('font-family','roboto')
        .style('font-size',10)
        .attr('text-anchor', 'end')
        .attr('fill', '#757575')
        .text((d,i) => {
          if (i == 3 || i == 5 ) return ''
          else return d.toLocaleString()
        })
        
        let sub_prog_ = Items
        sub_prog_
        .append('rect')
        .attr('x', 150).attr('y', (r*18)-9).attr('width',170).attr('height',12).attr('stroke-width', 0).attr('fill', '#F4F5F5')

        sub_prog_
        .append('rect')
        .attr('x', 150).attr('y', (r*18)-9)
        .attr('width', 0)
        .attr('height',12).attr('stroke-width', 0).attr('opacity', 0.5)
        .attr('fill', () => {
          if (values_[5] == 100) return '#CDDC37'
          return '#B4E3FA'
        })
        .transition().duration(500).attr('width', (values_[5]*0.01) * 170)
    
        sub_prog_
        .append('text')
        .attr('x', 180).attr('y', r*18).style('font-family','roboto').style('font-size',10).attr('text-anchor', 'end').attr('fill', '#757575')
        .text(`${values_[5]}%`)
        .transition().duration(500).attr('x', 148 + (values_[5]*0.01) * 170)

        sub_prog_
        .append('rect')
        .attr('x', 435).attr('y', (r*18)-9).attr('width',170).attr('height',12).attr('stroke-width', 0).attr('fill', '#F4F5F5')
        
        sub_prog_
        .append('rect')
        .attr('x', 435).attr('y', (r*18)-9)
        .attr('width', 0)
        .attr('height',12).attr('stroke-width', 0).attr('opacity', 0.5)
        .attr('fill', () => {
          if (values_[7] == 100) return '#CDDC37'
          return '#B4E3FA'
        })
        .transition().duration(500).attr('width', (values_[7]*0.01) * 170)
    
        sub_prog_
        .append('text')
        .attr('x', 435).attr('y', r*18).style('font-family','roboto').style('font-size',10).attr('text-anchor', 'end').attr('fill', '#757575')
        .text(`${values_[7]}%`)
        .transition().duration(500).attr('x', 435 + (values_[7]*0.01) * 170)
      })
    }, 
  }
}
