<template>
  <div>
    <div class="button-box-wrap" :style="`width: ${width - 60}px;`">
      <div class="button-wrap">
        <div>
          <button class="custom-button" :class="value === 'ROS_ETA_FORECAST' ? 'active' : ''" @click="setSchedule('forecast')">
            Forecast
          </button>
        </div>
        <div>
          <button class="custom-button" :class="value === 'ROS_ETA_PLAN' ? 'active' : ''" @click="setSchedule('plan')">
            Plan
          </button>
        </div>
      </div>
      
      <div class="button-wrap">
        <div class="custom-radio">
          <input type="radio" id="all" name="all" value="All" v-model="dataType" @change="setData('all')">
          <label for="all" >All</label>
        </div>
        <div class="custom-radio">
          <input type="radio" id="except" name="except" value="except" v-model="dataType" @change="setData('except')">
          <label for="except">Except delivered PKGs</label>
        </div>
      </div>
      
    </div>
    <div :id="localId">
    </div>
  </div>
</template>

<script>

import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,

    FilterValues: {
      type: Object,
      default: () => ({
        MOD: 'MOD-NO',
        MOD_DESC: 'Module Description',
      })
    },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    ColumnProps: { type: Array, default: () => ([]) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()

      this.drawChart()
      this.drawTexts()
      this.complete()
    },

    drawChart() {
      this.Draw_Charts()
    },

  }
}
</script>
<style lang="stylus" scoped>

.button-box-wrap {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  .button-wrap {
    display: flex;
  }
}

.custom-button {
  background-color: #f5f5f5;
  border: none;
  color: #212121;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
}

.active {
  background-color: #5cbbf6;
  color: white;
  border: none;
}

.button5:hover {
  opacity: 0.7;
  border: none;
}

input[type=radio] {
  width: 10px;
  margin-right: 2px;
}

.custom-radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.custom-radio label{
  cursor: pointer;
}

</style>