// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Texts from '../../../../../../includes/mixins/Text_Draw.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Miscellaneous2 from '../../../../../DEMO/include/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../../includes/mixins/Movable.mixin'

// Custom Charts  
import lmx_Custom_Charts from         '../../../../include/ChartLibrary/Custom_Charts.mixin'
import lmx_Custom_BarCharts from         '../../../../include/ChartLibrary/Custom_BarCharts.mixin'
import lmx_Custom_Header from         '../../../../include/ChartLibrary/Custom_Header.mixin'
import lmx_Custom_SummaryTable from   '../../../../include/ChartLibrary/Custom_SummaryTable.mixin'

// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_draw           from './draw.mixin'
import lmx_drawBack       from './drawback.mixin'
import lmx_drawProgress   from './drawprogress.mixin'
import lmx_declares       from './declares'
import lmx_dataStyles     from './dataStyles.mixin'
import lmx_static         from './static'

export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Texts,
    gmx_Miscellaneous,
    gmx_Miscellaneous2,
    gmx_Movable,

    lmx_Custom_Charts,
    lmx_Custom_BarCharts,
    lmx_Custom_Header,
    lmx_Custom_SummaryTable,

    lmx_ReactiveEvents,
    lmx_draw,
    lmx_drawBack,
    lmx_drawProgress,
    lmx_declares,
    lmx_dataStyles,
    lmx_static,
  ],
}
