import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    
  }),


  methods: {

    WeekPerformance_01(selection, _data, _style) {

      _data.forEach((data,i) => {
      if (i ==0 || i == _data.length -1) {
        data.width = 35
        data.tColor = '#333'
       } else {
        data.width = 25
        data.tColor = '#bcbcbc'
       }
      })

      let weekStatus = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      
      weekStatus
      .append('text')
      .attr('x', 0).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Last Week')

      weekStatus
      .append('text')
      .attr('x', 140).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#44A9DF').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('This Week')

      weekStatus
      .append('line')
      .attr('x1', 35).attr('y1', 8).attr('x2', 110).attr('y2', 8).style('stroke', '#757575').style('stroke-width', 0.3)
      weekStatus
      .append('line')
      .attr('x1', 170).attr('y1', 8).attr('x2', 245).attr('y2', 8).style('stroke', '#757575').style('stroke-width', 0.3)

      let drawX = 0
      _data.forEach((d,i)=> {
        d.x = drawX

        weekStatus
        .append('text')
        .attr('x', drawX).attr('y', 22)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', d.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.name)

        weekStatus
        .append('rect')
        .attr('transform', `translate(${drawX - (d.width/2)},30)`)
        .attr('width', d.width).attr('height', 14)
        .attr('stroke-width', .3)
        .attr('stroke', () => { if (d.name == 'Total') return '#44A9DF'; else return '#bcbcbc' })
        .attr('fill', () => { if(d.name != 'Total' && d.value > 0) return '#F4F5F5'; else return '#fff' })

        weekStatus
        .append('text')
        .attr('id', `weekValue_${this.localId}_${i}`)
        .attr('transform', `translate(${drawX}, 38)`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(() => {
          if(d.value > 0) return d.value
        })
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${d.x}, 38) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${d.x}, 38) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => { 
          //
        })

        drawX += d.width + 3
        if(i==7) drawX += 10
      })
    },

    WeekPerformance_02(selection, _data, _style) {

      _data.forEach((data,i) => {
      if (i ==0 || i == _data.length -1) {
        data.width = 35
        data.tColor = '#333'
       } else {
        data.width = 25
        data.tColor = '#bcbcbc'
       }
      })

      let weekStatus = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      let drawX = 0
      _data.forEach((d,i)=> {
        d.x = drawX

        weekStatus
        .append('text')
        .attr('x', drawX).attr('y', 22)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', d.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.name)

        weekStatus
        .append('rect')
        .attr('transform', `translate(${drawX - (d.width/2)},30)`)
        .attr('width', d.width).attr('height', 14)
        .attr('stroke-width', .3)
        .attr('stroke', () => { if (d.name == 'Total') return '#44A9DF'; else return '#bcbcbc' })
        .attr('fill', () => { if(d.name != 'Total' && d.value > 0) return '#F4F5F5'; else return '#fff' })

        weekStatus
        .append('text')
        .attr('id', `weekValue_${this.localId}_${i}`)
        .attr('transform', `translate(${drawX}, 38)`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(() => {
          if(d.value > 0) return d.value
        })
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${d.x}, 38) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${d.x}, 38) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => { 
          //
        })

        drawX += d.width + 3
        if(i==7) drawX += 10
      })
    },


    WeekPerformance_03(selection, _data, _style) {

      _data.forEach((data,i) => {
      if (i ==0 || i == _data.length -1) {
        data.width = 35
        data.tColor = '#333'
       } else {
        data.width = 25
        data.tColor = '#bcbcbc'
       }
      })

      let weekStatus = selection
      .append('g')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      let drawX = 0
      _data.forEach((d,i)=> {
        d.x = drawX

        weekStatus
        .append('rect')
        .attr('transform', `translate(${drawX - (d.width/2)},30)`)
        .attr('width', d.width).attr('height', 14)
        .attr('stroke-width', .3)
        .attr('stroke', () => { if (d.name == 'Total') return '#44A9DF'; else return '#bcbcbc' })
        .attr('fill', () => { if(d.name != 'Total' && d.value > 0) return '#F4F5F5'; else return '#fff' })

        weekStatus
        .append('text')
        .attr('id', `weekValue_${this.localId}_${i}`)
        .attr('transform', `translate(${drawX}, 38)`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(() => {
          if(d.value > 0) return d.value
        })
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${d.x}, 38) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(${d.x}, 38) scale(1)`).style('fill', '#757575')
        })
        .on('click', () => { 
          //
        })

        drawX += d.width + 3
        if(i==7) drawX += 10
      })
      

    },
  }
}
