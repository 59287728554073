// Essential Mixins
import { SafeIdMixin }        from './safeID'
import gmx_Controller         from './Controller' 

// Global Drawings
import draw_Canvas             from './draw_Canvas'
import draw_Text               from './draw_Text'
import draw_Buttons            from './draw_Buttons'
import draw_ToolTip            from './draw_ToolTip'
import draw_SearchTool         from './draw_SearchTool'
import draw_Movable            from './draw_Movable'

// Global Functions
import func_Global             from './func_Global'
import func_Miscellaneous      from './func_Miscellaneous'
import func_Element            from './func_Element'

// Global Setting Values
import init_Timeline           from './init_Timeline.js'
import init_Skyline            from './init_Skyline.js'


export default {
  
  mixins: [
    SafeIdMixin, 
    gmx_Controller,

    // Global Drawings
    draw_Canvas,
    draw_Text, 
    draw_Buttons,
    draw_ToolTip,
    draw_SearchTool,
    draw_Movable,

    // Global Functions
    func_Global,
    func_Miscellaneous,
    func_Element,

    // set_InitValues
    init_Timeline,
    init_Skyline,

  ],

}
