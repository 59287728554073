<template>
  <div class="j_fileuploader">
    <div class="file-object-wrapper">
      <v-text-field
        v-model="files"
        hidden
        required
        style="display:none;"
        class="file-object-input"
        :rules="fileRules"
      ></v-text-field>
      <!-- <span class="label">File Upload</span> -->
      <!-- <dropzone
        class="j_fileuploader_dropzone"
        id="customdropzone"
        ref="dropzone"
        :options="dropzoneOptions"
        :include-styling="false"
        :useCustomSlot="true"
        @vdropzone-thumbnail="onThumbnail"
        @vdropzone-error="onError"
      > -->
      
      <dropzone
        class="j_fileuploader_dropzone"
        id="customdropzone"
        ref="dropzone"
        :options="dropzoneOptions"
        :include-styling="false"
        :useCustomSlot="true"
        @vdropzone-thumbnail="genImageThumb"
        @vdropzone-error="onError"
      >

        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">Choose file(s) or drag it here.</h3>
        </div>

        <!-- <div class="dropzone-custom-content">
          <div v-if="selectedItem.dataUrl" class="edit-thumb">
            <img :src="selectedItem.dataUrl" >
          </div>
          <h3 v-else class="dropzone-custom-title">Choose file(s) or drag it here.</h3>
        </div> -->
      </dropzone>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'

import '@/assets/stylus/ui/component/_jFileuploader.styl'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import Dropzone from 'vue2-dropzone'

var _SELF__ = null

export default {
  name: "j-fileupload",
  components: {
    Dropzone
  },
  props: {
    accepted: {
      type: String,
      default: () => 'image/*'
    },
    selectedItem: {
      type: Object
    }
  },
  beforeCreate() {
    _SELF__ = this
  },
  data: () => ({
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      maxFiles: 1,
      maxFilesize: 3000,
      autoQueue: false,
      init: function() {
        this.hiddenFileInput.removeAttribute("multiple");
        this.on("addedfile", function(file) {
          if (!!this.files && this.files.length > 1) this.removeFile(this.files[0])

          // check file extension, see:
          // http://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript
          let comps = file.name.split('.');
          if (comps.length === 1 || (comps[0] === '' && comps.length === 2)) return

          setTimeout(() => { _SELF__.onComplete(file) }, 500)

          let ext = comps.pop().toLowerCase()
          if (!['mov', 'mpeg', 'mp4', 'wmv'].includes(ext)) return

          let initScreenshot__ = () => {
            let w_ = 254
            let ratio_ = (w_ / video_.videoWidth)
            let h_ = Math.round(video_.videoHeight * ratio_)

            canvas_.width = w_
            canvas_.height = h_

            // video_.setAttribute('currentTime', 5)   // not working
            video_.currentTime = 5

            file.width = video_.videoWidth
            file.height = video_.videoHeight
          }
          let canvas_ = document.createElement('canvas')
          let ctx_ = canvas_.getContext('2d')
          let video_ = document.createElement('video')
          let objectURL_ = URL.createObjectURL(file)

          video_.setAttribute('src', objectURL_)
          video_.addEventListener('loadedmetadata', () => { initScreenshot__() })
          video_.addEventListener('canplay', () => {
            ctx_.drawImage(video_, 0, 0, canvas_.width, canvas_.height)

            let thumbURL_ = canvas_.toDataURL()
            let imgEl = file.previewElement.querySelector('img')
            imgEl.setAttribute('src', thumbURL_)

            _SELF__.onThumbnail(file, thumbURL_)
          })

          document.body.appendChild(video_)
          // video_.play()
        })
        this.on("maxfilesexceeded", function (file) {
          this.removeAllFiles();
          this.addFile(file);
        })
      },

      maxThumbnailFilesize: 20,
      thumbnailHeight: 140,
      addRemoveLinks: false,
      uploadMultiple: false,
      acceptedFiles: 'image/*'
    },
    fileRules: [v => !!v[0] || "file required"],
    files: [],
    totaluploadprogress: 0,
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      this.files = []
      this.dropzoneOptions.acceptedFiles = this.accepted
    },

    onComplete(file) {
      if(file.type == 'image/svg+xml') {
        let img = document.querySelector('.dz-image img')
        file.width = parseInt(img.naturalWidth)
        file.height = parseInt(img.naturalHeight)
        file.ext = 'svg'
      } else {
        file.ext = file.type.split('/')[1]
      }

      this.files = []
      // this.selectedItem.fileName = file.name
      this.files.push(file);
      // this.$emit('loading-start', this.files)
      this.$emit('complete', this.files)
    },
    onError(file, message, xhr) {
      let accepted_ = this.accepted.split('/')

      if(file.type.split('/')[0] != accepted_[0]) {
        this.files = []
        file.previewElement.children[3].innerText = 'Not matched to accepted file type.'
      }
    },
    onThumbnail(file, dataUrl) {
      this.$emit('thumbnail', { file, dataUrl })
    },    
    genImageThumb(file) {
      let temp_w_ = file.width
      let temp_h_ = file.height

      // ratio width
      let defaultWidth_ = 180
      let defaultHeight_ = 140

      if(file.width < file.height) {
        var ratio_ = (defaultWidth_ / file.width)
        var w_ = defaultWidth_
        var h_ = Math.round(file.height * ratio_)
        
      } else {
        ratio_ = (defaultHeight_ / file.height)
        w_ = Math.round(file.width * ratio_)
        h_ = defaultHeight_
      }

      let image_ = new Image()
      let canvas_ = document.createElement('canvas')
      let ctx_ = canvas_.getContext('2d')
      let i = file.width / defaultWidth_

      image_.onload = () => {
          this.$emit('loading-start', { file, dataUrl })

          let final = false
          temp_w_ = Math.round(temp_w_ / 2)
          temp_h_ = Math.round(temp_h_ / 2)

          // if this is last
          if(temp_w_ <= defaultWidth_ || temp_h_ <= defaultHeight_) {
            canvas_.width = w_
            canvas_.height = h_
            final = true
          } else {
            canvas_.width = temp_w_
            canvas_.height = temp_h_
          }

          ctx_.drawImage(image_, 0, 0, canvas_.width, canvas_.height)
          let dataUrl = canvas_.toDataURL('image/png')

          if(final) this.$emit('thumbnail', { file, dataUrl })
          else image_.src = dataUrl

          
          this.$emit('loading-end', { file, dataUrl })
      }

      image_.src = URL.createObjectURL(file)
    }

    // setThumbnail(dataUrl) {
    //   if(!dataUrl) return

    //   let dropzone_ = d3.select('#customdropzone')
    //   .append('div')
    //   .attr('class', 'dz-preview dz-image-preview manul')
    //   .append('div')
    //   .attr('class', 'dz-image')
    //   .append('img')
    //   .attr('data-dz-thumbnail', null)
    //   .attr('src', dataUrl)
    // }
  }
}
</script>