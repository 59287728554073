import * as d3 from 'd3'

export default {
  methods: {
    callFuncSearchFilter(selection) {
      selection
      .on('mouseover', (d, i, a) => {
        if(this.displayOption.searchOption == d.colName) return

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.search_circle__${directionEls_[1]}`)
        .style('stroke', this.eqStyle.search.over.stroke)
        .style('fill', this.eqStyle.search.over.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${directionEls_[1]}`)
        .style('fill', '#000')
      })
      .on('mouseout', (d, i, a) => {
        if(this.displayOption.searchOption == d.colName) return

        let directionEls_ = d3.select(a[i]).attr('id').split('__')

        d3.select(`#${this.localId}`)
        .select(`.search_circle__${directionEls_[1]}`)
        .style('stroke', this.eqStyle.search.out.stroke)
        .style('fill', this.eqStyle.search.out.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${directionEls_[1]}`)
        .style('fill', this.eqStyle.search.out.color)
      })
      .on('click', (d) => {
        if(this.displayOption.searchOption == d.colName) return

        let prevIndex_ = d.optionValues.findIndex(v_ => v_.colName == this.displayOption.searchOption)
        let index_ = d.optionValues.findIndex(v_ => v_.colName == d.colName)

        // set previous option to be unselected
        d3.select(`#${this.localId}`)
        .select(`.search_circle__${prevIndex_}`)
        .style('stroke', this.eqStyle.search.out.stroke)
        .style('fill', this.eqStyle.search.out.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${prevIndex_}`)
        .style('fill', this.eqStyle.search.out.color)

        // set current option to be selected
        d3.select(`#${this.localId}`)
        .select(`.search_circle__${index_}`)
        .style('stroke', this.eqStyle.search.selected.stroke)
        .style('fill', this.eqStyle.search.selected.fill)

        d3.select(`#${this.localId}`)
        .select(`.search_text__${index_}`)
        .style('fill', this.eqStyle.search.selected.color)

        this.displayOption.searchOption = d.colName
        this.equipmentFilter('search')
      })
    },

  }
}