import * as d3 from 'd3'

export default {
  methods: {
    drawNavButton() {
      let Menu_Back_Wrap = this.svg
      .append('g')
      .attr('id', 'Menu_Back')
      .style('visibility', 'hidden')
      .attr('transform', `translate(330, 98)`)
      let Menu_Back = Menu_Back_Wrap

      Menu_Back
      .append('image')
      .attr('class', 'Back_Btn')
      .attr('transform', `translate(0, 0)`)
      .style('cursor', 'pointer')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/back_btn.svg'))
      .call(this.eventBack) // --------------------------------------------------------------## btnGoBack
      Menu_Back
      .append('image')
      .attr('class', 'Home_Btn')
      .attr('transform', `translate(20, 0)`)
      .style('cursor', 'pointer')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/home_btn.svg'))
      .call(this.eventBackHome) // --------------------------------------------------------------## btnGoHome
    },
    drawOverall(expaned=false) {
      
      // let curid__ = this.curid[this.curstage]
      this.loadSvg('/Demo/BlockDivision/Hebron/Overall/Overall.svg', 'S1').then(source => {
        
        let Overall = this.innerSvg.select('#Overall')
        
        Overall
        .append('polygon')
        .attr('id', 'Overall_Mask')
        .attr('transform', `translate(580, 268) scale(1)`).attr('points', `99.5,142.75 113,130.63 122,82.56 168,76.53 226,72.52 246,12.01 275,0 300,13 321.5,67.5 353,84 368,115.5 405,103.5 435,77 459.5,83.5 466,111 428,211.5 435.5,238 466,241 460.5,283 419,330.5 419.5,407 436,482.5 439.5,532 
        395.5,583.5 295,608 203.5,601.5 99.5,569.5 36.5,448 59,403 97.5,397.5 113.5,320 132,293.5 100.5,286.5 78,255 44.5,237.5 0,192 0,153 34.5,123.5 74.5,131`)
        .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer')
        .call(this.eventGotoDivision_Stage1)
        //-----------------------------------------------Move to Stage2 Mask
        Overall
        .append('polygon')
        .attr('id', 'S2L1_UD_Mask')
        .attr('transform', `translate(471, 424) scale(1)`).attr('points', `0.28,88.04 36,59.9 36,33 54.73,0 154,14.13 154,63 230.08,81.03 265.3,59.9 278.68,38.77 308,39.48 308,128 233.61,184 0,131.73`)
        .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
        .call((s) => { this.eventGotoDivision_Stage2(s, 'S2L1_UD') })//selection으로 인자값을 준다!
        Overall
        .append('polygon')
        .attr('id', 'S2L2_MD_Mask')
        .attr('transform', `translate(470, 663) scale(1)`).attr('points', `0,65 87.5,0 298,65 298,116 226,156 0,103`)
        .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
        .call((s) => { this.eventGotoDivision_Stage2(s, 'S2L2_MD') })
        Overall
        .append('polygon')
        .attr('id', 'S2R1_LD_Mask')
        .attr('transform', `translate(895, 475) scale(1)`).attr('points', `0,70 73.5,0 261,41.5 303,66.5 308,96.5 236.5,155 141,141.5 128,129.5 0,102`)
        .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
        .call((s) => { this.eventGotoDivision_Stage2(s, 'S2R1_LD') })
        Overall
        .append('polygon')
        .attr('id', 'S2R2_CD_Mask')
        .attr('transform', `translate(870, 677) scale(1)`).attr('points', `0,60 69,5 190.5,0 321,47 330,72 244.5,144 0,98.5`)
        .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
        .call((s) => { this.eventGotoDivision_Stage2(s, 'S2R2_CD') })

        this.drawProgress('Overall', null)
        this.drawMainProgress()
        if(expaned) {

          this.hiddenGroupID('#Overall_Mask', 0, 10)
          let selection = d3.select(`#S1_BOTTOM`)
          selection.transition().duration(300).style('opacity',0.3)
          d3.selectAll('#Progress_Chart').remove();
          setTimeout(() => {
            selection.transition().duration(500).style('opacity',0)

            this.moveItemTo('S1')
            this.displayGroupID(`#Menu_Back`, 10, 10, 1)
            this.displayGroupID(`#S2L1_UD_Mask`, 10, 10, 0)
            this.displayGroupID(`#S2L2_MD_Mask`, 10, 10, 0)
            this.displayGroupID(`#S2R1_LD_Mask`, 10, 10, 0)
            this.displayGroupID(`#S2R2_CD_Mask`, 10, 10, 0)
            d3.select('#Progress_Chart').remove();
            d3.select('#overall_prog').remove();
          })
          setTimeout(() => {
            this.drawProgress('4_Decks', null)
          },300)
        }
        d3.select('#Menu_Back').bringToFront() //Menu_Back을 innersvg에서 따로 작동되게 부르자
      })
      
      this.eventGotoDivision_Stage1 = (selection) => {
        selection
        .on('mouseover', () => { d3.select(`#Overall_Mask`).transition().style('opacity', 0.5) })
        .on('mouseout', () => {  d3.select(`#Overall_Mask`).transition().style('opacity', 0) })
        .on('click', () => {
          d3.selectAll('#Progress_Chart').remove();
          this.hiddenGroupID('#Overall_Mask', 0, 10)
          let selection = d3.select(`#S1_BOTTOM`)
          selection.transition().duration(300).style('opacity',0.3)

          setTimeout(() => {
            selection.transition().duration(500).style('opacity',0)
            this.moveGroupTo('S1').then(() => { //setTimeout으로 하지않고 promise로하자 언제끝났는지 알고싶으니까
              this.moveItemTo('S1') //내가 setTimeout으로 조정하면된다
            })
            this.displayGroupID(`#Menu_Back`, 10, 10, 1)
            this.displayGroupID(`#S2L1_UD_Mask`, 10, 10, 0)
            this.displayGroupID(`#S2L2_MD_Mask`, 10, 10, 0)
            this.displayGroupID(`#S2R1_LD_Mask`, 10, 10, 0)
            this.displayGroupID(`#S2R2_CD_Mask`, 10, 10, 0)
            d3.select('#Progress_Chart').remove();
            d3.select('#overall_prog').remove();
          }, 800)
          setTimeout(() => {
            this.drawProgress('4_Decks', null)
          }, 1500);
          this.curid.push('S1')
          this.curstage ++
          
        })
      }
      this.eventGotoDivision_Stage2 = (selection, id) => {//id 값으로 인자값 받을수 있다
        selection
        .on('mouseover', () => { d3.select(`#${id}_Mask`).transition().style('opacity', 0.5) })
        .on('mouseout', () => { d3.select(`#${id}_Mask`).transition().style('opacity', 0) })
        .on('click', () => {
          d3.event.preventDefault();
          this.hiddenGroupID(`#${id}_Mask`, 0, 10)
          this.loadSvg(`/Demo/BlockDivision/Hebron/Overall/${id}.svg`).then(source => {
            this.innerSvg
            .append('image')
            .attr('id', 'Keyplan')
            .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/BlockDivision/Hebron/Keyplan/${id}.jpg`)
            .attr('transform', `translate(19, 131)`)
            
            //-----------------------------------------------Move to Stage2 -M and -D Mask Block Division
            this.moveGroupTo(`${id}`, 'self').then(() => {
              this.moveGroupTo(`${id}`, 'nonself')
              this.Stage2_Division_Mask()
              this.displayGroupID(`#${id}_M_Mask`, 10, 10, 0)
              this.displayGroupID(`#${id}_D_Mask`, 10, 10, 0)
            })
            this.drawProgress('selectDeck', id)
            d3.select('#Menu_Back').bringToFront()
            
            this.curid.push(id)
            this.curstage ++
          })
        })
      }
      this.eventGotoDivision_Stage3 = (selection, id) => {
        selection
        .on('mouseover', () => { d3.select(`#${id}_Mask`).transition().style('opacity', 0.5) })
        .on('mouseout', () => { d3.select(`#${id}_Mask`).transition().style('opacity', 0) })
        .on('click', () => {
          this.onmoving = true

          //-----------------------------------------------Move to Stage2 -M and -D Mask Block Division 
          this.innerSvg
          .append('image')
          .attr('id', 'Keyplan')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/BlockDivision/Hebron/Keyplan/${id}.jpg`)
          .attr('transform', `translate(19, 131)`)      
          this.moveItemTo(`${id}`, 'self')

          let parentIDs = id.split('_')
          // console.log(parentIDs)
          d3.selectAll(`[id*="${parentIDs[0]}"]`).each((_, i, a) => {
            this.hiddenGroupID(`#${parentIDs[0]}_${parentIDs[1]}_M_Mask`, 0, 10)
            this.hiddenGroupID(`#${parentIDs[0]}_${parentIDs[1]}_D_Mask`, 0, 10)
            
            let target = d3.select(a[i])
            // console.log(target)
            if(target.node().tagName != 'image') return 
            if(target.node().id.includes(id)) return 
            // console.log(d3.select(a[i]).node().tagName)
            target.transition().style('visibility', 'hidden')
            d3.select('#Progress_Chart').remove();
          })
          setTimeout(() => {
            let BlockData = this.positioned.filter(d => d.Blk && d.ID.includes(parentIDs[0],parentIDs[1]) && d.Level.includes(parentIDs[2]))
            console.log(BlockData)
            // console.log(BlockData)
            BlockData.forEach((d,i) => { 
              
              let imageId = d3.select(`#${d.ID}`) 
              imageId.on('mouseover', () => { 
                if(this.onmoving) return
                d3.select(`#${d.ID}`).transition().style('opacity', 0.5).style('cursor', 'pointer') 
              })
              imageId.on('mouseout', () => {
                if(this.onmoving) return
                d3.select(`#${d.ID}`).transition().style('opacity', 1).style('cursor', 'pointer') 
              })
              imageId.on('click', () => {
                if(this.onmoving) return
                let request_ = {
                  dataType: 'row',
                  action: {
                    type: 'direct',
                    target: 'slide-modal',
                    component: 'ServiceSvgTabs',
                    id: 546,
                    no: 'LIBSVGTB-0021'
                  },
                  filters: {
                    BLOCK: d.Blk,
                    ...this.FilterValues,
                    ...this.inFilters
                  },
                  iFilters: {}
                }
        
                this.$emit('request-action', request_)
              })
              let blockName = this.innerSvg
              .append('g')
              .attr('id','blockNames')
              .attr('transform', `translate(0, 0)`)
              
              blockName
              .append('rect')
              .attr('x', d.tx - 15).attr('y', d.ty-4)
              .attr('width', 30).attr('height', 16)
              .attr('stroke', '##BDBCBC').attr('stroke-width', 0.5)
              .attr('fill', '#8DBA2D')
              .attr('fill', ()=> d.Status == 'erec' ? '#8DBA2D' : d.Status == 'assemb' ? '#FBC02D' : '#9C27B0')
              blockName
              .append('text')
              .attr('transform', `translate(${d.tx}, ${d.ty})`)
              .style('font-family', 'roboto').style('font-size', 10).style('fill', '#fff').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
              .text(`${d.Blk}`)
            })
            this.displayGroupID(`#Menu_Back`, 10, 10, 1)
            this.drawProgress('selectDivision', id)
            setTimeout(() => { this.onmoving = false }, 250)
            this.curid.push(id)
            this.curstage ++
          }, 800);
        })
      }
    },
    async drawGotoBack(){
      this.curstage --
      await this.backFunctions[this.curstage]()
      this.curid.pop()
    },
    drawGotoHome(){
      if(this.curstage <= 1) {
        this.backFunctions[0]()
      } else {
        this.drawOverall(false)
        this.hiddenGroupID('#Menu_Back', 0, 10)
      }
      this.curstage = 0
      this.curid.length = 0
      // this.curid = []
      // console.log(this.curstage,this.curid,this.curid.length)
    },
    Stage2_Division_Mask(){//-----------------------------------------------Move to Stage2 -M and -D Mask Block Division 

      this.innerSvg
      .append('polygon')
      .attr('id', 'S2L1_UD_M_Mask')
      .attr('transform', `translate(608, 260) scale(1)`).attr('points', `0,111 56.5,63 68,26 139.5,0 192,12 232,5 232,52 337,111 405,99 413,188 289.5,277 0,127`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
      .call((s) => { this.eventGotoDivision_Stage3(s, 'S2L1_UD_M') })
      this.innerSvg
      .append('polygon')
      .attr('id', 'S2L1_UD_D_Mask')
      .attr('transform', `translate(612, 581) scale(1)`).attr('points', `11,102.5 136,0 225.5,41.5 230.5,69.5 328,111.5 387,118.5 421,176.5 292.5,277 0,141.5`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
      .call((s) => { this.eventGotoDivision_Stage3(s, 'S2L1_UD_D') })

      this.innerSvg
      .append('polygon')
      .attr('id', 'S2L2_MD_M_Mask')
      .attr('transform', `translate(616, 306) scale(1)`).attr('points', `0,98.75 91,30.63 124.5,37.06 170.5,0.03 214.5,31.52 241.5,79.01 391,150.5 435,154.5 435,183 358,242 285.5,256 0,120.5`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
      .call((s) => { this.eventGotoDivision_Stage3(s, 'S2L2_MD_M') })
      this.innerSvg
      .append('polygon')
      .attr('id', 'S2L2_MD_D_Mask')
      .attr('transform', `translate(620, 563) scale(1)`).attr('points', `0,120 150.5,0 238,45.5 270.5,86 349.5,105 416,143.5 395,177 401,199.5 350.5,244 280.5,264 232,241.5 167,192 117.5,170 58.5,175`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
      .call((s) => { this.eventGotoDivision_Stage3(s, 'S2L2_MD_D') })

      this.innerSvg
      .append('polygon')
      .attr('id', 'S2R1_LD_M_Mask')
      .attr('transform', `translate(545, 269) scale(1)`).attr('points', `0,122.25 145,0.13 186,22.5 186,50 411,158.5 411,190 301.5,275`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
      .call((s) => { this.eventGotoDivision_Stage3(s, 'S2R1_LD_M') })
      this.innerSvg
      .append('polygon')
      .attr('id', 'S2R1_LD_D_Mask')
      .attr('transform', `translate(553, 538) scale(1)`).attr('points', `0,111.5 137.5,0 238,46 244,65 295.5,85.5 359,91 429,148.5 300.5,257 238,251 134,196.5 0,150`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
      .call((s) => { this.eventGotoDivision_Stage3(s, 'S2R1_LD_D') })

      this.innerSvg
      .append('polygon')
      .attr('id', 'S2R2_CD_M_Mask')
      .attr('transform', `translate(559, 292) scale(1)`).attr('points', `0,82.5 102.5,0 212,44.5 254.5,30.5 319.5,63.5 301.5,104.5 363.5,137.5 370,173.5 327.5,209 218,183.5 152,189.5 30,131`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
      .call((s) => { this.eventGotoDivision_Stage3(s, 'S2R2_CD_M') })
      this.innerSvg
      .append('polygon')
      .attr('id', 'S2R2_CD_D_Mask')
      .attr('transform', `translate(547, 563) scale(1)`).attr('points', `0,94 121.5,0 196,26.5 304.5,41.5 440,122.5 440,151 308,253 160,202.5 0,126.5`)
      .attr('fill', '#fff').style('opacity', 0).style('cursor', 'pointer') .style('visibility', 'hidden')
      .call((s) => { this.eventGotoDivision_Stage3(s, 'S2R2_CD_D') })
    }
  }
}