import * as d3 from 'd3'

export default {

  methods: {
    
    setEvent_Others() {

      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Deckbox_ModuleDivision = (selection) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Area_Summary) return
          d3.select(`#Overall_Deckbox_Group`).transition().duration(300).style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Area_Summary) return
          d3.select(`#Overall_Deckbox_Group`).transition().duration(300).style('opacity', 1)
        })
        .on('click', () => {
          if(this.stage != this.STG_Area_Summary) return

          d3.select(`#Overall_Deckbox_Group`).transition().duration(300).style('opacity', 1)
          this.stage = this.STG_TRANS
          this.removeSvg(this.SVG_AreaSummary) // Eemove SVG

          // Hidden         : Hull SideBlocks
          this.svgModeling
          .transition()
          .duration(1200)
          .attr('x', 0).attr('y', 0)
          .attr('width', Math.round(this.Canvas.CanvasWidth * 1))
          .attr('height', Math.round(this.Canvas.CanvasHeight * 1))

          // Explode         : Hull Module
          this.MODULE.Deckbox.filter(f => f.type == 'Module').forEach(mod => {
            d3.select(`#${mod.module}`)
            .transition().duration(1200).attr('transform', `translate(${mod.x2}, ${mod.y2}) scale(1)`).style('opacity', 1)
          })

          // Display         : Module_No_Group
          this.displayGroupID('#Module_No_Group', 1200, 300, 1)

          // Canvas           : ModuleDivision
          setTimeout(() => {
            this.Canvas_ModuleDivision()
          }, 1500);

          setTimeout(() => {
            this.stage = this.STG_Module_Division             ;this.StageCode() //////////////////////////// this.stage

            this.MODULE.Deckbox.filter(f => f.type == 'Module').forEach(mod => {
              d3.select(`#${mod.module}`)
              .style('cursor', 'pointer')
            })
          }, 1500);
        })
      }



      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Topside_ModuleDivision = (selection) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Area_Summary) return
          d3.select(`#Overall_Topside_Group`).transition().duration(300).style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Area_Summary) return
          d3.select(`#Overall_Topside_Group`).transition().duration(300).style('opacity', 1)
        })
        .on('click', () => {
          if(this.stage != this.STG_Area_Summary) return

          d3.select(`#Overall_Topside_Group`).transition().duration(300).style('opacity', 1)
          this.stage = this.STG_TRANS
          this.removeSvg(this.SVG_AreaSummary) // Eemove SVG

          // Hidden         : Hull SideBlocks
          this.svgModeling
          .transition()
          .duration(1200)
          .attr('x', 0).attr('y', 0)
          .attr('width', Math.round(this.Canvas.CanvasWidth * 1))
          .attr('height', Math.round(this.Canvas.CanvasHeight * 1))

          // Explode         : Hull Module
          this.MODULE.Topside.filter(f => f.type == 'Module').forEach(mod => {
            d3.select(`#${mod.module}`)
            .transition().duration(1200).attr('transform', `translate(${mod.x2}, ${mod.y2}) scale(1.3)`).style('opacity', 1)
          })

          // Display         : Module_No_Group
          this.displayGroupID('#Module_No_Group', 1200, 300, 1)

          // Canvas           : ModuleDivision
          setTimeout(() => {
            this.Canvas_ModuleDivision()
          }, 1500);

          setTimeout(() => {
            this.stage = this.STG_Module_Division             ;this.StageCode() //////////////////////////// this.stage

            this.MODULE.Topside.filter(f => f.type == 'Module').forEach(mod => {
              d3.select(`#${mod.module}`)
              .style('cursor', 'pointer')
            })
          }, 1500);
        })
      }

    },
  }
}