import * as d3 from 'd3'

export default {
  methods: {
    setEvent_Block() {
      this.Goto_Block_Summary = (selection, d) => {
        selection
        .on('mouseover', () => {
          if(![this.STG_Lv3_Block_Division, this.STG_Lv4_Block_Division].includes(this.stage)) return
          d3.select(`#Blk_${d.Blk}`).transition().style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Lv3_Block_Division && this.stage != this.STG_Lv4_Block_Division) return
          d3.select(`#Blk_${d.Blk}`).transition().style('opacity', 1)
        })
        .on('click', () => {
          if(this.stage != this.STG_Lv3_Block_Division && this.stage != this.STG_Lv4_Block_Division) return

          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceSvgTabs',
              id: 286,
              no: 'LIBSVGTB-0020'
            },
            filters: { 
              GWBS_LV1: d.Lv1, //받을값을 보낸다
              GWBS_LV2: d.Lv2,
              GWBS_LV3: d.Lv3,
              GWBS_LV4: d.Lv4,
              BLOCK: d.Blk,
              MBLOCK: d.Blk,
              DESC: '',
              // BD_LV3:d.BD_LV3,//this.DataItems에 BD_LV3칼럼이 있어야한다!칼럼 이사님과 확인하기
              // DESC: `${d.AREA} (${d.STATUS})`,
              
            },
            iFilters: {
              filterString : '',
              inputFilter  : ''
            }
          }
          // console.log(request_.filters)
          this.$emit('request-action', request_)
        })
      }
    },
  }
}