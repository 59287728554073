import * as d3 from 'd3'

export default {
  data: () => ({
  }),

  methods: {

    drawChart() {

      let drawPointY = 0
      let deltaValue = 0
      let gridLineData = [
                          {x: 0,                            value: '0%'},
                          {x: this.Delta.DeltaBarLength/2,  value: '50%'},
                          {x: this.Delta.DeltaBarLength,    value: '100%'},
      ]
      let vLine = (this.DataItems.length * this.Delta.DeltaBarDistance) - this.Delta.DeltaGridSpace

      let deltaDiscBarChart = this.svg
      .append('g')
      .attr('transform', `translate(${this.Delta.DeltaChartX}, ${this.Delta.DeltaChartY})`)

          let gridLine = deltaDiscBarChart
          .append('g')
          .attr('transform', `translate(80, 70 )`)

              .selectAll('line')
              .data(gridLineData)
              .enter()
              .append('line')
              .attr('x1', d => d.x)
              .attr('y1', - this.Delta.DeltaGridSpace)
              .attr('x2',  d => d.x)
              .attr('y2', vLine)
              .attr('stroke-width', this.Delta.DeltaGridStroke)
              .attr('stroke', this.Delta.DeltaGridColor)

          let gridText = deltaDiscBarChart
          .append('g')
          .attr('transform', `translate(80, 70 )`)

              .selectAll('text')
              .data(gridLineData)
              .enter()
              .append('text')
              .attr('x', d => d.x)
              .attr('y', -this.Delta.DeltaGridSpace - 2)
              .attr('fill',this.Delta.DeltaGridColor)
              .style('font-family', this.Delta.DeltaChartFont)
              .style('font-size',9)
              .attr('text-anchor', 'middle')
              .text(d => d.value)
        
          let barChart = deltaDiscBarChart
          .append('g')
          .attr('transform', `translate(80, 70 )`)



          // Draw the Bar Charts
          this.DataItems.forEach((data,i) => {

            deltaValue = data.actual - data.plan
            barChart
              .append('text')
              .attr('x', - 5)
              .attr('y', drawPointY + 12)
              .style('font-size',this.Delta.DeltaTitleSize).style('font-family',this.Delta.DeltaChartFont).attr('fill', this.Delta.DeltaTitleColor).attr('text-anchor', 'end')
              .text(data.title)

            barChart 
              .append('rect')
              .attr('x', 0)
              .attr('y', drawPointY)
              .attr('rx', this.Delta.DeltaBarRadius).attr('ry', this.Delta.DeltaBarRadius).attr('height', this.Delta.DeltaBarHeight)
              .attr('width', (data.plan/100)*this.Delta.DeltaBarLength)   
              .attr('fill', this.Delta.DeltaBarPlan)
              
            barChart
              .append('text')
              .attr('x', ((data.plan/100)*this.Delta.DeltaBarLength + 11))
              .attr('y', drawPointY - 4)
              .style('font-size',this.Delta.DeltaPlanSize).style('font-family',this.Delta.DeltaChartFont).attr('text-anchor', 'end').attr('fill', this.Delta.DeltaPlanColor)
              .text(`${(data.plan.toFixed(1))}%`)

            barChart 
              .append('rect')
              .attr('x', 0)
              .attr('y', drawPointY)
              .attr('rx', this.Delta.DeltaBarRadius).attr('ry', this.Delta.DeltaBarRadius).attr('height', this.Delta.DeltaBarHeight)
              .attr('width', 0)
              .attr('fill', `url(#${deltaValue < 0 ? 'Behind_Gradient' : 'Ahead_Gradient'}___${this.localId})`)
              .style('filter', `url(#barDropShadow___${this.localId})`)
              .transition().duration(500).attr('width', (data.actual/100)*this.Delta.DeltaBarLength)
            
            if (data.actual < 30) {
                barChart
                  .append('text')
                  .attr('x', ((data.actual/100) * this.Delta.DeltaBarLength + 9))
                  .attr('y', drawPointY + (this.Delta.DeltaBarHeight/2) + 2).style('font-size',this.Delta.DeltaGridSize)
                  .style('font-family',this.Delta.DeltaChartFont).attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('fill', this.Delta.DeltaGridColor).text(`${(data.actual.toFixed(1))}%`)
              } else {
                barChart
                  .append('text')
                  .attr('x', 0)
                  .attr('y', drawPointY + (this.Delta.DeltaBarHeight/2) + 3)
                  .style('font-size',this.Delta.DeltaActualSize).style('font-family',this.Delta.DeltaChartFont).attr('text-anchor', 'end').attr('fill', this.Delta.DeltaActualColor).text(`${(data.actual.toFixed(1))}%`)
                  .transition().duration(500).attr('x', ((data.actual/100)*this.Delta.DeltaBarLength-2))
            }

            // Delta Icon & Value
            barChart
              .append('image')
              .attr('id', `deltaArrow${i}___${this.localId}`)
              .attr('x', Number(this.Delta.DeltaBarLength) + 32).attr('width', 15).attr('height', this.Delta.DeltaBarHeight)
              .attr('y', drawPointY + 1)
              
              if (deltaValue < 0) d3.select(`#deltaArrow${i}___${this.localId}`).attr('xlink:href', require('../../../../src/assets/svg/delta_down_arrow.svg'))
              else d3.select(`#deltaArrow${i}___${this.localId}`).attr('xlink:href', require('../../../../src/assets/svg/delta_up_arrow.svg'))

            barChart
              .datum(deltaValue)
              .append('text')
              .attr('id', `deltaValue${i}___${this.localId}`)
              .attr('x', Number(this.Delta.DeltaBarLength) + 30)
              .attr('y', drawPointY + 12).style('font-size', 11).style('font-family','roboto').style('font-weight', 500).attr('text-anchor', 'end')
              .attr('fill', function() { if(deltaValue < 0) {return '#FF75A5'} else {return '#4CAE4E'} })
              .text(deltaValue.toFixed(1))
              .transition().duration(1000)
              .tween("text", (d) => {
                let d__ = d3.interpolateNumber(0, d)
                return function(t) {
                  d3.select(`#deltaValue${i}___${this.localId}`).text(d__(t).toFixed(1))
                }
              })

            // this.drawDelta(barChart, deltaValue, drawPointY)

            drawPointY += this.Delta.DeltaBarDistance
      })
    },

    // drawDelta(_barChart, _deltaValue, _drawPointY) {
    //   // console.log(_barChart)
    //   // // d3.select(_barChart)
      
    //   if (this.Delta.DeltaChartShape == 'arrow') {
    //       if(_deltaValue < 0) {

            
    //         // arrow-down
    //         _barChart
    //         .append('path')          // attach a path
    //         .style('fill', 'FF75A5')     // remove any fill colour
    //         .attr('d', `
    //           M ${3},${6} 
    //           h ${3}
    //           v ${5}
    //           l ${3.4},${0}
    //           l ${-4.9},${6}
    //           l ${-5.6},${-6} 
    //           h ${3} 
    //           l ${0},${-5} 
    //           h ${4} 
    //         `);
        
    //       } else {
    //         // arrow-up
    //         _barChart
    //         .append('path') // colour the line
    //         .style('fill', '4CAE4E')     // remove any fill colour
    //         .attr('d', `
    //           M ${3},${16}  
    //           h ${3} 
    //           v ${-5} 
    //           l ${3.4},${0} 
    //           l ${-4.8},${-6} 
    //           l ${-6},${6}  
    //           h ${3} 
    //           l ${0},${5}
    //           h ${4}
    //         `); 

    //       }
    //     } else { 

    //       // Triangle
    //       if (_deltaValue < 0) {
            
    //         _barChart // triangle down
    //         .append('path')          // attach a path
    //         .style('stroke', '#FF75A5')  // colour the line
    //         .style('fill', '#FF75A5')     // remove any fill colour
    //         .attr('d', `
    //           M ${0},${8}, 
    //           L ${4},${14},
    //           L ${8},${8}
    //         `)  // path commands 
            
    //       } else {
            
    //         _barChart // triangle up
    //         .append('path')          // attach a path
    //         .style('stroke', '4CAE4E')  // colour the line
    //         .style('fill', '4CAE4E')     // remove any fill colour
    //         .attr('d', `
    //           M ${0},${11.5}, 
    //           L ${4},${5}, 
    //           L ${8},${11.5}
    //         `)  // path commands
    //       }
    //   }
    // },

  }
}
