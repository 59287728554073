import * as d3 from 'd3'

export default {
  methods: {
    drawBackground() {
      let backgroundImage = this.chartArea
      .append('g')
      .attr('class','backgroundImage')

      backgroundImage
      .append('image')
      .attr('class', 'image_yard_background_1')
      .attr('transform', `translate(0, 0)`)
      .style('opacity', 0)

      backgroundImage
      .append('image')
      .attr('class', 'image_yard_background_2')
      .attr('transform', `translate(0, 0)`)
      .style('opacity', 1)
    },
    loadBackground() {

      let opstatus = parseInt(d3.select('.image_yard_background_1').style('opacity'))
      
      if(opstatus === 0) {
        var fadeIn = '.image_yard_background_1'
        var fadeOut = '.image_yard_background_2'
      } else {
        fadeIn = '.image_yard_background_2'
        fadeOut = '.image_yard_background_1'
      }
      
      switch(this.stage) {
        case 'OverAll':
          var bgname = 'site'
          break
      
        case 'Hull':
          bgname = 'hull'
          break

        case 'Topside':
          bgname = 'topside'
          break
  
        case 'Modules':
          bgname = 'modules'
          break

        case 'Common':
          bgname = 'common'
          break
        case 'LQ':
          bgname = 'lq'
          break
      }

      d3.select(fadeOut)
      .transition().duration(500)
      .attr('transform', `translate(0, 0) scale(1)`)
      .style('opacity', 0)

      d3.select(fadeIn)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Images/${bgname}.jpg`)
      .transition().delay(150).duration(750)
      .attr('transform', `translate(-30, -30) scale(1.05)`)
      .style('opacity', 1)

      return new Promise(resolve => {
        setTimeout(() => { resolve(true) }, 500)
      })
    }
  }
}