import Defs from '../../../../../../includes/primitives/Color_Defs'

export default {
  data: () => ({
    dataSet: [],
    lineChartData: [],

    activity: 'MC_PLAN',
    
    inputFilter: {
      values: [
        { colName: 'SUBSYSTEM'    , text: 'Subsystem' , value: '', x: 0   , textWidth: 67, on: true },
        { colName: 'TAG'          , text: 'Tag'       , value: '', x: 72  , textWidth: 34, on: false },
        { colName: 'PUNCH_NUMBER' , text: 'Punch'     , value: '', x: 111 , textWidth: 45, on: false },
      ]
    },
    filterCodes: {
      Disciplines: {
        attrs: { type: 'multi', x: 0, y: 0, width: 150, height: 220, labelWidth: 72, textWidth: 68 },
        values: [
          { colName: 'DISCIPLINE', text: 'Electrical' , value: 'Electrical' , on: true },
          { colName: 'DISCIPLINE', text: 'HVAC'       , value: 'HVAC'       , on: true },
          { colName: 'DISCIPLINE', text: 'ICSS'       , value: 'ICSS'       , on: true },
          { colName: 'DISCIPLINE', text: 'Instrument' , value: 'Instrument' , on: true },
          { colName: 'DISCIPLINE', text: 'Marine'     , value: 'Marine'     , on: true },
          { colName: 'DISCIPLINE', text: 'Mechanical' , value: 'Mechanical' , on: true },
          { colName: 'DISCIPLINE', text: 'Process'    , value: 'Process'    , on: true },
          { colName: 'DISCIPLINE', text: 'Structure'  , value: 'Structure'  , on: true },
          { colName: 'DISCIPLINE', text: 'Telecom'    , value: 'Telecom'    , on: true },
          { colName: 'DISCIPLINE', text: 'Utility'    , value: 'Utility'    , on: true },
        ]
      },
      Filters: {
        attrs: { type: 'multi-pretended', x: 155, y: 0, width: 150, height: 120, labelWidth: 50, textWidth: 130 },
        values: [
          { colName: 'MOC_OPEN'   , text: 'MOC Open Status'       , value: 'Y', on: false },
          { colName: 'PMCS_OPEN'  , text: 'PMCS Open Status'      , value: 'Y', on: false },
          // { colName: 'DBSPO'      , text: 'Deckbox Shore Power On', value: 'Y', on: false },
          // { colName: 'FLOAT_OFF'  , text: 'Float Off'             , value: 'Y', on: false },
          { colName: 'OBS_OPEN'   , text: 'Observation Open'      , value: 'Y', on: false },
        ]
      },
      Schedule: {
        attrs: { type: 'single', x: 310, y: 0, width: 150, height: 80, labelWidth: 65, textWidth: 60 },
        values: [
          { colName: 'DATE', text: 'MC Plan' , value: 'MC_PLAN' , on: true, disable: false},
          { colName: 'DATE', text: 'Forecast', value: 'FORECAST', on: false, disable: true },
          { colName: 'DATE', text: 'WD Plan' , value: 'WD_PLAN' , on: false, disable: true },
        ]
      }
    },
    skyline: {
                sizeType    : 'auto',
                fixedSize   : 450,
                minSize     : 800,
                startFloat  : 2,      // Fixed
                endFloat    : 2,      // Fixed
                weekEnd     : 'friday',
                font        : 'roboto',
                marginTop   : 50     ,

                box: {
                            font      : 'roboto', 
                            width     : 38,     // Fixed
                            height    : 15,     // Fixed
                            gap       : 1,      // Fixed
                },
                line : {
                            display   : 'Y',
                            height    : 300,
                            gap       : 1,
                },
                legend: {
                            x         : 25,
                            y         : 50,
                            font      : 'roboto', 
                            group: {
                                      tSize     : 11,
                                      tColot    : '#333',
                                      lineSpace : 15,
                            },
                            item: {
                                      tSize     : 10,
                                      tColor    : '#757575',
                                      space     : 10,
                            },
                            notes: {
                                      x         : 10,
                                      y         : 150,
                                      tSize     : 10,
                                      tColor    : '#bcbcbc',
                                      space     : 15,
                            },
                },
                dropdown: {
                            x         : 25,
                            y         : 90,
                            option: [
                                      {text: 'Steel Sutting',   value: 'SC'},
                                      {text: 'Block Assembly',  value: 'Assembly'},
                                      {text: 'Painting',        value: 'Painting'},
                                      {text: 'Block Erection',  value: 'Erection'},
                            ],
                },
    },
    timeline: { 
                week        : [],
                month       : [],
                year        : [],
                plan        : [],
                actual      : [],
                scale       : null,     

                weekCount   : [],
                weekSize    : null,    // Auto
                length      : null,    // Auto
                height      : null,    // Auto
                font        : 'roboto', 
                baseX       : 115,
                baseY       : 801,

                style: {
                        week: {
                                display   : 'Y',
                                height    : 15,
                                line      : 12,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 9,
                                tColor    : '#757575',
                        },
                        month: {
                                display   : 'Y',
                                height    : 14,
                                line      : 15,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 10,
                                tColor    : '#333',
                        },
                        year: {
                                display   : 'Y',
                                height    : 10,
                                line      : 20,
                                sWidth    : 0.5,
                                sColor    : '#BCBCBC',
                                tSize     : 11,
                                tColor    : '#000',
                        },
                },

    },
    style:[ 
              {
                group: 'Outstanding Punch',
                position: { x: 0, y: 60 },
                item: [
                  {
                    name        : 'None',
                    refColumn   : 'PUNCH_STATUS',
                    codeValue   : 'N',
                    dataColumn  : '',
                    tStyle      : 'regular',
                    tSize       : 9,
                    tColor      : '#000',
                    sWidth      : 0.5,
                    sColor      : '#fdd835',
                    bColor      : '#FEFEFE',
                    opacity     : 1,
                    radius      : 2,
                    on          : true,
                  },
                  {
                    name        : 'Category A',
                    refColumn   : 'PUNCH_STATUS',
                    codeValue   : 'A',
                    dataColumn  : '',
                    tStyle      : 'regular',
                    tSize       : 9,
                    tColor      : '#000',
                    sWidth      : 0.5,
                    sColor      : '#d32f2f',
                    bColor      : '#FEFEFE',
                    opacity     : 1,
                    radius      : 2,
                    on          : true,
                  },
                  {
                    name        : 'Category B',
                    refColumn   : 'PUNCH_STATUS',
                    codeValue   : 'B',
                    dataColumn  : '',
                    tStyle      : 'regular',
                    tSize       : 9,
                    tColor      : '#000',
                    sWidth      : 0.5,
                    sColor      : '#0362fc',
                    bColor      : '#FEFEFE',
                    opacity     : 1,
                    radius      : 2,
                    on          : true,
                  },
                ]
              },
              {
                group: 'Subsystem Status',
                position: { x: 0, y: 110 },
                item: [
                          {
                            name        : 'Not Started',
                            refColumn   : 'SUBSYSTEM_STATUS',
                            codeValue   : 'NOTSTATED',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#9e9e9e',
                            sWidth      : 0.5,
                            sColor      : '#bcbcbc',
                            bColor      : '#fff',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'In Progress',
                            refColumn   : 'SUBSYSTEM_STATUS',
                            codeValue   : 'INPROGRESS',
                            dataColumn  : 'A_PG',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#0d47a1',
                            sWidth      : 0.5,
                            sColor      : '#81d4fa',
                            bColor      : '#81d4fa',
                            opacity     : .7,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'All ITRs Completed',
                            refColumn   : 'SUBSYSTEM_STATUS',
                            codeValue   : 'ITRCOMPLETED',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#4a148c',
                            sWidth      : 0.5,
                            sColor      : '#e1bee7',
                            bColor      : '#f3e5f5',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'WD Completed',
                            refColumn   : 'SUBSYSTEM_STATUS',
                            codeValue   : 'WD',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#e65100',
                            sWidth      : 0.5,
                            sColor      : '#ffcc80',
                            bColor      : '#ffecb3',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                          {
                            name        : 'MC Completed',
                            refColumn   : 'SUBSYSTEM_STATUS',
                            codeValue   : 'MC',
                            dataColumn  : '',
                            tStyle      : 'regular',
                            tSize       : 9,
                            tColor      : '#827717',
                            sWidth      : 0.5,
                            sColor      : '#8BC248',
                            bColor      : '#CDDC37',
                            opacity     : 1,
                            radius      : 2,
                            on          : true,
                          },
                ],
              },
    ],
    styleFilter: {
      over: {
        stroke: '#ffb300',
        fill: '#ffd54f',
        color: '#fff'
      },
      out: {
        stroke: '#bcbcbc',
        fill: '#fff',
        color: '#c9c9c9'
      },
      selected: {
        stroke: '#1565c0',
        fill: '#64b5f6',
        color: '#757575'
      }
    },
    table: {
      values: [
        { text: 'Total ITRs'        , colName: 'A_T'      },
        { text: 'ITRs Completed'    , colName: 'A_A'      },
        { text: 'ITRs Remained'     , colName: 'A_O'      , tColor: '#2962ff' },
        { text: 'ITRs Progress(%)'  , colName: 'A_PG'     },
        { text: 'ITRs Cumulated(%)' , colName: 'A_CUM_PG' , tColor: '#d32f2f'  },
        { text: 'Open Punch "A"'    , colName: 'PA_O'     },
        { text: 'Open Punch "B"'    , colName: 'PB_O'     },
      ]
    }
  }),
  computed: {
  },
  methods: {
    setStyles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
  }
}