import * as d3 from 'd3'

export default {
  data: () => ({
    svgModeling: null,
    SVG_AreaSummary: null,
    SVG_ModuleDivision: null,
    SVG_ModuleSummary: null,

    Goto_Dashboard_Area: null,

    Goto_ActivateModuling: null,
    Goto_Hull_SideBlocks: null,
    Goto_Hull_ModuleDivision: null,
    Goto_ModuleSummary: null,
    Goto_BlockDivision: null,

    btnGoBack: null,

    currentStage: 'Dashboard-Overall',
    
    STG_Dashboard_Overall   : 'Dashboard-Overall',
    STG_Dashboard_Area      : 'Dashboard-Area',

    STG_Area_Summary        : 'Area-Summary',
    STG_Side_Blocks         : 'Side-Blocks',
    STG_Module_Division     : 'Module-Division',
    STG_Module_Summary      : 'Module-Sumummary',
    STG_Block_Division      : 'Block-Division',

    TRANS_Area_Summary      : 'Area-summary-trans',
    TRANS_Module_Summary    : 'Module-sumummary-trans',
    TRANS_Block_Division    : 'Block-division-trans',
    STG_TRANS               : 'transforming',


    ScaleOverall: {Hull: 0.725, Deckbox: 0.45,  Topside: 1},
    ScaleModule:  {Hull: 0.5,   Deckbox: 1,     Topside: 1.25},
    ScaleSummary: {Hull: 1,     Deckbox: 1.5,   Topside: 1.75},
    ScaleBlock:   {Hull: 0.725, Deckbox: 0.45,  Topside: 0.3},

    Module: null,
    CurrentArea: null,
  }),

  computed: {
    ready2draw() {
      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0 
      )
    },
    stage: {
      get() { return this.currentStage },
      set(val) { this.currentStage = val },
    },
  },

  methods: {
    setDefaultValues(){
      this.setEvent_Goto()
      this.setEvent_Back()
      
      this.setEvent_Others()


      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      
      this.dataSet.forEach(data => {
        let bItem = this.BLOCKS.Hull.filter(m=> m.module == 'MISC').find(f_ => f_.blk == data.MODULE)
        if(bItem) {
          data.MOD = 'MISC'
          data.BLOCK_STATUS = '3PE'
        }
      })

      // Style
      // Coloring
      this.dataSet.forEach(data => {

        // Change Type for Pre-Erection Block
        if (data.PE == 'Y') data.TYPE = 'PE'

        let bItem = this.Border.find(t_ => t_.type == data.BLOCK_STATUS)
        if(bItem) {
          data.shape = bItem.shape
          data.width = bItem.width
          data.height = bItem.height
          data.color = bItem.color
          data.stroke = bItem.stroke
          data.textSize = bItem.textSize
          data.padding = bItem.padding
        }
      })

      // Style
      // Coloring
      this.dataSet.forEach(data => {
        let bItem = this.Status.find(t_ => t_.status == data.BLOCK_STATUS)
        if(bItem) {
          data.bColor = bItem.bColor
          data.tColor = bItem.tColor
        }
      })

      // 텍스트 위치 x,y를 DATASET에 저장한다.
      // data.MOD - ???
      this.dataSet.forEach(data => {
        let bItem = this.BLOCKS.Hull.find(f_ => f_.blk == data.BLOCK)
        if(bItem) {
          data.MOD = bItem.module
          data.x = bItem.x
          data.y = bItem.y
        }

        bItem = this.BLOCKS.Deckbox.find(f_ => f_.blk == data.BLOCK)
        if(bItem) {
          data.MOD = bItem.module
          data.x = bItem.x
          data.y = bItem.y
          // console.log(data.BLOCK, data.x, data.y)
        }
        
        bItem = this.BLOCKS.Topside.find(f_ => f_.blk == data.BLOCK)
        if(bItem) {
          data.MOD = bItem.module
          data.x = bItem.x
          data.y = bItem.y
        }
      })
    },
    

  }
}