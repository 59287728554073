export default {
  data: () => ({
    packageBox: {
      x: 1570,
      y: 110,
      w: 271,
      h: 210,
    },
    packageRegions: [
      { code: 'P1', fill: '#42A5F5', path: 'M 654 470 L 754 425 L 822 499 L 956 439 L 1103 596 L 1301 507 L 1388 604 L 1059 759 L 936 631 L 839 672 L 655 470 Z' },
      { code: 'P2', fill: '#FFA726', path: 'M 393 528 L 470 492 L 513 535 L 655 470 L 839 672 L 698 738 L 774 827 L 695 865 L 602 763 L 598 756 L 471 613 L 449 596 L 401 544 L 393 528 Z' },
      { code: 'P3', fill: '#ec407a', path: 'M 332 464 L 457 409 L 562 369 L 615 426 L 661 406 L 677 420 L 757 385 L 810 442 L 917 393 L 1105 597 L 1376 474 L 1557 678 L 1182 849 L 1160 866 L 1058 757 L 1389 604 L 1299 506 L 1103 596 L 956 437 L 823 500 L 754 426 L 515 534 L 475 488 L 388 527 L 422 615 L 544 748 L 700 889 L 792 845 L 836 893 L 787 915 L 700 889 L 544 748 L 503 767 L 474 762 L 387 660 L 422 615 L 388 527 L 333 463 Z' },
    ]
  }),
  methods: {
    loadMain() {
      this.innerSvg = this.svg.append('svg')
      this.setPriority()
      
      let background = this.innerSvg
      .append('image')
      .attr('class','el_page_main_background')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Demo/EquipmentLayout/Sector/MAINPLANT.jpg`)
      .style('opacity', 0)
      .on('load', this.imageMainLoaded)

      if(this.route.from) background.attr('transform', `translate(-330, -330) scale(1.3)`)
      else background.attr('transform', `translate(0, 0) scale(.87)`)
    },
    postLoadMain() {
      // draw package pathes
      this.packageRegions.forEach(r => {
        this.innerSvg
        .append('path')
        .attr('d', r.path)
        .style('fill', r.fill)
        .style('opacity', .1)
        .style('cursor', 'pointer')
        .on('click', () => {
          this.routeTo({
            level  : 1,
            lname  : 'package',
            package: r.code
          }, {
            level  : 0,
            lname  : 'main',
          })
        })
        .call(this.callFuncRegionalAction)
      })

      // staget summary on the top-left of the shared area //
      this.dataset.stageSummary.package.forEach((d, i) => {
        let data = {
          x     : this.packageBox.x - this.packageBox.w * (i+1) - (i?10:0),
          y     : this.packageBox.y,
          title : `Package ${d.LV2}`,
          shadow: true,
          source: d,
          filters: { LV2: d.LV2 }
        }
        this.drawStageStatus(this.innerSvg, data, `_${d.LV2}`)
      })

      /**
       * Svg Path Tool to get the clicked mouse point (x, y)
       */
      // this.createPathReigon(this.innerSvg, {
      //   w: 1600,
      //   h: 1120,
      //   strokeWidth: 2,
      //   strokeColor: '#ff00ff'
      // })
    },
    unloadMain() {
      this.innerSvg.select('.el_page_main_background')
      .transition().duration(350)
      .attr('transform', `translate(-330, -330) scale(1.3)`)

      this.innerSvg
      .transition().delay(100).duration(250)
      .style('opacity', 0)

      return new Promise(resolve => {
        setTimeout(() => {
          this.innerSvg.remove()
          resolve() 
        }, 500)
      })
    },
    imageMainLoaded() {
      this.innerSvg.select('.el_page_main_background')
      .transition().duration(350)
      .attr('transform', `translate(-90, -110) scale(1)`)
      .style('opacity', 1)

      setTimeout(() => { this.postLoadMain() }, 350)
    }
  }
}