import * as d3 from 'd3'

export default {
  data: () => ({

  }),
  computed: {

    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },

  methods: {
    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.dataSet.forEach(d => {
        d.cDate = this.getTimelineCDate(d.date).eDate
        d.sDate = this.getTimelineCDate(d.date).sDate
        d.date = d3.timeParse('%Y-%m-%d')(d.date)
      })


      this.style.forEach(style => {

        style.item.forEach(item => {

          this.dataSet.filter( f => f[item.refColumn] == item.codeValue).forEach(d => {
            d.tStyle   = item.tStyle
            d.tSize    = item.tSize
            d.tColor   = item.tColor
            d.sWidth   = item.sWidth
            d.sColor   = item.sColor
            d.bColor   = item.bColor
            d.opacity  = item.opacity
            d.radius   = item.radius
            if (item.dataColumn != '') d.progress = 'Y'
          })
        })
      })

      this.timeline.height = this.timeline.style.week.height
      if (this.timeline.style.month.display == 'Y') this.timeline.height += this.timeline.style.month.height
      if (this.timeline.style.year.display == 'Y') this.timeline.height += this.timeline.style.year.height
    
      // console.log(this.dataSet)
    },
    
  }
}