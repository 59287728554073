import * as d3 from 'd3'

export default {
  data: () => ({
    Back_Dashboard_Overall: null,
  }),
  computed: {
  },

  methods: {
    
    setEvent_Gohome() {
      this.btnGoHome = (selection) => {
        selection
        .on('mouseover', () => {
          d3.select(`.Home_Btn`).transition().duration(100).style('opacity', 0.5)
        })
        .on('mouseout', () => {
          d3.select(`.Home_Btn`).transition().duration(100).style('opacity', 1)
        })
        .on('click', () => {
          d3.select(`.Home_Btn`).transition().duration(100).style('opacity', 1)
          let del = []
          this.selectedItems.forEach((d,index) => {
             del.push(index)
          })
          del.forEach(d => {
            this.selectedItems.pop()
          })

          setTimeout(() => {
              if (this.innerSvg) this.removeSvg(this.innerSvg, 10)
              if (this.svgModeling) this.removeSvg(this.svgModeling, 10)            
          }, 700)

          // Remove SVG
          setTimeout(() => {
            this.removeSvg(this.CANVAS, 10)
            if (this.stage == 'Activate' && ['HULL-AFT','HULL-FOR'].includes(this.Lev2)) {
              this.removeSvg(this.svgModeling, 10) 
              this.removeSvg(d3.select('#tag_button_wrapper'), 10)
            }
            if (this.stage == 'Division_Lv3' && ['MS01', 'MS02', 'MS03', 'MS04',
            'LQ01', 'LQ02', 'LQ03', 'LQ04',
            'LQ05', 'LQ06', 'LQ07',
            'KO01', 'FL01', 'LDOODA', 
            'MW01', 'MW02', 'MW04', 'MW05',
            'ME01', 'ME02', 'ME04', 'ME05'].includes(this.Lev4)) this.removeSvg(d3.select('#tag_button_wrapper'), 10)
            if(this.Dashboard_Overall) this.removeSvg(this.Dashboard_Overall, 10)
          }, 1000)

          setTimeout(() => {
            this.Activate_Overall()            
          }, 1300)
 
          // Hidden
          this.hiddenGroupID('#Mask_Topside', 0, 10)
          this.hiddenGroupID('#Mask_Hull', 0, 10)
          this.hiddenGroupID('#Menu_Back', 0, 10)
          this.hiddenGroupID('#Keyplan_Group', 0, 10)
          this.hiddenGroupID('#Legends', 0, 10)

          d3.select(`#Title`).text(`Tortue Overall`)

          setTimeout(() => {
            d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, 0)`)
            d3.select(`#Area_AFT`).transition().duration(1000).attr('transform',`translate(439, 553) scale(0.75)`)
            d3.select(`#Area_MS`).transition().duration(1000).attr('transform', `translate(854, 357) scale(0.75)`)
            d3.select(`#Area_FOR`).transition().duration(1000).attr('transform',`translate(706, 481) scale(0.75)`)
            d3.select(`#Area_LQ`).transition().duration(1000).attr('transform', `translate(946, 342) scale(0.75)`)
            d3.select(`#Area_VS`).transition().duration(1000).attr('transform', `translate(1000, 437) scale(0.75)`)
          }, 1800)

          setTimeout(() => {
            // Display
            this.displayGroupID(`#Mask_Overall`, 10, 10, 0)

            this.stage = this.STG_Overall
            this.Lev1 = null
            this.Lev2 = null
            this.Lev3 = null
            this.Lev4 = null
            this.fncName = 'Back_Overall_FPSO' 
            this.StageCode() // AREA / STAGE

          }, 1800);
          
        })
      }
    },
    setEvent_Goback() {

      // Set Event Listener Back Button
      this.btnGoBack = (selection) => {
        selection
        .on('mouseover', () => {
          d3.select(`.Back_Btn`).transition().duration(100).style('opacity', 0.5)
        })
        .on('mouseout', () => {
          d3.select(`.Back_Btn`).transition().duration(100).style('opacity', 1)
        })
        .on('click', () => {
          this.selectedItems.pop()
          d3.select(`.Back_Btn`).transition().duration(100).style('opacity', 1)

          this.hiddenGroupID('#Menu_Back', 0, 10)
          // console.log(this.stage,"------------1")
          // console.log(this.STG_Division_Lv3,"------------2")
          // console.log(this.STG_Activate,"---------------3")
          // console.log(this.STG_Division_Lv4,"-----------4")
          // console.log(this.STG_Lv4_Block_Division,"---------5")
          // console.log(this.Lev1,"----------------6")
          // console.log(this.Lev2,"----------------7")
          // console.log(this.Lev3,"----------------8")
          // console.log(this.Lev4,"----------------9")
          switch(this.stage) {

            case this.STG_Division_Lv1: 
                                        this.Back_Overall_FPSO()
                                        break;
            case this.STG_Division_Lv2: 
                                        if(this.Lev1 == 'HULL')           this.Hull_Division_Lv1()
                                        else if(this.Lev1 == 'TOPSIDES')   this.Topside_Division_Lv1()
                                        break;
            case this.STG_Activate:     
                                        if (this.Lev1 == 'HULL' && this.Lev2 == 'LQ') this.LQ_Back_Division_Lv2()
                                        else if(this.Lev1 == 'HULL' && this.Lev2 == 'HULL-MS') this.MS_Back_Division_Lv2()
                                        else if(this.Lev1 == 'HULL')           this.Hull_Back_Division_Lv2()
                                        else if(['ME01', 'ME02', 'ME04', 'ME05',
                                        'MW01', 'MW02', 'MW04', 'MW05'].includes(this.Lev3)) this.MODULE_TS_Division_Lv4()
                                        break;


            case this.STG_Division_Lv3: 
                                        if(this.Lev2 == 'HULL-AFT')       this.AFT_Activate_Hull()
                                        else if(this.Lev2 == 'HULL-FOR')  this.FOR_Activate_Hull()
                                        else if(this.Lev2 == 'LQ')        this.LQ_Activate_Hull()
                                        else if(this.Lev2 == 'HULL-MS')   this.MS_Activate_Hull()
                                        else if((this.Lev2 == 'COMMON' && this.Lev3 == null) ||  (this.Lev2 == 'MODULES')) this.TS_Division_Lv2()
                                        else if(this.Lev3 == 'LD00DA' ||  this.Lev3 == 'KO01' || this.Lev3 == 'FL01') this.COMMON_TS_Division_Lv3()
                                        else if(['ME01', 'ME02', 'ME04', 'ME05',
                                                 'MW01', 'MW02', 'MW04', 'MW05'].includes(this.Lev4)) this.MODULE_TS_Division_Lv3()
                                        break;

            case this.STG_Division_Lv4: 
                                        if(this.Lev2 == 'HULL-AFT')       this.AFT_Division_Hull_Lv4()
                                        else if(this.Lev2 == 'HULL-FOR')  this.FOR_Division_Hull_Lv4()
                                        else if(this.Lev2 == 'LQ')        this.LQ_Division_Hull_Lv4()
                                        break;

            case this.STG_Lv3_Block_Division: 
                                        if(this.Lev2 == 'HULL-AFT')       this.AFT_Lv3_Block_Division()
                                        else if(this.Lev2 == 'HULL-FOR')  this.FOR_Lv3_Block_Division()
                                        else if(this.Lev2 == 'LQ')        this.LQ_Lv3_Block_Division()
                                        break;

            case this.STG_Lv4_Block_Division: 
                                        if(this.Lev2 == 'HULL-AFT')       this.AFT_Lv4_Block_Division()
                                        else if(this.Lev2 == 'HULL-FOR')  this.FOR_Lv4_Block_Division()
                                        else if(this.Lev2 == 'LQ')        this.LQ_Lv4_Block_Division()
                                        else if(['ME01', 'ME02', 'ME04', 'ME05',
                                        'MW01', 'MW02', 'MW04', 'MW05'].includes(this.Lev3)) this.MODULE_TS_Division_Lv4()
                                        break;
            
          }
        })
      }
      


      this.Back_Overall_FPSO = () => { // ---------------------------------------------------------------------------------------------------------------------

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Hidden
        this.hiddenGroupID('#Mask_Topside', 0, 10)
        this.hiddenGroupID('#Mask_Hull', 0, 10)
        this.hiddenGroupID('#Menu_Back', 0, 10)
        this.hiddenGroupID('#Keyplan_Group', 0, 10)
        this.hiddenGroupID('#Legends', 0, 10)
        
        d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, 0)`)
        d3.select(`#Area_AFT`).transition().duration(1000).attr('transform',`translate(439, 553) scale(0.75)`)
        d3.select(`#Area_MS`).transition().duration(1000).attr('transform', `translate(854, 357) scale(0.75)`)
        d3.select(`#Area_FOR`).transition().duration(1000).attr('transform',`translate(706, 481) scale(0.75)`)
        d3.select(`#Area_LQ`).transition().duration(1000).attr('transform', `translate(946, 342) scale(0.75)`)
        d3.select(`#Area_VS`).transition().duration(1000).attr('transform', `translate(1000, 437) scale(0.75)`)

        setTimeout(() => {
          
          // Display
          this.displayGroupID(`#Mask_Overall`, 10, 10, 0)

          this.stage = this.STG_Overall
          this.fncName = 'Back_Overall_FPSO' 
          this.StageCode() // AREA / STAGE

          // ### Display Canvas ###
          this.drawProgress('Overall')  
        }, 1000);
      }


      this.Topside_Division_Lv1 = () => { // ---------------------------------------------------------------------------------------------------------------------

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Title & Keyplan
        d3.select(`#Title`).text('Tortue Overall')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TORTUE.jpg`)

        // Hidden
        this.hiddenGroupID('#Mask_TS_Common', 0, 10)
        this.hiddenGroupID('#Mask_TS_Modules', 0, 10)
        
        d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, -100)`)

        d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(918, 444) scale(0.75)`)
        d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(569, 558) scale(0.75)`)
        d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(652, 532) scale(0.75)`)
        d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(731, 513) scale(0.75)`)
        d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(815, 487) scale(0.75)`)
        d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(882, 463) scale(0.75)`)

        d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(527, 518) scale(0.75)`)
        d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(615, 499) scale(0.75)`)
        d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(793, 402) scale(0.75)`)
        d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(841, 412) scale(0.75)`)
        d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(592, 557) scale(0.75)`)
        d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(663, 540) scale(0.75)`)
        d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(843, 431) scale(0.75)`)
        d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(897, 452) scale(0.75)`)

        d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(485, 333) scale(0.75)`)
        d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(472, 611) scale(0.75)`)

        d3.select(`#Area_AFT`).transition().duration(1000).attr('transform', `translate(441, 692) scale(0.75)`)
        d3.select(`#Area_MS`).transition().duration(1000).attr('transform', `translate(857, 495) scale(0.75)`)
        d3.select(`#Area_FOR`).transition().duration(1000).attr('transform', `translate(709, 619) scale(0.75)`)
        d3.select(`#Area_LQ`).transition().duration(1000).attr('transform', `translate(947, 479) scale(0.75)`)
        d3.select(`#Area_VS`).transition().duration(1000).attr('transform', `translate(1003, 578) scale(0.75)`)
        
        // Display
        this.displayGroupID(`#Area_AFT`, 10, 1000, 1)
        this.displayGroupID(`#Area_MS`, 10, 1000, 1)
        this.displayGroupID(`#Area_FOR`, 10, 1000, 1)
        this.displayGroupID(`#Area_LQ`, 10, 1000, 1)
        this.displayGroupID(`#Area_VS`, 10, 1000, 1)

        setTimeout(() => {
          // Display Mask
          this.displayGroupID(`#Mask_Topside`, 10, 10, 0)
          this.displayGroupID(`#Mask_Hull`, 10, 10, 0)

          this.stage = this.STG_Division_Lv1

          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv1,
              mask : 'OVERALL',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'Topside_Division_Lv1'  
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Display Canvas ###
          this.drawProgress('Hull & Top')
          
        }, 1000);
      }

      this.Hull_Division_Lv1 = () => { // ------------------------------------------------------------------------------------------ Hull Activate
        
        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Title & Keyplan
        d3.select(`#Title`).text('Tortue Overall')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TORTUE.jpg`)

        // Hidden
        this.hiddenGroupID('#Mask_Hull_AFT', 0, 10)
        this.hiddenGroupID('#Mask_Hull_FOR', 0, 10)
        this.hiddenGroupID('#Mask_Hull_LQ', 0, 10)
        
        d3.select(`#Area_AFT`).transition().duration(1000).attr('transform', `translate(441, 692) scale(0.75)`)
        d3.select(`#Area_MS`).transition().duration(1000).attr('transform', `translate(857, 495) scale(0.75)`)
        d3.select(`#Area_FOR`).transition().duration(1000).attr('transform', `translate(709, 619) scale(0.75)`)
        d3.select(`#Area_LQ`).transition().duration(1000).attr('transform', `translate(947, 479) scale(0.75)`)
        d3.select(`#Area_VS`).transition().duration(1000).attr('transform', `translate(1003, 578) scale(0.75)`)

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Topside_Group`, 0, 500, 1)
          this.displayGroupID(`#Mask_Topside`, 10, 10, 0)
          this.displayGroupID(`#Mask_Hull`, 10, 10, 0)

          this.Lev2 = null
          this.stage = this.STG_Division_Lv1
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv1,
              mask : 'OVERALL',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'Hull_Division_Lv1'  
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Display Canvas ###
          this.drawProgress('Hull & Top')
        }, 1000);
      }

      this.Topside_Back_Division_Lv2 = () => { // ------------------------------------------------------------------------------------------ Topside Activate

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Title & Keyplan
        d3.select(`#Title`).text(`Topside_${this.Lev2}`)
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TOPSIDE_${this.Lev2}.jpg`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)
        
        // Remove SVG
        this.removeSvg(this.svgModeling, 1000) 
        
        // Call
        this.Activate_OverallnMasks()

        // Set Hull Position
        d3.select(`#Area_AFT`).attr('transform', `translate(418, 668) scale(0.75)`)
        d3.select(`#Area_FOR`).attr('transform', `translate(727, 653) scale(0.75)`)
        d3.select(`#Area_LQ`) .attr('transform', `translate(983, 663) scale(0.75)`)
        d3.select(`#Area_VS`) .attr('transform', `translate(926, 734) scale(0.75)`)

        // Set Topside Position
        d3.select(`#MW01`).attr('transform', `translate(288, 612)  scale(0.75)`)
        d3.select(`#MW02`).attr('transform', `translate(524, 537)  scale(0.75)`)
        d3.select(`#MW04`).attr('transform', `translate(769, 415) scale(0.75)`)
        d3.select(`#MW05`).attr('transform', `translate(975, 367) scale(0.75)`)
        d3.select(`#ME01`).attr('transform', `translate(499, 729)  scale(0.75)`)
        d3.select(`#ME02`).attr('transform', `translate(718, 656)  scale(0.75)`)
        d3.select(`#ME04`).attr('transform', `translate(965, 522)  scale(0.75)`)
        d3.select(`#ME05`).attr('transform', `translate(1177, 485) scale(0.75)`)
        
        d3.select(`#FL01`)  .attr('transform', `translate(347, 436) scale(0.75)`)
        d3.select(`#KO01`)  .attr('transform', `translate(375, 760) scale(0.75)`)
        d3.select(`#TSRC01`).attr('transform', `translate(514, 646) scale(0.75)`)
        d3.select(`#TSRC02`).attr('transform', `translate(663, 596) scale(0.75)`)
        d3.select(`#TSRC03`).attr('transform', `translate(805, 552) scale(0.75)`)
        d3.select(`#TSRC04`).attr('transform', `translate(954, 504) scale(0.75)`)
        d3.select(`#TSRC05`).attr('transform', `translate(1080, 458)scale(0.75)`)
        d3.select(`#LD00DA`).attr('transform', `translate(1184, 415)scale(0.75)`)

        if(this.Lev2 == 'COMMON') {
          // hide module
          this.hiddenGroupID('#MW01', 0, 1000)
          this.hiddenGroupID('#MW02', 0, 1000)
          this.hiddenGroupID('#MW04', 0, 1000)
          this.hiddenGroupID('#MW05', 0, 1000)
          this.hiddenGroupID('#ME01', 0, 1000)
          this.hiddenGroupID('#ME02', 0, 1000)
          this.hiddenGroupID('#ME04', 0, 1000)
          this.hiddenGroupID('#ME05', 0, 1000)
        }
        if(this.Lev2 == 'MODULES') {
          // hide module
          this.hiddenGroupID('#FL01', 0, 1000)
          this.hiddenGroupID('#KO01', 0, 1000)
          this.hiddenGroupID('#TSRC01', 0, 1000)
          this.hiddenGroupID('#TSRC02', 0, 1000)
          this.hiddenGroupID('#TSRC03', 0, 1000)
          this.hiddenGroupID('#TSRC04', 0, 1000)
          this.hiddenGroupID('#TSRC05', 0, 1000)
          this.hiddenGroupID('#LD00DA', 0, 1000)
        } 
        
        setTimeout(() => {

          this.displayGroupID(`#Topside_Group`, 0, 500, 1)

          // Mask Display
          if(this.Lev2 == 'COMMON') {
            this.displayGroupID(`#Mask_FL01`,   0, 10, 0)
            this.displayGroupID(`#Mask_KO01`,   0, 10, 0)
            this.displayGroupID(`#Mask_LD00DA`, 0, 10, 0)
          } 
          if(this.Lev2 == 'MODULES') {
            this.displayGroupID(`#Mask_MW01`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW02`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW04`, 0, 10, 0)
            this.displayGroupID(`#Mask_MW05`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME01`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME02`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME04`, 0, 10, 0)
            this.displayGroupID(`#Mask_ME05`, 0, 10, 0)
          }
          
          this.stage = this.STG_Division_Lv3
          this.fncName = 'Topside_Back_Division_Lv2'  
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
        }, 1000);
      }

      this.LQ_Back_Division_Lv2 = () => {
        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Hidden
        this.hiddenGroupID(`#Mask_LQ01`, 0, 0, 10)
        this.hiddenGroupID(`#Mask_LQ02`, 0, 0, 10)
        this.hiddenGroupID(`#Mask_LQ03`, 0, 0, 10)
        this.hiddenGroupID(`#Mask_LQ04`, 0, 0, 10)
        this.hiddenGroupID(`#Mask_LQ05`, 0, 0, 10)
        this.hiddenGroupID(`#Mask_LQ06`, 0, 0, 10)
        this.hiddenGroupID(`#Mask_LQ07`, 0, 0, 10)        
        
        setTimeout(() => {
          this.Hull_LQ.forEach(d => {
            d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(${0.35})`)
          })
        }, 300);

        setTimeout(() => {
          this.Hull_LQ.forEach(d => {
            d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(${0.35})`)
          })
        }, 1200);

        setTimeout(() => {
          this.Hull_LQ.forEach(d => {
            d3.select(`#Blk_${d.BLK}`).transition().duration(700).attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.35})`)
          })
          this.Hull_Back_Division_Lv2()
        }, 2400);
        
      } 
     
      this.MS_Back_Division_Lv2 = () => {
        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)
        
        this.hiddenGroupID(`#Mask_Lev1`, 0, 0, 0)
        this.hiddenGroupID(`#Mask_Lev2`, 0, 0, 0)
        this.hiddenGroupID(`#Mask_Lev3`, 0, 0, 0)
        this.hiddenGroupID(`#Mask_Lev4`, 0, 0, 0)
        setTimeout(() => {
          this.Hull_Mechinaery.filter(f => f.layout == null).forEach(d => {
            d3.select(`#Blk_${d.BLK}`).transition().duration(300).style('opacity', 1)
          })   
        }, 100);

        setTimeout(() => {
          this.Hull_Mechinaery.forEach(d => {
            d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(${0.35})`)
          })
        }, 300);

        setTimeout(() => {
          this.Hull_Mechinaery.forEach(d => {
            d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(${0.35})`)
          })
        }, 1200);

        setTimeout(() => {
          this.Hull_Mechinaery.forEach(d => {
            d3.select(`#Blk_${d.BLK}`).transition().duration(700).attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.35})`)
          })
          this.Hull_Back_Division_Lv2()
        }, 2400);
        
      }       

      this.Hull_Back_Division_Lv2 = () => { // ---------------------------------------------------------------------------------------------------------------------

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Remove SVG
        this.removeSvg(this.svgModeling, 1000) 

        this.removeSvg(d3.select('#tag_button_wrapper'), 1000)
        if (this.innerSvg)
        // Remove SVG
        this.removeSvg(this.innerSvg, 1000) //SVG 지우기

        // Title & Keyplan
        d3.select(`#Title`).text('Hull & LQ')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL.jpg`)

        this.Activate_OverallnMasks()
        d3.select(`#Topside_Group`).attr('transform', `translate(0, -100)`)

        setTimeout(() => {
          // Display
          d3.select(`#Area_AFT`).attr('transform', `translate(304, 670) scale(0.75)`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
          d3.select(`#Area_MS`).attr('transform',  `translate(962, 379) scale(0.75)`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
          d3.select(`#Area_FOR`).attr('transform', `translate(692, 548) scale(0.75)`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
          d3.select(`#Area_LQ`).attr('transform',  `translate(1051,195) scale(0.75)`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
          d3.select(`#Area_VS`).attr('transform',  `translate(1108,460) scale(0.75)`).transition().duration(500).style('visibility', 'visible').style('opacity', 1)
        }, 500);

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_Hull_AFT`, 0, 10, 0)
          this.displayGroupID(`#Mask_Hull_MS`, 0, 10, 0)
          this.displayGroupID(`#Mask_Hull_FOR`, 0, 10, 0)
          this.displayGroupID(`#Mask_Hull_LQ`, 0, 10, 0)
          this.stage = this.STG_Division_Lv2
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv2,
              mask : 'Mask_Hull',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false 
          }
          this.Lev2 = null
          this.Lev3 = null
          this.Lev4 = null
          this.fncName = 'Hull_Back_Division_Lv2'  
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Display Canvas ###
          this.drawProgress('Hull & LQ')
        }, 1000);
      }

      this.TS_Division_Lv2 = () => { // ---------------------------------------------------------------------------------------------------------------------

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Title & Keyplan
        d3.select(`#Title`).text('Topside')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TOPSIDES.jpg`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_TS_Common', 0, 10)
        this.hiddenGroupID('#Mask_TS_Modules', 0, 10)

        if(this.Lev2 == 'COMMON') {

          // Hide Mask
          this.hiddenGroupID('#Mask_FL01', 0, 10)
          this.hiddenGroupID('#Mask_KO01', 0, 10)
          this.hiddenGroupID('#Mask_TSRC01', 0, 10)
          this.hiddenGroupID('#Mask_TSRC02', 0, 10)
          this.hiddenGroupID('#Mask_TSRC03', 0, 10)
          this.hiddenGroupID('#Mask_TSRC04', 0, 10)
          this.hiddenGroupID('#Mask_TSRC05', 0, 10)
          this.hiddenGroupID('#Mask_LD00DA', 0, 10)

          // Move module
          d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(767, 545) scale(0.75)`)
          d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(754, 823) scale(0.75)`)
          d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(851, 770) scale(0.75)`)
          d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(934, 744) scale(0.75)`)
          d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(1013, 725) scale(0.75)`)
          d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(1097, 699) scale(0.75)`)
          d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(1164, 675) scale(0.75)`)
          d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(1200, 656) scale(0.75)`)

          d3.select(`#MW01`).attr('transform', `translate(450, 381) scale(0.75)`)
          d3.select(`#MW02`).attr('transform', `translate(538, 362) scale(0.75)`)
          d3.select(`#MW04`).attr('transform', `translate(716, 265) scale(0.75)`)
          d3.select(`#MW05`).attr('transform', `translate(764, 275) scale(0.75)`)
          d3.select(`#ME01`).attr('transform', `translate(515, 420) scale(0.75)`)
          d3.select(`#ME02`).attr('transform', `translate(586, 403) scale(0.75)`)
          d3.select(`#ME04`).attr('transform', `translate(766, 294) scale(0.75)`)
          d3.select(`#ME05`).attr('transform', `translate(820, 315) scale(0.75)`)
          
          // Display Module
          this.displayGroupID(`#MW01`, 0, 1000, 1)
          this.displayGroupID(`#MW02`, 0, 1000, 1)
          this.displayGroupID(`#MW04`, 0, 1000, 1)
          this.displayGroupID(`#MW05`, 0, 1000, 1)
          this.displayGroupID(`#ME01`, 0, 1000, 1)
          this.displayGroupID(`#ME02`, 0, 1000, 1)
          this.displayGroupID(`#ME04`, 0, 1000, 1)
          this.displayGroupID(`#ME05`, 0, 1000, 1)
        }

        if (this.Lev2 == 'MODULES') {

          // Hide Mask
          this.hiddenGroupID('#Mask_MW01', 0, 10)
          this.hiddenGroupID('#Mask_MW02', 0, 10)
          this.hiddenGroupID('#Mask_MW04', 0, 10)
          this.hiddenGroupID('#Mask_MW05', 0, 10)
          this.hiddenGroupID('#Mask_ME01', 0, 10)
          this.hiddenGroupID('#Mask_ME02', 0, 10)
          this.hiddenGroupID('#Mask_ME04', 0, 10)
          this.hiddenGroupID('#Mask_ME05', 0, 10)

          // Move module
          d3.select(`#FL01`)  .attr('transform', `translate(767, 545)  scale(0.75)`)
          d3.select(`#KO01`)  .attr('transform', `translate(754, 823)  scale(0.75)`)
          d3.select(`#TSRC01`).attr('transform', `translate(851, 770)  scale(0.75)`)
          d3.select(`#TSRC02`).attr('transform', `translate(934, 744)  scale(0.75)`)
          d3.select(`#TSRC03`).attr('transform', `translate(1013, 725) scale(0.75)`)
          d3.select(`#TSRC04`).attr('transform', `translate(1097, 699) scale(0.75)`)
          d3.select(`#TSRC05`).attr('transform', `translate(1164, 675) scale(0.75)`)
          d3.select(`#LD00DA`).attr('transform', `translate(1200, 656) scale(0.75)`)

          d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(450, 381) scale(0.75)`)
          d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(538, 362) scale(0.75)`)
          d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(716, 265)  scale(0.75)`)
          d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(764, 275)  scale(0.75)`)
          d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(515, 420) scale(0.75)`)
          d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(586, 403) scale(0.75)`)
          d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(766, 294)  scale(0.75)`)
          d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(820, 315)  scale(0.75)`)

           // Display Module
           this.displayGroupID(`#FL01`, 0, 1000, 1)
           this.displayGroupID(`#KO01`, 0, 1000, 1)
           this.displayGroupID(`#TSRC01`, 0, 1000, 1)
           this.displayGroupID(`#TSRC02`, 0, 1000, 1)
           this.displayGroupID(`#TSRC03`, 0, 1000, 1)
           this.displayGroupID(`#TSRC04`, 0, 1000, 1)
           this.displayGroupID(`#TSRC05`, 0, 1000, 1)
           this.displayGroupID(`#LD00DA`, 0, 1000, 1)
        }

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_TS_Common`, 0, 10, 0)
          this.displayGroupID(`#Mask_TS_Modules`, 0, 10, 0)

          this.stage = this.STG_Division_Lv2
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv2,
              mask : 'Mask_Topside',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'TS_Division_Lv2'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.drawProgress('Module & Common')
        }, 1000);
      }




      this.COMMON_TS_Division_Lv3 = () => { // ---------------------------------------------------------------------------------------------------------------------
        
        // Remove SVG
        setTimeout(() => {
          this.removeSvg(this.CANVAS, 800)
        }, 800)
        
        if(this.innerSvg) this.removeSvg(this.innerSvg, 1000)
        if (d3.select('#tag_button_wrapper')) this.removeSvg(d3.select('#tag_button_wrapper'), 1000)
        
        // ### Module Blocks
        let d = this.TOPSIDES.find(f => f.Lv3 == this.Lev3)
        setTimeout(() => {
          d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x1}, ${d.y1}) scale(${.35})`)
        }, 1000)

        // Remove SVG
        // this.removeSvg(d3.select(`#Blk_${d.BLK}`) , 2000) if(this.innerSvg) this.removeSvg(this.innerSvg, 2000)
        setTimeout(() => {
          this.removeSvg(this.svgModeling, 1000)   
          if(this.innerSvg) this.removeSvg(this.innerSvg, 2000)
        }, 1700);
  
        // Call
        this.Activate_OverallnMasks()        

        // Set Hull Position
        d3.select(`#Area_AFT`).transition().duration(1000).attr('transform', `translate(441, 692) scale(0.75)`)
        d3.select(`#Area_MS`).transition().duration(1000).attr('transform',  `translate(857, 495) scale(0.75)`)
        d3.select(`#Area_FOR`).transition().duration(1000).attr('transform', `translate(709, 619) scale(0.75)`)
        d3.select(`#Area_LQ`).transition().duration(1000).attr('transform',  `translate(947, 479) scale(0.75)`)
        d3.select(`#Area_VS`).transition().duration(1000).attr('transform',  `translate(1003, 576) scale(0.75)`)

        // move module
        d3.select(`#FL01`)  .transition().duration(0).attr('transform', `translate(347, 436) scale(0.75)`)
        d3.select(`#KO01`)  .transition().duration(0).attr('transform', `translate(375, 760) scale(0.75)`)
        d3.select(`#TSRC01`).transition().duration(0).attr('transform', `translate(514, 646) scale(0.75)`)
        d3.select(`#TSRC02`).transition().duration(0).attr('transform', `translate(663, 596) scale(0.75)`)
        d3.select(`#TSRC03`).transition().duration(0).attr('transform', `translate(805, 552) scale(0.75)`)
        d3.select(`#TSRC04`).transition().duration(0).attr('transform', `translate(954, 504) scale(0.75)`)
        d3.select(`#TSRC05`).transition().duration(0).attr('transform', `translate(1080, 458) scale(0.75)`)
        d3.select(`#LD00DA`).transition().duration(0).attr('transform', `translate(1184, 415) scale(0.75)`)

        // hide module
        this.hiddenGroupID('#MW01', 0, 0)
        this.hiddenGroupID('#MW02', 0, 0)
        this.hiddenGroupID('#MW04', 0, 0)
        this.hiddenGroupID('#MW05', 0, 0)
        this.hiddenGroupID('#ME01', 0, 0)
        this.hiddenGroupID('#ME02', 0, 0)
        this.hiddenGroupID('#ME04', 0, 0)
        this.hiddenGroupID('#ME05', 0, 0)

        setTimeout(() => {
          this.displayGroupID(`#Topside_Group`, 0, 0)
          d3.select('#Topside_Group').transition().duration(400).style('opacity',1)
        }, 2000)

        setTimeout(() => {

          this.displayGroupID(`#Mask_FL01`,   0, 0, 0)
          this.displayGroupID(`#Mask_KO01`,   0, 0, 0)
          this.displayGroupID(`#Mask_LD00DA`, 0, 0, 0)
          
          let lv2 = this.Lev2.toUpperCase()
          // Title & Keyplan
          d3.select(`#Title`).text(`Topside_${lv2}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TOPSIDE_${lv2}.jpg`)          
          
          this.Lev1 = 'TOPSIDES'
          this.Lev2 = lv2
          this.Lev3 = null
          this.Lev4 = null
          this.stage = this.STG_Division_Lv3
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : lv2 == 'COMMON' ? 'Mask_TS_Common' : 'Mask_TS_Modules',
              level: lv2
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'COMMON_TS_Division_Lv3'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)
          this.drawProgress(lv2 == 'COMMON' ? 'Topside Common' : 'Topside Modules')

        }, 2400);
      }

      this.MODULE_TS_Division_Lv3 = () => {

        this.removeSvg(this.CANVAS, 1000)

        if (this.innerSvg) this.removeSvg(this.innerSvg, 1000)
        if (d3.select('#tag_button_wrapper')) this.removeSvg(d3.select('#tag_button_wrapper'), 1000)
        // Explode

        setTimeout(() => {
          this.TOPSIDES.forEach(d => {
            if (d.BLK == this.Lev3 && d.Lv3 == 'ME05') 
              d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(${0.35})`)
            else if(d.BLK == this.Lev3 ) {
              d3.select(`#Blk_${this.Lev3}`).transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(${0.35})`)
            } else {
              this.displayGroupID(`#Blk_${d.BLK}`, 0, 0, 0)
            }
          })
        }, 800);
  
        setTimeout(() => {
          this.TOPSIDES.forEach(d => {
            if (d.Lv3 == this.Lev4) {
              this.displayGroupID(`#Blk_${d.BLK}`, 0, 0, 1)
            }
          })
        }, 1700); 
        
        setTimeout(() => {
          this.TOPSIDES.forEach(d => {
            if (d.Lv3 == this.Lev4) {
              this.displayGroupID(`#Mask_${d.BLK}`, 0, 0, 0)
            }
          })
        }, 1900); 
                
        setTimeout(() => {
          this.Lev1 = 'TOPSIDES'
          this.Lev2 = 'MODULES'
          this.Lev3 = this.Lev4
          this.Lev4 = null
          this.stage = this.STG_Lv4_Block_Division
          this.fncName = 'MODULE_TS_Division_Lv3'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          this.drawProgress(this.Lev3)     
        }, 2200);
      }

      this.MODULE_TS_Division_Lv4 = () => {

        this.removeSvg(this.CANVAS, 1000)

        setTimeout(() => {
          this.TOPSIDES.filter(f => f.layout == null&& f.Lv3 == 'ME05').forEach(d => {
            d3.select(`#Blk_${d.BLK}`).transition().duration(300).style('opacity', 1)
          })
        },500);
        setTimeout(() => {
          this.TOPSIDES.filter(f => f.Lv3 == this.Lev3 && f.Lv3 == 'ME05').forEach(d => {
            d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(${d.BLK === 'ME05DC' ? 0.235 : 0.35})`)
          })
        },1000);

        setTimeout(() => {
          this.TOPSIDES.filter(f => f.Lv3 == this.Lev3).forEach(d => {
            d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(${d.BLK === 'ME05DC' ? 0.235 : 0.35})`)
          })
        }, 1700);

        setTimeout(() => {
          this.TOPSIDES.filter(f => f.Lv3 == this.Lev3).forEach(d => {
            d3.select(`#Blk_${d.BLK}`).transition().duration(700).attr('transform', `translate(${d.x1}, ${d.y1}) scale(${d.BLK === 'ME05DC' ? 0.235 : 0.35})`)
          })
        }, 2500);

        setTimeout(() => {
          this.removeSvg(this.svgModeling, 1000)         
                  
        }, 3300);

        // Call
        this.Activate_OverallnMasks()

        // Set Hull Position
        d3.select(`#Area_AFT`).transition().duration(1000).attr('transform', `translate(441, 692) scale(0.75)`)
        d3.select(`#Area_MS`).transition().duration(1000).attr('transform',  `translate(857, 495) scale(0.75)`)
        d3.select(`#Area_FOR`).transition().duration(1000).attr('transform', `translate(709, 619) scale(0.75)`)
        d3.select(`#Area_LQ`).transition().duration(1000).attr('transform',  `translate(947, 479) scale(0.75)`)
        d3.select(`#Area_VS`).transition().duration(1000).attr('transform',  `translate(1003, 576) scale(0.75)`)

        // Set Topside Position
        d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(288, 612) scale(0.75)`)
        d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(524, 537) scale(0.75)`)
        d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(769, 415) scale(0.75)`)
        d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(975, 367) scale(0.75)`)
        d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(499, 729) scale(0.75)`)
        d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(718, 656) scale(0.75)`)
        d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(965, 522) scale(0.75)`)
        d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(1177, 485) scale(0.75)`)

        // hide
        this.hiddenGroupID(`#FL01`, 0, 0)
        this.hiddenGroupID(`#KO01`, 0, 0)
        this.hiddenGroupID(`#TSRC01`, 0, 0)
        this.hiddenGroupID(`#TSRC02`, 0, 0)
        this.hiddenGroupID(`#TSRC03`, 0, 0)
        this.hiddenGroupID(`#TSRC04`, 0, 0)
        this.hiddenGroupID(`#TSRC05`, 0, 0)
        this.hiddenGroupID(`#LD00DA`, 0, 0)    

        setTimeout(() => {
          this.displayGroupID(`#Topside_Group`, 0, 0)
          d3.select('#Topside_Group').transition().duration(600).style('opacity',1)
        }, 3600)

        setTimeout(() => {

          this.displayGroupID(`#Mask_MW01`,   0, 10, 0)
          this.displayGroupID(`#Mask_MW02`,   0, 10, 0)
          this.displayGroupID(`#Mask_MW04`, 0, 10, 0)
          this.displayGroupID(`#Mask_MW05`, 0, 10, 0)
          this.displayGroupID(`#Mask_ME01`, 0, 10, 0)
          this.displayGroupID(`#Mask_ME02`, 0, 10, 0)
          this.displayGroupID(`#Mask_ME04`, 0, 10, 0)
          this.displayGroupID(`#Mask_ME05`, 0, 10, 0)
          
          // Title & Keyplan
          d3.select(`#Title`).text(`Topside_${this.Lev2}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TOPSIDE_${this.Lev2}.jpg`)          
  
          this.Lev1 = 'TOPSIDES'
          this.Lev2 = this.Lev2
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : this.Lev2 == 'COMMON' ? 'Mask_TS_Common' : 'Mask_TS_Modules',
              level: this.Lev2
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.Lev3 = null
          this.Lev4 = null
          this.stage = this.STG_Division_Lv3
          this.fncName = 'MODULE_TS_Division_Lv4'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.drawProgress(this.Lev2 == 'COMMON' ? 'Topside Common' : 'Topside Modules')

        }, 4000);

      }

      // ===================================================================================================================
      // Activate
      // ===================================================================================================================
      
      // HULL-AFT --------------------------------------------------------------------------------------
      this.AFT_Activate_Hull = () => { 
        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Hidden
        this.hiddenGroupID('#Mask_HL02', 0, 10)
        this.hiddenGroupID('#Mask_MD00a', 0, 10)
        this.hiddenGroupID('#Mask_RB01_02', 0, 10)
        
        // Explode
        this.Hull_AFT.forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x1}, ${d.y1}) scale(0.5)`)
        })

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_AFT_Group`, 0, 10, 0)
          
          this.stage = this.STG_Activate
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv2,
              mask : 'Mask_Hull',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'AFT_Activate_Hull'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.drawProgress('Hull & LQ')
        }, 1000);
      }

      // HULL-FOR --------------------------------------------------------------------------------------
      this.FOR_Activate_Hull = () => { 

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)

        // Hidden
        this.hiddenGroupID('#Mask_HL03', 0, 10)
        this.hiddenGroupID('#Mask_MD00f', 0, 10)
        this.hiddenGroupID('#Mask_VS4_05', 0, 10)
        
        // Explode
        this.Hull_FOR.forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x1}, ${d.y1}) scale(0.5)`)
        })

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_FOR_Group`, 0, 10, 0)
          
          this.stage = this.STG_Activate
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv2,
              mask : 'Mask_Hull',
              level: ''
            }
            this.selectedItems.push(dStage)
            this.Goto_Plag = false
          }
          this.fncName = 'FOR_Activate_Hull'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.drawProgress('Hull & LQ')
        }, 1000);
      }

      // MS --------------------------------------------------------------------------------------
      this.MS_Activate_Hull = () => {
        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)
        this.removeSvg(this.innerSvg, 1000)
        if (d3.select('#tag_button_wrapper')) this.removeSvg(d3.select('#tag_button_wrapper'), 1000)
        this.displayGroupID(`#Mask_Lev1`, 0, 0, 0)
        this.displayGroupID(`#Mask_Lev2`, 0, 0, 0)
        this.displayGroupID(`#Mask_Lev3`, 0, 0, 0)
        this.displayGroupID(`#Mask_Lev4`, 0, 0, 0)

        setTimeout(() => {
          this.Hull_Mechinaery.forEach(d => {
            if(d.BLK == `${this.Lev4}_EQ`) {
              d3.select(`#Blk_${this.Lev4}_EQ`).transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(${.35})`)
            } else {
              this.displayGroupID(`#Blk_${d.BLK}`, 1000, 1)
            }
          })
        }, 1000);        

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_Lev1`, 0, 0, 0)
          this.displayGroupID(`#Mask_Lev2`, 0, 0, 0)
          this.displayGroupID(`#Mask_Lev3`, 0, 0, 0)
          this.displayGroupID(`#Mask_Lev4`, 0, 0, 0)
 
      
          this.stage = this.STG_Activate
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : 'Mask_Hull_MS',
              level: 'MS'
             }
             this.selectedItems.push(dStage)
             this.Goto_Plag = false
          }
          this.fncName = 'MS_Activate_Hull'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)
     
          // ### Call Canvas ###
          this.drawProgress('Hull MS')
        }, 1600);

      }

      // LQ --------------------------------------------------------------------------------------
      this.LQ_Activate_Hull = () => { 
       
        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)
        
        if (this.innerSvg) this.removeSvg(this.innerSvg, 1000)
        if (d3.select('#tag_button_wrapper')) this.removeSvg(d3.select('#tag_button_wrapper'), 1000)
          // Explode
          setTimeout(() => {
            this.Hull_LQ.forEach(d => {
              if(d.BLK.includes(this.Lev4 )) {
                d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(${.35})`)
              } else {
                this.displayGroupID(`#Blk_${d.BLK}`, 200, 1)
              }
            })
          }, 700);

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_LQ01`, 0, 0, 0)
          this.displayGroupID(`#Mask_LQ02`, 0, 0, 0)
          this.displayGroupID(`#Mask_LQ03`, 0, 0, 0)
          this.displayGroupID(`#Mask_LQ04`, 0, 0, 0)
          this.displayGroupID(`#Mask_LQ05`, 0, 0, 0)
          this.displayGroupID(`#Mask_LQ06`, 0, 0, 0)
          this.displayGroupID(`#Mask_LQ07`, 0, 0, 0)
          
          
          this.stage = this.STG_Activate
          if (this.Goto_Plag) {
            let dStage = {
              stage: this.STG_Division_Lv3,
              mask : 'Mask_Hull_LQ',
              level: 'LQ'
             }
             this.selectedItems.push(dStage)
             this.Goto_Plag = false
          }
          this.fncName = 'LQ_Activate_Hull'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)
          // ### Call Canvas ###
          this.drawProgress('Hull LQ')
        }, 1000);
      }

      // Topsides --------------------------------------------------------------------------------------
      this.Topside_Activate_Module = () => { 

        // Remove SVG
        this.removeSvg(this.CANVAS, 1000)
        
        // Remove SVG
        this.removeSvg(this.svg_BlockCode, 1000) 

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)
        
        // Explode
        this.TOPSIDES.filter(f => f.Lv3 == this.Lev3).forEach(d => {
          d3.select(`#Blk_${d.Blk}`)
          .transition().duration(1000).attr('transform', `translate(${d.x1}, ${d.y1}) scale(0.75)`)
        })

        setTimeout(() => {
          // Display
          this.displayGroupID(`#Mask_Mod_${this.Lev3}`, 0, 10, 0)

          this.stage = this.STG_Activate
          this.fncName = 'Topside_Activate_Module'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Display Canvas ###
          this.Canvas_Topside_Activate(this.Lev3)
        }, 1000);
      }

      // ===================================================================================================================
      // Division_Hull_Lv4
      // ===================================================================================================================

      // HULL-AFT --------------------------------------------------------------------------------------
      // this.AFT_Division_Hull_Lv4 = () => { 

      //   // Remove SVG
      //   this.removeSvg(this.CANVAS, 1000)

      //   // Title & Keyplan
      //   d3.select(`#Title`).text(`Hull-AFT`)
      //   d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL_AFT.jpg`)

      //   // Hidden
      //   this.hiddenGroupID('#Mask_HL02_Main', 0, 10)
      //   this.hiddenGroupID('#Mask_HL02_After', 0, 10)
      //   this.hiddenGroupID('#Mask_HL02_Port', 0, 10)
      //   this.hiddenGroupID('#Mask_HL02_Starboard', 0, 10)
      //   this.hiddenGroupID('#Mask_HL02_Bulkhead', 0, 10)
      //   this.hiddenGroupID('#Mask_HL02_Bottom', 0, 10)
        
      //   // Explode
      //   this.Hull_AFT.forEach(d => {
      //     d3.select(`#Blk_${d.Blk}`)
      //     .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)

      //     d3.select(`#Hull_AFT_Group`)
      //     .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)
      //   })

      //   setTimeout(() => {
      //     // Display
      //     this.Hull_AFT.filter(f=> f.Lv3 !== 'HL02').forEach(d => {
      //       this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
      //     })
    
      //     // Display
      //     this.displayGroupID(`#Mask_HL02`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_MD00a`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_RB01_02`, 0, 10, 0)

      //     this.stage = this.STG_Division_Lv3
      //     this.fncName = 'AFT_Division_Hull_Lv4'
      //     this.StageCode() // AREA / STAGE
      //     this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //      // ### Call Canvas ###
      //      this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      //   }, 1000);
      // }

      // // HULL-FOR --------------------------------------------------------------------------------------
      // this.FOR_Division_Hull_Lv4 = () => { 
        
      //   // Remove SVG
      //   this.removeSvg(this.CANVAS, 1000)

      //   // Title & Keyplan
      //   d3.select(`#Title`).text(`Hull-FOR`)
      //   d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL_FOR.jpg`)

      //   // Hidden
      //   this.hiddenGroupID('#Mask_HL03_Main', 0, 10)
      //   this.hiddenGroupID('#Mask_HL03_Forward', 0, 10)
      //   this.hiddenGroupID('#Mask_HL03_Port', 0, 10)
      //   this.hiddenGroupID('#Mask_HL03_Starboard', 0, 10)
      //   this.hiddenGroupID('#Mask_HL03_Bulkhead', 0, 10)
      //   this.hiddenGroupID('#Mask_HL03_Bottom', 0, 10)
        
      //   // Explode
      //   this.Hull_FOR.forEach(d => {
      //     d3.select(`#Blk_${d.Blk}`)
      //     .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
  
      //     d3.select(`#Hull_FOR_Group`)
      //     .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)
      //   })

      //   setTimeout(() => {
      //     // Display
      //     this.Hull_FOR.filter(f=> f.Lv3 !== 'HL03').forEach(d => {
      //       this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
      //     })
     
      //     // Display
      //     this.displayGroupID(`#Mask_HL03`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_MD00f`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_VS4_05`, 0, 10, 0)

      //     this.stage = this.STG_Division_Lv3
      //     this.fncName = 'FOR_Division_Hull_Lv4'
      //     this.StageCode() // AREA / STAGE
      //     this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //      // ### Call Canvas ###
      //      this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      //   }, 1000);
      // }

      // LQ --------------------------------------------------------------------------------------
      // this.LQ_Division_Hull_Lv4 = () => { 

      //   // Remove SVG
      //   this.removeSvg(this.CANVAS, 1000)

      //   // Title & Keyplan
      //   d3.select(`#Title`).text(`HULL-LQ`)
      //   d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL_LQ.jpg`)

      //   // Hidden
      //   this.hiddenGroupID('#Mask_LV01', 0, 10)
      //   this.hiddenGroupID('#Mask_LV23', 0, 10)
      //   this.hiddenGroupID('#Mask_LV45', 0, 10)
      //   this.hiddenGroupID('#Mask_LV67', 0, 10)
        
      //   // Explode
      //   this.Hull_LQ.forEach(d => {
      //     d3.select(`#Blk_${d.Blk}`)
      //     .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
      //   })

      //   d3.select(`#Hull_LQ_Group`)
      //   .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)

      //   setTimeout(() => {
      //     // Display
      //     this.Hull_LQ.filter(f=> f.Lv3 !== 'LQ00').forEach(d => {
      //       this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
      //     })
      //   }, 1000);
        
      //   setTimeout(() => {
      //     // Display
      //     this.displayGroupID(`#Mask_LQ00`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_HD01`, 0, 10, 0)

      //     this.stage = this.STG_Division_Lv3
      //     this.fncName = 'LQ_Division_Hull_Lv4'
      //     this.StageCode() // AREA / STAGE
      //     this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //      // ### Call Canvas ###
      //      this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      //   }, 1500);
      // }


      // ===================================================================================================================
      // Lv4_Block_Division
      // ===================================================================================================================

     

      // // LQ --------------------------------------------------------------------------------------
      // this.LQ_Lv4_Block_Division = () => { 
 
      //   // Remove SVG
      //   this.removeSvg(this.svg_BlockCode, 1000) 
        
      //   d3.select(`#Title`).text('Hull-LQ-LQ00')
      //   d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Keyplan/HULL_LQ00.jpg`)

      //   // Scale
      //   d3.select(`#Hull_LQ_Group`)
      //   .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)

      //   // Unexplode
      //   this.Hull_LQ.filter(f=> f.Lv4 == this.Lev4).forEach(d => {
      //     d3.select(`#Blk_${d.Blk}`)
      //     .transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(0.5)`)
      //   })

      //   setTimeout(() => {
      //     // Display
      //     this.Hull_LQ.filter(f=> f.Lv3 == this.Lev3 && f.Lv4 !== this.Lev4).forEach(d => {
      //       this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
      //     })
      //   }, 1000);
        
      //   setTimeout(() => {
      //     // Display
      //     this.displayGroupID(`#Mask_LV01`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_LV23`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_LV45`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_LV67`, 0, 10, 0)

      //     this.stage = this.STG_Division_Lv4
      //     this.fncName = 'LQ_Lv4_Block_Division'
      //     this.StageCode() // AREA / STAGE
      //     this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //      // ### Call Canvas ###
      //      this.Canvas_Division_Lv4(this.Lev2)
      //   }, 1500);
      // }


      // ===================================================================================================================
      // Lv3_Block_Division
      // ===================================================================================================================

      // HULL-AFT --------------------------------------------------------------------------------------
      // this.AFT_Lv3_Block_Division = () => { 
        
      //   // Remove SVG
      //   this.removeSvg(this.svg_BlockCode, 1000) 
      //   this.removeSvg(this.CANVAS, 1000)
        
      //   // Title & Keyplan
      //   d3.select(`#Title`).text(`HULL-AFT`)
      //   d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL_AFT.jpg`)

      //   // Scale
      //   d3.select(`#Hull_AFT_Group`)
      //   .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)

      //   // Unexplode
      //   this.Hull_AFT.filter(f=> f.Lv4 == this.Lev4).forEach(d => {
      //     d3.select(`#Blk_${d.Blk}`)
      //     .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
      //   })

      //   setTimeout(() => {
      //     // Display
      //     this.Hull_AFT.filter(f=> f.Lv2 == this.Lev2 && f.Lv3 !== this.Lev3).forEach(d => {
      //       this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
      //     })
      //   }, 1000);
        
      //   setTimeout(() => {
      //     // Display
      //     this.displayGroupID(`#Mask_HL02`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_MD00a`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_RB01_02`, 0, 10, 0)

      //     this.stage = this.STG_Division_Lv3
      //     this.fncName = 'AFT_Lv3_Block_Division'
      //     this.StageCode() // AREA / STAGE
      //     this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //     // ### Call Canvas ###
      //     this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      //   }, 1500);
      // }

      // // HULL-FOR --------------------------------------------------------------------------------------
      // this.FOR_Lv3_Block_Division = () => { 
        
      //   // Remove SVG
      //   this.removeSvg(this.svg_BlockCode, 1000) 
      //   this.removeSvg(this.CANVAS, 1000)
        
      //   // Title & Keyplan
      //   d3.select(`#Title`).text(`HULL-FOR`)
      //   d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL_FOR.jpg`)

      //   // Scale
      //   d3.select(`#Hull_FOR_Group`)
      //   .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)

      //   // Unexplode
      //   this.Hull_FOR.filter(f=> f.Lv4 == this.Lev4).forEach(d => {
      //     d3.select(`#Blk_${d.Blk}`)
      //     .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
      //   })

      //   setTimeout(() => {
      //     // Display
      //     this.Hull_FOR.filter(f=> f.Lv2 == this.Lev2 && f.Lv3 !== this.Lev3).forEach(d => {
      //       this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
      //     })
      //   }, 1000);
        
      //   setTimeout(() => {
      //     // Display
      //     this.displayGroupID(`#Mask_HL03`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_MD00f`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_VS4_05`, 0, 10, 0)

      //     this.stage = this.STG_Division_Lv3
      //     this.fncName = 'FOR_Lv3_Block_Division'
      //     this.StageCode() // AREA / STAGE
      //     this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //     // ### Call Canvas ###
      //     this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      //   }, 1500);
      // }

      // // LQ --------------------------------------------------------------------------------------
      // this.LQ_Lv3_Block_Division = () => { 
        
      //   // Remove SVG
      //   this.removeSvg(this.svg_BlockCode, 1000)
      //   this.removeSvg(this.CANVAS, 1000)
        
      //   // Title & Keyplan
      //   d3.select(`#Title`).text(`HULL-LQ`)
      //   d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL_LQ.jpg`)

      //   // Scale
      //   d3.select(`#Hull_LQ_Group`)
      //   .transition().duration(1000).attr('transform', `translate(0,0) scale(1)`)

      //   // Unexplode
      //   this.Hull_LQ.forEach(d => {
      //     d3.select(`#Blk_${d.Blk}`)
      //     .transition().duration(1000).attr('transform', `translate(${d.x2}, ${d.y2}) scale(0.5)`)
      //   })

      //   setTimeout(() => {
      //     // Display
      //     this.Hull_LQ.filter(f=> f.Lv2 == this.Lev2 && f.Lv3 !== this.Lev3).forEach(d => {
      //       this.displayGroupID(`#Blk_${d.Blk}`, 0, 500, 1)
      //     })
      //   }, 1000);
        
      //   setTimeout(() => {
      //     // Display
      //     this.displayGroupID(`#Mask_LQ00`, 0, 10, 0)
      //     this.displayGroupID(`#Mask_HD01`, 0, 10, 0)

      //     this.stage = this.STG_Division_Lv3
      //     this.fncName = 'LQ_Lv3_Block_Division'
      //     this.StageCode() // AREA / STAGE
      //     this.displayGroupID(`#Menu_Back`, 0, 200, 1)

      //     // ### Call Canvas ###
      //     this.Canvas_Division_Lv3(this.Lev1, this.Lev2)
      //   }, 1500);
      // }

    },

  }
}
