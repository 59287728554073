<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  
  
    FilterValues: {
      type: Object,
      default: () => ({})
    },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Queries: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    ColumnProps: { type: Array, default: () => ([]) },

    // Milestones Resulat
    // values: {
    //   type: Object,
    //   default: () => ({
    //     milestone: [],
    //     note: '',
    //   })
    // }
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      // if (!this.ready2draw) return
      if (!this.Queries.SQL1) return this.register(this.draw)

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()

      this.drawChart()

      setTimeout(() => {
        this.drawTexts()
      }, 200);

      if (this.getSelectItems) {
        this.setEvent_PDF()
      }      
      
      this.complete()
    },

    drawChart() {
      this.draw_Forms()
      this.Activate_Overall()
      this.drawEQPopup()
      // this.DrawSearch()
      if (!this.getSelectItems) this.checkFV()
    },
    checkFV() {

      if(
        !this.FilterValues || 
        !this.FilterValues.LV3 || 
        this.FilterValues.LV3 == this.prevFilterValues.LV3
      ) return

      this.prevFilterValues = this.FilterValues
      
      this.Goto_Screen_Dirty(this.FilterValues)
    }
  }
}
</script>
