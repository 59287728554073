<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: {type: Object, default: () => ({}) },

    values: {
      type: Object,
      default: () => ({})
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return

      this.clear()
      this.setStyles()
      
      let dataSet = JSON.parse(JSON.stringify(this.DataItems.filter(f => !!f[this.activity])))
      dataSet.forEach(d => {
        if (d.SUBSYSTEM && d.SUBSYSTEM.length > 10) {
          this.maxValue = d.SUBSYSTEM.length
        }
      })

      if (this.maxValue != 0 ) {
        let fixed_width = 38
        this.skyline.box.width = parseInt((fixed_width * this.maxValue) / 7)
      }  
      this.setDefaultValues()   

      this.drawCanvas()
      this.drawTexts()
      this.drawChart()

      this.draw_LineCharts()
      // this.draw_DropdownMenu()
      
      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()

      this.chartMounted = true
    },
    drawChart() {
      this.SkylineCharts = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)
      
      this.draw_Table()
      this.draw_Timeline()
      this.draw_Charts()
      this.draw_Legends()
      
      // this.Chart_filtering()
    },
    redraw() {
      this.setDefaultValues()
      this.draw_Table()
      this.draw_Charts()
      this.draw_LineCharts()
    }
  }
}
</script>