import * as d3 from 'd3'

export default {
  data: () => ({
    boxY: 0,
    boxHeight: 18,

  }),

  methods: {

    draw_Charts() {

      let BoxesChart = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(120,15)`)

      
      this.shipment.forEach((s,i) => {

        let ship = this.dataSet.find(f => s.name == f.SHIP)

          let vessel = BoxesChart
          .append('g')
          .attr('transform', `translate(0,0)`)
          .attr('opacity', () => {
            if (ship.CERT == 'RFSC') return .5; else return 1
          })

          vessel
          .append('rect')
          .attr('x', this.timelineScale(ship.plan)-18)
          .attr('y', s.y + 4)
          .attr('ry', 3)
          .attr('ry', 3)
          .attr('width', 37)
          .attr('height', 15)
          .attr('fill', '#fff')
          .attr('stroke', () => {
            if (ship.CERT == 'RFSC') return '#818181'; else return '#F7BACF'
          })
          .attr('stroke-width', 0.5)

          vessel
          .append('text')
          .attr('x', this.timelineScale(ship.plan))
          .attr('y', s.y + 13)
          .style('font-size', 10.5)
          .attr('fill', () => {
            if (ship.CERT == 'RFSC') return '#333'; else return '#EC407A'
          })
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .text(ship.SHIP_NAME)

          vessel
          .append('text')
          .attr('x', this.timelineScale(ship.plan))
          .attr('y', s.y + 27)
          .style('font-size', 10.5)
          .attr('fill', () => {
            if (ship.CERT == 'RFSC') return '#333'; else return '#44A9DF'
          })
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .text(d3.timeFormat('%m-%d')(ship.plan))

          vessel
          .append('line')
          .attr('x1', this.timelineScale(ship.plan)).attr('y1', s.y + 35)
          .attr('x2', this.timelineScale(ship.plan)).attr('y2', 300)
          .attr('stroke', '#bcbcbc')
          .attr('stroke-width', 0.5)

          let pauY = s.y - 2
          this.dataSet.filter(f => f.SHIP == s.name).forEach( d => {
            vessel
            .append('text')
            .attr('x', this.timelineScale(d.plan))
            .attr('y', pauY)
            .style('font-size', 9)
            .style('fill', '#757575')
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .text(d.PAU)

            pauY -= 10
          })
      })



      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
        this.parentNode.appendChild(this);
        });
      }

      this.timelineValues.week.forEach(w => {

        this.boxY = 280
        this.dataSet.filter(f => f.mDate == w.mDate).forEach(d => {
          
          let X = this.timelineScale(d.sDate)  
          let Y = this.boxY 

          let Boxes = BoxesChart
          .append('g')
          .attr('id', `${d.PAU}`)
          .attr('transform', `translate(${X},${Y})`)
          .attr('opacity', () => {
            if (d.CERT == 'RFSC') return .8; else return 1
          })
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            d3.select(this).moveToFront().transition().duration(100)
            .attr('transform', `translate(${X-10},${Y-5}) scale(1.3)`)
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(100)
            .attr('transform', `translate(${X},${Y}) scale(1)`)
          })
          .on('click', () => {
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',    // important!
                target: 'slide-modal',
                component: 'ServiceSvgTabs',
                id: 168,
                no: 'LIBSVGTB-0001'
              },
              filters     : {
                MOD: d.MOD,
              },
              iFilters    : {
                filterString : ``,
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })
    

          Boxes
          .append('rect')
          .attr('ry', 3)
          .attr('ry', 3)
          .attr('width', this.weekSize)
          .attr('height', this.boxHeight)
          .attr('fill', d.bColor)
          .attr('stroke', '#BCBCBC')
          .attr('stroke-width', 0.5)

          Boxes
          .append('text')
          .attr('x', this.weekSize/2)
          .attr('y', this.boxHeight/2 + 1)
          .style('font-size', 10.5)
          .style('fill', d.tColor)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .text(d.PAU)

          this.boxY -= this.boxHeight + 2
        })
      })
    }, 

  }
}
