import { DBSupportApi } from './api'

export default class DatabaseSupportService {
    
  constructor() {
    this.dbSupportApi = new DBSupportApi()
  }

  getSqlQueries (phaseCode, groupCode, itemCode, subItemCode, callback) {
    this.dbSupportApi.getSqlQueries(phaseCode, groupCode, itemCode, subItemCode, (res) => { callback(res) })
  }
  getSqlQuery (idx, callback) {
    this.dbSupportApi.getSqlQuery(idx, (res) => { callback(res) })
  }
  putSqlQuery (query, callback) {
    this.dbSupportApi.putSqlQuery(query, res => { callback(res) })
  }
  updSqlQuery (query, callback) {
    this.dbSupportApi.updSqlQuery(query, res => { callback(res) })
  }
  delSqlQuery (idx, callback) {
    this.dbSupportApi.delSqlQuery(idx, res => { callback(res) })
  }
  getSqlQueryResult (params, callback) {
    // @@params
    // {
    //   idx: Number,      // *Query ID
    //   name: String,
    //   filters: String,  // COL_NAME = 'VALUE' AND ... || ... COL_NAME IN (VALUE, ... | SUB-QUERY)
    // }
    let params_ = new URLSearchParams()
    params_.append('params', JSON.stringify(params))
  
    return new Promise((resolve) => {
      this.dbSupportApi.getSqlQueryResult(params_, (res) => { 
        if(callback) callback(res) 
        resolve(res)
      })
    })
  }
}
