import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    
  }),


  methods: {
    
    draw_Timeline2020() {
      
      // Title -------------------------------------------------------------------------------------------------------------------------
      let headerTitle = this.milestoneCharts
      .append('g')
      .attr('transform', `translate(0, 0)`)

          headerTitle
          .append('text')
          .attr('x', 0).attr('y', 5).style('font-size', 24).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(`${this.dataSet[0].MOD} Module`)

          headerTitle
          .append('text')
          .attr('x', 0).attr('y', 22).style('font-size', 14).style('fill', '#B4E3FA').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(this.dataSet[0].DESC)

      // 2020 Modulws -------------------------------------------------------------------------------------------------------------------------
      this.modules2020 = this.milestoneCharts
      .append('g')
      .attr('transform', `translate(40, 80)`)
          
          
          this.draw_TimelineModule()
          this.draw_GanttChart()
          
    }, 
  }
}
