import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    headers: [
      { name: 'Disciplines' , colname: 'DISC'           , tColor: '#454545', x: 15  , cx: 15 },
      
      { name: 'Pre-Rev.'         , colname: 'PRE_PLAN_CUM'    , tColor: '#c5c5c5', x: 132 , cx: 148 },
      { name: 'Plan'         , colname: 'PLAN_CUM'    , tColor: '#454545', x: 203 , cx: 219 },
      { name: 'Actual'      , colname: 'ACTUAL_CUM'      , tColor: '#c5c5c5', x: 274 , cx: 290 },
      { name: 'Certified'   , colname: 'ACTUAL_CERT_CUM' , tColor: '#454545', x: 345 , cx: 361 },
      { name: 'Var.'        , colname: 'VAR_CUM'         , tColor: '#454545', x: 416 , cx: 432 },
      
    ],
    tWidth: 470,
  }),
  methods: {
    drawChart() {
      let table__ = this.svg
      .append('g')
      .attr('class', '__progress_summary_table')
      .attr('transform', `translate(5.5, 20.5)`)

      // Header Rows
      table__.append('rect').attr('x', 110).attr('y', 0).attr('width', this.tWidth - 110).attr('height', 40).attr('stroke-width', 0).attr('fill', '#F4F5F5').attr('opacity', 0.4)
      table__.append('line').attr('x1', 110).attr('y1', 0).attr('x2', this.tWidth).attr('y2', 0).attr('stroke-width', 0.3).attr('stroke', '#333')
      table__.append('line').attr('x1', 0).attr('y1', 40).attr('x2', this.tWidth).attr('y2', 40).attr('stroke-width', 0.3).attr('stroke', '#333')

      // Header Text
      let headerTextGroup = table__
      .append('g')
      .attr('class', '__header_text')
      .attr('transform', `translate(0, 11)`)

      headerTextGroup
      .append('text')
      .attr('transform', `translate(230, 2)`)
      .style('font-family','roboto')
      .style('font-size', 11)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .attr('fill', '#000')
      .text('Cummulative Progress')

      this.headers.forEach(h => {
        headerTextGroup
        .append('text')
        .attr('x', h.x)
        .attr('y', 18)
        .style('font-family','roboto')
        .style('font-size', 10)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .attr('fill', '#757575')
        .text(h.name)
      })

      // Row-Data Text
      let rowTextGroup = table__
      .append('g')
      .attr('class', '__row_text')
      .attr('transform', `translate(0, 52)`)

      let rowLineStartY = 60
      let items = this.DataItems.filter(item => item.DISC != 'Total')
      let rowCount = items.length
      items.forEach((item, i) => {
        this.headers.forEach((h, j) => {
          rowTextGroup
          .append('text')
          .attr('x', h.cx)
          .attr('y', 18*i)
          .style('font-family','roboto')
          .style('font-size', 10)
          .attr('text-anchor', j===0?'start':'middle')
          .attr('alignment-baseline', 'middle')
          .attr('fill', j === 0 ?'#757575' : (item[h.colname]<0?'#dd2c00':'757575'))
          .text(j === 0 ? item[h.colname] : item[h.colname].toFixed(2))
        })

        if(i<rowCount-1) table__
          .append('line')
          .attr('x1', 0)
          .attr('y1', 18*i+rowLineStartY)
          .attr('x2', this.tWidth)
          .attr('y2', 18*i+rowLineStartY)
          .attr('stroke-width', 0.3)
          .attr('stroke', '#959595')
          .attr('opacity', 0.4)
      })

      // Total Text
      let rowLen = this.DataItems.length * 18 + 26
      let totalTextGroup = table__
      .append('g')
      .attr('class', '__total_text')
      .attr('transform', `translate(0, ${rowLen})`)

      // Total Rows
      totalTextGroup.append('rect').attr('x', 0).attr('y', 0).attr('width', this.tWidth).attr('height', 26).attr('stroke-width', 0).attr('fill', '#f4f5f5').attr('opacity', 0.4)
      totalTextGroup.append('line').attr('x1', 0).attr('y1', 0).attr('x2', this.tWidth).attr('y2', 0).attr('stroke-width', 0.3).attr('stroke', '#333')
      totalTextGroup.append('line').attr('x1', 0).attr('y1', 26).attr('x2', this.tWidth).attr('y2', 26).attr('stroke-width', 0.3).attr('stroke', '#333')
      
      let totalItem = this.DataItems.filter(item => item.DISC == 'Total')[0]
      this.headers.forEach((h, j) => {
        totalTextGroup
        .append('text')
        .attr('x', h.cx)
        .attr('y', 14)
        .style('font-family','roboto')
        .style('font-size', 11)
        .attr('text-anchor', j===0?'start':'middle')
        .attr('alignment-baseline', 'middle')
        .attr('fill', j===0?'#454545':(totalItem[h.colname]<0?'#dd2c00':'454545'))
        .text(j===0?totalItem[h.colname]:totalItem[h.colname].toFixed(2))
      })

      // Separator
      table__.append('rect').attr('x', 580).attr('y', -5).attr('width', 10).attr('height', 260).attr('fill', '#fff')
    }, 
  }
}
