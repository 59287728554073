import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Charts() {
      
      let data = null, 
          style = null

      let AitrDiscipline= this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      let week4 = 0
      this.Queries.SQL1.forEach(d=> { week4 += d. PERIOD })

      
      AitrDiscipline
      .append('text')
      .attr('id', `week4`)
      .attr('transform', `translate(270, 55)`)
      .style('font-family', 'roboto').style('font-size', 12)
      .style('fill', '#83D2F5').style('text-anchor', 'end').style('alignment-baseline', 'alphabetic').text(`ITRs / Week`)

      AitrDiscipline
      .append('text')
      .attr('transform', `translate(${265 - this.getNodeElValue('#week4', 'width')}, 55)`)
      .style('font-family', 'roboto').style('font-size', 16)
      .style('fill', '#44A9DF').style('text-anchor', 'end').style('alignment-baseline', 'alphabetic')
      .text(()=> {
        if(week4 > 0) return (week4/4).toFixed(1)
      })

      AitrDiscipline
      .append('text')
      .attr('transform', `translate(270, 67)`)
      .style('font-family', 'roboto').style('font-size', 10)
      .style('fill', '#757575').style('text-anchor', 'end').style('alignment-baseline', 'middle').text('Average ITRs Completed per Week (Last 4Weeks)')




       // Bar_Progress_01
       data = this.dataSet.filter(f=> f.DISC !== 'Overall')
       style = {
                x:        0,
                y:        130,
                width:    80,
                title:    {text: 'ITRs Status by discipline', x: 28, tSize: 11, tColor: '#333'},
                cell:     [70, 108, 146, 184, 190, 250, 300],
                lineHeight: 20,
                bColor:   '#B4E3FA', 
                sColor:   '#44A9DF', 
                tColor1:  '#fff', 
                tColor2:  '#757575', 
                opacity:  1,
                requests: [
                  {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'ServiceDatatable',
                      id: 192,
                      no: ''
                    },
                    filters: { 
                      SUBSYSTEM: this.FilterValues.SYS
                    },
                    iFilters: {
                      filterString : "[STAGE] = 'A'",
                      inputFilter  : ''
                    }
                  },
                  {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'ServiceDatatable',
                      id: 192,
                      no: ''
                    },
                    filters: { 
                      SUBSYSTEM: this.FilterValues.SYS
                    },
                    iFilters: {
                      filterString : "[STAGE] = 'A' AND [STATUS] = 'Y'",
                      inputFilter  : ''
                    }
                  },
                  {
                    dataType: 'row',
                    action: {
                      type: 'direct',
                      target: 'slide-modal',
                      component: 'ServiceDatatable',
                      id: 192,
                      no: ''
                    },
                    filters: { 
                      SUBSYSTEM: this.FilterValues.SYS
                    },
                    iFilters: {
                      filterString : "[STAGE] = 'A' AND [STATUS] = 'N'",
                      inputFilter  : ''
                    }
                  },
                ]
              }
       this.Bar_Progress_01(AitrDiscipline, data, style) // ---------------------------> 
    },
  }
}
           