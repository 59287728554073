import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

   
    
  }),


  methods: {
    
    draw_Timeline() {

      // Legends 
      let Legends = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(20, 20)`)

          Legends
          .append('text')
          .attr('x', 0)
          .attr('y', 0)
          .attr('font-size', 24)
          .attr('fill', '#757575')
          .attr('text-anchor', 'start')
          .text(`RFS 2020 Modules`)
          Legends
          .append('text')
          .attr('x', 0)
          .attr('y', 16)
          .attr('font-size', 14)
          .attr('fill', '#757575')
          .attr('text-anchor', 'start')
          .text(`System Completion`)

          Legends
          .append('text')
          .attr('x', 0)
          .attr('y', 50)
          .attr('font-size', 11)
          .attr('fill', '#333')
          .attr('text-anchor', 'start')
          .text('Legends')

          this.style.forEach((s,i) => {   
            Legends
            .append('rect')
            .attr('x', 5)
            .attr('y', s.y)
            .attr('width', 10)
            .attr('stroke', '#bcbcbc')
            .attr('stroke-width', 0.5)
            .attr('height', 10).attr('fill', s.bColor)
            
            Legends
            .append('text')
            .attr('x', 20)
            .attr('y', s.y + 8)
            .attr('font-size', 10)
            .attr('fill', '#757575')
            .attr('text-anchor', 'start')
            .text(`${s.title} (${ this.dataSet.filter(f => f.CERT == s.name).length})`)
          })


      // Timeline 
      let Timeline = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(120, 700)`)

          Timeline
          .append('path') // shadow
          .attr('d', 'M50,37 H 1480 L 1530,47 H0 Z').attr('fill', `url(#shadowTimeline)`) 

          Timeline
          .append('rect')
          .attr('x', 0).attr('y', 0).attr('width', this.timelineWidth).attr('height', 35).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`).attr('opacity', .5)

          Timeline
          .append('rect') 
          .attr('x', 0).attr('y', 35).attr('width', this.timelineWidth).attr('height', 6).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`)


          // Timeline Text using the scale function
          // Month
          Timeline
          .append('g')
          .selectAll('text')
          .data(this.timelineValues.month)
          .enter()
          .append('text')
          .attr('x', d => this.timelineScale(d.mDate))
          .attr('y', 25).style('font-size', 13).style('fill', '#333').attr('font-weight', 500)
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(d => d.name.toUpperCase())

          // Timeline Seperator for the Month
          Timeline
          .append('g')
          .selectAll('path')
          .data(this.timelineValues.month)
          .enter()
          .append('path')
          .attr('d', (d, i) => `M${this.timelineScale(d.eDate)}, 20 V${i == this.timelineValues.month.length-1 ? 20 : 35}`)
          .attr('stroke', '#757575').attr('stroke-width', 0.5)



          // Weeks
          Timeline
          .append('g')
          .selectAll('text')
          .data(this.timelineValues.week)
          .enter()
          .append('text')
          .attr('x', d => this.timelineScale(d.mDate))
          .attr('y', 8).style('font-size', 10).style('fill', '#333')
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(d => d3.timeFormat('%d')(d.eDate))

          // Timeline Seperator for the Week
          Timeline
          .append('g')
          .selectAll('path')
          .data(this.timelineValues.week)
          .enter()
          .append('path')
          .attr('d', (d, i) => `M${this.timelineScale(d.eDate)}, 3 V${i == this.timelineValues.week.length-1 ? 3 : 12}`)
          .attr('stroke', '#757575').attr('stroke-width', 0.5)

          
    }, 
  }
}
