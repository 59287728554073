// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    Draw_Charts() {
      if(this.ColumnProps.length > 0) var props = this.ColumnProps.find(props_ => props_.value == 'comprops').props
      else props = this.defaultset

      let bar = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      bar.append('rect')
      .attr('width', props.width).attr('height', props.height).attr('fill', props.color)

      if (props.linkIcon === 'Y' && props.path) {
        let img = bar.append('image')
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/LQDashboard/icons/page.png`)
        .attr('transform', `translate(${props.iconX}, ${props.iconY})`)
        .attr('width', `${props.iconW ? props.iconW : 20}`).attr('height', `${props.iconH ? props.iconH : 18}`)
        .on('mouseover', () => {
          img.style('cursor', 'pointer')
        })
        .on('click', () => { 
          let request_ = {
            dataType: 'row',    
            action: {
              type: 'direct',
              target: 'url',
              path: `${props.path}`
            },
          }
          if (props.filters) {
            request_.filters = { ...props.filters }
          }
          // path: `${this.__HOST_NAME}${props.path}`
          // path: `http://localhost:8081/#/service/procurement/3d_visualization/equipment_3d_layout/equipment_3d_layout`,

          this.$emit('request-action', request_)
        })
      }

      if (props.filter === 'Y'){
        bar.append('text').attr('x', props.fX ? props.fX : 30).attr('y', props.fY ? props.fY : 16).attr('text-anchor', 'middle')
          .attr('class', `bar_filter_text__}`)
          .text(this.FilterValues[props.fKey])
          .style('font-size', props.fFontSize ? props.fFontSize : 13)
          .attr('fill', props.fColor ? props.fColor : '#000')
      }

    },
  }
}
