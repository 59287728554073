import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

  }),


  methods: {
    
    drawChart() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightBlue`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#d4eaf3').attr('offset', '0')
      lightBlue.append('stop').attr('stop-color', '#A3D0E8').attr('offset', '1')


      let mainGroup = this.svg
      .append('g')
      .attr('transform', `translate(20, 0)`)

          // mainGroup
          // .append('rect') // Background Window
          // .attr('x', 0).attr('y', 0).attr('width', 180).attr('height', 380)
          // .attr('fill', '#fff').attr('stroke', 'gray').attr('stroke-width', 0.5)


          let header = mainGroup
          .append('g')
          .attr('transform', `translate(0, 45)`)

              header
              .append('text')
              .attr('x', 125).attr('y', 0).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end').attr('fill', '#333').text('Total')
              
              header
              .append('text')
              .attr('x', 160).attr('y', 0).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end').attr('fill', '#333').text('PCOW')

              header
              .append('text')
              .attr('x', 195).attr('y', 0).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end').attr('fill', '#333').text('Remain')


          let pointY = 28
          let dataTable = mainGroup
          .append('g')
          .attr('transform', `translate(0, 20)`)

          // Get unique value from array
          let dataSystem = Array.from(new Set([ ...this.DataItems.map(data => data.subsystem ) ]))


          var SN = 0

          dataSystem.forEach((sys) => {
            dataTable
            .append('rect')
            .attr('x', 0).attr('y', pointY).attr('rx', 2).attr('ry', 2).attr('width', 0).attr('height', 16).attr('fill', `url(#lightBlue)`).attr('opacity', .5)
            .transition().duration(500).attr('width', 200)

            dataTable
            .append('text')
            .attr('x', 5).attr('y', pointY + 11).style('font-family','roboto').style('font-size',9.5).attr('text-anchor', 'start').attr('fill', '#333').text(sys)
            pointY += 18

            this.dataSet.forEach((d, i) => {

              if (sys == d.subsystem) {
                d.y = pointY + 9
                d.sn = SN

                dataTable
                .append('text')
                .attr('x', 5).attr('y', pointY + 9).style('font-family','roboto').style('font-size',9).attr('text-anchor', 'start').attr('fill', '#757575').text(d.name)

                dataTable
                .append('text')
                .attr('id', `${this.localId}_T_${d.sn}`)
                .attr('transform', `translate(${125}, ${d.y})`)
                .style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end').attr('fill', '#757575').text(d.total)
                .style('cursor', 'pointer')
                .on('mouseover', () => {
                  d3.select(`#${this.localId}_T_${d.sn}`).transition().duration(100).attr('transform', `translate(${125 + (this.getNodeElValue(`#${this.localId}_T_${d.sn}`, 'width')/6)}, ${d.y + 1}) scale(1.3)`)
                })
                .on('mouseout', () => {
                  d3.select(`#${this.localId}_T_${d.sn}`).transition().duration(100).attr('transform', `translate(${125}, ${d.y})`)
                })
                .on('click', () => {
                  let request_ = this.getRequestColumnProps('total', d, this.ColumnProps)
                  if(request_) {
                    request_.filters = {
                      ...request_.filters,
                      ...this.FilterValues
                    }
                    this.$emit('request-action', request_)
                  }   
                })

                dataTable
                .append('text')
                .attr('id', `${this.localId}_C_${d.sn}`)
                .attr('transform', `translate(${160}, ${d.y})`)
                .style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end').attr('fill', '#757575').text(d.pcow)
                .style('cursor', 'pointer')
                .on('mouseover', () => {
                  d3.select(`#${this.localId}_C_${d.sn}`).transition().duration(100).attr('transform', `translate(${160 + (this.getNodeElValue(`#${this.localId}_C_${d.sn}`, 'width')/6)}, ${d.y + 1}) scale(1.3)`)
                })
                .on('mouseout', () => {
                  d3.select(`#${this.localId}_C_${d.sn}`).transition().duration(100).attr('transform', `translate(${160}, ${d.y})`)
                })
                .on('click', () => {
                  let request_ = this.getRequestColumnProps('pcow', d, this.ColumnProps)
                  if(request_) {
                    request_.filters = {
                      ...request_.filters,
                      ...this.FilterValues
                    }
                    this.$emit('request-action', request_)
                  }   
                })

                dataTable
                .append('text')
                .attr('id', `${this.localId}_R_${d.sn}`)
                .attr('transform', `translate(${195}, ${d.y})`)
                .style('font-family','roboto').style('font-size',9).attr('text-anchor', 'end').attr('fill', '#757575').text(d.remain)
                .style('cursor', 'pointer')
                .on('mouseover', () => {
                  d3.select(`#${this.localId}_R_${d.sn}`).transition().duration(100).attr('transform', `translate(${195 + (this.getNodeElValue(`#${this.localId}_R_${d.sn}`, 'width')/6)}, ${d.y + 1}) scale(1.3)`)
                })
                .on('mouseout', () => {
                  d3.select(`#${this.localId}_R_${d.sn}`).transition().duration(100).attr('transform', `translate(${195}, ${d.y})`)
                })
                .on('click', () => {
                  let request_ = this.getRequestColumnProps('remain', d, this.ColumnProps)
                  if(request_) {
                    request_.filters = {
                      ...request_.filters,
                      ...this.FilterValues
                    }
                    this.$emit('request-action', request_)
                  }   
                })
                
                SN += 1
                pointY += 15
              }

            })
            
          })
          
    }, 

  }
}
