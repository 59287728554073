<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'        

export default {
  name: "j-chart-project-1",
  mixins: [mx_Core],
  props: {
    id: String,

    // for export pdf
    getSelectItems: String, 

    // Filter
    FilterValues: { type: Object, default: () => ({}) },

    // json props
    ColumnProps: { type: Array, default: () => ([]) },

    // Chart Data
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Queries: {type: Object, default: () => ({}) },
    
    // Database
    DataItems: { type: Array, default: () => ([]) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.set_LocalValues()
      this.drawCanvas()
      this.drawing()  
      this.drawTexts()
      this.complete()
    },

    drawing() {
      this.draw_Header()
      this.draw_Data()
      
    },
  }
}
</script>

<style lang="scss" scoped>
  // .svg_wrapper {
  //   // width: 1600px;
  //   // height: 1120px;
  //   // overflow: hidden;
  // }
</style>