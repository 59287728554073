import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

  }),


  methods: {
    
    draw_ChartsActions(column, status) {

      // QVDs
      if ((column == 'A1' || column == 'B1' || column == 'D1')&& status == 'total') {
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',    // important!
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 188,
              no: ''
            },
            filters     : {
              MOD: this.dataSet[this.target].MOD,
            },
            iFilters    : {
              filterString : `CHK_TYPE = '${column}'`,
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
      }

      if ((column == 'A1' || column == 'B1' || column == 'D1') && status == 'remain') {
        console.log(this.dataSet[this.target].MOD, column, status)
        let request_ = {
          dataType: 'row',
          action: {
            type: 'direct',    // important!
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 188,
            no: ''
          },
          filters     : {
            MOD: this.dataSet[this.target].MOD,
          },
          iFilters    : {
            filterString : `CHK_TYPE = '${column}' AND QVD_STATUS = 'Not Started' AND EXCEPT_NO = 'N'`,
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)
      }

      // Walkdown
      let Cert = ''
      if (column == 'WD') Cert = 'FDMCWD'
      if (column == 'DAC') Cert = 'FM-DAC1'
      if (column == 'FMC') Cert = 'FMC1'

      if ((column == 'WD' || column == 'DAC' || column == 'FMC')&& status == 'total') {
        // console.log(column, status)
        let request_ = {
          dataType: 'row',
          action: {
            type: 'direct',    // important!
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 189,
            no: ''
          },
          filters     : {
            MOD: this.dataSet[this.target].MOD,
          },
          iFilters    : {
            filterString : `CERT_TYPE = '${Cert}'`,
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)
      }


      if ((column == 'WD' || column == 'DAC' || column == 'FMC')&& status == 'remain') {
        // console.log(column, status)
        let request_ = {
          dataType: 'row',
          action: {
            type: 'direct',    // important!
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 189,
            no: ''
          },
          filters     : {
            MOD: this.dataSet[this.target].MOD,
          },
          iFilters    : {
            filterString : `CERT_TYPE = '${Cert}' AND STATUS = 'Cert Not Started'`,
            inputFilter  : ''
          }
        }
        this.$emit('request-action', request_)
      }
    },
  }
}
