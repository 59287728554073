<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-custom",
  mixins: [mx_Core],
  props: {
    id: String,

    FilterValues: {
      type: Object,
      default: () => ({
        MOD: '41M001'
      })
    },
    // Database
    Queries: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    // ### -------------- --- --------------------

    // Chart Data
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },

    values: {
      type: Object,
      default: () => ({
        milestone: [],
        note: '',
      })
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()

      this.complete()
    },

    drawChart() {
      this.Draw_Forms()
      this.Draw_BarChart()

      if(!this.FilterValues.MOD) return
      
      this.Draw_ISO(this.FilterValues.MOD)
      this.loadSvg(`${this.__HOST_NAME_RESOURCE}/TCO/Equipment/${this.FilterValues.MOD}/${this.FilterValues.MOD}_OVERALL.svg`).then(() => {
        this.Draw_Code()
      })
    },

    reforming(code) {
      this.loadSvg(`${this.__HOST_NAME_RESOURCE}/TCO/Equipment/${this.FilterValues.MOD}/${this.FilterValues.MOD}_${code}.svg`).then(() => {
        this.Draw_Code()
      })
    }
  }
}
</script>