import * as d3 from 'd3'

export default {
  methods: {
    Activate_Hull_MS() {

      // Remove SVG
      this.removeSvg(this.CANVAS, 1000)

      // Title & Keyplan
      d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL_MS.jpg`)

      this.svgModeling = this.svg.append('svg')

      this.svgModeling
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .style('opacity', 1)

      // Overall_Hull_Group
      let Hull_MS = this.svgModeling
      .append('g')
      .attr('id', `Hull_MS_Group`)
      .attr('transform', `translate(0,0) scale(1)` )
      .attr('opacity', 1)
        
      // ### Module Blocks
      setTimeout(()=>{
        this.Hull_Mechinaery.forEach(d => {

          Hull_MS
          .append('image')
          .attr('id', `Blk_${d.BLK}`)
          .style('opacity', 1).style('visibility', 'visible')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Blocks/Hull/${d.BLK}.png`)
          .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${0.35})`)
        })
      },600)

      setTimeout(() => {
        this.Hull_Mechinaery.forEach(d => {
          d3.select(`#Blk_${d.BLK}`).transition().duration(700).attr('transform', `translate(${d.x2}, ${d.y2}) scale(${0.35})`)
        })
      }, 1000);

      setTimeout(() => {
        this.Hull_Mechinaery.forEach(d => {
          d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x3}, ${d.y3}) scale(${0.35})`)
        })
      }, 1700);

      setTimeout(() => {
        this.Hull_Mechinaery.forEach(d => {
          d3.select(`#Blk_${d.BLK}`).transition().duration(1000).attr('transform', `translate(${d.x4}, ${d.y4}) scale(${0.35})`)
        })
      }, 2700);

      setTimeout(() => {
        this.Hull_Mechinaery.filter(f => f.layout == null).forEach(d => {
          d3.select(`#Blk_${d.BLK}`).transition().duration(300).style('opacity', 0.3)
        })

        // Display
        this.displayGroupID(`#Mask_Lev1`, 0, 0, 0)
        this.displayGroupID(`#Mask_Lev2`, 0, 0, 0)
        this.displayGroupID(`#Mask_Lev3`, 0, 0, 0)
        this.displayGroupID(`#Mask_Lev4`, 0, 0, 0)

        // ### Call Canvas ###
        this.drawProgress('Hull MS')

        // this.stage = this.STG_Level      //STG_Level:
        this.Lev1 = 'HULL'
        this.Lev2 = 'HULL-MS'
        this.stage = this.STG_Activate
        this.fncName = 'Activate_Hull_MS'
        this.StageCode() // AREA / STAGE
        this.displayGroupID(`#Menu_Back`, 0, 200, 1)
      }, 3700);

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_Lev1') // Lev1
      .attr('points', '0,41 0,57 122,124 203,94 194,64 83,0')
      .attr('fill', '#fff').attr('transform', `translate(410,598)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_MS_Level(s, '#Mask_Lev1', 'MS04') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_Lev2') // Lev2
      .attr('points', '14,37 0,67 96,122 152,139 196,128 229,74 96,0')
      .attr('fill', '#fff').attr('transform', `translate(565,513)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_MS_Level(s, '#Mask_Lev2', 'MS03') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_Lev3') // Lev3
      .attr('points', '1.25,77.88 2.63,38.81 8.44,27.53 116.47,0.02 213,55 213,71 102.5,134.75')
      .attr('fill', '#fff').attr('transform', `translate(728,449)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_MS_Level(s, '#Mask_Lev3', 'MS02') }) // <--------

      this.svgModeling
      .append('polygon')
      .attr('id', 'Mask_Lev4') // Lev4
      .attr('points', '0,50 46,101 81,114 151,114 205,104 231,77 168,19 108,0 12,23')
      .attr('fill', '#fff').attr('transform', `translate(911,355)`).style('opacity', 0).style('cursor', 'pointer').style('visibility', 'hidden')
      .call(s => { this.Goto_MS_Level(s, '#Mask_Lev4', 'MS01') }) // <--------
    },
  }
}
