import * as d3 from 'd3'

export default {
  data: () => ({
    
  }),

  methods: {

    draw_Summary() {
      let s = this.style
      let d = this.dataSet

      if(s.title.visible == 'Y') {
        
        let padding = s.title.padding

        let Summary = this.svg
        .append('g')
        .attr('id', `summary-${this.localId}`)
        .attr('transform', `translate(1, 1)`)

        Summary
        .append('text')
        .attr('id', `title-${this.localId}`)
        .attr('x', padding * 2).attr('y', padding + s.title.plusY)
        .attr('fill', s.title.fColor).attr('font-size', s.title.fSize)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .attr('font-family', 'roboto')
        .text(d.title)

        let txt = d3.select(`#title-${this.localId}`)
        let xWidth = txt.node().getBBox().width

        Summary
        .append('rect')
        .attr('x', 0).attr('y', 0)
        .attr('width', xWidth + (padding * 4))
        .attr('height', s.title.fSize + (padding * 2))
        .attr('fill', s.title.xColor)
        .attr('rx', s.title.xRadius).attr('ry', s.title.xRadius)

        d3.select(`#title-${this.localId}`).moveToFront()
        let xHeight = s.title.fSize + (padding*2)

        Summary
        .append('text')
        .attr('x', xWidth + (padding * 4) + 10)
        .attr('y', (xHeight / 2) + 1 + s.title.plusY)
        .attr('fill', s.prog.fColor).attr('font-size', s.prog.fSize)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .attr('font-family', 'roboto')
        .text(`${d.prog}${s.prog.unit}`)
        .style('visibility', s.prog.visible == 'Y' ? 'visible' : 'hidden')

        this.draw_Table(xHeight)
      } else {
        this.draw_Table(0)
      }
        
    },

  } 
}