import * as d3 from 'd3'

export default {
  data: () => ({
    
  }),

  methods: {

    draw_Summary() {
      let s = this.style
      let d = this.dataSet
      let y_ = s.padding

      let Table = this.svg
      .append('g')
      .attr('id', `table-${this.localId}`)
      .attr('transform', `translate(1, 1)`)

      for(let i=0; i < d.count; i++) {
        y_ += s.box.height/2
        Table
        .append('text')
        .attr('id', `text_${this.localId}_${i}`)
        .attr('x', 0).attr('y', y_ + s.plusY)
        .attr('fill', s.text.fColor).attr('font-size', s.text.fSize)
        .attr('font-weight', s.text.fWeight)
        .attr('text-anchor', s.text.align).attr('alignment-baseline', 'middle') // hanging
        .attr('font-family', 'roboto')
        .text(d.text[i])

        d.width[i] = d3.select(`#text_${this.localId}_${i}`).node().getBBox().width
        d.y[i] = y_
        if(i < (d.count-1)) y_ += s.box.padding + ((s.box.height - s.box.sSize) / 2)
      }

      let maxLen = Math.max(...d.width) + s.padding
      let box_y = 0

      for(let i=0; i < d.count; i++) {

        d3.select(`#text_${this.localId}_${i}`)
        .attr('x', ()=> {
          if (s.text.align == 'start') return 5
          if (s.text.align == 'middle') return maxLen/2
          if (s.text.align == 'end') return maxLen - 5
        })
        
        Table
        .append('text')
        .attr('id', `value_${this.localId}_${i}`)
        .style('cursor', 'pointer')
        .attr('x', maxLen)
        .attr('y', d.y[i] + s.plusY)
        .attr('fill', d.color[i]).attr('font-size', s.value.fSize)
        .attr('font-weight', s.value.fWeight)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle') // hanging
        .attr('font-family', 'roboto')
        .text(() => {
          if(d.unit[i] == '$') {
            return d.value[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          } else if(d.unit[i] == '%') {
            return `${d.value[i]}%`
          } else {
            return d.value[i]
          }
        })
      }
    },
  } 
}