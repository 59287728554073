import * as d3 from 'd3'
import { sort } from 'core-js/fn/array'

export default {
  data: () => ({
    innerSvg: null,
    eventGotoDivision_Stage1: null,
    eventGotoDivision_Stage2: null,
    eventGotoDivision_Stage3: null,
    backFunctions: [],

    onmoving: false,//stage3에서 mouseEvent생겨서 좌표값으로 안감
    curstage: 0,
    curid: []
  }),
  computed: {
    ready2draw() {
      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    envInit() {
      // this.Goto_Back_Stage0 은
      // this.backFunctions[0]랑 같은것
      // this.backFunctions[0](sele, id)
      this.backFunctions.push(this.Goto_Back_Stage0)//함수를 array에 담을수있다
      this.backFunctions.push(this.Goto_Back_Stage1)
      this.backFunctions.push(this.Goto_Back_Stage2)
    },
    loadSvg(svgURL, prefix) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${svgURL}`).then(source => {
        if(this.innerSvg) {
          this.innerSvg.html()
          this.innerSvg.remove() //svg를 지우고 
          this.innerSvg = null
        }
        this.innerSvg = source.documentElement //새로운 데이터 만든다 비동기 처리가 1번 밖에 리턴되지 않기 때문에 
        this.svg.node().append(this.innerSvg)
        this.innerSvg = d3.select(this.innerSvg)
        this.intiPosition(prefix)

        return new Promise(resolve => { //여기서 다시 리턴해준다 그러면  draw.mixin.js에서 받는다
          resolve(source)
        })
      })
    },
    intiPosition(prefix){ //처음 loadSvg될때부터 x,y값구하는 함수를 실행시켜주자
      this.positioned.filter(d => d.Type=='item' && d.ID.includes(prefix)).forEach(d => {
        let target = d3.select(`#${d.ID}`)
        if(!target) return

        let coordinate = this.parseTranslate(target)
        d.x = coordinate.x
        d.y = coordinate.y
      })
      // console.log(this.positioned)
    },
    moveItemTo(prefix) {
      this.positioned.filter(d => d.Type=='item' && d.ID.includes(prefix)).forEach(d => {
        let target = d3.select(`#${d.ID}`)
        if(!target) return
        
        if(d.x1) {
          target.transition()
          .attr('transform', `translate(${d.x1}, ${d.y1})`)
          .style('opacity', d.opacity)
          
        } else {
          target.transition().style('opacity', d.opacity)
        }
      })
    },
    moveGroupTo(prefix, strict) {//기존 prefix ='S1'이런식으로 써줬는데 그렇게 적는것은 값이 안들어오면 defult값으로 하겠다는 의미 여기서는 할필요없음
      let time__ = 500
      //조건을 3가지로 나눔
      if(strict=='self') var source = [ this.positioned.find(d => d.ID == prefix) ]//find는 object라서 foreach안됨 []로 감싸준다!
      else if(strict=='nonself') source = this.positioned.filter(d => d.Type=='group' && d.ID != prefix && d.ID.includes(prefix))
      else source = this.positioned.filter(d => d.Type=='group' && d.ID.includes(prefix))
      // console.log(prefix,strict )
      source.forEach(d => {
        d3.selectAll(`[id*="${d.ID}"]`).each((_, i, a) => {
          // console.log(d.ID)
          // Update coordinate
          let coordinate = this.parseTranslate(d3.select(a[i]))
          d3.select(a[i]).transition().duration(time__).attr('transform', `translate(${coordinate.x + d.distx}, ${coordinate.y + d.disty})`)
        })
      })

      return new Promise(resolve => {
        setTimeout(() => { resolve(true) }, time__ + 50)
      })
    },
    moveGroupBack(prefix, strict){
      if(strict=='self'){ 
        var source = [ this.positioned.find(d => d.Type=='group' && d.ID == prefix) ]
        // console.log('self',source)
        source.forEach(d => {
          d3.selectAll(`[id*="${d.ID}"]`).each((_, i, a) => {
            let coordinate = this.parseTranslate(d3.select(a[i]))
            d3.select(a[i]).transition().duration(500).attr('transform', `translate(${(coordinate.x + (d.distx*-1))}, ${(coordinate.y + (d.disty*-1))})`)
          })
        })
      }
      else if(strict=='nonself'){
         source = this.positioned.filter(d => d.Type=='group' && d.ID != prefix && d.ID.includes(prefix))
        //  console.log('nonself',source)
         source.forEach(d => {
          d3.selectAll(`[id*="${d.ID}"]`).each((_, i, a) => {
            let coordinate = this.parseTranslate(d3.select(a[i]))
            d3.select(a[i]).transition().duration(500).attr('transform', `translate(${(coordinate.x + (d.distx*-1))}, ${(coordinate.y + (d.disty*-1))})`)
          })
        })
      }
      else{ 
        source = this.positioned.filter(d => d.Type=='group' && d.ID.includes(prefix))
        // console.log(source)
        source.forEach(d => {
          // console.log(d.ID)
          d3.selectAll(`[id*="${d.ID}"]`).each((_, i, a) => {
            let coordinate = this.parseTranslate(d3.select(a[i]))
            d3.select(a[i]).transition().duration(500).attr('transform', `translate(${(coordinate.x + (d.distx*-1))-20}, ${(coordinate.y + (d.disty*-1))-130})`)
          })
        })
      }
      return new Promise(resolve => {
        setTimeout(() => { resolve(true) }, 550)
      })
    },
    moveItemBack(prefix) {
      this.positioned.filter(d => d.Type=='item' && d.ID.includes(prefix)).forEach(d => {
        let target = d3.select(`#${d.ID}`)
        target.transition().duration(300)
        .attr('transform', `translate(${d.distx}, ${d.disty})`)
        .style('opacity', 1)
      })
      return new Promise(resolve => {
        setTimeout(() => { resolve(true) }, 650)
      })
    },
    eventBack(selection) {
      selection
      .on('mouseover', (_, a, i) => {
        d3.select(a[i]).transition().duration(100).style('opacity', 0.5)
      })
      .on('mouseout', (_, a, i) => {
        d3.select(a[i]).transition().duration(100).style('opacity', 1)
      })
      .on('click', (_, a, i) => {
        this.drawGotoBack()
      })
    },
    eventBackHome(selection) {
      selection
      .on('mouseover', (_, a, i) => {
        d3.select(a[i]).transition().duration(100).style('opacity', 0.5)
      })
      .on('mouseout', (_, a, i) => {
        d3.select(a[i]).transition().duration(100).style('opacity', 1)
      })
      .on('click', (_, a, i) => {
        this.drawGotoHome()
      })
    }
  }
}