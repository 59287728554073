import * as d3 from 'd3'
import __M from 'moment'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {
    Draw_Content() {
      this.drawWeekSummary()    //----------SQL1
      this.drawDropdownChart()  //----------SQL2
      this.drawPyramidChart()   //----------SQL3
      this.drawTable()          //----------SQL4,5
    },
    drawWeekSummary(){
      //-----------------------------------------------------------------Week Summary SQL1
      let Summary = this.svg
      .append('g')
      .attr('id', 'week-summary')
      .attr('transform', `translate(30, 150)`)
      .style('font-family','roboto')

      Summary
      .append('text')
      .attr('x', 0).attr('y', -30)
      .style('font-size',15).attr('text-anchor', 'start').attr('fill', '#000')
      .text('Key Performance Indicators')
    
      Summary
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('rx', 3.5).attr('ry', 3.5).attr('width', 44).attr('height', 20)  
      .attr('fill', '#fff').attr('stroke','#9E9E9E').attr('stroke-width', .3)
      Summary
      .append('text')
      .attr('x', 22).attr('y', 16)
      .style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#3C3C3C')
      .text('Week')

      let SummaryRect = Summary
      .append('g')
      .attr('id', 'week-summary-rect')
      .attr('transform', `translate(0, 28)`)

      for(let i=0; i<3; i++){
        SummaryRect
        .append('rect')
        .attr('x', 0).attr('y', 42*i).attr('rx', 3).attr('ry', 3).attr('width', 170).attr('height', 38)  
        .attr('fill', '#E0E0DF')

        SummaryRect
        .append('rect')
        .attr('x', 175).attr('y', 42*i).attr('rx', 3).attr('ry', 3).attr('width', 170).attr('height', 38)  
        .attr('fill', '#A8E0F8')
      }
      let SummaryText = Summary
      .append('g')
      .attr('id', 'week-summary-text')
      .attr('transform', `translate(0, 48)`)

      let sql1 = this.Queries.SQL1
      sql1.forEach((e, i) => {
        SummaryText
        .append('text')
        .attr('x', 8).attr('y', (43*i))
        .style('font-size',16).attr('text-anchor', 'start').attr('fill', '#3C3C3C')
        .text(e.DESCR)

        SummaryText
        .append('text')
        .attr('x', 258).attr('y', (43*i)+4)
        .style('font-size',20).attr('text-anchor', 'middle').attr('fill', '#fff')
        .text(e.CUM)
      })
      SummaryText
      .append('text')
      .attr('x', 8).attr('y', 11)
      .style('font-size',10).attr('text-anchor', 'start').attr('fill', '#9E9E9E')
      .text('Loss Time Incident Frequency')
      SummaryText
      .append('text')
      .attr('x', 8).attr('y', 54)
      .style('font-size',10).attr('text-anchor', 'start').attr('fill', '#9E9E9E')
      .text('Total Recordable Case Frequency')

      //----------------------------------------------------------------------------------Week Summary SQL1
    },
    drawDropdownChart(){
      //----------------------------------------------------------------------------------Dropdown select Chart SQL2
      let selectChart = this.svg
      .append('g')
      .attr('id', 'week-select-chart-wrap')
      .attr('transform', `translate(1080, 640)`)
      .style('font-family','roboto')

      selectChart
      .append('text')
      .attr('x', 0).attr('y', -20)
      .style('font-size',15).attr('text-anchor', 'start').attr('fill', '#000')
      .text('ALT by Area')

      selectChart
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 3.5).attr('ry', 3.5).attr('width', 180).attr('height', 20)  
      .attr('fill', '#fff').attr('stroke','#9E9E9E').attr('stroke-width', .3)
      selectChart
      .append('text')
      .attr('x', 8).attr('y', 14)
      .style('font-size',11).attr('text-anchor', 'start').attr('fill', '#3C3C3C')
      .text('No. of 111-obs for last two weeks')
      //--------------------------legend
      selectChart
      .append('rect')
      .attr('x', 90).attr('y', 220).attr('width', 10).attr('height', 10)  
      .attr('fill', '#E0E0DF')
      selectChart
      .append('text')
      .attr('x', 105).attr('y', 228)
      .style('font-size',9).attr('text-anchor', 'start').attr('fill', '#757575')
      .text('Previous WeeK')

      selectChart
      .append('rect')
      .attr('x', 200).attr('y', 220).attr('width', 10).attr('height', 10)  
      .attr('fill', '#B4E3FA')
      selectChart
      .append('text')
      .attr('x', 215).attr('y', 228)
      .style('font-size',9).attr('text-anchor', 'start').attr('fill', '#757575')
      .text('This WeeK')
      //--------------------------- Axis X
      let selcetChartX = selectChart 
      .append('g')
      .attr('id', 'week-select-chart-axisX')
      .attr('transform', `translate(150, 60)`)

      selcetChartX
      .selectAll('line')
      .data(this.tickGridX).enter()
      .append('line')
      .attr('x1', d => this.scaleGridX(d)).attr('x2', d => this.scaleGridX(d))
      .attr('y1', 0).attr('y2', this.axisYHeight)
      .attr('stroke-width', .4).attr('stroke', (_, i) => i === 0 ?'#000' : '#bcbcbc')

      selcetChartX
      .selectAll('text')
      .data(this.tickGridX).enter()
      .append('text')
      .attr('transform', d => `translate(${this.scaleGridX(d)}, -10)`)
      .attr('font-size', 9)
      .attr('text-anchor','middle').attr('alignment-baseline','middle')
      .attr('fill','#bcbcbc')
      .text(d => d)

      //--------------------------- selectChart
      let wkChartwrap = selectChart
      .append('g')
      .attr('class', '_week_select_chart_warpper')
      .attr('transform', `translate(150, 60)`)

      wkChartwrap
      .append('g')
      .attr('class', '_week_select_chart_text')
      .attr('transform', `translate(-10, 18)`)
      .selectAll('g')
      .data(this.codeDescr)
      .enter()
      .append('g')
      .attr('class', '_bar_text')
      .attr('transform', d => `translate(0, ${this.scaleYParent(d.DESCR)})`) 
      .append('text')
      .style('font-size',11).attr('text-anchor', 'end').attr('fill', '#3C3C3C')
      .text(d => d.DESCR)

      wkChartwrap
      .append('g')
      .attr('id', 'week_select_chart_area') //selcet할때 마다 그려지는 chart그리기

      this.drawChartByDescr()
      this.dropDown()
    //-------------------------------------------------------------------------------------Dropdown select Chart SQL2
    },
    drawChartByDescr(code='Steel Cutting') {
      let chartArea__ = d3.select('#week_select_chart_area')
      if(chartArea__.html()) chartArea__.html('')

      let data__ = this.Queries.SQL2.filter(d => d.CODE == code)
      let find__ = (descr, colname) => data__.find(d => d.DESCR == descr)[colname]

      let wkChart = chartArea__
      .selectAll('g')
      .data(this.codeDescr)
      .enter()
      .append('g')
      .attr('class', '_bar_parent')
      .attr('transform', d => `translate(0, ${this.scaleYParent(d.DESCR)})`) 
      .selectAll('path')
      .data(d => this.colnames.map((colname, i) => ({ y: i==0?'W1':'W2', x: find__(d.DESCR, colname) }))) 
      .enter()
      .append('path')
      .attr('d', d => `
        M0, ${this.scaleYChild(d.y)}
        L0, ${this.scaleYChild(d.y)+this.scaleYChild.bandwidth()}
        L0, ${this.scaleYChild(d.y)+this.scaleYChild.bandwidth()/2} Z
      `)
      .attr('fill', (_, i) => i==0?'#E0E0DF':'#B4E3FA')
      .attr('stroke', (_, i) => i==0?'#BDBCBC':'#44A9DF')
      .attr('stroke-width', 0.3)
      .transition().duration(300)
      .attr('d', d => `
        M${0}, ${this.scaleYChild(d.y)}
        L${0}, ${this.scaleYChild(d.y)+this.scaleYChild.bandwidth()}
        L${this.scaleX(d.x)}, ${this.scaleYChild(d.y)+this.scaleYChild.bandwidth()/2} Z
      `)

      let wkChartValue = chartArea__
      .append('g')
      .attr('id', 'week_select_chart_value')
      .selectAll('g')
      .data(this.codeDescr)
      .enter()
      .append('g')
      .attr('class', '_bar_value')
      .attr('transform', d => `translate(0, ${this.scaleYParent(d.DESCR)})`) 
      .selectAll('path')
      .data(d => this.colnames.map((colname, i) => ({ y: i==0?'W1':'W2', x: find__(d.DESCR, colname) }))) 
      .enter()
      .append('text')
      .attr('x', 0)
      .attr('y', d => `${this.scaleYChild(d.y)+this.scaleYChild.bandwidth()-2}`)
      .attr('font-size', 10)
      .attr('text-anchor','start')
      .attr('fill', (_, i) => i==0?'#BDBCBC':'#44A9DF')
      .text(d => d.x)
      .transition().duration(300)
      .attr('x', d => `${this.scaleX(d.x)+5}`)
      .attr('y', d => `${this.scaleYChild(d.y)+this.scaleYChild.bandwidth()-2}`)
    },
    dropDown(){
      let ddData = this.Queries.SQL7.map(d => ({ text: d.CODE, value: d.CODE }))
      let ddWrapper = this.svg
      .append('g')
      .attr('transform', `translate(1290,620)`)
      .attr('id', 'dropdown_wrapper')

      this.drawDropdown(ddWrapper, ddData, (value) => {
        this.drawChartByDescr(value) //value이 CODE니까 VALUE값만 넘겨주면 된다!
      })
    },
    drawPyramidChart(){
    //-------------------------------------------------------------------------------------pyramid Chart SQL3,4
      let pyramidChartWrap = this.svg
      .append('g')
      .attr('id', 'pyramid-chart-wrap')
      .attr('transform', `translate(400, 100)`)
      .style('font-family','roboto')

      let defs = pyramidChartWrap.append('defs')
      let dropShadowFilter = defs.append('svg:filter')
      .attr('id', 'drop-shadow')
      .attr('filterUnits', "userSpaceOnUse")
      .attr('width', '200%').attr('height', '200%')
      dropShadowFilter.append('svg:feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 5)
      .attr('result', 'blur-out')
      dropShadowFilter.append('svg:feOffset')
      .attr('in', 'color-out')
      .attr('dx', 2).attr('dy', 2)
      .attr('result', 'the-shadow')
      dropShadowFilter.append('svg:feBlend')
      .attr('in', 'SourceGraphic')
      .attr('in2', 'the-shadow')
      .attr('mode', 'normal')
      //---------------------------------------------pyramid side
      let pyramidSideChart = pyramidChartWrap
      .append('g')
      .attr('id', 'pyramid-side-blue')
      .attr('transform', `translate(0, 51)`)

      let width__ = this.TrapezoidX - 130 //side의 width

      for(let i=1; i<7; i++){
        pyramidSideChart
        .append('path')
        .attr('transform', `translate(-300, ${this.TrapezoidY*i})`)
        .attr('d', `M${this.TrapezoidC},0 L${width__-this.TrapezoidC},0 L${width__},${this.TrapezoidY} L0,${this.TrapezoidY}Z`)
        .attr('fill','#3FC1D9').style("filter", "url(#drop-shadow)")
        .style('opacity',0)
        .transition().duration(500).delay(i*50)
        .attr('transform', `translate(${i*this.TrapezoidC}, ${i*this.TrapezoidY})`).style('opacity',`${i*0.1}`)
      }

      let pyramidSideChart2 = pyramidChartWrap
      .append('g')
      .attr('id', 'pyramid-side-pink')
      .attr('transform', `translate(628, 52)`)
      //---------------------------i = 1, o = 8

      let o = 8
      for(let i=1; i<7; i++){
        pyramidSideChart2
        .append('path')
        .attr('transform', `translate(300, ${this.TrapezoidY*i})`)
        .attr('d', `M0,0 L${width__},0 L${width__-this.TrapezoidC},${this.TrapezoidY} L${this.TrapezoidC},${this.TrapezoidY}Z`)
        .attr('fill','#CA519A').style('opacity',`0`).style("filter", "url(#drop-shadow)")

        .transition().duration(500).delay(o*50)
        .attr('transform', `translate(${i*this.TrapezoidC}, ${i*this.TrapezoidY})`).style('opacity',`${o*0.1}`)
        o --
      }
      //---------------------------------------------pyramid side
      //---------------------------------------------pyramid chart
      let pyramidChart = pyramidChartWrap
      .append('g')
      .attr('id', 'pyramid-blue')
      .attr('transform', `translate(229, 52)`)

      let j = 0
      for(let i=0; i<8; i++){
        if(![0, 7].includes(i)) { //0,7 번 아닌것을 그려라
          pyramidChart
          .append('path')
          .attr('transform', `translate(${this.TrapezoidC*i}, ${this.TrapezoidY*i})`)
          .attr('d', `
            M0,0 
            L${this.TrapezoidX-(this.TrapezoidC*j)},0 
            L${this.TrapezoidX-(this.TrapezoidC*(j+1))},${this.TrapezoidY} 
            L${this.TrapezoidC},${this.TrapezoidY}
            Z
          `)
          .style('opacity', 0).style("filter", "url(#drop-shadow)")
          .attr('fill','#3FC1D9')
          .transition().duration(750)
          .style('opacity',`${i*0.1+.1}`)
        }
        j += 2
      }

      [[0, 0], [7, 14]].forEach(i => { //0,7 번 인것은 색깔 다르게 주기 위해
        pyramidChart
        .append('path')
        .attr('transform', `translate(${this.TrapezoidC*i[0]}, ${this.TrapezoidY*i[0]})`)
        .attr('d', `
          M0,0 
          L${this.TrapezoidX-(this.TrapezoidC*i[1])},0 
          L${this.TrapezoidX-(this.TrapezoidC*(i[1]+1))},${this.TrapezoidY} 
          L${this.TrapezoidC},${this.TrapezoidY}
          Z
        `)
        .style('opacity', 0).style("filter", "url(#drop-shadow)")
        .attr('fill', '#E0E0E0')
        .transition().duration(750)
        .style('opacity',.5)
      })

      let pyramidChart2 = pyramidChartWrap
      .append('g')
      .attr('id', 'pyramid-pink')
      .attr('transform', `translate(630, 4)`)

      let k = 0
      let op = 1
      for(let i=8; i>0; i--){
        if(![1, 8].includes(i)) {
        pyramidChart2
        .append('path')
        .attr('transform', `translate(${this.TrapezoidC*(i*-1)}, ${this.TrapezoidY*i})`)
        .attr('d', `
          M${this.TrapezoidC},0 
          L${this.TrapezoidX-(this.TrapezoidC*(k+1))},0 
          L${this.TrapezoidX-(this.TrapezoidC*k)},${this.TrapezoidY} 
          L0,${this.TrapezoidY}
          Z
          `)
        .style('opacity',0).style("filter", "url(#drop-shadow)")
        .attr('fill','#CA519A')
        .transition().duration(750)
        .style('opacity',`${op*0.1+.1}`)
        } 
        k += 2
        op ++
      }
      //--------------------------pyramid chart 1,8번 hard cording
      pyramidChart2
      .append('path')
      .attr('transform', `translate(${this.TrapezoidC*(-1)}, ${this.TrapezoidY})`)
      .attr('d', `
        M${this.TrapezoidC},0 L${(this.TrapezoidC-1)},0 L${this.TrapezoidY-1},${this.TrapezoidY} L0,${this.TrapezoidY}Z`)
      .style('opacity',0).style("filter", "url(#drop-shadow)")
      .attr('fill','#E0E0E0')
      .transition().duration(750)
      .style('opacity',.5)

      pyramidChart2
      .append('path')
      .attr('transform', `translate(${this.TrapezoidC*(-1*8)}, ${this.TrapezoidX+1})`)
      .attr('d', `
        M${this.TrapezoidC},0 L${this.TrapezoidX-(this.TrapezoidC)},0 L${this.TrapezoidX},${this.TrapezoidY}L0,${this.TrapezoidY} Z`)
      .style('opacity',0).style("filter", "url(#drop-shadow)")
      .attr('fill','#E0E0E0')
      .transition().duration(750)
      .style('opacity',.5)
      //----------------------------pyramid chart 1,8번 hard cording
      //---------------------------------------------pyramid chart
      //---------------------------------------------pyramid value, text
      let pyramidText = pyramidChartWrap
      .append('g')
      .attr('id', 'pyramid-text')
      .attr('transform', `translate(409, 116)`)

      pyramidText
      .append('text')
      .attr('x',10).attr('y', -43)
      .style('font-size',12).attr('text-anchor', 'middle').attr('fill', '#000')
      .text('Leading Indicators')
      pyramidText
      .append('text')
      .attr('x',10).attr('y', -30)
      .style('font-size',9).attr('text-anchor', 'middle').attr('fill', '#9E9E9E')
      .text('Weekly | Cumulate')
      pyramidText
      .append('text')
      .attr('x',223).attr('y', 305)
      .style('font-size',12).attr('text-anchor', 'middle').attr('fill', '#000')
      .text('Lagging Indicators')
      pyramidText
      .append('text')
      .attr('x',222).attr('y', 290)
      .style('font-size',9).attr('text-anchor', 'middle').attr('fill', '#9E9E9E')
      .text('Weekly | Cumulate')

      let sql3 = this.Queries.SQL3
      let seq3 = [...new Set(sql3.map(d=> d.SEQ))]//해당seq만큼 배열로 만들고//console.log(seq3)
      seq3.forEach((e, i) => {
        let filter3 = sql3.filter(d => d.SEQ === e) //sql3과 seq값 같은것을 찾아온다 //console.log(filter3)

        filter3.forEach((f, fi) => {
          pyramidText
          .append('text')
          .attr('x', 0)
          .attr('y', `${filter3.length == 3 ? (i*this.TrapezoidY)+fi*11 : filter3.length == 1 ?(i*this.TrapezoidY)+12 :(i*this.TrapezoidY)+fi*15+4}`)
          .style('font-size',10).attr('text-anchor', 'end').attr('fill', '#000')
          .text(f.WEEKLY)

          pyramidText
          .append('text')
          .attr('x',10)
          .attr('y', `${filter3.length == 3 ? (i*this.TrapezoidY)+fi*11 : filter3.length == 1 ?(i*this.TrapezoidY)+12 :(i*this.TrapezoidY)+fi*15+4}`)
          .style('font-size',10).attr('text-anchor', 'start').attr('fill', '#000')
          .text(f.CUM)
          pyramidText
          .append('text')
          .attr('x',4)
          .attr('y', `${filter3.length == 3 ? (i*this.TrapezoidY)+fi*11 : filter3.length == 1 ?(i*this.TrapezoidY)+12 :(i*this.TrapezoidY)+fi*15+4}`)
          .style('font-size',10).attr('text-anchor', 'start').attr('fill', '#757575')
          .text('|')

          pyramidText
          .append('text')
          .attr('x', -160 + i*20+fi*5)
          .attr('y', `${filter3.length == 3 ? (i*this.TrapezoidY)+fi*11 : filter3.length == 1 ?(i*this.TrapezoidY)+10 :(i*this.TrapezoidY)+fi*15+4}`)
          .style('font-size',10).attr('text-anchor', 'end').attr('fill', '#000')
          .text(f.DESCR)
        })
      })

      let sql4 = this.Queries.SQL4
      let seq4 = [...new Set(sql4.map(d=> d.SEQ))]//console.log(seq4)
      seq4.forEach((e, i) => { 
        let filter4 = sql4.filter(d => d.SEQ === e)//console.log(filter4)//console.log(filter4.length)를 찾아서 1,2,3 위치 다르게 만들자!
        filter4.forEach((f, fi)=>{
          pyramidText
          .append('text')
          .attr('x', 212)
          .attr('y', `${filter4.length == 3 ? (i*this.TrapezoidY)+fi*11 : filter4.length == 1 ?(i*this.TrapezoidY)+12 :(i*this.TrapezoidY)+fi*15+5}`)
          .style('font-size',10).attr('text-anchor', 'end').attr('fill', '#000')
          .text(f.WEEKLY)
  
          pyramidText
          .append('text')
          .attr('x',222)
          .attr('y', `${filter4.length == 3 ? (i*this.TrapezoidY)+fi*11 : filter4.length == 1 ?(i*this.TrapezoidY)+12 :(i*this.TrapezoidY)+fi*15+5}`)
          .style('font-size',10).attr('text-anchor', 'start').attr('fill', '#000')
          .text(f.CUM)
          pyramidText
          .append('text')
          .attr('x',216)
          .attr('y', `${filter4.length == 3 ? (i*this.TrapezoidY)+fi*11 : filter4.length == 1 ?(i*this.TrapezoidY)+12 :(i*this.TrapezoidY)+fi*15+5}`)
          .style('font-size',10).attr('text-anchor', 'start').attr('fill', '#757575')
          .text('|')
  
          pyramidText
          .append('text')
          .attr('x', 282 + (i*20)+fi*10)
          // .attr('y', `${(i*this.TrapezoidY)+fi*11}`)
          .attr('y', `${filter4.length == 3 ? (i*this.TrapezoidY)+fi*11 : filter4.length == 1 ?(i*this.TrapezoidY)+10 :(i*this.TrapezoidY)+fi*15+5}`)
          .style('font-size',10).attr('text-anchor', 'start').attr('fill', '#000')
          .text(f.DESCR)
        })
      })
      //---------------------------------------------pyramid value, text
      //-------------------------------------------------------------------------------------pyramid Chart SQL3,4
    },
    drawTable(){
      let tableWrap = this.svg
      .append('g')
      .attr('class', '_year_week_table_wrap')
      .attr('transform', `translate(60, 620)`)
      .style('font-family','roboto')

      tableWrap
      .append('line')
      .attr('x1',125).attr('y1',-35.5)
      .attr('x2',1250).attr('y2',-35.5)
      .attr('stroke','#BDBDBD').attr('stroke-width', .3)

      tableWrap
      .append('text')
      .attr('transform', `translate(-20, 0)`)
      .style('font-size',15).attr('text-anchor', 'start').attr('fill', '#000')
      // .text('Incident Summary')
      .text('LSR Violation')
      //-----------------------------------------------------table week summary
      let tableWeek = tableWrap
      .append('g')
      .attr('class', '_week_table')
      .attr('transform', `translate(0, 40)`)

      tableWeek
      .append('text')
      .attr('transform', `translate(90, -10)`)
      .style('font-size',9).attr('text-anchor', 'middle').attr('fill', '#9E9E9E')
      .text('Weekly | Cumulate')
      tableWeek
      .append('text')
      .attr('transform', `translate(15, -10)`)
      .style('font-size',9).attr('text-anchor', 'middle').attr('fill', '#9E9E9E')
      .text('LSR')

      let sql5 = this.Queries.SQL5
      let imagesData = [...new Set(sql5.map(d=> d.DESCR))]
      imagesData.forEach((e,ie) =>{
        let filterImages = sql5.filter(d => d.DESCR == e)
        filterImages.forEach((d, i) => {
          tableWeek
          .append('image')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/HSSE/dashboard/${d.DESCR}.png`)
          .attr('opacity', .7)
          .attr('transform', `translate(0, ${35*ie})`)

          tableWeek
          .append('text')
          .attr('x', 78).attr('y', `${ie*35+20}`)
          .style('font-size',12).attr('text-anchor', 'end').attr('fill', '#D81B60')
          .text(d.WEEKLY == null ? 0 :d.WEEKLY)

          tableWeek
          .append('text')
          .attr('x',90).attr('y', `${ie*35+20}`)
          .style('font-size',12).attr('text-anchor', 'start').attr('fill', '#424242')
          .text(d.CUM)

          tableWeek
          .append('text')
          .attr('x',83).attr('y', `${ie*35+20}`)
          .style('font-size',11).attr('text-anchor', 'start').attr('fill', '#9E9E9E')
          .text('|')
        })
      })

      //-----------------------------------------------------table year summary
      let tableYear = tableWrap
      .append('g')
      .attr('class', '_year_table')
      .attr('transform', `translate(190, 28)`)

      tableYear
      .append('line')
      .attr('x1',-12).attr('y1',5.5)
      .attr('x2',770).attr('y2',5.5)
      .attr('stroke','#BDBDBD').attr('stroke-width', .3)

      this.Queries.SQL8.forEach((m, ie) => { //원래 정해진 month를 썼는데 query로 가져오는것으로 바꿈
        tableYear
        .append('text')
        .attr('transform', `translate(${ie*66 + 3}, -4)`)
        .style('font-size',12).attr('text-anchor', 'start').attr('fill', '#9E9E9E')
        .text(m.MONTH.toUpperCase())

        let sql6Filter = this.Queries.SQL6.filter(d => d.MONTH == m.MONTH) //12까지의 달중에 데이터있는 달만 가져와라
        // console.log(sql6Filter)
        if(sql6Filter.length === 0) return

        sql6Filter.forEach((d, i) => {
          tableYear
          .append('image')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/HSSE/dashboard/${d.DESCR}.png`)
          .attr('opacity', .7)
          .attr('transform', `translate(${ie*66}, 0)`)
          .transition().duration(300)
          .attr('transform', `translate(${ie*66}, ${i*40 + 18})`)

          if(d.COUNT != 1 ){
            tableYear 
            .append('circle')
            .attr('cx', `${ie*66+26}`).attr('cy', `0`).attr('r', 8)
            .attr('stroke','#D81B60').attr('stroke-width', .5)
            .attr('fill', '#fff')
            .transition().duration(300)
            .attr('cx', `${ie*66+26}`).attr('cy', `${i*40 + 18}`)
          }
          
          tableYear
          .append('text')
          .attr('transform', `translate(${ie*66+26}, 0)`)
          .style('font-size',11).attr('text-anchor', 'middle').attr('fill', '#D81B60')
          .text(d.COUNT == 1 ? '': d.COUNT)
          .transition().duration(300)
          .attr('transform', `translate(${ie*66+26}, ${i*40 + 22})`)
        })
      })
    }
  }
}