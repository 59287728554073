import * as d3 from 'd3'
import { entries } from 'd3'

export default {
  data: () => ({
    scaleY: null,
  }),

  methods: {

    draw_SubSystemList() {

      let Systemmenu = this.SVG_SystemBoundary
      .append('g')
      .attr('transform', `translate(50, 340.5)`)


      Systemmenu
      .append('text')
      .attr('transform', `translate(0, 7)`)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Sub-System')

      // ITR A
      let Aitr = Systemmenu
      .append('g')
      .attr('id', `AITR`)
      .attr('transform', `translate(73, 0)`)
      .style('cursor', 'pointer')
      .attr('opacity', () => {
        if(this.mode_Menu =='A') return 1; else return 0.5
      })
      .on('mouseover', function() {
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(71, -1.5) scale(1.2)`)
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(73, 0) scale(1)`)
      })
      .on('click', () => { 
        this.mode_Menu ='A'
        this.transition_BarChart()
        d3.select('#AITR').attr('opacity', 1)
        d3.select('#BITR').attr('opacity', 0.5)
      })

      Aitr
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('rx', 2).attr('ry', 2).attr('width', 35).attr('height', 13).attr('fill', '#83D2F5')

      Aitr
      .append('text')
      .attr('transform', `translate(7, 7)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('A ITR')

      // ITR B
      let Bitr = Systemmenu
      .append('g')
      .attr('id', `BITR`)
      .attr('transform', `translate(114, 0)`)
      .style('cursor', 'pointer')
      .attr('opacity', () => {
        if(this.mode_Menu =='B') return 1; else return 0.5
      })
      .on('mouseover', function() {
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(112, -1.5) scale(1.2)`)
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(114, 0) scale(1)`)
      })
      .on('click', () => { 
        this.mode_Menu ='B'
        this.transition_BarChart()
        d3.select('#AITR').attr('opacity', 0.5)
        d3.select('#BITR').attr('opacity', 1)
      })

      Bitr
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('rx', 2).attr('ry', 2).attr('width', 35).attr('height', 13).attr('fill', '#83D2F5')

      Bitr
      .append('text')
      .attr('transform', `translate(7, 7)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('B ITR')




      let subSystemList = this.SVG_SystemBoundary
      .append('g')
      .attr('transform', `translate(50, 360.5)`)

      // default = {
      //   w: 300,                      // mask width
      //   h: 300,                      // mask height
      //   g: 20,                       // mask gap bottom
      //   bar : {                      // optional, if not defined, default values will be assigned
      //     align       : 'right',     // 'left' | 'right'
      //     gap         : 1,
      //     radius      : 1,
      //     width       : 4,
      //     stroke      : '#03a9f4',
      //     strokeWidth : .5,
      //     fill        : '#29b6f6',
      //     opacity     : .5,

      let props = {
        w: 157,
        h: 200,
        g: 0,
        bar: {
          align: 'left',
          gap  : 3,
          fill: '#757575',
          stroke: '#757575',
        }
      }
      this.setScroll(subSystemList, props)
    


      // Progress Chart
      let drawY = 10
      this.Queries.SQL3.filter(f => f.SYSTEM == this.System).forEach((d,i) => {

        let systemBtn = subSystemList
        .append('g')
        .attr('id', `SSYS_${d.SUBSYSTEM}`)
        .attr('transform', `translate(0, ${drawY + (i*20)})`)
        .style('cursor', 'pointer')
        .on('mouseover', function() {
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(-5, ${drawY + (i*20)-1.5}) scale(1.2)`)
        })
        .on('mouseout', function(){
          d3.select(this).transition().duration(100)
          .attr('transform', `translate(0, ${drawY + (i*20)}) scale(1)`)
        })
        .on('click', () => { 
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: d.SUBSYSTEM
            },
            iFilters: {
              filterString : `[STAGE] = '${this.mode_Menu}' AND [STATUS] = 'Y'`,
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })

          systemBtn
          .append('rect')
          .attr('transform', `translate(0, ${-8.5})`)
          .attr('rx', 2).attr('ry', 2).attr('width', 43).attr('height', 15).attr('fill', `url(#${this.localId}__LinearA4${'LightGray'}0)`).attr('opacity', 0.7)

          systemBtn
          .append('text')
          .attr('transform', `translate(5, 0)`)
          .style('font-family', 'roboto').style('font-size', 9).style('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(d.SUBSYSTEM)

          subSystemList
          .append('rect') // Background
          .attr('id', `bgBar_${d.SUBSYSTEM}`)
          .attr('transform', `translate(50, ${drawY + (i*20)-7})`).attr('width', 100).attr('height', 13).attr('fill', '#F4F5F5')
          .style('opacity', () => { if(d.B_T !== null) return 1; else return 0 })

          subSystemList
          .append('rect') // Actual
          .attr('id', `Bar_${d.SUBSYSTEM}`)
          .attr('transform', `translate(50, ${drawY + (i*20)-7})`).attr('width', 0).attr('height', 13).attr('fill', '#B4E3FA')
          .transition().duration(500)
          .attr('width', () => { if(d.B_T !== null) return d.B_PG; else return 0 })

          subSystemList
          .append('text')
          .attr('id', `Prog_${d.SUBSYSTEM}`)
          .attr('transform', `translate(50, ${drawY + (i*20) + 0.5})`)
          .style('font-family', 'roboto').style('font-size', 9).attr('alignment-baseline', 'middle').style('fill', '#757575')
          .attr('text-anchor', 'end')
          .text(() => { if(d.B_T !== null) return `${Number(d.B_PG).toFixed(0)}%`; else return 'N/A' })
          .transition().duration(500)
          .attr('transform', () => {
            if(d.B_T !== null) {
              if(d.B_PG < 25) return `translate(${51 + d.B_PG}, ${drawY + (i*20) + 0.5})`; else return `translate(${49 + d.B_PG}, ${drawY + (i*20) + 0.5})`
            } else {
              return `translate(100, ${drawY + (i*20) + 0.5})`
            }
          })
          .attr('text-anchor', () => {
            if(d.B_T !== null) {
              if(d.B_PG < 25) return 'start'; else return 'end'
            } else {
              return 'middle'
            }
          })
        
      })
      
    },

    transition_BarChart() {
      // this.mode_Menu
      this.Queries.SQL3.filter(f => f.SYSTEM == this.System).forEach((d,i) => {

        let drawY = 10

        if (this.mode_Menu == 'B') {

          d3.select(`#bgBar_${d.SUBSYSTEM}`)
          .style('opacity', () => {
            if(d.B_T !== null) return 1; else return 0
          })
          
          d3.select(`#Bar_${d.SUBSYSTEM}`)
          .transition().duration(500)
          .attr('width', () => {
            if(d.B_T !== null) return d.B_PG; else return 0
          })

          d3.select(`#Prog_${d.SUBSYSTEM}`)
          .transition().duration(500)
          .attr('transform', () => {
            if(d.B_T !== null) {
              if(d.B_PG < 25) return `translate(${51 + d.B_PG}, ${drawY + (i*20) + 0.5})`; else return `translate(${49 + d.B_PG}, ${drawY + (i*20) + 0.5})`
            } else {
              return `translate(100, ${drawY + (i*20) + 0.5})`
            }
          })
          .attr('text-anchor', () => {
            if(d.B_T !== null) {
              if(d.B_PG < 25) return 'start'; else return 'end'
            } else {
              return 'middle'
            }
          })
          .text(() => { if(d.B_T !== null) return `${Number(d.B_PG).toFixed(0)}%`; else return 'N/A' } )
        }

        if (this.mode_Menu == 'A') {

          d3.select(`#bgBar_${d.SUBSYSTEM}`)
          .style('opacity', () => {
            if(d.A_T !== null) return 1; else return 0
          })

          d3.select(`#Bar_${d.SUBSYSTEM}`)
          .transition().duration(500)
          .attr('width', () => {
            if(d.A_T !== null) return d.A_PG; else return 0
          })

          d3.select(`#Prog_${d.SUBSYSTEM}`)
          .transition().duration(500)
          .attr('transform', () => {
            if(d.A_T !== null) {
              if(d.A_PG < 25) return `translate(${51 + d.A_PG}, ${drawY + (i*20) + 0.5})`; else return `translate(${49 + d.A_PG}, ${drawY + (i*20) + 0.5})`
            } else {
              return `translate(100, ${drawY + (i*20) + 0.5})`
            }
          })
          .attr('text-anchor', () => {
            if(d.A_T !== null) {
              if(d.A_PG < 25) return 'start'; else return 'end'
            } else {
              return 'middle'
            }
          })
          .text(() => { if(d.A_T !== null) return `${Number(d.A_PG).toFixed(0)}%`; else return 'N/A' } )
        }

      })
    },

  }
}
