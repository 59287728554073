import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    dDMembers: [
      {text: 'Disciplines', value: 0},
      {text: 'BMW', value: 1},
      {text: 'Audi',value: 2},
      {text: 'Mercedes',value: 3},
      {text: 'Hyundai',value: 4},
    ],

    discTitle: ['Structural', 'Piping', 'Electrical', 'Mechanical',  'HVAC', 'Painting']
  }),



  methods: {
    
    drawChart() {

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `lightBlue`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#d4eaf3').attr('offset', '0')
      lightBlue .append('stop').attr('stop-color', '#a3d0e8').attr('offset', '1')


      let mainGroup = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

          // mainGroup
          // .append('rect') // Background Window
          // .attr('x', 0).attr('y', 0).attr('width', 310).attr('height', 270)
          // .attr('fill', '#fff').attr('stroke', 'gray').attr('stroke-width',1)


          let blockPhoto = mainGroup
          .append('g')
          .attr('transform', `translate(20, 40)`)

          blockPhoto
          .append('rect')
          .attr('x', 0).attr('y', 0).attr('rx', 5).attr('ry', 5).attr('width', 270).attr('height', 185)
          .attr('fill', '#fff').attr('stroke-width', 0.2).attr('stroke', '#bcbcbc')

          blockPhoto
          .append('image')
          .attr('id', 'menuIcon')
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TCO/Blocks/${this.DataItems[0].module}/${this.DataItems[0].block}.jpg`)
          .attr('x', 5).attr('y', 5).attr('width', 260).attr('height', 175)

          blockPhoto
          .append('rect')
          .attr('x', 0).attr('y', 195).attr('rx', 5).attr('ry', 5).attr('width', 70).attr('height', 24)
          .attr('fill', `url(#lightBlue)`).attr('stroke-width', 0.2).attr('stroke', '#bcbcbc').attr('opacity', .7)

          blockPhoto
          .append('rect')
          .attr('x', 80).attr('y', 195).attr('rx', 5).attr('ry', 5).attr('width', 190).attr('height', 24)
          .attr('fill', '#F4F5F5').attr('stroke-width', 0)

          blockPhoto
          .append('text')
          .attr('x', 10).attr('y', 210)
          .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'start').attr('fill', '#333')
          .text('Dimension')

          blockPhoto
          .append('text')
          .attr('x', 90).attr('y', 210)
          .style('font-family','roboto').style('font-size',11).attr('text-anchor', 'start').attr('fill', '#333')
          .text(`${this.DataItems[0].len ? this.DataItems[0].len : 17.3}m x ${this.DataItems[0].wid ? this.DataItems[0].wid : '24.6'}m x ${this.DataItems[0].hei ? this.DataItems[0].hei : 1 }m | ${this.DataItems[0].wt ? this.DataItems[0].wt : 1} tons`)


          
          
    }, 

  }
}
