// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../../includes/mixins/Draw_Canvas.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../../includes/mixins/Miscellaneous.mixins'

// -------------------------------------------------------------------------------------- Local
// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from        './Realtime_Monitor.mixin' // Reactive Events
import lmx_Miscellaneous from         './../../../../include/mixins/Miscellaneous.mixins'
  
// Charts 
import lmx_Chart_Pie from             '../../../../include/ChartLibrary/Charts_Pie.mixin'
import lmx_Chart_Radar from           '../../../../include/ChartLibrary/Charts_Radar.mixin'
  
// Custom Charts  
import lmx_Custom_Charts from         '../../../../include/ChartLibrary/Custom_Charts.mixin'
import lmx_Custom_BarCharts from         '../../../../include/ChartLibrary/Custom_BarCharts.mixin'
import lmx_Custom_Header from         '../../../../include/ChartLibrary/Custom_Header.mixin'
import lmx_Custom_SummaryTable from   '../../../../include/ChartLibrary/Custom_SummaryTable.mixin'
  
import lmx_DataStyles from            './DataStyles.mixin'
import lmx_Declares from              './Declares.mixin'
import lmx_Codes from                 './Draw_Codes.mixin'
import lmx_Forms from                 './Draw_Forms.mixin'

import activate_Hull from             './Activate_Hull.mixin'
import activate_Deckbox from          './Activate_Deckbox.mixin'
import activate_Topside from          './Activate_Topside.mixin'
  
import event_Goto from                './Event_Goto.mixin'
import event_Back from                './Event_Back.mixin'

import event_Others from             './Event_Others.mixin'
  

  
import Dashboard_Overall from         './Dashboard_Overall.mixin'
import Dashboard_Area from            './Dashboard_Area.mixin'
import canvas_AreaSummary from        './Canvas_AreaSummary.mixin'
import canvas_ModuleDivision from     './Canvas_ModuleDivision.mixin'
import canvas_ModuleSummary from      './Canvas_ModuleSummary.mixin'



export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Miscellaneous,

    lmx_ReactiveEvents,
    lmx_Miscellaneous,

    lmx_Chart_Pie,
    lmx_Chart_Radar,

    lmx_Custom_Charts,
    lmx_Custom_BarCharts,
    lmx_Custom_Header,
    lmx_Custom_SummaryTable,

    lmx_DataStyles,
    lmx_Declares,
    lmx_Codes,
    lmx_Forms,

    activate_Hull,
    activate_Deckbox,
    activate_Topside,

    event_Goto,
    event_Back,
    event_Others,

    

    Dashboard_Overall,
    Dashboard_Area,
    canvas_AreaSummary,
    canvas_ModuleDivision,
    canvas_ModuleSummary,






  ],
}
