import { values } from 'core-js/fn/array'
import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    blockColor: [
      { commodity: 'Assembly', color: '#FFCDD2' },
      { commodity: 'Assembly&Erection', color: '#F8BBD0' },
      { commodity: 'Cable Pulling', color: '#E1BEE7' },
      { commodity: 'Cable Termination', color: '#D1C4E9' },
      { commodity: 'Cable Tray', color: '#C5CAE9' },
      { commodity: 'Compartment', color: '#BBDEFB' },
      { commodity: 'Cranes', color: '#B3E5FC' },
      { commodity: 'Duct', color: '#B2EBF2' },
      { commodity: 'Equipment', color: '#B2DFDB' },
      { commodity: 'Equipment & Furniture', color: '#C8E6C9' },
      { commodity: 'Erection', color: '#DCEDC8' },
      { commodity: 'Fabrication', color: '#F0F4C3' },
      { commodity: 'Field Instrumentation', color: '#FFF9C4' },
      { commodity: 'Fire Protection System', color: '#FFECB3' },
      { commodity: 'Floor Works', color: '#FFE0B2' },
      { commodity: 'Machinery Erection - Rotating', color: '#FFCCBC' },
      { commodity: 'Outfitting', color: '#D7CCC8' },
      { commodity: 'PFP', color: '#CFD8DC' },
      { commodity: 'Piping', color: '#FFCDD2' },
      { commodity: 'Prefabrication', color: '#F8BBD0' },
      { commodity: 'Pressure Test', color: '#E1BEE7' },
      { commodity: 'Safety Equipment', color: '#D1C4E9' },
      { commodity: 'Skid, Packages & Misc.', color: '#C5CAE9' },
      { commodity: 'Spool', color: '#BBDEFB' },
      { commodity: 'Steel Structure', color: '#B3E5FC' },
      { commodity: 'Support', color: '#B2EBF2' },
      { commodity: 'Tertiary', color: '#F0F4C3' },
      { commodity: 'Vessels & Exchangers', color: '#FFF9C4' },
      { commodity: 'Wall & Ceiling', color: '#B2DFDB' },
    ],
    thead: [
      { key: 'WC_DESCRIPTION', html: 'Work Class Description', row: 1, rowspan: 3 },
      { key: 'UOM', html: 'UOM', row:1, rowspan: 3 },
      { key: 'INITIALQTY', html: 'Initial <br/> Q\'ty', row: 1, rowspan: 3 },
      { key: 'TARGET', html: 'Target<br/><span class="thead_subtitle__">(Based on Initial Q\'ty)</span>', row: 1, colspan: 2 },  // custom
      { key: 'ACTUAL', html: 'Actual<br/><span class="thead_subtitle__">(Actual / initial Q\'ty)</span>', row: 1, colspan: 4 },  // custom
      { key: 'Remain', html: 'Remain<br/><span class="thead_subtitle__">(Target - Actual)</span>', row: 1, colspan: 2 },  // custom

      { key: 'TARGET_QTY', html: 'Q\'ty', row: 2, rowspan: 2 },
      { key: 'TARGET_PROG', html: 'Prog(%)', row: 2, rowspan: 2 },
      { key: 'ACTUAL_CUMULATIVE', html: 'Cumulative', row: 2, colspan: 2 },
      { key: 'ACTUAL_WEEKLY', html: 'Weekly', row: 2, colspan: 2 },
      { key: 'REMAIN_QTY', html: 'Q\'ty', row: 2, rowspan: 2 },
      { key: 'REMAIN_PROG', html: 'Prog(%)', row: 2, rowspan: 2 },

      { key: 'ACTUAL_CUM_QTY', html: 'Q\'ty', row: 3 },
      { key: 'ACTUAL_CUM_PROG', html: 'Prog(%)', row: 3 },
      { key: 'ACTUAL_WEEK_QTY', html: 'Q\'ty', row: 3 },
      { key: 'ACTUAL_WEEK_PROG', html: 'Prog(%)', row: 3 },
    ],
    theadrows: 3,
    tbody: [
      { key: 'WC_DESCRIPTION', w: 310, align: 'left' },
      { key: 'UOM', w: 50, align: 'center' },
      { key: 'INITIALQTY', w: 50 },
      { key: 'TARGET_QTY', w: 60 },
      { key: 'TARGET_PROG', w: 60 },
      { key: 'ACTUAL_CUM_QTY', w: 60 },
      { key: 'ACTUAL_CUM_PROG', w: 60 },
      { key: 'ACTUAL_WEEK_QTY', w: 60 },
      { key: 'ACTUAL_WEEK_PROG', w: 60 },
      { key: 'REMAIN_QTY', w: 60 },
      { key: 'REMAIN_PROG', w: 60 },
    ],
    // itemWidth: 105,
    width: 960,
    
    scrollProps: {
      w: 932,
      h: 300,
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        width : 4,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),

  methods: {
    drawChart() {
      let values_ = this.DataItems
      let commodity = values_.map(d => d.COMMODITY)
      let itemWidth = 165
      let svg = this.svg

      if(this.ColumnProps.length > 0) var props = this.ColumnProps.find(props_ => props_.value == 'headimg').props
      else props = {img: 'topsides'}

      let head = this.Queries.SQL2[0]

      let headGroup = svg.append('g').attr('class', 'ogm_status_group_legend__').attr('transform', `translate(0.5, 0.5)`)

      headGroup.append('rect')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 140).attr('height', 140)
        .attr('fill', head.DELTA > 0 ? '#4CAF50' : !head.DELTA || head.DELTA === 0 ? '#fff' : '#F44336')
        .attr('opacity', '0.7')

      if (head.ACTUAL_CUM_PROG !== 0) {
        headGroup.append('rect')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 140)
        .attr('height', parseInt(140 - (head.ACTUAL_PROG < 0 ? head.ACTUAL_CUM_PROG * -1 : head.ACTUAL_CUM_PROG) / 100 * 140))
        .attr('fill', '#fff')
        .attr('opacity', '0.6')
      } else {
        headGroup.append('rect')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 140)
        .attr('height', 140)
        .attr('fill', '#fff')
        .attr('opacity', '0.6')
      }

      headGroup.append('image')
        .attr('id', `omg_img_${props.img.toLowerCase()}`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/LQDashboard/icons/${props.img.toLowerCase()}.png`)
        .attr('width', '140px')
        .attr('height', '140px')

      headGroup.append('rect')
      .attr('x', 145).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 115).attr('height', 25).attr('fill', '#616161')

      headGroup.append('text').attr('x', 200).attr('y', 16).attr('text-anchor', 'middle').text(`${props.img.split('_')[0]}`).style('font-size',12).attr('fill', '#fff')
      // .style('text-transform', 'capitalize')


      // Plan
      headGroup.append('text').attr('x', 160).attr('y', 58).attr('text-anchor', 'middle').text('Plan').style('font-size',10)

      headGroup.append('rect')
      .attr('x', 180).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 80).attr('height', 25).attr('fill', '#eeeeee')

      headGroup.append('text').attr('x', 220).attr('y', 58).attr('text-anchor', 'middle')
      .text(head.PLAN_PROG + '%')
      .style('font-size',12)
      .attr('fill', '#212121')

      // Actual
      headGroup.append('text').attr('x', 160).attr('y', 94).attr('text-anchor', 'middle').text('Actual').style('font-size',10)

      headGroup.append('rect')
      .attr('x', 180).attr('y', 78).attr('rx', 3).attr('ry', 3).attr('width', 80).attr('height', 25).attr('fill', '#66BB6A')

      headGroup.append('text').attr('x', 220).attr('y', 94).attr('text-anchor', 'middle')
      .text(head.ACTUAL_CUM_PROG + '% ')
      .style('font-size',12)
      .attr('fill', '#fff')

      // Delta
      headGroup.append('text').attr('x', 160).attr('y', 131).attr('text-anchor', 'middle').text('Delta').style('font-size',10)

      headGroup.append('rect')
      .attr('x', 180).attr('y', 115).attr('rx', 3).attr('ry', 3).attr('width', 80).attr('height', 25).attr('fill', 'Transparent').attr('stroke', '#ccc')

      headGroup
      .append('text').attr('x', 220).attr('y', 131).attr('text-anchor', 'middle')
      .text(`${head.DELTA > 0 ? '▲ ' : head.DELTA === 0 ? '': '▼ '}` + head.DELTA + '%')
      .style('font-size',12)
      .attr('fill', head.DELTA > 0 ? 'green' : head.DELTA === 0 ? '#ccc' : 'red')

      let group = svg.append('g').attr('class', 'ogm_status_group__').attr('transform', `translate(300, 0)`)

      values_.forEach((v, i) => {

        let g = group.append('g').attr('class', `${v.COMMODITY}_status__`).attr('transform', `translate(${((4 - values_.length) * 165) + i*itemWidth}, 0.5)`)

        // commodity text
        g.append('rect')
        .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', 160).attr('height', 25).attr('fill', this.blockColor.filter(d => d.commodity === v.COMMODITY)[0].color).attr('opacity', '0.7')

        g.append('text')
        .attr('x', 77.5).attr('y', 17).attr('text-anchor', 'middle')
        .text(v.COMMODITY)
        .style('font-size',12)
        .style('color', '#212121')
        // .style('font-weight', 'bold')


        // Imgae box
        g.append('rect')
        .attr('x', 0).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 95).attr('height', 95)
        .attr('fill', v.DELTA > 0 ? '#4CAF50' : !v.DELTA || v.DELTA === 0 ? '#fff' : '#F44336')
        .attr('opacity', '0.7')

        if (v.ACTUAL_CUM_PROG !== 0) {
          g.append('rect')
          .attr('x', 0).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 95)
          .attr('height', parseInt(95 - (v.ACTUAL_PROG < 0 ? v.ACTUAL_CUM_PROG * -1 : v.ACTUAL_CUM_PROG) / 100 * 95))
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        } else {
          g.append('rect')
          .attr('x', 0).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 95)
          .attr('height', 95)
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        }

        g.append('image')
        .attr('id', `omg_img_${v.COMMODITY.toLowerCase()}`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Icons/constructionItem/${v.COMMODITY.toLowerCase()}.png`)
        .attr('x', 0).attr('y', 42)
        .attr('width', '95px')
        .attr('height', '95px')

        


        // Plan
        g.append('rect')
        .attr('x', 100).attr('y', 42).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 25).attr('fill', '#eeeeee')

        g.append('text').attr('x', 130).attr('y', 58).attr('text-anchor', 'middle')
        .text(v.PLAN_PROG + '%')
        .style('font-size',12)
        .attr('fill', '#212121')

        // Actual
        g.append('rect')
        .attr('x', 100).attr('y', 76).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 25).attr('fill', '#66BB6A')

        g.append('text').attr('x', 130).attr('y', 92).attr('text-anchor', 'middle')
        .text(v.ACTUAL_CUM_PROG + '% ')
        .style('font-size',12)
        .attr('fill', '#fff')

        // Delta
        g.append('rect')
        .attr('x', 100).attr('y', 110).attr('rx', 3).attr('ry', 3).attr('width', 60).attr('height', 25).attr('fill', 'Transparent').attr('stroke', '#ccc')


        g.append('text').attr('x', 130).attr('y', 126).attr('text-anchor', 'middle')
        .text(`${v.DELTA > 0 ? '▲ ' : v.DELTA === 0 ? '': '▼ '}` + v.DELTA + '%')
        .style('font-size',12)
        .attr('fill', v.DELTA > 0 ? 'green' : v.DELTA === 0 ? '#ccc' : 'red')

      })

      this.drawTable(props)
    },
    drawTable(props) {

      // this.svg.selectAll('.ogm_table_group__').remove()

      let svg = this.svg

      let group = svg.append('g').attr('class', 'ogm_table_group__' + props.img)
      
      let table_group = group.append('g').attr('transform', `translate(0.3, 150.7)`)

      let list = this.Queries.SQL1
      let tableViewHeight = 96 + (list.length * 18) // thead + tbody

      // if (tableViewHeight > this.scrollProps.h) this.setScroll(table_group, this.scrollProps)

      let table_obj = table_group.append('foreignObject')
        .attr('y',0)
        .attr('width', '100%')
        .attr('height', `${tableViewHeight}px`)
        .append('xhtml:body')

      let table = table_obj.append('table')
      .style('border', 'solid 0.7px #eee')
      .style('border-collapse', 'collapse')
      .style('font-size', '10px')
      .style('width', `${this.width - 2}px`)
      .style('table-layout', 'fixed')

      let colgroup = table.append('colgroup')
      this.tbody.forEach(k => {
        colgroup.append('col').style('width', k.w + 'px')
      })

      let thead = table.append('thead')
      for (let r = 1; r <= this.theadrows; r++) {
        let row = this.thead.filter(d=> d.row === r)
        let tr = thead.append('tr').style('background', '#FAFAFA')

        row.forEach(item => {
          tr.append('th')
          .attr('class', 'ogm_table_group__th')
          .attr('rowspan', () => item.rowspan ? item.rowspan : '')
          .attr('colspan', () => item.colspan ? item.colspan : '')
          .style('border', 'solid 0.7px #eee')
          .style('height', '18px')
          .style('padding', '5px')
          .html(item.html)
        })        
      }
        
      let tbodykeys = this.tbody
      
      let tbody = table.append('tbody')

      for (let r = 0; r < list.length; r++) {
        let tr = tbody.append('tr')

        tbodykeys.forEach((k, i) => {
          let td = tr.append('td')
            .style('border', 'solid 0.1px #eee')
            .style('height', '18px')
            .style('text-align', k.align ? k.align : 'right')
            .style('padding', '0 3px 0 3px')

          if(i === 0) {
            td.style('white-space', 'nowrap')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .attr('title', list[r][k.key])
          }

          // td.on('mouseover', () => tr.style('cursor', 'pointer'))
          // td.on('click', () => {
          //   let dataSource = {
          //     GWBS_LV2: list[r].GWBS_LV2,
          //     WCCODE: list[r].WCCODE
          //   }
          //   let request_ = this.getRequestColumnProps('status', dataSource, this.ColumnProps)
          //   this.$emit('request-action', request_)
          // })

          if (!list[r][k.key]) return

          if(k.key.includes('PROG')&& k.key != 'REMAIN_PROG') {
            td
            .style('width', '64.19px')
            .style('height', '18px')
            .style('padding', '0')
            .style('position', 'fixed')

              .append('div')
              .style('border', 'none')
              .style('width', list[r][k.key] > 0 ? list[r][k.key] + '%' : 0)
              .style('height', '90%')
              .style('margin-top', '0.5px')
              .style('background-color', list[r][k.key] < 100 ? '#fde05d' : '#43A047')

            td.append('div')
            .style('position', 'absolute')
            .style('top', '1px')
            .style('right', '2px')
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : '#000')
            .html(list[r][k.key]+'%')
          // } else {
          //   if (k.key === 'INITIALQTY' || k.key === 'REMAIN_QTY' || k.key === 'TARGET_QTY' || k.key === 'ACTUAL_CUM_QTY' || k.key === 'ACTUAL_WEEK_QTY') td.html(list[r][k.key].toFixed(1))
          //   else td.html(list[r][k.key])
          // }
          } else if(k.key === 'REMAIN_PROG') {
            td
            .style('color', list[r][k.key] < 0 ? 'red' : list[r][k.key] === 100 ? '#fff' : '#000')
            .html(list[r][k.key])
          }else if (k.key === 'INITIALQTY' || k.key === 'REMAIN_QTY' || k.key === 'TARGET_QTY' || k.key === 'ACTUAL_CUM_QTY' || k.key === 'ACTUAL_WEEK_QTY'){
            td.html(list[r][k.key].toFixed(1))
          }else td.html(list[r][k.key])
        })
      }

      d3.selectAll('.thead_subtitle__').style('color', '#00B0FF').style('font-weight', 'normal')

    }


  }
}