import '../../../../src/assets/stylus/ui/component/_jDatagridTitle.styl'

import { VIcon, VFlex } from 'vuetify/lib'
import JPagination from '../JPagination'

export default {
  name: 'j-data-grid-title',
  components: {
    JPagination, VIcon, VFlex
  },
  props: {
    sideslide: Boolean,
    attrs: {
      type: Object,
      default: () => ({})
    },
    inputFilter: String,
    // legend <= tableAttrs.style
    legendItems: {
      type: Object,
      default: () => null
    },
    pagination: { type: Object, default: () => null },
  },
  data: () => ({
    currentPage: 1,
    inputValue: ''
  }),
  computed: {
    titleWrapStyle() {
      if(!this.attrs.main) return {}
      return {
        style: {
          'display': 'flex',
          'align-items': 'flex-end'
        }
      }
    },
    titleMain() { return this.attrs.main ? this.attrs.main.text : '' },
    titleMainStyle() {
      if(!this.attrs.main) return {}
      return {
        style: {
          'font-family': this.attrs.main.font,
          'font-size': `${this.attrs.main.fontSize}px`,
          'color':  this.sideslide ? '#2196f3' : this.attrs.main.color,
          'margin-right': '7px'
        }
      }
    },
    titleSub() { return this.attrs.sub ? this.attrs.sub.text : '' },
    titleSubStyle() {
      if(!this.attrs.sub) return {}
      return {
        style: {
          'font-family': this.attrs.sub.font,
          'font-size': `${this.attrs.sub.fontSize}px`,
          'color': this.attrs.sub.color,
          'margin-bottom' : '2px'
        }
      }
    },
    titleData() { 
      let titleData__ = ''
      if(this.titleData1) titleData__ += this.titleData1
      if(this.titleData2) titleData__ += this.titleData2
      if(this.titleData3) titleData__ += this.titleData3
      if(this.titleData4) titleData__ += this.titleData4
      if(this.titleData5) titleData__ += this.titleData5

      if(!titleData__) return null
      return this.$createElement('div', [this.$createElement('pre', this.titleDataStyle, [titleData__])])
    },
    titleData1() { return this.attrs.queries ? this.attrs.queries._1st : '' },
    titleData2() { return this.attrs.queries ? this.attrs.queries._2nd : '' },
    titleData3() { return this.attrs.queries ? this.attrs.queries._3rd : '' },
    titleData4() { return this.attrs.queries ? this.attrs.queries._4th : '' },
    titleData5() { return this.attrs.queries ? this.attrs.queries._5th : '' },
    titleDataStyle() {
      if(!this.attrs.query) return {}
      return {
        style: {
          'font-family': this.attrs.query.font,
          'font-size': `${this.attrs.query.fontSize}px`,
          'color': this.attrs.query.color,
          'padding-bottom': '7px'
        }
      }
    },
    note() {
      if(!this.attrs.note || !this.attrs.note.text) return null
      return this.$createElement('div', [this.$createElement('pre', this.noteStyle, [this.attrs.note.text])])
    },
    noteStyle() {
      if(!this.attrs.sub) return {}
      return {
        style: {
          'font-family': this.attrs.note.font,
          'font-size': `${this.attrs.note.fontSize}px`,
          'color': this.attrs.note.color,
          'padding-bottom': '7px'
        }
      }
    },
    legend() {
      if(this.attrs.showLegend != 'Y') return null
      if(!this.legendItems || Object.keys(this.legendItems).length === 0) return null

      let propNames_ = ['cell', 'color', 'text']
      let draw = (name, items) => items.map(item_ => {
        let data = {
          style: {
            'color': item_.tColor,
            'padding-left': '1rem',
            'padding-right': '1rem',
            'margin-right': '1rem'
          }
        }
        if(name == 'cell') data.style['background'] = `linear-gradient(to right, ${item_.bColor} 50%, #fff 50%)`
        else if(name == 'color') data.style['background-color'] = item_.bColor
        else {
          data.style['padding-left'] = '3px'
          data.style['padding-right'] = '3px'
        }

        return this.$createElement('div', data, [item_.text || item_.code])
      })

      let legendItems_ = []
      propNames_.forEach(name => { legendItems_ = [...legendItems_, ...draw(name, this.legendItems[name])] })

      let data = {
        style: {
          'display': 'flex',
          'line-height': '1.4rem',
          'padding-bottom': '7px'
        }
      }
      return this.$createElement('div', data, legendItems_)
    },
  },
  methods: {
    genPagination() {
      if(!this.attrs.pagination || this.attrs.pagination.type != 'page') return null

      let data = {
        props: {
          showStatus: this.attrs.pagination.showPageNo == 'Y' ? true : false,
          pagination: this.pagination,
          sideslide: this.sideslide,
        },
        on: { 
          first: () => { this.$emit('first') },
          previous: (v) => { this.$emit('previous', v) },
          next: (v) => { this.$emit('next', v) },
          last: (v) => { this.$emit('last', v) },
        }
      }        
      return this.$createElement('j-pagination', data)
    },
    genSearchInput() {
      if(this.attrs.showSearchInput != 'Y') return null

      let data = {
        input: {
          attrs: {
            type: 'search',
            name: 'q',
            placeholder: 'Filter and search',
            autocomplete: 'off'
          },
          style: {
            color: '#fff',
            fontWeight: 'bold'
          },
          domProps: {
            value: this.inputValue || this.inputFilter
          },
          on: {
            keyup : (e) => { 
              this.inputValue = e.target.value
              if(e.key === 'Enter') this.$emit('input-filter', e.target.value) 
            }
          }
        },
        btClear: {
          attrs: { type: 'submit' },
          style: { 'margin-right': '-5px' },
          on: { click: () => { 
            this.inputValue = ''
            this.$emit('input-filter', '') 
          }}
        },
        btSearch: {
          attrs: { type: 'submit' },
          on: { click: () => { this.$emit('input-filter', this.inputValue) }}
        }
      }

      let buttons_ = [ this.$createElement('button', data.btSearch, [this.$createElement('v-icon', ['mdi-magnify'])]) ]
      if(this.inputValue) buttons_ = [
        this.$createElement('button', data.btClear, [this.$createElement('i', {
          attrs: { 
            'aria-hidden': 'true',
            'title': 'Clear Text'
          },
          class: 'v-icon v-icon--link mdi mdi-close theme--light',
        })]),
        ...buttons_
      ]
      if (this.sideslide) {
        return this.$createElement('div', { style : {
          'border' : '#ccc',
          'background-color': 'rgb(222, 222, 222)',
          'opacity' : '1'     
        }, class: 'j_search type01' }, [
          this.$createElement('input', data.input),
          ...buttons_
        ])
      }else {
        return this.$createElement('div', { class: 'j_search type01' }, [
          this.$createElement('input', data.input),
          ...buttons_
        ])
      }
    },
    genExcelExport() {
      if(this.attrs.showExcelExport != 'Y') return null

      let data = { 
        button: { 
          class: 'j_button type01 sky',
          on: {
            click: () => { this.$emit('excel-download') }
          }
        },
        i: { class: 'jcon_download_02' }
      }
      return this.$createElement('button', data.button, [
        this.$createElement('i', data.i),
        this.$createElement('span', ['EXCEL']),
      ])
    },
  },
  render() {
    return (
      <div class="j_datagrid_title">
        <div>
          <div { ...this.titleWrapStyle }>
            <h5 { ...this.titleMainStyle }>{ this.titleMain }</h5>
            <small { ...this.titleSubStyle }>{ this.titleSub }</small>
          </div>
          { this.titleData }
          { this.note }
          { this.legend }
        </div>
        <div class="__title__right">
          { this.genPagination() }
          { this.genSearchInput() }
          { this.genExcelExport() }
        </div>
      </div>
    )
  }
}
