import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

  }),


  methods: {
    
    loadSvg(path) {
      return d3.xml(path).then(source => {
        if(this.innerSvg) {
          this.innerSvg.html()
          this.innerSvg.remove()
          this.innerSvg = null
        }

        this.innerSvg = source.documentElement
        this.svg.node().append(this.innerSvg)

        this.innerSvg = d3.select(this.innerSvg)
        this.innerSvg.attr('x',0).attr('y',0).attr('width',860).attr('height',515)
        .attr('opacity', 0)
        .transition()
        .duration(500)
        .attr('opacity', 1)

        return new Promise(resolve => {
          resolve('test')
        })
      })
    },

  }
}
