import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    
  }),


  methods: {
    
    draw_TimelineModule() {

      let ModuleLine = 50

      this.dataSet.forEach(data => {

        // the timeline of module needs a minimum date of schedule
        let scheduleMin = new Date(Math.min(
          ...[new Date(data.ST010_ESD), new Date(data.ST010_ASD)]
        ))

        let modStartMonth_ = new Date(scheduleMin.setMonth(scheduleMin.getMonth() - 1))
        let modStartDate_ = new Date(modStartMonth_.getFullYear(), modStartMonth_.getMonth(), 1, 0, 0, 0)
        
        // the timeline of module needs a maximum date of schedule
        let RFS = [new Date(data.OT090_EFD)]
        if (data.OT090_ASD) RFS.push(new Date(data.OT090_ASD))
        if (data.OT090_AFD) RFS.push(new Date(data.OT090_AFD))

        let scheduleMax = new Date(Math.max(...RFS))
        let modEndMonth_ = new Date(scheduleMax.setMonth(scheduleMax.getMonth() + 1))
        modEndMonth_ = new Date(new Date(modEndMonth_.getFullYear(), modEndMonth_.getMonth() + 1, 1, 0, 0, 0) - 1)
        let modEndDate_ = new Date(new Date(modEndMonth_).setMonth(modEndMonth_.getMonth() + 1) - 1)


        let noOfMonth = modEndDate_.getMonth() - modStartDate_.getMonth()
        let noOfYear = modEndDate_.getYear() - modStartDate_.getYear()
        noOfMonth = noOfMonth + (noOfYear*12)

        // ### Set Timeline-Values for the Month ### --------------------------
        let tickValues_ = []

        for(var i=0; i<=noOfMonth; i++) {
          let _nextMonthDate__ = new Date(new Date(modStartDate_).setMonth(modStartDate_.getMonth() + i))

          // For the Month ---
          let endMonth_ = new Date(new Date(_nextMonthDate__).setMonth(_nextMonthDate__.getMonth() + 1) - 1)
          let midDate = new Date(
            d3.mean([
              _nextMonthDate__, // 1st date of the month
              endMonth_         // last date of the month
            ])
          )
          tickValues_.push({ 
            name: d3.timeFormat('%b')(midDate), 
            value: new Date(midDate),
            endDate: endMonth_
          })
        }

        
        // ### Set Timeline-Values for the Month ### --------------------------
        let Timeline = this.modules2020
        .append('g')
        .attr('transform', `translate(0, ${ModuleLine})`)

            let startX = this.timeScale(modStartDate_)
            let endX = this.timeScale(modEndDate_)
            let timelineWidth = endX - startX
      
            Timeline
            .append('path') // shadow
            .attr('d', `M${startX + 50},10 H ${endX - 50} L ${endX},20 H${startX} Z`).attr('fill', `url(#shadowTimeline)`) 

            Timeline
            .append('rect')
            .attr('x', startX).attr('y', 0).attr('width', timelineWidth).attr('height', 15).attr('fill', `url(#${this.localId}__LinearA4LightGray0)`)

            // Timeline Text using the scale function
            // Month
            Timeline
            .append('g')
            .selectAll('text')
            .data(tickValues_)
            .enter()
            .append('text')
            .attr('x', d => this.timeScale(d.value))
            .attr('y', 8).style('font-size', 10).style('fill', '#333')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .text(d => d.name.toUpperCase())

            // Timeline Seperator
            Timeline
            .append('g')
            .selectAll('path')
            .data(tickValues_)
            .enter()
            .append('path')
            .attr('d', (d,i) => {
              if (tickValues_.length-1 > i) {
                return `M${this.timeScale(d.endDate)}, 4 V12`
              }
            })
            .attr('stroke', '#757575').attr('stroke-width', 0.3)
            
        ModuleLine += 400
      })

         
    }, 
  }
}
