import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    innerSvg: null,
    chartGroup: null,
    keyPlanSvg: null,
    Module: '41M001',
    mMode: 'CONST', // CONT / MC /CERT
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      // this.Module = this.FilterValues.MOD
      this.Module = ''
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
    },

    
    getMatrixProps: (selection, transform) => {
      let matrixEls = transform.substring(transform.indexOf('(') + 1, transform.indexOf(')')).split(' ')
      let elWidth = selection.node().getBoundingClientRect().width
      let elHeight = selection.node().getBoundingClientRect().height
      // console.log(selection.text(), elWidth, elHeight)
      return {
        a: matrixEls[0],
        b: matrixEls[1],
        c: matrixEls[2],
        d: matrixEls[3],
        dx: matrixEls[4],
        dy: matrixEls[5],
        cx: Number(matrixEls[4]),
        cy: Number(matrixEls[5]),
      }
    },


    
  }
}