<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'

export default {
  name: "j-chart-block-division-hebron",
  mixins: [ mx_Core ],
  props:{
    id: String,
    modeExport: Boolean,

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Queries: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  // mounted(){
  //   this.draw()
  // },
  mounted() {
    this.register(this.draw)
  },
  methods:{
    draw() {
      this.clear()
      this.envInit()
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()          // Global
      this.complete()
    },
    drawChart() {
      this.drawNavButton()
      this.drawOverall()
      this.drawProgress()
    },
  }
}
</script>
