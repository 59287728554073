import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
    PieData: [],

    OverallBlockStatus: [
      {TITLE: 'Cutting Commenced'   ,TOTAL: 0, ACTUAL: 0},
      {TITLE: 'Fab. Commenced'      ,TOTAL: 0, ACTUAL: 0},
      {TITLE: 'Assembly 100%'       ,TOTAL: 0, ACTUAL: 0},
      {TITLE: 'Pre-Outfitting 100%' ,TOTAL: 0, ACTUAL: 0},
      {TITLE: 'Block Painting 100%' ,TOTAL: 0, ACTUAL: 0},
      {TITLE: 'Erection Completion' ,TOTAL: 0, ACTUAL: 0},     
    ],

    ProgressSet: [
      {TYPE: 'Phase'  , PHASE: 'ENG', TITLE:'SHOP DWAWING',      bColor: '#FFE000', },
      {TYPE: 'Phase'  , PHASE: 'CON', TITLE:'CONSTRUCTION',      bColor: '#CFCD1A', },
      {TYPE: 'Phase'  , PHASE: 'PRO', TITLE:'PROCUREMENT',       bColor: '#7BAF30', },
      {TYPE: 'Phase'  , PHASE: 'INT', TITLE:'INTEGRATION',       bColor: '#2F993D', },
      {TYPE: 'Phase'  , PHASE: 'MC',  TITLE:'MECH. COMPLETION',  bColor: '#18634C', },
      {TYPE: 'Phase'  , PHASE: 'COM', TITLE:'PRE-COMMISSIONING', bColor: '#F7BACF', },
      {TYPE: 'Overall', PHASE: 'OV',  TITLE: null,               bColor: null,      },
      {TYPE: 'Overall', PHASE: 'PA',  TITLE: null,               bColor: null,      },
      {TYPE: 'Overall', PHASE: 'PB',  TITLE: null,               bColor: null,      },
      {TYPE: 'Overall', PHASE: 'WD',  TITLE: null,               bColor: null,      },
      {TYPE: 'Overall', PHASE: 'MC',  TITLE: null,               bColor: null,      },
    ],

  }),

  methods: {
    Dashboard_Overall() {

      this.SVG_DashboardOverall = this.svg.append('svg')

      this.SVG_DashboardOverall
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)

      let item = null, item1 = null, item2 = null, data = null, style = null

      this.SVG_DashboardOverall
      .append('image')
      .attr('id', 'MD2_Overall')
      .attr('transform', `translate(50,250)`).attr('width', 550)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/Images/Overall/Overall.jpg`)
      .style('cursor', 'pointer')
      .call(s => this.Goto_Dashboard_Area(s))


      // Overall Block Status ------------------------------------------------------------------------------------------
      item = this.Queries.SQL2.filter(f => f.AREA== 'Overall')
        
      style = [
                {name: 'Cutting Commenced',   bColor:'#44A9DF', opacity: 0.25},
                {name: 'Fab. Commenced',      bColor:'#44A9DF', opacity: 0.40},
                {name: 'Assembly 100%',       bColor:'#44A9DF', opacity: 0.55},
                {name: 'Pre-Outfitting 100%', bColor:'#44A9DF', opacity: 0.70},
                {name: 'Block Painting 100%', bColor:'#44A9DF', opacity: 0.85},
                {name: 'Erection Completion', bColor:'#44A9DF', opacity: 1.00},
            ]
      this.Bar_Activity_01(this.SVG_DashboardOverall, 'overall', 830, 400, item, 12, 100, 20, 10, style, 'on')





      // System Completion Summary ------------------------------------------------------------------------------------------
      this.Header_TitleBox( this.SVG_DashboardOverall, 'Certificates for MC', 1000, 220, 120, 20, 3, '#757575', '#E0E0DF') // ------------>


      style = {
        id: 'Certificates',
        x: 1000,
        y: 245,
        width: 210,
        height: 65,
        cell: [10, 90, 140, 190],
        bColor: '#83D2F5',
        opacity: 1,
        requests: {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 173,
            no: ''
          },
          filters     : {
          },
          iFilters    : {
          }
        }
      }
      item1 = this.Queries.SQL_OverallProgress.find(f=> f.PHASE == 'WD')
      item2 = this.Queries.SQL_OverallProgress.find(f=> f.PHASE == 'MCC')
      let data1 = ['Walkdown', item1.TOTAL, item1.ACTUAL, item1.REMAIN]
      let data2 = ['MC', item2.TOTAL, item2.ACTUAL, item2.REMAIN]
      this.SummaryTable3(this.SVG_DashboardOverall, data1, data2, style) // ------------>
      

      this.Header_TitleBox(this.SVG_DashboardOverall, 'Mech. Completion', 1000, 330, 120, 20, 3, '#757575', '#E0E0DF') // ------------>


      style = {
        id: 'MC',
        title: 'ITRs(A)',
        x: 1000,
        y: 355,
        width: 210,
        height: 40,
        cell : [10, 90, 140, 190],
        bColor: '#88C8EB',
        opacity: 1,
        requests: {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 173,
            no: ''
          },
          filters     : {
          },
          iFilters    : {
          }
        }
      }
      item = this.Queries.SQL_OverallProgress.find(f=> f.PHASE == 'MC')
      this.SummaryTable2(this.SVG_DashboardOverall, item, style) // ------------>


      style = {
        id: 'Punch',
        x: 1000,
        y: 400,
        width: 210,
        height: 65,
        cell: [10, 90, 140, 190],
        bColor: '#83D2F5',
        opacity: 1,
        requests: {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 173,
            no: ''
          },
          filters     : {
          },
          iFilters    : {
          }
        }
      }
      item1 = this.Queries.SQL_OverallProgress.find(f=> f.PHASE == 'PA')
      item2 = this.Queries.SQL_OverallProgress.find(f=> f.PHASE == 'PB')
      data1 = ['Cat. A', item1.TOTAL, item1.ACTUAL, item1.REMAIN]
      data2 = ['Cat. B', item2.TOTAL, item2.ACTUAL, item2.REMAIN]
      this.SummaryTable3(this.SVG_DashboardOverall, data1, data2, style) // ------------>

      this.Header_TitleBox( this.SVG_DashboardOverall, 'Pre-Commissioning', 1000, 483, 127, 20, 3, '#757575', '#E0E0DF')

      item = this.Queries.SQL_OverallProgress.find(f=> f.PHASE == 'COM')
      style = {
        id: 'SC',
        title: 'ITRs(B)',
        x: 1000,
        y: 508,
        width: 210,
        height: 40,
        cell : [10, 90, 140, 190],
        bColor: '#83D2F5',
        opacity: 1,
        requests: {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 173,
            no: ''
          },
          filters     : {
          },
          iFilters    : {
          }
        }
      }
      this.SummaryTable2( this.SVG_DashboardOverall, item, style)



      // Database for Pahse Progress ------------------------------------------------------------------------------------------
      this.ProgressSet.forEach((d,i) => {
        d.P_PG =  this.Queries.SQL_OverallProgress[i].P_PG
        d.A_PG =  this.Queries.SQL_OverallProgress[i].A_PG
        d.D_PG =  this.Queries.SQL_OverallProgress[i].D_PG
        
        d.TOTAL =  this.Queries.SQL_OverallProgress[i].TOTAL
        d.ACTUAL = this.Queries.SQL_OverallProgress[i].ACTUAL
        d.REMAIN = this.Queries.SQL_OverallProgress[i].REMAIN
      })
      
      let overallProg = this.SVG_DashboardOverall
      .append('g')
      .attr('transform', `translate(50, 120)`)

      let prog = this.ProgressSet.find(f=> f.PHASE == 'OV')

      overallProg
      .append('rect')
      .attr('transform', `translate(0, 20)`).attr('width', 1000).attr('height', 16).attr('fill', '#F4F5F5')

      overallProg
      .append('rect')
      .attr('transform', `translate(0, 20)`).attr('width', prog.A_PG*10).attr('height', 16).attr('fill', '#7BAF30')

      overallProg
      .append('text')
      .attr('transform', `translate(${(prog.A_PG*10)-2}, 32)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').style('text-anchor', 'end').text(`${prog.A_PG}%`)

      overallProg
      .append('text')
      .attr('transform', `translate(${(prog.A_PG*10)-2}, 48)`)
      .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').style('text-anchor', 'end').text(`Plan Cuml.(%)`)

      overallProg
      .append('text')
      .attr('transform', `translate(${(prog.A_PG*10)-2}, 60)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').style('text-anchor', 'end').text(`${prog.P_PG}%`)

      overallProg
      .append('text')
      .attr('transform', `translate(${(prog.A_PG*10)+-2}, 78)`)
      .style('font-family', 'roboto').style('font-size', 14).style('text-anchor', 'end')
      .style('fill', () => {
        if(prog.remain < 0) return '#EC407A'; else return '#44A9DF'
      })
      .text(`${prog.D_PG}%`)

      // Overall Progress with grids -------------------------------------------------------------------------------------------------
      for (let i = 0; i <= 100; i += 20) {
        overallProg
        .append('text')
        .attr('transform', `translate(${i*10}, 10)`)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').style('text-anchor', 'middle').text(`${i}%`)

        overallProg
        .append('line')
        .attr('x1', i*10).attr('y1', 13).attr('x2', i*10).attr('y2', 18)
        .style('stroke-width', 0.5).style('stroke', '#bcbcbc')
      }
      

      // Phase Progress -------------------------------------------------------------------------------------------------
      let phaseProgress = this.SVG_DashboardOverall
      .append('g')
      .attr('transform', `translate(50, 820)`)

      let startX = 0
      let length = 200, gapSize = 5
      this.ProgressSet.filter(f=> f.TYPE == 'Phase').forEach((d)=> {

        phaseProgress
        .append('text')
        .attr('transform', `translate(${startX}, -2)`)
        .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').style('text-anchor', 'start').text(`${d.TITLE}`)

        this.Arrow_Phase(phaseProgress, startX, (1*length), '#E0E0DF', 30, 10, 0.4)
        this.Arrow_PhaseTrans(phaseProgress, startX, ((d.A_PG/100)*length), d.bColor, 30, 10, 0.75, 750)

        phaseProgress
        .append('text')
        .style('font-family', 'roboto').style('font-size', 14).text(`${d.A_PG}%`)
        .style('fill', () => {
          if(d.A_PG > 35) return '#fff'; else return '#757575'
        })
        .attr('transform', () => {
          if(d.A_PG > 35) return `translate(${startX+(d.A_PG/100)*length-9}, 20)`; else return `translate(${startX+(d.A_PG/100)*length+5}, 20)`
        })
        .style('text-anchor', () => {
          if(d.A_PG > 35) return 'end'; else return 'start'
        })
        
        phaseProgress
        .append('text')
        .attr('transform', `translate(${startX+(d.A_PG/100)*length-9}, 42)`)
        .style('font-family', 'roboto').style('font-size', 8).style('fill', '#bcbcbc').style('text-anchor', 'end').text(`Plan Cuml.(%)`)

        phaseProgress
        .append('text')
        .attr('transform', `translate(${startX+(d.A_PG/100)*length-9}, 57)`)
        .style('font-family', 'roboto').style('font-size', 14).style('fill', '#757575').style('text-anchor', 'end').text(`${d.D_PG.toFixed(2)}%`)

        startX += length 
        startX += gapSize -10
      })


      // Donut Chart ------------------------------------------------------------------------------------------
      let setStyle = null, Circle = null,  Guideline = null

      setStyle = {
                  id: 'WT',
                  x: 720,
                  y: 650,
                  name: 'Weight',
                  sort: 'N',
      }
      data = this.Queries.SQL_Quantity.filter(f=> f.type == 'WT')
      Circle = {
        CircleDiameter: 50,
        CircleColorSet: ['LightBlue', 'Yellow', 'LightGray', ],
        CircleHoleSize: 32,
        CircleStackSize: 2,
        CircleSliceSize: 5,
        CircleTitleSize: 12,
        CircleLegendSpace: 65,
      }
      Guideline = {
        GuideInside: 5,
        GuideOutside: 5,
        GuideExtend: 0,
      }
      this.Chart_Pie(this.SVG_DashboardOverall, data, setStyle, Circle, Guideline) // ---------------------------> 
 

      setStyle = {
                  id: 'BLK',
                  x: 935,
                  y: 650,
                  name: 'Block Qty',
                  sort: 'Y',
      }
      data = this.Queries.SQL_Quantity.filter(f=> f.type == 'BLK') 
      Circle = {
      }
      Guideline = {
      }
      this.Chart_Pie(this.SVG_DashboardOverall, data, setStyle, Circle, Guideline) // ---------------------------> 
  

      setStyle = {
                  id: 'ITR',
                  x: 1150,
                  y: 650,
                  name: 'A ITRs',
                  sort: 'Y',
      }
      data = this.Queries.SQL_Quantity.filter(f=> f.type == 'ITR')
      Circle = {
      }
      Guideline = {
      }
      this.Chart_Pie(this.SVG_DashboardOverall, data, setStyle, Circle, Guideline) // ---------------------------> 
  
    },



  }
}
           