import * as d3 from 'd3'
import Defs from '../../../../includes/primitives/Color_Defs'
import Data from '../../../../includes/primitives/Color_Data'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    
  }),


  methods: {

    Chart_Tube_01(selection, d) {

      let TubeScale = d3.scaleLinear()
      .domain([0, 100])
      .rangeRound([d.height, 0])

      Defs.setDefs_Reflections(selection, this.localId)
      Defs.setDefs_Gradients(selection, 1, 'LinearA2', [d.color], [d.color, 0, 0.5, 1, 0.5], this.localId)
      Defs.setDefs_Gradients(selection, 1, 'LinearA9', [d.color], [d.color, 0, 0.5, 1, 0.5], this.localId)

      let Shadow = selection
      .append('defs') // Shadow Left
      .append('radialGradient')
      .attr('id', `${this.localId}_Shadow`)

      Shadow.append('stop').attr('stop-color', '#a4a4a4').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '0.37')


      let TubeBase = selection
      .append('defs') // d.base.height Cylinder
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeBase`)

      TubeBase.append('stop').attr('stop-color', '#a9adb0').attr('offset', '0.00').attr('stop-opacity', '1')
      TubeBase.append('stop').attr('stop-color', '#f6f6f6').attr('offset', '0.60').attr('stop-opacity', '1')
      TubeBase.append('stop').attr('stop-color', '#f6f6f6').attr('offset', '1.00').attr('stop-opacity', '1')

      let TubeBox = selection
      .append('defs') // Tube Box
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeBox`)

      TubeBox.append('stop').attr('stop-color', '#b4b4b4').attr('offset', '0.00').attr('stop-opacity', '0.50')
      TubeBox.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.30').attr('stop-opacity', '0.15')
      TubeBox.append('stop').attr('stop-color', '#fff').attr('offset', '0.60').attr('stop-opacity', '0.01')
      TubeBox.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.80').attr('stop-opacity', '0.15')
      TubeBox.append('stop').attr('stop-color', '#b4b4b4').attr('offset', '1.00').attr('stop-opacity', '0.50')

      let TubeCap = selection
      .append('defs') // Tube Cap
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeCap`)

      TubeCap.append('stop').attr('stop-color', '#efefef').attr('offset', '0.00').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.41').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#ececec').attr('offset', '0.59').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#e8e8e8').attr('offset', '1.00').attr('stop-opacity', '0.90')




      // --------------------------------------------------------------------------------------------------------------------------------------
      // Draw Tube Chart
      // Start from here
      let chartWrapper = selection
      .append('g')
      .attr('transform', `translate(${d.x + (d.tickness/2)},${d.y + (d.tickness/4)})`)

      // ---------------------------  Reflected Effect
      chartWrapper // Reflected Box
      .append('rect')
      .attr('transform', `translate(0,${d.height + d.base.height})`)
      .attr('width', d.tickness).attr('height', d.base.height).attr('opacity', '0.75').attr('fill', '#BDBCBC')

      chartWrapper // Transparent Gradation Area
      .append('rect')
      .attr('transform', `translate(${-d.tickness / 2},${d.height + d.base.height})`)
      .attr('width', d.tickness * 2.5).attr('height', d.base.height + 2).attr('fill', `url(#${this.localId}__Color_Reflection)`)

      chartWrapper // Shadow
      .append('ellipse')
      .attr('cx', d.tickness / 4).attr('cy', d.height + d.base.height)
      .attr('rx', (d.tickness * 1.5) / 2).attr('ry', (d.tickness * 1.5) / 3 / 2).attr('fill', `url(#${this.localId}_Shadow)`)


      // --------------------------- d.base.height Cylinder
      chartWrapper // Series Bottom Box
      .append('rect')
      .attr('transform', `translate(0,${d.height})`)
      .attr('width', d.tickness).attr('height', d.base.height).attr('fill', `url(#${this.localId}__TubeBase)`)

      chartWrapper // Series Bottom Cap Bottom
      .append('ellipse')
      .attr('cx', d.tickness / 2) .attr('cy', d.height + d.base.height)
      .attr('rx', d.tickness / 2).attr('ry', d.tickness / 3 / 2).attr('fill', `url(#${this.localId}__TubeBase)`)


      // --------------------------- Tube Cylinder
      chartWrapper
      .append('rect') // Tube Cylinder Box
      .attr('width', d.tickness).attr('height', d.height).attr('fill', `url(#${this.localId}__TubeBox)`).attr('opacity', 0.7)

      chartWrapper // Bottom Cap
      .append('ellipse')
      .attr('cx', d.tickness / 2).attr('cy', d.height)
      .attr('rx', d.tickness / 2).attr('ry', d.tickness / 3 / 2).attr('fill', '#E0E0DF').attr('opacity', 0.9)

      chartWrapper // Top Cap
      .append('ellipse')
      .attr('cx', d.tickness / 2).attr('cy', 0)
      .attr('rx', d.tickness / 2).attr('ry', d.tickness / 3 / 2).attr('fill', `url(#${this.localId}__TubeCap)`)


      // --------------------------- Color Cylinder
      chartWrapper
      .append('path') // Cyinder Box
      .attr('d', `
      M ${0} ${TubeScale(0)}
      V ${TubeScale(0)}
      C 0 ${((d.tickness/3/2)*1.25) + TubeScale(0) } ${d.tickness} ${((d.tickness/3/2)*1.25) + TubeScale(0) } ${d.tickness} ${TubeScale(0) } 
      V ${TubeScale(0)} 
      `)
      .attr('fill', `url(#${this.localId}__LinearA9${d.color}0)`)
      .attr('opacity', .9)
      .transition()
      .duration(500)
      .attr('d', () => {
      let h_ = TubeScale(d.value.PROG)
      let hy = TubeScale(0) 

      return `
      M ${0} ${hy}
      V ${TubeScale(d.value.PROG)}
      C 0 ${((d.tickness/3/2)*1.25) + h_} ${d.tickness} ${((d.tickness/3/2)*1.25) + h_} ${d.tickness} ${h_} 
      V ${TubeScale(0)} 
      `
      })

      chartWrapper // Bottom Cap
      .append('ellipse')
      .attr('cx', d.tickness / 2).attr('cy', d.height)
      .attr('rx', d.tickness / 2).attr('ry', d.tickness / 3 / 2).attr('fill', `url(#${this.localId}__LinearA9${d.color}0)`).attr('opacity', 1)

      chartWrapper // Top Cap
      .append('ellipse')
      .attr('cx', d.tickness / 2).attr('cy', TubeScale(0))
      .attr('rx', d.tickness / 2).attr('ry', d.tickness / 3 / 2).attr('fill', `url(#${this.localId}__LinearA2${d.color}0)`).attr('opacity', 1)
      .transition().duration(500).attr('cy', TubeScale(d.value.PROG))

      // --------------------------- Value & Grid

      chartWrapper
      .append('text')
      .attr('transform', `translate(${d.tickness / 2},${d.height + d.base.padding})`)
      .style('font-size', d.base.tSize).style('font-family', 'roboto').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').attr('fill', '#44A9DF')
      .text(`${d.value.PROG.toFixed(1)}%`)

      chartWrapper
      .append('line')
      .attr('x1', d.tickness + 5).attr('y1', d.height).attr('x2', d.tickness + 20).attr('y2', d.height)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      chartWrapper
      .append('line')
      .attr('x1', d.tickness + 5).attr('y1', d.height).attr('x2', d.tickness + 20).attr('y2', d.height)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      chartWrapper
      .append('line')
      .attr('x1', d.tickness + 5).attr('y1', TubeScale(d.value.PROG)).attr('x2', d.tickness + 20).attr('y2', TubeScale(d.value.PROG))
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      chartWrapper
      .append('line')
      .attr('x1', d.tickness + 5).attr('y1', 0).attr('x2', d.tickness + 20).attr('y2', 0)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      chartWrapper
      .append('line')
      .attr('x1', d.tickness + 15).attr('y1', 0).attr('x2', d.tickness + 15).attr('y2', d.height)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.5)

      chartWrapper
      .append('text')
      .attr('id', 'Tube_Actual')
      .attr('transform', `translate(${d.tickness + 25},${TubeScale(d.value.PROG/2)})`)
      .style('font-size', d.legend.valueSize).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('fill',  d.legend.actualColor)
      .text(`${d.value.ACTUAL}`)

      chartWrapper
      .append('text')
      .attr('transform', `translate(${this.getNodeElValue('#Tube_Actual', 'width') + 3 + d.tickness + 25},${TubeScale(d.value.PROG/2)})`)
      .style('font-size', d.legend.tSize).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('fill', '#757575')
      .text(`Completed`)

      chartWrapper
      .append('text')
      .attr('id', 'Tube_Remain')
      .attr('transform', `translate(${d.tickness + 25},${TubeScale(d.value.PROG + (100-d.value.PROG)/2)})`)
      .style('font-size', d.legend.valueSize).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('fill', d.legend.remainColor)
      .text(`${d.value.REMAIN}`)

      chartWrapper
      .append('text')
      .attr('transform', `translate(${this.getNodeElValue('#Tube_Remain', 'width') + 3 + d.tickness + 25},${TubeScale(d.value.PROG + (100-d.value.PROG)/2)})`)
      .style('font-size', d.legend.tSize).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').attr('fill', '#757575')
      .text(`Remain`)

    },

  }
}
