import Defs from '../../../../../../includes/primitives/Color_Defs'
import Data from '../../../../../../includes/primitives/Color_Data'

import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    innerSvg: null,
    keyPlanSvg: null,

    monthTimeline: [], // year: 2018, month: 'JAN' ,days: 31

    timelineWidth: 1530, // Timeline Total length
    timelineScale: null,
    timelineValues: null,

    weekSize: 0,

    style:[
      {name: 'NOT STARTED', title: 'Not Started Disconnection', bColor:'#FFF',    tColor:'#333', y: 60 },
      {name: 'IN PROGRESS', title: 'D1 QVDs in Progress',       bColor:'#FCE3EB', tColor:'#333', y: 75 },
      {name: 'COMPLETION',  title: 'D1 QVDs Completed',         bColor:'#FEED57', tColor:'#333', y: 90 },
      {name: 'RFD',         title: 'RFD Completed',             bColor:'#B4E3FA', tColor:'#333', y: 105 },
      {name: 'RFL',         title: 'RFL Completed',             bColor:'#44A9DF', tColor:'#fff', y: 120 },
      {name: 'RFSC',        title: 'RFSC Completed',            bColor:'#8BC248', tColor:'#fff', y: 135 },
    ],

    shipment:[
      { name: 'Okpo-27', y: 150,  vessel: 'CY2'},
      { name: 'Okpo-28', y: 50,  vessel: 'KES2'},
      { name: 'Okpo-29', y: 130,  vessel: 'HJP'},
      { name: 'Okpo-30', y: -20,  vessel: 'XHK'},
      { name: 'Okpo-31', y: 75,   vessel: 'YMTO'},
      { name: 'Okpo-32', y: 50,   vessel: 'DBG7'},
      { name: 'Okpo-33', y: 135,  vessel: 'TAK'},
      { name: 'Okpo-34', y: 130,  vessel: 'DEB2'},
      { name: 'Okpo-35', y: 200,  vessel: 'KSK'},
      { name: 'Okpo-36', y: 100,   vessel: 'ZYK'},
      { name: 'Okpo-37', y: 150,   vessel: 'XYK'},
      { name: 'Okpo-38', y: 150,   vessel: 'DBG3'},
      { name: 'Okpo-39', y: 150,   vessel: 'XRK'},
      { name: 'Okpo-40', y: 100,   vessel: 'XHK'},
      { name: 'Okpo-41', y: 150,   vessel: 'CY2'},
      { name: 'Okpo-42', y: 150,   vessel: 'CY1'},
      { name: 'Okpo-43', y: 150,   vessel: 'ZYK'},
      { name: 'Okpo-44', y: 150,   vessel: 'DBG7'},
      { name: 'Okpo-45', y: 75,   Vessel: 'XYK'},
    ],

    vessel:['CY1','DBG7','DEB2','',],
  }),
  computed: {

    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },



  methods: {
    setDefaultValues() {
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.weekSize = 0
      this.timelineScale = null
      this.timelineValues = {
        year: [],
        month: [],
        week: [],
      }
      
      
      let sDate = new Date('2019-12-20 00:00:00')
      let eDate = new Date('2020-07-31 23:59:59')

      // ### Set Timeline-Values for the Week ### ---------------------------
      let nextDay = new Date(sDate)
      while(nextDay <= eDate) {
        
        this.timelineValues.week.push({ 
          name: '', 
          sDate: new Date(new Date(nextDay).setHours(nextDay.getHours() - 168)),
          mDate: new Date(new Date(nextDay).setHours(nextDay.getHours() - 84)),
          eDate: new Date(nextDay)
        })
        nextDay.setDate(nextDay.getDate() + 7)
      }
      // ### ------------------------------------- --------------------------
      
      // ### Set Timeline-Values for the Month ### --------------------------
      eDate = null
      let months_ = []
      let months__ = []
      this.timelineValues.week.forEach((w_, i) => {
        if(eDate != d3.timeFormat('%Y-%m')(w_.eDate)) {
          eDate = d3.timeFormat('%Y-%m')(w_.eDate)

          if(i > 0) {
            months_.push(months__)
            months__ = []
          }
        }
        months__.push(w_)
        if(i === this.timelineValues.week.length - 1) months_.push(months__)
      })
      months_.forEach(m_ => {
        this.timelineValues.month.push({
          name: d3.timeFormat('%b')(m_[0].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })
      // ### ------------------------------------- --------------------------

      // get min & max values of the timeline-scale
      this.timelineScale = d3.scaleTime()
      .domain([
        this.timelineValues.week[0].sDate, 
        this.timelineValues.week[this.timelineValues.week.length - 1].eDate
      ])
      .range([0, this.timelineWidth])

      this.weekSize = this.timelineScale(this.timelineValues.week[0].eDate) - this.timelineScale(this.timelineValues.week[0].sDate) - 2


      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.dataSet.forEach( (d,i) => {
        d.date = d3.timeParse('%Y-%m-%d')(d.CDATE)
        d.plan = d3.timeParse('%Y-%m-%d')(d.CDATE)
      })

      // console.log(this.timelineValues.week)
      this.timelineValues.week.forEach((w,i) => {

        // if (0 == i) return
          
          let sWeek = w.sDate
          let eWeek = w.eDate

          this.dataSet.filter(f => f.date >= sWeek && f.date < eWeek).forEach(d => {
            d.sDate = w.sDate
            d.mDate = w.mDate
            d.eDate = w.eDate
          })
      })

      this.dataSet.forEach(d => {
        let style = this.style.find(f => f.name == d.CERT)
        d.bColor = style.bColor
        d.tColor = style.tColor
      })


      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },

    
    
    
  }
}