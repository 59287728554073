<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'        

export default {
  name: "j-chart-utility-commissioning",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },

    callback: { type: Object, default: () => ({}) },
  },


  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },


  mounted() {
    this.register(this.draw)
  },


  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.set_LocalValues()
      this.drawCanvas()
      this.drawing()
      this.complete()
    },


    drawing() {
      this.set_Gradients()
      this.draw_Forms()   
      this.draw_MenuboxHeader()   
      this.drawTexts()
      this.drawNotes()
    },

  }
}
</script>



<style lang="scss" scoped>
  .svg_wrapper {
    width: 1600px;
    height: 1120px;

    overflow: hidden;
  }
</style>