import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    Draw_Title() {
      let svgCon = null
      let svgMC = null
      let svgCert = null

      let Shadow = this.innerSvg
      .append('defs')
      .append('linearGradient').attr('id', `btn`).attr('x1', 0.5).attr('y1', 0).attr('x2', 0.5).attr('y2', 1)
      Shadow.append('stop').attr('stop-color', '#e9f8ff').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#7fcdf6').attr('offset', '1.00').attr('stop-opacity', '1')

      // TITLE
      let title = this.innerSvg
      title
        .append('text')
        .attr('transform', `translate(40, 40)`)
        .style('font-family', 'roboto').style('font-size', 36).attr('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text('2020 Modules')
        
      
      title
        .append('text')
        .attr('id', 'PhaseName')
        .attr('transform', `translate(40, 77)`)
        .style('font-family', 'roboto').style('font-size', 20).attr('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text('Mechanical Completion')

      let Help = this.innerSvg
      .append('g')
      .attr('transform', `translate(700, 45)`)
      .on('mouseover', () => { 
        Help.style('cursor', 'pointer')
        d3.select('#Help')
        .transition().duration(300).attr('fill','#44A9DF')
      })
      .on('mouseout', () => { 
        Help.style('cursor', 'default')
          d3.select('#Help')
          .transition().duration(300).attr('fill','#B4E3FA')
      })
      .on('click', () => { 
        console.log('Help')
      })

      Help
      .append('path')
      .attr('id','Help')
      
      .attr('d',`M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm3.123,23.247q-1.158.457-1.848.7a4.874,4.874,0,0,1-1.6.24,3.194,3.194,0,0,1-2.18-.684,2.209,2.209,0,0,1-.776-1.736,6.306,6.306,0,0,1,
            .057-.837c.039-.284.1-.6.187-.964l.966-3.413c.085-.328.159-.639.217-.928a4.116,4.116,0,0,0,.086-.8,1.238,1.238,0,0,0-.269-.91,1.529,1.529,0,0,0-1.032-.255,2.709,2.709,0,
            0,0-.768.114c-.26.08-.486.152-.672.223l.255-1.051q.949-.387,1.816-.662A5.365,5.365,0,0,1,14.2,12a3.134,3.134,0,0,1,2.149.673,2.236,2.236,0,0,1,.754,1.747q0,.223-.052.783a5.243,5.243,0,0,
            1-.193,1.03l-.961,3.4c-.079.273-.149.585-.212.935a4.942,4.942,0,0,0-.093.795,1.142,1.142,0,0,0,.3.924,1.7,1.7,0,0,0,1.05.246,3.046,3.046,0,0,0,.795-.123,4.519,4.519,0,0,0,
            .643-.216Zm-.17-13.813a2.294,2.294,0,0,1-1.619.625,2.318,2.318,0,0,1-1.625-.625,1.994,1.994,0,0,1-.677-1.515A2.015,2.015,0,0,1,14.707,6.4a2.3,2.3,0,0,1,1.625-.631,2.276,2.276,0,0,1,
            1.619.631,2.046,2.046,0,0,1,0,3.034Z`
            )
      .attr('fill','#B4E3FA')
      Help
      .append('circle')
      .attr('cx',15).attr('cy',15).attr('r',15).attr('fill','transparent')
      


      // Menus for Construction
      let x = -581
      let y = -34 + 40
      let Menu_Const = this.innerSvg
        .append('g')
        .attr('id','menuConst')
        .attr('transform', `translate(${x+750}, ${y})`)
        .on('mouseover', () => { 
          if (this.mMode != 'CONST') {
            Menu_Const.style('cursor', 'pointer')
            d3.select('#pathConst')
            .attr('fill','url(#btn)')
          }
        })
        .on('mouseout', () => { 
          if (this.mMode != 'CONST') {
            Menu_Const.style('cursor', 'default')
            d3.select('#pathConst')
            .attr('fill','#f4f5f5')
          }
        })
        .on('click', () => { 
          this.mMode = 'CONST'
          this.chartGroup.html('')
          this.draw_Construction()

          svgCon.transition().duration(500).attr('opacity', 1)
          svgMC.transition().duration(500).attr('opacity', 0)
          svgCert.transition().duration(500).attr('opacity', 0)
          
          d3.select('#pathConst') // Selected
          .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2h13.869l15.013,15.014a2,2,0,0,0,2.829,0L630.862,87.07H748.138a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
          .attr('fill','url(#btn)')
          d3.select('#pathMC')
          .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2c-.009-.082,164.057,0,164,0a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
          .attr('fill','#f4f5f5')
          d3.select('#pathCert')
          .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2c-.009-.082,164.057,0,164,0a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
          .attr('fill','#f4f5f5')

          d3.select('#menuArrow')
          .transition().duration(500).attr('transform', `translate(${x+750}, ${y})`)
        })
      Menu_Const
        .append('path')
        .attr('id','pathConst')
        .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2h13.869l15.013,15.014a2,2,0,0,0,2.829,0L630.862,87.07H748.138a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
        .attr('stroke','#bdbcbc').attr('stroke-width',0.5).attr('fill','url(#btn)')
      
      Menu_Const
        .append('text')
        .attr('transform', `translate(595, 45)`)
        .style('font-family', 'roboto').style('font-size', 16).attr('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text('Construction')
      Menu_Const
        .append('text')
        .attr('transform', `translate(597, 68)`)
        .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text('Progress(%) by Discipline')
      
      Menu_Const
      .append('path')
      .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2c-.009-.082,164.057,0,164,0a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
      .attr('fill','transparent')

      // Menus for Mechanical Completion
      let Menu_MC = this.innerSvg
        .append('g')
        .attr('id','menuMC')
        .attr('transform', `translate(${x+950}, ${y})`)
        .on('mouseover', () => { 
          if (this.mMode != 'MC') {
            Menu_MC.style('cursor', 'pointer')
            d3.select('#pathMC')
            .attr('fill','url(#btn)')
          }
        })
        .on('mouseout', () => { 
          if (this.mMode != 'MC') {
            Menu_MC.style('cursor', 'default')
            d3.select('#pathMC')
            .attr('fill','#f4f5f5')
          }
        })
        .on('click', () => { 
          this.mMode = 'MC'
          this.chartGroup.html('')
          this.draw_Completion()

          svgCon.transition().duration(500).attr('opacity', 0)
          svgMC.transition().duration(500).attr('opacity', 1)
          svgCert.transition().duration(500).attr('opacity', 0)
          
          d3.select('#pathConst')
          .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2c-.009-.082,164.057,0,164,0a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
          .attr('fill','#f4f5f5')
          d3.select('#pathMC') // Selected
          .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2h13.869l15.013,15.014a2,2,0,0,0,2.829,0L630.862,87.07H748.138a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
          .attr('fill','url(#btn)')
          d3.select('#pathCert')
          .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2c-.009-.082,164.057,0,164,0a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
          .attr('fill','#f4f5f5')

          d3.select('#menuArrow')
          .transition().duration(500).attr('transform', `translate(${x+950}, ${y})`)
        })

      Menu_MC
        .append('path')
        .attr('id','pathMC')
        .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2c-.009-.082,164.057,0,164,0a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
        .attr('stroke','#bdbcbc').attr('stroke-width',0.5).attr('fill','#f4f5f5')
      Menu_MC
        .append('text')
        .attr('transform', `translate(595, 45)`)
        .style('font-family', 'roboto').style('font-size', 16).attr('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text('QVDs')
      Menu_MC
        .append('text')
        .attr('transform', `translate(597, 68)`)
        .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text('MC | Pre-Comm. | Destack')
      
      Menu_MC
      .append('path')
      .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2c-.009-.082,164.057,0,164,0a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
      .attr('fill','transparent')

        // Menus for Certificates
      let Menu_Cert = this.innerSvg
        .append('g')
        .attr('id','menuCert')
        .attr('transform', `translate(${x+1150}, ${y})`)
        .on('mouseover', () => { 
          if (this.mMode != 'CERT') {
            Menu_Cert.style('cursor', 'pointer')
            d3.select('#pathCert')
            d3.select('#pathCert')
            .attr('fill','url(#btn)')
          }
        })
        .on('mouseout', () => { 
          if (this.mMode != 'CERT') {
            Menu_Cert.style('cursor', 'default')
            d3.select('#pathCert')
            .attr('fill','#f4f5f5')
          }
        })
        .on('click', () => { 
          this.mMode = 'CERT'
          this.chartGroup.html('')
          this.draw_Certificates()

          svgCon.transition().duration(500).attr('opacity', 0)
          svgMC.transition().duration(500).attr('opacity', 0)
          svgCert.transition().duration(500).attr('opacity', 1)

          d3.select('#pathConst')
          .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2c-.009-.082,164.057,0,164,0a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
          .attr('fill','#f4f5f5')
          d3.select('#pathMC')
          .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2c-.009-.082,164.057,0,164,0a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
          .attr('fill','#f4f5f5')
          d3.select('#pathCert') // Selected
          .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2h13.869l15.013,15.014a2,2,0,0,0,2.829,0L630.862,87.07H748.138a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
          .attr('fill','url(#btn)')

          d3.select('#menuArrow')
          .transition().duration(500).attr('transform', `translate(${x+1150}, ${y})`)
        })

      Menu_Cert
        .append('path')
        .attr('id','pathCert')
        .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2c-.009-.082,164.057,0,164,0a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
        .attr('stroke','#bdbcbc').attr('stroke-width',0.5).attr('fill','#f4f5f5')
      Menu_Cert
        .append('text')
        .attr('transform', `translate(595, 45)`)
        .style('font-family', 'roboto').style('font-size', 16).attr('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text('Certificates')
      Menu_Cert
        .append('text')
        .attr('transform', `translate(597, 68)`)
        .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text('FDMCWD | FM-DAC1 | FMC1')

      Menu_Cert
        .append('path')
        .attr('d','M748.138,35.07h-164a2,2,0,0,0-2,2v48a2,2,0,0,0,2,2c-.009-.082,164.057,0,164,0a2,2,0,0,0,2-2v-48A2,2,0,0,0,748.138,35.07Z')
        .attr('fill','transparent')

      // Arrow & Legends
      let Menu_Arrow = this.innerSvg
      .append('g')
      .attr('id','menuArrow')
      .attr('transform', `translate(${x+750}, ${y})`)

      Menu_Arrow
        .append('path')
        .attr('d','M614.354,98.242l10.951-10.951H619.54V84.582h-9.65v2.709H603.4Z')
        .attr('fill','#333')
          
      d3.xml(`${this.__HOST_NAME_RESOURCE}/TCO/Modules/svg/Legends_Con.svg`).then(source => {
        svgCon = source.documentElement
        this.svg.node().append(svgCon)
        svgCon = d3.select(svgCon).attr('x',752).attr('y',105).attr('id','legendConst')
      })

      d3.xml(`${this.__HOST_NAME_RESOURCE}/TCO/Modules/svg/Legends_MC.svg`).then(source => {
        svgMC = source.documentElement
        this.svg.node().append(svgMC)
        svgMC = d3.select(svgMC).attr('x',960).attr('y',105).attr('id','legendConst').attr('opacity', 0)
      })

      d3.xml(`${this.__HOST_NAME_RESOURCE}/TCO/Modules/svg/Legends_Cert.svg`).then(source => {
        svgCert = source.documentElement
        this.svg.node().append(svgCert)
        svgCert = d3.select(svgCert).attr('x',1160).attr('y',105).attr('id','legendConst').attr('opacity', 0)
      })



    },
  }
}