export default {
  watch: {
    
    // Canvas:    { handler(val) { if(!val || Ob10ject.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    DataItems: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },   
    Queries:    { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Note:   { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Text:   { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    
  },
}