<template>
  <div :id='localId'></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'

export default {
  name: "j-chart-bar-solid",
  mixins: [ 
    mx_Core 
  ],
  props: {
    id: String,
    ChartType: String,

    // Chart Data
    Database: { type: Object, default: () => ({}) },
    Canvas: { type: Object, default: () => ({}) },
    Timeline: { type: Object, default: () => ({}) },
    AxisY: { type: Object, default: () => ({}) },
    Boxes: { type: Object, default: () => ({}) },
    Line: { type: Object, default: () => ({}) },
    Milestone: { type: Object, default: () => ({}) },
    Title: { type: Object, default: () => ({}) },
    Status: { type: Object, default: () => ({}) },
    Legends: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    
    // Database
    DataItems: { type: Array, default: () => ([]) },

    Values: {
      type: Object,
      default: () => ({
        milestone: [],
        note: ''
      })
    }
  },
  created() {
    this.localId = this.id || 'j-chart-skyline__' + this.safeId('')
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return

      this.clear()

      this.setDefaultValues() 
      this.setComputedDataItems()

      /* 
        ### Replaced in the inline-svg (Chart Area) ### #############################
      */
      // Canvas & Chart Area
      this.drawCanvas()               // Global

      // Add an Inline-SVG as the Chart Area - Local
      this.redrawChartAreaWSVG()

      this.drawAxisY({
        s: this.viewBox,                  // Selection where the AxisY-Wrapper(SVG Group) to be placed.
        x: 0,                             // X coordinate of the Axis wrapper(SVG Group)
        y: 0,                             // Y coordinate of the Axis wrapper(SVG Group)
        w: this.Canvas.CanvasChartWidth,  // Width of the grid-line
        h: this.Canvas.CanvasChartHeight, // Height of the Axis Y
        m: 99,                            // Max-Value for the Axis-Bound
        o: null,                          // Object Values. If not assigned, default object values will 
                                          // be applied.
                                          // {
                                          //   01. LevelDisplay: String (Y | N),
                                          //   02. LevelSpace: Number,
                                          //   03. LevelFont: String,
                                          //   04. LevelStyle: String,
                                          //   05. LevelSize: Number,
                                          //   06. LevelWeight: Number,
                                          //   07. LevelColor: String,
                                          //   08. LevelUnitSize: Number,
                                          //   09. LevelUnitFormat: String,
                                          //   10. BoundsAuto: String (Y | N),
                                          //   11. BoundsMax: Number,
                                          // }
      })
      
      // Global - Timeline 
      this.drawTimeline({
        s: this.viewBox, 
        x: 0, 
        y: 0, 
        w: this.Canvas.CanvasChartWidth
      })

      this.drawChart()                // Local  - running at the subprocess flow --->

      // 'drawMilestone' uses pre-defined scale to get X coordinate
      // instead of its own.
      this.drawMilestone({
        s: this.viewBox,              // Selection where the AxisY-Wrapper(SVG Group) to be placed.
        x: 0,                         // X coordinate of the Axis wrapper(SVG Group)
        y: this.Canvas.CanvasChartHeight,   // Y coordinate of the Axis wrapper(SVG Group)
        h: 280,                       // Height of milestone area
        scaleX: (val)=> {             // Scale Callback function for X coordinate
          return this.scaleTimelineInfo.scale(val)
        },                            // Center point of the X coordinate 
        c: this.Boxes.BoxesBoxWidth/2,                      
      })

      this.svg.select(`.milestone_area___${this.localId}`).attr('opacity', 1)
      /* ### Inline-SVG ###  ############################# End */


      this.drawTitles()                   // Global
      this.drawNotes(this.viewBox)   
      this.draw_Toolbar()
      
      this.complete()
    },

    drawChart() {
      this.draw_SkylineBoxes()
      this.draw_LineCharts()
      this.draw_Legends(0)
      // this.draw_Table()
      // this.clippingSvg()
    },
    
    redrawChartAreaWSVG() {
      if(this.viewBox) this.viewBox.remove()

      this.zoomTransformInit = {
        x: this.Canvas.CanvasChartX,
        y: this.Canvas.CanvasChartY,
        w: this.Canvas.CanvasChartWidth,
        h: this.Canvas.CanvasChartHeight,
      }

      this.viewBox = this.chartArea
      .append('svg')
      .attr('id', `svg_inline__chart_area__${this.localId}`)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasChartWidth} ${this.Canvas.CanvasChartHeight}`)
      .attr('width', this.Canvas.CanvasChartWidth)
      .attr('height', this.Canvas.CanvasChartHeight)
      // .call(this.el2Zoom)
      // .on("mousedown.zoom", null)
    }
  }
}
</script>
<style lang="stylus" scoped>
div[id^=inputFilter_wrapper] {
  position: absolute;

  input {
    width: 10rem;
    height: 1.6rem;
    font-size: 1.2rem;
  }
}
</style>
