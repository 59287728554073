<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-svg-page",
  mixins: [mx_Core],
  props: {
    id: String,
    
    // ### Necessaries for the SVG-Page ###
    Action: {
      type: Object,
      default: () => ({})
    },
    FilterValues: {
      type: Object,
      default: () => ({})
    },
    // ### ---------------------------- ###

    // Chart Data
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },

    // Database
    DataItems: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({
      })
    }
  },
  created() {
    this.localId = this.id || 'j-svg-page__' + this.safeId('')
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      this.drawTexts()
      this.drawChart()
      this.draw_Timeline2020()
     

      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
    },

    drawChart() {
      this.milestoneCharts = this.svg
      .append('g')
      .attr('transform', `translate(20,20)`)
    }

  }
}
</script>