import * as d3 from 'd3'

export default {
  methods: {
    
    setEvent_Goto() {

      // Goto_Dashboard_Area
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Dashboard_Area = (selection) => {

        selection
        .on('mouseover', () => {
          d3.select(`#MD2_Overall`).transition().duration(300).style('opacity', 0.5)
        })
        .on('mouseout', () => {
          d3.select(`#MD2_Overall`).transition().duration(300).style('opacity', 1)
        })
        .on('click', () => {

          this.SVG_DashboardOverall
          .transition().duration(750).style('opacity', 0)

          // Didplay Menu_Back
          d3.select(`#Menu_Back`).attr('opacity', 0).style('visibility', 'visible').transition().delay(750).duration(500).attr('opacity', 1)

          setTimeout(() => {
            this.removeSvg( this.SVG_DashboardOverall )// remove SVG
            this.Dashboard_Area()
            this.stage = this.STG_Dashboard_Area              ;this.StageCode() //////////////////////////// this.stage
          }, 750);
        })
      }

      // Goto_ActivateModuling
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_ActivateModuling = (selection, _area) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Dashboard_Area) return
          d3.select(`#MD2_${_area}`).transition().duration(300).style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Dashboard_Area) return
          d3.select(`#MD2_${_area}`).transition().duration(300).style('opacity', 1)
        })
        .on('click', () => {
          if(this.stage != this.STG_Dashboard_Area) return
          this.stage = this.STG_TRANS

          if (_area == 'Hull') this.Activate_Hull()
          if (_area == 'Deckbox') this.Activate_Deckbox()
          if (_area == 'Topside') this.Activate_Topside()
          
          this.SVG_DashboardArea
          .transition().duration(750).style('opacity', 0)

          setTimeout(() => {
            d3.select(`#keyPlan`).transition().duration(500).style('opacity', 1)
            this.removeSvg(this.SVG_DashboardArea)// remove SVG
            
            this.Canvas_AreaSummary(this.CurrentArea)
            this.stage = this.STG_Area_Summary            ;this.StageCode() //////////////////////////// this.stage
          }, 750);
        })
      }

      // Goto_Hull_SideBlocks
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Hull_SideBlocks = (selection) => {
        
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Area_Summary) return
          d3.select('#Overall_Hull_Group').transition().duration(100).attr('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Area_Summary) return
          d3.select('#Overall_Hull_Group').transition().duration(100).attr('opacity', 1)
        })
        .on('click', () => {
          if(this.stage != this.STG_Area_Summary) return

          this.stage = this.TRANS_Area_Summary;
          d3.select('#Overall_Hull_Group').style('cursor', 'default').attr('opacity', 1);

          // Move & Scale for Hull Overall
          this.svgModeling
          .attr('opacity', 1)
          .transition().duration(1000)
          .attr('width', Math.round(this.Canvas.CanvasWidth * 0.8))
          .attr('height', Math.round(this.Canvas.CanvasHeight * 0.8))
          .attr('x', 200).attr('y', 100)

          this.hiddenGroupID('#PIPES', 0, 500)
          this.displayGroupID('#Legends', 0, 500, 1)
          

          // Explode the side block of Hull
          this.MODULE.Hull.filter(f => f.type == 'Block').forEach(mod => {
            d3.select(`#${mod.blk}`)
            .transition().delay(1000).duration(1000).attr('transform', `translate(${mod.x2}, ${mod.y2}) scale(0.725)`)
            .style('cursor', 'pointer')
          })

          // Didplay the Codes of Side Block Codes
          this.displayGroupID('#Hull_SideBlock_Codes', 2000, 300, 1)

          // Didplay HullMask
          d3.select('#HullMask')
          .transition().delay(2000).style('visibility', 'visible')

          this.removeSvg(this.SVG_AreaSummary) // Eemove SVG

          setTimeout(() => {
            this.stage = this.STG_Side_Blocks            ;this.StageCode() //////////////////////////// this.stage
          }, 2000);
        })
      }

      // Goto_Hull_ModuleDivision
      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_Hull_ModuleDivision = (selection) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Side_Blocks) return
          d3.select(`#HullMask`).transition().duration(300).style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Side_Blocks) return
          d3.select(`#HullMask`).transition().duration(300).style('opacity', 0)
        })
        .on('click', () => {
          if(this.stage != this.STG_Side_Blocks) return

          // Hidden       : HullMask
          this.hiddenGroupID(`#HullMask`, 0, 0)

          // Hidden         : Hull_SideBlock_Codes
          this.hiddenGroupID(`#Hull_SideBlock_Codes`, 0, 300)
      
          // Hidden         : Hull SideBlocks
          this.svgModeling
          .transition()
          .duration(1200)
          .attr('width', Math.round(this.Canvas.CanvasWidth * 1))
          .attr('height', Math.round(this.Canvas.CanvasHeight * 1))
          .attr('x', 0).attr('y', 0)

          this.MODULE.Hull.filter(f => f.type == 'Block').forEach(mod => {
            this.hiddenGroupID(`#${mod.blk}`, 0, 300)
          })

          // Explode         : Hull Module
          this.MODULE.Hull.filter(f => f.type == 'Module').forEach(mod => {
            d3.select(`#${mod.module}`)
            .transition().duration(1200).attr('transform', `translate(${mod.x2}, ${mod.y2}) scale(${this.ScaleModule[this.CurrentArea]})`)
          })

          // Display         : Module_No_Group
          this.displayGroupID('#Module_No_Group', 1200, 300, 1)

          // Canvas           : ModuleDivision
          setTimeout(() => {
            this.Canvas_ModuleDivision()
          }, 1500);

          setTimeout(() => {
            this.stage = this.STG_Module_Division             ;this.StageCode() //////////////////////////// this.stage

            this.MODULE.Hull.filter(f => f.type == 'Module').forEach(mod => {
              d3.select(`#${mod.module}`)
              .style('cursor', 'pointer')
            })
          }, 1500);
        })
      }

      // Goto_ModuleSummary
      // --------------------------------------------------------------------------------------------------------------------- 
      this.Goto_ModuleSummary = (selection, data) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Module_Division) return
          d3.select(`#${data.module}`).transition().duration(150).attr('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Module_Division) return
          d3.select(`#${data.module}`).transition().duration(150).attr('opacity', 1)
        })
        .on('click', () => {
          if(this.stage != this.STG_Module_Division) return
          
          this.Module = data.module
          this.stage = this.STG_TRANS
          d3.select(`#${data.module}`).transition().attr('opacity', 1)
          
          setTimeout(() => {
            // Hide    : Unselected Module
            this.MODULE[this.CurrentArea].filter(f => f.type == 'Module').forEach(d => {
              if (d.module != this.Module) {
                this.hiddenGroupID(`#${d.module}`, 500, 500, 0)
                this.hiddenGroupID(`#Key_${d.module}`, 0, 500, 0)
              } else {
                this.displayGroupID(`#Key_${d.module}`, 0, 500, 0.5)
              }
            })
            
            // Hide    : Module Codes
            this.hiddenGroupID('#Module_No_Group', 0, 500)
            
            // Scale    : Selected Module
            d3.select(`#${this.Module}`)
            .transition().duration(1200)
            .attr('transform', `translate(${data.x3},${data.y3}) scale(${this.ScaleSummary[this.CurrentArea]})`).style('cursor', 'default')

            // Display    : Module_MenuSet
            d3.select('#Mod_Name').text(this.Module)
            d3.select('#Mod_Descr').text(`${this.dataSet.find(f=> f.BLOCK == this.Module).DESCR}`)
            this.displayGroupID('#Module_MenuSet', 1000, 300, 1)
            
            this.removeSvg(this.SVG_ModuleDivision) // remove SVG
          }, 250)

          setTimeout(() => {
            this.stage = this.STG_Module_Summary            ;this.StageCode() //////////////////////////// this.stage
            this.Canvas_ModuleSummary()
          }, 1500)
        })
      }

      // ---------------------------------------------------------------------------------------------------------------------
      this.Goto_BlockDivision = (selection) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Module_Summary) return
          d3.select(`#Menu_BlockDivision`).transition().duration(200).attr('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Module_Summary) return
          d3.select(`#Menu_BlockDivision`).transition().duration(200).attr('opacity', 1)
        })

        .on('click', () => {
          if(this.stage != this.STG_Module_Summary) return

          this.stage = this.TRANS_Module_Summary

          // Scale       : Current Module
          d3.select(`#${this.Module}`)
          .transition()
          .duration(1200)
          .attr('transform', `translate(0, 0) scale(1)`)
          
          // Move       : Block Images
          this.BLOCKS[this.CurrentArea].filter(f => f.module == this.Module).forEach(d => {
            if (d.blk != `${this.Module}_P`) {
              d3.select(`#${d.blk}`)
              .transition().duration(1200)
              .attr('transform', `translate(${d.x2}, ${d.y2}) scale(${this.ScaleBlock[this.CurrentArea]})`)

              d3.select(`#${d.blk}`)
              .transition().delay(1200).style('cursor', 'pointer')
            } else {
              d3.select(`#${d.blk}`)
              .transition().duration(500).style('opacity', 0)
              .transition().delay(500).style('visibility', 'hidden')
            }
          })

          // Display    : Block Codes
          this.displayGroupID(`#${this.Module}_No_Group`, 1200, 300, 1)

          // Setting      : Menu Group 
          d3.select(`#Menu_BlockDivision`)
          .transition().duration(100).attr('opacity', 0.5).style('cursor', 'default')

          d3.select('#Module_MenuSet')
          .transition().duration(1000).attr('transform', `translate(400, 72)`)

          // Hide       :SVG_ModuleSummary
          this.SVG_ModuleSummary
          .transition()
          .duration(750).style('opacity', 0)

          setTimeout(() => {
            this.stage = this.STG_Block_Division            ;this.StageCode() //////////////////////////// this.stage
            this.removeSvg(this.SVG_ModuleSummary) // remove SVG
          }, 1500)
        })
      }


      
      this.Goto_Unit_Block = (selection, d) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Block_Division) return
          d3.select(`#${d.blk}`).transition().duration(200).attr('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Block_Division) return
          d3.select(`#${d.blk}`).transition().duration(200).attr('opacity', 1)
        })

        .on('click', () => {
          console.log(d.blk)
        })
      }



    },
  }
}