import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
    
  }),


  methods: {
    
    drawValues() {

      let drawX = 0
      this.dataSet.forEach((data,i) => {

       
        //Summary
        let summary = this.ModuleStatus
        .append('g')
        .attr('transform', `translate(${drawX + 40},220)`)

          // A1_T
          summary
          .append('text')
          .attr('id', `A1_T_${i}`)
          .attr('transform', `translate(145,31)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.A1_T)
          .style('cursor', 'pointer')
          .on('mouseover', () => {
            d3.select(`#A1_T_${i}`).transition().duration(100).attr('transform', `translate(${145 + (this.getNodeElValue(`#A1_T_${i}`,'width')/6)},31) scale(1.3)`)
          })
          .on('mouseout', () => {
            d3.select(`#A1_T_${i}`).transition().duration(100).attr('transform', `translate(145,31)`)
          })
          .on('click', () => {
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 188,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : `CHK_TYPE = 'A1'`,
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })

           // A1_R
          summary
          .append('text')
          .attr('id', `A1_R_${i}`)
          .attr('transform', `translate(185,31)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.A1_R)
          .style('cursor', 'pointer')
          .on('mouseover', () => {
            if (data.A1_R > 0) d3.select(`#A1_R_${i}`).transition().duration(100).attr('transform', `translate(${185 + (this.getNodeElValue(`#A1_R_${i}`,'width')/6)},31) scale(1.3)`)
          })
          .on('mouseout', () => {
            if (data.A1_R > 0) d3.select(`#A1_R_${i}`).transition().duration(100).attr('transform', `translate(185,31)`)
          })
          .on('click', () => {
            if (data.A1_R <= 0) return
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 188,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : "CHK_TYPE = 'A1' AND QVD_STATUS = 'Not Started' AND EXCEPT_NO = 'N'",
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })

           // A1_PCOW
          summary
          .append('text')
          .attr('id', `A1_PCOW_${i}`)
          .attr('transform', `translate(230,31)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.A1_PCOW)
          .style('cursor', () => { if (data.A1_PCOW > 0) return 'pointer'})
          .on('mouseover', () => {
            if (data.A1_PCOW > 0) d3.select(`#A1_PCOW_${i}`).transition().duration(100).attr('transform', `translate(${230 + (this.getNodeElValue(`#A1_PCOW_${i}`,'width')/6)},31) scale(1.3)`)
          })
          .on('mouseout', () => {
            if (data.A1_PCOW > 0) d3.select(`#A1_PCOW_${i}`).transition().duration(100).attr('transform', `translate(230,31)`)
          })
          .on('click', () => {
            if (data.A1_PCOW <= 0) return
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 188,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : "CHK_TYPE = 'A1' AND QVD_STATUS = 'Not Started' AND EXCEPT_NO = 'Y'",
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })





          // Punch A
          summary
          .append('text')
          .attr('id', `PA_${i}`)
          .attr('transform', `translate(185,51)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#fff').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.PA)
          .style('cursor', () => { if (data.PA > 0) return 'pointer'})
          .on('mouseover', () => {
            if (data.PA > 0) d3.select(`#PA_${i}`).transition().duration(100).attr('transform', `translate(${185 + (this.getNodeElValue(`#PA_${i}`,'width')/6)},51) scale(1.3)`)
          })
          .on('mouseout', () => {
            if (data.PA > 0) d3.select(`#PA_${i}`).transition().duration(100).attr('transform', `translate(185,51)`)
          })
          .on('click', () => {
            if (data.PA <= 0) return
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 187,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : "CATEGORY= 'A' AND STATUS = 'Not Started PL' AND EXCEPT_NO = 'N'",
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })

          summary
          .append('text')
          .attr('id', `PA_PCOW_${i}`)
          .attr('transform', `translate(230,51)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.PA_PCOW)
          .style('cursor', () => { if (data.PA_PCOW > 0) return 'pointer'})
          .on('mouseover', () => {
            if (data.PA_PCOW > 0) d3.select(`#PA_PCOW_${i}`).transition().duration(100).attr('transform', `translate(${230 + (this.getNodeElValue(`#PA_PCOW_${i}`,'width')/6)},51) scale(1.3)`)
          })
          .on('mouseout', () => {
            if (data.PA_PCOW > 0) d3.select(`#PA_PCOW_${i}`).transition().duration(100).attr('transform', `translate(230,51)`)
          })
          .on('click', () => {
            if (data.PA_PCOW <= 0) return
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 187,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : "CATEGORY= 'A' AND STATUS = 'Not Started PL' AND EXCEPT_NO = 'Y'",
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })





          // Punch B
          summary
          .append('text')
          .attr('id', `PB_${i}`)
          .attr('transform', `translate(185,66)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#fff').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.PB)
          .style('cursor', () => { if (data.PB > 0) return 'pointer'})
          .on('mouseover', () => {
            if (data.PB > 0) d3.select(`#PB_${i}`).transition().duration(100).attr('transform', `translate(${185 + (this.getNodeElValue(`#PB_${i}`,'width')/6)},66) scale(1.3)`)
          })
          .on('mouseout', () => {
            if (data.PB > 0) d3.select(`#PB_${i}`).transition().duration(100).attr('transform', `translate(185,66)`)
          })
          .on('click', () => {
            if (data.PB <= 0) return
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 187,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : "CATEGORY= 'B' AND STATUS = 'Not Started PL' AND EXCEPT_NO = 'N'",
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })

          summary
          .append('text')
          .attr('id', `PB_PCOW_${i}`)
          .attr('transform', `translate(230,66)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.PB_PCOW)
          .style('cursor', () => { if (data.PB_PCOW > 0) return 'pointer'})
          .on('mouseover', () => {
            if (data.PB_PCOW > 0) d3.select(`#PB_PCOW_${i}`).transition().duration(100).attr('transform', `translate(${230 + (this.getNodeElValue(`#PB_PCOW_${i}`,'width')/6)},66) scale(1.3)`)
          })
          .on('mouseout', () => {
            if (data.PB_PCOW > 0) d3.select(`#PB_PCOW_${i}`).transition().duration(100).attr('transform', `translate(230,66)`)
          })
          .on('click', () => {
            if (data.PB_PCOW <= 0) return
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 187,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : "CATEGORY= 'B' AND STATUS = 'Not Started PL' AND EXCEPT_NO = 'Y'",
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })






          // B1
          summary
          .append('text')
          .attr('id', `B1_T_${i}`)
          .attr('transform', `translate(145,90)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.B1_T)
          .style('cursor', () => { if (data.B1_T > 0) return 'pointer'})
          .on('mouseover', () => {
            d3.select(`#B1_T_${i}`).transition().duration(100).attr('transform', `translate(${145 + (this.getNodeElValue(`#B1_T_${i}`,'width')/6)},90) scale(1.3)`)
          })
          .on('mouseout', () => {
            d3.select(`#B1_T_${i}`).transition().duration(100).attr('transform', `translate(145,90)`)
          })
          .on('click', () => {
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 188,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : "CHK_TYPE = 'B1' AND QVD_STATUS = 'Not Started' AND EXCEPT_NO = 'N'",
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })

          summary
          .append('text')
          .attr('id', `B1_R_${i}`)
          .attr('transform', `translate(185,90)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.B1_R)
          .style('cursor', () => { if (data.B1_R > 0) return 'pointer'})
          .on('mouseover', () => {
            if (data.B1_R > 0) d3.select(`#B1_R_${i}`).transition().duration(100).attr('transform', `translate(${185 + (this.getNodeElValue(`#B1_R_${i}`,'width')/6)},90) scale(1.3)`)
          })
          .on('mouseout', () => {
            if (data.B1_R > 0) d3.select(`#B1_R_${i}`).transition().duration(100).attr('transform', `translate(185,90)`)
          })
          .on('click', () => {
            if (data.B1_R <= 0) return
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 188,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : "CHK_TYPE = 'B1' AND QVD_STATUS = 'Not Started' AND EXCEPT_NO = 'N'",
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })

          summary
          .append('text')
          .attr('id', `B1_PCOW_${i}`)
          .attr('transform', `translate(230,90)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.B1_PCOW)
          .style('cursor', () => { if (data.B1_PCOW > 0) return 'pointer'})
          .on('mouseover', () => {
            if (data.B1_PCOW > 0) d3.select(`#B1_PCOW_${i}`).transition().duration(100).attr('transform', `translate(${230 + (this.getNodeElValue(`#B1_PCOW_${i}`,'width')/6)},90) scale(1.3)`)
          })
          .on('mouseout', () => {
            if (data.B1_PCOW > 0) d3.select(`#B1_PCOW_${i}`).transition().duration(100).attr('transform', `translate(230,90)`)
          })
          .on('click', () => {
            if (data.B1_PCOW <= 0) return
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 188,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : "CHK_TYPE = 'B1' AND QVD_STATUS = 'Not Started' AND EXCEPT_NO = 'Y'",
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })




          
          // D1
          summary.append('text')
          .attr('id', `D1_T_${i}`)
          .attr('transform', `translate(145,116)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.D1_T)
          .style('cursor', () => { if (data.D1_T > 0) return 'pointer'})
          .on('mouseover', () => {
            if (data.D1_T > 0) d3.select(`#D1_T_${i}`).transition().duration(100).attr('transform', `translate(${145 + (this.getNodeElValue(`#D1_T_${i}`,'width')/6)},116) scale(1.3)`)
          })
          .on('mouseout', () => {
            if (data.D1_T > 0) d3.select(`#D1_T_${i}`).transition().duration(100).attr('transform', `translate(145,116)`)
          })
          .on('click', () => {
            if (data.D1_T <= 0) return
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 188,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : "CHK_TYPE = 'D1' AND QVD_STATUS = 'Not Started' AND EXCEPT_NO = 'N'",
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })

          summary
          .append('text')
          .attr('id', `D1_R_${i}`)
          .attr('transform', `translate(185,116)`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(data.D1_R)
          .style('cursor', () => { if (data.D1_R > 0) return 'pointer'})
          .on('mouseover', () => {
            if (data.D1_R > 0) d3.select(`#D1_R_${i}`).transition().duration(100).attr('transform', `translate(${185 + (this.getNodeElValue(`#D1_R_${i}`,'width')/6)},116) scale(1.3)`)
          })
          .on('mouseout', () => {
            if (data.D1_R > 0) d3.select(`#D1_R_${i}`).transition().duration(100).attr('transform', `translate(185,116)`)
          })
          .on('click', () => {
            if (data.D1_R <= 0) return
            let request_ = {
              dataType: 'row',
              action: {
                type: 'direct',
                target: 'slide-modal',
                component: 'ServiceDatatable',
                id: 188,
                no: ''
              },
              filters     : {
                MOD: data.MOD,
              },
              iFilters    : {
                filterString : "CHK_TYPE = 'D1' AND QVD_STATUS = 'Not Started' AND EXCEPT_NO = 'N'",
                inputFilter  : ''
              }
            }
            this.$emit('request-action', request_)
          })

        this.Sailaway(data.MOD, drawX)
        drawX += 308
      })
    },


  }
}
