import * as d3 from 'd3'

export default {
  data: () => ({
    innerSvg: null,
    
    style: null,
    dataSet: null,
    linkStatus: null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      let s_ = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      let d_ = JSON.parse(JSON.stringify(this.DataItems[0]))

      this.style = {
        width:        s_.WIDTH,
        padding:      s_.PADDING,
        plusY:        s_.PLUS_Y,

        box: {
          width:      s_.BOX_WIDTH,
          height:     s_.BOX_HEIGHT,
          padding:    s_.BOX_PADDING,
          sColor:     s_.BOX_STROKE_COLOR,
          sSize:      s_.BOX_STROKE_SIZE,
          xColor:     s_.BOX_FILL_COLOR,
          opacity:    s_.BOX_OPACITY,
          radius:     s_.BOX_RADIUS,
        },

        text: {
          align:      s_.TEXT_ALIGN,
          fColor:     s_.TEXT_COLOR,
          fSize:      s_.TEXT_SIZE,
          fWeight:    s_.TEXT_WEIGHT,
        },

        value: {
          fSize:      s_.VALUE_TEXT_SIZE,
          fWeight:    s_.VALUE_TEXT_WEIGHT,
          price:      s_.VALUE_PRICE,
          unit:       s_.VALUE_UNIT,
        }
  
      }

      this.dataSet = {
        count: d_.COUNT,
        text:   [],
        value:  [],
        color:  [],
        width:  [],
        y:      [],
        unit:   [],
      }

      for(let i=0; i < this.dataSet.count; i++) {
        this.dataSet.text[i] = d_[`TEXT${i+1}`]
        this.dataSet.value[i] = d_[`VALUE${i+1}`]
        this.dataSet.color[i] = d_[`COLOR${i+1}`]
      }

      this.dataSet.text.forEach( (d,i)=> {
        if(d.substr(d.length-1, 1) == '$' || d.substr(d.length-1, 1) == '%') {
          this.dataSet.unit[i] = d.substr(d.length-1, 1)
          this.dataSet.text[i] = d.slice(0, -1)
        } else {
          this.dataSet.unit[i] = ''
        }

      })
      
      this.Canvas.CanvasWidth = s_.WIDTH
      this.Canvas.CanvasHeight =  (s_.PADDING * 2 ) + 
                                  (s_.BOX_HEIGHT * d_.COUNT) + 
                                  (s_.BOX_PADDING * (d_.COUNT - 1))
    },

  }
}