import * as d3 from 'd3'

export default {
  data: () => ({

  }),

  computed: {

  },


  methods: {

    draw_DropdownMenu() {

      let boxSize = 150

      let dropdownMenu = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.skyline.dropdown.x},${this.skyline.dropdown.y})`)

          let dropdown = dropdownMenu
          .append('g')
          .attr('transform', `translate(${0}, ${0})`)

              dropdown
              .append('rect')
              .attr('id', `dDBox__${this.localId}`)
              .attr('x', 0).attr('y', 0).attr('width', boxSize).attr('height', 18)
              .attr('fill', '#fff').attr('stroke','#bcbcbc').attr('stroke-width',0.5)

              dropdown
              .append('text')
              .attr('id', `dDTitle__${this.localId}`)
              .attr('x', 5).attr('y', 4.5)
              .attr('fill', '#44A9DF') .style('font-size', 12).style('font-family', 'roboto').attr('font-weight', 500)
              .attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
              .text(this.skyline.dropdown.option.find(f => f.value == this.activity).text)
              
              dropdown
              .append('image')
              .attr('id', `dDArrow__${this.localId}`)
              .attr('xlink:href', require('../../../../../../src/assets/svg/select_arrow_down.svg'))
              .attr('x', boxSize -14).attr('y', 5).attr('width', 9).attr('height', 9)

              dropdown
              .on('mouseover', () => { 
                dropdown.style('cursor', 'pointer')
                d3.select(`#dDTitle__${this.localId}`).attr('fill','#44A9DF')
                d3.select(`#dDBox__${this.localId}`).attr('stroke','#44A9DF')
              })
              .on('mouseout', () => { 
                dropdown.style('cursor', 'default')
                d3.select(`#dDTitle__${this.localId}`).attr('fill','#44A9DF')
                d3.select(`#dDBox__${this.localId}`).attr('stroke','#bcbcbc')
              })
              .on('click', () => { 
                if (document.getElementById(`dDOptions__${this.localId}`).style.visibility == 'hidden') {
                  dDOptions.style('visibility', 'visible')
                  d3.select(`#dDArrow__${this.localId}`).attr('xlink:href', require('../../../../../../src/assets/svg/select_arrow_up.svg'))
                } else {
                  dDOptions.style('visibility', 'hidden')
                  d3.select(`#dDArrow__${this.localId}`).attr('xlink:href', require('../../../../../../src/assets/svg/select_arrow_down.svg'))
                }
              })

          let dDOptions = dropdownMenu
          .append('g')
          .attr('id',`dDOptions__${this.localId}`)
          .style('visibility', 'hidden')
          .attr('transform', `translate(0, ${20})`)
          
              dDOptions
              .append('rect')
              .attr('x', 0).attr('y', 0).attr('width', boxSize).attr('height', (19*this.skyline.dropdown.option.length)+1)
              .attr('fill', '#fff').attr('stroke','#bcbcbc').attr('stroke-width',0.5)
              
              this.skyline.dropdown.option.forEach((item, i) => {
                // if (i == 0) return
                dDOptions
                .append('rect')
                .attr('id', `optionRect__${this.localId}_${i}`).attr('fill', '#fff')
                .attr('x', 1).attr('y', (i * 19)+1).attr('width', boxSize-2).attr('height', 18)
                
                dDOptions
                .append('text')
                .attr('id', `optionText__${this.localId}_${i}`)
                .attr('x', 10).attr('y', (i * 19)+11).attr('fill', '#757575') .style('font-size', 11).style('font-family', 'roboto')
                .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
                .text(this.skyline.dropdown.option[i].text)

                dDOptions
                .datum(item)
                .append('rect')
                .attr('id', `optionBtn__${this.localId}_${i}`)
                .attr('x', 1).attr('y', i * 19).attr('width', boxSize-2).attr('height', 18)
                .attr('fill', 'transparent').attr('stroke','transparent').attr('stroke-width',0.5)
                .on('mouseover', () => { 
                  dDOptions.style('cursor', 'pointer')
                  d3.select(`#optionText__${this.localId}_${i}`).attr('fill','#ffff')
                  d3.select(`#optionRect__${this.localId}_${i}`).attr('fill','#83D2F5')
                })
                .on('mouseout', () => { 
                  dDOptions.style('cursor', 'default')
                  d3.select(`#optionText__${this.localId}_${i}`).attr('fill','#757575')
                  d3.select(`#optionRect__${this.localId}_${i}`).attr('fill','transparent')
                })
                .on('click', (d) => { 
                  d3.select(`#dDTitle__${this.localId}`).text(this.skyline.dropdown.option[i].text)
                  dDOptions.style('visibility', 'hidden')
                  dDOptions.style('visibility', 'hidden')
                  d3.select(`#dDArrow__${this.localId}`).attr('xlink:href', require('../../../../../../src/assets/svg/select_arrow_down.svg'))

                  this.activity = d.value
                  this.draw()
                  console.log(this.activity)
                })
              })
    
    }, 

  }
}
