import * as d3 from 'd3'
import { scaleBand } from 'd3'
import __M from 'moment'
// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    d3.select('.tooltip').remove()
  },
  methods: {
    Draw_Content() {
      this.drawMonthly()
    },
    drawMonthly(){
      let monthly = this.svg
      .append('g')
      .attr('id', 'monthly')
      .attr('transform', `translate(0, 0)`)
      .style('font-family','roboto')

      //---------------------------------------------------------------TITLE
      let today_date = __M(new Date()).format('YYYY-MM-DD')
      let today = new Date(__M(today_date + ' ' + '00:00'))  // new date 하니까 시간이 생성되어서 dataset에 있는 것과 날짜는 같지만 작은걸로 나옴! new data를 변환해줘야함
      // let today = new Date(today_f)
      let year = today.getFullYear();
      let month = this.FilterValues && this.FilterValues.MONTH ? this.FilterValues.MONTH != 'Previous' ? __M(this.FilterValues.MONTH, 'MMM').format('MMMM') : this.FilterValues.MONTH : __M(new Date).format('MMMM')
      
      // let monthformet = this.monthDate.filter(d => d.MONTH == this.DataItems[0].MONTH)

      monthly
      .append('text')
      .attr('x', this.Canvas.CanvasWidth/2).attr('y', 80)
      .style('font-size',28).attr('text-anchor', 'middle').attr('fill', '#000').attr('font-weight','700')
      .text('Tortue FPSO')
      
      monthly
      .append('text')
      .attr('x', this.Canvas.CanvasWidth/2-140).attr('y', 120)
      .style('font-size',28).attr('text-anchor', 'end').attr('fill', '#000').attr('font-weight','700')
      .text(month)
      //------------------이번달, 이번년도 구하기
      monthly
      .append('text')
      .attr('x', this.Canvas.CanvasWidth/2-100).attr('y', 120)
      .style('font-size',28).attr('text-anchor', 'middle').attr('fill', '#000').attr('font-weight','700')
      .text(year)
      monthly
      .append('text')
      .attr('x', this.Canvas.CanvasWidth/2+90).attr('y', 120)
      .style('font-size',28).attr('text-anchor', 'middle').attr('fill', '#000').attr('font-weight','700')
      .text('Milestones & Objectives')
      //---------------------------------------------------------------LOGO
      let logo = this.svg
      .append('g')
      .attr('id', 'logo')
      .attr('transform', `translate(${this.Canvas.CanvasWidth-290}, 15)`)
      .append('image')
      .attr('opacity', 1)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Milestones/milestoneLogo.png`)

      //---------------------------------------------------------------LEGEND
      let legend = this.svg
      .append('g')
      .attr('id', 'legend')
      .attr('transform', `translate(20, 70)`)

      this.legend.forEach((d, i)=>{
      legend
      .append('rect')
      .attr('x', 0.5).attr('y', 24*i).attr('width', 18).attr('height', 18)  
      .attr('fill', d.COLOR).attr('stroke','#707070').attr('stroke-width', .3)
      // .attr('rx', 1).attr('ry', 1)
      legend
      .append('text')
      .attr('x', 26).attr('y', 24*i+14)
      .style('font-size',12).attr('text-anchor', 'start').attr('fill', '#000')
      .text(d.CATEGORY)
      })
      //---------------------------------------------------------------LEGEND STATUS
      
      let status = this.svg
      .append('g')
      .attr('class', 'legendStatus')
      .attr('transform', `translate(120, 280)`)

      this.status.forEach((d, i)=>{
      status
      .append('path')
      .attr('transform', `translate(${160*i}, 0) scale(.8)`)
      .attr('d', `M565.648-156.75l-5.609,19.97H538.5l16.379,12.341-5.385,21.092,16.154-12.565,15.706,12.565-4.936-21.092,17.725-12.341H572.379Z`)
      .attr('fill',d.COLOR)
      status
      .append('text')
      .attr('transform', `translate(${160*i+479}, -98)`)
      .style('font-size',12).attr('text-anchor', 'start').attr('fill', '#000')
      .text(d.TEXT)
      })

      //tooltip 만들기
      let div = d3.select('body').append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('opacity', 0)
      .style('padding', 10+'px')
      .style('font-size', 11+'px')
      .style('background-color','#E0E0E0')

      //tooltip 만들기
      let addDiv = d3.select('body').append('div')	
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('opacity', 0)
      .style('padding', 10+'px')
      // .style('transform', 'rotate(-50deg)')
      .style('color', '#2E7D32')
      .style('font-size', 11+'px')
      .style('background-color','#E1F4FF');

      //-------------------------------------------------------------------------------------------------------MONTHLY RECT
      let monthlyRectWrap = this.svg
      .append('g')
      .attr('id', 'monthlyRectwrap')
      .attr('transform', `translate(20, 40)`)
      let row = 0
      this.DataItems.filter(d => {
        if (month != 'Previous') return __M(d.MONTH, 'MMM').format('MMMM') == month
        else return d.MONTH == month
      }).forEach((d, i)=>{
        // console.log(d.ITEM_STATUS != 'Completed') 

        // rect가 5개씩 나오고 밑으로 다음줄로 그려져야하니까 이렇게 하자!
        let seq = i % 5
        row = seq == 0 ? row +1 : row
        let X = i % 5 == 0 ? 0 : (i % 5) * 295
        let Y = 180 * row
        // console.log(i % 5, d.CODE, row)

        let monthlyRect = monthlyRectWrap
        .append('g')
        .attr('class', 'monthlyRect')
        .attr('transform', `translate(${X}, ${Y})`)
  
        let rectWidth = 270
        let rectheight = 150
        
        let rectColor = this.legend.find(c => c.CATEGORY == d.CATEGORY)
        // console.log(rectColor, d.CATEGORY)
        let mlv2 = this.Queries.SQL1.find(l => l.CODE == d.CODE) 
        // console.log(mlv2.ITEM_DUE_DATE == null)
        
        //-------------------------------------------------rect 모양 다르게 하기 위해서 조건 넣어준다
        if(mlv2.ITEM_DUE_DATE != null){
          monthlyRect
          .append('rect')
          .attr('x', 0.5).attr('y', 24).attr('width', rectWidth).attr('height', 150)  
          .attr('fill', rectColor.COLOR).attr('stroke', d.BORDER == null ? '#707070':'#FF0000').attr('stroke-width',  d.BORDER == null ? 1:3)
  
          monthlyRect
          .append('path')
          .attr('transform', `translate(${rectWidth-80}, 24.5)`)
          .attr('d', `M80 80 L80 0 L0 0 Z`)
          .attr('stroke', '#707070').attr('stroke-width', .5)
          .attr('fill','#E1F4FF')

          monthlyRect 
          .append('circle')
          .attr('cx', rectWidth-14).attr('cy', rectheight).attr('r', 30)
          .attr('stroke','#707070').attr('stroke-width', 1).attr('fill', '#ffffff')
        }else{
          monthlyRect
          .append('rect')
          .attr('x', 0.5).attr('y', 24).attr('width', rectWidth).attr('height', 150)  
          .attr('fill', rectColor.COLOR).attr('stroke', d.BORDER == null ? '#C35B15':'#FF0000').attr('stroke-width',  d.BORDER == null ? 1:3)

          monthlyRect 
          .append('circle')
          .attr('cx', rectWidth-14).attr('cy', rectheight).attr('r', 30)
          .attr('stroke','#C35B15').attr('stroke-width', 1).attr('fill', '#ffffff')
        }

        //--------------------------------------------------------------------------------------------PROG
        let progressBar = monthlyRect
        .append('g')
        .attr('class', 'progbar')
        .attr('transform', `translate(5, 150)`)

        let startX = 0
        let length = 210, gapSize = 5 //chart width= 210 
        let condition  = d.ITEM_STATUS != 'Completed' && mlv2.ITEM_REV=='C' && new Date(mlv2.ITEM_DUE_DATE) < today

        if(d.PROG >= 0){
          this.Arrow_MilestoneProg(progressBar, startX, ((d.PROG/100)*length), d.PROG, 20, 10, condition, 500)

          progressBar
          .append('text')
          .style('font-family', 'roboto').style('font-size', 14).text(`${d.PROG}%`).style('opacity', 0)
          .style('fill', () => {
            if(d.PROG < 30) return '#212121'
            if(d.PROG < 50) return '#ffffff'
            if(d.PROG < 70) return '#212121'
            if(d.PROG < 90) return '#212121'
            if(d.PROG <= 100) return '#ffffff'
          })
          .attr('transform', () => {
            if(d.PROG > 31) return `translate(${startX+(d.PROG/100)*length-9}, 15)`; else return `translate(${startX+(d.PROG/100)*length+5}, 15)`
          })
          .style('text-anchor', () => {
            if(d.PROG > 31) return 'end'; else return 'start'
          })
          .transition().duration(500)
          .style('opacity', 1)
        }

        // console.log(new Date(mlv2.ITEM_DUE_DATE) < today) //날짜를 비교할때는 이렇게 해야 비교가 된다---조건 추가 (지난것들 날짜빨간색으로)

        let itemduedates = this.Queries.SQL1.filter(r => r.CODE === d.CODE && r.ITEM_REV !== 'C') 
        // let elItemId = `Item_${r.CODE}_${ri}`

        // console.log(new Date(mlv2.ITEM_DUE_DATE))
        // console.log(today)
        monthlyRect
        .append('text')
        .attr('transform', `translate(${rectWidth-20},48),rotate(-50)`)
        .style('font-size',12).attr('text-anchor', 'middle').attr('font-weight','700')
        .attr('fill', d.ITEM_STATUS != 'Completed' && mlv2.ITEM_REV=='C' && new Date(mlv2.ITEM_DUE_DATE) < today ? '#FF0000' : '#2E7D32')
        .text(mlv2.ITEM_DUE_DATE ? __M(mlv2.ITEM_DUE_DATE).format('DD MMM'): '') //날짜 변환하는 방법
        .attr('cursor', itemduedates.length > 0 ? 'pointer' :'default')
        .on('click', () => {
          
          if(itemduedates.length == 0) return
          let dates = itemduedates.map(d => __M(d.ITEM_DUE_DATE).format('DD MMM'))
          // console.log(d3.event)
          // console.log(duedates)
          addDiv.transition()		
          .duration(100)
          .style('opacity', 1)
              
          addDiv.html(dates.join(', <br>'))	
          .style('left', (d3.event.clientX) + 'px')		
          .style('top', (d3.event.clientY) + 'px')
          .style('line-height', 1.5)
          })					
          .on('mouseout', () =>{		
            addDiv
            // .html('')
            .style('opacity', 0)
          });

        monthlyRect //ITEM 글자수 일정부분 자르기 위해서 html div를 넣어준다
        .append('g')
        .attr('transform', `translate(10, 34)`)
        .append('foreignObject')
        .attr('width', '200px')
        .attr('height', '150px')
        .append('xhtml:body')
        .append('div')
        .style('font-weight', 700)
        .style('font-size', 15+'px')
        .style('text-align', 'start')
        .style('line-height', 1.2)
        .style('color', '#000000')
        .html(d.ITEM)

        let Itemlength = d.ITEM.length
        let mlv3 = this.Queries.SQL2.filter(l => l.CODE == d.CODE && l.SUBITEM_REV ==='C') 
        
        mlv3.forEach((m, mi) =>{
          let duedates = this.Queries.SQL2.filter(d => d.CODE === m.CODE && d.SUBITEM_REV !== 'C' && m.SUBITEM == d.SUBITEM) 
          let elId = `subItem_${m.CODE}_${mi}` //각자의 id값이 있어야한다
          let subDueDate = m.SUBITEM_DUE_DATE ? __M(m.SUBITEM_DUE_DATE).format('DD/MM') : ''

          monthlyRect
          .append('text')//ITEM의 길이에 따라 SUBITEM 높이가 달라져야하기때문에 length구해서 'y'다르게
          .attr('id', elId)
          .attr('cursor', duedates.length > 0 ? 'pointer' :'default')
          .attr('transform', `translate(10, ${Itemlength < 28 ? 18*mi +70 : 28 <= Itemlength < 47 ? 18*mi +90 :18*mi +110})`)
          .style('font-size',12).attr('text-anchor', 'start').attr('fill','#000000')
          .text(m.SUBITEM_REV ==='C'? m.SUBITEM + ' ' + subDueDate :'')
          .on('click', () => {
            if(duedates.length == 0) return
            let dates = duedates.map(d => elId && __M(d.SUBITEM_DUE_DATE).format('DD/MM'))
            div.transition()		
                .duration(100)		
                .style('opacity', .9)
                
            div.html(dates.join(', <br>'))	
                .style('left', (d3.event.clientX) + 'px')		
                .style('top', (d3.event.clientY) + 'px')
                .style('line-height', 1.5)
            })					
            .on('mouseout', () => {		
                div
                // .html('')
                // .transition()		
                // .duration(500)		
                .style('opacity', 0)
            });

          let textEl = document.getElementById(elId).getBoundingClientRect()
          //이렇게 하면 안에 어떤것 있는지 위치 등 알수 있음
          let textWidth = textEl.width

          // SubItem 과 SubDueDate 하고나서 Sub Status 값이 있을때 circie 과 png 넣기
          if (!m.SUBITEM_STATUS) return 
          monthlyRect 
          .append('circle')
          .attr('cx', textWidth + 24).attr('cy', `${Itemlength < 28 ? 18*mi +65 : 28 <= Itemlength < 47 ? 18*mi +85 :18*mi +105}`).attr('r', 8)
          .attr('stroke','#707070').attr('stroke-width', 1)
          .attr('fill', '#ffffff')
          monthlyRect
          .append('image')
          .attr('transform', `translate(${m.SUBITEM_STATUS == 'Completed' ? textWidth + 16 : textWidth + 13}, ${Itemlength < 28 ? 18*mi +53 : 28 <= Itemlength < 47 ? 18*mi +73 :18*mi +93}) scale(0.4)`)
          .attr('opacity', 1)
          .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Milestones/${m.SUBITEM_STATUS}.png`)
        })

        if(!d.ITEM_STATUS) return //이미지 undifined 생기니까 맨 뒤로 빼준다
        monthlyRect
        .append('image')
        .attr('transform', `translate(${d.ITEM_STATUS == 'Completed' ? rectWidth-35 :rectWidth-40}, ${d.ITEM_STATUS == 'Completed' ? rectWidth-140 : rectheight-28})`)
        .attr('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Milestones/${d.ITEM_STATUS}.png`)
      })
    },
  }, 
}