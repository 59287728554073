import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
  }),


  methods: {
    
    draw_SystemList() {


      let StatusData = this.SystemList
      .append('g')
      .attr('transform', `translate(75, 55)`)

      // QVD A1 ----------------------------------------------------------------------------------------------------------------------------------------------------------------------
      let drawY = 0
      let borderY = drawY - 30

       StatusData
      .append('text')
      .attr('x', 30).attr('y', borderY+20)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#44A9DF').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Total')

      StatusData
      .append('text')
      .attr('x', 60).attr('y', borderY+20)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#44A9DF').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Pcow')

      StatusData
      .append('text')
      .attr('x', 92).attr('y', borderY+20)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#44A9DF').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text('Rem.')

      StatusData
      .append('text')
      .attr('x', 100).attr('y', borderY+20)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Progress(%)')

      this.dataSet.forEach((d,i) => {
        d.y = drawY + 1
        StatusData
        .append('text')
        .attr('x', 0).attr('y', d.y)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
        .text(d.name)

        StatusData // Total
        .append('text')
        .attr('id', `A1qvd_Total_${i}`)
        .attr('transform', `translate(${30}, ${d.y})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging').text(d.total)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#A1qvd_Total_${i}`).transition().duration(100).attr('transform', `translate(${30 + (this.getNodeElValue(`#A1qvd_Total_${i}`, 'width')/6)}, ${d.y - 2}) scale(1.3)`)
        })
        .on('mouseout', () => {
          d3.select(`#A1qvd_Total_${i}`).transition().duration(100).attr('transform', `translate(${30}, ${d.y})`)
        })
        .on('click', () => {
          let request_ = this.getRequestColumnProps('total', d, this.ColumnProps)
          if(request_) {
            request_.filters = {
              ...request_.filters,
              ...this.FilterValues
            }
            this.$emit('request-action', request_)
          }

        })


        StatusData // PCOW
        .append('text')
        .attr('id', `A1qvd_Pcow_${i}`)
        .attr('transform', `translate(${60}, ${d.y})`)
        .style('font-family', 'roboto').style('font-size', 10).attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
        .style('fill', () => { if (d.pcow == 0) return '#E0E0DF'; else return '#757575'}).text(d.pcow)
        .style('cursor', () => { if (d.pcow != 0) return 'pointer'})
        .on('mouseover', () => {
          if (d.pcow != 0) d3.select(`#A1qvd_Pcow_${i}`).transition().duration(100).attr('transform', `translate(${60 + (this.getNodeElValue(`#A1qvd_Pcow_${i}`, 'width')/6)}, ${d.y - 2}) scale(1.3)`)
        })
        .on('mouseout', () => {
          if (d.pcow != 0) d3.select(`#A1qvd_Pcow_${i}`).transition().duration(100).attr('transform', `translate(${60}, ${d.y})`)
        })
        .on('click', () => {
          let request_ = this.getRequestColumnProps('pcow', d, this.ColumnProps)
          if(request_) {
            request_.filters = {
              ...request_.filters,
              ...this.FilterValues
            }
            this.$emit('request-action', request_)
          }

        })

        StatusData // Remain
        .append('text')
        .attr('id', `A1qvd_Rem_${i}`)
        .attr('transform', `translate(${90}, ${d.y})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging').text(d.rem)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          d3.select(`#A1qvd_Rem_${i}`).transition().duration(100).attr('transform', `translate(${90 + (this.getNodeElValue(`#A1qvd_Rem_${i}`, 'width')/6)}, ${d.y - 2}) scale(1.3)`)
        })
        .on('mouseout', () => {
          d3.select(`#A1qvd_Rem_${i}`).transition().duration(100).attr('transform', `translate(${90}, ${d.y})`)
        })
        .on('click', () => {
          let request_ = this.getRequestColumnProps('rem', d, this.ColumnProps)
          if(request_) {
            request_.filters = {
              ...request_.filters,
              ...this.FilterValues
            }
            this.$emit('request-action', request_)
          }

        })

        StatusData
        .append('rect')
        .attr('x', 100).attr('y', drawY).attr('width', 100).attr('height', 10).style('fill', '#F4F5F5')
        .attr('stroke', '#E0E0DF').attr('stroke-width', 0.5).style('stroke-opacity', 0.5)

        StatusData
        .append('rect')
        .attr('x', 100).attr('y', drawY).attr('width', 0).attr('height', 10).style('fill', '#B4E3FA')
        .attr('stroke', '#44A9DF').attr('stroke-width', 0.5).style('stroke-opacity', 0.5)
        .transition().duration(700).attr('width', (d.completed/d.total)*100)

        StatusData
        .append('text')
        .attr('y', drawY)
        .attr('x', () => {if ((d.completed/d.total)*100 > 20) return 100 + (d.completed/d.total)*100 - 1; else return 100 + (d.completed/d.total)*100 + 1})
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('alignment-baseline', 'hanging')
        .attr('text-anchor', () => {if ((d.completed/d.total)*100 > 20) return 'end'; else return 'start'})
        .text(`${((d.completed/d.total)*100).toFixed(1)}%`)

        drawY += 16
      })

      



     
    },
  }
}
