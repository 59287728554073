export default {
  data: () => ({
    elProps: {
      coordinates: [
        { STAGE: 'OverAll', TYPE: 'Shop', DIRECT: null, CODE: 'Shop_F', x: 30, y: 370 },
        { STAGE: 'OverAll', TYPE: 'Shop', DIRECT: null, CODE: 'Shop_A', x: 220, y: 370 },
        { STAGE: 'OverAll', TYPE: 'Shop', DIRECT: null, CODE: 'Shop_P', x: 560, y: 180 },
        { STAGE: 'OverAll', TYPE: 'Shop', DIRECT: null, CODE: 'Shop_E', x: 800, y: 80 },
        // { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, CODE: 'Area_T', GOTO: 'Topside',  x: 600, y: 620 },
        { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, CODE: 'Area_M', GOTO: 'Modules',  x: 850, y: 550 },
        // { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, CODE: 'Area_C', GOTO: 'Common',   x: 1100, y: 180 },
        // { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, CODE: 'Area_L', GOTO: 'LQ',       x: 850, y: 620 },
        // { STAGE: 'OverAll', TYPE: 'Area', DIRECT: null, CODE: 'Area_H', GOTO: 'Hull',     x: 1080, y: 420 },
        
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'DMOD01'    , DIRECT: 'L', CODE: 'Area_M', x: 700, y: 600 },
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'DMOD02'    , DIRECT: 'L', CODE: 'Area_M', x: 1040, y: 480 },
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'DMOD03'    , DIRECT: 'R', CODE: 'Area_M', x: 450, y: 280 },
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'DMOD04'    , DIRECT: 'L', CODE: 'Area_M', x: 1060, y: 180 },
        { STAGE: 'Modules', TYPE: 'Area', LV3: 'DMOD05'    , DIRECT: 'L', CODE: 'Area_M', x: 690, y: 200 },
        { STAGE: 'Common',  TYPE: 'Area', LV3: 'RCxx'    , DIRECT: 'R', CODE: 'Area_C', x: 850, y: 650 },
        { STAGE: 'Common',  TYPE: 'Area', LV3: 'FL01'    , DIRECT: 'R', CODE: 'Area_C', x: 570, y: 250 },
        { STAGE: 'Common',  TYPE: 'Area', LV3: 'KO01'    , DIRECT: 'L', CODE: 'Area_C', x: 1200, y: 290 },
        { STAGE: 'LQ',      TYPE: 'Area', LV2: 'LQ'      , DIRECT: 'L', CODE: 'Area_L', x: 1110, y: 360 },
        { STAGE: 'Hull',    TYPE: 'Area', LV2: 'HULL-AFT', DIRECT: 'R', CODE: 'Area_H', x: 320, y: 590 },
        { STAGE: 'Hull',    TYPE: 'Area', LV2: 'HULL-FOR', DIRECT: 'L', CODE: 'Area_H', x: 1060, y: 250 },
      ]
    }
  })
}