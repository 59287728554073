import * as d3 from 'd3'
import __C from '../../../../../../../../../src/primitives/_constant_'

export default {
  data: () => ({
    parentStageSummaryBox: null,
    indicator: null,
    indipath: 'M 0 0 L 10 0 L 10 10 L 14 10 L 5 20 L -4 10 L 0 10 Z',
    layoutLoaded: false,
    reqIndicator: null,
  }),
  methods: {
    loadEquipment() {
      this.loadSvg(this.svg, { 
        url: `/Demo/EquipmentLayout/Svg/${this.route.to.package}_${this.route.to.area}_${this.route.to.equipment}.svg`, 
        x  : 0,
        y  : 0,
      }).then(svg_ => { 
        this.innerSvg = svg_
        this.innerSvg.style('opacity', 0)

        this.setPriority()
        this.postLoadEquipment()
      })
    },
    postLoadEquipment() {
      this.rebuildEqCode()
      this.rebuildEqTag()
      this.equipmentFilter()
      this.tagButtonAction(this.tagact())
  
      this.innerSvg
      .attr('x', this.zoom.out.x)
      .attr('y', this.zoom.out.y)
      .attr('width', this.scaleW)
      .attr('height', this.scaleH)
      .transition().duration(350)
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .style('opacity', 1)


      // draw indicator ---------------------------
      this.indicator = this.innerSvg
      .append('g')
      .attr('id', 'item_indicator')
      .attr('transform', 'translate(0, 0)')
      .style('opacity', 0)

      // shadow //
      this.indicator
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.12)')
      .append('path')
      .attr('d', this.indipath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      this.indicator
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.1)')
      .append('path')
      .attr('d', this.indipath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      this.indicator
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.07)')
      .append('path')
      .attr('d', this.indipath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      // indicator //
      this.indicator
      .append('path')
      .attr('d', this.indipath)
      .attr('fill', '#fa2c72')
      .attr('stroke', '#970234')
      .attr('stroke-width', 1)
      // ------------------------------------------

      setTimeout(() => {
        // parent (area) summary for current area-part
        this.parentStageSummaryBox = this.drawStageStatusSmall(this.innerSvg, {
          x     : this.areaBox.x - this.areaBox.w,
          y     : this.areaBox.y,
          w     : this.areaBox.w,
          h     : this.areaBox.h,
          title : `Area ${this.route.to.area}`,
          tsize : 10,
          shadow: true,
          source: this.dataset.stageSummary.area.find(d => d.LV2 == this.route.to.package && d.LV3 == this.route.to.area),
          filters: { LV2: this.route.to.package, LV3: this.route.to.area }
        })

        this.layoutLoaded = true
        this.indicatorMoveTo()
      }, 350)
    },
    unloadEquipment() {
      this.layoutLoaded = false
      this.parentStageSummaryBox.remove()

      this.innerSvg
      .transition().duration(350)
      .attr('x', this.zoom.out.x)
      .attr('y', this.zoom.out.y)
      .attr('width', this.scaleW)
      .attr('height', this.scaleH)
      .style('opacity', 0)

      return new Promise(resolve => {
        setTimeout(() => {
          this.innerSvg.remove()
          resolve() 
        }, 350)
      })
    },
    equipmentFilter(sender) {
      if(this.getSelectItems) var input__ = this.displayDataFilter.searchInput

      let searchcol = this.displayDataFilter.searchOption
      let input = input__ || d3.select(`#${this.localId}`).select('#search_input').text().toLowerCase()

      this.displayDataFilter.searchInput = input

      // Only from the search input action --------------------------
      let target = searchcol == 'TAG' ? 'Tag No.' : 'Tag Desc.'
      let with_ = input ? `With '${input}'` : 'All'
      let req = this.buildRequest(`Equipment Search Result on ${target} ${with_}`)
      req.filterString = input ? `LOWER(${searchcol}) LIKE '%${input}%'` : ''

      if(sender == 'search' && this.route.to.lname == 'equipment') {
        req.action.purpose = 'equipment-search'
        this.$emit('request-action', req)

      } else if(this.route.to.lname != 'equipment') {
        this.$emit('request-action', req)
        return
      }
      // ------------------------------------------------------------      

      let dFilter = Object.values(this.displayDataFilter).filter(f => f.type == 'CRITICAL' && f.checked)
      let sFilter = Object.values(this.displayDataFilter).filter(f => f.type == 'STAGE' && f.checked)

      this.dataset.eqList.filter(d => (
        d.LV2 == this.route.to.package &&
        d.LV3 == this.route.to.area &&
        d.AREA_UNIT == this.route.to.equipment
      )).forEach(d => {
        // filter not available
        let fna = dFilter.every(f => !f.checked) && sFilter.every(f => !f.checked)

        if(fna && !input) {
          d3.select(`.code_${d.LV2}_${d.LV3}_${d.SN}`).style('pointer-events', 'auto').transition().duration(150).style('opacity', 1)
          d3.select(`.tag_${d.LV2}_${d.LV3}_${d.SN}`).style('pointer-events', 'auto').transition().duration(150).style('opacity', 1)
          d3.select(`#tagline${d.SN}`).transition().duration(150).style('opacity', 1)

        } else {
          if(!input) var visible__ = dFilter.some(f => d[f.colName] == f.value) || sFilter.some(f => d[f.colName] == f.value)
          else if(fna) visible__ = d[searchcol] ? d[searchcol].toLowerCase().includes(input) : false
          else visible__ = (dFilter.some(f => d[f.colName] == f.value) || sFilter.some(f => d[f.colName] == f.value)) && d[searchcol].toLowerCase().includes(input)

          d3.select(`.code_${d.LV2}_${d.LV3}_${d.SN}`).style('pointer-events', visible__ ? 'auto' : 'none').transition().duration(150).style('opacity', visible__ ? 1 : .15)
          d3.select(`.tag_${d.LV2}_${d.LV3}_${d.SN}`).style('pointer-events', visible__ ? 'auto' : 'none').transition().duration(150).style('opacity', visible__ ? 1 : .15)
          d3.select(`#tagline${d.SN}`).transition().duration(150).style('opacity', visible__ ? 1 : .15)
        }
      })

      this.selectedItems = {
        ...this.selectedItems,
        filterOptions: {
          tag         : this.displayDataFilter.tag              ,
          searchOption: this.displayDataFilter.searchOption     ,
          searchInput : this.displayDataFilter.searchInput      ,
          p2w         : this.displayDataFilter.p2w.checked      ,
          w2w         : this.displayDataFilter.w2w.checked      ,
          nmr         : this.displayDataFilter.nmr.checked      ,
          mr          : this.displayDataFilter.mr.checked       ,
          loa         : this.displayDataFilter.loa.checked      ,
          po          : this.displayDataFilter.po.checked       ,
          delivery    : this.displayDataFilter.delivery.checked ,
          install     : this.displayDataFilter.install.checked  ,
          not         : this.displayDataFilter.not.checked      ,
        }
      }
    },
    rebuildEqCode() {
      // CODE Definition
      // The code must be in the 'CODE' layer of AI.
      let codeLayer = this.innerSvg.select(`#CODE`)

      if(codeLayer.empty()) {
        console.log('[DEBUG] Svg element #CODE does not exist.')
        return
      }

      codeLayer.selectAll('g').each((_, i, a) => {
        let group = d3.select(a[i])
        let elText = group.select('text')
        let elCircle = group.select('circle')
        
        if(elCircle.empty()) {
          var coordinate = group.select('path').node().getBBox()
          coordinate = {
            x: coordinate.x + this.eqStyle.CircleBorder.rx - 3,
            y: coordinate.y + this.eqStyle.CircleBorder.ry - 3,
          }
        } else {
          coordinate = {
            x: elCircle.attr('cx') ? Number(elCircle.attr('cx')) : 0,
            y: elCircle.attr('cy') ? Number(elCircle.attr('cy')) : 0,
          }
        }

        let no_ = elText.text()
        let idx = this.dataset.eqList.findIndex(d => d.LV2 == this.route.to.package && d.LV3 == this.route.to.area && d.AREA_UNIT==this.route.to.equipment && d.SN == parseInt(no_))
        if(idx < 0) {
          group.remove()
          return
        }
        
        let data = this.dataset.eqList[idx]
        let styleStatus = this.eqStyle.Status.find(styl => styl.name == data.STATUS)
        if(!styleStatus) {
          console.log(`[DEBUG] EQ Style '${data.STATUS}' does not exist.`)
          group.remove()
          return
        }

        // The border of code provides two types Rect and ellipse.
        let g = this.innerSvg
        .append('g')
        .attr('class', `code_${data.LV2}_${data.LV3}_${data.SN}`)
        .attr('transform', `translate(${coordinate.x}, ${coordinate.y})`)
        .style('cursor', 'pointer')
        .call(s => { this.sendRequestAction(s, data) })

        g.append('ellipse')
        .attr('rx', this.eqStyle.CircleBorder.rx).attr('ry', this.eqStyle.CircleBorder.ry)
        .attr('stroke', '#757575').attr('stroke-width', this.eqStyle.CircleBorder.stroke)
        .attr('fill', styleStatus.bColor)
        
        let style__ = this.eqStyle.critical.find(s => s.code == data.CRITICAL)
        if(style__) var tcolor = style__.tcolor
        else tcolor = '#333'

        g.append('text')
        .attr('transform', `translate(0, ${this.eqStyle.CircleBorder.padding})`)
        .attr('fill', styleStatus.tColor)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto').style('font-size', this.eqStyle.CircleBorder.textSize)
        .style('fill', tcolor)
        .text(no_)
      })

      codeLayer.remove()
    },
    rebuildEqTag() {
      let tagLayer = this.innerSvg.select(`#TAG`)

      if(tagLayer.empty()) {
        console.log('[DEBUG] Svg element #TAG does not exist.')
        return
      }

      tagLayer.selectAll('text').each((_, i, a) => {
        let elText = d3.select(a[i])
        let matrix = (elText.attr('transform') || '').match(/matrix(.*?)\)/g)
        matrix = matrix[0].match(/-?\d+\.?(\d+)?/g)
        
        if(matrix.length == 6) var coordinate = {
          x: parseInt(matrix[4]),
          y: parseInt(matrix[5])
        }; else coordinate = {
          x: parseInt(matrix[6]),
          y: parseInt(matrix[7])
        }

        let to = this.route.to
        let text_ = elText.text().split('-')
        let no_ = text_[0]
        let direction_ = text_[1]
        let idx = this.dataset.eqList.findIndex(d => d.LV2 == to.package && d.LV3 == to.area && d.AREA_UNIT==this.route.to.equipment &&d.SN == parseInt(no_))
        if(idx < 0) return

        let data = this.dataset.eqList[idx]
        this.innerSvg 
        .append('text')
        .attr('class', `tag_${to.package}_${to.area}_${to.equipment}_${no_}`)
        .attr('x', direction_ == 'L' ? coordinate.x - 2 : coordinate.x + 8)
        .attr('y', coordinate.y-1)
        .attr('fill', '#333')
        .attr('text-anchor', direction_ == 'L' ? 'end' : 'start')
        .attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto')
        .style('font-size', 9)
        .style('cursor', 'pointer')
        .call(s => { this.sendRequestAction(s, data) })
      })

      tagLayer.remove()
    },
    sendRequestAction(selection, d) {
      selection
      .on('mouseover', (_, i, a) => {
        let el = d3.select(a[i])

        if(el.node().tagName == 'g') el.style('opacity', .5)
        else el.attr('fill', '#81d4fa')
      })
      .on('mouseout', (_, i, a) => {
        let el = d3.select(a[i])
        if(el.node().tagName == 'g') el.style('opacity', 1)
        else el.attr('fill', '#333')
      })
      .on('click', () => {
        // declared in the page_equipment.minxin //
        this.reqIndicator = d
        this.indicatorMoveTo()
        // declared in the Declares.minxin //
        this.callEqPopup(d, ['share'])
      })
    },
    indicatorMoveTo() {
      if(!this.layoutLoaded || !this.reqIndicator) return

      let el = d3.select(`.code_${this.reqIndicator.LV2}_${this.reqIndicator.LV3}_${this.reqIndicator.SN}`)
      let box = this.getCoordinates(el)

      this.indicator
      .transition().duration(150)
      .attr('transform', `translate(${box[0]-5}, ${box[1]-29})`)
      .style('opacity', 1)

      this.reqIndicator = null
    }
  }
}