export default (function() {
  // const host_url = 'http://localhost:8080'
  // const api_url = 'http://localhost:27209'
  // const matchmaker_url = 'http://192.168.0.68:90'
  // const matchmaker_url = 'https://192.168.0.68'
  // demo 2023.05.29
  const host_url = 'https://jgsdemo.jinsolutions.com'
  const resource_url = 'https://jgsdemo.jinsolutions.com/resources'
  const api_url = 'https://jgsdemo.jinsolutions.com:27209'
  const websocket_api_url = 'wss://jgsdemo.jinsolutions.com:27209'
  const matchmaker_url = 'https://3dviewer-matchmaker-jgsdemo.jinsolutions.com'

  return {
    HOST_NAME: host_url,
    HOST_NAME_API: api_url,
    HOST_NAME_RESOURCE: resource_url,
    WEBSOCKET_STATE: 'ON',
    // WEBSOCKET_STATE: 'OFF',
    WEBSOCKET_API: websocket_api_url,
    // For the 3D Viewer ---
    MATCHMATER_URL: matchmaker_url,
    // ---------------------

    CONFIG: {
      ON: 'ON',
      OFF: 'OFF',
      TIMEOUT: 60 // In minutes(default = 60, 1 year = 525600)
    },
    ACCOUNT : {
      ACCESS_FULL: 'FULL_ACCESS'
    },
    APPLICATION : {
      URL_HOME: '/service/home',
      ACTION_TYPE_CONST_BLOCK_DIV: 'blockdiv',
      ACTION_TYPE_CONST_MOD_DASH: 'moddash'
    },
    NAVIGATION: {
      NAME_PHASE: 'Phase',
      NAME_GROUP: 'Group',
      NAME_ITEM: 'Item',
      NAME_SUBITEM: 'Subitem',
      PATH_TO_NAME: {
        '/sysenv/config/nav/phase': 'Phase',
        '/sysenv/config/nav/group':  'Group',
        '/sysenv/config/nav/item':  'Item',
        '/sysenv/config/nav/subitem':  'Subitem',
      },
      PATH_TO_COMPONENT: {
        '/sysenv/config/nav/phase': 'SysenvNavPhase',
        '/sysenv/config/nav/group': 'SysenvNavGroup',
        '/sysenv/config/nav/item': 'SysenvNavItem',
        '/sysenv/config/nav/subitem': 'SysenvNavSubitem',
      },
    },
    COLOR: {
      GRADATION_TYPE: {
        LINEAR_A2: 'LinearA2',
        LINEAR_A4: 'LinearA4',
        LINEAR_A9: 'LinearA9'
      }
    },
    BOARD: {
      REPORT_CAT_NAME_WEEKLY: 'Weekly Reports',
      REPORT_CAT_NAME_FABRICATOR: 'Fabricator Reports',
      REPORT_CAT_NAME_JGS: 'JGS Weekly Reports',
      REPORT_CAT_NAME_SPECIAL: 'Special Reports',
      
      REPORT_CAT_CODE_WEEKLY: 'Weekly',
      REPORT_CAT_CODE_FABRICATOR: 'Fabrication',
      REPORT_CAT_CODE_JGS: 'Jgs',
      REPORT_CAT_CODE_SPECIAL: 'special',
    },
    CHART: {
      ATTR_NAME_DATABSE         : 'Database',
      ATTR_NAME_AXIS            : 'Axis',
      ATTR_NAME_BAR             : 'Bar',
      ATTR_NAME_BOXES           : 'Box',
      ATTR_NAME_BORDER          : 'Border',
      ATTR_NAME_CANVAS          : 'Canvas',
      ATTR_NAME_CHARTDATA       : 'ChartData',
      ATTR_NAME_CIRCLE          : 'Circle',
      ATTR_NAME_COMPARE         : 'Compare',
      ATTR_NAME_CUTOFF          : 'Cutoff',
      ATTR_NAME_DELTA           : 'Delta',
      ATTR_NAME_GUIDELINE       : 'Guide',
      ATTR_NAME_LEGENDS         : 'Legend',
      ATTR_NAME_LINE            : 'Line',
      ATTR_NAME_MILESTONE       : 'Milestone',
      ATTR_NAME_NOTE            : 'Note',
      ATTR_NAME_PACKAGE         : 'Package',
      ATTR_NAME_RADAR           : 'Radar',
      ATTR_NAME_STYLE           : 'Style',
      ATTR_NAME_TABLE_SUMMARY   : 'TableSummary',
      ATTR_NAME_TABLE           : 'Table',
      ATTR_NAME_COLUMNS         : 'Columns',
      ATTR_NAME_HEADER          : 'Header',
      ATTR_NAME_FOOTER          : 'Footer',
      ATTR_NAME_TEXT            : 'Text',
      ATTR_NAME_TIMELINE        : 'Timeline',
      ATTR_NAME_TITLE           : 'Title',
      ATTR_NAME_VALUE           : 'Value',
      ATTR_NAME_VALUES          : 'Values',
      ATTR_NAME_Q_RESULT_EXTRAS : 'QResultExtras',
      ATTR_NAME_TAB_STATUS      : 'TabStatus',

      CAT_CODE_DONUT: 'DNT',
      CAT_CODE_PIE: 'PIE',
      CAT_CODE_BAR_AXIS: 'AXS',
      CAT_CODE_BAR_SOLID: 'SLD',
      CAT_CODE_BAR_PROGRESS: 'PRO',
      CAT_CODE_BAR_DELTA: 'DLT',
      CAT_CODE_HISTORY: 'HIS',
      CAT_CODE_SKYLINE: 'SKY',
      CAT_CODE_ICICLE: 'ICC',
      CAT_CODE_GAUGE: 'GAU',
      CAT_CODE_RADAR: 'RAD',
      CAT_CODE_GANTT: 'GNT',
      CAT_CODE_TABLE_SUMMARY: 'TSM',
      CAT_CODE_FLOAT: 'FLT',
      CAT_CODE_CUSTOM: 'CUS',
      CAT_CODE_PAGE_SVG: 'PSVG',
      CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER: 'LIBSVGCC',
      CAT_CODE_COMPLIB_SVG_NAVIGATION: 'LIBSVGNV',
      CAT_CODE_COMPLIB_SVG_TAB: 'LIBSVGTB',
      CAT_CODE_COMPLIB_SVG_PAGE: 'LIBSVGPG',
      CAT_CODE_NAME: {
        DNT: 'Donut',
        PIE: 'Pie',
        AXS: 'Axis Bar',
        SLD: 'Solid Bar',
        PRO: 'Progress Bar',
        DLT: 'Delta Bar',
        HIS: 'Histogram',
        SKY: 'Skyline',
        ICC: 'Icicle',
        GAU: 'Gauge',
        RAD: 'Radar',
        FLT: 'Floating',
        GNT: 'Gantt',
        TSM: 'Summary Table',
        CUS: 'Customized',
        LIBSVGCC: 'SVG Controller',
        LIBSVGTB: 'SVG Tab',
        LIBSVGPG: 'SVG Page',
      },
      CAT_CODE_COMPONENT: {
        DNT: 'SysenvChartlibDonut',
        PIE: 'SysenvChartlibPie',
        AXS: 'SysenvChartlibBarNormal',
        SLD: 'SysenvChartlibBarSolid',
        PRO: 'SysenvChartlibBarProgress',
        DLT: 'SysenvChartlibBarDelta',
        HIS: 'SysenvChartlibHistogram',
        SKY: 'SysenvChartlibSkyline',
        ICC: '_Icicle',
        GAU: '_Gauge',
        RAD: 'SysenvChartlibRadar',
        FLT: 'SysenvChartlibFloating',
        GNT: '_Gantt',
        TSM: 'SysenvChartlibTableSummary',
        CUS: 'SysenvChartlibCustom',
        LIBSVGTB: 'SysenvSvglibTab',
        LIBSVGCC: 'SysenvChartlibCustom',
        LIBSVGNV: 'SysenvChartlibCustom',
        LIBSVGPG: 'SysenvChartlibCustom',
      },
      TYPE_CODE_DONUT: 'Donut',
      TYPE_CODE_DOUBLE: 'Double',
      TYPE_CODE_GAUGE: 'Gauge',
      TYPE_CODE_MULTI: 'Multi',
      TYPE_CODE_NORMAL: 'Normal',
      TYPE_CODE_PACKAGE: 'Package',
      TYPE_CODE_POLYGON: 'Polygon',
      TYPE_CODE_PROGRESS: 'Progress',
      TYPE_CODE_SINGLE: 'Single',
      TYPE_CODE_TRIPLE: 'Triple',
      TYPE_CODE_VERTICAL: 'Vertical',
      TYPE_CODE_HORIZONTAL: 'Horizontal',
      TYPE_CODE_GROUP: 'Group',
      TYPE_CODE_GROUP_VERTICAL: 'Grouped Vertical',
      TYPE_CODE_GROUP_HORIZONTAL: 'Grouped Horizontal',
      TYPE_CODE_STEPPED: 'Stepped',
      TYPE_CODE_STACK: 'Stack',
      TYPE_CODE_STACK_VERTICAL: 'Stacked Vertical',
      TYPE_CODE_STACK_HORIZONTAL: 'Stacked Horizontal',
      TYPE_CODE_S_CURVE: 'S-Curve',
      TYPE_CODE_RUNDOWN: 'Rundown',
      TYPE_CODE_EMBOSSED: 'Embossed',
      TYPE_CODE_COMPARE: 'Compare',
      TYPE_CODE_COMPARATIVE: 'Comparative',
      TYPE_CODE_COLUMN: 'Column',
      TYPE_CODE_CIRCULAR: 'Circular',
      TYPE_CODE_CYLINDER_SHADOW: 'Cylinder',
      TYPE_CODE_CYLINDER_REFLECTED: 'Reflected Cylinder',
      TYPE_CODE_CYLINDER_TUBE: 'Tube',
      TYPE_CODE_BOX: 'Boxes',
      TYPE_CODE_BOX_TUBE: 'Tube Box',
      TYPE_CODE_TIMETABLE: 'Timetable',
      TYPE_CODE_PAGESVG: 'LIBSVGPG',
      
      MOVED_EL_TYPE_TEXT: 'coordinate-el-type-text',
      MOVED_EL_TYPE_BARSERIES: 'coordinate-el-type-barSeries',
      MOVED_EL_TYPE_LINE: 'coordinate-el-type-line',
    },
    FILE: {
      GALLERY_TYPE_PHOTO: 'PHOTO',
      GALLERY_TYPE_VIDEO: 'VIDEO',
    },
    FILE_BROWSER: {
      ROOT_SYSTEM     : '',
      ROOT_COI        : 'Coi',
      ROOT_PUBLIC_DATA: 'Pub',
      ROOT_JGS_DATA   : 'JGS',

      POUCH_DOCID_SYSTEM      : 'fbsys',
      POUCH_DOCID_PUBLIC_DATA : 'fbpub',
      POUCH_DOCID_JGS_DATA    : 'fbjgs',
    },
    SVG: {
      STROKE_LINECAP_BUTT: 'butt',
      STROKE_LINECAP_ROUND: 'round',
      STROKE_LINECAP_SQUARE: 'square',
    },
    PAGE_COMPONENT: {
      TYPE_PAGE: 'Page',
      TYPE_PDF: 'PDF',
      TYPE_MODAL: 'Modal',
      TYPE_P6: 'P6',
      REQUEST_TYPE_HOME: 'home',
      REQUEST_TYPE_INIT: 'init',            // Request all parts of the data including filtergin options
      REQUEST_TYPE_INITSMALL: 'init-small', // Request with Modal Mode
      REQUEST_TYPE_INPUT: 'input',          // Request only datalist by search filter (input filter)
      REQUEST_TYPE_FILTER: 'filtered',      // Request all parts of the data, except filtering options
      REQUEST_TYPE_PAGE: 'page',            // Request next page's rows of the data
      REQUEST_TYPE_SCROLL: 'scroll',        // Request next page's rows of the data for the infinite scroll
      REQUEST_TYPE_PREVIEW: 'preview',      // Request short result for the system admin's data-table configuration
      REQUEST_TYPE_BUILDER: 'builder',      // Request all parts of the data, except filtering options
      REQUEST_TYPE_VIEWER: 'viewer',        // Request all parts of the data, except filtering options
      REQUEST_TYPE_EXPORT: 'export',        // Request all parts of the data, except filtering options & Chart Controllers
    },
    COMPONENT_LIBRARY: {
      SVG_CHART_CONTROLLER: 'LIBSVGCC',
      SVG_PAGE: 'LIBSVGPG',
      DOM: 'LIBDOM',
    },
    DATATABLE: {
      TYPE_CODE_GENERAL: 'General',
      TYPE_CODE_TIMELINE: 'Timeline',
    },
    DASHBOARD: {
      TYPE_CODE_PAGE: 'PAGE',
      TYPE_CODE_PAGESVG: 'PAGESVG',
      TYPE_CODE_PDF: 'PDF',
      BUILDER_MODE_LAYOUT: 'builder',
      BUILDER_MODE_VIEWER: 'viewer',
      SVG_ROLE_CHART: 'chart',
      SVG_ROLE_CONTROLLER: 'controller',
    },
    DATABASE: {
      QUERY_APPLIED_JSON: 'J',
      QUERY_APPLIED_SQL: 'Q',
      QUERY_APPLIED_S_PROCEDURE: 'S',
    },
    DATABASE_NAME: {
      CHART_LIBRARY: 'ChartLibrary'
    },
    SCHEMA_NAME: {
      DONUT: 'Donut',
      BAR: 'Bar',
      HISTOGRAM: 'Histogram',
      PIE: 'Pie'
    },
    LOCAL_STORAGE_NAME: {
      TOKEN: '',
      TIMEOUT: '',
      ACCOUNT: 'ACCOUNT',
      APP_SERVICE_FILTERED_VALUES: 'FILTERED_VALUES_',
      APP_SERVICE_CONFIG_PROPS: 'CONFIG_PROPS_',
      APP_SERVICE_PAGE_PROPS: 'PAGE_PROPS_',
      ENVIRONMENT: 'ENVIRONMENT',
    },
    FORM: {
      EDIT_MODE_VIEW: 'VIEW',
      EDIT_MODE_MOD: 'MOD',
      EDIT_MODE_NEW: 'NEW',
      EDIT_MODE_EDIT: 'Edit',
      EDIT_MODE_ADD: 'Add'
    },
    STORE_NAMESPACE: {
      ACCOUNT: 'account',
      APPLICATION: 'app',
      APP_SERVICE: 'appsvc',
      CHART_LIBRARY: 'chartlib',
      CHART_LIBRARY_CHART: 'chartlib/chart',
      CHART_LIBRARY_BAR: 'chartlib/bar',
      CHART_LIBRARY_DONUT: 'chartlib/donut',
      CHART_LIBRARY_HISTOGRAM: 'chartlib/histogram',
      CHART_LIBRARY_PIE: 'chartlib/pie',

      CONFIG: 'config',
      DBSUPPORT: 'dbsupport',
      PAGE_COMPONENT: 'pagecomp',
      DASHBOARD_ADMIN: 'dashadmin',
      INTENDED_DATAGRID_ADMIN: 'idgadmin',
      SLIDE_MODAL: 'slidemodal',
      SVGCONTROL_ADMIN: 'svgprops',
      SVG_PAGEBUILDER_ADMIN: 'svgpagebuilder',
      SYS_ENVIRONMENT: 'sysenv',
      NOTICE: 'notice',
      
      INTENDED_DATAGRID: 'appsvc/idg',
      DASHBOARD: 'appsvc/dash',
    },
    STORE_NAMESPACE_KEY: {
      DASHBOARD   : 'dash',
      DATATABLE   : 'idg',
      FILE_BROWSER: 'filebrowser',
      BOARD_REPORT: 'boardReport',
    },
    IMAGE: {
      ICON_TYPE_SVG: 'SVG',
      ICON_TYPE_FONT: 'FNT'
    },
    FULL_ACCESS_PERMISSION: ['SYS_ADMIN'],
    PUBLIC_URL: [
      { name: 'signin'        , pos: 0 }, 
      { name: 'register'      , pos: 0 }, 
      { name: 'export'        , pos: 0 }, 
      { name: 'pub_3d_viewer' , pos: 0 }, 
      { name: 'Forgot'        , pos: 0 }, 
      { name: 'Invalid'       , pos: 0 }, 
    ],
    FILTER: {
      TYPE_CODE_RADIO: 'radio',
      TYPE_CODE_CHECK: 'check',
      TYPE_CODE_SELECT: 'select',
      TYPE_CODE_SELECT_MULTI: 'mselect',
      TYPE_CODE_NAV: 'nav',
      TYPE_CODE_NAV_PROGRESS: 'prognav',
      TYPE_CODE_CUSTOM: 'custom',
    },
    REQUEST_ACTION: {
      TARGET_URL: 'url',
      TARGET_POPUP: 'popup',
      TARGET_POPUP_EQ: 'eqpopup',
      TARGET_POPUP_HL_EQ: 'hleqpopup',
      TARGET_POPUP_HLS: 'popuphls',
      TARGET_POPUP_FREE: 'popup-component-free',
      TARGET_TABLE_POPUP: 'tablepopup',
      TARGET_IMG_POPUP: 'imgPopup',
      TARGET_SLIDEMODAL: 'slide-modal',
      TARGET_SINGLE_FILE_DOWNLOAD: 'single-file-download',
      SENDER_DASHBOARD: 'dashboard',
      SENDER_PAGESVG: 'pagesvg',
      COMPONENT_DASHBOARD: 'ServiceDashboard',
      COMPONENT_DASHBOARD_CONTAINER: 'ServiceDashboardContainer',
      COMPONENT_DATATABLE: 'ServiceDatatable',
      COMPONENT_SVGPAGE: 'ServicePageSvg',
      COMPONENT_SVGTABS: 'ServiceSvgTabs',
      COMPONENT_COMMENT: 'JCommComments',
      COMPONENT_MODEL_VIEWER: 'ServiceModelViewer'
    },
    MESSENGER: {
      CHAT_TYPE_1TO1: '1TO1',
      CHAT_TYPE_GROUP: 'GROUP',
      COMMAND_OPEN_CONNECTION: 'open',
      COMMAND_SEND_MESSAGE: 'send',
      COMMAND_READ_MESSAGE: 'read',
      MESSAGE_NOT_SAVE: 'NOT-SAVE',
      MODE_NOTICE: 'notice',
      MODE_FIND_MEMBER: 'find-member',
      MODE_TALKING_ON: 'talking-on',
      MODE_1TO1_MESSAGE: '1:1',
      MODE_GROUP_MESSAGE: 'group',
    }
  }
})()
