import * as d3 from 'd3'

export default {
  methods: {
    Export_Overall(d) {
      if (d != null) {
        this.displayGroupID(`#Keyplan_Group`, 10, 10, 1)
        this.displayGroupID(`#Legends`, 10, 10, 1)
      }
      if (d.stage == 'Division_Lv1' && d.mask == 'OVERALL' ) {
            
        // Remove SVG
        this.removeSvg(this.CANVAS, 10)          

        // Hidden
        this.hiddenGroupID('#Mask_Overall', 0, 10)

        setTimeout(() => {

          d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, -100)`)
          d3.select(`#Area_AFT`).transition().duration(1000).attr('transform', `translate(441, 692) scale(0.75)`)
          d3.select(`#Area_MS`).transition().duration(1000).attr('transform',  `translate(857, 495) scale(0.75)`)
          d3.select(`#Area_FOR`).transition().duration(1000).attr('transform', `translate(709, 619) scale(0.75)`)
          d3.select(`#Area_LQ`).transition().duration(1000).attr('transform',  `translate(947, 479) scale(0.75)`)
          d3.select(`#Area_VS`).transition().duration(1000).attr('transform',  `translate(1003, 576) scale(0.75)`)

        },1000);

        setTimeout(() => {

          // Display
          this.displayGroupID(`#Menu_Back`, 10, 10, 1)
          // this.displayGroupID(`#Keyplan_Group`, 10, 10, 1)
          // this.displayGroupID(`#Legends`, 10, 10, 1)
          this.displayGroupID(`#Mask_Topside`, 10, 10, 0)
          this.displayGroupID(`#Mask_Hull`, 10, 10, 0)

          this.stage = this.STG_Division_Lv1
          this.fncName = 'Goto_Division_Lv1'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.drawProgress('Hull & Top')
        },1000);
      // })        
  }
      if (d.stage == 'Division_Lv2' && d.mask == 'Mask_Hull') {
        setTimeout(() => {
        // Remove SVG
        this.removeSvg(this.CANVAS, 10)
        },1000);

        // Title & Keyplan
        d3.select(`#Title`).text('Hull & LQ')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL.jpg`)

        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_Topside', 0, 10)
        this.hiddenGroupID('#Mask_Hull', 0, 10)
        this.hiddenGroupID('#Topside_Group', 0, 1000)

        setTimeout(() => {

          d3.select(`#Area_AFT`).transition().duration(1000).attr('transform',`translate(304, 670) scale(0.75)`)
          d3.select(`#Area_MS`).transition().duration(1000).attr('transform', `translate(962, 379) scale(0.75)`)
          d3.select(`#Area_FOR`).transition().duration(1000).attr('transform',`translate(692, 548) scale(0.75)`)
          d3.select(`#Area_LQ`).transition().duration(1000).attr('transform', `translate(1051,195) scale(0.75)`)
          d3.select(`#Area_VS`).transition().duration(1000).attr('transform', `translate(1108,460) scale(0.75)`)

        }, 1000); 

        setTimeout(() => {

          // Mask Display
          this.displayGroupID(`#Mask_Hull_AFT`, 10, 10, 0)
          this.displayGroupID(`#Mask_Hull_FOR`, 10, 10, 0)
          this.displayGroupID(`#Mask_Hull_MS`, 10, 10, 0)
          this.displayGroupID(`#Mask_Hull_LQ`, 10, 10, 0)

          this.Lev1 = 'HULL'
          this.stage = this.STG_Division_Lv2
          this.fncName = 'Goto_Hull_Division_Lv2'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.drawProgress('Hull & LQ')
        }, 1000);          
      }

      if (d.stage == 'Division_Lv2' && d.mask == 'Mask_Topside' ) {

        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);        

        // Title & Keyplan
        d3.select(`#Title`).text('Topside')
        d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/TOPSIDES.jpg`)
        
        // Hidden
        this.hiddenGroupID('#Menu_Back', 0, 10)

        this.hiddenGroupID('#Mask_Topside', 0, 10)
        this.hiddenGroupID('#Mask_Hull', 0, 10)

        this.hiddenGroupID('#Area_AFT', 0, 1000)
        this.hiddenGroupID('#Area_MS', 0, 1000)
        this.hiddenGroupID('#Area_FOR', 0, 1000)
        this.hiddenGroupID('#Area_LQ', 0, 1000)
        this.hiddenGroupID('#Area_VS', 0, 1000)

        setTimeout(() => {

          d3.select(`#Topside_Group`).transition().duration(1000).attr('transform', `translate(0, 0)`)

          d3.select(`#LD00DA`).transition().duration(1000).attr('transform', `translate(1200, 656) scale(0.75)`)
          d3.select(`#TSRC05`).transition().duration(1000).attr('transform', `translate(1164, 675) scale(0.75)`)
          d3.select(`#TSRC04`).transition().duration(1000).attr('transform', `translate(1097, 699) scale(0.75)`)
          d3.select(`#TSRC03`).transition().duration(1000).attr('transform', `translate(1013, 725) scale(0.75)`)
          d3.select(`#TSRC02`).transition().duration(1000).attr('transform', `translate(934, 744) scale(0.75)`)
          d3.select(`#TSRC01`).transition().duration(1000).attr('transform', `translate(851, 770) scale(0.75)`)

          d3.select(`#MW01`).transition().duration(1000).attr('transform', `translate(450, 381) scale(0.75)`)
          d3.select(`#MW02`).transition().duration(1000).attr('transform', `translate(538, 362) scale(0.75)`)
          d3.select(`#MW04`).transition().duration(1000).attr('transform', `translate(716, 265) scale(0.75)`)
          d3.select(`#MW05`).transition().duration(1000).attr('transform', `translate(764, 275) scale(0.75)`)
          d3.select(`#ME01`).transition().duration(1000).attr('transform', `translate(515, 420) scale(0.75)`)
          d3.select(`#ME02`).transition().duration(1000).attr('transform', `translate(586, 403) scale(0.75)`)
          d3.select(`#ME04`).transition().duration(1000).attr('transform', `translate(766, 294) scale(0.75)`)
          d3.select(`#ME05`).transition().duration(1000).attr('transform', `translate(820, 315) scale(0.75)`)
          
          d3.select(`#FL01`)  .transition().duration(1000).attr('transform', `translate(767, 545) scale(0.75)`)
          d3.select(`#KO01`)  .transition().duration(1000).attr('transform', `translate(754, 823) scale(0.75)`)
        
      },1000);

        setTimeout(() => {

          // Mask Display
          this.displayGroupID(`#Mask_TS_Common`, 10, 10, 0)
          this.displayGroupID(`#Mask_TS_Modules`, 10, 10, 0)

          this.Lev1 = 'TOPSIDES'
          this.stage = this.STG_Division_Lv2
          this.fncName = 'Goto_TS_Division_Lv2'
          this.StageCode() // AREA / STAGE
          this.displayGroupID(`#Menu_Back`, 0, 200, 1)

          // ### Call Canvas ###
          this.drawProgress('Module & Common')
        }, 1000);          
      } 

      // Hull Select
      if (d.stage == 'Division_Lv3' && ['AFT','FOR','LQ','MS'].includes(d.level)) {

        setTimeout(() => {
          // Remove SVG
          this.removeSvg(this.CANVAS, 10)
        },1000);

          // Title & Keyplan
          d3.select(`#Title`).text(`Hull-${d.level}`)
          d3.select(`#Keyplan`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Equipment/Keyplan/HULL_${d.level}.jpg`)

          // Hidden
          this.hiddenGroupID('#Menu_Back', 0, 10)

          this.hiddenGroupID('#Mask_Hull_AFT', 0, 10)
          this.hiddenGroupID('#Mask_Hull_FOR', 0, 10)
          this.hiddenGroupID('#Mask_Hull_LQ', 0, 10)
   
         setTimeout(() => {
         // Remove SVG
         this.removeSvg(this.Dashboard_Overall, 10) 
        },1000);

          // Activate
          if(d.level =='AFT') this.Activate_Hull_AFT()
          if(d.level =='MS') this.Activate_Hull_MS()
          if(d.level =='FOR') this.Activate_Hull_FOR()
          if(d.level =='LQ') this.Activate_Hull_LQ()

         this.Stage = this.STG_Activate
         this.fncName = 'Goto_Hull_Activate'
         this.StageCode() // AREA / STAGE
         this.displayGroupID(`#Menu_Back`, 0, 200, 1) 
      }      
  
    },

  }
}
