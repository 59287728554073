import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    Canvas_ModuleSummary() {

      this.SVG_ModuleSummary = this.svg.append('svg')

      this.SVG_ModuleSummary
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)


      let data_ = null, data = null

      // Module Information
      this.ModuleInformation(this.SVG_ModuleSummary)


      // Status of Blocks
      let statusData = this.Queries.SQL2.filter(f => f.MODULE == this.Module)
      let style = [
                  {name: 'Cutting',         bColor:'#B4E3FA', opacity: 0.25},
                  {name: 'Fabrication',     bColor:'#83D2F5', opacity: 0.40},
                  {name: 'Assembly',        bColor:'#44A9DF', opacity: 0.55},
                  {name: 'Pre-Outfitting',  bColor:'#FEED57', opacity: 0.70},
                  {name: 'Block Painting',  bColor:'#FCE3EB', opacity: 0.85},
                  {name: 'Erection',        bColor:'#CDDC37', opacity: 1.00},
          ]
      this.Bar_Activity_01(this.SVG_ModuleSummary, 'id', 100, 750, statusData, 11, 150, 20, 10, style, 'on')


      // Status of activities
      this.StatusActivity(this.SVG_ModuleSummary, 450, 615, 'B4E3FA', 0.5, 10, 100, 18, 'Status of Supports', 105)
      this.StatusActivity(this.SVG_ModuleSummary, 770, 615, 'B4E3FA', 1.0, 10, 100, 18, 'Status of Outfitting', 105)

      //Progress Summary with Quantity Table by the phase
      data_ = this.Queries.SQL5.find(f => f.MODULE == this.Module && f.PHASE =='ENG' && f.DISC == 'Overall')
      this.ProgressWithTable(this.SVG_ModuleSummary, 95, 'Shop Drawing', 1000, 30, data_, ['Not Started', 'Started', 'Issued'], 'on')
      
      data_ = this.Queries.SQL5.find(f => f.MODULE == this.Module && f.PHASE =='PRO' && f.DISC == 'Overall')
      this.ProgressWithTable(this.SVG_ModuleSummary, 113, 'Procurement(MP)', 1000, 340, data_, ['Not Started', 'Started', 'Issued'], 'on')

      data_ = this.Queries.SQL5.find(f => f.MODULE == this.Module && f.PHASE =='CON' && f.DISC == 'Overall')
      this.ProgressWithTable(this.SVG_ModuleSummary, 113, 'Construction', 1000, 640, data_, ['Not Started', 'Started', 'Issued'], 'off')


      // Radar chart for the construction and engineering
      this.Radar.chart.size = 150
      style = {
        x: 1120,
        y: 205,
      }
      data = this.Queries.SQL5.filter(f => f.MODULE == this.Module && f.PHASE =='ENG' && f.DISC != 'Overall')
      this.draw_Radar(this.SVG_ModuleSummary, data, style)

      style = {
        x: 1120,
        y: 515,
      }
      data = this.Queries.SQL5.filter(f => f.MODULE == this.Module && f.PHASE =='PRO' && f.DISC != 'Overall')
      this.draw_Radar(this.SVG_ModuleSummary, data, style)

      style = {
        x: 1120,
        y: 780,
      }
      data = this.Queries.SQL5.filter(f => f.MODULE == this.Module && f.PHASE =='CON' && f.DISC != 'Overall')
      this.draw_Radar(this.SVG_ModuleSummary, data, style)
    },



  }
}
           