import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    innerSvg: null,
    keyPlanSvg: null,
    PAU: '',

    Status: [
                { type: 'N/A',  sColor: '#818181', bColor: '#BDBCBC', tColor: '#FFF'},
                { type: 'REQ',  sColor: '#FF75A5', bColor: '#F7BACF', tColor: '#FFF'},
                { type: 'PO',   sColor: '#FAAA18', bColor: '#FFDA00', tColor: '#FFF'},
                { type: 'DEL',  sColor: '#44A9DF', bColor: '#83D2F5', tColor: '#FFF'},
                { type: 'INST', sColor: '#4CAE4E', bColor: '#8BC248', tColor: '#FFF'},
    ],

    ChartData: [
                { title: 'Not REQ',   type: 'N/A',  actual: 0, total: 123},
                { title: 'REQ',       type: 'REQ',  actual: 0, total: 130},
                { title: 'PO',        type: 'PO',   actual: 0, total: 120},
                { title: 'Delivered', type: 'DEL',  actual: 0, total: 90},
                { title: 'Installed', type: 'INST', actual: 0, total: 140},
    ],

  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Queries).length', Object.keys(this.Queries).length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Queries).length > 0 &&
        Object.keys(this.Canvas).length > 0 

      )
    },
  },
  methods: {

    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))

      this.dataSet.forEach(data => {
        let bItem = this.Status.find(t_ => t_.type == data.STATUS)
        if(bItem) {
          data.bColor = bItem.bColor
          data.sColor = bItem.sColor
          data.tColor = bItem.tColor
        }
      })

      this.ChartData.forEach( (d) =>{
        d.total = this.Queries.SQL1[0].TOTAL
      })
      this.ChartData[0].actual = 0
      this.ChartData[1].actual = this.Queries.SQL1[0].REQ
      this.ChartData[2].actual = this.Queries.SQL1[0].PO
      this.ChartData[3].actual = this.Queries.SQL1[0].DELIVERED
      this.ChartData[4].actual = this.Queries.SQL1[0].INSTALLED
      // console.log(this.ChartData)
    },

    
    getMatrixProps: (selection) => {
      let transform = selection.attr('transform')
      let matrixEls = transform.substring(transform.indexOf('(') + 1, transform.indexOf(')')).split(' ')
      let elWidth = selection.node().getBoundingClientRect().width
      let elHeight = selection.node().getBoundingClientRect().height
      // console.log(selection.text(), elWidth, elHeight)
      return {
        a: matrixEls[0],
        b: matrixEls[1],
        c: matrixEls[2],
        d: matrixEls[3],
        dx: matrixEls[4],
        dy: matrixEls[5],
        cx: Number(matrixEls[4]) + elWidth / 2,
        cy: Number(matrixEls[5]) - elHeight / 4,
      }
    }
    
  }
}