import * as d3 from 'd3'

export default {
  methods: {
    setEvent_Block() {
      this.Goto_Block_Summary = (selection, d) => {
        selection
        .on('mouseover', () => {
          if(this.stage != this.STG_Lv3_Block_Division && this.stage != this.STG_Lv4_Block_Division) return
          d3.select(`#Blk_${d.Blk}`).transition().style('opacity', 0.5)
        })
        .on('mouseout', () => {
          if(this.stage != this.STG_Lv3_Block_Division && this.stage != this.STG_Lv4_Block_Division) return
          d3.select(`#Blk_${d.Blk}`).transition().style('opacity', 1)
        })
        .on('click', () => {
          if(this.stage != this.STG_Lv3_Block_Division && this.stage != this.STG_Lv4_Block_Division) return

          //console.log(d);
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceSvgTabs',
              id: 286,
              no: 'LIBSVGTB-0001'
            },
            filters: { 
              BLOCK: d.Blk,
              MBLOCK: d.Blk,
              DESC: '',
              // DESC: `${d.AREA} (${d.STATUS})`,
            },
            iFilters: {
              filterString : '',
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })
      }
    },
  }
}
