import * as d3 from 'd3'

export default {
  methods: {
    /*
      call_Coordinate()
      call_AreaList()
    */

    call_Coordinate(selection, d) {
      selection
      .on('mouseover', () => {
        this.innerSvg.select(`#BOX-${d.code}`)
        .transition().duration(250)
        .style('opacity', .5)

        this.innerSvg.select(`#AREA-${d.code}`)
        .transition().duration(250)
        .attr('transform', `translate(0, 0) scale(1)`)
        .style('opacity', 1)
        
        if (this.route.to.path !== 'Lev1') return

        // Animation effect for displaying the Package Group
        this.innerSvg.select(`#package_group_${d.code}`) 
        .transition().duration(250)
        .style('opacity', 1)

        // Animation effect for scrolling the Package box
        this.innerSvg.select(`#package_box_${d.code}`)
        .transition().duration(500)
        .attr('height', d.height)

        // Animation effect for expanding the area list by package
        this.Areas.filter(f => f.package == d.code).forEach((a,i) => {
          this.innerSvg.select(`#package_area_list_${a.code}`)
          .transition().duration(i*d.duration)
          .attr('transform', `translate(${5}, ${15 + (i*15)})`)
          .style('opacity', 1)
        })
      })

      .on('mouseout', () => {
        this.innerSvg.select(`#BOX-${d.code}`)
        .transition().duration(250)
        .style('opacity', 0)

        this.innerSvg.select(`#AREA-${d.code}`)
        .transition().duration(250)
        .attr('transform', `translate(${d.x}, ${d.y}) scale(0)`)
        .style('opacity', 0)

        if (this.route.to.path !== 'Lev1') return
        
        // Animation effect for displaying the Package Group
        this.innerSvg.select(`#package_group_${d.code}`)
        .transition().duration(250)
        .style('opacity', 0)

        // Animation effect for scrolling the Package box
        this.innerSvg.select(`#package_box_${d.code}`)
        .transition().duration(500)
        .attr('height', 0)

        // Animation effect for expanding the area list by package
        this.Areas.filter(f => f.package == d.code).forEach((a,i) => {
          this.innerSvg.select(`#package_area_list_${a.code}`)
          .attr('transform', `translate(${5}, ${15})`)
          .style('opacity', 0)
        })
      })
    },


    call_SEIList(selection, d) {
      selection
      .on('mouseover', () => {
        d3.select(`#SEI-${d.SEI}`).transition().duration(300).attr('font-weight', 'bold')
        d3.select(`#BOX-${d.SEI}`).transition().duration(300).style('opacity', 0.7)
        d3.select(`#MDR-CODE-${d.SEI}`).transition().duration(300).style('fill', '#83D2F5').style('stroke', '#83D2F5')
        d3.select(`#LINE-${d.SEI}`).select('line').transition().duration(300).style('stroke', '#83D2F5').style('stroke-width', 2)
        d3.select(`#LINE-${d.SEI}`).select('circle').transition().duration(300).attr('r', 3)
        d3.select(`#MDR-${d.SEI}`).select('rect').transition().duration(300).style('stroke', '#83D2F5').attr('stroke-width', 2)
      })
      .on('mouseout', () => {
        d3.select(`#SEI-${d.SEI}`).transition().duration(300).attr('font-weight', 'normal')
        d3.select(`#BOX-${d.SEI}`).transition().duration(300).style('opacity', 0)
        d3.select(`#MDR-CODE-${d.SEI}`).transition().duration(300).style('fill', '#fff').style('stroke', '#848484')
        d3.select(`#LINE-${d.SEI}`).select('line').transition().duration(300).style('stroke', '#848484').style('stroke-width', 0.5)
        d3.select(`#LINE-${d.SEI}`).select('circle').transition().duration(300).attr('r', 2)
        d3.select(`#MDR-${d.SEI}`).select('rect').transition().duration(300).style('stroke', '#333').attr('stroke-width', 0.2)
      })
      .on('click', () => { 
        this.currentPKG = d.PKG
        this.currentSEI = d.SEI
        this.link_Action(d.SEI) }) // --->
    },


    call_MDR(selection, d) {
      selection
      .on('mouseover', () => {
        d3.select(`#SEI-${d.SEI}`).transition().duration(300).attr('font-weight', 'bold')
        d3.select(`#BOX-${d.SEI}`).transition().duration(300).style('opacity', 0.7)
        d3.select(`#MDR-CODE-${d.SEI}`).transition().duration(300).style('fill', '#83D2F5').style('stroke', '#83D2F5')
        d3.select(`#LINE-${d.SEI}`).select('line').transition().duration(300).style('stroke', '#83D2F5').style('stroke-width', 2)
        d3.select(`#LINE-${d.SEI}`).select('circle').transition().duration(300).attr('r', 3)
        d3.select(`#MDR-${d.SEI}`).select('rect').transition().duration(300).style('stroke', '#83D2F5').attr('stroke-width', 2)
        
      })
      .on('mouseout', () => {
        d3.select(`#SEI-${d.SEI}`).transition().duration(300).attr('font-weight', 'normal')
        d3.select(`#BOX-${d.SEI}`).transition().duration(300).style('opacity', 0)
        d3.select(`#MDR-CODE-${d.SEI}`).transition().duration(300).style('fill', '#fff').style('stroke', '#848484')
        d3.select(`#LINE-${d.SEI}`).select('line').transition().duration(300).style('stroke', '#848484').style('stroke-width', 0.5)
        d3.select(`#LINE-${d.SEI}`).select('circle').transition().duration(300).attr('r', 2)
        d3.select(`#MDR-${d.SEI}`).select('rect').transition().duration(300).style('stroke', '#333').attr('stroke-width', 0.2)
      })
      .on('click', () => { 
        this.currentPKG = d.PKG
        this.currentSEI = d.SEI
        this.currentDISC = ''
        this.link_Action() }) // --->
    },

    event_Link(selection, d) {
      //if(this.linkStatus) return
      selection
      .on('mouseover', () => {
          d3.select(`#link_${d.CODE}`)
          .transition().duration(50).attr('cursor', 'pointer').attr('opacity', .4)
        })
      .on('mouseout', () => {
          d3.select(`#link_${d.CODE}`)
          .transition().duration(50).attr('cursor', 'default').attr('opacity', 0)
        })
      .on('click', () => { 
        this.currentPKG = d.PKG == 'TOTAL' ? '' : d.PKG
        this.currentSEI = ''
        this.currentDISC = d.DISC
        this.link_Action() }) // --->
    },

    link_Action() {
      // Get Modal Number
      let dataSource = {KEY1:this.currentDISC, KEY2:this.currentPKG, KEY3:this.currentSEI }
      let columnProps = this.getRequestColumnProps('request', dataSource, this.ColumnProps)

      if(!columnProps) return

      let request_ = {
        dataType: 'row',
        action: {
          type: 'direct',
          target: columnProps.action.target,
          component: columnProps.action.component,
          id: columnProps.action.id,
          no: columnProps.action.no
        },
        filters     : {
          ...this.FilterValues,
          ...columnProps.filters
        },
        iFilters    : {
          filterString : columnProps.iFilters.filterString || '',
          inputFilter  : ''
        }
      }
      this.$emit('request-action', request_)
    },
  }
}