<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    
    FilterValues: {
      type: Object,
      default: () => ({})
    },
    
    // Chart Data
    Canvas: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },

    // Database
    DataItems: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({
        milestone: [],
        note: '',
      })
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()

      // 'complete' must be executed to finish the parent process.
      // It sends 'this.localId' & XmlString for some case to make 
      // displaying image easy.
      this.complete()
    },

    drawChart() {
      
      this.chart_QVD_Summary('a1')
     
    },

  }
}
</script>