import * as d3 from 'd3'
import { entries } from 'd3'

export default {
  data: () => ({
    scaleY: null,
    exist: [1010,1020,1911,1912,2110,2120,2210,2220,2400,3000,3010,3020,3030,3040,3200,3400,3430,3470,4100,4330,4340,4610,4620,4640,4650,4730,5010,5030,5210,5410]
  }),

  methods: {

    draw_SystemBoundary(_system, _description) {

      this.System = _system
      this.mode_Menu = 'B'
      let item = null, style = null, data = null

      this.SVG_SystemBoundary = this.svg.append('svg')

      this.SVG_SystemBoundary
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .style('opacity', 0)

      let background = this.SVG_SystemBoundary

      background
      .append('rect')
      .attr('transform', `translate(0,0)`)
      .attr('width', this.Canvas.CanvasWidth).attr('height', this.Canvas.CanvasHeight)
      .attr('fill', '#fff').attr('stroke', '#bcbcbc').attr('stroke-width', 0.3)

      let formTitle = this.SVG_SystemBoundary
      formTitle
      .append('image')
      .attr('transform', `translate(30,20)`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/Common/demo_logo.svg`)

      formTitle
      .append('text')
      .attr('transform', `translate(30, 90)`)
      .style('font-family', 'roboto').style('font-size', 16).style('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(`${this.System} - ${_description}`)

      // formTitle
      // .append('text')
      // .attr('transform', `translate(30, 193)`)
      // .style('font-family', 'roboto').style('font-size', 10).style('fill', '#bcbcbc').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      // .text('HULL Block Division Rev.2 (01 Jun 2018) / Block Schedule (07-Feb-2020)')

      formTitle
      .append('image')
      .attr('transform', `translate(30,115)`).style('width', 230)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/SystemBoundary/img/${this.System}.jpg`)

      if (this.exist.includes(this.System)) {
        formTitle
        .append('image')
        .attr('id', `svg_${this.System}`)
        .attr('transform', `translate(400,50)`).style('width', 950).style('opacity', 0)
        d3.select(`#svg_${this.System}`).transition().delay(400).duration(700).style('opacity', 1)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/Visualization/SystemBoundary/svg/${this.System}.svg`)
      } else {
        formTitle
        .append('image')
        .attr('id', `svg_${this.System}`)
        .attr('transform', `translate(400,50)`).style('width', 950).style('opacity', 0)
        d3.select(`#svg_${this.System}`).transition().delay(400).duration(700).style('opacity', 1)
        .attr('xlink:href', require(`./system_tbd.png`))
      }
      

      formTitle
      .append('text')
      .attr('transform', `translate(130, 300)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text('LOCATION')

      formTitle
      .append('text')
      .attr('transform', `translate(130, 315)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text('Looking North-East')


      // button Menu_Back
      let Menu_Back = formTitle

      .append('g')
      .attr('id', 'Menu_Back')
      .attr('transform', `translate(400, 94)`)
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(398, 92) scale(1.2)`)
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(400, 94) scale(1)`)
      })
      .on('click', () => { 

        let request_ = {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'filter',
            component: '',
            id: '',
            no: ''
          },
          filters: {},
          iFilters: {}
        }

        this.$emit('request-action', request_)

        this.SVG_SystemBoundary.transition().duration(1000).style('opacity', 0)
        setTimeout(() => {
          this.SVG_SystemBoundary.html()
          this.SVG_SystemBoundary.remove()
          this.SVG_SystemBoundary = null
        }, 1000);
        
      })

      Menu_Back
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .style('width', 16).style('height', 16).style('fill', '#44A9DF')

      Menu_Back
      .append('text')
      .attr('transform', `translate(8, 12)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#fff').attr('text-anchor', 'middle')
      .text('B')


      // draw_SubSystemList
      this.draw_SubSystemList()


      // Chart for MC
      this.Header_TitleBox( this.SVG_SystemBoundary, 'Mechanical Completion', 50, 740, 143, 20, 2, '#333', '#E0E0DF')

      item = this.dataSet.find(f=> f.SYSTEM == this.System)
      style = {
        id: 'MC',
        title: 'ITRs(A)',
        x: 50,
        y: 765,
        width: 210,
        height: 40,
        cell: [10, 90, 140, 190],
        bColor: '#88C8EB',
        opacity: 1,
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'A'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'A' AND [STATUS] = 'Y'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'A' AND [STATUS] = 'N'",
              inputFilter  : ''
            }
          },
        ],
      }
      data = {TOTAL:item.A_T, ACTUAL:item.A_A, REMAIN:item.A_O,}
      this.SummaryTable2( this.SVG_SystemBoundary, data, style) // ------------>


      style = {
        id: 'Punch',
        x: 50,
        y: 810,
        width: 210,
        height: 65,
        cell: [10, 90, 140, 190],
        bColor: '#83D2F5',
        opacity: 1,
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 193,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 193,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[COMPLETED_DATE] IS NOT NULL",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 193,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[COMPLETED_DATE] IS NULL",
              inputFilter  : ''
            }
          },
        ],
      }
      let data1 = ['Cat. A', item.PA_T, item.PA_A, item.PA_O]
      let data2 = ['Cat. B', item.PB_T, item.PB_A, item.PB_O]
      this.SummaryTable3(this.SVG_SystemBoundary, data1, data2, style) // ------------>

      
      this.Radar.chart.size = 150
      style = {
        x: 430,
        y: 860,
      }
      data = this.Queries.SQL4.filter(f => f.SYSTEM == this.System && f.PHASE == 'PKG')
      this.draw_Radar( this.SVG_SystemBoundary, data, style) // ------------>
      

      this.Header_TitleBox( this.SVG_SystemBoundary, 'Pre-Commissioning', 1050, 740, 125, 20, 3, '#333', '#E0E0DF') // ------------>


      data = {TOTAL:item.B_T, ACTUAL:item.B_A, REMAIN:item.B_O,}
      style = {
        id: 'SC2',
        title: 'ITRs(B)',
        x: 1050,
        y: 765,
        width: 210,
        height: 40,
        cell: [10, 90, 140, 190],
        bColor: '#83D2F5',
        opacity: 1,
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'B'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'B' AND [STATUS] = 'Y'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'B' AND [STATUS] = 'N'",
              inputFilter  : ''
            }
          },
        ],
      }
      this.SummaryTable2( this.SVG_SystemBoundary, data, style) // ------------>


      // Progress Summary Table
      let colStyle = [
                      {name: ''        , x: 70, align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Total'   , x: 110, align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Closed'  , x: 150, align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Remain'  , x: 190, align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Prog.(%)', x: 200, align: 'start' , tColor:'#44A9DF'}
      ]
      let cellStyle = {
        x: 580, 
        y:790, 
        id: 'A', 
        barSize:100, 
        barColor: '#B4E3FA', 
        tColor: '#757575', 
        fSize: [9, 10, 9],
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'A'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'A' AND [STATUS] = 'Y'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'A' AND [STATUS] = 'N'",
              inputFilter  : ''
            }
          },
        ]
      }
      let dataTrans = this.Queries.SQL4.filter(f => f.SYSTEM == this.System && f.PHASE == 'A' && f.DISC !== 'OV')
      
      this.ProgressTable_01(this.SVG_SystemBoundary, dataTrans, colStyle, cellStyle, 'A ITRs Status', 'on') // ------------>


      // Progress Summary Table
      colStyle = [
                      {name: ''        , x: 70,  align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Total'   , x: 110, align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Closed'  , x: 150, align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Remain'  , x: 190, align: 'end'   , tColor:'#44A9DF'},
                      {name: 'Prog.(%)', x: 200, align: 'start' , tColor:'#44A9DF'}
      ]
      cellStyle = {
        x: 1050, 
        y:855, 
        id: 'B', 
        barSize:100, 
        barColor: '#B4E3FA', 
        tColor: '#757575', 
        fSize: [9, 10, 9],
        requests: [
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'B'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'B' AND [STATUS] = 'Y'",
              inputFilter  : ''
            }
          },
          {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceDatatable',
              id: 192,
              no: ''
            },
            filters: { 
              SUBSYSTEM: this.System
            },
            iFilters: {
              filterString : "[STAGE] = 'B' AND [STATUS] = 'N'",
              inputFilter  : ''
            }
          },
        ]
      }
      dataTrans = this.Queries.SQL4.filter(f => f.SYSTEM == this.System && f.PHASE == 'B' && f.DISC !== 'OV')
      
      this.ProgressTable_01(this.SVG_SystemBoundary, dataTrans, colStyle, cellStyle, 'B ITRs Status', 'on') // ------------>


      
      this.SVG_SystemBoundary.transition().duration(1000).style('opacity', 1)
    },


  }
  
}
