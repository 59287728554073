<template>
  <j-overlay 
    v-model="opened" 
    content-right
    :id="localId"
  > 
    <div id="j-modal-slide--content-wrapper" class="j-modal-slide--content-wrapper">
      <j-modal-slide-tab
        class="j-modal-slide--tab"
        ref="tab"
        v-if="showTab" 
        :color-index="tabColorIndex"
        :label="tabLabel" 
        @close="onClose"
        @resize="onResizeTab"
      />

      <div class="j-modal-slide--content" :style="{...styles}">
        <component 
          class="component--content-target"
          ref="target"
          :class="blindScreen"
          :is="component"
          :filters="filters"
          :target="target"
          @complete="(v) => { onComplete(v) }"
          @request-action="onRequestedAction"
        />

        <j-overlay 
          absolute
          :value="loading"
        >
          <j-spinner-orbit
            :border="3"
            :color="'#b3e5fc'"
            :animation-duration="1000"
            :size="150"
          />
        </j-overlay>
      </div>
    </div>
  </j-overlay>
</template>
<script>
import '@/assets/stylus/ui/component/_jModalSlideComponent.styl'

import __C from '@/primitives/_constant_'
import * as d3 from 'd3'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import Download from '@/mixins/download.mixin'
import JServiceComponents from '@/components/JServiceComponentTargets'

export default {
  name: 'j-modal-slide-component',
  mixins: [
    SafeIdMixin,
    Download
  ],
  components: {
    ...JServiceComponents
  },
  props: {
    component: String,
    filters: {
      type: Object,
      default: () => ({})
    },
    target: {
      type: Object,
      default: () => ({})
    },
    value: null,
  },
  data: () => ({
    blindScreen: 'on',
    layoutAttrs: {},
    // loading: false,
    modalIndex: 0,
    showTab: false,
    attach: 'viewer_3d_content',
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.SLIDE_MODAL, [
      'modals', 'tabs', 'modalComponent', 'modalIndexs', 'threedRequest'
    ]),

    ...mapGetters(__C.STORE_NAMESPACE.SLIDE_MODAL, [
      'exportInfo'
    ]),

    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },
    styles() { 
      let style = {}
      if(this.layoutAttrs.background && this.layoutAttrs.background != 'transparent') style['background-color'] = this.layoutAttrs.background
      return style
    },
    tabLabel() { return this.modals[this.modalIndex].name },
    tabColorIndex() {  return this.tabs[this.modalIndex].colorIndex || 0 }
  },
  watch: {
    opened(val) {
      if (val) {
        this.setThreedRequest(null)
        this.loading = true
        setTimeout(() => { this.run() })
      } 
      else if (!val && this.threedRequest) {
        this.$emit('request-action', this.threedRequest)
      }
      else return
      
    }
  },
  created() {
    // console.log('is JModalSlideComponet', this)
    this.localId = this.id || 'j-modal-slide__' + this.safeId('')
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown)
    this.insertBefore()
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    window.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.SLIDE_MODAL, [
      'appendModal','appendModalIndexs', 'removeModal', 'removeModalIndex', 'removeModalAll', 'updateTabStatus', 'setLayoutAttrs', 'setModalIdx', 'setModalComponent', 'setModalsComponent', 'setThreedRequest'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.SLIDE_MODAL, [
      'modalExist', 'createAction'
    ]),
    onRequestedAction(val) {
      let length_ = this.modals.length

      if (length_ > 1) {
        this.setThreedRequest(val)
        //modals의 전체 modal을 삭제 후 threed에 request action을 날린다
        this.onOverlayClick()
        
      } else {
        this.$emit('request-action', val)
        this.opened = false
      }
      
    },

    onComplete(v) {
      let widthable_ = true
      if(!v.dimention || !v.dimention.width) {
        widthable_ = false
        console.log(`[USER #DEBUG#] Need to be set component's dimention.`);
      }

      this.layoutAttrs = v
      
      d3.select(`#${this.localId}`)
      .select('.j-modal-slide--content')
      .transition()
      .duration(500)
      .style('width', widthable_ ? `${v.dimention.width}px` : 'unset')

      setTimeout(() => { 
        this.blindScreen = 'off'
        this.loading = false
      }, 500)

      // Configuring the layout & others for the Modal & its Tabs. ---------------
      setTimeout(() => { 
        (async () => {
          // For the SvgTabs' inline components. Inline Components cannot be 
          // registered as a Modal, except the first. SvgTabs should be registered 
          // when its first inline-component's onComplete event fired.
          if(await this.modalExist(this.localId)) return

          this.modalIndex = this.modals.length
          this.appendModal({
            modal: {
              id: `#${this.localId}`,
              idx: this.target.id,
              component: this.component,
              name: v.name,
              ...v.dimention,
              vComponent: () => this
            },
            tab: {
              modalId: `#${this.localId}`,
              colorIndex: 0,
            }
          })
          this.showTab = true

          setTimeout(() => { this.updateTabStatus({ index: this.modalIndex, data: { vComponent: () => this.$refs.tab } }) }, 100)
          setTimeout(() => { this.adjustModalStatus() }, 250)
        })()
      }, 500)
      // -------------------------------------------------------------------------

      if (this.component != 'ServiceSvgTabs') {
        // tab index와 tab내의 dashboard 또는 pageSvg의 index불일치로 인해 분리
        this.setLayoutAttrs(v)
        this.setModalIdx(this.target.id)
        this.setModalComponent(this.component)
      }
    },
    onKeydown(e) {
      if(e.key == 'Escape') this.onOverlayClick()
    },
    onResizeTab(v) {
      this.updateTabStatus({ data: { ...v.dimention }, index: this.modalIndex })
    },
    onClose() {
      this.blindScreen = 'on'

      d3.select(`#${this.localId}`)
      .select('.j-modal-slide--content')
      .transition()
      .delay(150)
      .duration(350)
      .style('width', '0px')

      setTimeout(() => { this.showTab = false }, 350)
      setTimeout(() => { 
        this.opened = false 
        this.remove()
      }, 500);
    },
    onOverlayClick() {
      let length_ = this.modals.length
      if(length_ < 1) return

      for(let i=length_-1;i>=0;i--) {
          this.modals[i].vComponent().showTab = false
          this.modals[i].vComponent().onClose()  
      }
    },
    insertBefore() {
      let attachement = document.getElementById(this.attach)
      if (!attachement) return
      let self = document.getElementById(this.localId)
      attachement.insertBefore(self, null)
    },
    adjustModalStatus() {
      let length_ = this.modals.length
      if(length_ < 1) return

      let width_ = this.modals[length_-1].width
      this.modals.forEach((m_, i) => {
        d3.select(this.tabs[i].modalId)
        .select('.j-overlay__scrim')
        .style('opacity', i===0?0.32:0)
        .on('click', i===length_-1?this.onOverlayClick:null)

        d3.select(m_.id)
        .select('.j-modal-slide--content')
        .transition()
        .duration(250)
        .style('width', `${width_}px`)
      })

      setTimeout(() => { this.adjustTabStatus() }, 250)
    },
    adjustTabStatus() {
      let length_ = this.modals.length
      if(length_ < 1) return

      let colorIndex_ = 0
      let top_ = 0
      for(let i=length_-1;i>=0;i--) {
        d3.select(this.tabs[i].modalId)
        .select('.j-modal-slide--tab')
        .transition()
        .duration(250)
        .style('top', `${top_}px`)

        this.tabs[i].vComponent().colorIndex = colorIndex_
        this.tabs[i].vComponent().draw()

        // For the next Tab's status
        colorIndex_++
        top_ = this.tabs[i].height - 33
      }
    },
    remove() {
      this.removeModal()
      this.removeModalIndex()

      setTimeout(() => {
        this.adjustModalStatus()
        this.adjustTabStatus()

        let modalInfo = this.modals[this.modals.length-1]

        if (!modalInfo) return

        this.setModalIdx(modalInfo.idx)

        this.setModalComponent(modalInfo.component)
        
        this.setLayoutAttrs({
          name: modalInfo.name,
          dimention: {
            width: modalInfo.width,
            height: modalInfo.height
          }
        })
      }, 250);
    },
    run() {
      d3.select(`#${this.localId}`)
      .select('.j-modal-slide--content')
      .transition()
      .duration(350)
      .style('width', '350px')

      setTimeout(() => { this.$refs.target.run() }, 350)
    }
  }
}
</script>

