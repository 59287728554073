import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    Shipment: [
      {name: 'Shipment Name',     value: '', y : 63},
      {name: 'Arrived at Yard',   value: '', y : 83},
      {name: 'Sail-away Plan',    value: '', y : 120},
      {name: 'Sailed-away Date ', value: '', y : 140},
    ]
  }),


  methods: {
    
    drawChart() {

      let NextShip = this.dataSet.find(f=> f.SA_ACTUAL == null)
      this.Shipment[0].value = NextShip.SHIP_NAME
      this.Shipment[1].value = NextShip.SHIP_DATE
      this.Shipment[2].value = NextShip.SA_PLAN
      this.Shipment[3].value = NextShip.SA_ACTUAL


      let Shipment = this.svg
      .append('g')
      .attr('id', `a`)
      .attr('transform', `translate(0,0)`)

      Shipment
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('rx', 5).attr('ry', 5).attr('width', 610).attr('height', 38).attr('fill', '#FEED57')

      Shipment
      .append('rect')
      .attr('x', 0).attr('y', 46).attr('rx', 5).attr('ry', 5).attr('width', 200).attr('height', 52).attr('fill', '#FEED57').attr('opacity', 0.64)

      Shipment
      .append('rect')
      .attr('x', 0).attr('y', 106).attr('rx', 5).attr('ry', 5).attr('width', 200).attr('height', 44).attr('fill', '#FEED57').attr('opacity', 0.22)

      // Text of Title
      Shipment
      .append('text')
      .attr('x', 15).attr('y', 20).attr('fill', '#333') .style('font-size', 20).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Oncoming sail-away schedule')

      
      this.Shipment.forEach(d => {
        Shipment
        .append('text')
        .attr('x', 100).attr('y', d.y).attr('fill', '#333') .style('font-size', 11).style('font-family', 'roboto').attr('text-anchor', 'end').attr('alignment-baseline', 'middle').text(d.name)

        Shipment
        .append('text')
        .attr('x', 125).attr('y', d.y).attr('fill', '#757575') .style('font-size', 11).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(d.value)
      })
      
      this.dataSet.filter(f=> f.SHIP_NAME == NextShip.SHIP_NAME && f.SA_PLAN == NextShip.SA_PLAN).forEach((d,i) => {
        Shipment
        .append('text')
        .attr('x', 273 + (i*100)).attr('y', 120).attr('fill', '#000') .style('font-size', 12).style('font-family', 'roboto').attr('text-anchor', 'middle').text(d.MOD)

        Shipment
        .append('image')
        .attr('id', `comming_${d.PAU}`)
        .attr('transform', `translate(${225 + (i*106)},45)`)
        .attr('width', 90)
        .attr('height', 75)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TCO/PAUs/${d.PAU}.png`)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
            d3.select(`#comming_${d.PAU}`).transition().duration(200).attr('transform', `translate(${225 + (i*106) -10},${30}) scale(1.2)`).attr('opacity', .2)
        })
        .on('mouseout', () => {
          d3.select(`#comming_${d.PAU}`).transition().duration(200).attr('transform', `translate(${225 + (i*106)},45)`).attr('opacity', 1)
        })
        .on('click', () => {
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',    // important!
              target: 'slide-modal',
              component: 'ServiceSvgTabs',
              id: 168,
              no: 'LIBSVGTB-0001'
            },
            filters     : {
              MOD: d.MOD,
            },
            iFilters    : {
              filterString : ``,
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })

        
        Shipment
        .append('text')
        .attr('x', 273 + (i*100)).attr('y', 135).attr('fill', '#757575') .style('font-size', 11).style('font-family', 'roboto').attr('text-anchor', 'middle').text(d.PAU)
      })
    },

    

  }
}
