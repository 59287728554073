import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    draw_Completion() {
      
      let y = (i) => 13 * i
      let QVD = ['A1','B1','D1',]
      let color = ['#FFDA00','#83D2F5','#F7BACF',]
      var pregress = 0

      let completion = this.chartGroup
      .append('g')
      .attr('transform', 'translate(50, 22)')
      .selectAll('g')
      .data(this.dataSet)
      .enter()

      QVD.forEach((_, j) => {
        let progWidth = (d) => {
          let total = d[`${QVD[j]}_T`]
          let completed = d[`${QVD[j]}_A`]
          pregress = (completed / total) * 100
          return pregress
        }
        completion
          .append('text')
          .attr('id', (d,i) => `${QVD[j]}_${i}`)
          .attr('transform', d => `translate(${d.x - 40}, ${d.y + y(j)})`).attr('font-weight', 500)
          .style('font-family', 'roboto').attr('font-size', 9).attr('fill', '#000').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
          .text(QVD[j])

        completion
          .append('text')
          .attr('transform', d => `translate(${d.x - 3}, ${d.y + y(j)})`)
          .style('font-family', 'roboto').attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
          .text(d => Number(d[`${QVD[j]}_T`]).toLocaleString())

        completion
          .append('rect')
          .attr('id', (d, i) => `QvdPlanBar${i}_${j}`)
          .attr('x', d => d.x + 0)
          .attr('y', d => d.y + y(j))
          .attr('width', 0)
          .attr('height', 10)
          .attr('fill', '#E0E0DF')
          .transition()
          .duration(500)
          .attr('width', 100)

        completion
          .append('rect')
          .attr('id', (d, i) => `QvdActualBar${i}_${j}`)
          .attr('x', d => d.x + 0)
          .attr('y', d => d.y + y(j))
          .attr('width', 0)
          .attr('height', 10)
          .attr('opacity', 0.7)
          .attr('fill', color[j])
          .transition()
          .duration(500)
          .attr('width', d => progWidth(d))

        completion
          .append('text')
          .attr('id', (_, i) => `TXT_${i}_${j}`)
          .attr('transform', d => {
            if (progWidth(d) < 30) { return  `translate(${d.x + progWidth(d) + 2}, ${d.y + y(j)+1})` } 
            else { return  `translate(${d.x + progWidth(d) - 2}, ${d.y + y(j)+1})` }
          })
          .style('font-family', 'roboto').style('font-size', 9)
          .attr('fill', '#757575')
          .attr('text-anchor', d => {
            if (progWidth(d) < 30) { return 'start' } 
            else { return 'end' }
          })
          .attr('alignment-baseline', 'hanging')
          .text(d => Number(d[`${QVD[j]}_A`]).toLocaleString())
        
          completion
          .append('rect')
          .attr('id', (d, i) => `QvdBtnBar${i}_${j}`)
          .attr('x', d => d.x -46).attr('y', d => d.y + y(j))
          .attr('width', 146).attr('height', 10)
          .attr('fill', 'transparent')
          .on('mouseover', (d, i) => {
            d3.select(`#QvdBtnBar${i}_${j}`)
            .style('cursor', 'pointer')
            
            d3.select(`#${QVD[j]}_${i}`)
            .transition().duration(400).attr('fill', '#E0E0DF')
            .transition().duration(400).attr('fill', '#333')
            
            d3.select(`#QvdPlanBar${i}_${j}`)
            .transition().duration(400).attr('width', 0)
            .transition().duration(400).attr('width', 100)

            d3.select(`#QvdActualBar${i}_${j}`)
            .transition().duration(400).attr('width', 0)
            .transition().duration(400).attr('width', d => progWidth(d))
          })
          .on('mouseout', (d, i) => { 
            d3.select(`#QvdBtnBar${i}_${j}`)
            .style('cursor', 'default')
          })
          .on('click', (d, i) => { 
            console.log(this.dataSet[i].MOD, QVD[j])
          })

      })

      // Punch Summary Table
      // Category A / B / C
      completion
        .append('text')
        .attr('id', (d,i) => `Punch${i}`)
        .attr('transform', d => `translate(${d.x - 30}, ${d.y + 42})`).attr('font-weight', 500)
        .style('font-family', 'roboto').attr('font-size', 9).attr('fill', '#000').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
        .text('PUNCH')
        
      completion
        .append('text')
        .attr('transform', d => `translate(${d.x - 45}, ${d.y + 52})`).attr('font-weight', 500)
        .style('font-family', 'roboto').style('font-size', 9).attr('fill', '#F7BACF').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text('(Active)')

      completion
        .append('rect')
        .attr('x', d => d.x).attr('y', d => d.y + 40)
        .attr('width', 66).attr('height', 30)
        .attr('fill', '#fff').attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)
      
      completion
        .append('line')
        .attr('x1', d => d.x).attr('y1', d => d.y + 55).attr('x2', d => d.x + 66).attr('y2', d => d.y + 55).attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)
      completion
        .append('line')
        .attr('x1', d => d.x + 33).attr('y1', d => d.y + 40).attr('x2', d => d.x + 33).attr('y2', d => d.y + 70).attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)
      
      completion
        .append('text')
        .attr('transform', d => `translate(${d.x + 20}, ${d.y + 43})`)
        .style('font-family', 'roboto').style('font-size', 9).attr('fill', '#000').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging').text('A')
      completion
        .append('text')
        .attr('transform', d => `translate(${d.x + 52}, ${d.y + 43})`)
        .style('font-family', 'roboto').style('font-size', 9).attr('fill', '#000').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging').text('B')
      // Active Punch
      completion
        .append('text')
        .attr('transform', d => `translate(${d.x + 18}, ${d.y + 59})`)
        .style('font-family', 'roboto').style('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
        .text(d => d.PA)
      completion
        .append('text')
        .attr('transform', d => `translate(${d.x + 50}, ${d.y + 59})`)
        .style('font-family', 'roboto').style('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
        .text(d => d.PB)
      completion
        .append('text')
        .attr('transform', d => `translate(${d.x + 83}, ${d.y + 59})`)
        .style('font-family', 'roboto').style('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
        .text(d => d.PC)
      
      completion
        .append('rect')
        .attr('id', (d,i) => `aniPunch${i}`)
        .attr('x', d => d.x).attr('y', d => d.y + 40).attr('width', 0).attr('height', 30).attr('fill', '#bcbcbc').attr('opacity', .1)

      completion
        .append('rect')
        .attr('id', (d,i) => `maskPunch${i}`)
        .attr('x', d => d.x - 46).attr('y', d => d.y + 40).attr('width', 146).attr('height', 30).attr('fill', 'transparent')
        .on('mouseover', (d, i) => {
          d3.select(`#maskPunch${i}`)
          .style('cursor', 'pointer')
          d3.select(`#Punch${i}`)
          .transition().duration(400).attr('fill', '#E0E0DF')
          .transition().duration(400).attr('fill', '#333')
          d3.select(`#aniPunch${i}`)
          .transition().duration(400).attr('width', 100)
          .transition().duration(400).attr('width', 0)
          
        })
        .on('mouseout', (d, i) => { 
          d3.select(`#maskPunch${i}`)
          .style('cursor', 'default')
        })
        .on('click', (d, i) => { 
          console.log(this.dataSet[i].MOD, 'Punch')
        })
    },



   

  }
}