import * as d3 from 'd3'
// ------------------------------------------------------------------------------- Global
export default {
  data:() => ({
    props:[
      { discIcon: 'Architecture'},
      { discIcon: 'Electrical'},
      { discIcon: 'Fire & Safety'},
      { discIcon: 'Hvac'},
      { discIcon: 'Instrument'},
      { discIcon: 'Insulation'},
      { discIcon: 'Mechanical'},
      { discIcon: 'Painting'},
      { discIcon: 'Piping'},
      { discIcon: 'Structure'},
    ],
    legend: 
    [
      { column: 'WK_PLAN_QTY', color: '#E0E0E0', shape: 'rect', text: 'Wk Plan Qty.', axis: 'left', x: 0, y: 0 },
      { column: 'WK_ACTUAL_QTY', color: '#81C784', shape: 'rect', text: 'Wk Actual Qty.', axis: 'left', x: 72, y: 0 },
      { column: 'CUM_PLAN_PROG', color: '#757575', shape: 'line', text: 'Cum. Plan Prog.', axis: 'right', lineShape: 'dash', x: 152, y: 0 },
      { column: 'CUM_ACTUAL_PROG', color: '#D81B60', shape: 'line', text: 'Cum. Actual Prog.', axis: 'right',  x: 237, y: 0},
      { column: 'WKS4_TREND', color: '#039BE5', shape: 'line', text: '4Weeks Trend', axis: 'right',lineShape: 'dash', x: 327, y: 0 }
    ],
    margin: { top: 80, left: 50, bottom: 50, right: 50 },
    width: 400,
    height: 270,
    filter: []
  }),
  methods: {
    drawChart() {

      let discIconMap = this.DataItems.map(d => d.DISC_DESCR)
      let props = this.props.filter(d => d.discIcon == discIconMap)[0]
      // console.log(discIconMap)
      // console.log(props)
      let values_ = this.DataItems

      let svg = this.svg
      .append('g').attr('class', 'disc_status_wrap').attr('transform', `translate(0, 0)`)
      if (this.DataItems[0].PLAN == null && this.DataItems[0].ACTUAL == null && this.DataItems[0].DELTA == null) { 
        let progress_chart = svg
        progress_chart
        .append('rect')
        .attr('x', 0).attr('y', 0).attr('width', 510).attr('height', 270).attr('fill', '#fff')
        progress_chart
        .append('text').attr('x', 510/2).attr('y', 270/2).attr('text-anchor', 'middle')
        .text('N/A').attr('font-size', '30px')
        .attr('fill', '#ccc')
        return
      }

      let discClick = svg
      .append('g').attr('class', 'disc_status_inner').attr('transform', `translate(0, 20)`)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        discClick.style('opacity', '0.7')
      })
      .on('mouseleave', () => {
        discClick.style('opacity', '1')
      })
      .on('click', () => {
        if (!discIconMap[0]) return
        
        let request_ = this.getRequestColumnProps(discIconMap, {}, this.ColumnProps)
        this.$emit('request-action', request_)
      })

      let legendGroup = discClick.append('g').attr('class', 'ogm_status_group_legend__').attr('transform', `translate(0, -3)`)
      let legendW = 90/3.5

      legendGroup.append('text').attr('x', 0).attr('y', -3).attr('text-anchor', 'start')
      .text(discIconMap).style('font-size',13).attr('fill', '#212121').style('font-weight','bold')

      // Plan
      legendGroup.append('rect')
      .attr('x', 0).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', legendW).attr('height', 20).attr('fill', '#EEEEEE')

      legendGroup.append('text').attr('x', legendW/2).attr('y', 23).attr('text-anchor', 'middle')
      .text('Plan')
      .style('font-size',10)
      .attr('fill', '#212121')

      // Actual
      legendGroup.append('rect')
      .attr('x', legendW+5).attr('y', 10).attr('rx', 3).attr('ry', 3).attr('width', legendW+5).attr('height', 20).attr('fill', '#66BB6A')

      legendGroup.append('text').attr('x', (legendW*3+15)/2).attr('y', 23).attr('text-anchor', 'middle')
      .text('Actual')
      .style('font-size',10)
      .attr('fill', '#fff')

      // Delta
      legendGroup.append('rect')
      .attr('x', (legendW+8)*2).attr('y', 10.5).attr('rx', 3).attr('ry', 3).attr('width', legendW).attr('height', 20).attr('fill', 'Transparent').attr('stroke', '#ccc').attr('stroke-width',0.5)

      legendGroup.append('text').attr('x', (legendW*5+32)/2).attr('y', 23).attr('text-anchor', 'middle')
      .text('Delta')
      .style('font-size',10)
      .attr('fill', 'red')

      let group = discClick.append('g').attr('class', 'ogm_status_group__').attr('transform', `translate(0.5, 35)`)

      values_.find((v) => {
        let g = group.append('g').attr('class', `${v.DISC_DESCR}_status__`).attr('transform', `translate(0, 6)`)
        const imageBox = 90
        // Imgae box
        g.append('rect')
        .attr('x', 0).attr('y', 0)
        .attr('rx', 3).attr('ry', 3).attr('width', imageBox).attr('height', imageBox)
        .attr('fill', v.DELTA > 0 ? '#4CAF50' : v.DELTA === 0 ? '#fff' : '#F44336')
        .attr('opacity', '0.7')

        if (v.ACTUAL !== 0) {
          g.append('rect')
          .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', imageBox)
          .attr('height', parseInt(imageBox - (v.ACTUAL < 0 ? v.ACTUAL * -1 : v.ACTUAL) / 100 * imageBox) )
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        } else {
          g.append('rect')
          .attr('x', 0).attr('y', 0).attr('rx', 3).attr('ry', 3).attr('width', imageBox)
          .attr('height', imageBox)
          .attr('fill', '#fff')
          .attr('opacity', '0.6')
        }
        g.append('image')
        .attr('id', `omg_img_${v.DISC_DESCR.toLowerCase()}`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Modules/LQDashboard/icons/${discIconMap[0].toLowerCase()+'_scale'}.png`)
        .attr('transform','translate(0,0)scale(0.5)')
        
        // Plan
        g.append('rect')
        .attr('x', 0).attr('y', imageBox+15).attr('rx', 3).attr('ry', 3).attr('width', imageBox).attr('height', 27).attr('fill', '#EEEEEE')

        g.append('text').attr('x', imageBox/2).attr('y', imageBox+34).attr('text-anchor', 'middle')
        .text(v.PLAN != null ? v.PLAN.toFixed(2) + '%' : 0 + '%')
        .style('font-size',14)
        .attr('fill', '#212121')

        // Actual
        g.append('rect')
        .attr('x', 0).attr('y', imageBox+52).attr('rx', 3).attr('ry', 3).attr('width', imageBox).attr('height', 27).attr('fill', '#66BB6A')

        g.append('text').attr('x', imageBox/2).attr('y', imageBox+71).attr('text-anchor', 'middle')
        .text(v.ACTUAL != null ? v.ACTUAL.toFixed(2) + '%': 0 + '%')
        .style('font-size',14)
        .attr('fill', '#fff')

        // Delta
        g.append('rect')
        .attr('x', 0).attr('y', imageBox+90.5).attr('rx', 3).attr('ry', 3).attr('width', imageBox).attr('height', 27).attr('fill', 'Transparent').attr('stroke', '#ccc')

        g.append('text').attr('x', imageBox/2).attr('y', imageBox+109).attr('text-anchor', 'middle')
        .text(v.DELTA > 0 ? '▲ ' + v.DELTA.toFixed(2) +'%' : v.DELTA == null ? 0 + '%': '▼ ' + v.DELTA.toFixed(2) +'%')
        .style('font-size',14)
        .attr('fill', v.DELTA > 0 ? 'green' : v.DELTA === 0 ? '#ccc' : 'red')
      })
      //-----------------------------------------------------------------------------------------------------------DataItems Icon Chart
      //----------------------------------------------------------------------------------------------------this.Queries.SQL1 Progress Chart

      //원래는 this.DataItems[0] === 0 이면 return 하면되는데 이상한 데이터가 들어와서 이렇게 작업함 
      if (!this.Queries.SQL1) { 
        let progress_chart = svg
        progress_chart
        .append('rect')
        .attr('x', 0).attr('y', 0).attr('width', 510).attr('height', 270).attr('fill', '#fff')
        progress_chart
        .append('text').attr('x', 510/2).attr('y', 270/2).attr('text-anchor', 'middle')
        .text('N/A').attr('font-size', '30px')
        .attr('fill', '#ccc')
        return
      }
      const list = this.Queries.SQL1
        .map(v => {
          return {
            CATEGORY: v['WK_NO'],
            VALUES: [
              { KEY: 'CUM_PLAN_PROG', VALUE: v['CUM_PLAN_PROG'] },
              { KEY: 'CUM_ACTUAL_PROG', VALUE: v['CUM_ACTUAL_PROG'] },
              { KEY: 'WK_PLAN_QTY', VALUE: v['WK_PLAN_QTY'] },
              { KEY: 'WK_ACTUAL_QTY', VALUE: v['WK_ACTUAL_QTY'] },
              { KEY: 'WKS4_TREND', VALUE: v['WKS4_TREND'] }
            ]
          }
        })
      this.filter = this.legend.map(d => d.column)

      const width = this.width - 30
      const height = this.height - 15

      const sidesvg = svg
        .append('svg')
        .attr('width', `${width}`)
        .attr('height', `${height}`)

        sidesvg
        .append('g')
        .attr('id','chart_progress')
        .attr('transform', `translate(155, 70)`)

      let legendGoup = sidesvg
        .append('g')
        .attr('id','chart_legend')
        .attr('transform', `translate(100,30)`)

      const legendName = this.legend
      const legends = legendGoup.selectAll('.legend').data(legendName)

      const filter = this.filter
      const render = this.render

      const legend = legends
        .enter()
        .append('g')
        .attr('class', 'legend')
        .attr('transform', (d, i) => {
          return `translate(${d.x}, ${d.y})`
        })
        .style('user-select', 'none')
        .on('click', function (d) {
          const el = d3.select(this)
          const text = el.select('text')
          const rect = el.select('rect')
          const column = d.column
          if (!filter.includes(column)) {
            text.attr('fill', '#000')
            rect.style('fill', d.color)
            filter.push(column)
          } else {
            text.attr('fill', '#efefef')
            rect.style('fill', '#efefef')
            const idx = filter.indexOf(column)
            filter.splice(idx, 1)
          }
          const newValue = list.map(d => {
            const obj = {
              CATEGORY: d['CATEGORY'],
              VALUES: d['VALUES'].filter(v => filter.includes(v['KEY']))
            }
            return obj
          })
          render(newValue)
        })

      legend.append('rect')
        .attr('x', 0)
        .attr('width', 10)
        .attr('height', d => d.shape === 'line' ? 2 : 6)
        .attr('y', d => d.shape === 'line' ? 4 : 4)
        .style('fill', d => d.color)

      legend.append('text')
        .attr('x', 14)
        .attr('y', 6)
        .attr('dy', '.35em')
        .attr('text-anchor', 'start')
        .attr('font-size', '8.5px')
        .attr('font-family', 'arial')
        .style('cursor', 'pointer')
        .text(d => d.text)

        render(list)
        // console.log(list)
    },
    render (dataset) {
      const { top, left, bottom, right } = this.margin
      const width = this.width - left - right
      const height = this.height - top - bottom

      const g = d3.select(this.$el).select('#chart_progress')

      const y0 = d3.scaleLinear().range([ height, 0 ])
      const y1 = d3.scaleLinear().range([ height, 0 ])

      const x0 = d3.scaleBand().range([ 0, width ])
      const x1 = d3.scaleBand().range([ 0, width ])
      //left Axis
      const leftColumns = this.legend.filter(d => d.axis === 'left').map(d => d['column'])
      const leftArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALUE'] && leftColumns.includes(v['KEY'])).map(v => v['VALUE'])
      const leftValues = Math.max.apply(null, leftArr)
      // console.log( leftArr )
      // console.log( leftValues )
      //right Axis
      const rightColumns = this.legend.filter(d => d.axis === 'right').map(d => d['column'])
      const rightArr = dataset.map(d => d['VALUES']).flat().filter(v => v['VALUE'] && rightColumns.includes(v['KEY'])).map(v => v['VALUE'])
      const rightValues = Math.max.apply(null, rightArr)

      const max = {
        left: leftArr.length === 0 ? 0 : leftValues,
        right: rightArr.length === 0 ? 0 : rightValues
      }
      max.left = max.left === 0 ? 100 : max.left * 1.5
      max.right = max.right === 0 ? 100 : max.right

      const axisLeftRange = d3.range(6).map(d => { const v = max.left / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })
      const axisRightRange = d3.range(6).map(d => { const v = max.right / 5 * d; return String(v).indexOf('.') !== -1 ? v : Math.ceil(v) })

      const categoryNames = dataset.map(d => d.CATEGORY)

      // const duration = 0
      const bars = this.legend.filter(d => d.shape === 'rect').map(d => d['column'])

      y0.domain([ 0, max.left ])
      y1.domain([ 0, max.right ])

      x0.domain(categoryNames)
      x1.domain(dataset[0].VALUES.filter(d => bars.includes(d.KEY)).map(d => d.KEY))
        .rangeRound([ 0, x0.bandwidth() ]).padding(0.15)

      const xAxis = d3.axisBottom(x0) .tickFormat(d => d).tickValues(x0.domain().filter((d,i) => { return !(i%1)}))
      //xAxis의 보이는 값을 다보여줄것인지 작게 보여줄것인지

      const yAxis = {
        left: d3.axisLeft(y0).tickValues(axisLeftRange),
        right: d3.axisRight(y1).tickValues(axisRightRange)
      }
      g.selectAll('g').remove()

      g.append('g')
        .attr('class', 'grid')
        .call(d3.axisLeft(y0).tickValues(axisLeftRange).tickSize(-width).tickFormat(''))
        .selectAll('.tick line')
        .attr('stroke', (d, k) => k > 0 ? '#efefef' : '#ccc')
        .attr('stroke-width', 1)
        .attr('shape-rendering', 'crispEdges')

      g.append('g')
        .style('font-size', '10px')
        .attr('class', 'x axis')
        .attr('transform', `translate(0,${height + 1})`)
        .call(xAxis)
        .selectAll('text')
        .attr('y', 0)
        .attr('x', -55)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'start')
        .style('fill','#424242')
        
      g.append('text')
        .attr('class', 'yaxis_left_text')
        .style('font-size', '10px')
        .attr('transform', `translate(-46,${height/2}),rotate(-90)`)
        .style('text-anchor', 'middle')
        .style('fill','#9E9E9E')
        .text("Weekly Q'ty")

        g.append('text')
        .attr('class', 'yaxis_right_text')
        .style('font-size', '10px')
        .attr('transform', `translate(${width+38},${height/2}),rotate(-90)`)
        .style('text-anchor', 'middle')
        .style('fill','#9E9E9E')
        .text('Cumulative Progress')

      g.append('g')
        .attr('class', 'y axis axis-left')
        .style('font-size', '10px')
        .call(yAxis.left)

      g.append('g')
        .attr('class', 'y axis axis-right')
        .style('font-size', '10px')
        .attr('transform', `translate(${width},0)`)
        .call(yAxis.right)

      g.selectAll('.domain').remove()
      g.selectAll('.y .tick').attr('color', '#878787')
      g.selectAll('.y .tick line').remove()
      g.selectAll('.x .tick line').remove()

      const barGroups = g.selectAll('.bar')
        .data(dataset)
        .enter()
        .append('g')
        .attr('class', 'bar')
        .attr('transform', d => `translate(${x0(d.CATEGORY)},0)`)

      barGroups.selectAll('rect')
        .data(d => d.VALUES.filter(v => bars.includes(v.KEY)))
        .enter()
        .append('rect')
        .attr('width', x1.bandwidth() < 1 ? 1 : x1.bandwidth())
        .attr('x', d => x1(d.KEY))
        .attr('y', d => y0(d.VALUE))
        .attr('height', d => height - y0(d.VALUE) < 0 ? 0 : isNaN(height - y0(d.VALUE)) ? 0 : height - y0(d.VALUE))
        .attr('fill', d => {
          const color = this.legend.find(v => v['column'] === d['KEY']).color
          return color
        })
        .style('opacity', 0)

      barGroups.selectAll('text')
        .data(d => d.VALUES.filter(v => bars.includes(v.KEY)))
        .enter()
        .append('text')
        .attr('x', d => x1(d.KEY))
        .attr('dx', x1.bandwidth() / 2)
        .attr('y', d => y0(d.VALUE))
        .attr('dy', '-.5em')
        .attr('fill', d => d3.rgb(this.legend.find(v => v['column'] === d.KEY).color).darker(1))
        .attr('text-anchor', 'middle')
        .attr('font-size', '10px')
        .text(d => d.VALUE > 0 ? d.VALUE : null)
        .style('opacity', 0)

      barGroups.selectAll('rect')
        .transition()
        .style('opacity', 1)

      barGroups.selectAll('text')
        .transition()
        .style('opacity', 0)

      const lines = this.legend.filter(d => d.shape === 'line').map(d => d['column']).filter(d => this.filter.includes(d))
      const lineLength = d3.line()
        .x(d => x0(d.KEY))
        .y(d => y1(d.VALUE))
        .defined(d => { // return d.VALUE && d.VALUE >= 0 기존것 있는것만 나오기
          return d.VALUE >= 0 //0있는것은 0부터 시작하게
        })
      const linedata = []
      lines.forEach(c => {
        const obj = {}
        obj.KEY = c
        obj.COLOR = this.legend.find(v => v['column'] === c).color
        obj.VALUES = dataset
          .map(d => {
            d.VALUES.find(v => {
              return v.KEY === c
            })
            return {
              KEY: d.CATEGORY,
              VALUE: d.VALUES.find(v => v.KEY === c).VALUE
            }
          })
        obj.LINESHAPE = this.legend.find(v => v['column'] === c).lineShape
        linedata.push(obj)
      })

      const lineGroups = g
        .append('g')
        .attr('class', 'line')
        .attr('transform', `translate(${x0.bandwidth() / 2},0)`)

      linedata.forEach(v => {
        const line = lineGroups
        .append('path')
        .datum(v)
        .attr('class', d => `line-${d.KEY}`)
        .attr('fill', 'none')
        .attr('stroke', d => d.COLOR)
        .attr('stroke-width', 1)
        .attr('shape-rendering', 'geometricPrecision')
        .attr('d', d => lineLength(d.VALUES))
        v.LENGTH = line.node().getTotalLength()
      })

      g.selectAll('.line path')
        .attr('stroke-dasharray', d => '')
        .attr('stroke-dashoffset', d => d.LENGTH)
        .transition()
        .attr('stroke-dashoffset', 0)
        .attr('stroke-dasharray', d => {
          const legend = this.legend.find(v => v['column'] === d['KEY'])
          if (legend) return legend.lineShape && legend.lineShape === 'dash' ? '4,3' : d.LENGTH + ' ' + d.LENGTH
          else return ''
        })
        .attr('d', d => lineLength(d.VALUES))

      g.selectAll('.line circle')
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
      g.selectAll('.line text')
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 0)
    },
  }
}