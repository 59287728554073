import * as d3 from 'd3'

export default {
  data: () => ({

  }),

  methods: {

    draw_Legends() {

      let self = this
      let Legends = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.skyline.legend.x},${this.skyline.legend.y})`)

     
      this.style.forEach((style,i) => {

        let itemX = 0,
            itemY = 0

        Legends
        .append('text')
        .attr('x', 0)
        .attr('y', itemY)
        .style('font-family', this.skyline.legend.font)
        .style('font-size', this.skyline.legend.group.tSize)
        .style('fill', this.skyline.legend.group.tColot)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(style.group)

        itemY += this.skyline.legend.group.lineSpace

        style.item.forEach((item,j) => {
          let legendItem = Legends
          .append('g')
          .attr('id', `item_${i}_${j}`)
          .attr('transform', `translate(0,0)`)
          
          legendItem
          .append('rect')
          .attr('x', 10)
          .attr('y', itemY)
          .attr('width', 10)
          .attr('height', 10)
          .attr('fill', item.bColor)
          .attr('stroke', item.sColor)
          .attr('stroke-width', item.sWidth)

          legendItem
          .append('text')
          .attr('x', 23)
          .attr('y', itemY + 6)
          .style('font-family', this.skyline.legend.font)
          .style('font-size', this.skyline.legend.item.tSize)
          .style('fill', this.skyline.legend.item.tColor)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text(`${item.name} (${this.dataSet.filter(f => f[item.refColumn] == item.codeValue).length})`)

          if (j > 0) {
            itemX += this.getNodeElValue(`#item_${i}_${j-1}`, 'width') + this.skyline.legend.item.space
            d3.select(`#item_${i}_${j}`).attr('transform', `translate(${itemX},0)`)
          }
        })

        let legendNotes = Legends
        .append('g')
        .attr('transform', `translate(10,50)`)

        legendNotes
        .append('text')
        .attr('x', 0).attr('y', 0)
        .style('font-family', this.skyline.legend.font).style('font-size', this.skyline.legend.notes.tSize).style('fill', this.skyline.legend.notes.tColor)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`Schedule based on start date of erection`)
      })



    }, 

  }
}
