import * as d3 from 'd3'

export default {
  data: () => ({

  }),

  methods: {
    /*
      draw_gmx_TextBox_Wrap_v1(selection, d, func)                       Updated by Jinkee - 2023.04.07


    */
    
    draw_gmx_TextBox_v1(s, d) {
    
      let _x = s.node().getBBox().x
      let _y = s.node().getBBox().y
      let _width = s.node().getBBox().width
      let _height = s.node().getBBox().height

      d3.select(d.id)
      .append('rect')
      .attr('id', ()=> {
        if(d.boxID) return d.boxID
      })
      .attr('x', _x - d.padding.left + d.x)
      .attr('y', _y - d.padding.top + d.y)
      .attr('width', _width + (d.padding.left + d.padding.right))
      .attr('height', _height + (d.padding.top + d.padding.bottom) )
      .attr('fill', d.color)
      .attr('stroke', d.border.color)
      .attr('stroke-width',d.border.size)
      .attr('rx', d.border.rx)
      .attr('ry', d.border.ry)
      .attr('opacity', d.opacity)
      s.moveToFront()
    },


    draw_gmx_TextBox_Wrap_v1(selection, d, call_Func) {
      
      d3.select(d.id.parents)
      .append("g")
      .attr('id', `${d.id.group}`)
      .attr('transform', `translate(${d.x + d.padding.left}, ${d.y + d.padding.top + 1})`)
      if(call_Func) d3.select(d.id.parents).call(call_Func)

      if (d.box.visible == 'Y') {
        selection.select(`#${d.id.group}`)
        .append("rect")
        .attr('id', `${d.id.rect}`)
        .attr("fill", d.box.color)
        .attr("stroke", d.border.color)
        .attr('stroke-width', d.border.size)
        .attr('opacity', d.box.opacity)
        .attr("rx", d.border.rx)
        .attr("ry", d.border.ry)
      }
      selection.select(`#${d.id.group}`)
      .append("text")
      .attr("dy", ".8em")
      .attr('font-size', d.font.size)
      .attr('fill', d.font.color)
      .text(d.text)
      .call(wrapText, d.width)
    
      if (d.box.visible == 'Y') {
        let bbox = d3.select(`#${d.id.group}`).node().getBBox()
    
        selection.select(`#${d.id.rect}`)
        .attr('x', -d.padding.left)
        .attr('y', -d.padding.top-1)
        .attr("width", bbox.width + (d.padding.left + d.padding.right))
        .attr("height", bbox.height + (d.padding.top + d.padding.bottom)-1)
      }

      function wrapText(text, width) {
        text.each(function() {
          var text = d3.select(this),
            words = text.text().split(/\s+/).reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            y = text.attr("y"),
            dy = parseFloat(text.attr("dy")),
            tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em")
          
          word = words.pop()
          while (word) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", lineHeight + "em").text(word);
            }
            word = words.pop()
          }
        })
      }
    },





  }
}
