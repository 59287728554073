import * as d3 from 'd3'
import __C from '../../../../includes/primitives/_constant_'

export default {
  data: () => ({
    axisXName: { base: '', month: 'month', year: 'year' },
    axisYName: { primary: 'LINE', secondary: 'BAR' },
    dataPurposeNames: ['primary', 'secondary'],
    items_: [],
    itemsPurposed: {
      primary: [],
      secondary: []
    },
    scaleYInfo: {
      primary: {
        valid: false,
        prefix: 'LINE',
        minValue: 0,
        minBoundValue: 0,
        maxValue: 100,
        maxBoundValue: 100,
        boundedValue: null,
        tickValues: [],
        colNames: [],
        domain: [],
        data: [],
        scale: null
      },
      secondary: {
        valid: false,
        prefix: 'BAR',
        minValue: 0,
        minBoundValue: 0,
        maxValue: 0,
        maxBoundValue: 0,
        boundedValue: null,
        tickValues: [],
        colNames: [],
        domain: [],
        data: [],
        scale: null
      },
      forecasted: false,
    },
    scaleColorInfo: {
      line: { colors: [], scale: null },
      bar: { colors: [], scale: null, },
    },
    scaleBarGroup: {
      scaleband: {
        single: null,
        group: null
      },
    },
    tickValues: [],

    table: {
      display: 'Y',
      bottom: 500,
      dwarY:45,
      gapFromChart: 0,
      cellWidth: 0,
      labelX: -55,
      labelWidth: 70,

      tColor: '#757575',
      tRMargin: 1,
      fontSize: 9,

      width:290, 
      hHeight: 22,
      lineHeight: 18,

      sColor:'#757575', 
      sWidth: 0.5, 
  
      headers: [
        {name: 'Total ITRs'   , colName: 'A_T'     , x: 15,  y: 20,  col: 'Y', link: 'Y', align: 'end',     tSize: 10, tColor: '#000', dColor: '#757575', dSize: 9, markupFill: '#c5e1a5'}, 
        {name: 'Completed'    , colName: 'A_A'     , x: 55,  y: 20,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 9, markupFill: '#80deea'}, 
        {name: 'Remained'     , colName: 'A_O'     , x: 110, y: 20,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#1976d2', dSize: 9, markupFill: '#1e88e5'}, 
        {name: 'Progress(%)'  , colName: 'A_PG'    , x: 110, y: 20,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 9, markupFill: '#9c27b0'}, 
        {name: 'Cumulated(%)' , colName: 'A_CUM_PG', x: 110, y: 20,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#e53935', dSize: 9, markupFill: '#e53935'}, 
        {name: 'Punch "A"'    , colName: 'PA_O'    , x: 175, y: 13,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 9, markupFill: '#9e9d24'}, 
        {name: 'Punch "B"'    , colName: 'PB_O'    , x: 250, y: 13,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 9, markupFill: '#e0e0e0'},
      ],
    }  
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.ChartType', this.ChartType)
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      // console.log('Object.keys(this.Timeline).length', Object.keys(this.Timeline).length)
      // console.log('Object.keys(this.Axis).length', Object.keys(this.Axis).length)
      // console.log('Object.keys(this.Bar).length', Object.keys(this.Bar).length)
      // console.log('Object.keys(this.Line).length', Object.keys(this.Line).length)
      // console.log('Object.keys(this.Milestone).length', Object.keys(this.Milestone).length)
      // console.log('Object.keys(this.Legends).length', Object.keys(this.Legends).length)

      return this.ChartType &&
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0 &&
        Object.keys(this.Timeline).length > 0 &&
        Object.keys(this.Axis).length > 0 &&
        Object.keys(this.Bar).length > 0 &&
        Object.keys(this.Line).length > 0 &&
        Object.keys(this.Milestone).length > 0 &&
        Object.keys(this.Legends).length > 0
    },
  },
  methods: {
    initData() {
      this.forecasted = true
      this.cutoff = this.Cutoff || this.cutoff

      // Check the data forecasted ---------------------------------------
      // The data of the Line & Bar should be available for the forecasted chart.
      // In this process, check the data forecasted using Bar Data. 
      let keys = Object.keys(this.DataItems[0]).filter(k => k.toUpperCase().includes(this.scaleYInfo.primary.prefix))
      if(keys.length === 0) keys = Object.keys(this.DataItems[0]).filter(k => k.toUpperCase().includes(this.scaleYInfo.secondary.prefix))
      // The Structure of the Key. ('//' Separator)
      // <Purpose Name>_<Class Code of the Data, 2 length characters>_<Column Name>
      // - Class Code
      //   PL: Plan
      //   AC: Acture
      //   FC: Forecast
      // check the data for the bar available.
      keys.forEach(k_ => {
        let splitted_ = k_.toUpperCase().split('//')
        if (splitted_.length != 3) this.forecasted = false
        if (!['PL', 'AC', 'FC'].includes(splitted_[1])) this.forecasted = false
      })
      // -----------------------------------------------------------------

      // Set the name of the primary axis with the very first column name.
      // It would generally be 'cDate'. This process must be run from the 
      // item change event, because of the object reordering. Running from
      // the others will cause that the order of elements will be changed,
      // then the originally first element cannot be found. 
      this.axisXName.base = Object.keys(this.DataItems[0])[0]
      // Set global timeline-base name
      this.timelineKeyName = this.axisXName.base
      // Distribute the data by its purpose.
      // This process must also be run from the item change event, because
      // of the same reason above.
      this.dataPurposeNames.forEach((p) => { this.setData(p) })
    },
    setData(name) {
      // Truncate the data by the Timeline_Declares.getDataRequested()
      // If the request is not exist, it will return full data-items,
      // not truncated.
      let items_ = this.getDataRequested()

      if (!items_ || items_.length === 0) return []

      this.tickValues = items_.map(d => this.parseDate(d[this.axisXName.base]))

      // CAUTION:
      // The data for the chart cannot accept null or undefined.
      // null or undefined value must be replaced with min value,
      // or the user defined default value. 
      // This component assumes that the null or undefined values
      // are not exist.

      // Extract the keys for the LINE or BAR.
      // prefix : LINE | BAR
      let keys = Object.keys(items_[0]).filter(k => k.toUpperCase().includes(this.scaleYInfo[name].prefix))

      this.scaleYInfo[name].valid = keys.length > 0
      this.scaleYInfo[name].colNames = this.scaleYInfo[name].valid ? keys.map(k_ => k_.split('//')[k_.split('//').length - 1]) : []

      if(!this.scaleYInfo[name].valid) return

      // Minus values
      this.setMinusValues(name, items_, keys)

      if(this.forecasted) {
        // primary : Set the data for the LINE.
        if (name == 'primary') {
          let plans_ = []
          let actuals_ = []
          let forecasts_ = []

          items_.forEach(item__ => {
            let itemDate_ = this.parseDate(item__[this.axisXName.base])

            if(typeof item__[keys[0]] == 'number') plans_.push({ [this.axisXName.base]: itemDate_, value: item__[keys[0]] })
            if (typeof item__[keys[1]] == 'number' && this.cutoffDate >= itemDate_) actuals_.push({ [this.axisXName.base]: itemDate_, value: item__[keys[1]] })
            if (typeof item__[keys[2]] == 'number' && this.cutoffDate <= itemDate_) forecasts_.push({ [this.axisXName.base]: itemDate_, value: item__[keys[2]] })
          })

          this.scaleYInfo.primary.data = [plans_, actuals_, forecasts_]

        // Set the data for the BAR.
        } else this.scaleYInfo.secondary.data = items_.map(item__ => ({
          plan: item__[keys[0]],
          acfc: item__[keys[this.cutoffDate > this.parseDate(item__[this.axisXName.base]) ? 1 : 2]],
        }))

      } else {
        if (name == 'primary') {
          let data_ = []

          items_.forEach((item__, i) => {
            let itemDate_ = this.parseDate(item__[this.axisXName.base])

            this.scaleYInfo[name].colNames.forEach((colName_, j) => {
              if(i === 0) data_.push([])
              if(typeof item__[keys[j]] == 'number') data_[j].push({ [this.axisXName.base]: itemDate_, value: item__[keys[j]] })
            })
          })

          this.scaleYInfo.primary.data = data_

        } else {
          this.scaleYInfo.secondary.data = items_.map(item__ => {
            let data = {}
            keys.forEach((k_, i) => { data[this.scaleYInfo[name].colNames[i]] = item__[k_] })
            return data
          })
        }
      }

      this.items_ = items_
    },
    setMinusValues(name, items, colNames=[]) {
      if(
        (name == 'primary' && (this.Axis.AxisPrBoundsCenter == 'Y' || this.Axis.AxisPrAllowMinusValue == 'Y')) ||
        (name == 'secondary' && (this.Axis.AxisSeBoundsCenter == 'Y' || this.Axis.AxisSeAllowMinusValue == 'Y'))
      ) return

      items.forEach(t_ => {
        colNames.forEach(c_ => { if(t_[c_] < 0) t_[c_] = null })
      })
    },
    extractKeyNames(prefix) {
      if (this.items_.length === 0) return []
      return Object.keys(this.items_[0]).filter(v => v.toUpperCase().includes(prefix))
    },
    setScaleYInfo() {
      Object.keys(this.scaleYInfo).forEach(k => {
        if (!this.scaleYInfo[k].valid) return

        let targetData_ = []
        // prefix : LINE | BAR
        let colNames_ = this.extractKeyNames(this.scaleYInfo[k].prefix)
        let dataSource_ = JSON.parse(JSON.stringify(this.DataItems))

        this.setMinusValues(k, dataSource_, colNames_)

        dataSource_.forEach(item_ => {
          let values = {}

          colNames_.forEach(colName_ => {
            values[colName_] = typeof item_[colName_] == 'undefined' ? null : item_[colName_]
          })

          targetData_.push(values)
        })

        // set the value ranges of the max
        this.setScaleYInfoValueRanges(k, targetData_)

        // Set the grid-lines with fixed number.
        // 5 for the general purpose
        // 6 for the centeralizing the Bound
        let centerBound = (k == 'primary' ? this.Axis.AxisPrBoundsCenter : this.Axis.AxisSeBoundsCenter) == 'Y'
        let gridLines___ = (this.Axis.AxisGridNum ? this.Axis.AxisGridNum : 1) * (centerBound ? 2 : 1)
        let boundLength = this.scaleYInfo[k].maxBoundValue - this.scaleYInfo[k].minBoundValue
        let portion_ = boundLength / gridLines___
        this.scaleYInfo[k].tickValues = Array.from(
          { length: gridLines___ + 1 },
          (_, i) => this.scaleYInfo[k].minBoundValue + (i * portion_)
        )
        this.scaleYInfo[k].scale = d3
        .scaleLinear()
        .domain([this.scaleYInfo[k].minBoundValue, this.scaleYInfo[k].maxBoundValue])
        .rangeRound([this.Canvas.CanvasChartHeight, 0])
      })
    },
    setScaleYInfoValueRanges(key, data) {
      let centerBound = key == 'primary' ? this.Axis.AxisPrBoundsCenter : this.Axis.AxisSeBoundsCenter
      let minBoundAuto = key == 'primary' ? this.Axis.AxisPrBoundsMinAuto : this.Axis.AxisSeBoundsMinAuto
      let minBoundInput = key == 'primary' ? this.Axis.AxisPrBoundsMin : this.Axis.AxisSeBoundsMin
      let maxBoundAuto = key == 'primary' ? this.Axis.AxisPrBoundsMaxAuto : this.Axis.AxisSeBoundsMaxAuto
      let maxBoundInput = key == 'primary' ? this.Axis.AxisPrBoundsMax : this.Axis.AxisSeBoundsMax

      if (this.ChartType == __C.CHART.TYPE_CODE_STACK) {
        var sums = data.map(item_ => Object.values(item_).reduce((a, b) => a + b, 0))
        this.scaleYInfo[key].minValue = Math.min(...sums)
        this.scaleYInfo[key].maxValue = Math.max(...sums)
      } else {
        this.scaleYInfo[key].minValue = Math.min(...data.map(item_ => Math.min(...Object.values(item_))))
        this.scaleYInfo[key].maxValue = Math.max(...data.map(item_ => Math.max(...Object.values(item_))))
      }

      // Get Bound Values
      this.scaleYInfo[key].minBoundValue = (
        minBoundAuto == 'Y' ? (
          this.scaleYInfo[key].minValue < 0 ?
          this.getMaxBound(Math.abs(this.scaleYInfo[key].minValue)) * (-1) :
          0
        ) :
        minBoundInput
      )
      this.scaleYInfo[key].maxBoundValue = (
        maxBoundAuto == 'Y' ? (
          this.scaleYInfo[key].maxValue < 0 ?
          this.getMaxBound(Math.abs(this.scaleYInfo[key].maxValue)) * (-1) :
          this.getMaxBound(this.scaleYInfo[key].maxValue)
        ) :
        maxBoundInput
      )

      // Centralizing
      if(centerBound == 'Y') {
        let values_ = [
          Math.abs(this.scaleYInfo[key].minBoundValue), 
          this.scaleYInfo[key].maxBoundValue
        ]
        this.scaleYInfo[key].minBoundValue = Math.max(...values_) * -1
        this.scaleYInfo[key].maxBoundValue = Math.max(...values_)
      }
    },
    setScaleColor() {
      // Set Colors
      this.scaleColorInfo.line.colors = this.getColors(
        this.scaleYInfo.primary.colNames,
        this.Line.LineColorSet
      )
      this.scaleColorInfo.bar.colors = this.getColors(
        this.scaleYInfo.secondary.colNames,
        this.Bar.BarColorSet
      )
    },
    getColors(source, colorset) {
      let colors_ = []
      let colorSetLen_ = colorset.length

      if (colorSetLen_ < source.length) {
        source.forEach((_, i) => {
          colors_.push(colorset[i % colorSetLen_])
        })
      } else source.forEach((_, i) => {
        colors_.push(colorset[i])
      })

      return colors_
    },
    getColorForBar(i, tick) {
      if (i === 0) return this.scaleColorInfo.bar.colors[i]
      else {
        if (this.forecasted) {
          if (this.cutoffDate > tick) return this.scaleColorInfo.bar.colors[1]
          else return this.scaleColorInfo.bar.colors[2]

        } else return this.scaleColorInfo.bar.colors[i]
      }
    },
    setScaleBarGroup() {
      if (!this.scaleYInfo.secondary.valid) return

      let bandInfo_ = this.calBandInfo()
      this.scaleTimelineInfo.base.bandwidth = bandInfo_.bandwidth

      // Set the scale for the bars ----------------------------------
      // let barGap_ = !this.bar.barGapSize ? 0 : this.bar.barGapSize / 10
      // Set scaleband for positioning of the bars.
      // 1. Set bands as much as the length of the single data.
      //    - If the 'barType' is 'group-normal', it is used in grouped-scale wrapper.
      //    - If not, it is used for each of the single bars.
      // this.scaleBarGroup.scaleband.single = d3
      // .scaleBand()
      // .domain(this.scaleTimelineInfo.base.tickValues)
      // .rangeRound([0, this.Canvas.CanvasChartWidth])
      // .paddingOuter(barGap_)
      // .paddingInner(barGap_)

      // 2. Set bands as much as the length of the grouped data
      if (this.ChartType != __C.CHART.TYPE_CODE_S_CURVE) return

      this.scaleBarGroup.scaleband.group = d3.scaleBand()
      .domain(Object.keys(this.scaleYInfo.secondary.data[0]))
      .rangeRound([0, this.scaleTimelineInfo.base.bandwidth])
      .padding(0.2)
    },
    calBandInfo() {
      // let itemNum = this.items_.length
      let itemNum = this.DataItems.length
      let paddingInner = !this.Bar.BarDistance ? 0.5 : this.Bar.BarDistance
      // (- paddingInner): remove a last paddingInner not padded
      let paddingInnerTotal = paddingInner * itemNum - paddingInner
      let areaWidth = this.Canvas.CanvasChartWidth - (this.Canvas.CanvasChartSpace * 2)
      areaWidth = areaWidth < 0 ? 0 : areaWidth

      let bandwidth = 0
      if (this.ChartType == __C.CHART.TYPE_CODE_S_CURVE) {
        // let elNum = Object.keys(this.items_[0]).length
        let elNum = Object.keys(this.DataItems[0]).length
        let paddingInnerInGroup = 0.5
        let paddingInnerInGroupTotal = paddingInnerInGroup * itemNum - paddingInnerInGroup

        bandwidth = Math.round((areaWidth - paddingInnerTotal - paddingInnerInGroupTotal) / itemNum)
        // itemNum * 2 - 1 => itemNum(1px of each) + paddingInner(1px of itemNum each) - 1px, 
        bandwidth = bandwidth < elNum * 2 - 1 ? elNum * 2 - 1 : bandwidth

      } else {
        bandwidth = Math.round((areaWidth - paddingInnerTotal) / itemNum)
        // 1.5 => a bar width and its padding
        bandwidth = bandwidth < 1.5 ? 1.5 : bandwidth
      }

      return {
        areaWidth: areaWidth,
        bandwidth: bandwidth,
        paddingInner: paddingInner,
      }
    },
    getBarValueYPos(v, vPrev=0) {
      if(!v) return this.scaleYInfo.secondary.scale(0)

      if(this.ChartType == __C.CHART.TYPE_CODE_S_CURVE) {
        if (this.Bar.BarValuePosition == 'top') var yPos_ = this.scaleYInfo.secondary.scale(v)
        else if (this.Bar.BarValuePosition == 'middle') yPos_ = this.scaleYInfo.secondary.scale(v / 2)
        else yPos_ = this.scaleYInfo.secondary.scale(0)

      } else if(this.ChartType == __C.CHART.TYPE_CODE_STACK) {
        if (this.Bar.BarValuePosition == 'top') yPos_ = this.scaleYInfo.secondary.scale(v)
        else if (this.Bar.BarValuePosition == 'middle') yPos_ = this.scaleYInfo.secondary.scale(vPrev + (v - vPrev) / 2)
        else yPos_ = this.scaleYInfo.secondary.scale(vPrev)
        
      } else if(this.ChartType == __C.CHART.TYPE_CODE_COMPARE) {
        if (this.Bar.BarValuePosition == 'top') yPos_ = this.scaleYInfo.secondary.scale(v)
        else if (this.Bar.BarValuePosition == 'middle') yPos_ = this.scaleYInfo.secondary.scale(vPrev - v / 2)
        else yPos_ = this.scaleYInfo.secondary.scale(vPrev)
      }

      return yPos_
    },
    setTableEnvValues() {
      this.table.width = this.Canvas.CanvasChartWidth
      this.table.cellWidth = Math.round(this.Canvas.CanvasChartWidth / this.tickValues.length)
    }
  },
}
