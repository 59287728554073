import Vue from 'vue'
import __C from '@/primitives/_constant_'
import { PageComponentService } from "@/services"

const _pageComponentService = new PageComponentService()

export default {
  namespaced: true,
  state: {
    chartData: [],
    dataItems: [],
    sqlValues: {
      milestone: [],
      note: '',
    },
    svgProps: {},
  },
  mutations: {
    setSvgProps(state, props) {
      if(Object.keys(props).length > 0) {
        Object.keys(props).forEach(k => {
          if(Object.keys(state.svgProps).includes(k)) state.svgProps[k] = props[k]
          else Vue.set(state.svgProps, k, props[k])
        })
      } else state.svgProps = {}
    },
    setChartData(state, data) {
      state.chartData = data
    },
    setDataItems(state, items) {
      state.dataItems = items
    },
    setEmpty(state) { 
      state.chartData = [],
      state.dataItem = [],
      state.sqlValues = {
        milestone: [],
        note: '',
      },
      state.svgProps = {}
    },
  },
  getters: {
    general: (state) => ({
      name: state.svgProps.name || '',
      phaseCode: state.svgProps.phaseCode || '',
      groupCode: state.svgProps.groupCode || '',
      itemCode: state.svgProps.itemCode || '',
      subItemCode: state.svgProps.subItemCode || '',
      desc: state.svgProps.desc || '',
    }),
    database: (state) => ({
      dbName: state.svgProps.dbName || '',
      schemaName: state.svgProps.schemaName || '',
      tableName: state.svgProps.tableName || '',
      directAccess: state.svgProps.directAccess || '',
      jsonString: state.svgProps.jsonProps || '',
      qApplied: state.svgProps.qApplied || '',
      qDataset: state.svgProps.qDataset || '',
      qSp: state.svgProps.qSp || '',
      qSummary: state.svgProps.qSummary || '',
    }),

    canvas: (state) => (state.svgProps.canvas ? state.svgProps.canvas : {}),
    note: (state) => (state.svgProps.note ? state.svgProps.note : {}),
    style: (state) => (state.svgProps.style ? state.svgProps.style : []),
    border: (state) => (state.svgProps.border ? state.svgProps.border : []),
    forms: (state) => (state.svgProps.forms ? state.svgProps.forms : {}),
  },
  actions: {
    getSvgProps({ commit }, idx) {
      return new Promise((resolve) => {
        _pageComponentService.getSvgProps(idx, (item) => {
          commit('setsvgProps', item)
          resolve(true)
        })
      })
    },
    updSvgProps({ state }) {
      return new Promise((resolve) => {
        _pageComponentService.updSvgProps(state.svgProps, () => {
            resolve(true)
          }
        )
      })
    },
    delSvgProps({ state, commit }) {
      return new Promise((resolve) => {
        console.log(state.svgProps)
        _pageComponentService.delSvgProps(state.svgProps.idx, () => {
            commit('setEmpty')
            commit('setSvgProps', {})
            resolve(true)
          }
        )
      })
    },
  }
}