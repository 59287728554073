import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    draw_Certificates() {
      this.dataSet.forEach((data,i) => {
        // Draw Background Donut Chart
        this.draw_DonutChart('FDMCWD', 'WD', i, 20, data.x, data.y, '#FFDA00')
        this.draw_DonutChart('FM-DAC1', 'DAC', i, 80, data.x, data.y, '#83D2F5')
        this.draw_DonutChart('FMC1', 'FMC', i, 140, data.x, data.y, '#CDDC37')
      })
    },

    draw_DonutChart(certName, certCode, i, _dx,_x, _y, _Color) {
      let x = _dx
      let y = 50
      let total = this.dataSet[i][`${certCode}_T`]
      let completed = this.dataSet[i][`${certCode}_A`]
      let Prog = (completed / total) * 100

      let bgGroup = this.chartGroup
      .append('g')
      .attr('transform', `translate(${_x + x},${_y + y})`)
      .on('mouseover', () => {
        bgGroup.style('cursor', 'pointer')
        d3.select(`#${certCode}_${i}`)
        .transition().duration(300).attr('fill', '#E0E0DF')
        .transition().duration(300).attr('fill', '#333')
        
        d3.select(`#donut${certCode}_${i}`)
        .transition().duration(300)
        .attrTween('d', () => {
          let interpolate = d3.interpolate(( ((360 * (Prog/100)) + 0) * Math.PI / 180), 0)
          let d = {}
          return function(t) {
            d.endAngle = interpolate(t)
            return arc(d)
          }
        })
        .transition().duration(300)
        .attrTween('d', () => {
          let interpolate = d3.interpolate(0 * Math.PI / 180, ( ((360 * (Prog/100)) + 0) * Math.PI / 180))
          let d = {}
          return function(t) {
            d.endAngle = interpolate(t)
            return arc(d)
          }
        })
      })
      .on('mouseout', () => { 
        d3.select(`#${certCode}_${i}`)
        .style('cursor', 'default')
      })
      .on('click', () => { 
        console.log(this.dataSet[i].MOD, certName)
      })
      
      bgGroup
      .append('circle')
      .attr('cx',0).attr('cy',0).attr('r',30).attr('fill','#fff')

      bgGroup
      .append('text')
      .attr('id', `${certCode}_${i}`)
      .attr('transform', `translate(${0}, ${38})`)
      .style('font-family', 'roboto').attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'middle').text(certName)
      

      bgGroup
      .append('line')
      .attr('x1',-13).attr('x2',13).attr('y1',1).attr('y2',1).attr('stroke','#bcbcbc').attr('stroke-width',0.5)
      bgGroup
      .append('text')
      .attr('transform', `translate(${0}, ${-3})`)
      .style('font-family', 'roboto').style('font-size', 11).attr('fill', _Color).attr('text-anchor', 'middle').text(total)
      bgGroup
      .append('text')
      .attr('transform', `translate(${0}, ${12})`)
      .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#333').attr('text-anchor', 'middle').text(completed)

      var arc1 = d3.arc()
      .outerRadius(26).innerRadius(20)
      .startAngle(0 * Math.PI / 180).endAngle(360 * Math.PI / 180)

      bgGroup
      .append('path')
      .attr('d', arc1)
      .attr('fill', '#F4F5F5')

      // Draw Front Donut Chart
      let frontGroup = this.chartGroup
      .append('g')
      .attr('transform', `translate(${_x + x},${_y + y})`)

      let arc = d3.arc()
      .outerRadius(26).innerRadius(20)
      .startAngle(0 * Math.PI / 180)
      .endAngle(d => typeof d == 'undefined' ? 0 : (d.endAngle || 0 * Math.PI / 180))

      frontGroup
      .append('path')
      .attr('id', `donut${certCode}_${i}`)
      .attr('d', arc)
      .attr('fill', _Color)
      .transition()
      .duration(750)
      .attrTween('d', () => {
        let endAngle_ = ( ((360 * (Prog/100)) + 0) * Math.PI / 180)
        let interpolate = d3.interpolate(0 * Math.PI / 180, endAngle_)
        let d = {}
        return function(t) {
          d.endAngle = interpolate(t)
          return arc(d)
        }
      })

      bgGroup
      .append('circle')
      .attr('cx',0).attr('cy',0).attr('r',30).attr('fill','transparent')
    },

  }
}