/* 
  ### Chart Controllers ### 
  Predefined component's behaviors for the Filter & Action
*/
import System_ChartOptions from '../../09_Projects/TCO/00_System/ChartOptions'
import System_Dropdown from     '../../09_Projects/TCO/00_System/Dropdown'
import System_SubMenu_01 from   '../../09_Projects/TCO/00_System/SubMenu_01'
  
/* 
  ### Chart Customized ### 
  Unregulated chart components, not on the role of the controller.
*/
// Common
import CMN_HistoryTable from     '../../01_Charts/99_Common/HistoryTable'
import CMN_TBD from              '../../01_Charts/99_Common/TBD'
import CMN_Up2UText from         '../../01_Charts/99_Common/Up2UText'
import CMN_3DViewerEmbedded from '../../01_Charts/99_Common/3DViewerEmbedded'

// ### Home ###
// 01. Module based chart components
import Home_ModuleStatus from         '../../09_Projects/TCO/00_Homepage/ModuleStatus'
import Home_OncomingSailaway from     '../../09_Projects/TCO/00_Homepage/OncomingSailaway'
import Home_OverallProgress from      '../../09_Projects/TCO/00_Homepage/OverallProgress'
import Home_OverallSummary from       '../../09_Projects/TCO/00_Homepage/OverallSummary'
import Home_SailawayStatus from       '../../09_Projects/TCO/00_Homepage/SailawayStatus'
import Home_ConstructOutfitting from  '../../09_Projects/TCO/07_Construction/Outfitting'

// ### Construction ###
import Con_BlockSkyline from            '../../09_Projects/Tortue/01_Planning/ConstructionBlockSkyline'
import Con_BlockDivision from           '../../09_Projects/Tortue/04_Construction/BlockDivision'
import Con_BlockDivisionFGP from        '../../09_Projects/MD2/08_Construction/BlockDivision'
import Const_Skyline_Erec from          '../../09_Projects/TCO/07_Construction/Skyline_Erec'
import Const_BlockSummary from          '../../09_Projects/TCO/07_Construction/BlockSummary'
import Const_ActivityGanttChart from    '../../09_Projects/TCO/07_Construction/ActivityGanttChart'
import Const_MeasurementBlock from      '../../09_Projects/TCO/07_Construction/MeasurementBlock'
import Con_BlockDivisionFP from   '../../09_Projects/DEMO/07_Construction/BlockDivisionHebron'
import Const_MilestonesModule from      '../../09_Projects/TCO/03_Planning/MilestonesModule'
import Const_MeasurementDashboard from  '../../09_Projects/TCO/07_Construction/MeasurementDashboard'
import Const_Skyline_Module from        '../../09_Projects/TCO/03_Planning/Skyline_Module'
import Const_Outfitting from            '../../09_Projects/TCO/07_Construction/Outfitting'





// ### System Completion ###
import SC_MCSkyline from              '../../09_Projects/MD2/09_SystemCompletion/MC_Skyline'
import SC_RfsSkyline from             '../../09_Projects/TCO/08_SystemCompletion/RFS_Skyline'
import SC_CommissioningSkyline from   '../../09_Projects/MD2/09_SystemCompletion/CommissioningSkyline'
import SC_SubsystemList from          '../../09_Projects/TCO/08_SystemCompletion/SubsystemList'
import SC_SystemBoundary from         '../../09_Projects/TCO/08_SystemCompletion/SystemBoundary'
import SC_Skyline from                '../../09_Projects/TCO/08_SystemCompletion/Overall_Skyline'
import SC_ModuleSummary from          '../../09_Projects/TCO/08_SystemCompletion/ModuleSummary'
import SC_SheetSummary from           '../../09_Projects/TCO/08_SystemCompletion/SheetSummary'
import SC_WalkdownAvailable from      '../../09_Projects/TCO/08_SystemCompletion/WalkdownAvailable'
import SC_DiscTrendChart from         '../../09_Projects/TCO/08_SystemCompletion/DiscTrendChart'











// ### Belows will be removed or updated or the demo website. ###

//Homepage
import Home_DataSource from '../../09_Projects/Tortue/00_Homepage/DataSource'
import Home_ProjectProgress from '../../09_Projects/Tortue/00_Homepage/OverallProgress'
import Home_KeyMilestone from '../../09_Projects/Tortue/00_Homepage/KeyMilestone'
import Home_Observation from '../../09_Projects/Tortue/00_Homepage/Observation'
// import Home_OverallSummary from '../../09_Projects/Tortue/00_Homepage/OverallSummary'
// import Home_OncomingSailaway from '../../09_Projects/Tortue/00_Homepage/OncomingSailaway'
import Home_Yard             from '../../09_Projects/Tortue/00_Homepage/Yard'
import Home_Milestones  from '../../09_Projects/Tortue/00_Homepage/MilestoneImg'
import Home_Milestones_R  from '../../09_Projects/Tortue/00_Homepage/Milestone'

// HSSE
// import HSSE_SafetyPyramid from '../../09_Projects/MD2/03_HSSE/Pyramid'
import HSSE_SafetyPyramid from '../../09_Projects/Tortue/01_HSSE/Pyramid'
import HSSE_LSR from '../../09_Projects/Tortue/01_HSSE/LSR_IMG'
import HSSE_TSC from '../../09_Projects/Tortue/01_HSSE/tortueSafetyCharter'
import HSSE_Dashboard from '../../09_Projects/Tortue/01_HSSE/HSSEDashboard'

//Planning
import Plan_ProgressSummaryTable from '../../09_Projects/Tortue/01_Planning/ProgressSummaryTable'
import Plan_ModuleSummarySchedule from '../../09_Projects/Tortue/01_Planning/ModuleSummarySchedule'
import Plan_CommoditySummarySchedule from '../../09_Projects/Tortue/01_Planning/CommoditySummarySchedule'
import Plan_SummaryByArea from '../../09_Projects/Tortue/01_Planning/SummaryByArea'
import Plan_ModuleKeyMilestones from '../../09_Projects/Tortue/01_Planning/ModuleKeyMilestones'
import TopsidesGanttChart from '../../09_Projects/Tortue/01_Planning/TopsidesGanttChart'
import Plan_FilterPhase from '../../09_Projects/Tortue/01_Planning/FilterPhase'

//Engineering
import Eng_CMDRStatus from            '../../09_Projects/Tortue/02_Engineering/CMDRStatus'
import Eng_IFCDrawingList from            '../../09_Projects/Tortue/02_Engineering/IFCDrawingList'
import Eng_TotalPlanActualRemainL from    '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainL'
import Eng_TotalPlanActualRemainM from    '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainM'
import Eng_TotalPlanActualRemainS from    '../../09_Projects/Tortue/02_Engineering/TotalPlanActualRemainS'
import Eng_CDREHistoryChart from    '../../09_Projects/Tortue/02_Engineering/CDREHistoryChart'
import Eng_TQHistoryChart from    '../../09_Projects/Tortue/02_Engineering/TQHistoryChart'
import Eng_SDIDashboard from    '../../09_Projects/Tortue/02_Engineering/SDIDashboard'
import Eng_Interface from    '../../09_Projects/DEMO/02_Engineering/Interface'
import Eng_AreaInfo from    '../../09_Projects/DEMO/02_Engineering/AreaInfo'
import Eng_AreaTable from    '../../09_Projects/DEMO/02_Engineering/AreaTable'

// Procurement
import Proc_GanttChart from '../../09_Projects/DEMO/05_Procurement/GanttChart'
import Proc_EquipmentLayout from '../../09_Projects/DEMO/05_Procurement/Equipment_Layout'
import Proc_EquipmentLayout_V1 from '../../09_Projects/DEMO/05_Procurement/EquipmentLayout_V1'
import Proc_EquipmentTable from '../../09_Projects/Tortue/03_Procurement/EquipmentTable'
import Proc_Equipment3DLayout from '../../09_Projects/Tortue/03_Procurement/Equipment3DLayout'
import Proc_EquipmentRoom from '../../09_Projects/Tortue/03_Procurement/EquipmentRoom'
import Proc_ProgressBar from '../../09_Projects/Tortue/03_Procurement/progressBar'
import Proc_Lookahead from '../../09_Projects/Tortue/03_Procurement/lookahead'
import Proc_OWL from '../../09_Projects/Tortue/03_Procurement/owl'
import Proc_OverallScurve from '../../09_Projects/Tortue/03_Procurement/Overall_Scurve'
import Proc_FATScurve from '../../09_Projects/Tortue/03_Procurement/FAT_Scurve'
import Proc_IRNScurve from '../../09_Projects/Tortue/03_Procurement/IRN_Scurve'
import Proc_ETAScurve from '../../09_Projects/Tortue/03_Procurement/ETA_Scurve'
import Proc_IRNLookahead from '../../09_Projects/Tortue/03_Procurement/IRN_Lookahead'
import Proc_ETALookahead from '../../09_Projects/Tortue/03_Procurement/ETA_Lookahead'
import Proc_EquipmentHullCommon from '../../09_Projects/Tortue/03_Procurement/EquipmentHullCommon'


// Construction
import Con_BlockSummary from          '../../09_Projects/Tortue/04_Construction/BlockSummary'
import Con_ActivityGanttChart from    '../../09_Projects/Tortue/04_Construction/ActivityGanttChart'
import Con_ActivityGanttChart_R1 from    '../../09_Projects/Tortue/04_Construction/ActivityGanttChart_R1'
import Con_ActivityGanttChartLegend from    '../../09_Projects/Tortue/04_Construction/ActivityGanttChartLegend'
import Con_MeasurementBlock from      '../../09_Projects/Tortue/04_Construction/MeasurementBlock'
import Con_ShopDrawingActivities from '../../09_Projects/Tortue/04_Construction/ShopDrawingActivities'
import Con_ShopDrawingStatus from '../../09_Projects/Tortue/04_Construction/ShopDrawingStatus'
import Con_ShopDrawingStatusOther from '../../09_Projects/Tortue/04_Construction/ShopDrawingStatusOther'
import Con_OutfittingStatus from      '../../09_Projects/Tortue/04_Construction/OutfittingStatus'
import Con_OutfittingStatus_new from      '../../09_Projects/Tortue/04_Construction/OutfittingStatus_new'
import Con_Outfitting from            '../../09_Projects/Tortue/04_Construction/Outfitting'
import Con_ErectionTimeline from      '../../09_Projects/Tortue/04_Construction/ErectionTimeline'
import Con_OverallDashboard from      '../../09_Projects/Tortue/04_Construction/DashboardOverall'
import Con_OverallDashboardTopside from      '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside'
import Con_OverallDashboardTopside_L from      '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside_L'
import Con_OverallDashboardTopside_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallTopside_S'
import Con_OverallDashboardHull from      '../../09_Projects/Tortue/04_Construction/DashboardOverallHull'
import Con_OverallDashboardHull_L from      '../../09_Projects/Tortue/04_Construction/DashboardOverallHull_L'
import Con_OverallDashboardHull_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallHull_S'
import Con_OverallDashboardModules_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallModules_S'
import Con_OverallDashboardModule_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallModule_S'
import Con_OverallDashboardCommon_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallCommon_S'
import Con_OverallDashboardLQ_S from      '../../09_Projects/Tortue/04_Construction/DashboardOverallLQ_S'
import Con_OverallDashboardLQ from      '../../09_Projects/Tortue/04_Construction/DashboardOverallLQ'
import Con_ProgressSummaryOverall from      '../../09_Projects/Tortue/04_Construction/ProgressSummaryOverall'
import Con_ProgressSummaryTopside from      '../../09_Projects/Tortue/04_Construction/ProgressSummaryTopside'
import Con_ProgressSummaryHull from      '../../09_Projects/Tortue/04_Construction/ProgressSummaryHull'
import Con_OutfittingCommoditiesStatus from      '../../09_Projects/Tortue/04_Construction/OutfittingCommoditiesStatus'
import Con_OutfittingCommoditiesStatusL from      '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus_L'
import Con_OgmDashboardHull from      '../../09_Projects/Tortue/04_Construction/OgmDashboardHull'
import Con_OgmDashboardTopside from      '../../09_Projects/Tortue/04_Construction/OgmDashboardTopside'
import Con_SimpleText from      '../../09_Projects/Tortue/04_Construction/SimpleText'
import Con_OgmDisc from      '../../09_Projects/Tortue/04_Construction/OgmDisc'
import Con_OutfittingCommStatus from      '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus'
import Con_OutfittingCommStatus_S from      '../../09_Projects/Tortue/04_Construction/OutfittingCommStatus_S'
import Con_SpoolSummarySize from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize'
import Con_SpoolSummarySize_S from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize_S'
import Con_SpoolSummarySize_L from      '../../09_Projects/Tortue/04_Construction/SpoolSummarySize_L'
import Con_SpoolSummaryArea from      '../../09_Projects/Tortue/04_Construction/SpoolSummaryArea'
import Con_AreaDashboard_Hull_AFT from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Hull'
import Con_AreaDashboard_Hull_FOR from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_HullFOR'
import Con_AreaDashboard_Module from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Module'
import Con_AreaDashboard_Common from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L2_Common'
import Con_AreaDashboard_L3_ME01 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME01'
import Con_AreaDashboard_L3_ME02 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME02'
import Con_AreaDashboard_L3_ME04 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME04'
import Con_AreaDashboard_L3_ME05 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_ME05'
import Con_AreaDashboard_L3_MW01 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW01'
import Con_AreaDashboard_L3_MW02 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW02'
import Con_AreaDashboard_L3_MW04 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW04'
import Con_AreaDashboard_L3_MW05 from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_MW05'
import Con_AreaDashboard_L3_Common from      '../../09_Projects/Tortue/04_Construction/AreaDashboard_L3_Common'
import Con_MoreBtn from      '../../09_Projects/Tortue/04_Construction/MoreBtn'
import Con_MoreBtn_ME02 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_ME02'
import Con_MoreBtn_ME04 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_ME04'
import Con_MoreBtn_ME05 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_ME05'
import Con_MoreBtn_MW01 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_MW01'
import Con_MoreBtn_MW02 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_MW02'
import Con_MoreBtn_MW04 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_MW04'
import Con_MoreBtn_MW05 from      '../../09_Projects/Tortue/04_Construction/MoreBtn_MW05'
import Con_AreaDashboardBarchart from      '../../09_Projects/Tortue/04_Construction/AreaDashboardBarchart'
import Con_LQCOM_OGM from      '../../09_Projects/Tortue/04_Construction/LQCOM_OGM'
import Con_LQCOM_OGM_Modal from      '../../09_Projects/Tortue/04_Construction/LQCOM_OGM_Modal'
import Con_LQCOM_ImgModal from      '../../09_Projects/Tortue/04_Construction/LQCOM_ImgModal'
import Con_LQCOM_Level from      '../../09_Projects/Tortue/04_Construction/LQCOM_Level'
import Con_LQCOM_OvBlockBar from      '../../09_Projects/Tortue/04_Construction/LQCOM_OvBlockBar'
import Con_LQCOM_OvEqBar from      '../../09_Projects/Tortue/04_Construction/LQCOM_OvEqBar'
import Con_LQCOM_OVIMG from      '../../09_Projects/Tortue/04_Construction/LQCOM_OVIMG'
import Con_HULLFORCOM_OVIMG from      '../../09_Projects/Tortue/04_Construction/HULLFORCOM_OVIMG'
import Con_HULLAFTCOM_OVIMG from      '../../09_Projects/Tortue/04_Construction/HULLAFTCOM_OVIMG'
import Con_LQCOM_Shop from      '../../09_Projects/Tortue/04_Construction/LQCOM_Shop'
import Con_LQCOM_Shop_Modal from      '../../09_Projects/Tortue/04_Construction/LQCOM_Shop_Modal'
import Con_LQCOM_ProgChart from      '../../09_Projects/Tortue/04_Construction/LQCOM_ProgChart'
import Con_SimpleRect from      '../../09_Projects/Tortue/04_Construction/SimpleRect'
import Con_ImagesBox from                 '../../09_Projects/Tortue/04_Construction/ImagesBox'
import Con_ImagesBox_6 from                 '../../09_Projects/Tortue/04_Construction/ImagesBox_6'
import Con_ImagesBox_8 from                 '../../09_Projects/Tortue/04_Construction/ImagesBox_8'
import Con_ModuleSummary from                 '../../09_Projects/Tortue/04_Construction/ModuleSummary'
import Con_PipingItem from                '../../09_Projects/Tortue/04_Construction/PipingItem'
import Con_PipingOverall from             '../../09_Projects/Tortue/04_Construction/PipingOverall'
import Con_DiscStatusTrackingIcon from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon'//piping
import Con_DiscStatusTrackingIcon_architecture from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_architecture'
import Con_DiscStatusTrackingIcon_electrical from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_electrical'
import Con_DiscStatusTrackingIcon_hvac from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_hvac'
import Con_DiscStatusTrackingIcon_instrument from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_instrument'
import Con_DiscStatusTrackingIcon_insulation from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_insulation'
import Con_DiscStatusTrackingIcon_mechanical from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_mechanical'
import Con_DiscStatusTrackingIcon_painting from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_painting'
import Con_DiscStatusTrackingIcon_safety from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_safety'
import Con_DiscStatusTrackingIcon_structure from    '../../09_Projects/Tortue/04_Construction/DiscStatusTrackingIcon_structure'
import Con_DiscItemSummary from    '../../09_Projects/Tortue/04_Construction/DiscItemSummary'
import Con_DiscItemSummaryModal from    '../../09_Projects/Tortue/04_Construction/DiscItemSummaryModal'
import Con_DiscStatusSummaryTopsides from    '../../09_Projects/Tortue/04_Construction/DiscStatusSummaryTopsides'
import Con_TopsideLevel from    '../../09_Projects/Tortue/04_Construction/TopsideLevel'
import Con_TopsideModule_ME01 from    '../../09_Projects/Tortue/04_Construction/TopsideModules_ME01'
import Con_BlockTable from    '../../09_Projects/Tortue/04_Construction/BlockTable'
import Con_HullCommonDashboard from    '../../09_Projects/Tortue/04_Construction/HullCommonDashboard'
import Con_HullCommonDivision from    '../../09_Projects/Tortue/04_Construction/HullCommonDivision'
import Con_CommoditySummary from    '../../09_Projects/Tortue/04_Construction/CommoditySummary'
import Con_CommoditySummaryHull from    '../../09_Projects/Tortue/04_Construction/CommoditySummaryHull'
import Con_CommoditySummaryTable from    '../../09_Projects/Tortue/04_Construction/CommoditySummaryTable'
import Con_DiscIconStatusChart from    '../../09_Projects/Tortue/04_Construction/DiscIconStatusChart'
import Con_TopsideModulesChartAll from    '../../09_Projects/Tortue/04_Construction/TopsideModulesChartAll'

// Quality Control
import QC_WeldingRepairTrendChart from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairTrendChart'
import QC_WeldingRepairSummaryUT from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryUT'
import QC_WeldingRepairSummaryRT from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryRT'
import QC_WeldingRepairSummaryPAUT from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryPAUT'
import QC_WeldingRepairSummaryMT from    '../../09_Projects/Tortue/06_QualityControl/WeldingRepairSummaryMT'
import QC_3DBlockDivision from    '../../09_Projects/Tortue/06_QualityControl/3DBlockDivision'
import QC_DashboardBarchart from    '../../09_Projects/Tortue/06_QualityControl/DashboardBarchart'


// System Completion
// import SC_MCSkyline from              '../../09_Projects/Tortue/09_SystemCompletion/MC_Skyline'
// import SC_CommissioningSkyline from   '../../09_Projects/MD2/09_SystemCompletion/CommissioningSkyline'
  
// import SC_MC_DB_SysStatus from        '../../09_Projects/MD2/09_SystemCompletion/MC_DB_SysStatus'
import SC_MC_DB_ITRStatus from        '../../09_Projects/MD2/09_SystemCompletion/MC_DB_ITRStatus'
import SC_MC_DB_PunchTrends from      '../../09_Projects/MD2/09_SystemCompletion/MC_DB_PunchTrends'
  
import SC_Table_Lookahead from        '../../09_Projects/MD2/09_SystemCompletion/Table_Lookahead'
import SC_Table_MostOpen from         '../../09_Projects/MD2/09_SystemCompletion/Table_MostOpen'
import SC_Table_PassedPunch from      '../../09_Projects/MD2/09_SystemCompletion/Table_PassedPunch'
  
import SC_Disc_DB_ITRStatus from      '../../09_Projects/MD2/09_SystemCompletion/Disc_DB_ITRStatus'
import SC_Disc_DB_Package from        '../../09_Projects/MD2/09_SystemCompletion/Disc_DB_Package'
  
import SC_SChart_ITR from             '../../09_Projects/MD2/09_SystemCompletion/SChart_ITR'
import SC_SChart_Punch from           '../../09_Projects/MD2/09_SystemCompletion/SChart_Punch'
import SC_Chart_Punch from            '../../09_Projects/MD2/09_SystemCompletion/Chart_Punch'
import SC_Punch_Disc from             '../../09_Projects/MD2/09_SystemCompletion/Punch_Discipline'
  
import SC_Sys_MC_Overall from         '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_Overall'
import SC_Sys_MC_ITRA from            '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_ITRA'
import SC_Sys_MC_PunchChart from      '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_PunchChart'
import SC_Sys_MC_PunchDisc from       '../../09_Projects/MD2/09_SystemCompletion/Sys_MC_PunchDisc'
import SC_Sys_SC_Overall from         '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_Overall'
import SC_Sys_SC_ITRB from            '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_ITRB'
import Sys_SC_Boundary from           '../../09_Projects/MD2/09_SystemCompletion/Sys_SC_Boundary'
  
import SC_Reg_COISkyline from         '../../09_Projects/MD2/11_Regulatory/COI_Skyline'

// Commissioning  
import COM_OverallSummary from        '../../09_Projects/MD2/10_Commissioning/OverallSummary'
import COM_Procedures from            '../../09_Projects/MD2/10_Commissioning/Procedures'
import COM_ITR_B from                 '../../09_Projects/MD2/10_Commissioning/ITR_B'
import COM_Discipline from            '../../09_Projects/MD2/10_Commissioning/Discipline'

// Regulatory  
import RGT_COI_Skyline from           '../../09_Projects/MD2/11_Regulatory/COI_Skyline'
import RGT_Milestones from            '../../09_Projects/MD2/11_Regulatory/Milestones'
import RGT_OverallSummary from        '../../09_Projects/MD2/11_Regulatory/OverallSummary'
import RGT_RTP from                   '../../09_Projects/MD2/11_Regulatory/RTP'
import RGT_USCG from                  '../../09_Projects/MD2/11_Regulatory/USCG'

//Planning
import Const_Milestones from '../../09_Projects/TCO/03_Planning/Milestones'

//Safety
import SA_Hsse from    '../../09_Projects/TCO/02_Safety/Hsse'






export default {
  Const_Skyline_Erec,
  Const_BlockSummary,
  Const_ActivityGanttChart,
  Const_MeasurementBlock,

  // Common
  CMN_HistoryTable,
  CMN_TBD,
  CMN_Up2UText,
  CMN_3DViewerEmbedded,

  // System
  System_ChartOptions,
  System_Dropdown,
  System_SubMenu_01,

  //Homepage
  Home_ProjectProgress,
  Home_KeyMilestone,
  Home_Observation,
  Home_DataSource,
  Home_Milestones,
  Home_Milestones_R,
  
  // HSSE
  HSSE_SafetyPyramid,
  HSSE_LSR,
  HSSE_TSC,
  HSSE_Dashboard,
  
  //Planning
  Plan_ProgressSummaryTable,
  Plan_ModuleSummarySchedule,
  Plan_CommoditySummarySchedule,
  Plan_SummaryByArea,
  Plan_ModuleKeyMilestones,
  TopsidesGanttChart,
  Plan_FilterPhase,

  //Engineering
  Eng_CMDRStatus,
  Eng_IFCDrawingList,
  Eng_TotalPlanActualRemainL,
  Eng_TotalPlanActualRemainM,
  Eng_TotalPlanActualRemainS,
  Eng_CDREHistoryChart,
  Eng_TQHistoryChart,
  Eng_SDIDashboard,

  Eng_Interface,
  Eng_AreaInfo,
  Eng_AreaTable,

  // Procurement
  Proc_GanttChart,
  Proc_EquipmentLayout,
  Proc_EquipmentLayout_V1,
  Proc_EquipmentTable,
  Proc_Equipment3DLayout,
  Proc_EquipmentRoom,
  Proc_ProgressBar,
  Proc_Lookahead,
  Proc_OWL,
  Proc_OverallScurve,
  Proc_FATScurve,
  Proc_IRNScurve,
  Proc_ETAScurve,
  Proc_IRNLookahead,
  Proc_ETALookahead,
  Proc_EquipmentHullCommon,
      
  // Construction
  Const_MilestonesModule,
  Const_Skyline_Module,
  Const_Outfitting,
  Const_MeasurementDashboard,
  Con_BlockDivision,
  Con_BlockSummary,
  Con_ActivityGanttChart,
  Con_ActivityGanttChart_R1,
  Con_ActivityGanttChartLegend,
  Con_MeasurementBlock,
  Con_ShopDrawingActivities,
  Con_ShopDrawingStatus,
  Con_ShopDrawingStatusOther,
  Con_OutfittingStatus,
  Con_OutfittingStatus_new,
  Con_Outfitting,
  Con_ErectionTimeline,
  Con_OverallDashboard,
  Con_OverallDashboardTopside,
  Con_OverallDashboardTopside_L,
  Con_OverallDashboardTopside_S,
  Con_OverallDashboardHull,
  Con_OverallDashboardHull_L,
  Con_OverallDashboardHull_S,
  Con_OverallDashboardModules_S,
  Con_OverallDashboardLQ_S,
  Con_OverallDashboardLQ,
  Con_ProgressSummaryOverall,
  Con_ProgressSummaryTopside,
  Con_ProgressSummaryHull,
  Con_OutfittingCommoditiesStatus,
  Con_OutfittingCommoditiesStatusL,
  Con_OgmDisc,
  Con_OgmDashboardHull,
  Con_OgmDashboardTopside,
  Con_SimpleText,
  Con_OutfittingCommStatus,
  Con_OutfittingCommStatus_S,
  Con_SpoolSummarySize,
  Con_SpoolSummarySize_S,
  Con_SpoolSummarySize_L,
  Con_SpoolSummaryArea,
  Con_AreaDashboard_Hull_AFT,
  Con_AreaDashboard_Hull_FOR,
  Con_AreaDashboard_L3_ME01,
  Con_AreaDashboard_L3_ME02,
  Con_AreaDashboard_L3_ME04,
  Con_AreaDashboard_L3_ME05,
  Con_AreaDashboard_L3_MW01,
  Con_AreaDashboard_L3_MW02,
  Con_AreaDashboard_L3_MW04,
  Con_AreaDashboard_L3_MW05,
  Con_MoreBtn,
  Con_MoreBtn_ME02,
  Con_MoreBtn_ME04,
  Con_MoreBtn_ME05,
  Con_MoreBtn_MW01,
  Con_MoreBtn_MW02,
  Con_MoreBtn_MW04,
  Con_MoreBtn_MW05,
  Con_AreaDashboardBarchart,
  Con_LQCOM_OGM,
  Con_LQCOM_OGM_Modal,
  Con_LQCOM_ImgModal,
  Con_LQCOM_Level,
  Con_LQCOM_OvBlockBar,
  Con_LQCOM_OvEqBar,
  Con_HULLFORCOM_OVIMG,
  Con_LQCOM_OVIMG,
  Con_HULLAFTCOM_OVIMG,
  Con_LQCOM_Shop,
  Con_LQCOM_Shop_Modal,
  Con_LQCOM_ProgChart,
  Con_SimpleRect,
  Con_ImagesBox,  
  Con_ImagesBox_6,
  Con_ImagesBox_8,
  Con_ModuleSummary,
  Con_PipingItem,   
  Con_PipingOverall,
  Con_DiscStatusTrackingIcon,
  Con_DiscStatusTrackingIcon_architecture,
  Con_DiscStatusTrackingIcon_electrical,
  Con_DiscStatusTrackingIcon_hvac,
  Con_DiscStatusTrackingIcon_instrument,
  Con_DiscStatusTrackingIcon_insulation,
  Con_DiscStatusTrackingIcon_mechanical,
  Con_DiscStatusTrackingIcon_painting,
  Con_DiscStatusTrackingIcon_safety,
  Con_DiscStatusTrackingIcon_structure,
  Con_DiscItemSummary,
  Con_DiscItemSummaryModal,
  Con_DiscStatusSummaryTopsides,
  Con_TopsideLevel,
  Con_TopsideModule_ME01,
  Con_BlockTable,
  Con_HullCommonDashboard,
  Con_HullCommonDivision,
  // Con_AreaDashboard_Module,
  Con_AreaDashboard_Common,
  Con_AreaDashboard_L3_Common,
  Con_OverallDashboardModule_S,
  Con_OverallDashboardCommon_S,
  Con_CommoditySummary,
  Con_CommoditySummaryTable,
  Con_CommoditySummaryHull,
  Con_DiscIconStatusChart,
  Con_TopsideModulesChartAll,
  // 
  // Quality Control
  QC_WeldingRepairTrendChart,
  QC_WeldingRepairSummaryUT,
  QC_WeldingRepairSummaryRT,
  QC_WeldingRepairSummaryPAUT,
  QC_WeldingRepairSummaryMT,
  QC_3DBlockDivision,
  QC_DashboardBarchart,
  
  // System Completion
  SC_SubsystemList,
  SC_SystemBoundary,
  SC_Skyline,
  SC_MCSkyline,
  SC_ModuleSummary,
  SC_SheetSummary,
  SC_WalkdownAvailable,
  SC_DiscTrendChart,

  SC_CommissioningSkyline,
  // SC_MC_DB_SysStatus,
  SC_MC_DB_ITRStatus,
  SC_MC_DB_PunchTrends,

  SC_Table_Lookahead,
  SC_Table_MostOpen,
  SC_Table_PassedPunch,

  SC_Disc_DB_ITRStatus,
  SC_Disc_DB_Package,

  SC_SChart_ITR,
  SC_SChart_Punch,
  SC_Chart_Punch,
  SC_Punch_Disc,

  SC_Sys_MC_Overall,
  SC_Sys_MC_ITRA,
  SC_Sys_MC_PunchChart,
  SC_Sys_MC_PunchDisc,
  
  SC_Sys_SC_Overall,
  SC_Sys_SC_ITRB,
  Sys_SC_Boundary,

  SC_Reg_COISkyline,

  // Commissioning
  COM_OverallSummary,
  COM_Procedures,
  COM_ITR_B,
  COM_Discipline,

  // Regulatory
  RGT_COI_Skyline,
  RGT_Milestones,
  RGT_OverallSummary,
  RGT_RTP,   
  RGT_USCG,  


  // ### For the DEMO Project -----------
  // Home
  Home_ModuleStatus,          // TCO
  Home_OncomingSailaway,      // TCO
  Home_OverallProgress,       // TCO
  Home_OverallSummary,        // TCO
  Home_SailawayStatus,        // TCO
  Home_ConstructOutfitting,   // TCO
  Home_Yard,                  // TORTUE

  // Construction
  Con_BlockDivisionFGP,
  Con_BlockDivisionFP,

  // System Completion
  SC_RfsSkyline,
  // ### -------------------- -----------
  
  // Planning
  Con_BlockSkyline,

  //Planning
  Const_Milestones,

  //Safety
  SA_Hsse,
}
