import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {

    draw_BlockCode_Lv3(lv3, x, y) {
      
      // Draw border of code
      // The border of code provides two types Rect and ellipse.

      // this.svg_BlockCode = this.svg.append('svg')
      // this.svg_BlockCode
      // .attr('width', this.Canvas.CanvasWidth)
      // .attr('height', this.Canvas.CanvasHeight)
      // .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      // .attr('opacity', 1)

      // let BlockCodes = this.svg_BlockCode
      // .append('g')
      // .attr('id', 'BlockCodes')
      // .attr('transform', `translate(${x}, ${y}) scale(1)`)

      // this.dataSet.filter(f => f.Lv3 == lv3).forEach(data => {
      //   if (data.shape == 'rect') {
      //     BlockCodes
      //     .append('rect')
      //     .attr('x', data.x - data.width/2).attr('y', data.y)
      //     .attr('width', data.width).attr('height', data.height)
      //     .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
      //   }  
      //   if (data.shape == 'ellipse') {
      //     BlockCodes
      //     .append('ellipse')
      //     .attr('cx', data.x).attr('cy', data.y + (data.height/2))
      //     .attr('rx', data.width/2).attr('ry', data.height/2)
      //     .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
      //   }
      //   BlockCodes
      //   .append('text')
      //   .attr('transform', `translate(${data.x}, ${data.y + data.padding})`)
      //   .style('font-family', 'roboto').style('font-size', 10).style('fill', data.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      //   .text(`${data.BLOCK}`)
      // })
    },
    

    draw_BlockCode_Lv4(lv2, lv4, x, y) {
      
      // Draw border of code
      // The border of code provides two types Rect and ellipse.
      // this.svg_BlockCode = this.svg.append('svg')
      // this.svg_BlockCode
      // .attr('width', this.Canvas.CanvasWidth)
      // .attr('height', this.Canvas.CanvasHeight)
      // .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      // .attr('opacity', 1)

      // let BlockCodes = this.svg_BlockCode
      // .append('g')
      // .attr('id', 'BlockCodes')
      // .attr('transform', `translate(${x}, ${y})`)

      // this.dataSet.filter(f => f.Lv2 == lv2 && f.Lv4 == lv4).forEach(data => {
      //   if (data.shape == 'rect') {
      //     BlockCodes
      //     .append('rect')
      //     .attr('x', data.x - data.width/2).attr('y', data.y)
      //     .attr('width', data.width).attr('height', data.height)
      //     .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
      //   }  
      //   if (data.shape == 'ellipse') {
      //     BlockCodes
      //     .append('ellipse')
      //     .attr('cx', data.x).attr('cy', data.y + (data.height/2))
      //     .attr('rx', data.width/2).attr('ry', data.height/2)
      //     .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
      //   }
      //   BlockCodes
      //   .append('text')
      //   .attr('transform', `translate(${data.x}, ${data.y + data.padding})`)
      //   .style('font-family', 'roboto').style('font-size', 10).style('fill', data.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      //   .text(`${data.BLOCK}`)
      // })
    },

    draw_BlockCode_Module(lv3, x, y) {
      
      // Draw border of code
      // The border of code provides two types Rect and ellipse.

      this.svg_BlockCode = this.svg.append('svg')
      this.svg_BlockCode
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('viewBox', `0 0 ${this.Canvas.CanvasWidth} ${this.Canvas.CanvasHeight}`)
      .attr('opacity', 1)

      let BlockCodes = this.svg_BlockCode
      .append('g')
      .attr('id', 'BlockCodes')
      .attr('transform', `translate(${x}, ${y}) scale(1)`)

      this.dataSet.filter(f => f.Lv3 == lv3).forEach(data => {
        if (data.shape == 'rect') {
          BlockCodes
          .append('rect')
          .attr('x', data.x - (data.width+20)/2).attr('y', data.y)
          .attr('width', data.width+20).attr('height', data.height)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
        }  
        if (data.shape == 'ellipse') {
          BlockCodes
          .append('ellipse')
          .attr('cx', data.x).attr('cy', data.y + (data.height/2))
          .attr('rx', data.width/2).attr('ry', data.height/2)
          .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bColor)
        }
        BlockCodes
        .append('text')
        .attr('transform', `translate(${data.x}, ${data.y + data.padding})`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
        .text(`${data.BLOCK}`)
      })
    },

    Draw_EQ_Code(innerSvg, _level) {
      // CODE Definition
      // The code must be in the 'CODE' layer of AI.
      let codeLayer = innerSvg.select(`#CODE`)

     if(codeLayer.empty()) {
       //console.log('svg empty!!!')
       return
     }
    // console.log(_level)
     codeLayer.selectAll('g').each((_, i, a) => {
      let svgText = d3.select(a[i]).select('text')
       let no_ = svgText.text()
       let circle_ = d3.select(a[i]).select('circle')
       let coordinate = null
       svgText.text('')

       if(circle_.empty()) {
         
         coordinate = d3.select(a[i]).select('path').node().getBBox()
         coordinate = {
           x: coordinate.x + this.CircleBorder.rx - 3,
           y: coordinate.y + this.CircleBorder.ry - 3,
         }
       } else {
         coordinate = {
           x: circle_.attr('cx') ? Number(circle_.attr('cx')) : 0,
           y: circle_.attr('cy') ? Number(circle_.attr('cy')) : 0,
         }
       }

       let idx = this.dataSet.findIndex(d => d.LV4 == _level && d.SN == parseInt(no_))
       if (idx < 0) return

       let data = this.dataSet[idx]
       let style = data.STATUS == null ? null : this.Status.find(styl => styl.code == data.STATUS)

       // .getPointAtLength()

        // Draw border of code
        // The border of code provides two types Rect and ellipse.
        if(style) {
          let g = innerSvg
          .append('g')
          .attr('transform', `translate(${coordinate.x}, ${coordinate.y})`)
          .call(s => this.SendRequestAction(s, data.TAG))

          g.append('ellipse')
          .attr('rx', this.CircleBorder.rx).attr('ry', this.CircleBorder.ry)
          .attr('stroke', '#757575').attr('stroke-width', this.CircleBorder.stroke).attr('fill', style.bColor)

          g.append('text')
          .attr('transform', `translate(0, ${this.CircleBorder.padding})`)
          .attr('fill', style.tColor)
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .style('font-family', 'roboto').style('font-size', this.CircleBorder.textSize)
          .text(no_)
        }
      })
    },

    Draw_EQ_Tag(innerSvg, _level) {
      // CODE Definition
      // The code must be in the 'CODE' layer of AI.
      let codeLayer = innerSvg.select(`#TAG`)

      if(codeLayer.empty()) return
      this.drawTagButtons()
 
      codeLayer.selectAll('text').each((_, i, a) => {
        let svgText = d3.select(a[i])
        let text_ = svgText.text().split('-')
        let no_ = text_[0]
        let direction_ = text_[1]
        svgText.text('')
       
        
        let matrix = (svgText.attr('transform') || '').match(/matrix(.*?)\)/g)
        matrix = matrix[0].match(/-?\d+\.?(\d+)?/g)
        
        if(matrix.length == 6) var coordinate = {
          x: parseInt(matrix[4]),
          y: parseInt(matrix[5])
        }; else coordinate = {
          x: parseInt(matrix[6]),
          y: parseInt(matrix[7])
        }

        let idx = this.dataSet.findIndex(d => d.LV4 == _level && d.SN == parseInt(no_))
        if (idx < 0) return
 
        let data = this.dataSet[idx]
        // let style = this.Status.find(styl => styl.code == data.STATUS)
 
        // .getPointAtLength()
 
        // Draw border of code
        // The border of code provides two types Rect and ellipse.
 
        innerSvg 
        .append('text')
        .attr('class', `eq_tag__${_level}__${no_}`)
        .attr('x', direction_ == 'L' ? coordinate.x + 8 :coordinate.x - 5).attr('y', coordinate.y)
        .attr('fill', '#333')
        .attr('text-anchor', direction_ == 'L' ? 'start' : 'end')
        .attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto')
        .style('font-size', 9)
        .text('')   // TAG | TAG_DESCR | null
        .call(s => this.SendRequestAction(s, data.TAG))
      })

      setTimeout(() => { this.showTagButtons() })
    },
    Draw_EQ_Block(innerSvg, _level) {
      d3.select('#BLOCK_LINE').style('visibility', 'hidden')

      let codeLayer = innerSvg.select(`#BLOCK_NO`)

      let blockLayer = innerSvg 
      .append('g') // 1. blockLayer를 그룹으로 만들어서 이것이 사라지고 나타나게 만들자
      .attr('class', 'NEW_BLOCK_NO')
      .style('visibility', 'hidden') //시작할때 안보이게 만들기

      codeLayer.selectAll('g').each((_, i , a) =>{
        let svgText = d3.select(a[i]).select('text')
        let blkNo_ = svgText.text()
        let ellipse_ = d3.select(a[i]).select('ellipse')
        let rect_ = d3.select(a[i]).select('rect')
        let coordinate = null
        svgText.text('')

        if(!ellipse_.empty()) {
          coordinate = {
            x: ellipse_.attr('cx') ? Number(ellipse_.attr('cx')) + 2 : 0,
            y: ellipse_.attr('cy') ? Number(ellipse_.attr('cy')) + 2 : 0,
          }
        } else {
          coordinate = {
            x: rect_.attr('x') ? Number(rect_.attr('x')) + 1 : 0,
            y: rect_.attr('y') ? Number(rect_.attr('y')) : 0,
          }
        }
      
        let idx = this.Queries.SQL1.find(d => d.BLOCK == blkNo_)
        if (!idx) return
        // idx = 0
     
        // let data = this.dataSet[idx]
        // let style = data.STATUS == null ? null : this.Status.find(styl => styl.code == data.STATUS)
        
        if(idx.BLOCK_TYPE == 'MEGA'){
          if(idx.STATUS == 'EREC'){
            blockLayer // 2. blockLayer를 그룹으로 안에서 작동되게 innerSvg에서 바꿔주기
            .append('ellipse')
            .attr('cx', coordinate.x).attr('cy',coordinate.y)
            .attr('rx' ,this.BlockStyle.MEGA.rx).attr('ry',this.BlockStyle.MEGA.ry)
            .attr('stroke-width', this.BlockStyle.MEGA.stroke)
            .attr('stroke', '#333')
            .attr('fill', '#8BC34A')
          } else{
            blockLayer
            .append('ellipse')
            .attr('cx', coordinate.x).attr('cy',coordinate.y)
            .attr('rx' ,this.BlockStyle.MEGA.rx).attr('ry',this.BlockStyle.MEGA.ry)
            .attr('stroke-width', this.BlockStyle.MEGA.stroke)
            .attr('stroke', '#333')
            .attr('fill','#fff')
          }
        } else { // idx.BLOCK_TYPE == 'UNIT'
          if(idx.STATUS == 'EREC'){
            blockLayer
            .append('rect')
            .attr('x', coordinate.x - 13).attr('y', coordinate.y - 8)
            .attr('width', this.BlockStyle.UNIT.width).attr('height', this.BlockStyle.UNIT.height)
            .attr('stroke-width', this.BlockStyle.UNIT.stroke)
            .attr('stroke', '#333')
            .attr('fill', '#8BC34A')
          } else{
            blockLayer
            .append('rect')
            .attr('x', coordinate.x - 13).attr('y', coordinate.y - 8)
            .attr('width', this.BlockStyle.UNIT.width).attr('height', this.BlockStyle.UNIT.height)
            .attr('stroke-width', this.BlockStyle.UNIT.stroke)
            .attr('stroke', '#333')
            .attr('fill','#fff')
          }
        }
        blockLayer 
        .append('text')
        .attr('x', coordinate.x - 1).attr('y', coordinate.y - 1 + this.BlockStyle.MEGA.padding)
        .style('font-family', 'roboto').attr('fill', '#333').style('font-size', this.BlockStyle.MEGA.textSize)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text(blkNo_)
        
      })

      codeLayer.remove()
    },
  }
}
