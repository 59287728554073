import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    Canvas_ModuleDivision() {

      this.SVG_ModuleDivision = this.svg.append('svg')

      this.SVG_ModuleDivision
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)

      this.MODULE[this.CurrentArea].filter(f => f.type == 'Module').forEach (mod => {
        let statusData = this.Queries.SQL2.filter(f => f.MODULE == mod.module)
        let style = [
                    {name: 'Cutting',         bColor:'#B4E3FA', opacity: 0.25},
                    {name: 'Fabrication',     bColor:'#83D2F5', opacity: 0.40},
                    {name: 'Assembly',        bColor:'#44A9DF', opacity: 0.55},
                    {name: 'Pre-Outfitting',  bColor:'#FEED57', opacity: 0.70},
                    {name: 'Block Painting',  bColor:'#FCE3EB', opacity: 0.85},
                    {name: 'Erection',        bColor:'#CDDC37', opacity: 1.00},
            ]
        this.Bar_Activity_01(this.SVG_ModuleDivision, mod.module, mod.x + 40, mod.y, statusData, 10, 100, 12, 9, style, 'off')
      })




    },



  }
}
           