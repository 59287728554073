import * as d3 from 'd3'

export default {
  data: () => ({
    Back_Dashboard_Overall: null,
    Back_Dashboard_Area: null,
    Back_Hull_Activate: null,
    Back_Hull_SideBlocks: null,
    Back_Hull_ModuleDivision: null,
    Back_Hull_ModuleSummary: null,

    BackButtonUse: 'on',
 
  }),
  computed: {
  },

  methods: {
    
    setEvent_Back() {

      // Set Event Listener Back Button
      this.btnGoBack = (selection) => {
        selection
        .on('mouseover', () => {
          if (this.BackButtonUse != 'on') return
          d3.select(`#Menu_Back`).transition().duration(200).attr('opacity', 0.5)
        })
        .on('mouseout', () => {
          if (this.BackButtonUse != 'on') return
          d3.select(`#Menu_Back`).transition().duration(200).attr('opacity', 1)
        })
        .on('click', () => {
          d3.select(`#Menu_Back`).transition().duration(200).attr('opacity', 1)

          if (this.BackButtonUse != 'on') return

          switch(this.stage) {
            case this.STG_Dashboard_Area:
              this.BackButtonUse = 'off'

              this.Back_Dashboard_Overall()
              this.stage = this.STG_Dashboard_Overall                         ;this.StageCode() //////////////////////////// this.stage

              setTimeout(() => {
                this.BackButtonUse = 'on'
              }, 2000);
            break;

            case this.STG_Area_Summary:
              this.BackButtonUse = 'off'

              this.Back_Dashboard_Area()
              this.stage = this.STG_Dashboard_Area                            ;this.StageCode() //////////////////////////// this.stage

              setTimeout(() => {
                this.BackButtonUse = 'on'
              }, 2000);
            break;

            case this.STG_Side_Blocks:
              this.BackButtonUse = 'off'

              this.Back_AreaSummary()
              this.stage = this.STG_Area_Summary                            ;this.StageCode() //////////////////////////// this.stage

              setTimeout(() => {
                this.BackButtonUse = 'on'
              }, 2000);
            break;

            case this.STG_Module_Division:
             
              ////////////////////////////////////////////////
              if (this.CurrentArea !== 'Hull') {

                this.BackButtonUse = 'off'

                this.Back_AreaSummary()
                this.stage = this.STG_Area_Summary                            ;this.StageCode() //////////////////////////// this.stage

                setTimeout(() => {
                  this.BackButtonUse = 'on'
                }, 2000);

              } else {

                this.BackButtonUse = 'off'

                this.Back_Hull_SideBlocks()
                this.stage = this.STG_Side_Blocks                            ;this.StageCode() //////////////////////////// this.stage

                setTimeout(() => {
                  this.BackButtonUse = 'on'
                }, 2000);

              }

            break;

            case this.STG_Module_Summary:
              this.BackButtonUse = 'off'

              this.Back_Hull_ModuleDivision()
              this.stage = this.STG_Module_Division                            ;this.StageCode() //////////////////////////// this.stage

              setTimeout(() => {
                this.BackButtonUse = 'on'
              }, 2000);
            break;

            case this.STG_Block_Division:
              this.BackButtonUse = 'off'

              this.Back_Hull_ModuleSummary()
              this.stage = this.STG_Module_Summary                            ;this.StageCode() //////////////////////////// this.stage

              setTimeout(() => {
                this.BackButtonUse = 'on'
              }, 2000);
            break;
          }
        })
      }
      


      // Remove         : SVG_DashboardOverall
      // Didplay        : Menu_Back
      // Goto           : Dashboard_Area()
      this.Back_Dashboard_Overall = () => {

        this.removeSvg( this.SVG_DashboardArea )// remove SVG

        // Didplay Menu_Back
        d3.select(`#Menu_Back`).style('visibility', 'visible').transition().delay(750).duration(500).attr('opacity', 0)

        setTimeout(() => {
          d3.select(`#Menu_Back`).style('visibility', 'hidden')
          
          this.Dashboard_Overall()
        }, 750);

      }


      // Hide         : svgModeling
      // Hide         : SVG_AreaSummary
      // Hide         : keyPlan
      // Remove       : svgModeling
      // Remove       : SVG_AreaSummary
      // Goto         : Dashboard_Area()
      this.Back_Dashboard_Area = () => {

        this.svgModeling
        .transition().duration(500).style('opacity', 0) // Hide - svgModeling

        this.SVG_AreaSummary
        .transition().duration(500).style('opacity', 0) // Hide - SVG_AreaSummary

        d3.select(`#keyPlan`).transition().duration(500).style('opacity', 0) // Hide - keyPlan

        setTimeout(() => {
          this.removeSvg( this.svgModeling ) // remove SVG
          this.removeSvg( this.SVG_AreaSummary ) // remove SVG

          this.Dashboard_Area()
        }, 400);

      }


      // Remove         : SVG_DashboardOverall
      // Didplay        : Menu_Back
      // Goto           : Dashboard_Area()
      this.Back_AreaSummary = () => {

        this.hiddenGroupID('#Hull_SideBlock_Codes', 0, 300) // Hidden the Codes of Side Block
        this.hiddenGroupID('#HullMask', 0, 0) // Hidden HullMask

        if (this.CurrentArea == 'Hull') {

          // Explode the side block of Hull
          this.MODULE.Hull.filter(f => f.type == 'Block').forEach(mod => {
            d3.select(`#${mod.blk}`)
            .transition().delay(0).duration(1000)
            .attr('transform', `translate(${mod.x1}, ${mod.y1}) scale(${this.ScaleOverall[this.CurrentArea]})`)
            .style('cursor', 'pointer')
            if (mod.blk == 'PIPES') {
              d3.select(`#PIPES`)
              .transition().delay(500).duration(300).style('opacity', 1)
              .style('visibility', `visible`)
            }
          })

          this.hiddenGroupID('#Legends', 0, 500)

          setTimeout(() => {
            this.svgModeling
            .transition()
            .duration(1000)
            .attr('width', Math.round(this.Canvas.CanvasWidth * 0.7))
            .attr('height', Math.round(this.Canvas.CanvasHeight * 0.7))
            .attr('x', 50).attr('y', 0).attr('opacity', 1)
          }, 750);
          
        } 

        if (this.CurrentArea !== 'Hull') {
          this.removeSvg( this.SVG_ModuleDivision ) // remove SVG
          this.hiddenGroupID('#Module_No_Group', 300, 300)

          // Deckbox / Topside
          this.MODULE[this.CurrentArea].forEach(data => {
            
            d3.select(`#${data.module}`)
            .transition().duration(750)
            .attr('transform', `translate(${data.x1}, ${data.y1}) scale(1)`)
            .style('cursor', 'pointer') 
          })

          this.hiddenGroupID('#Legends', 0, 500)

          setTimeout(() => {
            this.svgModeling
            .transition()
            .duration(1000)
            .attr('width', Math.round(this.Canvas.CanvasWidth * 1.3))
            .attr('height', Math.round(this.Canvas.CanvasHeight * 1.3))
            .attr('x', -300).attr('y', -200).attr('opacity', 1.3)
          }, 750);
        }
        
        

        setTimeout(() => {
          this.Canvas_AreaSummary(this.CurrentArea)
        }, 1500);
        
      }

      
      // Remove         : SVG_DashboardOverall
      // Didplay        : Menu_Back
      // Goto           : Dashboard_Area()
      this.Back_Hull_SideBlocks = () => {

        this.removeSvg( this.SVG_ModuleDivision ) // remove SVG
        this.hiddenGroupID('#Module_No_Group', 300, 300)

        this.svgModeling
          .transition()
          .delay(300)
          .duration(1200)
          .attr('width', Math.round(this.Canvas.CanvasWidth * 0.8))
          .attr('height', Math.round(this.Canvas.CanvasHeight * 0.8))
          .attr('x', 200).attr('y', 100)


        // Explode         : Hull Module
        this.MODULE.Hull.filter(f => f.type == 'Module').forEach(mod => {
          d3.select(`#${mod.module}`)
          .transition().delay(300).duration(1200).attr('transform', `translate(${mod.x1}, ${mod.y1}) scale(1)`)
        })

        setTimeout(() => {
          this.MODULE.Hull.filter(f => f.type == 'Block').forEach(mod => {
            this.displayGroupID(`#${mod.blk}`, 0, 300, 1)
          })
        }, 1200);

        this.displayGroupID(`#HullMask`, 1500, 300, 0) // Display       : HullMask
        this.displayGroupID(`#Hull_SideBlock_Codes`, 1500, 300, 1) // Display         : Hull_SideBlock_Codes
        
      }


      
      // Remove         : SVG_DashboardOverall
      // Didplay        : Menu_Back
      // Goto           : Dashboard_Area()
      this.Back_Hull_ModuleDivision = () => {

        this.removeSvg( this.SVG_ModuleSummary ) // remove SVG
        this.hiddenGroupID('#Module_MenuSet', 0, 300, 0) // Hidden    : Module_MenuSet
        
        // Scale    : Selected Module
        let data = this.MODULE[this.CurrentArea].find(f=> f.module == this.Module)
        d3.select(`#${this.Module}`)
        .transition()
        .duration(750)
        .attr('transform', `translate(${data.x2}, ${data.y2}) scale(${this.ScaleModule[this.CurrentArea]})`)
        .style('cursor', 'pointer')

        this.hiddenGroupID(`#Key_${this.Module}`, 0, 750, 0)

        this.MODULE[this.CurrentArea].filter(f => f.type == 'Module').forEach(d => {
          if (d.module != this.Module) {
            this.displayGroupID(`#${d.module}`, 500, 500, 1)
            d3.select(`#${d.module}`).style('cursor', 'pointer')
          }
        })

        // Display         : Module_No_Group
        this.displayGroupID('#Module_No_Group', 1200, 300, 1)

        setTimeout(() => {
          this.Canvas_ModuleDivision()
        }, 1800);
      }



      
      // Remove         : SVG_DashboardOverall
      // Didplay        : Menu_Back
      // Goto           : Dashboard_Area()
      this.Back_Hull_ModuleSummary = () => {

        this.hiddenGroupID(`#${this.Module}_No_Group`, 0, 300, 0)
        
        // Move       : Block Images
        let data = this.MODULE[this.CurrentArea].find(f=> f.module == this.Module)
        d3.select(`#${this.Module}`)
        .transition().delay(300).duration(1200)
        .attr('transform', `translate(${data.x3}, ${data.y3}) scale(${this.ScaleSummary[this.CurrentArea]})`).style('cursor', 'default')

        this.BLOCKS[this.CurrentArea].filter(f => f.module == this.Module).forEach(d => {
          if (d.blk != `${this.Module}_P`) {
            d3.select(`#${d.blk}`)
            .transition().delay(300).duration(1200)
            .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${this.ScaleOverall[this.CurrentArea]})`).style('cursor', 'default')
          } else {
            d3.select(`#${d.blk}`)
            .transition().style('visibility', 'visible').delay(1200).duration(500).style('opacity', 1)
          }
        })

        // .attr('transform', `translate(${d.x1}, ${d.y1}) scale(${this.ScaleOverall[this.CurrentArea]})`).style('cursor', 'default')
        setTimeout(() => {
          // Setting      : Menu Group 
          d3.select(`#Menu_BlockDivision`)
          .transition().duration(100).attr('opacity', 1)
          .style('cursor', 'pointer')
          
          d3.select('#Module_MenuSet')
          .transition()
          .duration(750).attr('transform', `translate(380, 480)`)
          
          this.Canvas_ModuleSummary()
        }, 1000);
      }

      
    },
    
  }
}