import * as d3 from 'd3'

export default {
  methods: {

    draw_Outfitting() {
      
      let Outfits = ['STR','PNT','MEC','PIP',]
      let Testpkg = ['Inspt.','Flush','Hydro','Reinst.']
      let TestCode = ['LI','FL','HT','RE']

      this.dataSet.forEach((data,i) => {
        
        let pointY = 10
        let ourfittingGroup = this.chartGroup
        .append('g')
        .attr('transform', 'translate(32, 13)')
        
   
            let TestPackage = this.chartGroup
            .append('g')
            .attr('transform', `translate(${data.x}, ${data.y})`)

            TestPackage
            .append('text')
            .attr('x', 0).attr('y', 78)
            .style('font-family', 'roboto').attr('font-size', 11).attr('fill', '#333').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
            .text('Hydro Text')
            
            let checkSheet = TestPackage
            .append('g')
            .attr('transform', 'translate(0, 0)')
            .append('g')
            .selectAll('text')
            .data(Testpkg)
            .enter()
            
            checkSheet
            .append('rect')
            .attr('x', (d,i) => i * 40).attr('y', 90).attr('opacity', 0.5).attr('width', 37).attr('height', 14)
            .attr('fill',(d,n) =>{
              if(data[`${TestCode[n]}_T`] == data[`${TestCode[n]}_A`]) return '#CDDC37'; else return '#F7BACF'
            })

            checkSheet
            .append('text')
            .attr('x', (d,i) => (i*40) + 19).attr('y', 92)
            .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
            .text(d => d)

            checkSheet
            .append('text')
            .attr('x', (d,i) => (i*40) + 19).attr('y', 105)
            .style('font-family', 'roboto').attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
            .text( (d,n) => {
              console.log(
                data[`${TestCode[n]}_A`]
              )
              return data[`${TestCode[n]}_T`] + '/' + data[`${TestCode[n]}_A`]
            })
  

        Outfits.forEach((_, j) => {
          if (!data[`${Outfits[j]}_M_PP`]) return
          
          let outfittingCharts = ourfittingGroup
          .append('g')
          .attr('transform', 'translate(0, 0)')

          .on('mouseover', () => {
            outfittingCharts.style('cursor', 'pointer')
            d3.select(`#disc${i}_${j}`)
            .transition().duration(400).attr('fill', '#E0E0DF')
            .transition().duration(400).attr('fill', '#333')
            
            d3.select(`#planBar${i}_${j}`)
            .transition().duration(400).attr('width', 0)
            .transition().duration(400).attr('width', data[`${Outfits[j]}_M_PP`])

            d3.select(`#actualBar${i}_${j}`)
            .transition().duration(400).attr('width', 0)
            .transition().duration(400).attr('width', data[`${Outfits[j]}_A_PP`])
          })
          .on('mouseout', () => { 
            outfittingCharts.style('cursor', 'pointer')
            .style('cursor', 'default')
          })
          .on('click', () => { 
            console.log(this.dataSet[i].MOD, Outfits[j])
          })

          outfittingCharts
          .append('text')
          .attr('id',`disc${i}_${j}`)
          .attr('transform', d => `translate(${data.x - 2}, ${data.y + pointY - 1})`)
          .style('font-family', 'roboto').attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
          .text(Outfits[j])

          outfittingCharts
          .append('rect')
          .attr('id', `planBar${i}_${j}`)
          .attr('x', data.x).attr('y', data.y + pointY)
          .attr('width', 0).attr('height', 7).attr('fill', '#E0E0DF')
          .transition()
          .duration(500)
          .attr('width', data[`${Outfits[j]}_M_PP`])
        
          outfittingCharts
          .append('rect')
          .attr('id', `actualBar${i}_${j}`)
          .attr('x', data.x).attr('y', data.y + pointY)
          .attr('width', 0).attr('height', 7)
          .attr('opacity', 0.7)
          .attr('fill', d => {
            let plan = data[`${Outfits[j]}_M_PP`]
            let actual = data[`${Outfits[j]}_A_PP`]
            let color =''
    
            if (plan < actual) color = '#83D2F5'
            if (plan >= actual) color = '#F7BACF'
            if (actual == 100) color = '#CDDC37'
            return color
          })
          .transition()
          .duration(500).attr('width', data[`${Outfits[j]}_A_PP`])
          pointY += 13
        })

      })
    },

  }
}