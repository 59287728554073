import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    keyActivity: [
      {title: 'S/C',        total: 'CUT_T',   completed: 'CUT_A',}, 
      {title: 'Assembly',   total: 'ASSY_T',  completed: 'ASSY_A',}, 
      {title: 'Painting',   total: 'PNT_T',   completed: 'PNT_A',}, 
      {title: 'Erection',   total: 'EREC_T',  completed: 'EREC_A',}, 
      {title: 'Hydrotest',  total: 'HT_T',    completed: 'HT_A',}, 
      {title: 'A1',         total: 'A1_T',    completed: 'A1_A',}, 
      {title: 'B1',         total: 'B1_T',    completed: 'B1_A',},
      {title: 'D1',         total: 'D1_T',    completed: 'D1_A',},
    ],
  
  }),



  methods: {
    
    Const_Summary() {
      
      let SummaryMainGroup = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      SummaryMainGroup
          // .append('rect') // Background Window
          // .attr('x', 0).attr('y', 0).attr('width', 550).attr('height', 300)
          // .attr('fill', '#fff').attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)

          let ModPhoto = SummaryMainGroup
              .append('g')
              .attr('transform', `translate(20, 40)`)

              // ModPhoto
              // .append('rect') // Photo Window
              // .attr('x', 0).attr('y', 0).attr('rx', 5).attr('ry', 5).attr('width', 250).attr('height', 160)
              // .attr('fill', '#fff').attr('stroke', '#bcbcbc').attr('stroke-width', 0.2)

              ModPhoto
              .append('image')
              .attr('id', 'menuIcon')
              .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TCO/Modules/Images/size_500/${this.DataItems[0].MOD}.jpg`)
              .attr('x', 25).attr('y', 3).attr('width', 240).attr('height', 150)


          let ModInfo = SummaryMainGroup
              .append('g')
              .attr('transform', `translate(350, 40)`)
              
              // Module Information - Facility
              
              ModInfo
              .append('circle').attr('cx', -7).attr('cy', 2).attr('r',3).attr('fill','#44A9DF')
              ModInfo
              .append('text').attr('x', 0).attr('y', 5).attr('font-size', 11).attr('fill','#44A9DF').style('font-family','roboto')
              .text(this.DataItems[0].MOD)
              ModInfo
              .append('text').attr('x', 5).attr('y', 20).attr('font-size', 10).attr('fill','#757575').style('font-family','roboto')
              .text(this.DataItems[0].MOD_DESC)

              // Module Information - Area
              ModInfo
              .append('circle').attr('cx', -7).attr('cy', 44).attr('r',3).attr('fill','#44A9DF')
              ModInfo
              .append('text').attr('x', 0).attr('y', 47).attr('font-size', 11).attr('fill','#44A9DF').style('font-family','roboto')
              .text('TSCD / RFS')
              ModInfo
              .append('text').attr('x', 5).attr('y', 63).attr('font-size', 10).attr('fill','#757575').style('font-family','roboto')
              .text(`${this.DataItems[0].TSCD} ~ ${this.DataItems[0].RFS_ESD}`)

              // Module Information - Area
              ModInfo
              .append('circle').attr('cx', -7).attr('cy', 86).attr('r',3).attr('fill','#44A9DF')
              ModInfo
              .append('text').attr('x', 0).attr('y', 89).attr('font-size', 11).attr('fill','#44A9DF').style('font-family','roboto')
              .text('PAU / Block')
              ModInfo
              .append('text').attr('x', 5).attr('y', 104).attr('font-size', 11).attr('fill','#757575').style('font-family','roboto')
              .text(`No. of PAU: ${this.DataItems[0].PAU}  /  No. of Block: ${this.DataItems[0].BLK}`)

              // Module Information - Area
              ModInfo
              .append('circle').attr('cx', -7).attr('cy', 128).attr('r',3).attr('fill','#44A9DF')
              ModInfo
              .append('text').attr('x', 0).attr('y', 131).attr('font-size', 11).attr('fill','#44A9DF').style('font-family','roboto')
              .text('Size / Weight')
              ModInfo
              .append('text').attr('x', 5).attr('y', 146).attr('font-size', 11).attr('fill','#757575').style('font-family','roboto')
              .text(`${this.DataItems[0].LEN}m x ${this.DataItems[0].WID}m x ${this.DataItems[0].HEI}m  (${this.DataItems[0].WT} tons)`) 



          let Activityies = SummaryMainGroup
              .append('g')
              .attr('transform', `translate(20, 240)`)

              Activityies
              .append('text').attr('x', 110).attr('y',-11).attr('font-size', 10).attr('fill','#333').style('font-family','roboto').text('Construction (Block)')
              Activityies
              .append('text').attr('x', 297).attr('y',-11).attr('font-size', 10).attr('fill','#333').style('font-family','roboto').text('Mechanical Completion')
              Activityies
              .append('text').attr('x', 430).attr('y',-11).attr('font-size', 10).attr('fill','#333').style('font-family','roboto').text('Pre-Comm.')
              Activityies
              .append('text').attr('x', 505).attr('y',-11).attr('font-size', 10).attr('fill','#333').style('font-family','roboto').text('Destack')

              Activityies
              .append('path')
              .attr('d', `M 0,-8 L 277,-8`).attr('fill', 'transparent').attr('stroke-width',0.3).attr('stroke', '#757575')
              Activityies
              .append('path')
              .attr('d', `M 280,-8 L 417,-8`).attr('fill', 'transparent').attr('stroke-width',0.3).attr('stroke', '#757575')
              Activityies
              .append('path')
              .attr('d', `M 420,-8 L 487,-8`).attr('fill', 'transparent').attr('stroke-width',0.3).attr('stroke', '#757575')
              Activityies
              .append('path')
              .attr('d', `M 490,-8 L 557,-8`).attr('fill', 'transparent').attr('stroke-width',0.3).attr('stroke', '#757575')

          let pointX = 0 
          let barWidth = 67
          let barProg = 0

          this.keyActivity.forEach((i) =>{

              Activityies
              .append('path') // Background
              .attr('d', `M ${pointX},0 
                          L ${pointX + barWidth}, 0
                          L ${pointX + barWidth + 10}, 13
                          L ${pointX + barWidth}, 26
                          L ${pointX}, 26
                          L ${pointX + 10}, 13
                          Z
                  `).attr('fill', '#F4F5F5')

              pointX += 70
          })

          pointX = 0 
          this.keyActivity.forEach((data,i) =>{
            barProg = pointX + ((this.DataItems[0][data.completed]/this.DataItems[0][data.total]) * barWidth)
              Activityies
              .append('path') // Actual
              .attr('d', `M ${pointX},0 
                          L ${pointX}, 0
                          L ${pointX + 10}, 13
                          L ${pointX}, 26
                          L ${pointX}, 26
                          L ${pointX + 10}, 13
                          Z
                  `)
              .attr('fill', () => {
                if (this.DataItems[0][data.completed] == this.DataItems[0][data.total]){ return '#CDDC37' } else { return '#FEED57' }
              })
              .transition().duration(500)
              .attr('d', `M ${pointX},0 
                          L ${barProg}, 0
                          L ${barProg + 10}, 13
                          L ${barProg}, 26
                          L ${pointX}, 26
                          L ${pointX + 10}, 13
                          Z
                  `)

              Activityies
              .append('text').attr('x', pointX + 17).attr('y',17).attr('font-size', 11).style('font-family','roboto')
              .attr('fill',() => {
                if (this.DataItems[0][data.completed] == this.DataItems[0][data.total]){ return '#fff' } else { return '#757575' }
              })
              .text(data.title)

              Activityies
              .append('text').attr('x', pointX + barWidth).attr('y',38).attr('font-size', 10).attr('fill','#757575').style('font-family','roboto').attr('text-anchor','end')
              .text(`${this.DataItems[0][data.completed]} / ${this.DataItems[0][data.total]}`)

              pointX += 70
          })
          
              
              


    }, 

  }
}
