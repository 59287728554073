import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {

  methods: {
    Canvas_AreaSummary(CurrentArea) {
      
      this.SVG_AreaSummary = this.svg.append('svg')

      this.SVG_AreaSummary
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)

      // Chart Library
      // Progress Summary
      let AreaSummaryTable = this.SVG_AreaSummary
      .append('g')
      .attr('id', `AreaSummaryTable`)
      .attr('opacity', 0)

      let data = this.Queries.SQL_MasterProg.find(f => f.AREA == CurrentArea)
      this.Header_Progress_PAD( AreaSummaryTable,'Construction',      200,  600, 88, 20, 3, 
                              [data.CON_P_PG, data.CON_A_PG, data.CON_D_PG],
                              '#fff', '#44A9DF'
                            )



      let style = {
        title: 'Mech. Completio',
        x: 1000,
        y: 120,
        width: 115,
        height: 20,
        radius: 3,
        tSize: 12,
        tColor: '#fff',
        bColor: '#44A9DF',
        progSize: 15,
      }
      this.Header_01( AreaSummaryTable, data.A_PG, style)

      style = {
        title: 'Pre-Commissioning',
        x: 1000,
        y: 570,
        width: 125,
        height: 20,
        radius: 3,
        tSize: 12,
        tColor: '#fff',
        bColor: '#44A9DF',
        progSize: 15,
      }
      this.Header_01( AreaSummaryTable, data.B_PG, style) // ------------>
  

      style = {
        id: 'MC',
        title: 'ITRs(A)',
        x: 1000,
        y: 150,
        width: 210,
        height: 40,
        cell: [10, 90, 140, 190],
        bColor: '#88C8EB',
        opacity: 1,
        requests: {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 173,
            no: ''
          },
          filters     : {
          },
          iFilters    : {
          }
        }
      }
      this.SummaryTable2( AreaSummaryTable, {TOTAL:data.A_T, ACTUAL:data.A_A, REMAIN:data.A_O,}, style) // ------------>


      style = {
        id: 'Punch',
        x: 1000,
        y: 195,
        width: 210,
        height: 65,
        cell: [10, 90, 140, 190],
        bColor: '#83D2F5',
        opacity: 0.9,
        requests: {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 173,
            no: ''
          },
          filters     : {
          },
          iFilters    : {
          }
        }
      }
      let data1 = ['Cat. A', data.PA_T, data.PA_A, data.PA_O]
      let data2 = ['Cat. B', data.PB_T, data.PB_A, data.PB_O]
      this.SummaryTable3( AreaSummaryTable, data1, data2, style) // ------------>
      

      style = {
        id: 'SC',
        title: 'ITRs(B)',
        x: 1000,
        y: 600,
        width: 210,
        height: 40,
        cell: [10, 90, 140, 190],
        bColor: '#88C8EB',
        opacity: 0.9,
        requests: {
          dataType: 'row',
          action: {
            type: 'direct',
            target: 'slide-modal',
            component: 'ServiceDatatable',
            id: 173,
            no: ''
          },
          filters     : {
          },
          iFilters    : {
          }
        }
      }
      data = {TOTAL:data.B_T, ACTUAL:data.B_A, REMAIN:data.B_O,}
      this.SummaryTable2( AreaSummaryTable, data, style) // ------------>

      d3.select('#AreaSummaryTable').transition().duration(750).style('opacity', 1)




      // Chart Library ------------------
      // Charts_Radar.mixin > draw_Radar()
      let RadarChart = this.SVG_AreaSummary

      RadarChart
      .append('text')
      .attr('x', 550).attr('y', 650)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Discipline Progress(%)')

      RadarChart
      .append('text')
      .attr('x', 830).attr('y', 650)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Piping Test Package')

      this.Radar.chart.size = 150
      style = {
        x: 550,
        y: 770,
      }
      data = this.Queries.SQL3.filter(f => f.AREA == CurrentArea && f.PHASE =='CON')
      this.draw_Radar(this.SVG_AreaSummary, data, style) // ------------>

      style = {
        x: 830,
        y: 770,
      }
      data = this.Queries.SQL3.filter(f => f.AREA == CurrentArea && f.PHASE =='PKG')
      this.draw_Radar(this.SVG_AreaSummary, data, style) // ------------>

      style = {
        x: 1120,
        y: 380,
      }
      data = this.Queries.SQL3.filter(f => f.AREA == CurrentArea && f.PHASE =='MC')
      this.draw_Radar(this.SVG_AreaSummary, data, style) // ------------>

      style = {
        x: 1120,
        y: 770,
      }
      data = this.Queries.SQL3.filter(f => f.AREA == CurrentArea && f.PHASE =='COM')
      this.draw_Radar(this.SVG_AreaSummary, data, style) // ------------>



      // Chart Library ---------------------------
      // Custom_Charts.mixin > Bar_Activity_01()
      let statusData = this.Queries.SQL2.filter(f => f.AREA == 'Hull' && f.MODULE == 'Overall')
      style = [
                  {name: 'Cutting Commenced',   bColor:'#44A9DF', opacity: 0.25},
                  {name: 'Fab. Commenced',      bColor:'#44A9DF', opacity: 0.40},
                  {name: 'Assembly 100%',       bColor:'#44A9DF', opacity: 0.55},
                  {name: 'Pre-Outfitting 100%', bColor:'#44A9DF', opacity: 0.70},
                  {name: 'Block Painting 100%', bColor:'#44A9DF', opacity: 0.85},
                  {name: 'Erection Completion', bColor:'#44A9DF', opacity: 1.00},
          ]
      this.Bar_Activity_01(this.SVG_AreaSummary, 'con', 200, 670, statusData, 12, 150, 20, 10, style, 'on') // ------------>

    },



  }
}
           