import * as d3 from 'd3'

export default {
  data: () => ({
    layerPopup: null,
    stylePopup: {
      blkinfo: [
        { label: 'PO'               , textid: 'value_po'    , colName: { plan: 'PO_PLAN'            , actual: 'PO_ACTUAL' }, },
        { label: 'IRN'              , textid: 'value_irn'   , colName: { plan: 'IRN_PLAN'           , actual: 'IRN_ACTUAL' }, },
        { label: 'ON Site'          , textid: 'value_eta'   , colName: { plan: 'ETA_PLAN'           , actual: 'ETA_ACTUAL' }, },
        { label: 'ROS'              , textid: 'value_ros'   , colName: { plan: 'ROS'                , actual: '' }, },
        { label: 'Upper Block Erct.', textid: 'value_berct' , colName: { plan: 'UPPERBLOCK_ERECTION', actual: '' }, },
        { label: 'Installation'     , textid: 'value_inst'  , colName: { plan: ''                   , actual: 'INSTALL' }, },
      ]
    },

    scrollProps: {
      w: 572, // set clip width
      h: 120, // full clip height
      g: 0,
      bar: {
        align : 'right',
        gap   : 3,
        radius: 2,
        height : 6,
        fill  : '#9E9E9E',
        stroke: '#9E9E9E',
      }
    }
  }),
  methods: {
    drawBlkPopup() {
      this.layerPopup = this.svg
      .append('g')
      .attr('id', 'layer_blk_popup')
      .style('visibility', 'hidden')

      this.layerPopup
      .append('rect')
      .attr('class', '_overlay_blk_popup')
      .attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight)
      .attr('fill', '#fff')
      .style('opacity', 0)
      .on('click', this.closeBlkPopup)

      let popup = this.layerPopup
      .append('g')
      .attr('class', '_popup_box')
      .attr('transform', `translate(450, 112)`)
      .call(this.popup2drag)
      .call(this.setPopupCurMoveing)

      popup
      .append('rect')
      .attr('class', '_popup_box_bg')
      .attr('width', 585)
      .attr('height', 310)
      .attr('fill', '#fff')
      .attr('rx', 3)
      .attr('ry', 3)
      .style('filter', 'url(#dropshadow)')

      popup
      .append('text')
      .attr('transform', `translate(6.5, 12)`)
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#959595')
      .text('BLOCKS OVERVIEW')

      popup
      .append('text')
      .attr('transform', `translate(342.5, 14)`)
      .style('font-family', 'roboto')
      .style('font-size', 9)
      .style('fill', '#e5e5e5')
      .style('cursor', 'pointer')
      .attr('text-anchor', 'middle')
      .text('DRAG TO MOVE')

      popup
      .append('text')
      .attr('transform', `translate(570, 14)`)
      .style('font-family', 'roboto')
      .style('font-size', 12)
      .style('fill', '#000')
      .style('cursor', 'pointer')
      .text('×')
      .on('mouseorver', (_, i, a) => { d3.select(a[i]).style('fill', '#999') })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).style('fill', '#000') })
      .on('click', this.closeBlkPopup)

      // day information
      let behind = popup
      .append('g')
      .attr('class', 'behind_group')
      .attr('transform', `translate(6.5, 30.5)`)

      behind
      .append('rect')
      .attr('width', 572)
      .attr('height', 120)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
      .style('fill', '#fff')

      behind
      .append('rect')
      .attr('transform', `translate(5, -3)`)
      .attr('width', 85)
      .attr('height', 10)
      .style('fill', '#fff')

      behind
      .append('text')
      .attr('transform', `translate(10, 3)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#000')
      .text('Behind')

      behind
      .append('text')
      .attr('class', 'behind_counter')
      .attr('transform', `translate(50, 3)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#000')
      .text('(75)')

      behind
      .append('g')
      .attr('class', 'behind_image_wrapper')
      .attr('transform', `translate(0, 0)`)
      // .attr('clip-path', 'url(#mask_horizontal_img_slide)')

      // tag information
      let ahead = popup
      .append('g')
      .attr('class', 'ahead_group')
      .attr('transform', `translate(6.5, 175.5)`)

      ahead
      .append('rect')
      .attr('width', 572)
      .attr('height', 120)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
      .style('fill', '#fff')

      ahead
      .append('rect')
      .attr('transform', `translate(5, -3)`)
      .attr('width', 85)
      .attr('height', 10)
      .style('fill', '#fff')

      ahead
      .append('text')
      .attr('transform', `translate(10, 3)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#000')
      .text('Ahead')

      ahead
      .append('text')
      .attr('class', 'ahead_counter')
      .attr('transform', `translate(45, 3)`)
      .style('font-family', 'roboto')
      .style('font-size', 11)
      .style('fill', '#000')
      .text('(75)')

      ahead
      .append('g')
      .attr('class', 'ahead_image_wrapper')
      .attr('transform', `translate(0, 0)`)
    },
    openBlkPopup(code) {
      let popup = d3.select('#layer_blk_popup')
      let imgstart = 10
      let imggap = 20
      let imgw = 80
      let imgh = 80

      let data = this.Queries.SQL1.filter(d => d.CODE == code && d.DELTA_CODE == 'DELAY')
      let wrapper = d3.select('.behind_image_wrapper')

      d3.select('.behind_counter').text(`(${data.length || 0})`)
      d3.select('.behind_image_wrapper').selectAll('*').remove()
      data = data.sort((a, b) => (a.BLOCK > b.BLOCK) ? 1 : -1)


      data.forEach((d, i) => {
        let dirname = (d.LV1 == 'TOPSIDES' ? 'Topsides' : 'Hull')
        let wrapper__ = wrapper
        .append('g')
        .attr('class', 'behind_item')
        .on('click', (_, i, a) => {
          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceSvgTabs',
              id: 286,
              no: 'LIBSVGTB-0020'
            },
            filters: { 
              GWBS_LV1: d.LV1,
              GWBS_LV2: d.LV2,
              GWBS_LV3: d.LV3,
              GWBS_LV4: d.LV4,
              BLOCK: d.BLOCK,
              MBLOCK: d.BLOCK
            },
            iFilters: {
              filterString : '',
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })

        wrapper__
        .append('image')
        .attr('transform', `translate(${imgstart + ((imgw + imggap) * i)}, 10)`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Skyline/${d.BLOCK}.jpg`)
        .style('width', imgw)
        .style('height', imgh)

        wrapper__
        .append('text')
        .attr('transform', `translate(${imgstart + ((imgw + imggap) * i) + (imgw / 2)}, ${imgh + 25})`)
        .style('font-size', 10)
        .style('font-family', 'roboto')
        .attr('fill', '#454545')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(d.BLOCK)
      })

      let scrollId = 'behind_horizontal'
      let barTarget = d3.select('.behind_group')
      
      if(data.length > 0) this.setHorizonScroll(wrapper, this.scrollProps, scrollId, barTarget)
      if(data.length === 0) d3.select(`scrollable_#${scrollId}___bar`).remove()
      
      data = this.Queries.SQL1.filter(d => d.CODE == code && d.DELTA_CODE == 'AHEAD')
      wrapper = d3.select('.ahead_image_wrapper')

      d3.select('.ahead_image_wrapper').selectAll('*').remove()
      d3.select('.ahead_counter').text(`(${data.length || 0})`)
      data = data.sort((a, b) => (a.BLOCK > b.BLOCK) ? 1 : -1)

      data.forEach((d, i) => {
        let dirname = (d.LV1 == 'TOPSIDES' ? 'Topsides' : 'Hull')
        let wrapper__ = wrapper
        .append('g')
        .attr('class', 'ahead_item')
        .on('click', () => {
          // If the "ServiceSvgTabs" is used, right "no" must be inputted. It cannot be used for description.

          let request_ = {
            dataType: 'row',
            action: {
              type: 'direct',
              target: 'slide-modal',
              component: 'ServiceSvgTabs',
              id: 286,
              no: 'LIBSVGTB-0020' // !!!important => It must be inputted right "CHART_NO"
            },
            filters: { 
              GWBS_LV1: d.LV1,
              GWBS_LV2: d.LV2,
              GWBS_LV3: d.LV3,
              GWBS_LV4: d.LV4,
              BLOCK: d.BLOCK,
              MBLOCK: d.BLOCK
            },
            iFilters: {
              filterString : '',
              inputFilter  : ''
            }
          }
          
          this.$emit('request-action', request_)
        })

        wrapper__
        .append('image')
        .attr('transform', `translate(${imgstart + ((imgw + imggap) * i)}, 10)`)
        // .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Division/Blocks/${dirname}/${d.BLOCK}.png`)
        .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/TORTUE/Skyline/${d.BLOCK}.jpg`)
        .style('width', imgw)
        .style('height', imgh)

        wrapper__
        .append('text')
        .attr('transform', `translate(${imgstart + ((imgw + imggap) * i) + (imgw / 2)}, ${imgh + 25})`)
        .style('font-size', 10)
        .style('font-family', 'roboto')
        .attr('fill', '#454545')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(d.BLOCK)
      })

      scrollId = 'ahead_horizontal'
      barTarget = d3.select('.ahead_group')

      if(data.length > 0) this.setHorizonScroll(wrapper, this.scrollProps, scrollId, barTarget)
      if(data.length === 0) d3.select(`#scrollable_${scrollId}___bar`).remove()

      popup.style('visibility', 'visible')
      popup.moveToFront()
    },
    closeBlkPopup() {
      d3.select('#layer_blk_popup').style('visibility', 'hidden')
    }
  }
}