import Defs from '../../../../../../includes/primitives/Color_Defs'
import Data from '../../../../../../includes/primitives/Color_Data'

import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    innerSvg: null,
    keyPlanSvg: null,
    Module: '41M001',
  }),
  computed: {

    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.ChartType', this.ChartType)
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      // console.log('this.Status.length', this.Status.length)
      // console.log('this.Border.length', this.Border.length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },
  

  methods: {
    setDefaultValues(){
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.5, 0.5, -0.2], this.localId)


      // this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      
      // this.dataSet.forEach(data => {
      //   //
      // })
      
    },

    
    
    
  }
}