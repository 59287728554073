export default {
  data: () => ({
    dataSet: [],
    lineChartData: [],

    style: {
      id          : 'AITR',
      title       : ['Total', 'Open', 'BP', 'TFMC'],
      underTitle  : ['Review', 'development'],
      cell        : [30, 98, 170, 258],
      x           : 70,
      y           : 80,
      y1          : 17,
      y0          : 21,
      y2          : 27,
      y3          : 45,
      width       : 306,
      height      : 63,
      radius      : 3,
      bColor      : '#94D5F5',
      tSize       : 11,
      tColor      : '#2c3e50',
      vSize       : 12,
      vColor      : '#fff',
      value       : []
    },
    timeline: { 
      week        : [],
      month       : [],
      year        : [],
      plan        : [],
      actual      : [],
      scale       : null,     

      weekSize    : null,     // Auto
      length      : null,     // Auto
      weight      : 0,        // Manual, Should be assigned by 'SetDefaultValues()'
      height      : null,     // Auto
      font        : 'roboto', 
      baseX       : 115,
      baseY       : 550,

      style: {
        week: {
          display   : 'N',
          height    : 15,
          line      : 12,
          sWidth    : 0.5,
          sColor    : '#BCBCBC',
          tSize     : 9,
          tColor    : '#757575',
        },
        month: {
          display   : 'N',
          height    : 14,
          line      : 15,
          sWidth    : 0.5,
          sColor    : '#BCBCBC',
          tSize     : 10,
          tColor    : '#333',
        },
        year: {
          display   : 'N',
          height    : 10,
          line      : 20,
          sWidth    : 0.5,
          sColor    : '#BCBCBC',
          tSize     : 11,
          tColor    : '#000',
        }
      }
    },
    table: {
      display: 'Y',
      bottom: 500,
      dwarY:45,
      gapFromChart: 0,
      cellWidth: 0,
      labelX: -78,

      width:290, 
      hHeight: 20,
      lineHeight: 20,

      sColor:'#757575', 
      sWidth: 0.5, 
  
      headers: [
        {name: 'Weekly Approved'  , colName: 'WK_APPROVED'    , x: 250, y: 13,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#e0e0e0'},
        {name: 'Weekly Submitted' , colName: 'WK_SUBMITTED'   , x: 110, y: 20,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#e53935'}, 
        {name: 'Cum. Approved'    , colName: 'CUM_APPROVED'   , x: 175, y: 13,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#9e9d24'}, 
        {name: 'Cum. Submitted'   , colName: 'CUM_SUBMITTED'  , x: 110, y: 20,  col: 'Y', link: 'N', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 10, markupFill: '#9c27b0'},
        
      ],
    }  
  })
}