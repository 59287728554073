import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    loadSvg(path) {
      return d3.xml(path).then(source => {
        if(this.innerSvg) {
          this.innerSvg.html()
          this.innerSvg.remove()
          this.innerSvg = null
        }

        this.innerSvg = source.documentElement
        this.svg.node().append(this.innerSvg)

        this.innerSvg = d3.select(this.innerSvg)
        this.innerSvg.attr('x',0).attr('y',0).attr('width',1360).attr('height',3200)
        .attr('opacity', 0)
        .transition()
        .duration(500)
        .attr('opacity', 1)

        return new Promise(resolve => {
          resolve('test')
        })
      })
    },


    draw_SVGs() {
      // CODE Definition
      // The code must be in the 'CODE' layer of AI.
      let codeLayer = this.innerSvg.select(`#CODE`)
      let countCode = codeLayer.node().children.length // Get no of Code(text)

      for(var i=0; i < countCode; i++) {
        let codeName = codeLayer.node().children[i].textContent
        let idx = this.dataSet.findIndex(d => d.MOD == codeName)

        if (idx < 0) continue

        // console.log(i, codeName, this.dataSet[idx].name)

        this.dataSet[idx].x = this.getMatrixProps(d3.select(codeLayer.node().children[i]), d3.select(codeLayer.node().children[i]).attr('transform')).cx
        this.dataSet[idx].y = this.getMatrixProps(d3.select(codeLayer.node().children[i]), d3.select(codeLayer.node().children[i]).attr('transform')).cy

        d3.select(codeLayer.node().children[i]).style('visibility','hidden')
        

        let data = this.dataSet[idx]

        this.innerSvg
          .append('text')
          .attr('transform', `translate(${data.x}, ${data.y})`).attr('font-weight', 400)
          .style('font-family', 'roboto').style('font-size', 16).attr('fill', '#44A9DF').attr('text-anchor', 'start').attr('alignment-baseline', 'alphabetic')
          .text(data.MOD)

        this.innerSvg
          .append('text')
          .attr('transform', `translate(${data.x}, ${data.y+15})`).attr('font-weight', 400)
          .style('font-family', 'roboto').style('font-size', 12).attr('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'alphabetic')
          .text(data.MOD_DESC)

        
      }

      // Mask button Definition
      // The Mask button must be in the 'MASK' layer of AI.
      let self_ = this
      let maskLayer = this.innerSvg.select(`#MASK`)
      
      maskLayer.selectAll('g').each((_, i, g) => {

        let setAction_ = (selection) => {
          selection
            .on('mouseover', (_, i, a) => { 
              d3.select(a[i])
              .style('cursor', 'pointer')
              .transition().duration(200).style('opacity', 0.7)
            })
            .on('mouseout', (_, i, a) => { 
              d3.select(a[i])
              .style('cursor', 'default')
              .transition().duration(100).style('opacity', 0)
            })
            .on('click', (_, i, t) => {
              // ### REQUEST-ACTION ###
              // Every request from the chart should be sent by the event
              // name 'request-action'. It is one of the reserved name of 
              // the requester.

              // REQUEST-ACTION must include the field name 'action' & 
              // 'filters'. The 'action' usually is the props 'Action'.
              // And, if there is no filter to request, make it empty object
              // 'filters: {}'. Don't use thses two reserved names to use 
              // other purpose.

              self_.$emit('request-action', {
                action: {
                  ...this.Action,
                },
                filters: {
                  MOD: code
                }
              }) 
            })
        }

        let elMask = d3.select(d3.select(g[i]).node().children[1]) // Polygon ,rect, path, circle, ellipse
        let elText = d3.select(g[i]).select('text')
        let code = elText.text()

        elText.style('opacity', 0.0)
        elMask.style('opacity', 0.0).attr('fill','#fff').call(setAction_)
      })
    },
  }
}